/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

// `model` here is Ruleset and `value` is Ruleset#userRolePredicate
const RulesetNotStaticVisitor = BaseValidator.extend({
  validate(value, options, model) {
    if (
      model.isMatchBehaviorStatic &&
      value?.value.length === 1 &&
      value?.value.includes('visitor_role')
    ) {
      return 'You can not send one-off messages to visitors.';
    }
    return true;
  },
});

RulesetNotStaticVisitor.reopenClass({
  getDependentsFor() {
    return [
      'model.isMatchBehaviorStatic',
      'model.predicateGroup.userPredicate',
      'model.rolePredicateGroup.userPredicate',
    ];
  },
});

export default RulesetNotStaticVisitor;
