/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import type Session from './session';
import { get } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import type InboxApi from 'embercom/services/inbox-api';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { request } from 'embercom/lib/inbox/requests';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type Team from 'embercom/objects/inbox/inboxes/team';

interface AppConversationCapacityLimitWireFormat {
  pull_conversation_enabled: boolean;
}

export default class PullConversationService extends Service {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @tracked pullConversationEnabled = false;
  @tracked teammateHasLbaInbox = false;

  constructor() {
    super(...arguments);
    this.getPullConversationEnabled();

    if (!this.session.showLightInbox) {
      this.initTeammateHaveLbaInbox();
    }
  }

  get isPullConversationEnabled() {
    return this.pullConversationEnabled && this.teammateHasLbaInbox;
  }
  async initTeammateHaveLbaInbox() {
    this.teammateHasLbaInbox = await this.doesTeammateHaveLbaInbox();
  }

  async doesTeammateHaveLbaInbox() {
    let lbaInboxes = await this.loadLbaInboxes();
    let adminTeamMemberships = await this.loadAdminTeamMemberships();

    let lbaInboxIds = lbaInboxes.map((inbox: Team) => parseInt(inbox.id, 10));
    let adminInboxIds = adminTeamMemberships.map((team: TeamSummary) => team.id);
    return lbaInboxIds.some((inboxId) => adminInboxIds.includes(inboxId));
  }

  async loadLbaInboxes() {
    let { inboxes } = await this.inboxApi.listInboxes({
      types: [InboxType.Team],
    });

    return inboxes.filter((inbox) => {
      return inbox.teamSummary?.teamSettings?.assignmentDistribution === 'load_balanced';
    });
  }

  async loadAdminTeamMemberships() {
    let response = await request(
      `/ember/inbox/admins/team_memberships?app_id=${this.session.workspace.id}`,
    );
    let data = await response.json();
    return await data?.teams;
  }

  async getPullConversationEnabled() {
    let data: AppConversationCapacityLimitWireFormat = await get(
      `/ember/app_conversation_capacity_limits/${this.session.workspace.id}`,
    );
    this.pullConversationEnabled = data.pull_conversation_enabled;
  }
}

declare module '@ember/service' {
  interface Registry {
    pullConversationService: PullConversationService;
    'pull-conversation-service': PullConversationService;
  }
}
