/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class EarlyStageGraduationSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    futurePrice: { embedded: 'always' },
    productsForGraduatingPricingModel: { deserialize: 'records' },
  };
}
