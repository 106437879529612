/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import type Session from './session';

const MISSING_TRANSLATIONS_BLOCKLIST = ['inbox.ticket-state.'];

export default class MissingTranslations extends Service {
  @service declare appService: { app?: { id: string; currentAdmin?: { id: string } } };
  @service declare session: Session;

  missingTranslations: Array<{ key: string; locale: string }> = [];

  knownMissingTranslations: Set<string> = new Set();

  isEnabledInTests = true;

  notifyMissingTranslation(key: string, locale: string): void {
    if (ENV.environment === 'test' && !this.isEnabledInTests) {
      return;
    }

    if (MISSING_TRANSLATIONS_BLOCKLIST.includes(key)) {
      return;
    }

    let cacheKey = `${key}:${locale}`;
    // Return early if we have already notified about this missing translation
    if (this.knownMissingTranslations.has(cacheKey)) {
      return;
    }
    // Return early if we have no app
    if (
      !this.appService.app &&
      !(this.session.isWorkspaceLoaded && this.session.isTeammateLoaded)
    ) {
      return;
    }
    this.missingTranslations.push({
      key,
      locale,
    });
    this.knownMissingTranslations.add(cacheKey);
    taskFor(this.sendMissingTranslationKeys).perform();
  }

  @task({ keepLatest: true })
  *sendMissingTranslationKeys() {
    try {
      yield timeout(ENV.APP._2000MS);

      let copiedFailures = [...this.missingTranslations];
      this.missingTranslations = [];
      yield post('/ember/missing_translations', {
        app_id: this.appService.app?.id || this.session.workspace.id,
        admin_id: this.appService.app?.currentAdmin?.id || this.session.teammate.id,
        missing: copiedFailures,
      });
    } catch (e) {
      console.error('Failed to record missing translation keys', e);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    missingTranslations: MissingTranslations;
    'missing-translations': MissingTranslations;
  }
}
