/* RESPONSIBLE TEAM: team-reporting */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class ReportSerializer extends EmbercomBaseSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    charts: {
      deserialize: 'records',
      serialize: 'ids',
    },
  };

  serialize(snapshot, options) {
    //TODO: when cleaning up FF change serialize: 'ids' to serialize: 'records' and take out this method
    let json = super.serialize(snapshot, options);

    if (snapshot.adapterOptions?.canSeeR2Beta || options?.adapterOptions?.canSeeR2Beta) {
      json.report = snapshot.record.serialize();
      json.charts = snapshot.record.availableCharts.map((chart) => chart.serialize());
    }

    return json;
  }
}
