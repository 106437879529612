/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    folder: { key: 'folder_id' },
    ruleset: { embedded: 'always' },
    articleContents: { embedded: 'always' },
    tags: { key: 'tag_ids' },
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
});
