/* RESPONSIBLE TEAM: team-proactive-support */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import type Session from './session';

export default class ResourceUsage extends Service {
  @service declare appService: { app?: { id: string; currentAdmin?: { id: string } } };
  @service declare session: Session;

  knownResources: Set<string> = new Set();

  usedResources: Array<string> = [];

  isEnabledInTests = false;

  startTracking() {
    if (ENV.environment !== 'test' || this.isEnabledInTests) {
      taskFor(this.trackResourceUsage).perform();
    }
  }

  @task *trackResourceUsage() {
    yield timeout(ENV.APP._10000MS);

    this.notifyUsage();

    taskFor(this.trackResourceUsage).perform();
  }

  notifyUsage(): void {
    if (ENV.environment === 'test' && !this.isEnabledInTests) {
      return;
    }

    if (window.EMBER_COMPONENT_USAGE) {
      let allUsedResources = window.EMBER_COMPONENT_USAGE;

      let newResourceUsages = Array.from(allUsedResources).filter(
        (resource: string) => !this.knownResources.has(resource),
      );

      this.usedResources = newResourceUsages;

      taskFor(this.sendResourceUsages).perform();
    }
  }

  @task({ keepLatest: true })
  *sendResourceUsages() {
    if (!this.usedResources.length) {
      return;
    }
    try {
      yield post('/ember/resource_usage', {
        app_id: this.appService.app?.id || this.session.workspace.id,
        admin_id: this.appService.app?.currentAdmin?.id || this.session.teammate.id,
        used_resources: this.usedResources,
      });
    } catch (e) {
      // console.error('Failed to record resource usages', e);
    } finally {
      this.usedResources.forEach((resource) => this.knownResources.add(resource));
      this.usedResources = [];
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    resourceUsage: ResourceUsage;
    'resource-usage': ResourceUsage;
  }
}
