/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';
import { getRouter } from 'embercom/lib/container-lookup';
import { readOnly } from '@ember/object/computed';
import generateUUID from 'embercom/lib/uuid-generator';
import OpenCenteredWindow from 'embercom/lib/open-centered-window';
import { buildAuthorizeUrl } from 'embercom/lib/import/authorize-url-builder';

export default Service.extend({
  appService: service(),
  app: readOnly('appService.app'),

  tokenRetrievalError: false,
  conversationImport: null,
  intercomEventService: service(),

  handleRedirect(queryParams) {
    let searchParams = new URLSearchParams(queryParams);
    let code = searchParams.get('code');
    let state = searchParams.get('state');
    if (!code || !this._verifyState(state)) {
      return this.set('tokenRetrievalError', true);
    }

    let origin = this.conversationImport.get('origin');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'integrated',
      object: origin,
      context: `${origin}_import`,
    });

    this.conversationImport.set('code', code);
    return this.conversationImport
      .save()
      .then((response) => {
        this.set('tokenRetrievalError', false);
        let router = getRouter();
        router.transitionTo('apps.app.import.zendesk-chat.import', response);
      })
      .catch((error) => {
        this.set('tokenRetrievalError', true);
      });
  },

  authorize(conversationImport) {
    this.set('tokenRetrievalError', false);
    this.set('conversationImport', conversationImport);
    let authorizeUrl = this._authorizeUrl(conversationImport);
    return OpenCenteredWindow(authorizeUrl, 800, 1100);
  },

  _authorizeUrl(conversationImport) {
    let baseUrl = buildAuthorizeUrl(conversationImport);
    return `${baseUrl}&state=${this._generateState()}`;
  },

  _verifyState(state) {
    let authorizeState = window.sessionStorage.getItem('authorizeState');
    return authorizeState !== null && state === authorizeState;
  },

  _generateState() {
    let state = generateUUID();
    window.sessionStorage.setItem('authorizeState', state);
    return state;
  },
});
