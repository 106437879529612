/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default class CustomDomainRequired extends BaseValidator {
  @service intl;

  // value is isSendToUnsubscribed or isLegallyMandated, both boolean
  validate(value, options, emailModel) {
    let customDomainValidations = emailModel.localizedEmailContents.map((localizedEmailContent) => {
      return (
        value &&
        !(
          localizedEmailContent.isSenderUsingCustomDomain &&
          localizedEmailContent.isDomainDkimAuthenticated &&
          localizedEmailContent.isDomainReturnPathAuthenticated
        )
      );
    });
    return customDomainValidations.includes(true) ? this.intl.t(options.message) : true;
  }
}

CustomDomainRequired.reopenClass({
  getDependentsFor() {
    return [
      'model.localizedEmailContents.@each.{isSenderUsingCustomDomain,isDomainDkimAuthenticated,isDomainReturnPathAuthenticated}',
    ];
  },
});
