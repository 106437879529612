/* RESPONSIBLE TEAM: team-proactive-support */
import Service from '@ember/service';
import ClipboardJS from 'clipboard';

export default class ClipboardService extends Service {
  /*
   * Copy content to the clipboard by creating a Clipboard object using this
   * service method. This method returns the created clipboard object.
   * Remember to call clipboard.destroy() on the object when your
   * component gets destroyed!
   */
  createClipboard(
    element: $TSFixMe,
    textToCopy: string,
    onSuccess: (e: ClipboardJS.Event) => void,
    onError: (e: ClipboardJS.Event) => void,
  ) {
    let clipboard = this._createClipboardObject(element, textToCopy);

    if (onSuccess) {
      clipboard.on('success', onSuccess);
    }

    if (onError) {
      clipboard.on('error', onError);
    }

    return clipboard;
  }

  _createClipboardObject(element: $TSFixMe, text: string) {
    return new ClipboardJS(element, { text: () => text });
  }
}
