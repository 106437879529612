/* RESPONSIBLE TEAM: team-customer-data-platform */
import Service from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import FinPersistedDataContextAttribute from 'embercom/models/fin-persisted-data-context/fin-persisted-data-attribute';

const INTERCOM_ATTRIBUTE_SOURCE = 'user_attributes';

export default class FinPersistedDataContextService extends Service {
  @task *getOrCreateFinPersistedDataContextAttribute(
    appId: string,
    adminId: string,
    attributeIdentifier: string,
  ): TaskGenerator<FinPersistedDataContextAttribute | null> {
    let paramData = {
      app_id: appId,
      admin_id: adminId,
      intercom_attribute_source: INTERCOM_ATTRIBUTE_SOURCE,
      intercom_attribute_identifier: attributeIdentifier,
    };
    try {
      let response = yield post(
        '/ember/fin_persisted_data_context/attribute/get_or_create',
        paramData,
      );
      return new FinPersistedDataContextAttribute(
        response.id,
        response.status === 'live',
        response.ai_content_segment_ids,
      );
    } catch (error) {
      console.error('Failed to initialize finPersistedDataContextAttribute:', error);
      return null;
    }
  }

  @task *updateFinPersistedDataContextAttributeStatus(
    appId: string,
    adminId: string,
    attributeIdentifier: string,
    isFinLive: boolean,
  ): TaskGenerator<void> {
    let paramData = {
      app_id: appId,
      admin_id: adminId,
      intercom_attribute_source: 'user_attributes',
      intercom_attribute_identifier: attributeIdentifier,
      status: isFinLive ? 'live' : 'draft',
    };
    try {
      yield post('/ember/fin_persisted_data_context/attribute/update_status', paramData);
    } catch (error) {
      console.error('Failed to update finPersistedDataContextAttribute:', error);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    finPersistedDataContextService: FinPersistedDataContextService;
    'fin-persisted-data-context-service': FinPersistedDataContextService;
  }
}
