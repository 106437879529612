/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class RulesetIsTransactional extends BaseValidator {
  @service intl;

  validate(matchBehavior, options, model) {
    if (!model.isMatchBehaviorSingle) {
      return true;
    }

    let emailRulesetLinks = model.rulesetLinks.filter(
      (link) => link.objectType === objectTypes.email,
    );
    let isSendToUnsubscribed = emailRulesetLinks.any((link) => link.sendToUnsubscribed);
    let isLegallyMandated = emailRulesetLinks.any((link) => link.isLegallyMandated);

    if (isSendToUnsubscribed && !isLegallyMandated) {
      return this.intl.t(
        'matching-system.audience-selector.unsubscribed-preference.include-unsubscribed-validation',
      );
    }
    return true;
  }
}

RulesetIsTransactional.reopenClass({
  getDependentsFor() {
    return [
      'model.isMatchBehaviorSingle',
      'model.rulesetLinks.@each.sendToUnsubscribed',
      'model.rulesetLinks.@each.isLegallyMandated',
    ];
  },
});
