/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import {
  defaultRulesetSpacingPeriod,
  minimumEventTriggeredRulesetSpacingPeriod,
  reducedLimitMinimumEventTriggeredRulesetSpacingPeriod,
  objectNotificationPreferences,
} from 'embercom/models/data/matching-system/matching-constants';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';

// `model` here is Ruleset and `value` is Ruleset#matchSpacingPeriod
const RulesetHasValidMatchSpacingPeriod = BaseValidator.extend({
  appService: service(),

  validate(value, options, model) {
    if (model.isMatchBehaviorSingle) {
      return true;
    }

    let multiMatchCustomBot =
      model.rulesetLinks.firstObject?.isOutboundCustomBot &&
      model.rulesetLinks.any(
        (rulesetLink) =>
          rulesetLink.object.showUntil === objectNotificationPreferences.interactedWith ||
          rulesetLink.object.showUntil === objectNotificationPreferences.engagedWith,
      );

    let multiMatchNoTriggers = model.isMatchBehaviorMulti && model.rulesetTriggers.length === 0;
    if (
      multiMatchNoTriggers &&
      (model.rulesetLinks.firstObject?.isBanner ||
        multiMatchCustomBot ||
        (model.node?.isInternal && model.node?.series.isMultipleEntry))
    ) {
      return true;
    }

    let hasReducedSpacingPeriod = this.appService.app?.canUseReducedMatchSpacingPeriod;
    let multiMatchWithTriggers = model.isMatchBehaviorMulti && model.rulesetTriggers.length > 0;
    let multiMatchWithoutSchedule = model.isMatchBehaviorMulti && isBlank(model.recurringSchedule);
    let minimumSpacingPeriod = hasReducedSpacingPeriod
      ? reducedLimitMinimumEventTriggeredRulesetSpacingPeriod
      : minimumEventTriggeredRulesetSpacingPeriod;
    if (multiMatchWithTriggers && value < minimumSpacingPeriod) {
      let errorMessage = hasReducedSpacingPeriod
        ? 'You cannot send this message more than once every 5 seconds'
        : 'You cannot send this message more than once a minute';
      return options.message || errorMessage;
    }

    if (model.rulesetTriggers.length === 0 && multiMatchWithoutSchedule) {
      if (value < defaultRulesetSpacingPeriod) {
        return options.message || 'You cannot send this message more than once a day';
      }
    }

    return true;
  },
});

RulesetHasValidMatchSpacingPeriod.reopenClass({
  getDependentsFor() {
    return [
      'model.isMatchBehaviorMulti',
      'model.isMatchBehaviorSingle',
      'model.rulesetTriggers.[]',
      'model.recurringSchedule',
      'model.rulesetLinks.@each.isBanner',
      'model.rulesetLinks.@each.isOutboundCustomBot',
      'model.node.series.isMultipleEntry',
      'model.node.isInternal',
    ];
  },
});

export default RulesetHasValidMatchSpacingPeriod;
