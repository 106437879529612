/* RESPONSIBLE TEAM: team-workflows */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  normalize(modelClass, resourceHash) {
    // Ensure that data must be a hash
    resourceHash.data = resourceHash.data || {};
    return this._super(modelClass, resourceHash);
  },
});
