/* RESPONSIBLE TEAM: team-messenger */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class LookAndFeelSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  normalize(modelClass, resourceHash) {
    if (resourceHash.brands) {
      resourceHash.brand_settings = resourceHash.brands;
    }

    return super.normalize(modelClass, resourceHash);
  }

  attrs = {
    brands: {
      serialize: false,
      deserialize: 'records',
    },
    brandSettings: {
      serialize: false,
      deserialize: 'records',
    },
    newBrands: {
      serialize: false,
      deserialize: 'records',
    },
  };
}
