/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import { type ViewItem } from './reporting-views';
import type ReportingViews from './reporting-views';
import type IntlService from 'ember-intl/services/intl';
import type FavoritesService from './favorites-service';
import { find, indexBy, isEmpty, isEqual } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { A } from '@ember/array';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { isPresent } from '@ember/utils';

export interface ReportsFolder {
  id: string;
  name: string;
  icon: InterfaceIconName;
  isSticky: boolean;
  reports: ViewItem[];
}

export interface SubmenuFolder {
  id: string;
  isSticky: boolean;
}

export default class ReportingFolderManagerService extends Service {
  @service declare reportingViews: ReportingViews;
  @service declare intl: IntlService;
  @service declare favoritesService: FavoritesService;
  @service declare appService: any;
  @service declare store: any;
  @service declare permissionsService: any;

  @tracked private folderState: SubmenuFolder[] = [];
  @tracked private activeFolderReport = '';
  @tracked pinnedFlexibleFolders: NavigationFolder[] = A([]);
  @tracked allFlexibleFolders: NavigationFolder[] = A([]);

  constructor() {
    super(...arguments);
    this.setFolderStateToDefault();
  }

  private defaultFolders() {
    return [
      {
        id: 'favorites',
        isSticky: true,
      },
      {
        id: 'human-support',
        isSticky: true,
      },
    ];
  }

  get activeReport() {
    return this.activeFolderReport;
  }

  setActiveFolderReport(folderId: string, reportId: string) {
    this.activeFolderReport = `${folderId}-${reportId}`;
  }

  get emptyState() {
    return <SubmenuFolder>{};
  }

  get activeFolders() {
    return this.folderState;
  }

  @action
  updateFolderState(selectedFolder: SubmenuFolder) {
    if (this.isActive(selectedFolder)) {
      this.folderState = this.folderState.reject((folder) => isEqual(folder, selectedFolder));
      this.closeAllNonStickyFolders();
    } else {
      let stickyFolders = this.folderState.filter((folder) => folder.isSticky);
      let newFolderState = [...stickyFolders, selectedFolder].reject((folder) => isEmpty(folder));
      this.folderState = newFolderState;
    }
  }

  isActive(selectedFolder: SubmenuFolder): boolean {
    return (
      find(this.folderState, (folder: SubmenuFolder) => isEqual(folder, selectedFolder)) !==
      undefined
    );
  }

  setFolderStateToDefault() {
    this.folderState = this.defaultFolders();
  }

  closeAllNonStickyFolders() {
    let oldState = this.folderState;
    this.folderState = oldState.filter((folder) => folder.isSticky);
  }

  @action
  unSelectActiveFolderItem() {
    this.setActiveFolderReport('', '');
  }

  @action
  resetFolders() {
    this.setActiveFolderReport('', '');
    this.setFolderStateToDefault();
  }

  @action
  resetActiveFolder() {
    this.unSelectActiveFolderItem();
    this.allFlexibleFolders
      .filter((folder) => isPresent(folder.lastOpened))
      .forEach((folder) => folder.resetLastOpened());
  }

  get favoriteFolder(): ReportsFolder {
    return {
      id: 'favorites',
      name: this.appService.app.hasOptedInForProductIa
        ? this.intl.t('reporting.submenu-component.views.your-favorites')
        : this.intl.t('reporting.submenu-component.views.favorites'),
      icon: 'relationship',
      reports: this.favoritesService.favoriteReports,
      isSticky: true,
    };
  }

  get topicsFolder(): ReportsFolder {
    return {
      id: 'conversation-topics',
      name: this.intl.t('reporting.submenu-component.items.conversational-insights.title'),
      icon: 'insights',
      reports: this.reportingViews.topicReports,
      isSticky: true,
    };
  }

  get staticFolders(): ReportsFolder[] {
    let staticViews = this.reportingViews.staticViews.map((view) => {
      return {
        ...view,
        name: this.intl.t(view.name),
      };
    });
    let indexedViews = indexBy(staticViews, 'id');
    return [
      {
        ...indexedViews['human-support'],
        icon: 'recipient-data',
        isSticky: true,
      },
      {
        ...indexedViews['automation'],
        icon: 'auto-message',
        isSticky: true,
      },
      {
        ...indexedViews['proactive'],
        icon: 'rocket-ship',
        isSticky: true,
      },
      {
        ...indexedViews['conversation-topics'],
        icon: 'insights',
        isSticky: true,
      },
    ];
  }

  async loadFlexibleFolders() {
    return taskFor(this.fetchFlexibleFolders).perform();
  }

  get loadFlexibleFoldersIsRunning() {
    return taskFor(this.fetchFlexibleFolders).isRunning;
  }

  @restartableTask
  *fetchFlexibleFolders(): TaskGenerator<void> {
    let folders = yield this.store.findAll('reporting/navigation-folder', {
      reload: true,
      isBackgroundReload: true,
    });
    this.pinnedFlexibleFolders = folders.filter((f: NavigationFolder) => f.pinned);
    this.allFlexibleFolders = folders;
  }

  async loadPinnedFlexibleFolders() {
    return taskFor(this.fetchPinnedFlexibleFolders).perform();
  }

  @restartableTask
  *fetchPinnedFlexibleFolders(): TaskGenerator<void> {
    let folders = yield this.store.findAll('reporting/navigation-folder', {
      reload: true,
      isBackgroundReload: true,
    });
    this.pinnedFlexibleFolders = folders.filter((f: NavigationFolder) => f.pinned);
  }

  get canAdminManageFlexibleFolders() {
    return this.permissionsService.currentAdminCan('can_reporting__navigation_folders__manage');
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingFolderManager: ReportingFolderManagerService;
    reportingFolderManagerService: ReportingFolderManagerService;
    'reporting-folder-manager-service': ReportingFolderManagerService;
  }
}
