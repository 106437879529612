import Handlebars from 'handlebars';
      const _template = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-test-nps-line-chart-tooltip class='reporting__highcharts-tooltip'>\n  <div>\n    <span class='font-bold'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"npsScore") : depth0), depth0))
    + "</span>\n  </div>\n  <div>\n    ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rangeText") : depth0), depth0))
    + ")\n  </div>\n</div>";
},"useData":true});
      export default _template;