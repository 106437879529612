/* RESPONSIBLE TEAM: team-frontend-tech */
import SubviewSerializer from './subview';
import { getOwner } from '@ember/application';

export default class ContentBlockSerializer extends SubviewSerializer {
  normalize(modelClass, resourceHash) {
    let result = super.normalize(modelClass, resourceHash);
    let store = getOwner(this).lookup('service:store');
    Object.assign(result.data.attributes, {
      appearance: store.normalize('common/subviews/appearance', resourceHash.appearance).data
        .attributes,
      block: store.normalize('common/blocks/block', resourceHash.block).data.attributes,
    });
    return result;
  }
}
