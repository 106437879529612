/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';
import { VALID_STATES } from 'embercom/models/data/messages/message-constants';

export default buildValidations({
  state: {
    description: 'State',
    validators: [validator('presence', true), validator('inclusion', { in: VALID_STATES })],
  },
});
