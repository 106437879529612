/* RESPONSIBLE TEAM: team-frontend-tech */

import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class ReachabilityService extends Service {
  @service('browser/window') window;

  startNotifier(onNetworkIsReachable, onNetworkIsNotReachable) {
    if (this._isFunction(onNetworkIsReachable)) {
      this.onNetworkIsReachable = onNetworkIsReachable;
    }
    if (this._isFunction(onNetworkIsNotReachable)) {
      this.onNetworkIsNotReachable = onNetworkIsNotReachable;
    }

    this.window.addEventListener('online', this.onNetworkIsReachable, false);
    this.window.addEventListener('offline', this.onNetworkIsNotReachable, false);
  }

  stopNotifier() {
    this.window.removeEventListener('offline', this.onNetworkIsNotReachable, false);
    this.window.removeEventListener('online', this.onNetworkIsReachable, false);
  }

  onNetworkIsReachable() {
    console.info('Reachability: network is reachable');
  }
  onNetworkIsNotReachable() {
    console.info('Reachability: network is not reachable');
  }

  // Private methods
  _isFunction(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  }
}
