/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import cached from 'embercom/lib/cached-decorator';
import { Article } from 'embercom/objects/inbox/article';
import type Session from 'embercom/services/session';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { request } from 'embercom/lib/inbox/requests';
import type Store from '@ember-data/store';

export type AugmentedHelpCenterData = { id: string; name: string };

export default class ArticlesApi extends Service {
  @service declare session: Session;
  @service declare store: Store;

  get isArticleInserterInstalled() {
    return this.articleAppLoader.value?.installed;
  }

  @cached({ max: 100, ttl: 1000 * 60 * 60 })
  async inserterSearch({
    query,
    conversationId,
    helpCenterId,
    listed,
  }: {
    query: string;
    conversationId?: number;
    helpCenterId?: string;
    listed?: boolean;
  }) {
    let url = `/ember/articles/inserter_search?phrase=${query}&app_id=${this.session.workspace.id}`;
    if (conversationId) {
      url += `&conversation_id=${conversationId}`;
    }
    if (helpCenterId) {
      url += `&help_center_id=${helpCenterId}`;
    }
    if (listed === false) {
      url += '&listed=0';
    }
    url += '&per_page=40';

    let response = await request(url);
    let { articles } = await response.json();
    return { query, helpCenterId, articles };
  }

  @cached({ max: 100, ttl: 1000 * 60 * 60 })
  async helpCenters(articleId?: string, returnDefaultIfNoArticle = false) {
    let response: Array<AugmentedHelpCenterData> = [];
    let helpCenters = await this.store.query('help-center-site', {
      filtering_article_id: articleId,
      return_default_if_no_article: returnDefaultIfNoArticle,
    });
    if (Number(helpCenters.length) > 0) {
      helpCenters.forEach((helpCenter: HelpCenterSite) => {
        response.push({
          id: helpCenter.id,
          name: helpCenter.name,
        });
      });
    }

    return response;
  }

  @cached({ max: 100, ttl: 1000 * 60 * 60 })
  async fetchArticle(id: string) {
    let response = await request(`/ember/inbox/articles/${id}?app_id=${this.session.workspace.id}`);
    let json = await response.json();

    return { id, article: Article.deserialize(json.article) };
  }

  private articleAppLoader = trackedFunction(this, async () => {
    let response = await request(
      `/ember/appstore/app_packages/article-inserter?app_id=${this.session.workspace.id}`,
    );
    let json = await response.json();
    return { installed: json.is_installed as boolean };
  });
}

declare module '@ember/service' {
  interface Registry {
    articlesApi: ArticlesApi;
    'articles-api': ArticlesApi;
  }
}
