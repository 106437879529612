/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default class Sounds extends Service {
  @service audioNotificationService;
  @service appService;
  soundIds = ['default', 'blip', 'bop', 'clack', 'hiss', 'shake', 'tink'];
  adminNotificationSounds = {
    default: new window.Audio(assetUrl('/assets/audio/notification.mp3')),
    blip: new window.Audio(assetUrl('/assets/audio/blip.mp3')),
    bop: new window.Audio(assetUrl('/assets/audio/bop.mp3')),
    clack: new window.Audio(assetUrl('/assets/audio/clack.mp3')),
    hiss: new window.Audio(assetUrl('/assets/audio/hiss.mp3')),
    shake: new window.Audio(assetUrl('/assets/audio/shake.mp3')),
    tink: new window.Audio(assetUrl('/assets/audio/tink.mp3')),
  };

  async playAdminNotification() {
    try {
      let soundId = 'default';
      let customSoundId = this.appService.app.currentAdmin.notification_sound;

      if (customSoundId && this.soundIds.includes(customSoundId)) {
        soundId = customSoundId;
      }
      await this.adminNotificationSounds[soundId].play();
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        this.audioNotificationService.setBlockedState(true);
        this.audioNotificationService.hideAudioNotificationsBlockedBanner = false;
        return;
      }
      throw error;
    }

    this.audioNotificationService.setBlockedState(false);
  }
}
