/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    series: { key: 'series_id' },
    rulesetClientData: { embedded: 'always' },
    rulesetErrors: { embedded: 'always' },
    contentPreviews: { embedded: 'always' },
    edgeSplits: {
      embedded: 'always',
    },
  },
});
