/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class ConversationAccessService extends Service {
  @service appService;

  isConversationAccessAllowed(conversation, admin) {
    let permissionToCheck = admin.currentAppPermissions;

    // This follows the same logic we have on the backend
    // https://github.com/intercom/intercom/blob/d16bfa2d35e771396288ea048084fbaab06b6eb9/app/commands/conversations/admin/can_access_conversation.rb#L3
    switch (permissionToCheck?.accessType) {
      case 'all_except':
        return (
          this._isAssignedToThem(admin, conversation) ||
          !this._isAssignedToExcluded(permissionToCheck, conversation)
        );
      case 'only_them':
        return (
          this._isAssignedToThem(admin, conversation) ||
          this._shouldIncludeUnassigned(permissionToCheck, conversation)
        );
      case 'only_them_or_their_teams':
        return (
          this._isAssignedToThem(admin, conversation) ||
          this._shouldIncludeUnassigned(permissionToCheck, conversation) ||
          this._isAssignedToTheirTeams(admin, conversation)
        );
      default:
        return true;
    }
  }

  isSpamAccessAllowed(admin) {
    let permissionToCheck = admin.currentAppPermissions;

    return ['all', 'all_except', null].includes(permissionToCheck?.accessType);
  }

  get app() {
    return this.appService.app;
  }

  get currentAdminPermissions() {
    return this.app.currentAdmin.currentAppPermissions;
  }

  get currentAdminCanAccessAllConversations() {
    return ['all', null, undefined].includes(this.currentAdminPermissions.accessType);
  }

  _isAssignedToTheirTeams(admin, conversation) {
    let adminTeams = this.app.teamsWithoutAppTeam
      .filter((team) => team.member_ids.includes(parseInt(admin.id, 10)))
      .mapBy('id');
    return adminTeams.includes(conversation.team_assignee_id);
  }

  _isAssignedToThem({ id }, { admin_assignee_id }) {
    return admin_assignee_id === id;
  }

  _isAssignedToExcluded({ assigneeBlockedList }, { admin_assignee_id, team_assignee_id }) {
    let blockList = assigneeBlockedList || [];
    blockList = blockList.map((id) => parseInt(id, 10));
    return (
      blockList.includes(parseInt(admin_assignee_id, 10)) ||
      blockList.includes(parseInt(team_assignee_id, 10))
    );
  }

  _shouldIncludeUnassigned(permission, conversation) {
    return (
      permission.includeUnassigned && !conversation.hasTeamAssignee && !conversation.hasAssignee
    );
  }
}
