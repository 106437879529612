/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { MAX_DELAY_IN_SECONDS, MIN_DELAY_IN_SECONDS } from 'embercom/lib/workflows/helpers/delays';

const CustomBotInactivityDurationValid = BaseValidator.extend({
  intl: service(),

  validate(inactivityDuration) {
    if (
      !inactivityDuration ||
      inactivityDuration > MAX_DELAY_IN_SECONDS ||
      inactivityDuration < MIN_DELAY_IN_SECONDS
    ) {
      return this.intl.t('operator.custom-bot.trigger.inactivity-duration.invalid-message');
    }

    return true;
  },
});

CustomBotInactivityDurationValid.reopenClass({
  getDependentsFor() {
    return ['model.inactivityDuration'];
  },
});

export default CustomBotInactivityDurationValid;
