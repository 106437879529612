/* RESPONSIBLE TEAM: team-frontend-tech */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    attributes: {
      serialize: 'records',
      deserialize: 'records',
    },
    attribute_settings: {
      serialize: 'records',
      deserialize: 'records',
    },
    features: {
      serialize: 'records',
      deserialize: 'records',
    },
    products: {
      serialize: 'records',
      deserialize: 'records',
    },
    qualification_attributes: {
      serialize: 'records',
      deserialize: 'records',
    },
    segments: {
      serialize: 'records',
      deserialize: 'records',
    },
    sdkApps: {
      serialize: 'records',
      deserialize: 'records',
    },
    plans: {
      serialize: 'records',
      deserialize: 'records',
    },
    admins: {
      serialize: 'records',
      deserialize: 'records',
    },
    tags: {
      serialize: 'records',
      deserialize: 'records',
    },
    subscriptionTypes: {
      serialize: 'records',
      deserialize: 'records',
    },
    customObjectTypes: {
      serialize: 'records',
      deserialize: 'records',
    },
    finFreeUsageWindow: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
});
