/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';
import { not } from '@ember/object/computed';
import { computed } from '@ember/object';

export default buildValidations({
  nextPath: {
    description: 'Next path',
    validators: [
      validator('workflow-branching-control-has-next-path', {
        disabled: not('model.step.canBranch'),
        dependentKeys: ['model.step.branchingControls.@each.nextPath'],
      }),
    ],
  },
  predicateGroup: {
    validators: [
      validator('workflow-conditional-branch-valid', {
        disabled: not('model.step.isConditionalBranches'),
        dependentKeys: ['model.predicateGroup.predicates.[]'],
      }),
    ],
  },
  attributeIdentifier: {
    description: 'Attribute identifier',
    validators: [
      validator('workflow-step-data-field-is-set', {
        disabled: not('model.step.isAttributeCollector'),
      }),
    ],
  },
  buttonLabel: {
    description: 'Reply button label',
    validators: [
      validator('workflow-control-reply-button-not-empty', {
        disabled: computed(
          'model.step.{isReplyButtons,isCustomObjectSelector}',
          'model.isFallback',
          function () {
            return !(
              this.model.step.isReplyButtons ||
              (this.model.step.isCustomObjectSelector && this.model.isFallback)
            );
          },
        ),
      }),
    ],
  },
});
