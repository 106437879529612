/* RESPONSIBLE TEAM: team-phone */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import 'amazon-connect-streams';
import {
  AmazonCtiPhoneCall,
  AmazonCtiPhoneCallState,
} from 'embercom/objects/phone/amazon-cti-phone-call';
import { type IntercomCtiData } from 'embercom/objects/phone/cti-types';

declare global {
  interface Window {
    IntercomCtiSDK: any;
    connect: any;
  }
}

export default class AmazonCtiService extends Service {
  @tracked isInitialised = false;
  @tracked isFailedInitialisation = false;

  ctiSDK: any = window.IntercomCtiSDK;
  currentPhoneCall: AmazonCtiPhoneCall | null = null;
  outboundCallData: { [key: string]: string } | null = null;
  contactCentreUrl = '';

  get instanceUrl() {
    return `https://${this.contactCentreUrl}/connect/ccp-v2`;
  }

  get amazonConnect() {
    return window.connect;
  }

  initialize(contactCentreUrl: string) {
    this.contactCentreUrl = contactCentreUrl;

    this.ctiSDK.initialize();
    this.ctiSDK.subscribeToCallRequest(this.handleOutboundPhoneCall.bind(this));
    this.initAmazonConnect();
  }

  private initAmazonConnect() {
    let containerDiv = document.getElementById('cti-app-container');
    // leave this line in case we need to trigger external Amazon Connect sign in popup window in the future
    // window.localStorage.removeItem('connectPopupManager::connect::loginPopup');

    this.amazonConnect.core.initCCP(containerDiv, {
      ccpUrl: this.instanceUrl, // REQUIRED
      loginPopup: false, // optional, defaults to `true`
      softphone: {
        allowFramedSoftphone: true, // optional, defaults to false
      },
    });

    this.amazonConnect.core.onInitialized(() => {
      this.isInitialised = true;
      this.ctiSDK.notifyReady();
    });
    this.amazonConnect.core.onSoftphoneSessionInit(() => {
      // TODO we might need to be smarter than just picking the first contact
      // I.e. what happens if you have more than one contact?
      let contact = new this.amazonConnect.Agent().getContacts()[0];

      // registering a new phone call for outbound case is done in the outbound call handler
      this.registerNewCall(contact, this.outboundCallData);
    });

    // subscribing to failures during initialisation
    this.amazonConnect.core.onIframeRetriesExhausted(this.handleIframeRetriesExhausted.bind(this));

    // this obscure low-level code is for getting access to logging out event from amazon connect streams
    let eventBus = this.amazonConnect.core.getEventBus();
    eventBus.subscribe(this.amazonConnect.EventType.TERMINATED, () => {
      this.isInitialised = false;
      this.ctiSDK.notifyNotReady();
    });
  }

  private handleIframeRetriesExhausted() {
    this.isFailedInitialisation = true;
    this.ctiSDK.notifyInitialisationFailure();
  }

  private handleOutboundPhoneCall(data: IntercomCtiData) {
    this.outboundCallData = data.payload;
    let agent = new this.amazonConnect.Agent();
    let endpoint = this.amazonConnect.Endpoint.byPhoneNumber(data.payload.phoneNumber);

    // TODO log the error if any
    // after calling 'connect', the softphone gets initialised and we jump into the 'onSoftphoneSessionInit' handler
    // defined in the 'initAmazonConnect' function
    agent.connect(endpoint);
  }

  private getCallMetadata(phoneCall: AmazonCtiPhoneCall) {
    let contactId = phoneCall.amazonContact.getContactId();

    return {
      recordingUrl: `https://${this.contactCentreUrl}/get-recording?format=wav&callLegId=${contactId}`,
      contactUrl: `https://${this.contactCentreUrl}/contact-trace-records/details/${contactId}`,
      duration: this.currentPhoneCall?.duration,
    };
  }

  private registerNewCall(contact: any, payload: any = {}) {
    // TODO check if there's another call in progress
    // should we just overwrite the old call if there's a new one?

    this.currentPhoneCall = new AmazonCtiPhoneCall(
      contact,
      AmazonCtiPhoneCallState.Started,
      payload,
    );

    this.currentPhoneCall.onStateChange((oldState: string, newState: string) => {
      let callMetadata = {};

      switch (newState) {
        case AmazonCtiPhoneCallState.Accepted:
          // protection from duplicate events
          if (oldState === AmazonCtiPhoneCallState.Accepted) {
            return;
          }

          this.ctiSDK.notifyAcceptedCall({
            phoneNumber: this.currentPhoneCall?.phoneNumber,
            ...payload,
          });
          break;
        case AmazonCtiPhoneCallState.Finished:
          // protection from duplicate events
          if (oldState === AmazonCtiPhoneCallState.Finished) {
            return;
          }

          if (this.currentPhoneCall) {
            callMetadata = this.getCallMetadata(this.currentPhoneCall);
          }

          this.ctiSDK.notifyEndedCall({
            phoneNumber: this.currentPhoneCall?.phoneNumber,
            ...payload,
            ...callMetadata,
          });

          // TODO how do we clean this up in case the "end call" event does not happen
          this.currentPhoneCall = null;
          this.outboundCallData = null;

          break;
      }
    });

    this.ctiSDK.notifyNewCall({ phoneNumber: this.currentPhoneCall.phoneNumber, ...payload });
  }
}

declare module '@ember/service' {
  interface Registry {
    amazonCtiService: AmazonCtiService;
    'amazon-cti-service': AmazonCtiService;
  }
}
