/* RESPONSIBLE TEAM: team-channels */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    appSsoDomains: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
  serialize(snapshot) {
    let json = this._super(...arguments);

    try {
      let jitSettings = json.jit_settings;

      jitSettings.access_type = snapshot.record.jitSettings.accessType;
      jitSettings.include_unassigned = snapshot.record.jitSettings.includeUnassigned;
      jitSettings.assignee_blocked_list = snapshot.record.jitSettings.assigneeBlockedList;
    } catch (e) {
      // Do nothing;
    }

    return json;
  },
  normalize() {
    let payload = this._super(...arguments);

    try {
      let jitSettings = payload.data.attributes.jitSettings;

      jitSettings.accessType = jitSettings.access_type;
      jitSettings.includeUnassigned = jitSettings.include_unassigned;
      jitSettings.assigneeBlockedList = jitSettings.assignee_blocked_list;
    } catch (e) {
      // Do nothing;
    }

    return payload;
  },
});
