/* RESPONSIBLE TEAM: team-ai-agent */
import Service, { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  ALL_ANSWER_STATES,
  ALL_BOT_MODES,
  BOT_ONLY_MODE,
  HAS_ANSWER_STATE,
  QUICK_REPLY_ID,
} from 'embercom/lib/operator/resolution-bot/constants';

export default class AiAgentQuickRepliesService extends Service {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  all_emoji_regex = /[👍👎👤🔁🌐💬👀]/u;

  get quickReplies() {
    return [
      {
        id: QUICK_REPLY_ID.ALL_DONE,
        description: this.intl.t(
          'ai-agent.settings.customize-reply-buttons.resolves-the-conversation',
        ),
        customizable: true,
        labels: ['all_done', 'that_answered_my_question', 'thats_it', 'thumbs_up'],
        emoji: '👍',
        botModes: ALL_BOT_MODES,
        answerStates: [HAS_ANSWER_STATE],
      },
      {
        id: QUICK_REPLY_ID.WAIT_FOR_THE_TEAM,
        description: this.intl.t('ai-agent.settings.customize-reply-buttons.routes-the-customer'),
        tooltipText: this.intl.t(
          'ai-agent.settings.customize-reply-buttons.routes-the-customer-tooltip',
        ),
        customizable: true,
        labels: [
          'talk_to_person',
          'wait_for_the_team',
          'get_more_help',
          'connect_to_an_agent',
          ...(this.appService.app.canUseFeature('fin-thumbs-down') ? ['thumbs_down'] : []),
        ],
        emoji: '👤',
        botModes: ALL_BOT_MODES,
        answerStates: ALL_ANSWER_STATES,
      },
      {
        id: QUICK_REPLY_ID.MORE_ANSWERS,
        description: '',
        customizable: false,
        labels: ['more_answers'],
        emoji: '👀',
        botModes: ALL_BOT_MODES,
        answerStates: [HAS_ANSWER_STATE],
      },
      {
        id: QUICK_REPLY_ID.GET_MORE_HELP,
        description: '',
        customizable: false,
        labels: ['get_more_help'],
        emoji: '🌐',
        botModes: [BOT_ONLY_MODE],
        answerStates: ALL_ANSWER_STATES,
      },
    ];
  }

  getById(id: string) {
    return this.quickReplies.find((r) => r.id === id);
  }

  getLabel(id: string, labelKey: string | null = null, locale: string | [string, ...string[]]) {
    let quickReply = this.getById(id);
    let labelSuffix = labelKey || id;
    switch (labelSuffix) {
      case 'thumbs_up':
        return '👍';
      case 'thumbs_down':
        return '👎';
      default:
        return this.intl
          .t(`answer_bot_${labelSuffix}`, { locale, htmlSafe: true })
          .toString()
          .replace(this.all_emoji_regex, quickReply?.emoji || '');
    }
  }

  getSelectItems(id: string, locale: string) {
    return this.getById(id)?.labels.map((value) => {
      let fullText = this.getLabel(id, value, locale);
      let text =
        value === 'thumbs_up' || value === 'thumbs_down' ? fullText : this._textOnly(fullText);
      return { value, text, fullText };
    });
  }

  _textOnly(fullLabel: string) {
    return fullLabel.replace(this.all_emoji_regex, '').trim();
  }
}
