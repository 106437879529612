/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

/**
 * Validates text does not end with punctuation
 *
 */
const REGEX = /[!"#$€£%&'()*+,-./:;<=>?@[\]^_`{|}~\\]$/;

const TextDoesNotEndWithPunctuation = BaseValidator.extend({
  validate(value) {
    let invalidMessage = 'Text must not end with punctuation';
    return value.match(REGEX) ? invalidMessage : true;
  },
});

export default TextDoesNotEndWithPunctuation;
