/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  questionType: {
    description: 'Question Type',
    validators: [validator('presence', true)],
  },
  blocks: {
    description: 'Question Blocks',
    validators: [validator('question-blocks-not-empty')],
  },
  saveResponseToAttribute: {
    description: 'Save Response To Attribute',
    validators: [validator('save-response-to-attribute-checked-but-no-attribute-selected')],
  },
  data: {
    description: 'Question Data',
    validators: [
      validator('survey-question-options-not-empty'),
      validator('survey-numeric-scale-range'),
      validator('survey-question-options-not-duplicated'),
      validator('survey-question-no-empty-multiselect-options'),
    ],
  },
});
