/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { animate, Promise, isAnimating, finish } from 'liquid-fire';

let findFlippingElement = function (context) {
  for (let i = 0; i < context.older.length; i++) {
    let entry = context.older[i];
    if (isAnimating(entry.element, 'flip-out')) {
      return entry.element;
    }
  }
  if (isAnimating(context.oldElement, 'flip-out')) {
    return context.oldElement;
  }
};

export default function (options) {
  let firstStep;
  let flippingElement = findFlippingElement(this);

  if (!options) {
    options = {};
  }

  if (flippingElement) {
    // We still have some older version that is in the process of
    // revealing, so our first step is waiting for it to finish.
    firstStep = finish(flippingElement, 'flip-out');
  } else {
    firstStep = Promise.resolve();
  }

  return firstStep.then(() => {
    let firstRotation = {};
    let secondRotation = {};

    if (options['reverse']) {
      firstRotation['rotateY'] = '90deg';
      secondRotation['rotateY'] = ['0deg', '-90deg'];
    } else {
      firstRotation['rotateY'] = '-90deg';
      secondRotation['rotateY'] = ['0deg', '90deg'];
    }

    let duration = options.duration ? options.duration : 150;
    let firstOpts = { duration };
    let secondOpts = {
      easing: 'easeInSine',
      duration,
    };

    let container = this.oldElement.parent('.liquid-container');
    container.css({
      perspective: options.perspective ? options.perspective : '1000px',
      overflow: 'visible',
    });

    this.newElement.css('visibility', 'hidden');
    return animate(this.oldElement, firstRotation, firstOpts, 'flip-out').then(() => {
      this.oldElement.css('visibility', 'hidden');
      this.newElement.css('visibility', '');
      return animate(this.newElement, secondRotation, secondOpts, 'flip-out').then(() => {
        container.css({
          perspective: '',
          overflow: '',
        });
      });
    });
  });
}
