/* RESPONSIBLE TEAM: team-workflows */
import StepSerializer from '../step';

export default StepSerializer.extend({
  attrs: {
    action: 'action_id',
  },

  normalize(model, hash) {
    hash.original_action_id = hash.action_id;
    return this._super(...arguments);
  },
  serialize(snapshot, option) {
    let serialized = this._super(...arguments);
    delete serialized.original_action_id;
    return serialized;
  },
});
