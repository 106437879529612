/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { capturePageLoadStats } from 'embercom/lib/page-load-performance-utils';
import { getRouter } from 'embercom/lib/container-lookup';

const SAMPLE_RATE = 0.1;

const getCurrentRouteName = () => {
  try {
    let router = getRouter();
    if (router) {
      return router.currentRouteName;
    }
  } catch (e) {
    // just incase there's no container yet
    console.error(e);
  }
};

export default class PageLoadPerformanceInstrumentationService extends Service {
  @service frontendStatsService;

  sendSampledPageLoadPerformanceEvent() {
    if (Math.random() < SAMPLE_RATE) {
      this._sendPageLoadPerformanceEvent();
    }
  }

  _sendPageLoadPerformanceEvent() {
    let pageLoadStats = capturePageLoadStats();
    let extraMetadata = {
      name: 'page_load_performance',
      sample_rate: SAMPLE_RATE,
      url: window.location.href,
      embercom_route: getCurrentRouteName(),
      embercom_revision_id: document.querySelector('meta[name="embercom_revision_id"]')?.content,
      platform: this.frontendStatsService.getPlatformName(),
    };
    let structuredEvent = Object.assign(pageLoadStats, extraMetadata);

    this.frontendStatsService.enqueueInteractionMetric(structuredEvent);
    this.frontendStatsService.sendInteractionMetrics();
  }
}
