/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import storage from 'embercom/vendor/intercom/storage';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
// @ts-ignore
import intermoji from '@intercom/intermoji';

/*
  There are 5 skin tone modifiers: https://emojipedia.org/emoji-modifier-sequence/
  We store the skin tone modifier in localstorage as the decimal number format of their unicode codepoint.
  The default emoji colour we see of yellow is when there isn't a skin tone modifier applied.
  A user can choose the default emoji colour, which we store as 'default-yellow-skin-tone' in localstorage.

  The numbers below are the [emoji skin tone modifiers'](https://emojipedia.org/emoji-modifier-sequence) codepoint values.
  They can be derived by running the [codePointAt](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/codePointAt) function on each modifier as a character.
*/
export type NoSkinToneModifier = null;
export type DefaultYellowSkinTone = 'default-yellow-skin-tone';
export enum SkinToneModifiers {
  LightSkinToneModifier = 127995,
  MediumLightSkinToneModifier = 127996,
  MediumSkinToneModifier = 127997,
  MediumDarkSkinToneModifier = 127998,
  DarkSkinToneModifier = 127999,
}

export default class EmojiService extends Service {
  @service declare session: Session;

  skinToneModifierLocalStorageKey = 'skinToneModifier';
  @tracked skinToneModifier: SkinToneModifiers | DefaultYellowSkinTone | NoSkinToneModifier =
    storage.get(this.skinToneModifierLocalStorageKey);

  updateSkinToneModifier(skinToneModifier: SkinToneModifiers | DefaultYellowSkinTone) {
    storage.set(this.skinToneModifierLocalStorageKey, skinToneModifier);
    this.skinToneModifier = skinToneModifier;
  }

  // We can have modified emojis, for example when we apply a skin tone modifier.
  // These emojis are represented as an array of characters. For example: 🕺🏾 = ['🕺', '🏾']
  // An unmodified emoji (e.g. base emoji) is an array of one: the base emoji.
  // The first character in expanded array is the base emoji.
  baseEmojiFor(emoji: string) {
    return Array.from(emoji)[0];
  }

  skinTonedModifiedEmoji(emoji: string) {
    if (
      this.skinToneModifier === ('default-yellow-skin-tone' as DefaultYellowSkinTone) ||
      this.skinToneModifier === null
    ) {
      return emoji;
    }

    let modifiedEmojis = intermoji.getAllModifiedForBaseEmoji(emoji);
    if (modifiedEmojis.length === 0) {
      return emoji;
    }

    let matchedSkintoneModifiedEmojis = modifiedEmojis.filter((modifiedEmoji: string) => {
      return Array.from(modifiedEmoji).some(
        (element) => element.codePointAt(0) === this.skinToneModifier,
      );
    });

    return matchedSkintoneModifiedEmojis.length === 1 ? matchedSkintoneModifiedEmojis[0] : emoji;
  }
}

declare module '@ember/service' {
  interface Registry {
    emojiService: EmojiService;
    'emoji-service': EmojiService;
  }
}
