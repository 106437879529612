/* RESPONSIBLE TEAM: team-workflows */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class Localization extends EmbercomBaseSerializer {
  serialize(localization) {
    let json = super.serialize(...arguments);

    json._isDirty = localization.record.get('hasChanges');

    return json;
  }
}
