/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { equal, not } from '@ember/object/computed';

export default buildValidations({
  state: {
    description: 'State',
    validators: [
      validator('presence', true),
      validator('inclusion', {
        in: [states.draft, states.live, states.scheduled, states.paused, states.suspended],
      }),
    ],
  },
  matchBehavior: {
    validators: [validator('ruleset-is-transactional')],
  },
  predicateGroup: {
    description: 'Audience',
    validators: [validator('presence', true), validator('ruleset-has-valid-predicates')],
  },
  clientPredicateGroup: {
    description: 'Page targeting',
    validators: [
      validator('ruleset-has-no-blank-client-predicates'),
      validator('ruleset-does-not-target-mobile-and-use-page-targeting'),
      validator('ruleset-does-not-use-client-predicates-for-static-match-behavior'),
    ],
  },
  userRolePredicate: {
    description: 'Audience',
    validators: [
      validator('ruleset-has-role-predicate'),
      validator('ruleset-not-static-visitor'),
      validator('ruleset-does-not-target-mobile-only-and-non-users'),
    ],
  },
  goal: {
    description: 'Goal',
    validators: [
      validator('ruleset-has-valid-goal', { disabled: not('model.hasGoal') }),
      validator('control-group-message-has-goal'),
    ],
  },
  rulesetLinks: {
    description: 'Ruleset Link',
    validators: [validator('has-many'), validator('ruleset-links-are-different')],
  },
  scheduledActivation: {
    description: 'Scheduling',
    validators: [validator('ruleset-has-valid-scheduled-activation')],
  },
  scheduledDeactivation: {
    description: 'Scheduling',
    validators: [validator('ruleset-has-valid-scheduled-deactivation')],
  },
  activationIsBeforeDeactivation: {
    description: 'Scheduling',
    validators: [validator('ruleset-activation-is-before-deactivation')],
  },
  isSendable: {
    description: 'Can be sent',
    validators: [validator('ruleset-links-have-valid-matching-locations')],
  },
  rulesetTriggers: {
    description: 'Ruleset Triggers',
    validators: [validator('ruleset-has-valid-ruleset-trigger-predicates')],
  },
  matchSpacingPeriod: {
    description: 'Sending frequency limit',
    validators: [validator('ruleset-has-valid-match-spacing-period')],
  },
  maxMatchCount: {
    validators: [
      validator('number', {
        integer: true,
        gt: 0,
        message: 'Limit for maximum number of sends must be greater than 0',
        disabled: equal('model.maxMatchCount', -1),
      }),
    ],
  },
  recurringSchedule: {
    description: 'Attribute and Calendar based Schedules',
    validators: [
      validator('attribute-based-schedule-day-offset'),
      validator('calendar-based-schedule-offsets'),
    ],
  },
});
