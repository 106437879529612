/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default Service.extend({
  intl: service('intl'),

  localize(keyName, options) {
    if (window.PHRASEAPP_CONFIG) {
      return `${window.PHRASEAPP_CONFIG.prefix}phrase_${keyName}${window.PHRASEAPP_CONFIG.suffix}`;
    } else {
      return sanitizeHtml(this.intl.t(keyName, options));
    }
  },
});
