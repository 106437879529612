/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';

export default class TagService extends Service {
  @service appService;

  get app() {
    return this.appService.app;
  }

  async getMessagesTags(app) {
    let response = await ajax({
      type: 'GET',
      url: '/ember/tags/messages_tag_ids',
      data: {
        app_id: app.id,
      },
    });

    let messagesTagIds = response.messages_tag_ids;
    return app.tags.filter((tag) => {
      return messagesTagIds.includes(parseInt(tag.id, 10));
    });
  }

  @task
  *fetchTagDependencies(tag) {
    let dependencies = {
      dependentSegments: [],
      dependentCustomBots: [],
      dependentBehaviors: [],
      dependentContents: [],
      dependentArticles: [],
      dependentTagRules: [],
      dependentPriorityRules: [],
      dependentSlaRules: [],
      dependentAssignmentRules: [],
      dependentWorkflows: [],
      dependentRulesets: [],
      dependentMacros: [],
    };

    try {
      let response = yield ajax({
        url: `/ember/tags/${tag.id}/dependent_objects`,
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });

      dependencies = {
        dependentSegments: response.table.dependent_segments,
        dependentCustomBots: response.table.dependent_custom_bots,
        dependentAnswers: response.table.dependent_answers,
        dependentAnswerContents: response.table.dependent_answer_contents,
        dependentBehaviors: response.table.dependent_behaviors,
        dependentContents: response.table.dependent_contents,
        dependentArticles: response.table.dependent_articles,
        dependentTagRules: response.table.dependent_tag_rules,
        dependentPriorityRules: response.table.dependent_priority_rules,
        dependentSlaRules: response.table.dependent_sla_rules,
        dependentAssignmentRules: response.table.dependent_assignment_rules,
        dependentWorkflows: response.table.dependent_workflows,
        dependentRulesets: response.table.dependent_rulesets,
        dependentMacros: response.table.dependent_macros,
      };
    } catch (e) {
      if (e.errorThrown !== 'Not Found') {
        throw e;
      }
    }

    return dependencies;
  }

  hasDependencies(dependencies) {
    if (!dependencies) {
      return false;
    }

    return (
      this._hasDependency('dependentSegments', dependencies) ||
      this._hasDependency('dependentCustomBots', dependencies) ||
      this._hasDependency('dependentAnswers', dependencies) ||
      this._hasDependency('dependentAnswerContents', dependencies) ||
      this._hasDependency('dependentContents', dependencies) ||
      this._hasDependency('dependentArticles', dependencies) ||
      this._hasDependency('dependentTagRules', dependencies) ||
      this._hasDependency('dependentPriorityRules', dependencies) ||
      this._hasDependency('dependentSlaRules', dependencies) ||
      this._hasDependency('dependentAssignmentRules', dependencies) ||
      this._hasDependency('dependentWorkflows', dependencies) ||
      this._hasDependency('dependentRulesets', dependencies) ||
      this._hasDependency('dependentMacros', dependencies)
    );
  }

  _hasDependency(name, dependencies) {
    return dependencies[name]?.length > 0;
  }
}
