/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Session from './session';
import { post } from 'embercom/lib/ajax';
import SearchableDocument, {
  type SearchableDocumentWireFormat,
} from 'embercom/objects/inbox/searchable-document';
import {
  type SearchableType,
  type SearchableTypeString,
} from 'embercom/models/data/inbox/searchable-types';
import { type QuickSearchContexts } from 'embercom/objects/inbox/macro';
import cached from 'embercom/lib/cached-decorator';

type SearchByTypeWireFormat = {
  total: number;
  results: Array<SearchableDocumentWireFormat>;
};
export type SearchByTypeResponse = {
  total: number;
  results: Array<SearchableDocument>;
};

export type SearchMultipleTypesWireFormat = Partial<
  Record<SearchableTypeString, SearchByTypeWireFormat>
>;
export type SearchMultipleTypesResponse = Partial<Record<SearchableType, SearchByTypeResponse>>;

export type QuickSearchExcludeEntities = {
  type: SearchableDocument['entityType'];
  ids: SearchableDocument['entityId'][];
};

export type SearchByTypeArgs = {
  entityType: SearchableType;
  searchTerm?: string;
  pageFrom?: number;
  perPage?: number;
  currentSearchContext?: Array<QuickSearchContexts>;
  fields?: object;
};

interface SearchMultipleTypesArgs {
  entityTypes?: Array<SearchableType>;
  searchTerm?: string;
  size?: number;
  currentSearchContext?: Array<QuickSearchContexts>;
  fields?: object;
  excludeEntities?: QuickSearchExcludeEntities;
}
export default class QuickSearchService extends Service {
  @service declare session: Session;

  @cached({ max: 100, ttl: 1000 * 60 * 60, keyGenerator: JSON.stringify })
  async searchByType(inputs: SearchByTypeArgs): Promise<SearchByTypeResponse> {
    let json = (await post('/ember/inbox/quick_search/search_by_type', {
      app_id: this.session.workspace.id,
      entity_type: inputs.entityType,
      search_term: inputs.searchTerm,
      page_from: inputs.pageFrom,
      per_page: inputs.perPage,
      current_search_context: inputs.currentSearchContext,
      fields: inputs.fields,
    })) as SearchByTypeWireFormat;

    let response = {
      total: json.total,
      results: json.results.map((result) => SearchableDocument.deserialize(result)),
    };

    return response;
  }

  @cached({ max: 100, ttl: 1000 * 60 * 60, keyGenerator: JSON.stringify })
  async searchMultipleTypes(
    inputs?: SearchMultipleTypesArgs,
  ): Promise<SearchMultipleTypesResponse> {
    let json = (await post('/ember/inbox/quick_search/search_multiple_entity_types', {
      app_id: this.session.workspace.id,
      entity_types: inputs?.entityTypes,
      search_term: inputs?.searchTerm,
      size: inputs?.size,
      current_search_context: inputs?.currentSearchContext,
      fields: inputs?.fields,
      exclude_entities: inputs?.excludeEntities,
    })) as SearchMultipleTypesWireFormat;

    return this.serializeMultipleTypesWireFormat(json);
  }

  private serializeMultipleTypesWireFormat(
    json: SearchMultipleTypesWireFormat,
  ): SearchMultipleTypesResponse {
    return Object.keys(json).reduce((accumulator, stringEntityType: SearchableTypeString) => {
      let entityType = Number(stringEntityType) as SearchableType;
      let entityJson = json[stringEntityType];
      if (entityJson) {
        accumulator[entityType] = {
          total: entityJson.total,
          results: entityJson.results.map((result) => SearchableDocument.deserialize(result)),
        };
      }
      return accumulator;
    }, {} as SearchMultipleTypesResponse);
  }
}

declare module '@ember/service' {
  interface Registry {
    quickSearchService: QuickSearchService;
    'quick-search': QuickSearchService;
  }
}
