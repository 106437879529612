/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { HotkeyID } from './HotkeyID';
import platform from 'embercom/lib/browser-platform';
import { type HotkeysMap } from '../inbox-hotkeys';
import { alternateKeysLetter, letterKeyShortcut } from './common';

export function frAzertyHotkeyMapping(modifierKey: string, altKey: string): HotkeysMap {
  return {
    [HotkeyID.CommandK]: {
      id: HotkeyID.CommandK,
      ...letterKeyShortcut('k', 'cmd'),
      display: [modifierKey, 'k'],
    },
    [HotkeyID.Send]: {
      id: HotkeyID.Send,
      keys: 'cmd+Enter',
      display: [modifierKey, 'Enter'],
    },
    [HotkeyID.Close]: {
      id: HotkeyID.Close,
      ...letterKeyShortcut('y', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'Y'],
    },
    [HotkeyID.Reopen]: {
      id: HotkeyID.Reopen,
      ...letterKeyShortcut('o', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'O'],
    },
    [HotkeyID.SnoozeUntilTomorrow]: {
      id: HotkeyID.SnoozeUntilTomorrow,
      ...letterKeyShortcut('z', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'Z'],
    },
    [HotkeyID.SendAndClose]: {
      id: HotkeyID.SendAndClose,
      keys: 'cmd+shift+Enter',
      display: [modifierKey, 'Shift', 'Enter'],
    },
    [HotkeyID.SendAndSnooze]: {
      id: HotkeyID.SendAndSnooze,
      keys: 'cmd+alt+Enter',
      display: [modifierKey, altKey, 'Enter'],
    },
    [HotkeyID.SendAndCrossPost]: {
      id: HotkeyID.SendAndCrossPost,
      keys: 'cmd+shift+Enter',
      display: [modifierKey, 'Shift', 'Enter'],
    },
    [HotkeyID.ChangePriority]: {
      id: HotkeyID.ChangePriority,
      ...letterKeyShortcut('p'),
      display: ['P'],
    },
    [HotkeyID.SnoozeAction]: {
      id: HotkeyID.SnoozeAction,
      ...letterKeyShortcut('z'),
      display: ['Z'],
    },
    [HotkeyID.AssignToAction]: {
      id: HotkeyID.AssignToAction,
      ...letterKeyShortcut('a'),
      display: ['A'],
    },
    [HotkeyID.JumpToAction]: {
      id: HotkeyID.JumpToAction,
      ...letterKeyShortcut('g'),
      display: ['G'],
    },
    [HotkeyID.Emoji]: {
      id: HotkeyID.Emoji,
      keys: ':',
      display: [':'],
    },
    [HotkeyID.ComposeReply]: {
      id: HotkeyID.ComposeReply,
      ...letterKeyShortcut('r'),
      display: ['R'],
    },
    [HotkeyID.ComposeNote]: {
      id: HotkeyID.ComposeNote,
      ...letterKeyShortcut('n'),
      display: ['N'],
    },
    [HotkeyID.UseMacro]: {
      id: HotkeyID.UseMacro,
      keys: '#',
      alternateKeys: [
        ...alternateKeysLetter('m'),
        ...alternateKeysLetter('s'),
        'alt+#',
        'alt+shift+#',
      ],
      display: ['#'],
    },
    [HotkeyID.InsertGif]: {
      id: HotkeyID.InsertGif,
      ...letterKeyShortcut('g', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'G'],
    },
    [HotkeyID.InsertImage]: {
      id: HotkeyID.InsertImage,
      ...letterKeyShortcut('i', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'I'],
    },
    [HotkeyID.InsertArticle]: {
      id: HotkeyID.InsertArticle,
      ...letterKeyShortcut('h', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'H'],
    },
    [HotkeyID.AddAttachment]: {
      id: HotkeyID.AddAttachment,
      ...letterKeyShortcut('a', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'A'],
    },
    [HotkeyID.NewConversation]: {
      id: HotkeyID.NewConversation,
      ...letterKeyShortcut('c'),
      display: ['C'],
    },
    [HotkeyID.TagLastUserComment]: {
      id: HotkeyID.TagLastUserComment,
      keys: 'shift+KeyT',
      display: ['Shift', 'T'],
    },
    [HotkeyID.SwitchView]: {
      id: HotkeyID.SwitchView,
      ...letterKeyShortcut('l'),
      display: ['L'],
    },
    [HotkeyID.ToggleLeftNav]: {
      id: HotkeyID.ToggleLeftNav,
      keys: '(',
      display: ['('],
    },
    [HotkeyID.ToggleConversationDetailsSidebar]: {
      id: HotkeyID.ToggleConversationDetailsSidebar,
      keys: ')',
      display: [')'],
    },
    [HotkeyID.TagUser]: {
      id: HotkeyID.TagUser,
      keys: 't',
      display: ['t'],
    },
    [HotkeyID.KeyboardShortcuts]: {
      id: HotkeyID.KeyboardShortcuts,
      keys: '?',
      display: ['?'],
    },
    [HotkeyID.ToggleEvents]: {
      id: HotkeyID.ToggleEvents,
      ...letterKeyShortcut('e', platform.isMac ? 'cmd' : 'cmd+shift'),
      display: platform.isMac ? [modifierKey, 'E'] : [modifierKey, 'Shift', 'E'],
    },
    [HotkeyID.NavigateArrowUp]: {
      id: HotkeyID.NavigateArrowUp,
      keys: 'ArrowUp',
      display: ['↑'],
    },
    [HotkeyID.NavigateArrowDown]: {
      id: HotkeyID.NavigateArrowDown,
      keys: 'ArrowDown',
      display: ['↓'],
    },
    [HotkeyID.NavigateUp]: {
      id: HotkeyID.NavigateUp,
      ...letterKeyShortcut('j'),
      display: ['J'],
    },
    [HotkeyID.NavigateDown]: {
      id: HotkeyID.NavigateDown,
      ...letterKeyShortcut('k'),
      display: ['K'],
    },
    [HotkeyID.JumpToBottom]: {
      id: HotkeyID.JumpToBottom,
      keys: 'cmd+ArrowDown',
      display: [modifierKey, '↓'],
    },
    [HotkeyID.JumpToTop]: {
      id: HotkeyID.JumpToTop,
      keys: 'cmd+ArrowUp',
      display: [modifierKey, '↑'],
    },
    [HotkeyID.PageDown]: {
      id: HotkeyID.JumpToTop,
      keys: 'ArrowDown',
      display: ['↓'],
    },
    [HotkeyID.PageUp]: {
      id: HotkeyID.PageUp,
      keys: 'ArrowUp',
      display: ['↑'],
    },
    [HotkeyID.Undo]: {
      id: HotkeyID.Undo,
      ...letterKeyShortcut('z', 'cmd'),
      display: [modifierKey, 'z'],
    },
    [HotkeyID.ConvertToTicket]: {
      id: HotkeyID.ConvertToTicket,
      ...letterKeyShortcut('b', 'cmd'),
      display: [modifierKey, 'b'],
    },
    [HotkeyID.MergeInto]: {
      id: HotkeyID.MergeInto,
      ...letterKeyShortcut('m', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'M'],
    },
    [HotkeyID.TriggerWorkflowConnectorAction]: {
      id: HotkeyID.TriggerWorkflowConnectorAction,
      ...letterKeyShortcut('j', 'cmd+shift'),
      display: [modifierKey, 'Shift', 'J'],
    },
    [HotkeyID.Impersonate]: {
      id: HotkeyID.Impersonate,
      ...letterKeyShortcut('i', 'shift'),
      display: ['Shift', 'I'],
    },
    [HotkeyID.ToggleAll]: {
      id: HotkeyID.ToggleAll,
      ...letterKeyShortcut('a', 'cmd'),
      display: [modifierKey, 'a'],
    },
    [HotkeyID.ToggleSelect]: {
      id: HotkeyID.ToggleSelect,
      ...letterKeyShortcut('x'),
      display: ['X'],
    },
    [HotkeyID.ToggleDown]: {
      id: HotkeyID.ToggleDown,
      keys: 'shift+ArrowDown',
      display: ['Shift', '↓'],
    },
    [HotkeyID.ToggleUp]: {
      id: HotkeyID.ToggleUp,
      keys: 'shift+ArrowUp',
      display: ['Shift', '↑'],
    },
    [HotkeyID.Search]: {
      id: HotkeyID.Search,
      ...letterKeyShortcut('p', 'cmd'),
      display: [modifierKey, 'p'],
    },
    [HotkeyID.AiAssist]: {
      id: HotkeyID.AiAssist,
      ...letterKeyShortcut('j', 'cmd'),
      display: [modifierKey, 'j'],
    },
    [HotkeyID.UpdateTicketState]: {
      id: HotkeyID.UpdateTicketState,
      ...letterKeyShortcut('s', 'cmd'),
      display: [modifierKey, 's'],
    },
    [HotkeyID.ResolveAndCloseTicketState]: {
      id: HotkeyID.ResolveAndCloseTicketState,
      ...letterKeyShortcut('s', 'cmd+shift'),
      display: [modifierKey, 'Shift', 's'],
    },
    [HotkeyID.OpenKnowledgeBasePanel]: {
      id: HotkeyID.OpenKnowledgeBasePanel,
      ...letterKeyShortcut('b'),
      display: ['b'],
    },
    [HotkeyID.OpenCopilot]: {
      id: HotkeyID.OpenCopilot,
      keys: 'cmd+Slash',
      display: [modifierKey, '='],
    },
    [HotkeyID.OpenConversationDetails]: {
      id: HotkeyID.OpenConversationDetails,
      keys: 'cmd+Period',
      display: [modifierKey, ':'],
    },
  };
}
