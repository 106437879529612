/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

function hasPredicates(predicateGroup) {
  return predicateGroup?.predicates?.length;
}

const WorkflowConditionalBranchValid = BaseValidator.extend({
  validate(predicateGroup, options, model) {
    // last control must have no predicates so it always matches as a fallback
    if (model === model.step.controls.lastObject) {
      if (hasPredicates(predicateGroup)) {
        return 'There is no fallback path to jump to';
      }
    } else {
      //predicateGroup must exist and contain valid predicates
      if (!hasPredicates(predicateGroup)) {
        return 'This path contains empty jump conditions';
      }
      let predicates = predicateGroup.predicates;
      for (let j = 0; j < predicates.length; j++) {
        if (!predicates.objectAt(j).isValid) {
          return 'This path contains invalid jump conditions';
        }
      }
    }
    return true;
  },
});

WorkflowConditionalBranchValid.reopenClass({
  getDependentsFor() {
    return ['model.predicateGroup.isValid'];
  },
});

export default WorkflowConditionalBranchValid;
