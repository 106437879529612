/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  parts: [
    validator('inline', {
      validate(value, options, model) {
        if (model.behavior.botMode !== 'bot_only') {
          return true;
        }

        let allValidBlocks = model.parts.content.flatMap(
          (part) => part.get('validations.attrs.blocks').isValid,
        );

        if (
          model.parts.length === 0 ||
          (allValidBlocks.length > 0 && allValidBlocks.some((b) => !b))
        ) {
          return `Finish your message in ${model.name}`;
        }

        return true;
      },
      dependentKeys: ['model.parts.@each.blocks.[]', 'model.behavior.botMode'],
    }),
    validator('has-many'),
  ],
});
