/* RESPONSIBLE TEAM: team-frontend-tech */
/* eslint-disable @intercom/intercom/no-window-atob */
import { isNone } from '@ember/utils';
import { LEAD_ROLE_PREDICATE, USER_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
import { captureException } from 'embercom/lib/sentry';

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
let stringToB64 = function (str) {
  let escapedString = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(`0x${p1}`);
  });
  return window.btoa(escapedString);
};

let b64ToString = function (b64String) {
  let percentEncodedString = window
    .atob(b64String)
    .split('')
    .map(function (char) {
      let charCode = `00${char.charCodeAt(0).toString(16)}`;
      return `%${charCode.slice(-2)}`;
    })
    .join('');
  return decodeURIComponent(percentEncodedString);
};

let appSection = function (userOrCompany) {
  return userOrCompany.isCompany ? 'companies' : 'users';
};

let filterParams = function (userOrCompany, filterObject) {
  if (!userOrCompany.isCompany && filterObject.predicates) {
    let rolePredicate = userOrCompany.is_anonymous ? LEAD_ROLE_PREDICATE : USER_ROLE_PREDICATE;
    filterObject.predicates.push(rolePredicate);
  }
  return stringToB64(JSON.stringify(filterObject));
};

let url = function (userOrCompany, filterParams) {
  // TODO(JO): these should be changed to 'usersOrContacts' after acquire launch. all will still work but looks :(
  let allOrContacts = userOrCompany.is_anonymous ? 'all-leads' : 'all';
  let app_id = userOrCompany.isAdmin ? userOrCompany.current_app_id : userOrCompany.app_id;
  return `/a/apps/${app_id}/${appSection(userOrCompany)}/segments/${allOrContacts}:${filterParams}`;
};

let tagUrl = function (tag, type) {
  let tagId = tag.id;
  let isCompanyType = type === 'company';
  let manualTagAttribute = isCompanyType ? 'company.manual_tag_ids' : 'manual_tag_ids';
  let filterObject = { predicates: [] };
  let urlFragment = isCompanyType ? 'companies' : `${type}s`;
  if (!isCompanyType) {
    filterObject.predicates.push(USER_ROLE_PREDICATE);
  }
  filterObject.predicates.push({
    type: 'manual_tag',
    attribute: manualTagAttribute,
    comparison: 'eq',
    value: `${tagId}`,
  });
  let encodedTagPredicate = stringToB64(JSON.stringify(filterObject));

  let appId = tag.app_id || tag.appId;
  return `/a/apps/${appId}/${urlFragment}/segments/all:${encodedTagPredicate}`;
};

let filterUrl = function (appId, isTargetingLeads, filterObject) {
  let allOrContacts = isTargetingLeads ? 'all-leads' : 'all';
  let rolePredicate = isTargetingLeads ? LEAD_ROLE_PREDICATE : USER_ROLE_PREDICATE;
  filterObject.predicates.push(rolePredicate);
  let encodedMessagePredicate = stringToB64(JSON.stringify(filterObject));
  return `/a/apps/${appId}/users/segments/${allOrContacts}:${encodedMessagePredicate}`;
};

let B64FilterParamEncoder = {
  b64Encode(str) {
    try {
      return stringToB64(str);
    } catch (e) {
      // btoa encoding failed
      captureException(e, { fingerprint: ['b64-filter-param-encoder', 'b64Encode'] });
      console.log(e);
      return null;
    }
  },
  b64Decode(str) {
    try {
      return b64ToString(str);
    } catch (e) {
      // atob decoding failed
      captureException(e, { fingerprint: ['b64-filter-param-encoder', 'b64Decode'] });
      console.log(e);
      return null;
    }
  },
  filterUrl(userOrCompany, attribute, rawValue) {
    try {
      if (attribute && !isNone(rawValue)) {
        return url(
          userOrCompany,
          filterParams(userOrCompany, attribute.asPredicateFilterObject(rawValue)),
        );
      }
    } catch (e) {
      // btoa encoding failed
      captureException(e, { fingerprint: ['b64-filter-param-encoder', 'filterUrl'] });
      console.log(e);
    }
    // TODO(JO): these should be changed to 'users' after acquire launch
    return `/a/apps/${userOrCompany.app_id}/${appSection(userOrCompany)}/segments/all`;
  },
  filterUrlFromPredicate(userOrCompany, attribute, predicate) {
    try {
      if (predicate) {
        if (userOrCompany.isCompany) {
          predicate.attribute = `company.${predicate.attribute}`;
        }
        let filterObject = { predicates: [predicate] };
        return url(userOrCompany, filterParams(userOrCompany, filterObject));
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['b64-filter-param-encoder', 'filterUrlFromPredicate'],
      });
      console.log(e);
    }
  },
  filterUrlFromPredicates(userOrCompany, predicates) {
    try {
      if (predicates) {
        return url(userOrCompany, filterParams(userOrCompany, predicates));
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['b64-filter-param-encoder', 'filterUrlFromPredicates'],
      });
      console.log(e);
    }
  },
  filterUrlFromMessagePredicates(appId, isTargetingLeads, predicates) {
    try {
      if (predicates) {
        return filterUrl(appId, isTargetingLeads, predicates);
      }
    } catch (e) {
      console.log(e);
      captureException(e, {
        fingerprint: ['b64-filter-param-encoder', 'filterUrlFromMessagePredicates'],
      });
    }
  },
  tagUrl(tag, type) {
    try {
      return tagUrl(tag, type);
    } catch (e) {
      console.log(e);
      captureException(e, { fingerprint: ['b64-filter-param-encoder', 'tagUrl'] });
    }
  },
  segmentIdentifierWithFilters(segment) {
    let predicates = segment.predicates;
    let encodedFilterParams = this.b64Encode(JSON.stringify({ predicates }));
    let identifierWithFilters = `${segment.identifier}:${encodedFilterParams}`;
    return identifierWithFilters;
  },
};
export default B64FilterParamEncoder;
