/* RESPONSIBLE TEAM: team-frontend-tech */
import { warn } from '@ember/debug';
import moment from 'moment-timezone';

import formats from 'embercom/formats';

const round = Math.round;

const RELATIVE_TIME_THRESHOLDS = {
  ss: 44, // a few seconds to seconds
  s: 45, // seconds to minute
  m: 45, // minutes to hour
  h: 22, // hours to day
  d: 26, // days to month
  M: 11, // months to year
};

const THRESHOLDS_REFRESH_SECONDS = {
  s: 5, // auto refresh every 5 seconds
  ss: 5,
  m: 60, // auto refresh every minute
  mm: 60,
  h: 60 * 60, // auto refresh every hour
  hh: 60 * 60,
};

export function isUsingLegacyFormatRelativeApi(options) {
  return options.unit !== undefined;
}

export function getRelativeTimeFormatOptions(options) {
  let { format = 'default', ...rest } = options;

  let namedFormats = formats.relativeTime;

  let defaultFormat = namedFormats.default;

  return {
    ...defaultFormat,
    ...namedFormats[format],
    relativeTo: moment.utc().toISOString(),
    ...rest,
  };
}

export function determineRelativeThreshold(dateTimeString, relativeTo, thresholds = {}) {
  let { ss, ...rest } = thresholds;

  if (Object.keys(rest).length > 0) {
    warn(
      'You have overridden one or more relative time thresholds other than `ss`. Currently, we do not support overriding those thresholds. Your overrides have been ignored.. Please refer to our [localization guide](https://go.inter.com/product-localization#dbd353c5a752405bacd7a53b4fe7b0bb) for more info',
      false,
      {
        id: 'intl.override-relative-thresholds',
      },
    );

    thresholds = { ss };
  }

  thresholds = {
    ...RELATIVE_TIME_THRESHOLDS,
    ...thresholds,
  };

  let diff = moment(dateTimeString).diff(relativeTo);
  let duration = moment.duration(diff).abs();
  let seconds = round(duration.as('s'));
  let minutes = round(duration.as('m'));
  let hours = round(duration.as('h'));
  let days = round(duration.as('d'));
  let months = round(duration.as('M'));
  let years = round(duration.as('y'));

  let [key, number = 1] = (seconds <= thresholds.ss && ['s', seconds]) ||
    (seconds < thresholds.s && ['ss', seconds]) ||
    (minutes <= 1 && ['m']) ||
    (minutes < thresholds.m && ['mm', minutes]) ||
    (hours <= 1 && ['h']) ||
    (hours < thresholds.h && ['hh', hours]) ||
    (days <= 1 && ['d']) ||
    (days < thresholds.d && ['dd', days]) ||
    (months <= 1 && ['M']) ||
    (months < thresholds.M && ['MM', months]) ||
    (years <= 1 && ['y']) || ['yy', years];

  return { key, number, diff };
}

export function calculateRefreshSeconds([dateTimeString], options) {
  let { relativeTo, thresholds } = getRelativeTimeFormatOptions(options);
  let { key } = determineRelativeThreshold(dateTimeString, relativeTo, thresholds);

  return THRESHOLDS_REFRESH_SECONDS[key];
}

// export default function formatRelative(dateTimeString, options, t) {
export default function formatRelative(value, options = {}) {
  let formatOptions = getRelativeTimeFormatOptions(options);

  let opts = {
    locale: this.primaryLocale,
    ...formatOptions,
  };

  let { style, withSuffix, relativeTo, locale, thresholds } = opts;

  let { key, number, diff } = determineRelativeThreshold(value, relativeTo, thresholds);

  let timeString = this.t(`relative-time-formatting.${style}.${key}`, { count: number, locale });

  if (key === 's' && style !== 'long') {
    withSuffix = false;
  }

  if (withSuffix) {
    if (diff > 0) {
      return this.t(`relative-time-formatting.future`, { timeString, locale });
    }

    return this.t(`relative-time-formatting.past`, { timeString, locale });
  }

  return timeString;
}
