/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';

const RulesetActivationIsBeforeDeactivation = BaseValidator.extend({
  validate(value, options, model) {
    if (isEmpty(model.scheduledActivation) || isEmpty(model.scheduledDeactivation)) {
      return true;
    }

    let scheduledActivationDate = moment(model.scheduledActivation.changeAt);
    let scheduledDeactivationDate = moment(model.scheduledDeactivation.changeAt);

    if (scheduledDeactivationDate <= scheduledActivationDate) {
      return 'Choose a start date that is before the stop date';
    }

    return true;
  },
});

RulesetActivationIsBeforeDeactivation.reopenClass({
  getDependentsFor() {
    return [
      'model.scheduledActivation',
      'model.scheduledActivation.changeAt',
      'model.scheduledDeactivation',
      'model.scheduledDeactivation.changeAt',
    ];
  },
});

export default RulesetActivationIsBeforeDeactivation;
