/* RESPONSIBLE TEAM: team-channels */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  attrs: {
    repository: {
      key: 'name',
    },
  },
  normalize(modelClass, resourceHash) {
    let result = this._super(modelClass, resourceHash);
    Object.assign(result.data.attributes, resourceHash, {
      fullName: resourceHash.repository,
    });

    return result;
  },
});
