/* RESPONSIBLE TEAM: team-help-desk-experience */

import { request } from 'embercom/lib/inbox/requests';
import Service, { inject as service } from '@ember/service';
import type Session from './session';
import MessengerApp from 'embercom/objects/inbox/messenger-app';
import { type Canvas, type MessengerCardBlock } from '@intercom/interblocks.ts';
import { post } from 'embercom/lib/ajax';
import { type ComponentValues } from '@intercom/interblocks.ts/dist/types/view/canvas-view/canvas-view';
import { trackedFunction } from 'ember-resources/util/function';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';

export interface ConfigureCardResponse {
  new_canvas?: Canvas;
  results?: object;
}

export default class MessengerApps extends Service {
  @service declare session: Session;

  @dedupeTracked isToursLoaded = false;

  get inserterApps(): MessengerApp[] {
    let apps = this.appsLoader.value || [];
    return apps.filter(
      (app: MessengerApp) => app.installed && app.showInInserter && !app.isIntercomTours,
    );
  }

  get toursApp(): MessengerApp | undefined | null {
    return this.toursLoader.value;
  }

  appsLoader = trackedFunction(this, async (): Promise<MessengerApp[]> => {
    let response = await request(
      `/ember/messenger_apps?restrict_to=conversations&app_id=${this.session.workspace.id}`,
    );
    let json = await response.json();
    return json.map(MessengerApp.deserialize);
  });

  toursLoader = trackedFunction(this, async (): Promise<MessengerApp | undefined> => {
    this.isToursLoaded = false;
    try {
      let response = await request(
        `/ember/messenger_apps?app_package_id=${MessengerApp.intercomToursPackageId}&app_id=${this.session.workspace.id}`,
      );
      let json = await response.json();
      let apps = json.map(MessengerApp.deserialize);
      let [toursApp] = apps;
      return toursApp;
    } finally {
      this.isToursLoaded = true;
    }
  });

  async initializeCard(params: {
    app_id: string;
    card_creation_params: object;
    context: object;
  }): Promise<MessengerCardBlock> {
    return post(`/ember/messenger_apps/initialize_card`, params);
  }

  async configureCard(params: {
    admin_id: number;
    app_id: string;
    messenger_app_id: string;
    component_id?: string;
    context: object;
    current_canvas?: Canvas;
    input_values?: ComponentValues;
  }): Promise<ConfigureCardResponse> {
    return post('/ember/messenger_apps/configure_card', params);
  }

  async initializeMessengerApp(params: {
    app_id: string;
    inbox_app_id: number;
    context: {
      conversation_id: number;
    };
    card_creation_params: {
      canvas_creation_options: Record<string, unknown>;
    };
  }): Promise<any> {
    return post('/ember/inbox_apps/initialize_messenger_app', params);
  }
}

declare module '@ember/service' {
  interface Registry {
    messengerApps: MessengerApps;
    'messenger-apps': MessengerApps;
  }
}
