/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isBlank } from '@ember/utils';
import { isValidUrlWithProtocol } from 'embercom/lib/url-validator';
import { actionOptions } from 'embercom/models/banners/banner';

const BannerActionUrlValid = BaseValidator.extend({
  validate(value, options, model) {
    let localizedBannerContent = model.localizedBannerContents.firstObject;
    if (model.action !== actionOptions.open_a_url) {
      return true;
    }

    let actionHref = localizedBannerContent.actionHref;

    if (isBlank(actionHref)) {
      return 'Enter a URL';
    }

    // Skip the URL validation if it contains a CDA since we can't reliably validate.
    // Avoids cases like https://github.com/intercom/intercom/issues/138897
    if (actionHref.includes('{{') && actionHref.includes('}}')) {
      return true;
    }

    if (!isValidUrlWithProtocol(actionHref)) {
      return 'Enter a valid URL';
    }

    return true;
  },
});

BannerActionUrlValid.reopenClass({
  getDependentsFor() {
    return ['model.action', 'model.localizedBannerContents.firstObject.actionHref'];
  },
});

export default BannerActionUrlValid;
