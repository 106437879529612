/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const OR_PREDICATE_LIMIT = 10;

export default BaseValidator.extend({
  validate(value) {
    if (value.get('userAddedPredicates.length') > OR_PREDICATE_LIMIT && value.get('isOrMode')) {
      return `Limit audience rules to ${OR_PREDICATE_LIMIT} (because you chose "match any")`;
    }
    return true;
  },
});
