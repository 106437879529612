/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { get } from '@ember/object';
import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const PushMessageVariationContent = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isMobilePush')) {
      if (isBlank(get(value, 'firstObject.text'))) {
        return 'Add a title to your message';
      }
      if (isBlank(get(value, 'lastObject.text'))) {
        return 'Finish writing your message';
      }
    }
    return true;
  },
});

PushMessageVariationContent.reopenClass({
  getDependentsFor() {
    return ['model.isMobilePush'];
  },
});

export default PushMessageVariationContent;
