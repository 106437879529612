/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import { getOwner, setOwner } from '@ember/application';
import Evented from '@ember/object/evented';
import NexusIntercom from 'embercom/vendor/intercom/nexus';
import type Session from 'embercom/services/session';
import { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { type InboxType } from 'embercom/models/data/inbox/inbox-types';
import { type Notification, type Opts as SnackbarOpts } from 'embercom/services/snackbar';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { createMachine, interpret } from 'xstate';
import type { InterpreterFrom } from 'xstate';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import Metrics from 'embercom/models/metrics';
import type TracingService from 'embercom/services/tracing';
import { type BlockList } from '@intercom/interblocks.ts';

export enum NexusEventName {
  AdminAwayReassignModeChangeEvent = 'AdminAwayReassignModeChange',
  AdminIsViewingConversation = 'AdminIsViewingConversation',
  AdminStoppedViewingConversation = 'AdminStoppedViewingConversation',
  ConversationPartToken = 'ConversationPartToken',
  CountersUpdated = 'CountersUpdated',
  InboxContentsChanged = 'InboxContentsChanged',
  ThreadAssigned = 'ThreadAssigned',
  ThreadReopened = 'ThreadReopened',
  ThreadCreated = 'ThreadCreated',
  ThreadClosed = 'ThreadClosed',
  ThreadUpdated = 'ThreadUpdated',
  ThreadSnoozed = 'ThreadSnoozed',
  ThreadUnsnoozed = 'ThreadUnsnoozed',
  ThreadIngested = 'ThreadIngested',
  ConversationAttributesUpdated = 'ConversationAttributesUpdated',
  ConversationPartUpdated = 'ConversationPartUpdated',
  AdminIsTyping = 'AdminIsTyping',
  AdminIsTypingANote = 'AdminIsTypingANote',
  UserIsTyping = 'UserIsTyping',
  UserMerged = 'UserMerged',
  ConversationSeen = 'ConversationSeen',
  TicketStateUpdated = 'TicketStateUpdated',
  Reply = 'Reply',
  ExternalReplySendStateUpdated = 'ExternalReplySendStateUpdated',
  MacroExecutionError = 'MacroExecutionError',
  DebugAdminMessage = 'DebugAdminMessage',
  UserContentSeenByAdmin = 'UserContentSeenByAdmin',
  SideConversationStarted = 'SideConversationStarted',
  SideConversationUpdated = 'SideConversationUpdated',
  SideConversationRead = 'SideConversationRead',
  TrackerTicketLinkedConversationsUpdated = 'TrackerTicketLinkedConversationsUpdated',
  BulkReportsLinked = 'BulkReportsLinked',
  InboundCallbackRequest = 'InboundCallbackRequest',
  ConferenceParticipantAdded = 'ConferenceParticipantAdded',
  ConferenceParticipantRemoved = 'ConferenceParticipantRemoved',
  ConferenceParticipantFailedToJoin = 'ConferenceParticipantFailedToJoin',
}

type NexusEventBase = {
  clientVersion: string;
  eventData: unknown;
  eventGuid: string;
  eventName: NexusEventName;
  ['nx.Destination']: Array<string>;
  ['nx.Topics']: Array<string> | null;
  platform: string;
  receivedAt: number;
};

export type NexusEvent = NexusEventBase & {
  eventData: $TSFixMe; // should be unknown?
};

export enum NexusStatusEvents {
  Reconnected = 'Reconnected',
}

export type AdminIsViewingConversationEvent = NexusEventBase & {
  eventName: NexusEventName.AdminIsViewingConversation;
  eventData: {
    conversationID: number;
    admin: AdminSummaryWireFormat;
    sessionUUID: string;
  };
};

export type AdminStoppedViewingConversationEvent = NexusEventBase & {
  eventName: NexusEventName.AdminStoppedViewingConversation;
  eventData: {
    conversationID: number;
    admin: AdminSummaryWireFormat;
    sessionUUID: string;
  };
};

export enum ConversationPartTokenPartType {
  Partial = 'partial_fin_answer',
  Complete = 'complete_fin_answer',
  NoAnswer = 'no_fin_answer',
  RelatedContent = 'related_content',
  Error = 'error',
  FallbackRelatedContent = 'fallback_related_content',
}
export interface ConversationPartToken {
  part_type: ConversationPartTokenPartType;
  token_sequence_index: number;
  blocks: BlockList;
}

export enum CountersUpdatedSource {
  Database = 'db',
  Elasticsearch = 'es',
}

export type CountersUpdatedEvent = NexusEventBase & {
  eventName: NexusEventName.CountersUpdated;
  eventData: {
    counters: Array<[number | string, number]>;
    treat_missing_assignees_as_zero: boolean | null;
    source: CountersUpdatedSource;
  };
};

export type InboxContentsChangedEvent = NexusEventBase & {
  eventName: NexusEventName.InboxContentsChanged;
  eventData: {
    inboxes: Array<{ type: InboxType; id?: string }>;
  };
};

export type DebugAdminMessageEvent = NexusEventBase & {
  eventName: NexusEventName.DebugAdminMessage;
  eventData: {
    seq_no: number;
    report_id: string;
  };
};

type MessageThreadData = {
  subType: string;
  lastActivityOwnerId: number | null;
  lastActivityPreview: string | null;
  conversationId: string;
  userDisplayAs: string | null;
  ruleId: number | null;
  assigneeId: number;
  assigneeIdWas: number;
  teamAssigneeId: number;
  teamAssigneeIdWas: number;
  lastCommentId: number | null;
};

export type ThreadCreatedEvent = NexusEventBase & {
  eventName: NexusEventName.ThreadCreated;
  eventData: MessageThreadData;
};

export type ThreadUpdatedEvent = NexusEventBase & {
  eventName: NexusEventName.ThreadUpdated;
  eventData: MessageThreadData;
};

export type ThreadAssignedEvent = NexusEventBase & {
  eventName: NexusEventName.ThreadAssigned;
  eventData: MessageThreadData;
};

export type UserMergedEvent = NexusEventBase & {
  eventName: NexusEventName.UserMerged;
  eventData: {
    userId: string;
    leadId: string;
  };
};

export type TicketStateUpdatedEvent = NexusEventBase & {
  eventName: NexusEventName.TicketStateUpdated;
  eventData: {
    conversation_id: string;
    ticket_state: string;
  };
};

export type SideConversationEvent = NexusEventBase & {
  eventName:
    | NexusEventName.SideConversationStarted
    | NexusEventName.SideConversationUpdated
    | NexusEventName.SideConversationRead;
  eventData: {
    parent_conversation_id: number;
  };
};

export type BulkReportsLinkedEvent = NexusEventBase & {
  eventName: NexusEventName.BulkReportsLinked;
  eventData: {
    conversation_id: $TSFixMe;
  };
};

export type InboundCallbackRequestEvent = NexusEventBase & {
  eventName: NexusEventName.InboundCallbackRequest;
  eventData: {
    conversationId: number;
    adminId: number;
    userId: string;
    countryCode: string;
  };
};

export type ConferenceParticipantAddedEvent = NexusEventBase & {
  eventName: NexusEventName.ConferenceParticipantAdded;
  eventData: {
    conversationId: number;
    adminId: number;
    adminParticipants: number[];
    userParticipants: string[];
  };
};

export type ConferenceParticipantRemovedEvent = NexusEventBase & {
  eventName: NexusEventName.ConferenceParticipantRemoved;
  eventData: {
    conversationId: number;
    adminId: number;
    adminParticipants: number[];
    userParticipants: string[];
  };
};

export type ConferenceParticipantFailedToJoinEvent = NexusEventBase & {
  eventName: NexusEventName.ConferenceParticipantFailedToJoin;
  eventData: {
    conversationId: number;
    adminId: number;
    adminParticipants: number[];
    userParticipants: string[];
  };
};

type TypingEventData = {
  conversationId: string;
  adminName: string;
  adminId: string;
  adminAvatar: string;
};

export type UserIsTypingEvent = NexusEventBase & {
  'nx.FromUser': string;
  eventName: NexusEventName.UserIsTyping;
  eventData: TypingEventData;
};

export type AdminIsTypingEvent = NexusEventBase & {
  eventName: NexusEventName.AdminIsTyping;
  eventData: TypingEventData;
};

export type AdminIsTypingANoteEvent = NexusEventBase & {
  eventName: NexusEventName.AdminIsTypingANote;
  eventData: TypingEventData;
};

type NexusStatusEvent = 'PENDING' | 'CONNECTED' | 'ERROR' | 'DISCONNECTED';

const nexusStateMachine = createMachine({
  predictableActionArguments: true,
  tsTypes: {} as import('./nexus.typegen').Typegen0,
  id: 'nexus',
  initial: 'pending',
  context: {},
  schema: {
    events: {} as { type: NexusStatusEvent },
  },
  states: {
    pending: {
      after: {
        5000: { target: 'connecting' },
      },
      on: {
        CONNECTED: 'connected',
        ERROR: 'error',
      },
    },
    connecting: {
      entry: ['notifyConnecting'],
      on: {
        CONNECTED: {
          target: 'connected',
          actions: ['notifyConnected'],
        },
        ERROR: 'error',
      },
    },
    connected: {
      on: {
        DISCONNECTED: 'disconnected',
        PENDING: 'pending',
      },
    },
    disconnected: {
      after: {
        5000: { target: 'reconnecting' },
      },
      on: {
        CONNECTED: {
          target: 'connected',
          actions: ['notifyReconnectedSilently'],
        },
      },
    },
    reconnecting: {
      entry: ['notifyReconnecting'],
      on: {
        CONNECTED: {
          target: 'connected',
          actions: ['notifyReconnected'],
        },
        ERROR: 'error',
      },
    },
    error: {
      entry: ['notifyError'],
      on: {
        CONNECTED: {
          target: 'connected',
          actions: ['notifyReconnected'],
        },
      },
    },
  },
});

export default class Nexus extends Service.extend(Evented) {
  @service declare session: Session;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare tracing: TracingService;

  @tracked state?: string;

  private nexus: any;
  private bufferedEvents: Array<{ name: string; data: any }> = [];
  private subscribedTopics: Record<string, number> = {};
  private lastNotification?: Notification;
  private stateMachine?: InterpreterFrom<typeof nexusStateMachine>;

  constructor() {
    super(...arguments);
    let owner = getOwner(this) as any;
    this.nexus = owner.lookup('custom:nexus') || NexusIntercom.create();
  }

  get isConnected() {
    return this.state === 'connected';
  }

  async start() {
    this.buildStateMachine();
    await this.connect();
  }

  stop() {
    this.state = undefined;
    this.subscribedTopics = {};
    this.bufferedEvents = [];
    this.nexus.unsubscribe();
    this.stateMachine?.stop();
    this.stateMachine = undefined;
  }

  private buildStateMachine() {
    this.stateMachine = interpret(
      nexusStateMachine.withConfig({
        actions: {
          notifyConnecting: () => {
            this.notify(this.intl.t('inbox.notifications.nexus.connecting'), {
              type: 'warning',
              persistent: true,
              clearable: true,
            });
          },
          notifyReconnecting: () => {
            Metrics.capture({ increment: ['inbox.nexus.disconnected'] });
            this.tracing.startRootSpan({
              name: 'nexus.state_transition',
              resource: 'nexus.disconnected',
              attributes: {
                app_id: this.session.workspace.id,
                admin_id: this.session.teammate.id,
              },
            });
            this.notify(this.intl.t('inbox.notifications.nexus.disconnected'), {
              type: 'warning',
              persistent: true,
              clearable: true,
            });
          },
          notifyConnected: () => {
            this.notify(this.intl.t('inbox.notifications.nexus.connected'), {
              clearable: true,
              type: 'default',
            });
          },
          notifyReconnected: () => {
            this.tracing.startRootSpan({
              name: 'nexus.state_transition',
              resource: 'nexus.reconnected',
              attributes: {
                app_id: this.session.workspace.id,
                admin_id: this.session.teammate.id,
              },
            });
            this.notify(this.intl.t('inbox.notifications.nexus.connected'), {
              clearable: true,
              type: 'default',
            });
            this.trigger(NexusStatusEvents.Reconnected);
          },
          notifyReconnectedSilently: () => {
            this.trigger(NexusStatusEvents.Reconnected);
          },
          notifyError: () => {
            Metrics.capture({ increment: ['inbox.nexus.error'] });
            this.tracing.startRootSpan({
              name: 'nexus.state_transition',
              resource: 'nexus.error',
              attributes: {
                app_id: this.session.workspace.id,
                admin_id: this.session.teammate.id,
              },
            });
            this.notify(this.intl.t('inbox.notifications.nexus.error'), {
              type: 'warning',
              persistent: true,
              contentComponent: 'inbox2/left-nav/notification-nexus-error',
              clearable: true,
            });
          },
        },
      }),
    );

    this.stateMachine.onTransition((state) => {
      this.state = state.value as string;
    });

    this.stateMachine.start();
  }

  willDestroy() {
    this.stop();
  }

  addListener(name: string, fn: (e: NexusEvent) => void) {
    this.nexus.addListener(name, fn);
  }

  removeListener(name: string, fn: (e: NexusEvent) => void) {
    this.nexus.removeListener(name, fn);
  }

  // If many components subscribe to the same topic, we count how many are subscribed
  // so that we don't unsubscribe too early
  subscribeTopics(topics: Array<string>): void {
    topics.forEach((topic) => {
      this.subscribedTopics[topic] = this.subscribedTopics[topic] ?? 0;
      this.subscribedTopics[topic] += 1;
      if (this.subscribedTopics[topic] === 1) {
        this.nexus.subscribeTopics([topic]);
      }
    });
  }

  unsubscribeTopics(topics: Array<string>): void {
    topics.forEach((topic) => {
      this.subscribedTopics[topic] = this.subscribedTopics[topic] ?? 0;
      this.subscribedTopics[topic] -= 1;
      if (this.subscribedTopics[topic] < 1) {
        this.nexus.unsubscribeTopics([topic]);
        delete this.subscribedTopics[topic];
      }
    });
  }

  sendEvent(name: string, data: any): void {
    if (this.nexus.channelName === undefined) {
      this.bufferedEvents.push({ name, data });
    } else {
      this.nexus.throttleSendEvent(name, data);
    }
  }

  sendUserEvent(userId: string, name: string, data: any): void {
    this.nexus.sendUserEvent(userId, name, data);
  }

  throttleSendUserEvent(userId: string, name: string, data: any): void {
    this.nexus.throttleSendUserEvent(userId, name, data);
  }

  private async connect(): Promise<void> {
    await this.nexus.initForApp(this.session.workspace.id, {
      longPollingEnabled: false,
      onStatusChanged: (oldStatus: string, newStatus: string) => {
        console.info(`[WEBSOCKET] ${new Date().toISOString()} ${oldStatus} -> ${newStatus} `);
        this.stateMachine?.send(newStatus.toUpperCase() as NexusStatusEvent);
      },
    });
    this.nexus.subscribeTopics([
      `admin/${this.session.teammate.id}`,
      'inbox-general/CountersUpdated',
    ]);
    this.sendBufferedEvents();
  }

  private notify(message: string, opts?: SnackbarOpts) {
    if (this.lastNotification) {
      this.snackbar.clearNotification(this.lastNotification);
    }
    this.lastNotification = this.snackbar.notify(message, opts);
  }

  private sendBufferedEvents(): void {
    this.bufferedEvents.forEach((event) => {
      this.nexus.throttleSendEvent(event.name, event.data);
    });
  }
}

type CallbackFn = () => unknown;

export class NexusFallbackPoller {
  @service declare nexus: Nexus;
  @service declare session: Session;

  private interval: number;

  constructor(parent: unknown, opts: { interval: number } = { interval: ENV.APP._30000MS }) {
    setOwner(this, getOwner(parent)!);
    this.interval = opts.interval;
  }

  start(cb: CallbackFn) {
    taskFor(this.pollingTask).perform(cb);
  }

  stop() {
    taskFor(this.pollingTask).cancelAll();
  }

  @task({ restartable: true, maxConcurrency: 1 })
  private *pollingTask(cb: CallbackFn) {
    if (ENV.environment === 'test') {
      return;
    }

    while (true) {
      yield timeout(this.interval);

      if (this.shouldPoll) {
        cb();
      }
    }
  }

  private get shouldPoll() {
    return !this.nexus.isConnected;
  }
}

declare module '@ember/service' {
  interface Registry {
    nexus: Nexus;
  }
}
