/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import {
  type EmberCPError,
  toErrorResult,
  type EmberCPValidationsResult,
} from 'embercom/validations/typescript/ember-cp-validations-compat';
import type AiContentSegment from 'embercom/models/ai-content-segment';
/* eslint-disable no-restricted-imports */
import containerLookup from 'embercom/lib/container-lookup';

export function validateSegment(segment: AiContentSegment): EmberCPValidationsResult {
  let intl = containerLookup('service:intl');
  let errors: Array<EmberCPError> = [];

  if (!segment.name || segment.name.trim() === '') {
    errors.push({
      attribute: 'title',
      message: intl.t('ai-content-library.segments.validations.no-title'),
    });
  }

  return toErrorResult(errors);
}

export function validateSegmentWithRuleset(
  segment: AiContentSegment,
  ruleset: any,
): EmberCPValidationsResult {
  let intl = containerLookup('service:intl');
  let errors: Array<EmberCPError> = [];

  if (!ruleset?.predicateGroup.isValid) {
    errors.push({
      attribute: 'predicateGroup',
      message: intl.t('settings.audience.validations.audience-invalid'),
    });
  }

  if (!segment?.name || segment.name.trim() === '') {
    errors.push({
      attribute: 'title',
      message: intl.t('settings.audience.validations.no-title'),
    });
  }

  return toErrorResult(errors);
}
