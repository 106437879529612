/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const QuickLinkURL = BaseValidator.extend({
  validate(value) {
    // Strip attributes before validating as attribute names may contain whitespace
    // https://github.com/intercom/intercom/issues/142920
    let attributeRegex = /{{.+}}/gi;
    let urlWithoutAttributes = value.replace(attributeRegex, '');
    if (
      !urlWithoutAttributes.startsWith('http://') &&
      !urlWithoutAttributes.startsWith('https://')
    ) {
      return 'Enter a URL that starts with http:// or https://';
    } else if (/\s/.test(urlWithoutAttributes)) {
      return "Make sure the URL doesn't have any whitespaces";
    } else {
      return true;
    }
  },
});

export default QuickLinkURL;
