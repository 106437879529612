/* RESPONSIBLE TEAM: team-proactive-support */

import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default class extends BaseValidator {
  @service intl;

  validate(value) {
    let paths = value.split('/').filter((path) => path !== '');
    if (paths.length > 2) {
      return this.intl.t(
        'articles.settings.redesign.validations.custom-domain-with-multiple-subpaths',
      );
    }

    return true;
  }
}
