/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { camelize } from '@ember/string';
import type Model from '@ember-data/model';
import { isObject } from 'underscore';

export default class UpcomingInvoiceSerializer extends EmbercomBaseSerializer {
  normalize(modelClass: Model, resourceHash: any) {
    let response: any = super.normalize(modelClass, resourceHash);
    response.data.attributes = this.camelCaseKeys(response.data.attributes);

    return response;
  }

  camelCaseKeys(data: any): any {
    if (Array.isArray(data)) {
      return data.map((item) => this.camelCaseKeys(item));
    } else if (isObject(data)) {
      return Object.keys(data).reduce((acc, key) => {
        let camelCasedKey = camelize(key);
        acc[camelCasedKey] = this.camelCaseKeys(data[key]);
        return acc;
      }, {} as any);
    }

    return data;
  }
}
