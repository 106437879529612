/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { Promise as EmberPromise } from 'rsvp';
import { schedule } from '@ember/runloop';
import { empty } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import Service from '@ember/service';
import ENV from 'embercom/config/environment';
import { runTask } from 'ember-lifeline';

// This service introduces a phased boot process with a deferred secondary stage
// for running non-critical code such as ajax requests and secondary UI updates.
// By default, the second phase kicks in after 2 seconds but performance sensitive
// routes such as the user list and the inbox can manually control advancement
// to the secondary phase by calling `deferSecondaryPhase` and `advanceToSecondaryPhase`.
// If `advanceToSecondaryPhase` isn't called within 10 seconds, the boot auto advances.
// An `onSecondaryStage` event is emitted when the secondary stage can be run

export default Service.extend(Evented, {
  shouldDeferSecondaryPhase: false,
  hasAdvancedToSecondaryStage: false,
  considerAutoAdvancementDelay: ENV.APP._2000MS,
  secondaryStageAdvancementDelay: ENV.APP.secondaryStageAdvancementDelay,
  autoAdvanceWhenDefferedDelay: ENV.APP._10000MS,
  manualAdvanceToSecondaryStage: false,
  hasNoAppModel: empty('app'),

  init() {
    this._super(...arguments);
    this._scheduleConsiderAutoAdvancementToSecondaryPhase();
  },

  deferSecondaryPhase() {
    this.set('shouldDeferSecondaryPhase', true);
  },

  deferSecondaryPhaseForManualAdvance() {
    this.set('manualAdvanceToSecondaryStage', true);
    this.deferSecondaryPhase();
  },

  setApp(app) {
    this.set('app', app);
    this._scheduleConsiderAutoAdvancementToSecondaryPhase();
  },

  considerAutoAdvancementToSecondaryPhase() {
    if (this.shouldDeferSecondaryPhase) {
      runTask(
        this,
        () => {
          this.advanceToSecondaryPhase();
        },
        this.autoAdvanceWhenDefferedDelay,
      );
    } else {
      this.advanceToSecondaryPhase();
    }
  },

  advanceToSecondaryPhase() {
    if (
      this.hasAdvancedToSecondaryStage ||
      this.hasNoAppModel ||
      this.manualAdvanceToSecondaryStage
    ) {
      return;
    }

    schedule('afterRender', () => {
      let hasAdvancedToSecondaryStage = this.hasAdvancedToSecondaryStage;
      if (!hasAdvancedToSecondaryStage) {
        this.set('hasAdvancedToSecondaryStage', true);

        runTask(
          this,
          () => {
            this.triggerSecondaryStage();
          },
          this.secondaryStageAdvancementDelay,
        );
      }
    });
  },

  ifOrWhenSecondaryStageReached() {
    return new EmberPromise((resolve) => {
      if (this.hasAdvancedToSecondaryStage) {
        resolve();
      } else {
        this.on('onSecondaryStage', () => resolve());
      }
    });
  },

  triggerSecondaryStage() {
    this.set('hasAdvancedToSecondaryStage', true);
    this.trigger('onSecondaryStage', this.app);
  },

  _scheduleConsiderAutoAdvancementToSecondaryPhase() {
    runTask(
      this,
      () => {
        this.considerAutoAdvancementToSecondaryPhase();
      },
      this.considerAutoAdvancementDelay,
    );
  },
});
