/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

const ConnectionPointsInboundBotMobileConstraints = BaseValidator.extend({
  intl: service(),
  contentEditorService: service(),

  validate(value, options, model) {
    let rulesetLink = this.contentEditorService.activeRulesetLink;
    // Only validate if the ruleset link is inbound bot
    if (!rulesetLink || !rulesetLink.object.isInbound) {
      return true;
    }

    // Only validate if the ruleset link preferred devices include mobile
    let { ios, android } = preferredDevices;
    if (!rulesetLink.preferredDevices.any((device) => [ios, android].includes(device))) {
      return true;
    }

    let { replyButtons } = model;
    if (!replyButtons || !replyButtons.length) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.inbound-bot-mobile-constraints.no-reply-button',
      );
    }

    return true;
  },
});

ConnectionPointsInboundBotMobileConstraints.reopenClass({
  getDependentsFor() {
    return ['model.replyButtons.@each.label', 'model.outwardConnectionPoints.@each.isConnected'];
  },
});

export default ConnectionPointsInboundBotMobileConstraints;
