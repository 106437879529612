/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { get } from '@ember/object';

const CHAT_MESSAGE_HARD_LIMIT = 11;
const CHAT_MESSAGE_SOFT_LIMIT = 10;

function hasConsecutiveTextGroupsAboveLimit(steps, limit) {
  let count = 0;
  let limitReached = false;

  steps.forEach((step) => {
    if (step.isChatMessage) {
      count += 1;
      if (count >= limit) {
        limitReached = true;
      }
    } else {
      count = 0;
    }
  });

  return limitReached;
}

const WorkflowPathLimitsConsecutiveAutoFollows = BaseValidator.extend({
  validate(_, options, model) {
    let stepCount = model.get('steps.length');
    let isWarning = get(options, 'isWarning');
    let limit = isWarning ? CHAT_MESSAGE_SOFT_LIMIT : CHAT_MESSAGE_HARD_LIMIT;
    let steps;

    if (isWarning) {
      steps = model
        .get('steps')
        .slice(stepCount - 1 - limit, stepCount - 1) // take the last slidingWindow, except last one since it is an empty step for follow up rules
        .filter((x) => !!x);
      if (steps.get('length') !== limit) {
        return true;
      }
    } else {
      steps = model.get('steps');
    }

    if (hasConsecutiveTextGroupsAboveLimit(steps, limit)) {
      return 'You cannot have more than 10 consecutive bot messages';
    }

    return true;
  },
});

WorkflowPathLimitsConsecutiveAutoFollows.reopenClass({
  getDependentsFor() {
    return ['model.steps.[]'];
  },
});

export default WorkflowPathLimitsConsecutiveAutoFollows;
