/* RESPONSIBLE TEAM: team-proactive-support */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  normalize(modelClass, resourceHash) {
    let result = this._super(modelClass, resourceHash);
    if (result.data.id) {
      result.data.attributes.userId = result.data.id;
    }
    result.data.attributes.legacyUserData = resourceHash;
    return result;
  },
});
