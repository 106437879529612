/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const RuleData = BaseValidator.extend({
  validate(value, options, model) {
    let isRuleValid = model.get('isRuleValid');
    if (!isRuleValid) {
      if (!model.get('predicatesAreValid')) {
        return 'Rule data is incomplete';
      }
      if (!model.get('actionsAreValid')) {
        return 'Rule actions are incomplete';
      }
    }
    return true;
  },
});

export default RuleData;
