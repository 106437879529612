/* RESPONSIBLE TEAM: team-ai-agent */

import Service, { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { post } from 'embercom/lib/ajax';
import { type BlockList } from '@intercom/interblocks.ts';
import type ExperimentsApi from 'embercom/services/experiments-api';
import {
  type InboxAssistantConversationPart,
  InboxAssistantConversation,
  type BaseSource,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import { type CopilotSourceTypes } from 'embercom/lib/inbox2/copilot/types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type InboxSidebarService from './inbox-sidebar-service';
import { postRequest, putRequest, request } from 'embercom/lib/inbox/requests';
import type IntlService from 'ember-intl/services/intl';
import { captureException } from '@sentry/browser';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';

export type CopilotSettingsResponse = {
  trusted_admin_ids: number[];
  use_past_conversations: boolean;
  excerpt_matching_attributes?: {
    attributes: { identifier: string; type: string }[];
    type: string;
  };
  use_macros: boolean;
};

export type CopilotSetupWireFormat = {
  preparing_workspace: boolean;
  ready_to_use: boolean;
  has_had_successful_answer: boolean;
  access_blocked: boolean;
  excerpts_enabled_for_app: boolean;
  macros_enabled_for_app: boolean;
  has_excerpt_matching_attributes: boolean;
  has_ingested_content: boolean;
};

export type CopilotSuggestionWireFormat =
  | { type: 'question'; question: string; emoji: string }
  | { type: 'macro'; macro: { id: number; name: string; blocks: BlockList } };

export enum InteractionEventType {
  AddedToComposer = 'added_to_composer',
  Copied = 'copied',
}

export default class CopilotApi extends Service {
  @service declare session: Session;
  @service declare experimentsApi: ExperimentsApi;
  @service declare inboxSidebarService: InboxSidebarService;

  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @tracked private _isCopilotReadyToUse = false;
  @tracked private _isPreparingWorkspaceForCopilot?: boolean;
  @tracked private _hasHadSuccessfulCopilotAnswer?: boolean;
  @tracked private _prefilledSearchQuery?: string;
  @tracked private _isCopilotAccessBlocked?: boolean;
  @tracked private _excerpts_enabled_for_app?: boolean;
  @tracked private _macros_enabled_for_app?: boolean;
  @tracked private _has_excerpt_matching_attributes?: boolean;
  @tracked private _has_ingested_content?: boolean;

  @action triggerAndSearchCopilotQuery(query: string) {
    this.inboxSidebarService.openCopilot();
    this.setPrefilledSearchQuery(query);
  }

  // Returns if the current admin can see Ask Fin at all, whether forced or not.
  get isCopilotEnabled() {
    if (this.isEfficiencyExperimentEnabled) {
      if (this.efficiencyExperimentVariant === 'copilot-efficiency-experiment-treatment') {
        return true;
      } else if (this.efficiencyExperimentVariant === 'copilot-efficiency-experiment-control') {
        return false;
      }
    }

    return this._isCopilotReadyToUse;
  }

  get isCopilotAccessBlocked() {
    return this._isCopilotAccessBlocked;
  }

  get isPreparingWorkspaceForCopilot() {
    return this._isPreparingWorkspaceForCopilot;
  }

  get hasHadSuccessfulCopilotAnswer() {
    return this._hasHadSuccessfulCopilotAnswer;
  }

  get hasNotHadSuccessfulCopilotAnswer() {
    return this._hasHadSuccessfulCopilotAnswer === false;
  }

  get excerptsEnabledForApp() {
    return this._excerpts_enabled_for_app;
  }

  get macrosEnabledForApp() {
    return this._macros_enabled_for_app;
  }

  get hasExcerptMatchingAttributes() {
    return this._has_excerpt_matching_attributes;
  }

  get hasIngestedContent() {
    return this._has_ingested_content;
  }

  get isEfficiencyExperimentEnabled() {
    return this.experimentsApi.experiments.some(
      (experiment) => experiment.name === 'copilot-efficiency-experiment' && experiment.enabled,
    );
  }

  get efficiencyExperimentVariant() {
    return (
      this.experimentsApi.experiments.find(
        (experiment) => experiment.name === 'copilot-efficiency-experiment' && experiment.enabled,
      ) as { variant?: string }
    )?.variant;
  }

  get betaAnalyticsData() {
    return {
      ask_fin_version: 'v3' as const,
      ask_fin_experiment_enabled: false,
      copilot_efficiency_experiment: this.isEfficiencyExperimentEnabled,
      copilot_efficiency_experiment_variant: this.efficiencyExperimentVariant,
    };
  }

  async extractQuestionForConversation(id: number) {
    let response: { app_id: number; answer_bot_transaction_id: string; question: string } =
      await post(
        `/ember/inbox/conversations/${id}/fin_extract_question?app_id=${this.session.workspace.id}`,
        {},
      );

    if (!response) {
      return;
    }

    return {
      conversationId: id,
      question: response.question,
      answerBotTransactionId: response.answer_bot_transaction_id,
    };
  }

  async extractSuggestionsForConversation(
    id: number,
    existingQuestions: string[] = [],
  ): Promise<
    | {
        conversationId: number;
        suggestions: CopilotSuggestionWireFormat[];
        answerBotTransactionId: string;
      }
    | undefined
  > {
    let response: {
      app_id: number;
      conversation_id: number;
      answer_bot_transaction_id: string;
      hints: CopilotSuggestionWireFormat[];
    } | null = await post(
      `/ember/inbox/conversations/${id}/copilot_extract_multiple_suggestions?app_id=${this.session.workspace.id}`,
      {
        existing_questions: existingQuestions,
      },
    );

    if (!response) {
      return;
    }

    let conversationId = response.conversation_id;
    if (conversationId === undefined && response.hints.length === 0) {
      // If we have no hints and no conversation ID there's no harm in filling it in with the original ID
      // We should ultimately apply this fix to the backend
      conversationId = id;
    }

    return {
      conversationId,
      suggestions: response.hints,
      answerBotTransactionId: response.answer_bot_transaction_id,
    };
  }

  async createTeammateReply(
    assistantConversationId: number,
    activeConversationId: number | undefined,
    searchUUID: string,
    content: BlockList,
    sourceTypeFilters: CopilotSourceTypes[],
  ) {
    let response = await postRequest(
      `/ember/inbox/inbox_assistant_conversations/${assistantConversationId}/teammate_reply?app_id=${this.session.workspace.id}&client_assigned_uuid=${searchUUID}`,
      {
        conversation_id: activeConversationId,
        content,
        entity_type_filters: sourceTypeFilters,
        // feature flags on front-end clients only update on refresh, and we don't want the back-end streaming us
        // any fallback answers we won't render so drive this boolean from client's feature flag state rather than
        // hardcoding as true
        allow_fallback: true,
        options: {
          should_rewrite_citations_with_attr: true,
        },
      },
    );

    let json = await response.json();
    let conversation = json.conversation
      ? InboxAssistantConversation.deserialize(json.conversation)
      : undefined;

    return {
      conversation,
      plan: json.plan,
      answer_bot_transaction_id: json.answer_bot_transaction_id,
    };
  }

  async expandSources(sources: BaseSource[]) {
    return await post(
      `/ember/inbox/inbox_assistant_conversations/expand_sources?app_id=${this.session.workspace.id}`,
      {
        sources,
      },
    );
  }

  async consentToIngestionAndSetupCopilot() {
    try {
      this.session.workspace.setHasConsentedToFinIngestion(true);
      await post(`/ember/inbox/copilot_settings?app_id=${this.session.workspace.id}`);
    } catch (e) {
      this.session.workspace.setHasConsentedToFinIngestion(false);
      throw new Error('Failed to consent to copilot ingestion');
    }
  }

  async refreshCopilotSettingsData() {
    this.copilotSettingsLoader.reload();
  }

  get copilotSettings() {
    return this.copilotSettingsLoader.value;
  }

  get isCopilotSettingsLoading() {
    return this.copilotSettingsLoader.isLoading;
  }

  @use private copilotSettingsLoader = AsyncData<CopilotSettingsResponse>(async () => {
    let response = await request(
      `/ember/inbox/copilot_settings?app_id=${this.session.workspace.id}`,
    );

    let { trusted_admin_ids, use_past_conversations, use_macros, excerpt_matching_attributes } =
      await response.json();

    return {
      trusted_admin_ids,
      use_past_conversations,
      use_macros,
      excerpt_matching_attributes,
    };
  });

  async updateSettings(args: {
    trustedAdminIds?: number[];
    usePastConversations?: boolean;
    useMacros?: boolean;
    attributesPayload?: { attributes: { identifier: string; type: string }[]; type: string } | null;
  }): Promise<void> {
    let { trustedAdminIds, usePastConversations, useMacros, attributesPayload } = args;

    try {
      await putRequest(`/ember/inbox/copilot_settings`, {
        app_id: this.session.workspace.id,
        trusted_admin_ids: trustedAdminIds,
        use_past_conversations: usePastConversations,
        excerpt_matching_attributes: attributesPayload,
        use_macros: useMacros,
      });
    } catch (error) {
      throw new Error('Failed to set trusted admin ids', error.message);
    }
  }

  async conversationPartTrackInteractionEvent(
    conversationPart: InboxAssistantConversationPart,
    type: InteractionEventType,
  ) {
    try {
      await post(
        `/ember/inbox/copilot_conversation_parts/${conversationPart.id}/track_interaction_event?app_id=${this.session.workspace.id}`,
        { type },
      );
    } catch (error) {
      captureException(error);
    }
  }

  async setup() {
    try {
      let response = await request(`/ember/inbox/copilot?app_id=${this.session.workspace.id}`);
      let json: CopilotSetupWireFormat = await response.json();
      this._isCopilotReadyToUse = json.ready_to_use;
      this._isPreparingWorkspaceForCopilot = json.preparing_workspace;
      this._hasHadSuccessfulCopilotAnswer = json.has_had_successful_answer;
      this._isCopilotAccessBlocked = json.access_blocked;
      this._excerpts_enabled_for_app = json.excerpts_enabled_for_app;
      this._macros_enabled_for_app = json.macros_enabled_for_app;
      this._has_excerpt_matching_attributes = json.has_excerpt_matching_attributes;
      this._has_ingested_content = json.has_ingested_content;
    } catch (e) {
      this._isCopilotReadyToUse = false;
    }
  }

  async onSuccessfulCopilotAnswer() {
    this._hasHadSuccessfulCopilotAnswer = true;
  }

  // Private API

  // These are only exposed to be used within the copilot component to implement inserting into copilot from anywhere.
  // triggerAndSearchCopilotQuery is intended to be used by consumers.

  get prefilledSearchQuery() {
    return this._prefilledSearchQuery;
  }

  @action setPrefilledSearchQuery(query: string) {
    this._prefilledSearchQuery = query;
  }

  @action resetPrefilledSearchQuery() {
    this._prefilledSearchQuery = undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    copilotApi: CopilotApi;
    'copilot-api': CopilotApi;
  }
}
