/* RESPONSIBLE TEAM: team-workflows */
import Service, { inject as service } from '@ember/service';
import { CONVERSATION_PRIORITIES } from 'embercom/lib/inbox/constants';

const CVDA_IDENTIFIER = 'set-conversation-data-attribute';

export default class RuleActionService extends Service {
  @service appService;
  @service intercomEventService;
  @service attributeService;

  get conversationCustomAttributes() {
    return this.attributeService.nonArchivedConversationCustomAttributes;
  }

  addActionToTarget(target, item) {
    let identifier = item.identifier;
    let actionData = {};

    if (identifier === 'send-app') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'messenger_app_inserter',
      });
    }

    if (identifier === CVDA_IDENTIFIER) {
      actionData.attribute_identifier = item.attribute_identifier;

      // boolean and list types need default values due to pulse component requirements
      if (item.attribute_type === 'boolean') {
        actionData.value = true;
      }
      if (item.attribute_type === 'conversation_attribute_list') {
        let firstValue = this.conversationCustomAttributes
          .find((attribute) => attribute.identifier === item.attribute_identifier)
          .get('options.firstObject.identifier');

        actionData.value = firstValue;
      }
    }

    if (identifier === 'change-conversation-priority') {
      actionData.priority = CONVERSATION_PRIORITIES.priority;
    }

    let newAction = target.createFragment({
      type: identifier,
      justAdded: true,
      actionData,
    });

    return newAction;
  }
}
