/* RESPONSIBLE TEAM: team-ai-agent */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type Model from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';

export default class HasConsentToExternalAiDataProcessing extends BaseValidator {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: $TSFixMe;

  validate(_value: unknown, _options: ValidationOptions, model: Model & { useAiAnswers: boolean }) {
    if (!model.useAiAnswers) {
      return true;
    }

    if (this.appService.app.canUseExternalAi) {
      return true;
    }

    return this.intl.t('ai-agent.opted-out-external-ai-data-processing');
  }
}
