/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const BranchingQualificationCollectsQualificationData = BaseValidator.extend({
  validate(value, options, model) {
    let hasQualificationAttributes = model
      .get('answers')
      .any((answer) => answer.get('qualificationAttributes.length') > 0);
    if (!hasQualificationAttributes) {
      return 'You must collect a qualification attribute in at least one reply';
    }
    return true;
  },
});

BranchingQualificationCollectsQualificationData.reopenClass({
  getDependentsFor() {
    return [
      'model.answers.[]',
      'model.answers.firstObject.qualificationAttributes.[]',
      'model.answers.firstObject.qualificationAttributes.@each.hasDirtyAttributes',
      'model.answers.lastObject.qualificationAttributes.[]',
      'model.answers.lastObject.qualificationAttributes.@each.hasDirtyAttributes',
    ];
  },
});

export default BranchingQualificationCollectsQualificationData;
