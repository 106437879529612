/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';

import { connect, createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video';
import { timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import type UserSummary from 'embercom/objects/inbox/user-summary';

export interface VideoTrackDimensions {
  height: number;
  width: number;
}
export default class VideoCallService extends Service {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare customerService: any;
  @service notificationsService: any;

  videoRoom: any;
  localVideoTrack: any;
  localAudioTrack: any;
  @tracked conversationId: number | undefined;
  callId: number | undefined;
  @tracked isActiveCall = false;
  @tracked isCameraOn = false;
  @tracked recordingEnabled = false;
  @tracked isMicrophoneOn = false;
  @tracked isUserCameraOn = false;
  @tracked isScreenSharingOn = false;
  @tracked isExpandedView = false;
  @tracked isRecording = false;
  @tracked userCameraDimensions: VideoTrackDimensions = { height: 0, width: 0 };
  @tracked screenSharingDimensions: VideoTrackDimensions = { height: 0, width: 0 };
  @tracked user: UserSummary | undefined;

  async createRoom(conversationId: number, isCameraOn: boolean) {
    this.conversationId = conversationId;
    let roomData = await this._createOrGetRoom(conversationId);
    if (!roomData) {
      return;
    }
    this.recordingEnabled = roomData.recording_enabled;
    this.isRecording = this.recordingEnabled;
    this.isCameraOn = isCameraOn;
    this.isMicrophoneOn = true;

    this.callId = roomData.call_id;
    if (this.isCameraOn) {
      this.videoRoom = await this._connectToRoom(roomData);
    } else {
      this.videoRoom = await this._connectToVoiceOnlyRoom(roomData);
    }
    this.isActiveCall = true;
    this._recordEvent('initiate_messenger_call', this.conversationId);
    return this.videoRoom;
  }

  setupParticipantsTracks() {
    if (this.videoRoom) {
      this._manageRemoteParticipants(this.videoRoom);
    }
  }

  addParticipantTracksToDisplay() {
    if (this.videoRoom) {
      this._displayLocalParticipantVideoTrack();
      this._displayRemoteParticipantVideoTrack();
    }
  }

  async _createOrGetRoom(conversationId: number) {
    try {
      let roomData = await post('/ember/video_call', {
        app_id: this.session.workspace.id,
        admin_id: this.session.teammate.id,
        conversation_id: conversationId,
      });
      return roomData;
    } catch (e) {
      this.notificationsService.notifyError(`Failed to create video call room: ${e}`);
      return undefined;
    }
  }

  async _displayLocalParticipantVideoTrack() {
    let localMediaContainer = document.querySelector('#local-media-container') as HTMLDivElement;
    if (!localMediaContainer) {
      // on the init of this componenet this selector is not rendered so waiting for it to exist if null; on subsequent triggers we dont want to wait.
      await timeout(1000);
      localMediaContainer = document.querySelector('#local-media-container') as HTMLDivElement;
    }
    if (this.localVideoTrack) {
      localMediaContainer.appendChild(this.localVideoTrack.attach());
    }
  }

  async _displayRemoteParticipantVideoTrack() {
    for (let participant of this.videoRoom.participants.values()) {
      for (let publication of participant.tracks.values()) {
        if (publication.isSubscribed) {
          if (publication.trackName === 'screen') {
            this._displayScreenSharing(publication.track);
          } else {
            await this._attachTrack(publication.track);
          }
        }
      }
    }
  }

  async _connectToRoom(roomData: any) {
    this.localVideoTrack = await createLocalVideoTrack();
    this.localAudioTrack = await createLocalAudioTrack();
    try {
      let room = await connect(roomData.token, {
        name: roomData.room_name,
        audio: true,
        video: { height: 450, frameRate: 24, width: 380 },
        tracks: [this.localVideoTrack, this.localAudioTrack],
      });
      return room;
    } catch (error) {
      this.notificationsService.notifyError(`Failed to join video call room: ${error}`);
      return undefined;
    }
  }

  async _connectToVoiceOnlyRoom(roomData: any) {
    this.localAudioTrack = await createLocalAudioTrack();
    try {
      let room = await connect(roomData.token, {
        name: roomData.room_name,
        audio: true,
        video: false,
        tracks: [this.localAudioTrack],
      });
      return room;
    } catch (error) {
      this.notificationsService.notifyError(`Failed to join video call room: ${error}`);
      return undefined;
    }
  }

  _manageRemoteParticipants(room: any) {
    room.participants.forEach((participant: any) =>
      this._addTracksForRemoteParticipant(participant),
    );

    room.on('participantConnected', (participant: any) => {
      this._recordEvent('joined_messenger_call', this.conversationId);
      this._addTracksForRemoteParticipant(participant);
    });

    room.on('participantDisconnected', (participant: any) => {
      document.getElementById(participant.sid)?.remove();
    });

    window.onbeforeunload = () => room.disconnect();
  }

  _addTracksForRemoteParticipant(participant: any) {
    participant.on('trackSubscribed', (track: any) => {
      if (track.name === 'screen') {
        this.isScreenSharingOn = true;
        this.isExpandedView = true;
        this.screenSharingDimensions = track.dimensions;
        this._recordEvent('initiate_messenger_screen_sharing', this.conversationId);

        track.on('dimensionsChanged', (track: any) => {
          this.screenSharingDimensions = track.dimensions;
        });

        this._displayScreenSharing(track);
      } else {
        if (track.kind === 'video') {
          this.isUserCameraOn = true;
          this.userCameraDimensions = track.dimensions;
          this._recordEvent('initiate_messenger_user_camera', this.conversationId);

          track.on('dimensionsChanged', (track: any) => {
            this.userCameraDimensions = track.dimensions;
          });
        } else {
          this._recordEvent('initiate_messenger_user_audio', this.conversationId);
        }

        this._attachTrack(track);
      }
    });

    participant.on('trackUnsubscribed', (track: any) => {
      if (track.name === 'screen') {
        this.isScreenSharingOn = false;
        this.isExpandedView = false;
        this._recordEvent('end_messenger_screen_sharing', this.conversationId);
      } else if (track.name === 'camera') {
        this.isUserCameraOn = false;
        this._recordEvent('end_messenger_user_camera', this.conversationId);
        if (!this.isScreenSharingOn) {
          this.isExpandedView = false;
        }
      } else {
        this._recordEvent('end_messenger_user_audio', this.conversationId);
      }
      this._removeTrack(track);
    });
  }

  async _attachTrack(track: any) {
    let remoteMediaContainer = document.querySelector('#remote-media-container') as HTMLDivElement;
    if (!remoteMediaContainer) {
      // on the init of this componenet this selector is not rendered so waiting for it to exist if null; on subsequent triggers we dont want to wait.
      await timeout(1000);
      remoteMediaContainer = document.querySelector('#remote-media-container') as HTMLDivElement;
    }
    remoteMediaContainer.appendChild(track.attach());
  }

  _displayScreenSharing(track: any) {
    let screenSharingContainer = document.querySelector(
      '#screen-sharing-container',
    ) as HTMLDivElement;
    screenSharingContainer.appendChild(track.attach());
  }

  _removeTrack(track: any) {
    track.detach().forEach((element: any) => element.remove());
  }

  toggleAudio(on: boolean) {
    if (this.videoRoom) {
      this.videoRoom.localParticipant.audioTracks.forEach((publication: any) => {
        on ? publication.track.enable() : publication.track.disable();
      });
      this.isMicrophoneOn = on;

      if (on) {
        this._recordEvent('initiate_messenger_teammate_audio', this.conversationId);
      } else {
        this._recordEvent('end_messenger_teammate_audio', this.conversationId);
      }
    }
  }

  async toggleVideo(on: boolean) {
    this.isCameraOn = on;
    if (!this.isCameraOn) {
      this.videoRoom.localParticipant.videoTracks.forEach((publication: any) => {
        this._removeTrack(publication.track);
        publication.track.stop();
        publication.unpublish();
      });
      this._recordEvent('end_messenger_teammate_camera', this.conversationId);
    } else {
      this.localVideoTrack = await createLocalVideoTrack();
      await this._displayLocalParticipantVideoTrack();
      await this.videoRoom.localParticipant.publishTrack(this.localVideoTrack);
      this._recordEvent('initiate_messenger_teammate_camera', this.conversationId);
    }
  }

  toggleExpandedView() {
    this.isExpandedView = !this.isExpandedView;
  }

  async endCall() {
    this.isActiveCall = false;
    await this._endRoomStreams();
    await this._sendEndCallRequest();
    this.isMicrophoneOn = false;
    this.videoRoom = null;
    this._recordEvent('end_messenger_call', this.conversationId);
  }

  async toggleRecording() {
    if (this.isRecording) {
      await post('/ember/video_call/stop_recording', {
        app_id: this.session.workspace.id,
        room_id: this.videoRoom.sid,
      });
      this.isRecording = false;
    } else {
      await post('/ember/video_call/start_recording', {
        app_id: this.session.workspace.id,
        room_id: this.videoRoom.sid,
      });
      this.isRecording = true;
    }
  }

  async _endRoomStreams() {
    this.videoRoom.localParticipant.removeAllListeners();
    await this.toggleVideo(false);
    this.localAudioTrack?.stop();
    this.localVideoTrack?.stop();
    this.videoRoom.disconnect();
  }

  async _sendEndCallRequest() {
    await post('/ember/video_call/end', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
      room_id: this.videoRoom.sid,
      call_id: this.callId,
    });
  }

  _recordEvent(action: string, conversationId: number | undefined) {
    let customer = this.customerService.customer;

    let payload: any = {
      action,
      object: 'conversation',
      place: 'inbox2',
      conversation_id: conversationId,
      is_video_call: this.isCameraOn,
      is_trial: customer?.hasActiveTrials,
    };

    this.intercomEventService.trackAnalyticsEvent(payload);
  }
}

declare module '@ember/service' {
  interface Registry {
    videoCallService: VideoCallService;
    'video-call-service': VideoCallService;
  }
}
