/* RESPONSIBLE TEAM: team-help-desk-experience */

import Service, { inject as service } from '@ember/service';
import { request } from 'embercom/lib/inbox/requests';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import { taskFor } from 'ember-concurrency-ts';
import { didCancel } from 'ember-concurrency';

type Experiment =
  | {
      name: string;
      enabled: false;
    }
  | {
      name: string;
      enabled: true;
      variant: string;
    };

export default class ExperimentsApi extends Service {
  @service declare session: Session;
  @service declare intercomEventService: any;

  @tracked experiments: Experiment[] = [];

  async loadExperiments() {
    await taskFor(this.loadExperimentsTask).perform();
  }

  @dropTask private *loadExperimentsTask() {
    try {
      let response = (yield request(
        `/ember/inbox/experiments?app_id=${this.session.workspace.id}`,
      )) as Response;

      let { experiments } = (yield response.json()) as { experiments: Experiment[] };
      this.experiments = experiments ?? [];
    } catch (err) {
      if (!didCancel(err)) {
        captureException(err);
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    experimentsApi: ExperimentsApi;
    'experiments-api': ExperimentsApi;
  }
}
