/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';

export default class LoadingIndicatorService extends Service {
  on() {
    document.body.classList.add('global-loading');
  }
  off() {
    document.body.classList.remove('global-loading');
  }
}
