/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isBlank } from '@ember/utils';
import { actionOptions } from 'embercom/models/banners/banner';

const BannerButtonTextLength = BaseValidator.extend({
  validate(value, options, model) {
    let localizedBannerContent = model.localizedBannerContents.firstObject;
    if (model.action === actionOptions.open_a_url) {
      if (isBlank(localizedBannerContent.actionTitle)) {
        return 'Enter a button text';
      } else if (localizedBannerContent.actionTitle.length > 25) {
        return 'The button text is too long';
      }
    }
    return true;
  },
});

BannerButtonTextLength.reopenClass({
  getDependentsFor() {
    return ['model.action', 'model.localizedBannerContents.firstObject.actionTitle'];
  },
});

export default BannerButtonTextLength;
