/* RESPONSIBLE TEAM: team-workflows */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import generateUUID from 'embercom/lib/uuid-generator';

export default EmbercomBaseSerializer.extend({
  attrs: {
    toGroup: 'to_group_id',
  },

  serialize(snapshot) {
    let json = this._super(...arguments);
    this._maybeGenerateRelationshipIdFromUuid(json, snapshot, 'toGroup');
    return json;
  },

  normalize(typeClass, resourceHash) {
    this._maybeGeneratePrimaryId(resourceHash);
    return this._super(...arguments);
  },

  _maybeGeneratePrimaryId(hash) {
    hash[this.primaryKey] = hash[this.primaryKey] ?? generateUUID();
  },

  _maybeGenerateRelationshipIdFromUuid(json, snapshot, relationshipKey) {
    let payloadKey = this.attrs[relationshipKey] ?? this.keyForRelationship(relationshipKey);
    json[payloadKey] = json[payloadKey] ?? snapshot.record[relationshipKey]?._uuid;
  },
});
