/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import loadCDNScript from 'embercom/lib/load-cdn-script';
import { captureMessage } from 'embercom/lib/sentry';

export default class PaymentService extends Service {
  @service notificationsService;

  load() {
    this.loadPaymentLibrary.perform();
  }

  @task *loadPaymentLibrary() {
    try {
      if (typeof window.Z === 'undefined') {
        return yield loadCDNScript(this.librarySourceScript);
      }
    } catch (error) {
      console.error(error);
    }
  }

  get librarySourceScript() {
    return `https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js`;
  }

  get getPaymentLibrary() {
    return window.Z;
  }

  submit() {
    return this.getPaymentLibrary.submit();
  }

  sendErrorMessageToHpm(key, errorMessage) {
    this.getPaymentLibrary.sendErrorMessageToHpm(key, errorMessage);
  }

  setEventHandler(eventName, eventCallback) {
    this.getPaymentLibrary.setEventHandler(eventName, eventCallback);
  }

  processZuoraError(permitPayment, key, code, message) {
    let errorMessage = message;
    let errorMessageParts = message.split(']').map((part) => part.replace(/^\s+|\s+$/gm, ''));
    if (key === 'error') {
      if (errorMessageParts.length > 2) {
        errorMessage = `Transaction failed. ${errorMessageParts[2]}`;
      } else if (errorMessageParts[1] !== 'null') {
        errorMessage = `Transaction failed. ${errorMessageParts[1]}`;
      } else {
        errorMessage = 'Validation failed on server-side, Please check your input values.';
      }
    }
    permitPayment();
    this.sendErrorMessageToHpm(key, errorMessage);
    console.error(message);
  }

  processZuoraSuccess(paymentAttempted, retrieveInvoices, response) {
    let { notificationsService } = this;
    let success = response.success === 'true';

    if (success) {
      notificationsService.notifyConfirmation('Payment has been successfully processed.');
    } else {
      let failureMessage = `Failed Zuora manual payment. Code: ${response.errorCode}, Message: ${response.errorMessage}`;
      captureMessage(failureMessage, { tags: { affects: 'zuora_manual_payment_page' } });

      notificationsService.notifyError('Payment has failed.');
      console.error(response);
    }
    retrieveInvoices();
    paymentAttempted(success);
  }

  renderWithErrorHandler(params, prepopulateFields, callBacks = {}) {
    let { ZPaymentLoaded, permitPayment, retrieveInvoices, paymentAttempted } = callBacks;

    this.getPaymentLibrary.setEventHandler('onloadCallback', ZPaymentLoaded);
    this.getPaymentLibrary.renderWithErrorHandler(
      params,
      prepopulateFields,
      (...args) => this.processZuoraSuccess(paymentAttempted, retrieveInvoices, ...args),
      (...args) => this.processZuoraError(permitPayment, ...args),
    );
  }
}
