/* RESPONSIBLE TEAM: team-frontend-tech */
import moment from 'moment-timezone';

export function determineThreshold(durationSeconds) {
  let duration = moment.duration(durationSeconds, 'seconds');

  let years = duration.years();
  let months = duration.months();
  let days = duration.days();
  let hours = duration.hours();
  let minutes = duration.minutes();
  let seconds = duration.seconds();

  let [key, props] = (years > 1 && ['yy', { years, months }]) ||
    (years === 1 && ['y', { years: 1, months }]) ||
    (months >= 6 && ['MM', { months, days: 0 }]) ||
    (months > 1 && ['MM', { months, days }]) ||
    (months === 1 && ['M', { months: 1, days }]) ||
    (days > 1 && ['dd', { days, hours }]) ||
    (days === 1 && ['d', { days: 1, hours }]) ||
    (hours > 1 && ['hh', { hours, minutes }]) ||
    (hours === 1 && ['h', { hours: 1, minutes }]) ||
    (minutes > 1 && ['mm', { minutes, seconds }]) ||
    (minutes === 1 && ['m', { minutes: 1, seconds }]) ||
    (seconds === 1 && ['s', { seconds: 1 }]) || ['ss', { seconds }];

  return { key, props };
}

export default function formatDuration(value /*, options = {}*/) {
  let { key, props } = determineThreshold(value);

  return this.t(`duration-formatting.formats.${key}`, props);
}
