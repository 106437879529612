/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';

export default class SecurityAppSettingsService extends Service {
  @service appService;
  @service store;
  @tracked settings = {};

  @task({ drop: true })
  *ensureDataIsLoaded() {
    if (!this.settings.notificationEmailsWithContentEnabled) {
      let result = yield ajax({
        url: '/ember/security_app_settings/contentless_notification_settings',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });
      this.settings.notificationEmailsWithContentEnabled =
        result['notification_emails_with_content_enabled'];
    }
  }
}
