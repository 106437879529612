/* RESPONSIBLE TEAM: team-knowledge-interop */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class FolderSerializer extends EmbercomBaseSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    contentWrapper: { embedded: 'always' },
    parentFolder: { key: 'parent_folder_id' },
    source: { key: 'source_id' },
  };
}
