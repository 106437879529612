/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  questions: {
    description: 'Survey questions',
    validators: [validator('has-many'), validator('survey-step-questions')],
  },
  actions: {
    description: 'Step actions',
    validators: [validator('has-many'), validator('step-can-have-actions')],
  },
  introThanks: {
    description: 'Intro and thanks steps',
    validators: [validator('has-many'), validator('intro-thanks-step-has-content')],
  },
  inwardPaths: {
    description: 'Step paths',
    validators: [validator('survey-step-has-inwards-paths')],
  },
  buttonText: {
    description: 'Step button text is not whitespace',
    validators: [validator('has-many'), validator('step-button-text-is-not-whitespace')],
  },
});
