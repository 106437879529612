/* RESPONSIBLE TEAM: team-phone */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type PhoneCallService from 'embercom/services/phone-call-service';
import type RouterService from '@ember/routing/router-service';
import {
  CallType,
  CtiMessage,
  type IntercomCtiData,
  type IntercomCtiMessage,
} from 'embercom/objects/phone/cti-types';

export default class CtiService extends Service {
  @tracked appContainerVisible = false;
  @tracked initiatedCall = false;
  @tracked activeCall = false;
  @tracked ctiAvailable = false;

  @service declare phoneCallService: PhoneCallService;
  @service declare router: RouterService;
  @service declare appService: any;
  @service intercomEventService: any;

  initialize() {
    window.addEventListener('message', this.handleMessageFromCti.bind(this));
  }

  get app() {
    return this.appService.app;
  }

  showAppContainer() {
    this.appContainerVisible = true;
  }

  hideAppContainer() {
    this.appContainerVisible = false;
  }

  initiateNewCall(phoneNumber: string) {
    let appId = this.appService.app.id;
    let adminId = this.appService.app.currentAdmin.id;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'cti_call_initiated',
      object: 'cti',
      metadata: {
        direction: 'outbound',
      },
    });

    // because CTI controls are available only in Inbox, we want to redirect to Inbox
    // in case user initiates outbound outside it
    if (!this.isInInbox()) {
      this.router.transitionTo('inbox.workspace.inbox.inbox', appId, 'admin', adminId);
    }

    CtiService.sendMessageToCti(CtiMessage.SEND_OUTBOUND_CALL, {
      phoneNumber,
      callType: CallType.Outbound,
    });
  }

  escalateChat(phoneNumber: string, conversationId: number) {
    let callType = CallType.Escalation;
    CtiService.sendMessageToCti(CtiMessage.SEND_OUTBOUND_CALL, {
      phoneNumber,
      conversationId,
      callType,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'cti_call_initiated',
      object: 'cti',
      metadata: {
        direction: 'outbound',
        type: 'escalation',
      },
    });
  }

  private async handleMessageFromCti(e: { data: IntercomCtiMessage }) {
    let { intercomCti } = e.data;

    if (!intercomCti) {
      return;
    }

    switch (intercomCti.type) {
      case CtiMessage.NOTIFY_READY:
        console.info('CTI -> ready');
        this.ctiAvailable = true;
        break;
      case CtiMessage.NOTIFY_NOT_READY:
        console.info('CTI -> not ready');
        this.ctiAvailable = false;
        break;
      case CtiMessage.NOTIFY_NEW_CALL:
        console.info(`CTI -> new call: ${JSON.stringify(intercomCti, null, 2)}`);

        if (
          intercomCti.payload.callType !== CallType.Escalation &&
          intercomCti.payload.callType !== CallType.Outbound
        ) {
          this.intercomEventService.trackAnalyticsEvent({
            action: 'cti_call_initiated',
            object: 'cti',
            metadata: {
              direction: 'inbound',
            },
          });
        }

        this.showAppContainer();
        this.initiatedCall = true;
        break;
      case CtiMessage.NOTIFY_ACCEPTED_CALL:
        console.info(`CTI -> call accepted ${JSON.stringify(intercomCti, null, 2)}`);
        await this.handleAcceptedCall(intercomCti);
        break;
      case CtiMessage.NOTIFY_ENDED_CALL:
        console.info(`CTI -> call ended ${JSON.stringify(intercomCti, null, 2)}`);
        await this.handleEndedCall(intercomCti);
        break;
      case CtiMessage.NOTIFY_INIT_FAILURE:
        console.info(`CTI -> initialisation failure ${JSON.stringify(intercomCti, null, 2)}`);
        this.ctiAvailable = false;
        break;
    }
  }

  private async handleAcceptedCall(data: IntercomCtiData) {
    this.activeCall = true;

    if (data.payload.callType === CallType.Escalation) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'cti_call_accepted',
        object: 'cti',
        metadata: {
          direction: 'outbound',
          type: 'escalation',
        },
      });

      await this.phoneCallService.startEscalationCall(data.payload.conversationId);

      return;
    } else if (data.payload.callType === CallType.Outbound) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'cti_call_accepted',
        object: 'cti',
        metadata: {
          direction: 'outbound',
        },
      });

      let conversationId = await this.phoneCallService.acceptOutboundCall(data.payload.phoneNumber);
      let appId = this.appService.app.id;
      let adminId = this.appService.app.currentAdmin.id;

      return this.router.transitionTo(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        appId,
        'admin',
        adminId,
        conversationId,
      );
    } else {
      // the inbound call case

      this.intercomEventService.trackAnalyticsEvent({
        action: 'cti_call_accepted',
        object: 'cti',
        metadata: {
          direction: 'inbound',
        },
      });

      let conversationId = await this.phoneCallService.acceptInboundCall(data.payload.phoneNumber);
      let appId = this.appService.app.id;
      let adminId = this.appService.app.currentAdmin.id;

      return this.router.transitionTo(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        appId,
        'admin',
        adminId,
        conversationId,
      );
    }
  }

  private async handleEndedCall(intercomCti: IntercomCtiData) {
    await this.phoneCallService.endCall(intercomCti);
    this.initiatedCall = false;
    this.activeCall = false;
  }

  private isInInbox() {
    let currentRoute = this.router.currentRouteName;

    return currentRoute.startsWith('inbox.workspace.inbox');
  }

  // --- sending messages to CTI ---

  private static sendMessageToCti(type: string, payload?: any) {
    let message = CtiService.createMessage(type, payload);
    window.parent.postMessage(message, '*');
  }

  private static createMessage(type: string, payload?: any): IntercomCtiMessage {
    return {
      intercomCti: {
        type,
        payload,
      },
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    ctiService: CtiService;
    'cti-service': CtiService;
  }
}
