/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Evented from '@ember/object/evented';
import Service from '@ember/service';
export default Service.extend(Evented, {
  publish() {
    return this.trigger.apply(this, arguments);
  },
  subscribe() {
    return this.on.apply(this, arguments);
  },
  unsubscribe() {
    return this.off.apply(this, arguments);
  },
});
