/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable-next-line @intercom/intercom/max-file-length */
import Service, { inject as service } from '@ember/service';
import platform from 'embercom/lib/browser-platform';
import { brAbnt2HotkeyMapping } from './inbox-hotkeys/br_abnt2';
import { frAzertyHotkeyMapping } from './inbox-hotkeys/fr_azerty';
import { esLatinAmericanHotkeyMapping } from './inbox-hotkeys/es_latin_american';
import { deQwertzHotkeyMapping } from './inbox-hotkeys/de_qwertz';
import type KeyboardLayout from './keyboard-layout';
import type Session from './session';
import { HotkeyID } from './inbox-hotkeys/HotkeyID';
import { getModifierKeyLabel } from 'embercom/helpers/modifier-key';
import { alternateKeysLetter, letterKeyShortcut } from './inbox-hotkeys/common';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export interface Hotkey {
  id: HotkeyID;
  keys: string;
  display: string[];
  alternateKeys?: string[];
}

export type HotkeysMap = Record<HotkeyID, Hotkey>;

export default class InboxHotkeys extends Service {
  @service declare keyboardLayout: KeyboardLayout;
  @service declare session: Session;

  @tracked hotkeysEnabled = true;

  private readonly modifierKey = getModifierKeyLabel();
  private readonly altKey = platform.isMac ? '⌥' : 'Alt';

  get individualMapForLayout(): HotkeysMap | undefined {
    if (this.keyboardLayout.isFrenchAzertyKeyboard) {
      return frAzertyHotkeyMapping(this.modifierKey, this.altKey);
    } else if (this.keyboardLayout.isBrazilianABNT2Keyboard) {
      return brAbnt2HotkeyMapping(this.modifierKey, this.altKey);
    } else if (this.keyboardLayout.isSpanishLatinAmericanKeyboard) {
      return esLatinAmericanHotkeyMapping(this.modifierKey, this.altKey);
    } else if (this.keyboardLayout.isGermanQwertzKeyboard) {
      return deQwertzHotkeyMapping(this.modifierKey, this.altKey);
    }
    return undefined;
  }
  get hotkeysMap(): HotkeysMap {
    let individualMap = this.individualMapForLayout;
    if (individualMap) {
      return individualMap;
    }

    if (this.keyboardLayout.isEnglishOrNonLatinKeyboard) {
      return {
        [HotkeyID.CommandK]: {
          id: HotkeyID.CommandK,
          keys: 'cmd+KeyK',
          display: [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyK', 'K')],
        },
        [HotkeyID.Send]: {
          id: HotkeyID.Send,
          keys: 'cmd+Enter',
          display: [this.modifierKey, 'Enter'],
        },
        [HotkeyID.Close]: {
          id: HotkeyID.Close,
          keys: 'cmd+shift+KeyY',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyY', 'Y')],
        },
        [HotkeyID.Reopen]: {
          id: HotkeyID.Reopen,
          keys: 'cmd+shift+KeyO',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyO', 'O')],
        },
        [HotkeyID.SnoozeUntilTomorrow]: {
          id: HotkeyID.SnoozeUntilTomorrow,
          keys: 'cmd+shift+KeyZ',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyZ', 'Z')],
        },
        [HotkeyID.SendAndClose]: {
          id: HotkeyID.SendAndClose,
          keys: 'cmd+shift+Enter',
          display: [this.modifierKey, 'Shift', 'Enter'],
        },
        [HotkeyID.SendAndSnooze]: {
          id: HotkeyID.SendAndSnooze,
          keys: 'cmd+alt+Enter',
          display: [this.modifierKey, this.altKey, 'Enter'],
        },
        [HotkeyID.SendAndCrossPost]: {
          id: HotkeyID.SendAndCrossPost,
          keys: 'cmd+shift+Enter',
          display: [this.modifierKey, 'Shift', 'Enter'],
        },
        [HotkeyID.ChangePriority]: {
          id: HotkeyID.ChangePriority,
          keys: 'KeyP',
          display: [this.keyboardLayout.getKeyForCode('KeyP', 'P')],
        },
        [HotkeyID.SnoozeAction]: {
          id: HotkeyID.SnoozeAction,
          keys: 'KeyZ',
          display: [this.keyboardLayout.getKeyForCode('KeyZ', 'Z')],
        },
        [HotkeyID.AssignToAction]: {
          id: HotkeyID.AssignToAction,
          keys: 'KeyA',
          display: [this.keyboardLayout.getKeyForCode('KeyA', 'A')],
        },
        [HotkeyID.JumpToAction]: {
          id: HotkeyID.JumpToAction,
          keys: 'KeyG',
          display: [this.keyboardLayout.getKeyForCode('KeyG', 'G')],
        },
        [HotkeyID.Emoji]: {
          id: HotkeyID.Emoji,
          keys: ':',
          display: [':'],
        },
        [HotkeyID.ComposeReply]: {
          id: HotkeyID.ComposeReply,
          keys: 'KeyR',
          display: [this.keyboardLayout.getKeyForCode('KeyR', 'R')],
        },
        [HotkeyID.ComposeNote]: {
          id: HotkeyID.ComposeNote,
          keys: 'KeyN',
          display: [this.keyboardLayout.getKeyForCode('KeyN', 'N')],
        },
        [HotkeyID.MergeInto]: {
          id: HotkeyID.MergeInto,
          keys: 'cmd+shift+KeyM',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyM', 'M')],
        },
        [HotkeyID.UseMacro]: {
          id: HotkeyID.UseMacro,
          keys: '#',
          alternateKeys: [...alternateKeysLetter('m'), ...alternateKeysLetter('s'), '\\', 'alt+#'],
          display: ['#'],
        },
        [HotkeyID.InsertGif]: {
          id: HotkeyID.InsertGif,
          keys: 'cmd+shift+KeyG',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyG', 'G')],
        },
        [HotkeyID.InsertImage]: {
          id: HotkeyID.InsertImage,
          keys: 'cmd+shift+KeyI',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyI', 'I')],
        },
        [HotkeyID.InsertArticle]: {
          id: HotkeyID.InsertArticle,
          keys: 'cmd+shift+KeyH',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyH', 'H')],
        },
        [HotkeyID.AddAttachment]: {
          id: HotkeyID.AddAttachment,
          keys: 'cmd+shift+KeyA',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyA', 'A')],
        },
        [HotkeyID.NewConversation]: {
          id: HotkeyID.NewConversation,
          keys: 'KeyC',
          display: [this.keyboardLayout.getKeyForCode('KeyC', 'C')],
        },
        [HotkeyID.TagLastUserComment]: {
          id: HotkeyID.TagLastUserComment,
          keys: 'shift+KeyT',
          display: ['Shift', this.keyboardLayout.getKeyForCode('KeyT', 'T')],
        },
        [HotkeyID.SwitchView]: {
          id: HotkeyID.SwitchView,
          keys: 'KeyL',
          display: [this.keyboardLayout.getKeyForCode('KeyL', 'L')],
        },
        [HotkeyID.ToggleLeftNav]: {
          id: HotkeyID.ToggleLeftNav,
          keys: 'BracketLeft',
          display: [this.keyboardLayout.getKeyForCode('BracketLeft', '[')],
        },
        [HotkeyID.ToggleConversationDetailsSidebar]: {
          id: HotkeyID.ToggleConversationDetailsSidebar,
          keys: 'BracketRight',
          display: [this.keyboardLayout.getKeyForCode('BracketRight', ']')],
        },
        [HotkeyID.TagUser]: {
          id: HotkeyID.TagUser,
          keys: 't',
          display: [this.keyboardLayout.getKeyForCode('KeyT', 'T')],
        },
        [HotkeyID.KeyboardShortcuts]: {
          id: HotkeyID.KeyboardShortcuts,
          keys: '?',
          display: ['?'],
        },
        [HotkeyID.ToggleEvents]: {
          id: HotkeyID.ToggleEvents,
          keys: platform.isMac ? 'cmd+KeyE' : 'cmd+shift+KeyE',
          display: platform.isMac
            ? [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyE', 'E')]
            : [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyE', 'E')],
        },
        [HotkeyID.NavigateUp]: {
          id: HotkeyID.NavigateUp,
          keys: 'KeyJ',
          display: [this.keyboardLayout.getKeyForCode('KeyJ', 'J')],
        },
        [HotkeyID.NavigateDown]: {
          id: HotkeyID.NavigateDown,
          keys: 'KeyK',
          display: [this.keyboardLayout.getKeyForCode('KeyK', 'K')],
        },
        [HotkeyID.NavigateArrowUp]: {
          id: HotkeyID.NavigateArrowUp,
          keys: 'ArrowUp',
          display: ['↑'],
        },
        [HotkeyID.NavigateArrowDown]: {
          id: HotkeyID.NavigateArrowDown,
          keys: 'ArrowDown',
          display: ['↓'],
        },
        [HotkeyID.JumpToBottom]: {
          id: HotkeyID.JumpToBottom,
          keys: 'cmd+ArrowDown',
          display: [this.modifierKey, '↓'],
        },
        [HotkeyID.JumpToTop]: {
          id: HotkeyID.JumpToTop,
          keys: 'cmd+ArrowUp',
          display: [this.modifierKey, '↑'],
        },
        [HotkeyID.PageDown]: {
          id: HotkeyID.JumpToTop,
          keys: 'ArrowDown',
          display: ['↓'],
        },
        [HotkeyID.PageUp]: {
          id: HotkeyID.PageUp,
          keys: 'ArrowUp',
          display: ['↑'],
        },
        [HotkeyID.Undo]: {
          id: HotkeyID.Undo,
          keys: `cmd+${this.getUndoKeyCode()}`,
          display: [this.modifierKey, this.getUndoKeyDisplayText()],
        },
        [HotkeyID.ConvertToTicket]: {
          id: HotkeyID.ConvertToTicket,
          keys: 'cmd+KeyB',
          display: [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyB', 'B')],
        },
        [HotkeyID.TriggerWorkflowConnectorAction]: {
          id: HotkeyID.TriggerWorkflowConnectorAction,
          keys: 'cmd+shift+KeyJ',
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyJ', 'J')],
        },
        [HotkeyID.Impersonate]: {
          id: HotkeyID.Impersonate,
          keys: 'shift+KeyI',
          display: ['Shift', this.keyboardLayout.getKeyForCode('KeyI', 'I')],
        },
        [HotkeyID.ToggleAll]: {
          id: HotkeyID.ToggleAll,
          keys: 'cmd+KeyA',
          display: [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyA', 'A')],
        },
        [HotkeyID.ToggleSelect]: {
          id: HotkeyID.ToggleSelect,
          keys: 'KeyX',
          display: [this.keyboardLayout.getKeyForCode('KeyX', 'X')],
        },
        [HotkeyID.ToggleDown]: {
          id: HotkeyID.ToggleDown,
          keys: 'shift+ArrowDown',
          display: ['Shift', '↓'],
        },
        [HotkeyID.ToggleUp]: {
          id: HotkeyID.ToggleUp,
          keys: 'shift+ArrowUp',
          display: ['Shift', '↑'],
        },
        [HotkeyID.Search]: {
          id: HotkeyID.Search,
          keys: 'cmd+KeyP',
          display: [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyP', 'P')],
        },
        [HotkeyID.AiAssist]: {
          id: HotkeyID.AiAssist,
          keys: 'cmd+KeyJ',
          display: [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyJ', 'J')],
        },
        [HotkeyID.UpdateTicketState]: {
          id: HotkeyID.UpdateTicketState,
          keys: 'cmd+KeyS',
          display: [this.modifierKey, this.keyboardLayout.getKeyForCode('KeyS', 'S')],
        },
        [HotkeyID.ResolveAndCloseTicketState]: {
          id: HotkeyID.ResolveAndCloseTicketState,
          ...letterKeyShortcut('s', 'cmd+shift'),
          display: [this.modifierKey, 'Shift', this.keyboardLayout.getKeyForCode('KeyS', 'S')],
        },
        [HotkeyID.OpenKnowledgeBasePanel]: {
          id: HotkeyID.OpenKnowledgeBasePanel,
          keys: 'KeyB',
          display: [this.keyboardLayout.getKeyForCode('KeyB', 'B')],
        },
        [HotkeyID.OpenCopilot]: {
          id: HotkeyID.OpenCopilot,
          keys: 'cmd+Slash',
          display: [this.modifierKey, '/'],
        },
        [HotkeyID.OpenConversationDetails]: {
          id: HotkeyID.OpenConversationDetails,
          keys: 'cmd+Period',
          display: [this.modifierKey, '.'],
        },
      };
    } else {
      // We should use keycodes (https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values)
      // instead of keys for cmd+shift+<key> shortcuts. On Mac, the unshifted key is received by the browser when
      // modified with cmd. On operating systems where cmd is replaced with ctrl, the browser receives shifted
      // keys. In both cases they receive the same keycode value so using this handles the discrepancy and makes
      // the hotkey operating-system agnostic.
      return {
        [HotkeyID.CommandK]: {
          id: HotkeyID.CommandK,
          ...letterKeyShortcut('k', 'cmd'),
          display: [this.modifierKey, 'k'],
        },
        [HotkeyID.Send]: {
          id: HotkeyID.Send,
          keys: 'cmd+Enter',
          display: [this.modifierKey, 'Enter'],
        },
        [HotkeyID.Close]: {
          id: HotkeyID.Close,
          keys: 'cmd+shift+KeyY',
          display: [this.modifierKey, 'Shift', 'Y'],
        },
        [HotkeyID.Reopen]: {
          id: HotkeyID.Reopen,
          keys: 'cmd+shift+KeyO',
          display: [this.modifierKey, 'Shift', 'O'],
        },
        [HotkeyID.SnoozeUntilTomorrow]: {
          id: HotkeyID.SnoozeUntilTomorrow,
          keys: 'cmd+shift+KeyZ',
          display: [this.modifierKey, 'Shift', 'Z'],
        },
        [HotkeyID.SendAndClose]: {
          id: HotkeyID.SendAndClose,
          keys: 'cmd+shift+Enter',
          display: [this.modifierKey, 'Shift', 'Enter'],
        },
        [HotkeyID.SendAndSnooze]: {
          id: HotkeyID.SendAndSnooze,
          keys: 'cmd+alt+Enter',
          display: [this.modifierKey, this.altKey, 'Enter'],
        },
        [HotkeyID.SendAndCrossPost]: {
          id: HotkeyID.SendAndCrossPost,
          keys: 'cmd+shift+Enter',
          display: [this.modifierKey, 'Shift', 'Enter'],
        },
        [HotkeyID.ChangePriority]: {
          id: HotkeyID.ChangePriority,
          ...letterKeyShortcut('p'),
          display: ['P'],
        },
        [HotkeyID.SnoozeAction]: {
          id: HotkeyID.SnoozeAction,
          ...letterKeyShortcut('z'),
          display: ['Z'],
        },
        [HotkeyID.AssignToAction]: {
          id: HotkeyID.AssignToAction,
          ...letterKeyShortcut('a'),
          display: ['A'],
        },
        [HotkeyID.JumpToAction]: {
          id: HotkeyID.JumpToAction,
          ...letterKeyShortcut('g'),
          display: ['G'],
        },
        [HotkeyID.Emoji]: {
          id: HotkeyID.Emoji,
          keys: 'shift+;',
          display: [':'],
        },
        [HotkeyID.ComposeReply]: {
          id: HotkeyID.ComposeReply,
          ...letterKeyShortcut('r'),
          display: ['R'],
        },
        [HotkeyID.ComposeNote]: {
          id: HotkeyID.ComposeNote,
          ...letterKeyShortcut('n'),
          display: ['N'],
        },
        [HotkeyID.MergeInto]: {
          id: HotkeyID.MergeInto,
          keys: 'cmd+shift+KeyM',
          display: [this.modifierKey, 'Shift', 'M'],
        },
        [HotkeyID.UseMacro]: {
          id: HotkeyID.UseMacro,
          keys: '\\',
          alternateKeys: [...alternateKeysLetter('m'), ...alternateKeysLetter('s')],
          display: ['\\'],
        },
        [HotkeyID.InsertGif]: {
          id: HotkeyID.InsertGif,
          keys: 'cmd+shift+KeyG',
          display: [this.modifierKey, 'Shift', 'G'],
        },
        [HotkeyID.InsertImage]: {
          id: HotkeyID.InsertImage,
          keys: 'cmd+shift+KeyI',
          display: [this.modifierKey, 'Shift', 'I'],
        },
        [HotkeyID.InsertArticle]: {
          id: HotkeyID.InsertArticle,
          keys: 'cmd+shift+KeyH',
          display: [this.modifierKey, 'Shift', 'H'],
        },
        [HotkeyID.AddAttachment]: {
          id: HotkeyID.AddAttachment,
          keys: 'cmd+shift+KeyA',
          display: [this.modifierKey, 'Shift', 'A'],
        },
        [HotkeyID.NewConversation]: {
          id: HotkeyID.NewConversation,
          ...letterKeyShortcut('c'),
          display: ['C'],
        },
        [HotkeyID.TagLastUserComment]: {
          id: HotkeyID.TagLastUserComment,
          keys: 'shift+KeyT',
          display: ['Shift', 'T'],
        },
        [HotkeyID.SwitchView]: {
          id: HotkeyID.SwitchView,
          ...letterKeyShortcut('l'),
          display: ['L'],
        },
        [HotkeyID.ToggleLeftNav]: {
          id: HotkeyID.ToggleLeftNav,
          keys: '[',
          display: ['['],
        },
        [HotkeyID.ToggleConversationDetailsSidebar]: {
          id: HotkeyID.ToggleConversationDetailsSidebar,
          keys: ']',
          display: [']'],
        },
        [HotkeyID.TagUser]: {
          id: HotkeyID.TagUser,
          keys: 't',
          display: ['t'],
        },
        [HotkeyID.KeyboardShortcuts]: {
          id: HotkeyID.KeyboardShortcuts,
          keys: '?',
          display: ['?'],
        },
        [HotkeyID.ToggleEvents]: {
          id: HotkeyID.ToggleEvents,
          ...(platform.isMac ? letterKeyShortcut('e', 'cmd') : { keys: 'cmd+shift+KeyE' }),
          display: platform.isMac ? [this.modifierKey, 'E'] : [this.modifierKey, 'Shift', 'E'],
        },
        [HotkeyID.NavigateUp]: {
          id: HotkeyID.NavigateUp,
          ...letterKeyShortcut('j'),
          display: ['J'],
        },
        [HotkeyID.NavigateDown]: {
          id: HotkeyID.NavigateDown,
          ...letterKeyShortcut('k'),
          display: ['K'],
        },
        [HotkeyID.JumpToBottom]: {
          id: HotkeyID.JumpToBottom,
          keys: 'cmd+ArrowDown',
          display: [this.modifierKey, '↓'],
        },
        [HotkeyID.JumpToTop]: {
          id: HotkeyID.JumpToTop,
          keys: 'cmd+ArrowUp',
          display: [this.modifierKey, '↑'],
        },
        [HotkeyID.NavigateArrowUp]: {
          id: HotkeyID.NavigateArrowUp,
          keys: 'ArrowUp',
          display: ['↑'],
        },
        [HotkeyID.NavigateArrowDown]: {
          id: HotkeyID.NavigateArrowDown,
          keys: 'ArrowDown',
          display: ['↓'],
        },
        [HotkeyID.PageDown]: {
          id: HotkeyID.JumpToTop,
          keys: 'ArrowDown',
          display: ['↓'],
        },
        [HotkeyID.PageUp]: {
          id: HotkeyID.PageUp,
          keys: 'ArrowUp',
          display: ['↑'],
        },
        [HotkeyID.Undo]: {
          id: HotkeyID.Undo,
          ...letterKeyShortcut('z', 'cmd'),
          display: [this.modifierKey, 'z'],
        },
        [HotkeyID.ConvertToTicket]: {
          id: HotkeyID.ConvertToTicket,
          ...letterKeyShortcut('b', 'cmd'),
          display: [this.modifierKey, 'b'],
        },
        [HotkeyID.TriggerWorkflowConnectorAction]: {
          id: HotkeyID.TriggerWorkflowConnectorAction,
          keys: 'cmd+shift+KeyJ',
          display: [this.modifierKey, 'Shift', 'J'],
        },
        [HotkeyID.Impersonate]: {
          id: HotkeyID.Impersonate,
          ...letterKeyShortcut('i', 'shift'),
          display: ['Shift', 'I'],
        },
        [HotkeyID.ToggleAll]: {
          id: HotkeyID.ToggleAll,
          ...letterKeyShortcut('a', 'cmd'),
          display: [this.modifierKey, 'a'],
        },
        [HotkeyID.ToggleSelect]: {
          id: HotkeyID.ToggleSelect,
          ...letterKeyShortcut('x'),
          display: ['X'],
        },
        [HotkeyID.ToggleDown]: {
          id: HotkeyID.ToggleDown,
          keys: 'shift+ArrowDown',
          display: ['Shift', '↓'],
        },
        [HotkeyID.ToggleUp]: {
          id: HotkeyID.ToggleUp,
          keys: 'shift+ArrowUp',
          display: ['Shift', '↑'],
        },
        [HotkeyID.Search]: {
          id: HotkeyID.Search,

          ...letterKeyShortcut('p', 'cmd'),
          display: [this.modifierKey, 'p'],
        },
        [HotkeyID.AiAssist]: {
          id: HotkeyID.AiAssist,
          ...letterKeyShortcut('j', 'cmd'),
          display: [this.modifierKey, 'j'],
        },
        [HotkeyID.UpdateTicketState]: {
          id: HotkeyID.UpdateTicketState,
          ...letterKeyShortcut('s', 'cmd'),
          display: [this.modifierKey, 's'],
        },
        [HotkeyID.ResolveAndCloseTicketState]: {
          id: HotkeyID.ResolveAndCloseTicketState,
          ...letterKeyShortcut('s', 'cmd+shift'),
          display: [this.modifierKey, 'Shift', 's'],
        },
        [HotkeyID.OpenKnowledgeBasePanel]: {
          id: HotkeyID.OpenKnowledgeBasePanel,
          ...letterKeyShortcut('b'),
          display: ['b'],
        },
        [HotkeyID.OpenCopilot]: {
          id: HotkeyID.OpenCopilot,
          keys: 'cmd+Slash',
          display: [this.modifierKey, '/'],
        },
        [HotkeyID.OpenConversationDetails]: {
          id: HotkeyID.OpenConversationDetails,
          keys: 'cmd+Period',
          display: [this.modifierKey, '.'],
        },
      };
    }
  }

  get macroMatcher(): RegExp {
    return /(#|\\)$/;
  }

  // In French AZERTY keyboard, the key w will be present in the place of z when comapred with English keyboard
  // Listening to Cmd+KeyZ means listening to Cmd+W in AZERTY keyboard, which closes the current browser tab :facepalm:
  // So we have this escape hatch where the KeyZ maps to w, then we switch back to using z
  private getUndoKeyCode() {
    if (this.keyboardLayout.getKeyForCode('KeyZ', 'Z') === 'w') {
      return 'KeyW';
    } else {
      return 'KeyZ';
    }
  }

  private getUndoKeyDisplayText() {
    if (this.keyboardLayout.getKeyForCode('KeyZ', 'Z') === 'w') {
      return 'z';
    } else {
      return this.keyboardLayout.getKeyForCode('KeyZ', 'Z');
    }
  }

  handleNotEditorHotkey(action: Function, event: KeyboardEvent, _kbEvent: any) {
    let target = event.target as Element;
    let invalidTagNames = ['input', 'textarea'];

    if (
      invalidTagNames.includes(target.tagName?.toLowerCase()) ||
      (target.getAttribute && target.getAttribute('contentEditable') === 'true')
    ) {
      return;
    }

    if (event.repeat) {
      return;
    }
    event.preventDefault();
    return action();
  }

  handleEditorHotkey(action: Function, event: KeyboardEvent, _kbEvent: any) {
    let target = event.target as Element;

    if (target.getAttribute && target.getAttribute('contentEditable') !== 'true') {
      return;
    }
    if (event.repeat) {
      return;
    }

    event.preventDefault();
    return action();
  }

  handleFocusWithin(action: Function, containerElementIdentifier: string, event: KeyboardEvent) {
    let isActionTriggerWithinContainer =
      document.activeElement?.closest(containerElementIdentifier) !== null;

    if (!isActionTriggerWithinContainer) {
      return;
    }

    event.preventDefault();
    return action(event);
  }

  @action
  disableHotkeys() {
    this.hotkeysEnabled = false;
  }

  @action
  enableHotkeys() {
    this.hotkeysEnabled = true;
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxHotkeys: InboxHotkeys;
    'inbox-hotkeys': InboxHotkeys;
  }
}
