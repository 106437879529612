/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const DeliveryChannelValidForAudience = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isMobilePush') && !model.get('selectionState.isTargetingUsers')) {
      return 'To send this as a push message, change the audience to users';
    }

    return true;
  },
});

DeliveryChannelValidForAudience.reopenClass({
  getDependentsFor() {
    return ['model.isMobilePush', 'model.selectionState.isTargetingUsers'];
  },
});

export default DeliveryChannelValidForAudience;
