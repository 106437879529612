/* RESPONSIBLE TEAM: team-web */
import Service, { inject as service } from '@ember/service';
import { sendIntercomUpdate } from 'embercom/lib/intercom-widget-helper';
import { captureException } from 'embercom/lib/sentry';

export default class PurchaseAnalyticsService extends Service {
  @service gtmService;
  @service intercomEventService;
  @service googleTagManagerService;
  @service router;
  @service cookies;

  defaultProperties = {};

  constructor() {
    super(...arguments);
    this.defaultProperties = {
      screen_size: this.gtmService.screenSize(),
    };
  }

  setDefaults(props) {
    this.defaultProperties = Object.assign(this.defaultProperties, props);
  }

  applyDefaults(event) {
    let objectCopy = Object.assign({}, event);

    return Object.assign(objectCopy, this.defaultProperties);
  }

  async trackPageView(pageView) {
    pageView.action = 'viewed';
    this.trackIntercomEvent(pageView);
    this.trackGoogleTagManager(pageView).catch((e) => captureException(e));
    await this.trackGtmPageView(pageView);
    await this.gtmService.recordOnPageviewEvent();
    return this.setGtmIdentifierCda();
  }

  trackEvent(event) {
    this.trackIntercomEvent(event);
    let gtmEvent = this.trackGtmEvent(event);
    return this.trackGoogleTagManager(event)
      .catch((e) => captureException(e))
      .finally(() => gtmEvent);
  }

  trackAnalyticsAndGtmEvent(event) {
    this.trackIntercomEvent(event);
    return this.trackGtmEvent(event);
  }

  trackIntercomEvent(event) {
    let data = this.scrubData(this.applyDefaults(event));
    this.intercomEventService.trackAnalyticsEvent(data);
  }

  trackGoogleTagManager({
    context,
    action,
    object,
    place,
    solutionId,
    planIds,
    cartAmount,
    gclid,
    emailSubmissionId,
    email,
    companySize,
  }) {
    let event = `${context}-${action}-${object}`;
    let data = this.applyDefaults({
      event,
      action,
      object,
      place,
      context,
      metadata: {
        gclid,
        email_submission_id: emailSubmissionId,
        solution_id: solutionId,
        plan_ids: planIds,
        mrr: cartAmount,
        email,
        origin: window.location.href,
        referrer: document.referrer,
        path: this.router.currentURL,
        companySize,
      },
    });
    return this.googleTagManagerService.pushEvent(data);
  }

  trackGtmPageView(pageView) {
    let data = this.scrubData(this.applyDefaults(pageView));
    return this.gtmService.recordPageView(data);
  }

  trackGtmEvent(event) {
    let identifier = `${event.context}-${event.action}-${event.object}`;
    let gtmService = this.gtmService;
    let data = this.scrubData(this.applyDefaults(event));
    return gtmService.recordEvent(identifier, data);
  }

  setGtmIdentifierCda() {
    let cookieService = this.cookies;
    let gtmCookie = cookieService.read('gtm_id');
    if (gtmCookie) {
      sendIntercomUpdate({ gtm_cookie_identifier: gtmCookie });
    }
  }

  scrubData(data) {
    delete data.email;
    return data;
  }
}
