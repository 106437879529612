/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import type Checklist from 'embercom/models/checklists/checklist';
import type Task from 'embercom/models/checklists/task';
import {
  type EmberCPError,
  toErrorResult,
  type EmberCPValidationsResult,
} from 'embercom/validations/typescript/ember-cp-validations-compat';
import { taskValidationErrors } from 'embercom/validations/typescript/checklists/task';
import containerLookup from 'embercom/lib/container-lookup';
import { isEmpty } from '@ember/utils';
import { type Paragraph } from 'embercom/models/common/blocks/paragraph';
import { type Block } from 'embercom/models/common/blocks/block';
import { MAX_NUMBER_OF_TASKS } from 'embercom/models/data/checklists/constants';

export function validateChecklist(checklist: Checklist): EmberCPValidationsResult {
  let intl = containerLookup('service:intl');
  let errors: Array<EmberCPError> = [];

  if ((checklist.taskGroups.length as number) < 1) {
    errors.push({
      attribute: 'taskGroup',
      message: intl.t('outbound.checklists.validations.no-task-groups'),
    });
  }

  if (checklist.tasks.length < 1) {
    errors.push({
      attribute: 'tasks',
      message: intl.t('outbound.checklists.validations.no-tasks'),
    });
  }

  if (checklist.tasks.length > MAX_NUMBER_OF_TASKS) {
    errors.push({
      attribute: 'tasks',
      message: intl.t('outbound.checklists.validations.too-many-tasks', {
        maximumTasks: MAX_NUMBER_OF_TASKS,
      }),
    });
  }

  if (
    isEmpty(checklist.titleBlocks) ||
    checklist.titleBlocks.any((block: Paragraph) => !block.hasContent)
  ) {
    errors.push({
      attribute: 'titleBlocks',
      message: intl.t('outbound.checklists.validations.empty-checklist-title-blocks'),
    });
  }

  let completionScreenErrors: Array<EmberCPError> = [];
  if (checklist.showCompletionScreen) {
    completionScreenErrors = validateCompletionScreen(checklist);
  }

  let taskErrors = checklist.tasks.flatMap((task: Task) => taskValidationErrors(task));
  errors = [...errors, ...taskErrors, ...completionScreenErrors];

  return toErrorResult(errors);
}

export function validateCompletionScreen(checklist: Checklist): Array<EmberCPError> {
  let intl = containerLookup('service:intl');
  let errors: Array<EmberCPError> = [];
  if (
    isEmpty(checklist.completionScreenBlocks) ||
    checklist.completionScreenBlocks.every((block: Block) => !block.hasContent)
  ) {
    errors.push({
      attribute: 'completionScreenTitleBlocks',
      message: intl.t('outbound.checklists.validations.empty-completion-text'),
    });
  }

  if (isEmpty(checklist.completionScreenButtonText)) {
    errors.push({
      attribute: 'completionScreenBodyBlocks',
      message: intl.t('outbound.checklists.validations.empty-completion-button-text'),
    });
  }
  return errors;
}
