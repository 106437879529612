/* RESPONSIBLE TEAM: team-purchase-experience */
import ApplicationSerializer from '../application';

export default class BillingStripeIntent extends ApplicationSerializer.extend({}) {
  primaryKey = 'object_id';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'billing/stripe-intent': BillingStripeIntent;
  }
}
