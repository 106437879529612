/* RESPONSIBLE TEAM: team-workflows */

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export default class WorkflowEditorService extends Service {
  @tracked declare editorStateMap: Map<string, EditorState>;

  constructor(...args: any[]) {
    super(...args);
    this.editorStateMap = new Map();
  }

  setState(workflowId: string, editorState: EditorState) {
    this.editorStateMap.set(workflowId, editorState);
  }

  getState(workflowId: string) {
    return this.editorStateMap.get(workflowId);
  }
}

declare module '@ember/service' {
  interface Registry {
    workflowEditorService: WorkflowEditorService;
    'workflow-editor-service': WorkflowEditorService;
  }
}
