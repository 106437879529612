/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

function validateLinkForPreferredDevice(value, options, model, preferredDevice) {
  let carousel = model.get('carouselScreen.localizedCarouselContent.carousel');
  if (
    model.get('carouselScreen.isLastScreen') &&
    (model.type === 'deep_link' || model.type === 'link') &&
    carousel.preferredDevices.includes(preferredDevices[preferredDevice]) &&
    !value
  ) {
    return `The specified URI link for ${options.description} on the last screen of the carousel should be valid`;
  }
  return true;
}

export default buildValidations({
  title: {
    description: 'Title',
    validators: [validator('presence', true)],
  },
  backgroundColor: {
    description: 'Background Color',
    validators: [validator('presence', true)],
  },
  textColor: {
    description: 'Text Color',
    validators: [validator('presence', true)],
  },
  iosUri: {
    description: 'Ios',
    validators: [
      validator('inline', {
        dependentKeys: [
          'model.carouselScreen.localizedCarouselContent.carousel.preferredDevices',
          'model.type',
        ],
        validate: (value, options, model, _attribute) => {
          return validateLinkForPreferredDevice(value, options, model, 'ios');
        },
      }),
    ],
  },
  androidUri: {
    description: 'Android',
    validators: [
      validator('inline', {
        dependentKeys: [
          'model.carouselScreen.localizedCarouselContent.carousel.preferredDevices',
          'model.type',
        ],
        validate: (value, options, model, _attribute) => {
          return validateLinkForPreferredDevice(value, options, model, 'android');
        },
      }),
    ],
  },
});
