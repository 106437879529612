/* RESPONSIBLE TEAM: team-frontend-tech */
import { isBlank } from '@ember/utils';
import { dasherize } from '@ember/string';
import { A } from '@ember/array';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  optionalAttributes: A(),
  keyForAttribute(attr) {
    return attr;
  },
  normalize(modelClass, resourceHash) {
    let result = this._super(modelClass, resourceHash);
    if (result.data.attributes.type) {
      Object.assign(result.data.attributes, resourceHash, {
        modelKey: `common/blocks/${dasherize(result.data.attributes.type)}`,
      });
    }
    if (result.data.attributes.type === 'video') {
      Object.assign(result.data.attributes, {
        videoId: resourceHash.id,
      });
      delete result.data.attributes.id;
    }
    if (result.data.attributes.type === 'attachmentList') {
      result.data.attributes.attachments = result.data.attributes.attachments || [];
      result.data.attributes.attachments = result.data.attributes.attachments.map((attachment) => {
        let copiedAttachment = Object.assign({}, attachment, {
          attachmentId: attachment.id,
        });

        delete copiedAttachment.id;
        return copiedAttachment;
      });
    }
    return result;
  },
  serialize(snapshot, options) {
    let serialized = this._super(snapshot, options);
    this.optionalAttributes.forEach((optionalAttribute) => {
      if (isBlank(serialized[optionalAttribute])) {
        delete serialized[optionalAttribute];
      }
    });
    return serialized;
  },
});
