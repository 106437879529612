/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';

import { inject as service } from '@ember/service';

export default class SurveyPathPredicates extends BaseValidator {
  @service intl;

  validate(paths, options, model) {
    let stepsWithInvalidPredicates = paths
      .map((path) => {
        let isNonDefaultPath = path.order !== 0;
        let hasInvalidPredicates = !path.hasValidPredicates;
        let hasNoPredicates = !path.hasPredicates;

        if (isNonDefaultPath && (hasNoPredicates || hasInvalidPredicates)) {
          let pathStep = model.steps.find(
            (step) => step.id === path.stepId || step.uuid === path.stepUuid,
          );

          return pathStep.readableName;
        }
      })
      .compact()
      .uniq();

    if (stepsWithInvalidPredicates.length > 0) {
      return this.intl.t('outbound.surveys.validation-errors.no-missing-predicates', {
        stepsReadableName: stepsWithInvalidPredicates.join(', '),
      });
    }
    return true;
  }
}

SurveyPathPredicates.reopenClass({
  getDependentsFor() {
    return ['model.paths.@each.hasValidPredicates', 'model.paths.@each.hasPredicates'];
  },
});
