/* RESPONSIBLE TEAM: team-data-interop */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  url: [
    validator('presence', true, {
      messageKey: 'workflow-connector.builder.body.request.url.https',
    }),
    validator('format', { type: 'url' }),
    validator('https-except-in-development'),
  ],
});
