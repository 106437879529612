/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

const WorkflowStepSupportedByChannel = BaseValidator.extend({
  intl: service(),

  validate(stepType, _options, model) {
    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }

    let supportedChannels = model.supportedChannels;
    let targetChannels = editorConfig.targetChannels;

    if (isEmpty(targetChannels)) {
      return true; // Skip this validator until we can get the target channels
    }

    if (isEmpty(supportedChannels)) {
      return false; // TODO: Add an error message for this case
    }

    let unsupportedChannels = targetChannels.filter(
      (channel) => !supportedChannels.includes(channel),
    );

    if (!isEmpty(unsupportedChannels)) {
      return this.intl.t(
        `operator.workflows.visual-builder.validations.step-not-supported-by-channel.${model.typeKey}`,
        {
          channels: this.intl.formatList(
            unsupportedChannels.map((channel) =>
              this.intl.t('operator.workflows.channels.type', { channel }),
            ),
            {
              style: 'long',
              type: 'conjunction',
            },
          ),
        },
      );
    } else {
      return true;
    }
  },
});

WorkflowStepSupportedByChannel.reopenClass({
  getDependentsFor() {
    return ['model.supportedChannels', 'model.editorConfig', 'model.editorConfig.targetChannels'];
  },
});

export default WorkflowStepSupportedByChannel;
