/* RESPONSIBLE TEAM: team-growth-opportunities */
import Service, { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';

export default class StripeLoaderService extends Service {
  @service stripev3;
  @service appService;

  get admin() {
    return this.appService?.app?.currentAdmin;
  }

  get adminLocale() {
    //align intercom admin locale strings with stripe supported locale strings
    //en => en, fr-FR => fr, de-DE => de, pt-BR => pt, es-MX => es
    if (this.admin) {
      if (this.admin.locale === 'de-DE') {
        return 'de';
      } else if (this.admin.locale === 'es-MX') {
        return 'es';
      } else if (this.admin.locale === 'fr-FR') {
        return 'fr';
      } else if (this.admin.locale === 'pt-BR') {
        return 'pt';
      } else {
        return 'en';
      }
    }
  }

  _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  load() {
    if (this._isStaging()) {
      return this.stripev3.load(ENV.APP.stripe.sandboxPublishableKey);
    }
    if (this.adminLocale) {
      return this.stripev3.load('', { locale: this.adminLocale });
    }
    return this.stripev3.load();
  }
}
