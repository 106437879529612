/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default class StepHasInwardPaths extends BaseValidator {
  @service intl;

  validate(value, options, model) {
    if (!model.hasValidInwardPaths) {
      return `${model.readableName}: ${this.intl.t(
        'outbound.surveys.validation-errors.no-inward-paths',
      )}`;
    }

    return true;
  }
}
