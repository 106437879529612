/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const QuestionBlocksNotEmpty = BaseValidator.extend({
  validate(value, options, model) {
    if (isEmpty(model.blocks) || model.blocks.any((block) => !block.hasContent)) {
      return `Step ${model.step.get('order') + 1} Question ${model.questionOrder}: Add a title.`;
    }
    return true;
  },
});

QuestionBlocksNotEmpty.reopenClass({
  getDependentsFor() {
    return ['model.blocks.@each'];
  },
});

export default QuestionBlocksNotEmpty;
