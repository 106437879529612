/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import ajax from 'embercom/lib/ajax';
import moment from 'moment-timezone';

export default class PaymentIntentService extends Service {
  @service appService;
  @service stripev3;
  @service frontendStatsService;

  get app() {
    return this.appService.app;
  }

  @task({ drop: true })
  *addPaymentMethod(cardElement, billingCountryCode) {
    let paymentMethodResponse = yield this.createPaymentMethod(cardElement);
    let setupIntent = yield this.fetchSetupIntent(
      paymentMethodResponse.paymentMethod,
      billingCountryCode,
    );
    let completedIntent = yield this.completeSetupIntent(setupIntent);
    return completedIntent.payment_method;
  }

  async createPaymentMethod(cardElement) {
    let createPaymentMethodStartTime = moment.now();

    let resp = await this.stripev3.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    this.logStripeMetrics('stripe_create_payment_method', createPaymentMethodStartTime, resp);

    if (resp.error) {
      throw new Error(resp.error.message);
    }

    return resp;
  }

  async fetchSetupIntent(paymentMethod, billingCountryCode) {
    try {
      return await ajax({
        type: 'POST',
        url: `/ember/customers/create_setup_intent`,
        data: JSON.stringify({
          app_id: this.app.id,
          stripe_payment_method_id: paymentMethod.id,
          billing_country_code: billingCountryCode,
        }),
      });
    } catch (err) {
      let errorMessage = err.jqXHR?.responseJSON?.error;

      if (!errorMessage) {
        errorMessage = 'Unable to setup payment method';
      }

      throw new Error(errorMessage);
    }
  }

  async completeSetupIntent(setupIntent) {
    let completeSetupIntentStartTime = moment.now();

    let stripe = this.createStripeClientForAccount(setupIntent.stripe_account_id);

    let confirmedIntentResult = await stripe.confirmCardSetup(
      setupIntent.stripe_setup_intent_client_secret,
    );

    this.logStripeMetrics(
      'stripe_confirm_setup_intent',
      completeSetupIntentStartTime,
      confirmedIntentResult,
    );

    if (confirmedIntentResult?.setupIntent?.status !== 'succeeded') {
      throw new Error(confirmedIntentResult?.error?.message);
    }

    return confirmedIntentResult.setupIntent;
  }

  _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  createStripeClientForAccount(stripeAccountId) {
    let Stripe = this.getStripe();
    let stripeOptions = this.stripev3.stripeOptions;

    if (stripeAccountId) {
      stripeOptions = {
        ...stripeOptions,
        stripeAccount: stripeAccountId,
      };
    }

    if (this._isStaging()) {
      return new Stripe(ENV.APP.stripe.sandboxPublishableKey, stripeOptions);
    }

    return new Stripe(this.stripev3.publishableKey, stripeOptions);
  }

  getStripe() {
    // This global is provided by ember-stripe-elements
    return Stripe; // eslint-disable-line no-undef
  }

  logStripeMetrics(name, start, resp) {
    try {
      let time = moment().diff(start);
      let result = resp?.error ? resp?.error?.type || 'error' : 'success';
      this.frontendStatsService.enqueueInteractionMetric({ name, time, result });
      this.frontendStatsService.sendInteractionMetrics();
    } catch (err) {
      // Nothing for the moment, the Stripe response/errors won't contain any sensitive information but not logging for
      // safety.
    }
  }
}
