/* RESPONSIBLE TEAM: team-proactive-support */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  attrs: {
    predecessor: { key: 'predecessor_id' },
    successor: { key: 'successor_id' },
    series: { key: 'series_id' },
    edgeSplit: { key: 'edge_split_id' },
  },
});
