/* RESPONSIBLE TEAM: team-proactive-support */
import { isBlank } from '@ember/utils';
import { get } from '@ember/object';
import BaseValidator from 'ember-cp-validations/validators/base';

function containsUnsubscribeUrlOrLink(content) {
  return /(%7B%7B|\{\{)(\s|%20)*unsubscribe_(url|link)(\s|%20)*(%7D%7D|\}\})/.test(content);
}

const MessageVariationHtmlModeContentContainsUnsubscribe = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isHtmlMode')) {
      let content = get(value, 'firstObject.content');
      if (isBlank(content) || !containsUnsubscribeUrlOrLink(content)) {
        return 'Add an {{unsubscribe_url}} or {{unsubscribe_link}} to the body of your HTML';
      }
    }
    return true;
  },
});

MessageVariationHtmlModeContentContainsUnsubscribe.reopenClass({
  getDependentsFor() {
    return ['model.emailTemplate.isHtmlMode', 'model.block.@each.{content}'];
  },
});

export default MessageVariationHtmlModeContentContainsUnsubscribe;
