/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Service from '@ember/service';
import type Chart from 'embercom/models/reporting/custom/chart';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type Report from 'embercom/models/reporting/custom/report';
import type Range from 'embercom/models/reporting/range';

type FilterParams =
  | TimeFilterParam
  | {
      index: number;
      attribute: { id: string; field: string };
      values: string[];
      operator: string | null;
    };

type TimeFilterParam = {
  index: number;
  attribute: { id: 'time'; field: 'time' };
  values: Range;
  operator: string | null;
};

export type Filter = {
  type: string;
  data: {
    property: string;
    values: string[];
  };
};

export type Filters = {
  type: string;
  filters: Filter;
};

export default class ChartBuilderFilterService extends Service {
  @service intercomEventService: any;

  private trackAnalyticsEvent(report: Report, chart: Chart, data: Record<string, any>) {
    let shared_analytics_data = {
      object: 'custom_chart',
      custom_chart_id: chart.id,
      custom_chart_name: chart.title || 'untitled',
      custom_report_id: report.id,
      custom_report_name: report.title || 'untitled',
      currentMetricId: chart.chartSeries.firstObject.metric.id,
    };
    this.intercomEventService.trackAnalyticsEvent({
      ...shared_analytics_data,
      ...data,
    });
  }

  private applyFilter(chart: Chart, chartSeries: ChartSeries, params: FilterParams) {
    if (params.attribute.id === 'time') {
      chart.applyDateRange(params.values);
      return;
    }
    chartSeries.changeFilter(
      params.index,
      params.attribute,
      params.values as string[],
      params.operator,
    );
  }

  changeFilter(report: Report, chart: Chart, chartSeries: ChartSeries, params: FilterParams) {
    this.trackAnalyticsEvent(report, chart, {
      action: 'filtered',
      filter_name: params.attribute.id,
    });
    this.applyFilter(chart, chartSeries, params);
  }

  clearFilter(report: Report, chart: Chart, chartSeries: ChartSeries) {
    this.trackAnalyticsEvent(report, chart, { action: 'cleared_filters' });
    chartSeries.clearFilters();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'chart-builder-filter-service': ChartBuilderFilterService;
  }
}

declare module '@ember/service' {
  interface Registry {
    chartBuilderFilterService: ChartBuilderFilterService;
    'chart-builder-filter-service': ChartBuilderFilterService;
  }
}
