/* RESPONSIBLE TEAM: team-frontend-tech */
import { A } from '@ember/array';
import BlockSerializer from './block';

const OPTIONAL_BLOCK_ATTRIBUTES = A([
  'align',
  'linkUrl',
  'text',
  'trackingLinkUrl',
  'displayWidth',
]);

export default BlockSerializer.extend({
  optionalAttributes: OPTIONAL_BLOCK_ATTRIBUTES,
});
