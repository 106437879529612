/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const RulesetHasValidRulesetTriggerPredicates = BaseValidator.extend({
  validate(value, options, model) {
    if (isEmpty(model.rulesetTriggers)) {
      return true;
    }

    let allPredicatesAreValid = model.rulesetTriggers
      .map((rulesetTrigger) => {
        return rulesetTrigger.eventPredicateGroup.predicates.every(
          (predicate) => predicate.isValid,
        );
      })
      .reduce((isValid, validPredicates) => isValid && validPredicates, true);

    if (!allPredicatesAreValid) {
      return 'Please finish filtering event metadata';
    }

    return true;
  },
});

RulesetHasValidRulesetTriggerPredicates.reopenClass({
  getDependentsFor() {
    return [
      'model.rulesetTriggers.[]',
      'model.rulesetTriggers.firstObject.eventPredicateGroup.predicates.[]',
      'model.rulesetTriggers.firstObject.eventPredicateGroup.predicates.@each',
    ];
  },
});

export default RulesetHasValidRulesetTriggerPredicates;
