/* RESPONSIBLE TEAM: team-customer-data-platform */

import Service, { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

// @ts-ignore
import type AttributeService from 'embercom/services/attribute-service';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';

export default class IntercomAttributeUpdaterService extends Service {
  // @ts-ignore
  @service declare attributeService: AttributeService;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;
  @service declare store: Store;

  async createTicketAttribute(
    ticketTypeId: string,
    name: string,
    dataType: string,
    props: any = {},
  ): Promise<ConversationAttributeDescriptor> {
    return await taskFor(this.createTicketAttributeTask).perform(
      ticketTypeId,
      name,
      dataType,
      props,
    );
  }

  @dropTask *createTicketAttributeTask(
    ticketTypeId: string,
    name: string,
    dataType: string,
    props: any,
  ): TaskGenerator<any> {
    let descriptorsScopedByTicketType =
      ConversationAttributeDescriptor.peekAllAndMaybeLoad().filter(
        (d: ConversationAttributeDescriptor) => d.ticketTypeId === ticketTypeId,
      );

    let maxOrder = 0;
    if (descriptorsScopedByTicketType.length !== 0) {
      maxOrder =
        Math.max(
          ...descriptorsScopedByTicketType.map((d: ConversationAttributeDescriptor) => d.order),
        ) + 1;
    }

    let attributeDescriptor = this.store.createRecord('conversation-attributes/descriptor', {
      dataType,
      name,
      description: props.description,
      requiredToCreate: props.requiredToCreate,
      order: maxOrder,
      ticketTypeId,
    });

    try {
      yield attributeDescriptor.save();

      // There's some delay from the moment the attribute descriptor is created
      // and the moment it appears in the list of descriptors in a ticket type.
      // As a workaround we explicitly add the descriptor to the list, this seems to work fine.
      let ticket = yield this.store.findRecord('inbox/ticket-type', ticketTypeId);
      ticket.descriptors.pushObject(attributeDescriptor);

      // This line triggers the recalculation of all the computed properties that depend on the attribute descriptor
      // it's necessary so that the UI sees the newly created attribute
      this.attributeService.notifyPropertyChange('conversationAttributeDescriptors');
      return attributeDescriptor;
    } catch (error) {
      this.store.unloadRecord(attributeDescriptor);
      throw error;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    dataIntercomAttributeUpdaterService: IntercomAttributeUpdaterService;
    'data/intercom-attribute-updater-service': IntercomAttributeUpdaterService;
  }
}
