/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const FollowUpRuleIsValid = BaseValidator.extend({
  intl: service(),

  validate(value, options, model) {
    if (value.isEvery('isRuleValid')) {
      return true;
    }

    if (options.message) {
      return this.intl.t(options.message);
    } else {
      return 'Your follow up rules are incomplete.';
    }
  },
});

FollowUpRuleIsValid.reopenClass({
  getDependentsFor() {
    return ['model.followUpRules.@each.isRuleValid'];
  },
});

export default FollowUpRuleIsValid;
