/* RESPONSIBLE TEAM: team-messenger */
import Service, { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import EmberObject from '@ember/object';
import type Store from '@ember-data/store';

export interface NewsLabel {
  id: string;
  name: string;
}

export default class NewsLabelsServiceService extends Service {
  @service declare store: Store;
  @tracked labels: NewsLabel[] = [];
  @tracked labelList: any;

  @task({ drop: true }) *loadLabels(): TaskGenerator<NewsLabel[]> {
    this.labels = yield this.store.findAll('news/label');

    if (this.labels) {
      this.labelList = this.labels
        .map((label) => {
          return EmberObject.create({
            editing: false,
            label,
          });
        })
        .sortBy('label.name');
    }

    return this.labels;
  }
}

declare module '@ember/service' {
  interface Registry {
    newsLabelsServiceService: NewsLabelsServiceService;
    'news-labels-service': NewsLabelsServiceService;
  }
}
