/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';

export default class ModalService extends Service {
  @tracked isModalOpen = false;
  @tracked modalComponentModel = null;
  @tracked modalComponent = null;
  @tracked modalOptions = null;
  canCloseModal = true;

  openModal(componentName, model, options) {
    this.modalComponent = componentName;
    this.modalComponentModel = model;
    this.isModalOpen = true;
    this.modalOptions = options;
    this.canCloseModal = options?.canCloseModal ?? true;
    getOwner(this)
      .lookup('route:application')
      .send('openModal', ...arguments);
  }

  closeModal(results = {}) {
    if (!this.canCloseModal) {
      return;
    }
    getOwner(this).lookup('route:application').send('closeModal', results);
    getOwner(this).lookup('route:application').send('warnOnNavigation', false);
    this.isModalOpen = false;
    this.modalComponent = null;
    this.modalComponentModel = null;
    this.modalOptions = null;
    this.canCloseModal = true;
  }
}
