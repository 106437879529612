/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const SaveResponseToAttributeCheckedButNoAttributeSelected = BaseValidator.extend({
  validate(value, options, model) {
    if (model.hasInvalidAttributeState) {
      return `Step ${model.step.get('order') + 1} Question ${
        model.questionOrder
      }: Select a user attribute.`;
    }

    return true;
  },
});

SaveResponseToAttributeCheckedButNoAttributeSelected.reopenClass({
  getDependentsFor() {
    return ['model.saveResponseToAttribute', 'model.attributeIdentifier'];
  },
});

export default SaveResponseToAttributeCheckedButNoAttributeSelected;
