/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import MurmurHash3 from 'imurmurhash';
import moment from 'moment-timezone';

export default class ActivityTrackingSamplingService extends Service {
  @service declare appService: $TSFixMe;

  SAMPLE_RATE = 0.01;

  get shouldSampleActivity() {
    return (
      this.appService.get('app.hasDetailedActivityTracking') ||
      (this.appService.get('app.hasSampledActivityTracking') &&
        this.adminHash() <= this.SAMPLE_RATE * (2 ** 32 - 1))
    );
  }

  adminHash() {
    // sample on a 48 hour period basis,
    // where the period starts always on an even numbered ISO ordinal date
    // https://en.wikipedia.org/wiki/ISO_8601#Ordinal_dates
    let evenDay = moment();
    evenDay.dayOfYear(evenDay.dayOfYear() - (evenDay.dayOfYear() % 2));
    let hashState = MurmurHash3(evenDay.toDate().toDateString());
    hashState.hash(this.appService.get('app.currentAdmin.id'));
    return hashState.result();
  }
}
