/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const RulesetHasNoBlankClientPredicates = BaseValidator.extend({
  validate(value) {
    if (isEmpty(value)) {
      return true;
    }

    let errors = value.basePredicates
      .map((clientPredicate) => {
        if (
          !clientPredicate.isLogicalType &&
          clientPredicate.isValueBasedComparison &&
          isEmpty(clientPredicate.value)
        ) {
          return this._errorMessage(clientPredicate);
        }
        return null;
      }, this)
      .compact();

    return errors.firstObject || true;
  },

  _errorMessage(clientPredicate) {
    if (clientPredicate.isPageTarget) {
      return 'You must enter a URL value';
    } else {
      return 'You must enter a value';
    }
  },
});

RulesetHasNoBlankClientPredicates.reopenClass({
  getDependentsFor() {
    return ['model.clientPredicateGroup.basePredicates.@each.{isPageTarget,value}'];
  },
});

export default RulesetHasNoBlankClientPredicates;
