/* RESPONSIBLE TEAM: team-messenger */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default class LocalizedCarouselContentSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    carouselScreens: { embedded: 'always' },
  };
}
