/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class ArticleContentForEditing extends EmbercomBaseSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    json._isDirty = snapshot.record.get('hasChanges');

    if (snapshot.adapterOptions?.forceDraftState) {
      json.state = 'draft';
    }

    return json;
  }
}
