/* RESPONSIBLE TEAM: team-channels */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    topics: {
      serialize: 'ids',
      deserialize: 'ids',
    },
    room: {
      key: 'room_id',
    },
  },
});
