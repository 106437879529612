/* RESPONSIBLE TEAM: team-ai-agent */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';

export default class AutomationNavCounts extends Service {
  @service appService: $TSFixMe;
  @tracked allAnswers = 0;

  get app() {
    return this.appService.app;
  }

  get allAnswersCount() {
    return this.allAnswers;
  }

  async loadCustomAnswerCounts() {
    // We don't want to fail the page load in case this ever goes wrong
    try {
      let response = await ajax({
        url: '/ember/custom_answers/counts',
        type: 'GET',
        data: { app_id: this.appService.app.id },
      });

      this.allAnswers = response.all;
    } catch (e) {
      console.error(e);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    automationNavCounts: AutomationNavCounts;
    'automation-nav-counts': AutomationNavCounts;
  }
}
