/* RESPONSIBLE TEAM: team-messenger */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Ruleset from 'embercom/models/matching-system/ruleset';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

export default class NewsFeedService extends Service {
  @service declare contentEditorService: any;
  @service notificationsService: any;
  @service outboundHomeService: any;
  @service router: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked activeRuleset: any;
  @service declare appService: any;
  @tracked isNewsfeedModalOpen = false;
  @tracked showDeleteNewsfeedConfirmModal = false;
  @tracked newsfeedToBeDeletedId: any;

  get app() {
    return this.appService.app;
  }

  async createRuleset() {
    let adminId = this.appService.app.currentAdmin.id;
    let params = {
      app_id: this.appService.app.id,
      admin_id: adminId,
      match_behavior: matchBehaviors.transient,
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
      object_type: objectTypes.newsfeed,
      object_data: {
        sender_id: adminId,
      },
    };
    let newRuleset = await Ruleset.createForType(this.store, params);
    newRuleset.set('isNewRuleset', true);
    this.activeRuleset = newRuleset;
    if (this.activeRuleset) {
      this.isNewsfeedModalOpen = true;
    }
  }

  async openModal(content_wrapper_id: any) {
    let ruleset = await this.store.findRecord('matching-system/ruleset', content_wrapper_id, {
      reload: false,
      backgroundReload: false,
    });
    this.activeRuleset = ruleset;
    if (this.activeRuleset) {
      this.isNewsfeedModalOpen = true;
    }
  }

  async saveAndCloseNewsfeedModal() {
    try {
      await this.activeRuleset.save();
      this.isNewsfeedModalOpen = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.newsfeeds.notify-confirmation.newsfeed.saved'),
      );
    } catch (error) {
      this.notificationsService.notifyError(error);
    }
  }

  async openDeleteNewsfeedConfirmModal(content_wrapper_id: any) {
    this.newsfeedToBeDeletedId = content_wrapper_id;
    this.showDeleteNewsfeedConfirmModal = true;
  }

  async deleteNewsfeed() {
    try {
      let ruleset = await this.store.findRecord(
        'matching-system/ruleset',
        this.newsfeedToBeDeletedId,
        {
          reload: false,
          backgroundReload: false,
        },
      );
      await ruleset.destroyRecord();
      this.store.unloadAll('news/newsfeed');
      this.showDeleteNewsfeedConfirmModal = false;
      this.newsfeedToBeDeletedId = null;
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.newsfeeds.notify-confirmation.newsfeed.deleted'),
      );
    } catch (error) {
      this.notificationsService.notifyError(error);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    newsFeedService: NewsFeedService;
    'news-feed-service': NewsFeedService;
  }
}
