/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { isSameInbox } from 'embercom/objects/inbox/inboxes/inbox';
import type InboxState from 'embercom/services/inbox-state';
import FaviconCounter from 'embercom/lib/favicon-counter';

export default class InboxCounters extends Service {
  @service declare inboxState: InboxState;

  @tracked counts: { [key: string]: number } = {};

  faviconCounter = new FaviconCounter();

  countForInbox(inbox: Inbox): number {
    return this.counts[this.inboxKey(inbox)] || 0;
  }

  countForInboxWithIdAndCategory(id: Inbox['id'], category: Inbox['category']): number {
    return this.counts[this.generateKey(category, id)] || 0;
  }

  decrementCount(inbox: Inbox) {
    let key = this.inboxKey(inbox);
    let counts = this.counts;
    let current = counts[key];
    if (current !== undefined && current > 0) {
      counts[this.inboxKey(inbox)] = current - 1;
      this.counts = counts;
    }

    this.updateCurrentInboxFavicon(inbox);
  }

  incrementCount(inbox: Inbox) {
    let key = this.inboxKey(inbox);
    let counts = this.counts;
    let current = counts[key];
    if (current !== undefined) {
      counts[this.inboxKey(inbox)] = current + 1;
      this.counts = counts;
    }

    this.updateCurrentInboxFavicon(inbox);
  }

  updateCount(inbox: Inbox, count: number) {
    let counts = this.counts;
    counts[this.inboxKey(inbox)] = count;
    this.counts = counts;

    this.updateCurrentInboxFavicon(inbox);
  }

  updateCountFromInbox(inbox: Inbox) {
    if (inbox.count !== undefined) {
      this.updateCount(inbox, inbox.count);
    }
  }

  updateCountsFromInboxes(inboxes: Inbox[]) {
    inboxes.forEach((inbox) => {
      this.updateCountFromInbox(inbox);
    });
  }

  private inboxKey(inbox: Inbox) {
    return this.generateKey(inbox.category, inbox.id);
  }

  private generateKey(category: Inbox['category'], id: Inbox['id']) {
    return `${category}-${id}`;
  }

  private updateCurrentInboxFavicon(inbox: Inbox) {
    if (isSameInbox(inbox, this.inboxState.activeInbox)) {
      let count = this.counts[this.inboxKey(inbox)];
      if (count !== undefined) {
        this.faviconCounter.updateFaviconCounter(count);
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxCounters: InboxCounters;
    'inbox2-counters': InboxCounters;
  }
}
