/* RESPONSIBLE TEAM: team-frontend-tech */
import { dasherize } from '@ember/string';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { getOwner } from '@ember/application';

export default class SubviewSerializer extends EmbercomBaseSerializer {
  normalize(modelClass, resourceHash) {
    let result = super.normalize(...arguments);
    if (result.data.attributes.type) {
      Object.assign(result.data.attributes, resourceHash, {
        modelKey: `common/subviews/${dasherize(result.data.attributes.type)}`,
      });
    }
    if (result.data.attributes.subviews) {
      Object.assign(result.data.attributes, {
        subviews: this.normalizeRecursively(result.data.attributes.subviews),
      });
    }
    return result;
  }

  normalizeRecursively(subviews) {
    let store = getOwner(this).lookup('service:store');
    return subviews.map((subview) => {
      let modelKey = `common/subviews/${dasherize(subview.type)}`;
      return store.normalize(modelKey, subview, true).data.attributes;
    });
  }
}
