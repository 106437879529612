/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-jquery */
import Service, { inject as service } from '@ember/service';
import { parseDomain, fromUrl } from 'parse-domain';
import OpenCenteredWindow from 'embercom/lib/open-centered-window';
import TourModel from 'embercom/models/tour';
import $ from 'jquery';
import { get } from '@ember/object';
import { runTask, runDisposables } from 'ember-lifeline';
import ENV from 'embercom/config/environment';
import { tracked } from '@glimmer/tracking';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import ToursMessengerNotLoaded from 'embercom/components/notifications/tours-messenger-not-loaded';
import TooltipsMessengerNotLoaded from 'embercom/components/notifications/tooltips-messenger-not-loaded';

const INCORRECT_ORIGIN = 0;
const INCORRECT_TYPE = 1;
const INCORRECT_HOST = 2;

const INTERSECTION_WINDOW_SCALE = 0.85;

export default class IntersectionService extends Service {
  @service appService;
  @service store;
  @service intl;

  @service intercomEventService;
  @service notificationsService;
  @service missingTranslations;

  @tracked windowTitle = 'Intercom';

  app = this.appService.app;

  intersectionWindow = null;
  targetDomain = null;

  messengerLoaded = false;

  eventHandlers = {};

  registerEventHandler(eventName, handler) {
    this.eventHandlers[eventName] = handler;
  }

  unregisterEventHandler(eventName) {
    if (this.eventHandlers[eventName]) {
      delete this.eventHandlers[eventName];
    }
  }

  // Added to clean up listeners in test environment
  // https://github.com/intercom/embercom/pull/35436
  willDestroy() {
    super.willDestroy(...arguments);
    $(window).off('message.intersection');
    $(window).off('message.intersection-wait-for-ready');
    let eventKeys = Object.keys(this.eventHandlers);
    eventKeys.forEach((event) => {
      this.unregisterEventHandler(event);
    });
  }

  openTourSplashScreen() {
    this.windowTitle = 'Product Tour Editor';
    let height = this._getHeight();
    let width = this._getWidth();
    let appId = this.get('app.id');
    return OpenCenteredWindow(`/a/apps/${appId}/pre-tour`, height, width, this.windowTitle);
  }

  openTooltipsSplashScreen() {
    this.windowTitle = 'Tooltips Editor';

    let height = this._getHeight();
    let width = this._getWidth();
    let appId = this.get('app.id');
    return OpenCenteredWindow(
      `/a/apps/${appId}/pre-tour?tooltips=true`,
      height,
      width,
      this.windowTitle,
    );
  }

  openTourURLUpdateSplashScreen(url) {
    this.windowTitle = 'Product Tour Editor';
    let height = this._getHeight();
    let width = this._getWidth();
    let appId = this.get('app.id');
    return OpenCenteredWindow(
      `/a/apps/${appId}/pre-tour?current_url=${url}`,
      height,
      width,
      this.windowTitle,
    );
  }

  /**
   * Mounts an <iframe> element in the specified target element and
   * hooks into the intersection service.
   *
   * @param {string} url to which the iframe should be directed
   * @param {string} mode in which the messenger should be set
   * @param {string} target element ID on the screen where the iframe should be mounted
   * @param {object} options extra options for width, height, name of the iframe
   */
  mountIFrame(url, mode, target, options = {}) {
    $(window).off('message.intersection');
    $(window).off('message.intersection-wait-for-ready');
    let urlString = this._urlWithHttpPrefix(url);

    let targetElement = document.getElementById(target);
    if (targetElement === null) {
      console.error(`Target ID ${target} doesn't exist in the DOM.`);
      return;
    }

    let { width, height, name } = options;
    let iframe = document.createElement('iframe');
    iframe.src = urlString;
    iframe.name = name || `${target}-iframe`;

    // Pick given dimensions or default to size of the target
    iframe.width = width || targetElement.clientWidth;
    iframe.height = height || targetElement.clientHeight;

    this.targetDomain = new URL(urlString);
    targetElement.replaceChildren(iframe);
    this.intersectionWindow = iframe.contentWindow;

    this._registerWindowReadyEvents(mode);
  }

  openWindow(url, mode) {
    $(window).off('message.intersection');
    $(window).off('message.intersection-wait-for-ready');
    let urlString = this._urlWithHttpPrefix(url);
    let height = this._getHeight();
    let width = this._getWidth();
    this.intersectionWindow = OpenCenteredWindow(urlString, height, width, this.windowTitle);
    this.targetDomain = new URL(urlString);

    this._registerWindowReadyEvents(mode);
  }

  waitForMessenger(objectType) {
    if (objectType === undefined) {
      objectType = objectTypes.tour;
    }

    this.set('messengerLoaded', false);
    runTask(
      this,
      () => {
        if (this.messengerLoaded) {
          return;
        }
        this.intercomEventService.trackAnalyticsEvent({
          place: `${objectNames[objectType]}_editor`,
          action: 'loading_failed',
          object: 'tour_builder',
        });
        let notificationComponentClass =
          objectNames[objectType] === 'tour' ? ToursMessengerNotLoaded : TooltipsMessengerNotLoaded;
        this.notificationsService.notifyWarningWithModelAndComponent(
          {},
          notificationComponentClass,
          ENV.APP._1000MS * 60 * 10,
        );
      },
      ENV.APP._1000MS * 20,
    );
  }

  _registerWindowReadyEvents(mode) {
    //eslint-disable-next-line @intercom/intercom/no-jquery-window-on
    $(window).on(
      'message.intersection-wait-for-ready',
      function (event) {
        this.intersectionService.handleReadyEvent(event.originalEvent, this.mode);
      }.bind({ intersectionService: this, mode }),
    );
    //eslint-disable-next-line @intercom/intercom/no-jquery-window-on
    $(window).on('message.intersection', (event) => this.handlePostMessage(event.originalEvent));
  }

  _getHeight() {
    return Math.floor(window.outerHeight * INTERSECTION_WINDOW_SCALE);
  }

  _getWidth() {
    return Math.floor(window.outerWidth * INTERSECTION_WINDOW_SCALE);
  }

  _urlWithHttpPrefix(url) {
    if (url.match(/^http(?:s?):\/\//)) {
      return url;
    }
    return `http://${url}`;
  }

  // Error responses cannot be cloned via Structured Clone:
  // (https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm)
  // This is the default cloning algorithm used by postMessage
  // To ensure our objects may be posted, we naively deep clone them.
  _ensurePostableObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  _parseDomain(url) {
    return parseDomain(fromUrl(url?.trim()), { customTlds: ['test'] });
  }

  handleReadyEvent(event, mode) {
    if (get(event, 'data.type') !== 'intercom-snippet__ready') {
      return INCORRECT_TYPE;
    }

    let eventURL = new URL(get(event, 'origin'));
    let parsedEventURL = this._parseDomain(eventURL.href);
    let parsedTargetURL = this._parseDomain(this.targetDomain.href);

    if (!parsedEventURL || !parsedTargetURL || !this._hasSameTLD(parsedEventURL, parsedTargetURL)) {
      return INCORRECT_HOST;
    }

    this.updateTargetDomain(eventURL);

    this.set('messengerLoaded', true);
    runDisposables(this);

    let appId = this.get('app.id');
    let locale = this.intl.primaryLocale;
    this.intersectionWindow.postMessage(
      {
        type: 'intercom-snippet__boot-intersection',
        mode,
        appId,
        locale,
      },
      this.targetDomain.origin,
    );
  }

  _hasSameTLD(url1, url2) {
    return `${url1.domain}${url1.tld}` === `${url2.domain}${url2.tld}`;
  }

  // Update the targetDomain to account for a HTTP --> HTTPS change, for a example.com --> www.example.com or for a
  // example.com --> example.org change.
  updateTargetDomain(eventDomain) {
    this.targetDomain = eventDomain;
  }

  respondToRequest(event, result, data) {
    let responseData = {
      __messageType: 'intersection-service-response',
      __requestId: get(event, 'data.__requestId'),
    };
    let postMessageData = this._ensurePostableObject(Object.assign(responseData, { result }, data));

    this.intersectionWindow.postMessage(postMessageData, this.targetDomain.origin);
  }

  _formatData(data, method) {
    if (method === 'POST' || method === 'PUT') {
      return JSON.stringify(data);
    } else {
      return data;
    }
  }

  handlePostMessage(event) {
    if (get(event, 'origin') !== this.targetDomain.origin) {
      return INCORRECT_ORIGIN;
    }

    if (get(event, 'data.__messageType') !== 'intersection-service-request') {
      return INCORRECT_TYPE;
    }

    switch (get(event, 'data.__requestType')) {
      case 'ajax':
        this.handleAjaxRequest(event);
        break;
      case 'event':
        this.handleEventRequest(event);
        break;
      case 'missing-translation':
        this.handleMissingTranslation(event);
        break;
      default:
        throw new Error('Unknown event type received from Intersection');
    }
  }

  handleAjaxRequest(event) {
    let requestData = get(event, 'data.__requestData');

    // For Product Tours, we want to allow for certain requests to first check local
    // storage for the Tour instance, before falling back to the standard Ajax request
    // against the backend
    if (requestData.hash?.data?.requestLocalModel === 'tour' && requestData.method === 'GET') {
      let localTourModel = TourModel.getLocalModel({
        store: this.store,
        tourId: requestData.hash.data.id,
      });
      if (localTourModel) {
        return this.respondToRequest(event, 'success', { content: localTourModel.serialize() });
      }
    }

    let options = {
      url: requestData.url,
      method: requestData.method,
      data: this._formatData(requestData.hash.data, requestData.method),
      contentType: 'application/json',
    };

    $.ajax(options)
      .done((content) => this.respondToRequest(event, 'success', { content }))
      .fail((error) => this.respondToRequest(event, 'error', { error }));
  }

  handleEventRequest(event) {
    let requestData = get(event, 'data.__requestData');
    let handler = this.eventHandlers[requestData.eventName];
    if (handler) {
      try {
        let content = handler(requestData.eventData);
        this.respondToRequest(event, 'success', { content });
      } catch (error) {
        this.respondToRequest(event, 'error', { error });
        throw error;
      }
    } else {
      this.respondToRequest(event, 'error', {
        error: { message: `No handler for '${requestData.eventName}'` },
      });
    }
  }

  handleMissingTranslation(event) {
    try {
      let requestData = get(event, 'data.__requestData');
      let key = `intersection.${requestData.key}`;
      let localeNames = requestData.localeNames;

      this.missingTranslations.notifyMissingTranslation(key, localeNames);

      this.respondToRequest(event, 'success', null);
    } catch (e) {
      console.error('Failed to parse Intersection missing translation');
      console.error(e);
    }
  }
}

export { INCORRECT_ORIGIN, INCORRECT_TYPE, INCORRECT_HOST };
