/* RESPONSIBLE TEAM: team-frontend-tech */
export default function (tmap) {
  tmap.transition(
    tmap.hasClass('inbox__left-nav-transitions'),
    tmap.toValue(true),
    tmap.use('toRight', { duration: 200 }),
    tmap.reverse('toLeft', { duration: 200 }),
  );
  tmap.transition(
    tmap.hasClass('copilot-suggestions-carousel'),
    tmap.toValue(function (toValue, fromValue) {
      return toValue && fromValue && toValue.index > fromValue.index;
    }),
    tmap.use('toLeft', { duration: 300 }),
    tmap.reverse('toRight', { duration: 300 }),
  );
}
