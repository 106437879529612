/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default class IntroThanksStepHasContent extends BaseValidator {
  @service intl;

  validate(value, options, model) {
    if (model.textLength === 0) {
      if (model.isThankYouType) {
        return `${model.readableName}: ${this.intl.t(
          'outbound.surveys.validation-errors.no-empty-thanks-steps',
        )}`;
      } else if (model.isIntroType) {
        return `${model.readableName}: ${this.intl.t(
          'outbound.surveys.validation-errors.no-empty-intro-steps',
        )}`;
      }
    }

    return true;
  }
}

IntroThanksStepHasContent.reopenClass({
  getDependentsFor() {
    return ['model.isIntroType', 'model.isThankYouType', 'model.blocks.@each.text'];
  },
});
