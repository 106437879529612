/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    rulesetLinks: { embedded: 'always' },
    rulesetTriggers: { embedded: 'always' },
    clientData: { embedded: 'always' },
    rulesetErrors: { embedded: 'always' },
    state: { serialize: false },
    tags: { key: 'tag_ids' },
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
    currentVersion: { embedded: 'always' },
    utmSettings: { embedded: 'always' },
  },

  serialize(snapshot, options = {}) {
    let json = this._super(snapshot, options);
    json.implicit_predicates = snapshot.record.implicitPredicates;
    return json;
  },
});
