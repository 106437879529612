/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

const WorkflowStepHasBillingFeature = BaseValidator.extend({
  intl: service(),
  appService: service(),

  validate(stepType, _options, model) {
    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }

    if (!editorConfig.areStepPaywallsActive) {
      return true; // Skip this validator if step paywalls are not active
    }

    if (isEmpty(model.constructor.requiredBillingFeature)) {
      return true; // Skip this validator if there are no required billing features
    }

    if (!this.appService.app.canUseFeature(model.constructor.requiredBillingFeature)) {
      let stepName = this.intl.t(`operator.workflows.visual-builder.step-menu.${model.typeKey}`);

      return this.intl.t(`operator.workflows.visual-builder.validations.upgrade-to-use-step`, {
        stepName,
      });
    } else {
      return true;
    }
  },
});

WorkflowStepHasBillingFeature.reopenClass({
  getDependentsFor() {
    return [
      'model.requiredBillingFeature',
      'model.editorConfig',
      'model.editorConfig.areStepPaywallsActive',
      'model.appService.app.features.[]',
    ];
  },
});

export default WorkflowStepHasBillingFeature;
