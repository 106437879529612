/* RESPONSIBLE TEAM: team-help-desk-experience */

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { runTask } from 'ember-lifeline';
import type Conversation from 'embercom/objects/inbox/conversation';
import ENV from 'embercom/config/environment';
import { type EmberRunTimer } from 'ember-lifeline/types';
import { action } from '@ember/object';
import Ember from 'ember';
import { inject as service } from '@ember/service';

// maps to the types supported by IcBanner: https://pulse.internal.intercom.io/components/banner#type
export type SnackbarType = 'default' | 'error' | 'help' | 'warning' | 'confirmation';

export type Opts = Partial<{
  // Circular: Opts needs Notification which is not defined yet. Notification
  // _also_ needs Opts which won't be defined if we moved this below.  So ignore
  // for now.

  onButtonClick: (notification: Notification) => unknown;

  onClear: (notification: Notification) => unknown;
  buttonLabel: string;
  persistent: boolean;
  clearable: boolean;
  conversation: Conversation;
  type: SnackbarType;
  contentComponent?: string;
  dismissalKey?: string;
}>;

const DefaultOpts: Record<string, string> = {
  type: 'default',
};

export class Notification {
  readonly text: string;
  readonly opts: Opts;

  constructor(text: string, opts: Opts = {}) {
    this.text = text;
    this.opts = { ...DefaultOpts, ...opts };
  }

  get type() {
    return this.opts.type;
  }

  get buttonLabel() {
    return this.opts.buttonLabel;
  }

  get onButtonClick() {
    return this.opts.onButtonClick?.bind(this, this);
  }

  get onClear() {
    return this.opts.clearable ? this.opts.onClear?.bind(this, this) : undefined;
  }

  get contentComponent() {
    return this.opts.contentComponent;
  }

  get dismissalKey() {
    return this.opts.dismissalKey;
  }
}

export default class Snackbar extends Service {
  @service declare intercomEventService: any;
  @tracked notifications: Notification[] = [];

  get nonDuplicatedNotifications() {
    return [
      ...new Map(
        this.notifications.map((notification) => [notification['text'], notification]),
      ).values(),
    ];
  }

  @action
  notify(text: string, opts?: Opts) {
    let notification = new Notification(text, { ...opts, onClear: this.clearNotification });
    this.notifications = [...this.notifications, notification];

    if (!opts?.persistent) {
      this.clearNotificationLater(notification);
    }

    return notification;
  }

  @action
  notifyError(text: string, opts?: Opts) {
    return this.notify(text, {
      type: 'error',
      ...opts,
    });
  }

  @action
  clearNotification(notification: Notification) {
    this.notifications = this.notifications.without(notification);
    if (notification.dismissalKey) {
      let dismissCount = parseInt(
        localStorage.getItem(`${notification.dismissalKey}-dismiss-count`) || '0',
        10,
      );
      localStorage.setItem(
        `${notification.dismissalKey}-dismiss-count`,
        (dismissCount + 1).toString(),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `notification-dismiss`,
        context: notification.dismissalKey,
        place: 'snackbar',
      });
    }
  }

  private clearNotificationLater(notification: Notification) {
    if (Ember.testing) {
      return;
    }

    runTask(
      this,
      () => this.clearNotification(notification),
      ENV.APP.default_notification_timeout,
    ) as EmberRunTimer;
  }
}

declare module '@ember/service' {
  interface Registry {
    snackbar: Snackbar;
  }
}
