/* RESPONSIBLE TEAM: team-frontend-tech */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    archived_attributes: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
});
