/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  MEMBER_OF_TEAM,
  NOT_MEMBER_OF_TEAM,
} from 'embercom/lib/reporting/custom/data-config-builder-helpers';

export interface FilterBarItem {
  text: string;
  value: string;
  isSelected: boolean;
}

export interface SelectedFilter {
  operator: string;
  values: string[];
}
export default class FilterPatternService extends Service {
  @service intl!: IntlService;
  get mappings(): Record<string, string> {
    return {
      category: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is',
      ),
      not_in_category: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is-not',
      ),
      exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.exists',
      ),
      not_exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not-exists',
      ),
      contains: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.contains',
      ),
      starts_with: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.starts_with',
      ),
      ends_with: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.ends_with',
      ),
      not_contains: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not_contains',
      ),
      [MEMBER_OF_TEAM]: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.member_of',
      ),
      [NOT_MEMBER_OF_TEAM]: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not_member_of',
      ),
    };
  }

  makeLabel(label: string, operator: string) {
    switch (operator) {
      case 'category':
      case 'not_in_category':
      case 'contains':
      case 'starts_with':
      case 'ends_with':
      case 'not_contains':
        return `${this.mappings[operator]} ${label}`;
      default:
        return this.mappings[operator];
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'filter-pattern-service': FilterPatternService;
  }
}
