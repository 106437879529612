/* RESPONSIBLE TEAM: team-help-desk-experience */

import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';
import type InboxState from 'embercom/services/inbox-state';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type CommonPrimaryNavBarService from './common-primary-nav-bar-service';

export const NAV_WIDTH = 44;
export const NARROW_STREAM_WIDTH = 600;

// Window width at which our absolute min widths don't break the stream + sidebar display
// See: https://github.com/intercom/embercom/pull/59198
export const LAYOUT_BREAKPOINT = 1512;
export const SMALL_SCREEN_BREAKPOINT = 1268;

export const ABSOLUTE_MINIMUM_STREAM_WIDTH = 400;
export const LOCALSTORAGE_SIDEBAR_WIDTH_KEY = 'inbox.sidebarWidth';
export const DEFAULT_SIDEBAR_WIDTH = '31.5%';
export const MIN_SIDEBAR_WIDTH = 280;

export default class InboxSectionSizes extends Service {
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare commonPrimaryNavBarService: CommonPrimaryNavBarService;

  @tracked sidebarWidth: string = this.storedSidebarWidth;
  @tracked currentWindowWidth: number = window.innerWidth;

  // Public methods
  get storedSidebarWidth() {
    return this.hasValidStoredSidebarWidth
      ? storage.get(LOCALSTORAGE_SIDEBAR_WIDTH_KEY)
      : DEFAULT_SIDEBAR_WIDTH;
  }

  // The new conversation page doesn't include a conversation list, so we only need to account for the Inbox list and RHSB
  get dynamicNewConversationStreamWidth(): string {
    if (this.inboxSidebarService.isSidebarCollapsed) {
      return '100%';
    } else if (this.sidebarWidthIsPercentBased) {
      let streamPercent = 100 - this.sidebarWidthPercentage;
      return `${streamPercent}%`;
    } else {
      return `${
        window.innerWidth - this.numericalSidebarWidthValue - this.inboxListWidth - this.navBarWidth
      }px`;
    }
  }

  // The Inbox page has a conversation list, Inbox list and RHSB to account for.
  get dynamicConversationStreamWidth(): string {
    if (this.inboxSidebarService.isSidebarCollapsed) {
      return '100%';
    } else if (this.sidebarWidthIsPercentBased) {
      let streamPercent: number;
      if (window.innerWidth >= LAYOUT_BREAKPOINT && this.sidebarWidthInPixels < MIN_SIDEBAR_WIDTH) {
        let sidebarWidth = MIN_SIDEBAR_WIDTH;
        let sidebarWidthPercentage = (sidebarWidth / this.streamAndSidebarWidth) * 100;
        streamPercent = 100 - sidebarWidthPercentage;
      } else {
        streamPercent = 100 - this.sidebarWidthPercentage;
      }
      return `${streamPercent}%`;
    } else {
      return `${
        window.innerWidth -
        this.numericalSidebarWidthValue -
        this.dynamicConversationListWidth -
        this.inboxListWidth -
        this.navBarWidth
      }px`;
    }
  }

  get conversationStreamWidthInPixels(): number {
    let streamWidth = this.dynamicConversationStreamWidth;
    if (streamWidth.includes('%')) {
      return (this.conversationStreamWidthPercentage / 100) * this.streamAndSidebarWidth;
    } else {
      return this.numericalStreamWidthValue || 0;
    }
  }

  get sidebarWidthInPixels(): number {
    if (this.sidebarWidthIsPercentBased) {
      return (this.sidebarWidthPercentage / 100) * this.streamAndSidebarWidth;
    }
    return this.numericalSidebarWidthValue;
  }

  get isNarrowConversationStream(): boolean {
    if (this.currentWindowWidth < LAYOUT_BREAKPOINT) {
      return true;
    }

    if (this.numericalStreamWidthValue) {
      return this.numericalStreamWidthValue < NARROW_STREAM_WIDTH;
    } else {
      return false;
    }
  }

  // Actions
  @action
  updateWindowWidth() {
    debounce(this, this.updateCurrentWindowWidth, 200);
  }

  @action
  updateCurrentWindowWidth() {
    this.currentWindowWidth = window.innerWidth;
  }

  @action
  expandRHSBtoHalfScreen() {
    // allocate half the screen to child ticket creation view - inbox list - nav bar
    let availableSpace = window.innerWidth - this.inboxListWidth - this.navBarWidth;
    let widthInPx = Math.floor(availableSpace / 2);
    if (this.sidebarWidthIsPercentBased) {
      this.sidebarWidth = `${(widthInPx / availableSpace) * 100}%`;
    } else {
      this.sidebarWidth = `${widthInPx}px`;
    }
  }

  @action
  revertRHSBToOriginalSize() {
    // reset sidebar width
    this.sidebarWidth = this.storedSidebarWidth;
  }

  @action
  resetStreamAndSidebarLayout() {
    this.sidebarWidth = DEFAULT_SIDEBAR_WIDTH;
  }

  // Private APIs
  private get hasValidStoredSidebarWidth() {
    return !!storage.get(LOCALSTORAGE_SIDEBAR_WIDTH_KEY);
  }

  private get numericalSidebarWidthValue(): number {
    let numericalString = this.sidebarWidth.split('px')[0];
    let floatValue = Number.parseFloat(numericalString);

    if (window.innerWidth < LAYOUT_BREAKPOINT) {
      return Math.max(
        floatValue,
        0.3 * (window.innerWidth - this.inboxListWidth - this.dynamicConversationListWidth),
      );
    } else {
      return floatValue;
    }
  }

  private get numericalStreamWidthValue(): number | null {
    // We only use this to determine if the conversation header needs to use icon only buttons.
    // These buttons don't appear on the new conversation page, so we only need to check the width for the stream on the Inbox page.
    if (this.dynamicConversationStreamWidth.includes('%')) {
      // The numerical value is dynamic when the width is percentage based
      return null;
    }

    let numericalValue = this.dynamicConversationStreamWidth.split('px')[0];
    return Number.parseFloat(numericalValue);
  }

  private get inboxListWidth(): number {
    if (this.inboxState.isInboxListHidden || this.inboxState.inboxListElement === null) {
      return 0;
    } else {
      return this.inboxState.inboxListElement.getBoundingClientRect().width;
    }
  }

  private get conversationElementsContainerWidth(): number {
    if (this.inboxState.isInboxListHidden) {
      // The container is equal to the entire page width, as the Inbox list isn't on the page.
      return window.innerWidth;
    } else {
      // The container excludes the Inbox list, so we must remove its width.
      return window.innerWidth - this.inboxListWidth - this.navBarWidth;
    }
  }

  private get conversationListWidth(): number {
    // Container for: conversation list, stream, and sidebar, because % widths are calculated relative to their parent container.
    // We need to remove the width of the conversation list from the space available to display the conversation stream and sidebar.
    // The conversation list has a dynamic width (24%) combined with max-width of 460px,
    // but the page maybe not have re-rendered the conversation list element yet so we have to derive what the new width will be rather than calling .width() on the element.
    //
    // If 24% of the available space is more than 460px, the max-width styling will constrain the element to 460px, so we want to take the smaller of the values.
    return Math.min(this.conversationElementsContainerWidth * 0.24, 460);
  }

  private get dynamicConversationListWidth(): number {
    // conversation list is hidden in child ticket creation view
    if (this.inboxState.isConversationListHidden) {
      return 0;
    }

    return this.conversationListWidth;
  }

  private get sidebarWidthIsPercentBased(): boolean {
    return this.sidebarWidth.includes('%');
  }

  private get sidebarWidthPercentage(): number {
    return Number.parseFloat(this.sidebarWidth.split('%')[0]);
  }

  private get conversationStreamWidthPercentage(): number {
    return Number.parseFloat(this.dynamicConversationStreamWidth.split('%')[0]);
  }

  private get streamAndSidebarWidth(): number {
    return window.innerWidth - this.inboxListWidth - this.navBarWidth - this.conversationListWidth;
  }

  private get navBarWidth() {
    if (this.commonPrimaryNavBarService.isProductIAEnabled) {
      return this.commonPrimaryNavBarService.navBarWidth;
    }
    return NAV_WIDTH;
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxSectionSizes: InboxSectionSizes;
    'inbox-section-sizes': InboxSectionSizes;
  }
}
