/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isPresent, isEqual } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';
import MESSAGE_CONSTANTS from 'embercom/models/data/messages/message-constants';

const MessageVariationsAreDifferent = BaseValidator.extend({
  validate(value, options, model) {
    if (isPresent(value) && value.length === 2) {
      if (this._variationsAreEqual(value[0], value[1], model)) {
        return 'Your A & B messages are exactly the same';
      }
    }

    return true;
  },

  _variationsAreEqual(variationA, variationB, message) {
    let variationAContentHash = this._generate_content_hash_for_variation(variationA, message);
    let variationBContentHash = this._generate_content_hash_for_variation(variationB, message);
    return isEqual(JSON.stringify(variationAContentHash), JSON.stringify(variationBContentHash));
  },

  _generate_content_hash_for_variation(variation, message) {
    switch (message.get('type')) {
      case MESSAGE_CONSTANTS.emailType:
        return this._generateEmailContentHash(variation);
      case MESSAGE_CONSTANTS.inAppType:
        return this._generateInAppContentHash(variation);
      case MESSAGE_CONSTANTS.pushType:
        return this._generatePushOnlyContentHash(variation);
    }

    throw new Error('Invalid message type');
  },

  _generateEmailContentHash(variation) {
    return {
      blocks: variation.get('blocks').serialize(),
      subject: variation.get('subject'),
      assignToId: variation.get('assignToId'),
      fromId: variation.get('fromId'),
      customFromAddress: variation.get('hasCustomFromAddress')
        ? variation.get('customFromAddress').content.serialize()
        : null,
      customReplyToAddress: variation.get('hasCustomReplyToAddress')
        ? variation.get('customReplyToAddress').content.serialize()
        : null,
      email_style: variation.get('emailTemplate').content.serialize(),
    };
  },

  _generateInAppContentHash(variation) {
    return {
      blocks: variation.get('blocks').serialize(),
      assignToId: variation.get('assignToId'),
      fromId: variation.get('fromId'),
      messageStyle: variation.get('messageStyle'),
      replyType: variation.get('replyType'),
      deliveryOption: variation.get('deliveryOption'),
    };
  },

  _generatePushOnlyContentHash(variation) {
    return {
      blocks: variation.get('blocks').serialize(),
      pushOnlyURIs: variation.get('pushOnlyURIs')
        ? variation.get('pushOnlyURIs').serialize()
        : null,
    };
  },
});

MessageVariationsAreDifferent.reopenClass({
  getDependentsFor() {
    return [
      'model.variations',
      'model.type',
      'model.variationA.blocks.@each.{align,attachments,content,header,items,footer,language,text,type,url,videoId}',
      'model.variationB.blocks.@each.{align,attachments,content,header,items,footer,language,text,type,url,videoId}',
      'model.variations.blocks.[]',
      'model.variations.@each.subject',
      'model.variations.@each.assignToId',
      'model.variations.@each.fromId',
      'model.variations.@each.customFromAddress',
      'model.variations.@each.customReplyToAddress',
      'model.variations.@each.messageStyle',
      'model.variations.@each.pushOnlyURIs',
      'model.variations.@each.replyType',
      'model.variations.@each.deliveryOption',
    ];
  },
});

export default MessageVariationsAreDifferent;
