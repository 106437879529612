/* RESPONSIBLE TEAM: team-phone */
import BaseValidator from 'ember-cp-validations/validators/base';
import parsePhoneNumberFromString, {
  validatePhoneNumberLength,
  type ValidatePhoneNumberLengthResult,
} from 'libphonenumber-js';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

const ERROR_MESSAGES: { [key in ValidatePhoneNumberLengthResult]: string } = {
  INVALID_COUNTRY: 'validators.phone-number-format.invalid-country',
  NOT_A_NUMBER: 'validators.phone-number-format.invalid-characters',
  TOO_SHORT: 'validators.phone-number-format.too-short',
  TOO_LONG: 'validators.phone-number-format.too-long',
  INVALID_LENGTH: 'validators.phone-number-format.invalid-number',
};

export default class PhoneNumberFormat extends BaseValidator {
  @service declare intl: IntlService;

  validate(value: any) {
    let validator = validatePhoneNumberLength(value);

    if (validator) {
      return this.intl.t(ERROR_MESSAGES[validator]);
    }

    if (!parsePhoneNumberFromString(value)?.isValid()) {
      return this.intl.t('validators.phone-number-format.invalid-number');
    }

    return true;
  }
}
