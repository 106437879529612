/* RESPONSIBLE TEAM: team-messenger */
import Service from '@ember/service';

import EmberObject, { action } from '@ember/object';

import { tracked } from '@glimmer/tracking';
import { type ComponentLike } from '@glint/template';
import { inject as service } from '@ember/service';
import { type TabId } from 'embercom/services/help-center-setting-navigation-service';
import type HelpCenterSettingNavigationService from 'embercom/services/help-center-setting-navigation-service';

export interface ArticleNavigationItem {
  component: ComponentLike;
  title: string;
  showTitle?: boolean;
}

export class ArticleNavigationStackState extends EmberObject {
  @tracked items: Array<ArticleNavigationItem> = [];

  get isEmpty() {
    return this.items.length === 0;
  }

  get currentItemIndex() {
    return this.items.length;
  }

  get currentItem() {
    return this.items.lastObject;
  }

  get previousItem() {
    if (this.items.length <= 1) {
      return;
    }

    let index = this.items.length - 2;
    return this.items[index];
  }

  push({ component, title, showTitle = true }: ArticleNavigationItem) {
    this.items.pushObject({ component, title, showTitle });
  }

  pop() {
    this.items.popObject();
  }
}

export default class HelpCenterNavigationService extends Service {
  @service declare helpCenterSettingNavigationService: HelpCenterSettingNavigationService;
  @tracked state: ArticleNavigationStackState = ArticleNavigationStackState.create();

  initialize(root: ArticleNavigationItem, tabId: TabId | undefined = undefined) {
    if (tabId !== undefined) {
      let navigationState =
        this.helpCenterSettingNavigationService.lastOpenedSectionByTab[tabId]?.navigationStackState;
      if (navigationState) {
        this.state = navigationState;
        return;
      }
    }

    let state = ArticleNavigationStackState.create();
    state.push(root);
    this.state = state;
  }

  get previousItem() {
    return this.state.previousItem;
  }

  get currentItem() {
    return this.state.currentItem;
  }

  get currentItemIndex() {
    return this.state.currentItemIndex;
  }

  @action push(item: ArticleNavigationItem) {
    this.state.push(item);
    this.helpCenterSettingNavigationService.updateNavigationState(this.state);
  }

  @action pop() {
    this.state.pop();
    this.helpCenterSettingNavigationService.loadSectionsState();
  }
}
