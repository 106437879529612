/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Transform from '@ember-data/serializer/transform';
import CompanySummary from 'embercom/models/company-summary';

export default Transform.extend({
  deserialize(serialized) {
    return serialized.map((s) => {
      return CompanySummary.create(s);
    });
  },
  serialize(deserialized) {
    return deserialized;
  },
});
