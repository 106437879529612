/* RESPONSIBLE TEAM: team-customer-data-platform */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class AttributeMapping extends EmbercomBaseSerializer {
  attrs = {
    status: { serialize: false },
    mappingVisibility: { serialize: false },
    sourceAttributeType: { serialize: false },
  };
}
