/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type Store from '@ember-data/store';
import type Report from 'embercom/models/reporting/custom/report';
import ReportAccessRequestModal from 'embercom/components/reporting/custom/report-access-request-modal';
import { first, isEmpty } from 'underscore';
import type Admin from 'embercom/models/admin';

export enum AccessType {
  RESTRICTED_VIEW = 'restricted_view', // "View only" in the UI
  VIEW = 'view', // "Explore only" in the UI
  EDIT = 'edit', // "Full access" in the UI
}

export default class ReportAccessService extends Service {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare modalService: any;
  @service declare router: any;
  @service declare intercomEventService: any;

  sharedAnalyticsData = {
    object: 'report_access',
    section: 'reports',
  };

  constructor() {
    super(...arguments);
  }

  readonly DEFAULT_NEW_WORKSPACE_ACCESS = AccessType.VIEW;

  get app() {
    return this.appService.app;
  }

  fetchReportAccess = taskFor(this.fetchReportAccessTask);

  @restartableTask
  *fetchReportAccessTask(reportId: string): TaskGenerator<ReportAccessControlList[]> {
    // we do this to avoid multiple rerequests, see: https://github.com/NullVoxPopuli/ember-resources/issues/340
    yield Promise.resolve();
    // Cleanup any previously loaded records unrelated to this report and the current admin
    let currentAdminId = this.app.currentAdmin.id;
    this.store
      .peekAll('reporting/report-access-control-list')
      .filter((control) => control.reportId !== reportId && control.adminId !== currentAdminId)
      .map((control) => {
        control.unloadRecord();
      });
    return yield this.store.query('reporting/report-access-control-list', {
      report_id: reportId,
    });
  }

  get localAccessControls() {
    return this.fetchReportAccess.lastSuccessful?.value || [];
  }

  currentAdminReportAccess(reportId: string): ReportAccessControlList | undefined {
    let expectedOrder = [AccessType.EDIT, AccessType.VIEW, AccessType.RESTRICTED_VIEW];
    let controls: ReportAccessControlList[] = this.localAccessControls.filter((control) =>
      this.shouldConsiderControl(this.currentAdmin, control, reportId),
    );

    return first(
      controls.sort(
        (a, b) => expectedOrder.indexOf(a.accessType) - expectedOrder.indexOf(b.accessType),
      ),
    );
  }

  hasAnyReportAccessType(report: Report): boolean {
    return this.localAccessControls.any((control) =>
      this.shouldConsiderControl(this.currentAdmin, control, report.id),
    );
  }

  @restartableTask
  *updateReportAccess(report: Report, accessControls: ReportAccessControlList[]) {
    let accessControlsWithoutOwner = accessControls.filter(
      (control) =>
        !control.isAdminAccessControl ||
        report.isIntercomOwnedReport ||
        control.adminId !== report.createdById,
    );
    yield post('/ember/reporting/report_access_control_list/bulk_update', {
      app_id: this.appService.app.id,
      report_id: report.id,
      controls: accessControlsWithoutOwner.map((control) => {
        return {
          admin_id: control.adminId,
          access_type: control.accessType,
          role_id: control.roleId,
        };
      }),
    });

    accessControls.forEach((control: ReportAccessControlList) => {
      if (control.get('isNew')) {
        this.intercomEventService.trackAnalyticsEvent({
          ...this.sharedAnalyticsData,
          action: 'created',
          report_id: report.id,
          is_workspace: control.isWorkspaceAccessControl,
          access_type: control.accessType,
        });
      } else if (control.get('hasDirtyAttributes')) {
        this.intercomEventService.trackAnalyticsEvent({
          ...this.sharedAnalyticsData,
          action: 'changed',
          report_id: report.id,
          is_workspace: control.isWorkspaceAccessControl,
          from: control.changedAttributes().accessType?.[0],
          to: control.accessType,
        });
      }
    });
  }

  get currentAdmin(): Admin {
    return this.appService.app.currentAdmin;
  }

  adminIsCreatorOrHasEditAccess(report: Report) {
    return (
      // all report creators are seen as users with edit access to report
      this.isReportCreator(this.currentAdmin, report) ||
      this.adminHasAccessType(this.currentAdmin, report, AccessType.EDIT)
    );
  }

  isReportCreator(admin: Admin, report: Report) {
    return report.createdById === admin.id;
  }

  shouldConsiderControl(admin: Admin, control: ReportAccessControlList, reportId: string) {
    return (
      control.reportId === reportId &&
      (control.belongsToAdmin(admin) ||
        control.isWorkspaceAccessControl ||
        control.isRoleAccessForAdminRole(admin))
    );
  }

  adminHasAccessType(admin: Admin, report: Report, accessType: AccessType): boolean {
    //TODO: peeking store value should be good here
    return this.localAccessControls.any(
      (control) =>
        this.shouldConsiderControl(admin, control, report.id) && control.accessType === accessType,
    );
  }

  adminIsRestricted(report: Report) {
    //admin is Restricted if only has Restricted access control
    let admin = this.currentAdmin;
    let adminControls = this.localAccessControls.filter((control) =>
      this.shouldConsiderControl(admin, control, report.id),
    );
    return (
      adminControls.length > 0 &&
      adminControls.every((control) => control.accessType === AccessType.RESTRICTED_VIEW)
    );
  }

  loadReportAccessModal(reportId: string, onClose: () => void) {
    this.modalService.openModal(ReportAccessRequestModal, {
      reportId,
      onClose,
    });
  }

  canEditReportOrReportIsNew(report: Report) {
    if (this.appService.app.canShareReportsInternally && report.isIntercomOwnedReport) {
      return this.adminIsCreatorOrHasEditAccess(report);
    } else if (this.appService.app.canShareReportsInternally) {
      return (
        this.adminIsCreatorOrHasEditAccess(report) || report.isNew || isEmpty(report.createdById)
      );
    } else {
      return true;
    }
  }

  async saveDefaultWorkspaceAccess(report: Report) {
    let accessControls = [
      this.store.createRecord('reporting/report-access-control-list', {
        reportId: report.id,
        accessType: this.DEFAULT_NEW_WORKSPACE_ACCESS,
        adminId: null,
      }),
    ];
    await taskFor(this.updateReportAccess).perform(report, accessControls);
  }

  getAccessTypeTranslation(selectedAccessType: AccessType) {
    let translations = {
      [AccessType.EDIT]: 'reporting.custom-reports.report.share-modal.full-access',
      [AccessType.VIEW]: 'reporting.custom-reports.report.share-modal.explore-only',
      [AccessType.RESTRICTED_VIEW]: 'reporting.custom-reports.report.share-modal.view-only',
    };

    return this.intl.t(translations[selectedAccessType]);
  }
}

declare module '@ember/service' {
  interface Registry {
    reportAccessService: ReportAccessService;
    'report-access-service': ReportAccessService;
  }
}
