/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const BlocksNotEmpty = BaseValidator.extend({
  intl: service(),

  validate(value, options, model) {
    if (model.stepConfig?.allowEmptyBlocks || value.any((value) => value.hasContent)) {
      return true;
    }

    let errorMessage = options.message;

    if (options.messageTranslated === true) {
      errorMessage = this.intl.t(options.message);
    }
    return errorMessage || 'Finish writing your message';
  },
});

BlocksNotEmpty.reopenClass({
  getDependentsFor() {
    return [
      'model.{blocks,bodyBlocks,subjectBlocks}.@each.hasContent',
      'model.stepConfig.allowEmptyBlocks',
    ];
  },
});

export default BlocksNotEmpty;
