/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const PageTargetPredicates = BaseValidator.extend({
  validate(value, options, model) {
    let validations = model
      .get('selectionState.pageTargetPredicates')
      .map((pageTargetPredicate) => {
        let pageTargetValue = pageTargetPredicate.get('value');
        if (!pageTargetValue) {
          return 'Your page targeting is invalid';
        }
      })
      .compact()
      .uniq();
    if (validations.length > 0) {
      return validations.join(', ');
    }
    return true;
  },
});

PageTargetPredicates.reopenClass({
  getDependentsFor() {
    return ['model.selectionState.pageTargetPredicates.@each'];
  },
});

export default PageTargetPredicates;
