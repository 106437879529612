/* RESPONSIBLE TEAM: team-ai-agent */
import { buildValidations, validator } from 'ember-cp-validations';
import { alias, not } from '@ember/object/computed';

export default buildValidations({
  botMode: [validator('presence', true)],
  botIntroLocalizations: [
    validator('has-many', {
      disabled: alias('model.disableLocalizationsValidation'),
    }),
  ],
  botOnlyMessageLocalizations: [
    validator('has-many', {
      disabled: alias('model.disableBotOnlyMessageLocalizationsValidation'),
      dependentKeys: ['model.botMode'],
    }),
  ],
  genericTriageLocalizations: [
    validator('all-generic-triages-are-valid-or-disabled', {
      disabled: alias('model.disableGenericTriageValidation'),
    }),
  ],
  hasConsentToExternalAiDataProcessing: [
    validator('has-consent-to-external-ai-data-processing', {
      disabled: not('model.useAiAnswers'),
      dependentKeys: ['model.useAiAnswers', 'appService.app.canUseExternalAi'],
    }),
  ],
});
