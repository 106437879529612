/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
    uploads: {
      embedded: 'always',
    },
    _rule: {
      key: 'rule',
      serialize: false,
      deserialize: 'records',
    },
  },
});
