/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { type TaskGenerator } from 'ember-concurrency';
import { keepLatestTask, dropTask } from 'ember-concurrency-decorators';
import Metric, {
  type MetricDefinition,
  type FieldMetric,
} from 'embercom/objects/reporting/unified/metrics/types';
import {
  type MetricProperty,
  type MetricPropertyDefinition,
  type MetricPropertyId,
} from 'embercom/objects/reporting/unified/properties/types';
import * as outbound from 'embercom/objects/reporting/unified/metrics/outbound';
import * as series from 'embercom/objects/reporting/unified/metrics/series';
import { taskFor } from 'ember-concurrency-ts';

import { indexBy } from 'underscore';
import { isEmpty, isPresent } from '@ember/utils';

// @ts-ignore
import { cached } from 'tracked-toolbox';
import { captureException } from 'embercom/lib/sentry';
import type IntlService from 'embercom/services/intl';
import {
  type AttributeDefinition,
  Dataset,
  type Attribute,
  type DatasetDefinition,
  CUSTOM_COMPANY_ATTRIBUTE,
  CUSTOM_USER_ATTRIBUTE,
  TICKETS_ATTRIBUTES,
  SYSTEM_DEFINED_ATTRIBUTES,
  CONVERSATION_ATTRIBUTES,
  TEAM_ATTRIBUTE,
  TEAMMATE_ATTRIBUTE,
  FILTERABLE_CONVERSATION_CUSTOM_ATTRIBUTE_TYPES,
  FILTERABLE_USER_AND_COMPANY_ATTRIBUTE_TYPES,
} from 'embercom/objects/reporting/unified/datasets/types';
import { flatten } from 'underscore';
import type ReportingStandalone from 'embercom/services/reporting-standalone';

const metricDefinitions: MetricDefinition[] = [...outbound.metrics, ...series.metrics];

export const ATTRIBUTE_GROUP_ORDERING = [
  'standard',
  'conversation',
  'ticket-state',
  'timestamp',
  'fin',
  'workflows',
  'call',
  'teammate',
  'team',
  'duration',
  'copilot',
  'system.attribute',
  'conversation_custom_fields',
  'company',
  'companies.custom_data',
  'user',
  'user.custom_data',
  'ticket',
] as const;

export default class ReportingMetrics extends Service {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare reportingStandalone: ReportingStandalone;

  runtimeMetrics: { [key: string]: Metric } = {};
  frontendMetrics: Metric[] = metricDefinitions.map((definition: MetricDefinition) =>
    Metric.fromDefinition(this.translate(definition)),
  );
  frontendMetricsById = indexBy(this.frontendMetrics, (metric) => metric.id);
  // Metrics loaded from the backend
  metrics: Metric[] = [];

  @cached
  get metricsById() {
    return indexBy(this.metrics, (metric: Metric) => metric.id);
  }

  // @tracked metricProperties: MetricPropertyDefinition[] = this.preloadMetricProperties(); //TODO: replace this with loaded from backend i.e dataset attributes
  propertyVariantsMap: Record<MetricPropertyId, MetricPropertyDefinition[]> = {};

  private isSetup = false;
  private datasets: Dataset[] = [];
  private attributes: Attribute[] = [];

  async loadMetricsAndProperties() {
    if (isEmpty(this.metrics)) {
      return taskFor(this.fetchMetricsAndProperties).perform();
    }
  }

  get metricProperties(): MetricPropertyDefinition[] {
    return flatten([
      this.datasets.flatMap((dataset) => dataset.attributes),
      this.frontEndAttributes,
    ]);
  }

  get timeAttribute(): Attribute {
    return {
      id: 'time',
      field: 'time',
      name: this.intl.t('reporting.object-catalog.humanized-names.time'),
      filterTooltip: this.intl.t('reporting.filter-tooltip.time'),
      icon: 'calendar',
      type: 'date-range-filter',
      group: 'standard',
      disableMultiple: true,
      supportsBreakdown: true,
      supportsFiltering: true,
      parentAttributeId: null,
      dataType: 'timestamp',
    };
  }

  get teammateAttribute(): Attribute {
    return {
      id: 'teammate',
      field: 'teammate',
      name: this.intl.t('reporting.object-catalog.humanized-names.teammate'),
      groupTooltip: this.intl.t('reporting.group-tooltip.teammate.teammate'),
      filterTooltip: this.intl.t('reporting.filter-tooltip.teammate.teammate'),
      filterLabelPrefix: this.intl.t('reporting.label-prefix.teammate.teammate'),
      icon: 'person',
      type: 'teammate',
      group: TEAMMATE_ATTRIBUTE,
      disableMultiple: false,
      supportsBreakdown: false,
      supportsFiltering: false,
      parentAttributeId: null,
      dataType: 'teammate',
    };
  }

  get teamAttribute(): Attribute {
    return {
      id: 'team',
      field: 'team',
      name: this.intl.t('reporting.object-catalog.humanized-names.team'),
      groupTooltip: this.intl.t('reporting.group-tooltip.team.team'),
      filterTooltip: this.intl.t('reporting.filter-tooltip.team.team'),
      filterLabelPrefix: this.intl.t('reporting.label-prefix.team.team'),
      filterSelectAllLabel: this.intl.t('reporting.select-all-label.team.team'),
      icon: 'multiple-people',
      type: 'team-assigned',
      group: TEAM_ATTRIBUTE,
      disableMultiple: false,
      supportsBreakdown: false,
      supportsFiltering: false,
      parentAttributeId: null,
      dataType: 'team',
    };
  }

  get frontEndAttributes() {
    return [
      this.timeAttribute,
      this.teammateAttribute,
      this.teamAttribute,
      {
        id: 'stats.question#',
        field: 'stats.question#',
        name: this.intl.t('reporting.filter-bar.answer-to-question-filter.selector-label'),
        filterTooltip: this.intl.t('reporting.filter-bar.answer-to-question-filter.tooltip'),
        icon: 'survey-filled',
        type: 'answer_to_question',
        disableMultiple: true,
      },
    ];
  }

  @keepLatestTask
  *setup() {
    if (!this.isSetup && this.permissionsService.currentAdminCan('can_access_reporting')) {
      yield Promise.all([
        taskFor(this.loadDatasetsAndAttributes).perform(),
        taskFor(this.fetchMetricsAndProperties).perform(),
      ]);
      this.isSetup = true;
    }
  }

  @dropTask
  *loadDatasetsAndAttributes() {
    let result: { attributes: AttributeDefinition[]; datasets: DatasetDefinition[] } =
      yield this.fetchData('/ember/reporting/metric_service/datasets');

    let datasets = result.datasets.map((dataset) => Metric.camelizeKeys(dataset));
    let attributes = result.attributes.map((attribute) => Metric.camelizeKeys(attribute));

    this.datasets = Dataset.fromDefinition(attributes, datasets);
    this.attributes = flatten(this.datasets.map((dataset) => dataset.attributes));
  }

  private get datasetsById(): Record<string, Dataset> {
    return indexBy(this.datasets, ({ id }) => id);
  }

  @cached
  get attributesById(): Record<string, Attribute> {
    return indexBy([...this.attributes, this.timeAttribute], ({ id }) => id);
  }

  getAttributeById(id: string): Attribute {
    return this.attributesById[id];
  }

  getAttributesByField(field: string, datasetId: string): Attribute[] {
    return this.getDatasetAttributesFor(datasetId).filter((attribute) => attribute.field === field);
  }

  getAttributeByField(field: string, datasetId: string): Attribute | undefined {
    let attributes = this.getAttributesByField(field, datasetId);
    if (attributes.length <= 1) {
      return attributes[0];
    } else {
      throw new Error(`Multiple attributes found for field ${field} in dataset ${datasetId}`);
    }
  }

  getDatasetAttributesFor(datasetId: string): Attribute[] {
    if (this.appService.app.canUseFinStandalone) {
      return this.reportingStandalone.getAttributesForStandalone(datasetId);
    }
    return this.datasetsById[datasetId].attributes;
  }

  getDatasetById(datasetId: string): Dataset {
    return this.datasetsById[datasetId];
  }

  getDatasetCdaAttributesFor(datasetId: string): Attribute[] {
    return this.getDatasetAttributesFor(datasetId).filter((attribute) =>
      this.isCdaAttribute(attribute),
    );
  }

  getFilterableDatasetCdaAttributesFor(datasetId: string): Attribute[] {
    let userAndCompanyCdaAttributes = flatten([
      this.getDatasetAttributesForGroup(CUSTOM_USER_ATTRIBUTE, datasetId),
      this.getDatasetAttributesForGroup(CUSTOM_COMPANY_ATTRIBUTE, datasetId),
    ]).filter(
      (attribute) =>
        attribute.type && FILTERABLE_USER_AND_COMPANY_ATTRIBUTE_TYPES.includes(attribute.type),
    );

    let conversationCdaAttributes = flatten([
      this.getDatasetAttributesForGroup(CONVERSATION_ATTRIBUTES, datasetId),
      this.getDatasetAttributesForGroup(TICKETS_ATTRIBUTES, datasetId),
      this.getDatasetAttributesForGroup(SYSTEM_DEFINED_ATTRIBUTES, datasetId),
    ]).filter(
      (attribute) =>
        attribute.type && FILTERABLE_CONVERSATION_CUSTOM_ATTRIBUTE_TYPES.includes(attribute.type),
    );

    return flatten([userAndCompanyCdaAttributes, conversationCdaAttributes]);
  }

  getGroupableDatasetCdaAttributesFor(datasetId: string): Attribute[] {
    let groupableUserAndCompanyAttributeTypes = flatten([
      FILTERABLE_USER_AND_COMPANY_ATTRIBUTE_TYPES,
      'date',
    ]);

    let userAndCompanyCdaAttributes = flatten([
      this.getDatasetAttributesForGroup(CUSTOM_USER_ATTRIBUTE, datasetId),
      this.getDatasetAttributesForGroup(CUSTOM_COMPANY_ATTRIBUTE, datasetId),
    ]).filter(
      (attribute) =>
        attribute.type && groupableUserAndCompanyAttributeTypes.includes(attribute.type),
    );

    let groupableConversationCustomAttributeTypes = flatten([
      FILTERABLE_CONVERSATION_CUSTOM_ATTRIBUTE_TYPES,
      'datetime',
    ]);
    let conversationCdaAttributes = flatten([
      this.getDatasetAttributesForGroup(CONVERSATION_ATTRIBUTES, datasetId),
      this.getDatasetAttributesForGroup(TICKETS_ATTRIBUTES, datasetId),
      this.getDatasetAttributesForGroup(SYSTEM_DEFINED_ATTRIBUTES, datasetId),
    ]).filter(
      (attribute) =>
        attribute.type && groupableConversationCustomAttributeTypes.includes(attribute.type),
    );

    return flatten([userAndCompanyCdaAttributes, conversationCdaAttributes]);
  }

  private isCdaAttribute(attribute: Attribute) {
    if (attribute.group) {
      return [
        CUSTOM_COMPANY_ATTRIBUTE,
        CUSTOM_USER_ATTRIBUTE,
        TICKETS_ATTRIBUTES,
        SYSTEM_DEFINED_ATTRIBUTES,
        CONVERSATION_ATTRIBUTES,
      ].includes(attribute.group);
    }
    return false;
  }

  getAttributesForTickets(datasetId: string): Attribute[] {
    let attributes = this.getDatasetAttributesFor(datasetId);
    return attributes.filter((attribute) => attribute.id.startsWith('tickets.'));
  }

  getDatasetAttributesForGroup(group: string, datasetId: string): Attribute[] {
    return this.getDatasetAttributesFor(datasetId).filter((attribute) => attribute.group === group);
  }

  @dropTask
  *fetchMetricsAndProperties(): TaskGenerator<void> {
    let metricDefinitions = yield this.fetchData(
      '/ember/reporting/metric_service/metric_definitions',
    );
    this.metrics = metricDefinitions.map((definition: MetricDefinition) =>
      Metric.fromSnakeCaseDefinition(definition),
    );

    this.propertyVariantsMap = this.metricProperties.reduce(
      (map: Record<MetricPropertyId, MetricPropertyDefinition[]>, property) => {
        if (property.variantOf) {
          map[property.variantOf] = map[property.variantOf] || [];
          map[property.variantOf].push(property);
        }
        return map;
      },
      {},
    );
  }

  async fetchData(url: string): Promise<any> {
    return await ajax({
      url,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  @cached
  get metricPropertiesById() {
    return indexBy(this.metricProperties, (property: MetricProperty) => property.id);
  }

  get customReportMetrics(): Metric[] {
    return this.metrics.filter((metric: Metric) => metric.availableInCustomReports);
  }

  getCustomReportMetrics(isStandalone = false): Metric[] {
    let metrics = this.metrics.filter((metric: Metric) => metric.availableInCustomReports);
    if (isStandalone) {
      return this.reportingStandalone.filterMetrics(metrics);
    }
    return metrics;
  }

  get defaultCustomChartMetric(): Metric {
    return (
      this.customReportMetrics.find((metric: Metric) => metric.id === 'v1.new_conversations') ||
      this.customReportMetrics[0]
    );
  }

  getMetricById(id: string): Metric {
    let allMetrics = { ...this.runtimeMetrics, ...this.frontendMetricsById, ...this.metricsById };
    let metric = allMetrics[id];

    if (
      metric?.migration?.replacement_metric_id &&
      metric.migration.feature_flag &&
      this.appService.app.canUseFeature(metric.migration.feature_flag)
    ) {
      metric = allMetrics[metric.migration.replacement_metric_id];
    }

    if (!metric) {
      let error = new Error(`Metric definition with id ${id} not found`);
      this.captureError(error, 'getMetricById', { metricId: id });
      throw error;
    }
    return metric;
  }

  getMetricsByFamilyId(metricFamilyId: string): Metric[] {
    return this.metrics.filter((metric: Metric) => metric.metricFamilyId === metricFamilyId);
  }

  metricSupportsCustomAggregations(metricId: string): boolean {
    let metric = this.getMetricById(metricId);
    return metric.type === 'field' && (metric as FieldMetric).supportedAggregations.length > 1;
  }

  getPropertyById(id: string): MetricPropertyDefinition {
    let property = this.metricPropertiesById[id];
    if (!property) {
      throw new Error(`Metric property definition with id ${id} not found`);
    }
    return property;
  }

  getPropertiesByIds(ids: string[]): MetricPropertyDefinition[] {
    return ids.map((id) => this.getPropertyById(id));
  }

  getPropertyVariants(property: MetricPropertyDefinition): MetricPropertyDefinition[] {
    return this.propertyVariantsMap[property.id] || [];
  }

  propertyExists(id: string): boolean {
    return isPresent(this.metricPropertiesById[id]);
  }

  addTemporaryMetricDefinitions(metricDefinitions: MetricDefinition[]) {
    let metrics = metricDefinitions.map((definition: MetricDefinition) =>
      Metric.fromDefinition(this.translate(definition)),
    );
    let newMetrics = indexBy(metrics, ({ id }) => id);
    this.runtimeMetrics = { ...this.runtimeMetrics, ...newMetrics };
  }

  deleteTemporaryMetricDefinitions() {
    this.runtimeMetrics = {};
  }

  translate(definition: MetricDefinition) {
    if (definition.name && this.intl.exists(definition.name)) {
      definition.name = this.intl.t(definition.name);
    }
    if (definition.description && this.intl.exists(definition.description)) {
      definition.description = this.intl.t(definition.description);
    }
    return definition;
  }

  translateKey(maybeKey: string | null | undefined): string | null {
    if (maybeKey && this.intl.exists(maybeKey)) {
      return this.intl.t(maybeKey);
    } else {
      return maybeKey || null;
    }
  }

  captureError(error: Error, context: string, extra: Record<string, any> = {}) {
    captureException(error, {
      fingerprint: ['reporting-metrics', context],
      extra: {
        appId: this.appService.app.id,
        ...extra,
      },
      tags: {
        responsibleTeam: 'team-reporting',
        responsible_team: 'team-reporting',
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingMetrics: ReportingMetrics;
    'reporting-metrics': ReportingMetrics;
  }
}
