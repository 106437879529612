/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type InboxState from './inbox-state';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';

export default class PermissionsModal extends Service {
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare inboxState: InboxState;

  @tracked isOpen = false;
  @tracked permissionsLabel = '';
  @tracked adminsWhoCanChangePermissions: AdminSummary[] = [];

  @action onModalClose() {
    this.isOpen = false;
    this.permissionsLabel = '';
    this.adminsWhoCanChangePermissions = [];
  }

  @action async showModal({ permissionsLabel }: { permissionsLabel?: string } = {}) {
    this.permissionsLabel = permissionsLabel || '';
    this.isOpen = true;
    taskFor(this.loadAdminsWhoCanChangePermissions).perform();
  }

  @task
  *loadAdminsWhoCanChangePermissions() {
    try {
      this.adminsWhoCanChangePermissions = yield this.inboxState.getAdminsWhoCanManageTeammates();
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.common.permissions-load-admins-error'),
      );
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    permissionsModal: PermissionsModal;
    'permissions-modal': PermissionsModal;
  }
}
