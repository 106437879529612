/* RESPONSIBLE TEAM: team-frontend-tech */
import move from 'ember-animated/motions/move';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';
import { serial, wait } from 'ember-animated';
import ENV from 'embercom/config/environment';

export const SUGGESTED_ANSWERS_ANIMATION_LENGTH = ENV.APP._250MS;

export default function (tmap) {
  tmap.transition(
    tmap.hasClass('animate__suggested-answer-clusters-container'),
    tmap.toValue(true),
    tmap.use('explode', {
      pick: '.animate__suggested-answer-clusters',
      use: ['toLeft', { duration: SUGGESTED_ANSWERS_ANIMATION_LENGTH, easing: 'easeOutQuad' }],
    }),
    tmap.toValue(function (toValue) {
      return toValue.get('slideDirection') === 'next';
    }),
  );

  tmap.transition(
    tmap.hasClass('animate__suggested-answer-clusters-container'),
    tmap.toValue(true),
    tmap.use('explode', {
      pick: '.animate__suggested-answer-clusters',
      use: ['toRight', { duration: SUGGESTED_ANSWERS_ANIMATION_LENGTH, easing: 'easeOutQuad' }],
    }),
    tmap.toValue(function (toValue) {
      return toValue.get('slideDirection') === 'previous';
    }),
  );

  tmap.transition(
    tmap.hasClass('animate__answer-progress'),
    tmap.toValue(true),
    tmap.toValue(function (toValue, fromValue) {
      return toValue > fromValue;
    }),
    tmap.use('bounce', { duration: ENV.APP._1000MS }),
  );

  tmap.transition(tmap.hasClass('animate__answer-bot__first-results'), tmap.use('fade'));

  tmap.transition(
    tmap.hasClass('animate__answer-bot__example-loaded'),
    tmap.use('fade', { duration: ENV.APP._300MS }),
  );
}

export function* answerListAcceptRejectTransition({
  keptSprites,
  removedSprites,
  insertedSprites,
}) {
  yield removedSprites.forEach((x) => fadeOut(x, { duration: ENV.APP._250MS }));
  yield keptSprites.forEach(
    serial(
      () => wait(ENV.APP._100MS),
      (x) => move(x, { duration: ENV.APP._250MS }),
    ),
  );
  yield insertedSprites.forEach(
    serial(
      () => wait(ENV.APP._350MS),
      (x) => fadeIn(x, { duration: ENV.APP._250MS }),
    ),
  );
}

// this is the length of the longest animation from the block above.
export const NEW_ANSWER_ANIMATION_LENGTH = ENV.APP._600MS;
