/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { request } from 'embercom/lib/inbox/requests';
import type Session from 'embercom/services/session';
import Company, { type CompanyAttributes } from 'embercom/objects/inbox/company';
import Tagging, { type TaggingWireFormat } from 'embercom/objects/inbox/tagging';
import cached from 'embercom/lib/cached-decorator';

export default class CompanyApi extends Service {
  @service declare session: Session;

  async search(predicates: Array<Record<string, unknown>>) {
    let response = (await post(`/ember/companies/search.json`, {
      app_id: this.session.workspace.id,
      predicates,
      page: 1,
      per_page: 20,
      include_count: true,
    })) as { companies: CompanyAttributes[] };

    return response.companies.map((company) => {
      return {
        id: company.id,
        remoteCompanyId: company.remote_company_id,
        name: company.name,
      };
    });
  }

  async fetchCompanyTags(companyId: string) {
    let response = await request(
      `/ember/inbox/companies/${companyId}/tags?app_id=${this.session.workspace.id}`,
    );
    let responseJson = (await response.json()).tags as TaggingWireFormat[];

    return responseJson.map(Tagging.deserialize);
  }

  @cached({ max: 100, ttl: 5000 })
  async fetchCompany(companyId: string) {
    try {
      let response = await request(
        `/ember/companies/${companyId}?app_id=${this.session.workspace.id}`,
      );
      let json: CompanyAttributes = await response.json();
      return new Company(json);
    } catch (error) {
      return null;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    companyApi: CompanyApi;
    'company-api': CompanyApi;
  }
}
