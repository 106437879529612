/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';
import { readOnly, not, and } from '@ember/object/computed';

export default buildValidations({
  uuid: {
    description: 'Next step',
    validators: [validator('presence', true)],
  },
  controls: {
    description: 'Step control',
    validators: [validator('has-many')],
  },
  serializedBlocks: {
    description: 'Serialized Blocks',
    validators: [
      validator('workflow-step-blocks-not-empty', {
        isChatMessage: readOnly('model.isChatMessage'),
        isAnswerBot: readOnly('model.isAnswerBot'),
      }),
      validator('workflow-step-blocks-messenger-apps-are-valid', {
        disabled: not('model.appService.app.showAppsInBotsPaywall'),
      }),
      validator('workflow-step-blocks-messenger-cards-are-valid'),
    ],
  },
  followUpRules: {
    description: 'Follow up rules',
    validators: [
      validator('follow-up-rule-is-valid'),
      validator('follow-up-rule-is-complete'),
      validator('custom-bot-rule-messenger-apps-are-valid'),
    ],
  },
  simpleActionRule: {
    description: 'Follow up rules',
    validators: [
      validator('simple-action-rule-is-valid'),
      validator('custom-bot-rule-messenger-apps-are-valid'),
    ],
  },
  'stepData.customObjectSelectorParams.relationship_attribute_for_button_generation_id': {
    description: 'Custom Object Selector - Relationship attribute for button generation id',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.isCustomObjectSelector'),
      }),
    ],
  },
  'stepData.customObjectSelectorParams.object_type_display_attribute_id': {
    description: 'Custom Object Selector - Object type display attribute id',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.isCustomObjectSelector'),
      }),
    ],
  },
  'stepData.customObjectSelectorParams.relationship_attribute_for_user_selection_id': {
    description: 'Custom Object Selector - Relationship attribute for user selection id',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.isCustomObjectSelector'),
      }),
    ],
  },
  workflowConnector: {
    description: 'Workflow connector',
    validators: [
      validator('workflow-step-workflow-connector-has-action', {
        disabled: not('model.isWorkflowConnector'),
      }),
    ],
  },
  triggerableWorkflowId: {
    description: 'Handover to another bot',
    validators: [
      validator('presence', { presence: true, disabled: not('model.isTriggerWorkflow') }),
    ],
  },
  'stepData.ticketTypeId': {
    description: 'Ticket type for sending form',
    validators: [
      validator('presence', {
        presence: true,
        disabled: and('model.isNotSendTicket', 'model.isNotSendTicketTerminal'),
      }),
    ],
  },
});
