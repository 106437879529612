/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { useFunction } from 'ember-resources';
import { buildParams, request } from 'embercom/lib/inbox/requests';
import Attribute, { type AttributeWireFormat } from 'embercom/objects/inbox/attribute';
import type Session from 'embercom/services/session';

export default class AttributesApi extends Service {
  @service declare session: Session;

  @tracked private isLoadingQualificationAttributes = false;

  async listUserAttributes(): Promise<Attribute[]> {
    return this.listAttributes({ type: ['user_attributes'], displayable: true });
  }

  async listUserCompanyAttributes(): Promise<Attribute[]> {
    return this.listAttributes({ type: ['company_attributes'], displayable: true });
  }

  get templatableAttributes() {
    return this.templatableAttributesLoader.value ?? [];
  }

  get qualificationAttributes() {
    return {
      isLoading: this.isLoadingQualificationAttributes,
      value: this.qualificationAttributesLoader.value ?? [],
    };
  }

  async listAttributes(opts: {
    type: string[];
    displayable?: boolean;
    templatable?: boolean;
  }): Promise<Attribute[]> {
    let params = buildParams(this.session.workspace.id, opts);
    let response = await request(`/ember/inbox/attributes?${params}`);
    let json: { attributes: AttributeWireFormat[] } = await response.json();
    return json.attributes.map(Attribute.deserialize);
  }

  private templatableAttributesLoader = useFunction(
    this,
    async (previous: Attribute[]): Promise<Attribute[]> => {
      if (previous) {
        return previous;
      }

      return await this.listAttributes({
        type: ['user_attributes', 'company_attributes', 'event_attributes'],
        templatable: true,
      });
    },
    () => [this.session.workspace.id],
  );

  private qualificationAttributesLoader = useFunction(
    this,
    async (previous: Attribute[]): Promise<Attribute[]> => {
      if (previous) {
        return previous;
      }

      try {
        this.isLoadingQualificationAttributes = true;
        return await this.listAttributes({
          type: ['qualification_attributes'],
        });
      } finally {
        this.isLoadingQualificationAttributes = false;
      }
    },
    () => [this.session.workspace.id],
  );
}

declare module '@ember/service' {
  interface Registry {
    attributesApi: AttributesApi;
    'attributes-api': AttributesApi;
  }
}
