/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class Action extends EmbercomBaseSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    ruleset: { embedded: 'always' },
  };

  // @ts-ignore
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'findRecord') {
      this.formatMockResponseBody(payload);
    } else if (requestType === 'findAll') {
      payload.forEach((object: any) => this.formatMockResponseBody(object));
    }

    // @ts-ignore
    return super.normalizeResponse(...arguments);
  }

  private formatMockResponseBody(json: any) {
    let body = json.mock_response?.body;
    if (body !== undefined) {
      try {
        let parsedBody = JSON.parse(json.mock_response.body);
        json.mock_response.body = JSON.stringify(parsedBody, null, 4);
      } catch (e) {
        // leave the contents of mock_response.body unchanged
      }
    }
  }
}
