/* RESPONSIBLE TEAM: team-help-desk-experience */

import Service from '@ember/service';
import Evented from '@ember/object/evented';

export enum MessengerAppEvents {
  InitializeMessengerApp = 'initializeMessengerApp',
  UpdateInboxAppCanvas = 'updateInboxAppCanvas',
}

export default class MessengerAppInsertionService extends Service.extend(Evented) {
  initializeMessengerApp(
    inboxAppId: number,
    canvasCreationOptions: Record<string, unknown>,
    resolve: (value: unknown) => void,
    reject: (error: Error) => void,
  ) {
    this.trigger(
      MessengerAppEvents.InitializeMessengerApp,
      inboxAppId,
      canvasCreationOptions,
      resolve,
      reject,
    );
  }

  updateInboxAppCanvas(
    inboxAppId: number,
    resolve: (value: unknown) => void,
    reject: (error: Error) => void,
    error?: Error,
  ) {
    this.trigger(MessengerAppEvents.UpdateInboxAppCanvas, inboxAppId, error, resolve, reject);
  }
}

declare module '@ember/service' {
  interface Registry {
    messengerAppInsertionService: MessengerAppInsertionService;
    'messenger-app-insertion-service': MessengerAppInsertionService;
  }
}
