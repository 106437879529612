/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const RulesetHasRolePredicate = BaseValidator.extend({
  validate(value, options, model) {
    if (
      (model.isMemberOfSeries && model.node.isInternal) ||
      model.isTriggeredByCode ||
      model.isTriggeredByOperator
    ) {
      return true;
    }
    if (isEmpty(value)) {
      return 'Choose an audience';
    }
    return true;
  },
});

RulesetHasRolePredicate.reopenClass({
  getDependentsFor() {
    return [
      'model.isRuleset',
      'model.predicateGroup.userPredicate',
      'model.rolePredicateGroup.userPredicate',
      'model.isMemberOfSeries',
      'model.node.isInternal',
      'model.isTriggeredByCode',
      'model.isTriggeredByOperator',
    ];
  },
});

export default RulesetHasRolePredicate;
