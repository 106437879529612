/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    variations: { embedded: 'always' },
    controlGroup: { embedded: 'always' },
    goal: { embedded: 'always' },
    deliveryWindow: { embedded: 'always' },
    folder: { key: 'folder_id' },
    dependencies: { key: 'dependencies_id' },
    tags: { key: 'tag_ids' },
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
  normalize(modelClass, resourceHash) {
    resourceHash.dependencies_id = resourceHash.id;
    return this._super(modelClass, resourceHash);
  },
  serialize() {
    let json = this._super(...arguments);
    try {
      json.client_data.tab_identifier = sessionStorage.getItem('embercom_tab_identifier');
    } catch (e) {
      // Do nothing;
    }
    return json;
  },
});
