/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ApplyRules from 'embercom/models/operator/visual-builder/step/apply-rules';

export default class ApplyRulesPredicateIsValid extends BaseValidator {
  @service declare intl: IntlService;

  validate(rules: $TSFixMe, options: ValidationOptions, model: ApplyRules) {
    if (this.isRulesPredicatesSupported(rules, model)) {
      return true;
    }

    if (options.message) {
      return this.intl.t(options.message);
    } else {
      return 'Make sure all rules are set up correctly.';
    }
  }

  isRulesPredicatesSupported(rules: $TSFixMe, model: ApplyRules) {
    if (!model?.editorConfig?.runtimeMatchingTargetingAttributeIdentifiers) {
      return true;
    }
    let supportedPredicateAttributes =
      model.editorConfig.runtimeMatchingTargetingAttributeIdentifiers;
    return rules.every((rule: $TSFixMe) => {
      return this.isPredicatesSupported(rule.predicates, supportedPredicateAttributes);
    });
  }

  isPredicatesSupported(predicates: $TSFixMe, supportedPredicateAttributes: string[]) {
    return predicates.every((predicate: $TSFixMe) => {
      if (predicate.isLogicalType) {
        return this.isPredicatesSupported(predicate.predicates, supportedPredicateAttributes);
      } else {
        return this.isPredicateAttributeSupported(
          predicate.attributeIdentifier,
          supportedPredicateAttributes,
        );
      }
    });
  }

  isPredicateAttributeSupported(
    attributeIdentifier: string,
    supportedPredicateAttributes: string[],
  ) {
    return supportedPredicateAttributes.includes(attributeIdentifier);
  }

  static getDependentsFor() {
    return ['model.followUpRules'];
  }
}
