/* RESPONSIBLE TEAM: team-messenger */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { inject as service } from '@ember/service';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  appService: service(),
  attrs: {
    languages: { embedded: 'always' },
    availability: { embedded: 'always' },
    visibility: { embedded: 'always' },
    home: { embedded: 'always' },
    security: { embedded: 'always' },
    lookAndFeel: { embedded: 'always' },
    upfrontEmailCollection: { embedded: 'always' },
    privacyPolicyNotice: { embedded: 'always' },
    temporaryExpectations: { embedded: 'always' },
    replyExpectations: { embedded: 'always' },
    spaces: { embedded: 'always' },
    form: { embedded: 'always' },
    startConversationButtonText: { embedded: 'always' },
    metadata: {
      serialize: false,
      deserialize: 'records',
    },
    operatorArticleAutoReaction: { embedded: 'always' },
  },
  serializeBelongsTo(snapshot, json, relationship) {
    let key = relationship.key;
    let belongsTo = snapshot.belongsTo(key);

    if (belongsTo.record.hasUnsavedChanges ?? true) {
      this._super(snapshot, json, relationship);
    } else if (key === 'form') {
      // We need to send the form even if it hasn't changed
      this._super(snapshot, json, relationship);
    } else if (key === 'spaces') {
      // We need to also send spaces even if it hasn't changed
      this._super(snapshot, json, relationship);
    } else if (key === 'startConversationButtonText') {
      // We need to also send start conversation button text even if it hasn't changed
      this._super(snapshot, json, relationship);
    } else if (key === 'visibility') {
      // Temp fix for launcher predicates tracking for visibility
      // Launcher settings doesn't save properly when only predicates are changed
      // TODO: Remove this once we have a better solution
      this._super(snapshot, json, relationship);
    }
  },
});
