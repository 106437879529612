/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  blocks: {
    description: 'Contents',
    validators: [validator('blocks-not-empty')],
  },
  backgroundColor: {
    description: 'Background Color',
    validators: [validator('presence', true)],
  },
  textColor: {
    description: 'Text Color',
    validators: [validator('presence', true)],
  },
  verticalAlignment: {
    description: 'Vertical Alignment',
    validators: [validator('presence', true)],
  },
  carouselActions: {
    description: 'Carousel Actions',
    validators: [validator('has-many'), validator('length', { min: 1, max: 2 })],
  },
});
