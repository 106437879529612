/* RESPONSIBLE TEAM: team-frontend-tech */
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

// Dates appear to come from the future in the user table occasionally, usually because device
// clocks are out of sync with our server
// this makes them all the past

let DateFormatter = {
  timeDiff(timestamp) {
    return moment().diff(moment(timestamp));
  },
  forcedPastTimeDiff(timestamp) {
    let timeDiff = this.timeDiff(timestamp);
    if (timeDiff < 0) {
      timeDiff = 1;
    }
    return timeDiff;
  },
  forcedPastMinuteDiff(timestamp) {
    return this.forcedPastTimeDiff(timestamp) / (1000 * 60);
  },
  forcedPastFromNow(timestamp) {
    if (!timestamp || typeof timestamp === 'undefined') {
      return 'Unknown';
    }
    if (this.isJustNow(timestamp)) {
      return 'Just now';
    } else if (this.forcedPastMinuteDiff(timestamp) < 10) {
      return 'In the last 10 mins';
    }
    return moment().subtract(this.forcedPastTimeDiff(timestamp)).fromNow();
  },
  forcedPastFromNowRealTime(timestamp) {
    if (!timestamp || typeof timestamp === 'undefined') {
      return 'Unknown';
    }
    if (this.isJustNow(timestamp)) {
      return 'Just now';
    }
    return moment().subtract(this.forcedPastTimeDiff(timestamp)).fromNow();
  },
  isJustNow(timestamp) {
    if (!timestamp || typeof timestamp === 'undefined') {
      return false;
    }
    return this.forcedPastMinuteDiff(timestamp) < 2;
  },
  renderTimeStamp(timestamp) {
    return timestamp ? moment(timestamp).fromNow() : 'Unknown';
  },
  attributeFromNow(timestamp, forcePast) {
    if (!timestamp) {
      return 'Unknown';
    }
    let now = new Date();
    let target = new Date(timestamp);
    let diff = now.getTime() - target.getTime();
    if (forcePast) {
      if (diff <= 1000 * 60 * 2) {
        return 'Just now';
      }
      if (diff <= 1000 * 60 * 10) {
        return 'In the last 10 mins';
      }
      return moment(timestamp).fromNow();
    } else {
      let veryInTheFuture = diff < -1800000;
      if (veryInTheFuture) {
        return moment(timestamp).format(dateAndTimeFormats.fullDateAndTimeWithoutTimezone);
      } else {
        return moment(timestamp).fromNow();
      }
    }
  },
  fromNow(timestamp) {
    return this.renderTimeStamp(timestamp);
  },
};

export default DateFormatter;
