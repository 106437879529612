/* RESPONSIBLE TEAM: team-channels */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class SmsLanguageSerializer extends EmbercomBaseSerializer {
  attrs = {
    _id: 'id',
  };

  primaryKey = '_id';

  serialize(snapshot, options) {
    let json = super.serialize(...arguments);
    delete json.default;
    delete json.default_sms_content;
    return json;
  }
}
