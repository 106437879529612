/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const ConversationRoutingHasValidRedirect = BaseValidator.extend({
  validate(value, options, model) {
    if (
      !model.get('redirectEnabled') ||
      model.get('redirectEmail') ||
      model.get('redirectPhone') ||
      model.get('redirectUrl')
    ) {
      return true;
    }
    return 'You must supply an email, phone number or URL for conversation redirecting';
  },
});

export default ConversationRoutingHasValidRedirect;
