/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type TrackMouseTime from 'embercom/modifiers/track-mouse-time';

export default class MouseTrackingService extends Service {
  @service declare intercomEventService: $TSFixMe;
  @service declare userIdleService: $TSFixMe;
  @service declare activityTrackingSamplingService: $TSFixMe;

  trackers = new Set<TrackMouseTime>();
  timers = new Map<string, number>();

  constructor() {
    super(...arguments);
    this.userIdleService.on('idle', () => {
      this._pauseTrackers();
    });
    this.userIdleService.on('active', () => {
      this._resumeTrackers();
    });
  }

  registerTracker(tracker: TrackMouseTime) {
    this.trackers.add(tracker);
  }

  unregisterTracker(tracker: TrackMouseTime) {
    this.accumulateTime(tracker);
    this.trackers.delete(tracker);
  }

  _pauseTrackers() {
    for (let tracker of this.trackers) {
      tracker.pause();
    }
  }

  _resumeTrackers() {
    for (let tracker of this.trackers) {
      tracker.resume();
    }
  }

  accumulateTime(tracker: TrackMouseTime) {
    let key = tracker.accumulatorKey;
    let currentAccumulatedTime = this.timers.has(key) ? this.timers.get(key) ?? 0 : 0;
    this.timers.set(key, currentAccumulatedTime + (tracker.getAndResetAccumulatedTime() ?? 0));
  }

  report() {
    for (let tracker of this.trackers) {
      this.accumulateTime(tracker);
    }
    let data: { [key: string]: number } = {};
    for (let key of this.timers.keys()) {
      let time = this.timers.get(key);
      if (time !== undefined && time !== 0) {
        data[key] = time;
        this.timers.set(key, 0);
      }
    }
    if (Object.keys(data).length !== 0) {
      if (this.activityTrackingSamplingService.shouldSampleActivity) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'mousetime',
          object: 'application',
          section: 'global',
          place: 'global',
          timings: JSON.stringify(data),
        });
      }
    }
  }
}
