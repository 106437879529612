/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import AssigneeFilter from 'embercom/models/inbox/search/assignee-filter';
import TeammateAssigneeFilter from 'embercom/models/inbox/search/teammate-assignee-filter';
import TeamAssigneeFilter from 'embercom/models/inbox/search/team-assignee-filter';
import DateFilter from 'embercom/models/inbox/search/date-filter';
import TagFilter from 'embercom/models/inbox/search/tag-filter';
import User from 'embercom/models/user';
import UserFilter from 'embercom/models/inbox/search/user-filter';

const DEFAULT_SUGGESTIONS_TO_FETCH = 6;

export default class SearchSuggestionsService extends Service {
  @service appService;

  get app() {
    return this.appService.app;
  }

  getAssigneeSuggestions(query, count = DEFAULT_SUGGESTIONS_TO_FETCH) {
    return this._assigneeSuggestionsTask.perform(query, count);
  }

  getTeammateAssigneeSuggestions(query, count = DEFAULT_SUGGESTIONS_TO_FETCH) {
    return this._teammateAssigneeSuggestionsTask.perform(query, count);
  }

  getTeamAssigneeSuggestions(query, count = DEFAULT_SUGGESTIONS_TO_FETCH) {
    return this._teamAssigneeSuggestionsTask.perform(query, count);
  }

  getDateSuggestions() {
    return this._dateSuggestionsTask.perform();
  }

  getTagSuggestions(query, count = DEFAULT_SUGGESTIONS_TO_FETCH, selectedTags = []) {
    return this._tagSuggestionsTask.perform(query, count, selectedTags);
  }

  getUserSuggestions(
    query,
    count = DEFAULT_SUGGESTIONS_TO_FETCH,
    selectedUsers = [],
    searchComparison = 'contains',
  ) {
    return this._userSuggestionsTask.perform(query, count, selectedUsers, searchComparison);
  }

  @task({ restartable: true })
  *_assigneeSuggestionsTask(query, count) {
    let assignees = this.app.assignableAdmins;
    return yield this._filterByName(query, assignees)
      .slice(0, count)
      .map((assignee) =>
        AssigneeFilter.create({
          assignee,
          value: assignee.id,
        }),
      );
  }

  @task({ restartable: true })
  *_teammateAssigneeSuggestionsTask(query, count) {
    let assignees = this.app.assignableAdmins.filterBy('isTeam', false);
    return yield this._filterByName(query, assignees)
      .slice(0, count)
      .map((assignee) =>
        TeammateAssigneeFilter.create({
          assignee,
          value: assignee.id,
        }),
      );
  }

  @task({ restartable: true })
  *_teamAssigneeSuggestionsTask(query, count) {
    let assignees = this.app.assignableAdmins.filterBy('isTeam', true);
    assignees.unshift(this.app.unassignedAdmin);
    return yield this._filterByName(query, assignees)
      .slice(0, count)
      .map((assignee) =>
        TeamAssigneeFilter.create({
          assignee,
          value: assignee.id,
        }),
      );
  }

  @task({ drop: true })
  *_dateSuggestionsTask() {
    let now = moment();
    let today = now.clone();
    let yesterday = now.clone().subtract(1, 'days');

    return yield [
      DateFilter.create({
        suggestionText: 'Today',
        range: {
          start: today,
          end: today,
        },
      }),
      DateFilter.create({
        suggestionText: 'Yesterday',
        range: {
          start: yesterday,
          end: yesterday,
        },
      }),
      DateFilter.create({
        suggestionText: 'Last 30 days',
        range: {
          start: now.clone().subtract(30, 'days'),
          end: today,
        },
      }),
      DateFilter.create({
        suggestionText: 'Last 90 days',
        range: {
          start: now.clone().subtract(90, 'days'),
          end: today,
        },
      }),
    ];
  }

  @task({ restartable: true })
  *_tagSuggestionsTask(query, count, selectedTags) {
    let tags = this.app.tags.filter((tag) => !selectedTags.includes(tag));

    return yield this._filterByName(query, tags)
      .slice(0, count)
      .map((tag) => TagFilter.create({ tag, value: tag.id }));
  }

  @task({ restartable: true })
  *_userSuggestionsTask(query, count, selectedUsers, searchComparison) {
    let debounceTimeout = searchComparison === 'starts_with' ? ENV.APP._100MS : ENV.APP._750MS;
    yield timeout(debounceTimeout);

    let predicates = [
      {
        type: 'or',
        predicates: [
          { attribute: 'name', comparison: searchComparison, type: 'string', value: query },
          { attribute: 'email', comparison: searchComparison, type: 'string', value: query },
        ],
      },
    ];
    let options = {
      page: 1,
      per_page: count + selectedUsers.length,
      sort_by: 'name',
      sort_direction: 'asc',
    };

    let searchResults = yield User.search(this.app, predicates, options);
    return searchResults.users
      .filter((user) => !selectedUsers.includes(user))
      .slice(0, count)
      .map((user) => UserFilter.create({ user, value: user.id }));
  }

  _filterByName(query, arr) {
    let lowerCaseQuery = query.toLowerCase();
    return arr.filter((item) => item.name.toLowerCase().includes(lowerCaseQuery));
  }
}
