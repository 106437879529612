/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import {
  type TopLevelFilter,
  type LogicalFilter,
  type Filter,
} from 'embercom/components/reporting/custom/filters';
import {
  categoryFilters,
  existsFilters,
  MEMBER_OF_TEAM,
  NOT_MEMBER_OF_TEAM,
} from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type Admin from 'embercom/models/admin';
import type { MetricPropertyDefinition } from 'embercom/objects/reporting/unified/properties/types';
// @ts-ignore
import { copy } from 'ember-copy';

const TEAM_ADMIN_PARTICIPANTS_IDS = '$admin_participant_ids';

export function isPresent(
  topLevelFilter: TopLevelFilter,
): topLevelFilter is NonNullable<LogicalFilter> {
  return topLevelFilter !== null && Object.keys(topLevelFilter).length > 0;
}

export default class FilterMigrationService extends Service {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: any;

  migrateUnsupportedFilters(
    topLevelFilter: TopLevelFilter,
    filterableAttributes: MetricPropertyDefinition[],
  ) {
    if (isPresent(topLevelFilter)) {
      topLevelFilter.filters = topLevelFilter.filters.map((filter) =>
        this.handleMigration(filter, filterableAttributes),
      );
      return topLevelFilter;
    }
    return topLevelFilter;
  }

  private handleMigration(filter: Filter, filterableAttributes: MetricPropertyDefinition[]) {
    filter = this.migrateTeamRepliedToFilter(filter);
    filter = this.addAttributeToFilter(filter, filterableAttributes);
    return filter;
  }

  private addAttributeToFilter(filter: Filter, filterableAttributes: MetricPropertyDefinition[]) {
    if (!filter.data.attribute && filter.data.property && filterableAttributes) {
      let attribute = filterableAttributes.find(
        (attribute) => attribute.field === filter.data.property,
      );
      if (attribute) {
        filter = copy(filter, true);
        filter.data.attribute = attribute.id;
      } else {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'filter_attribute_not_found',
          property: filter.data.property,
        });
      }
    }
    return filter;
  }

  private migrateTeamRepliedToFilter(filter: Filter) {
    if (filter.data.property !== TEAM_ADMIN_PARTICIPANTS_IDS) {
      return filter;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'team_replied_to_migrated',
    });

    if (filter.data.values && filter.data.values[0] === REPORTING_FILTER_SELECT_ALL) {
      return {
        type: filter.type === 'category' ? MEMBER_OF_TEAM : NOT_MEMBER_OF_TEAM,
        data: {
          property: 'admin_participant_ids',
          values: this.appService.app.teams.map((team: Admin) => team.id),
        },
      };
    } else if (categoryFilters.includes(filter.type)) {
      return {
        type: filter.type === 'category' ? MEMBER_OF_TEAM : NOT_MEMBER_OF_TEAM,
        data: {
          property: 'admin_participant_ids',
          values: filter.data.values,
        },
      };
    } else if (existsFilters.includes(filter.type)) {
      return {
        type: filter.type === 'exists' ? MEMBER_OF_TEAM : NOT_MEMBER_OF_TEAM,
        data: {
          property: 'admin_participant_ids',
          values: this.appService.app.teams.map((team: Admin) => team.id),
        },
      };
    }
    return filter;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'filter-migration-service': FilterMigrationService;
  }
}
