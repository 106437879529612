/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const AutoMessageHasUserPredicates = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('clientData.hasOpenedAudienceStep')) {
      return true;
    } else if (model.get('isAutoOrVisitorMessage') && value.get('hasNoUserAddedPredicates')) {
      return 'Choose an audience for your message';
    } else {
      return true;
    }
  },
});

AutoMessageHasUserPredicates.reopenClass({
  getDependentsFor() {
    return [
      'model.isAutoOrVisitorMessage',
      'model.selectionState.predicates.[]',
      'model.clientData.hasOpenedAudienceStep',
    ];
  },
});

export default AutoMessageHasUserPredicates;
