/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';

const WorkflowHasValidPredicatesForStatus = BaseValidator.extend({
  validate(_value, _options, model) {
    if (model.status === 'live') {
      return model.predicateGroup.predicates && model.predicateGroup.predicates.length > 0;
    }
    return true;
  },
});

WorkflowHasValidPredicatesForStatus.reopenClass({
  getDependentsFor() {
    return ['model.predicateGroup.predicates.@each', 'model.status'];
  },
});

export default WorkflowHasValidPredicatesForStatus;
