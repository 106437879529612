import Handlebars from 'handlebars';
      const _template = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return ",";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- TODO: refine design and style implementation -->\n<div class=\"avatar__container o__l\">\n  <div class=\"avatar o__l o__contained\">\n    <img class=\"avatar__media\" src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"image_urls") : stack1)) != null ? lookupProperty(stack1,"normal") : stack1), depth0))
    + "\" style=\"position:absolute;z-index:1;\">\n    <span class=\"avatar__media\" style=\"background-color: #"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + ";\">\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"initials") : stack1), depth0))
    + "\n    </span>\n  </div>\n  <div style=\"position:relative;top:5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding-right:15px;\">\n    <h3 style=\"margin:0;\">\n      <a href=\"/a/apps/"
    + alias2(((helper = (helper = lookupProperty(helpers,"app_id") || (depth0 != null ? lookupProperty(depth0,"app_id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"app_id","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":33}}}) : helper)))
    + "/users/"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":40},"end":{"line":11,"column":46}}}) : helper)))
    + "\">\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"display_as") || (depth0 != null ? lookupProperty(depth0,"display_as") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"display_as","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":22}}}) : helper)))
    + "\n      </a>\n    </h3>\n    <p style=\"margin:0;font-size:13px;opacity:0.5;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;\">\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"geoip_data") : depth0)) != null ? lookupProperty(stack1,"city_name") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"geoip_data") : depth0)) != null ? lookupProperty(stack1,"city_name") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":30},"end":{"line":16,"column":66}}})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"geoip_data") : depth0)) != null ? lookupProperty(stack1,"country_name") : stack1), depth0))
    + "\n    </p>\n  </div>\n</div>\n";
},"useData":true});
      export default _template;