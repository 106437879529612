/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const SimpleActionRuleIsValid = BaseValidator.extend({
  validate(value) {
    if (value && !value.get('isRuleValid')) {
      return 'Your action is invalid';
    }
    return true;
  },
});

SimpleActionRuleIsValid.reopenClass({
  getDependentsFor() {
    return ['model.simpleActionRule.isRuleValid'];
  },
});

export default SimpleActionRuleIsValid;
