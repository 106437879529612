/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Service, { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import escapeRegExp from 'embercom/lib/escape-regular-expression';
import ENV from 'embercom/config/environment';

import SearchClient from 'embercom/lib/search/client';

let searchClientConfig = {
  disableGlobalStopWords: true,
  fields: [
    { name: 'name', boost: 10 },
    {
      name: 'admin',
      boost: 5,
    },
    {
      name: 'body',
      boost: 1,
      stopWords: [
        'a',
        'able',
        'about',
        'across',
        'after',
        'all',
        'almost',
        'also',
        'am',
        'among',
        'an',
        'and',
        'any',
        'are',
        'as',
        'at',
        'be',
        'because',
        'been',
        'but',
        'by',
        'can',
        'cannot',
        'could',
        'dear',
        'did',
        'do',
        'does',
        'either',
        'else',
        'ever',
        'every',
        'for',
        'from',
        'get',
        'got',
        'had',
        'has',
        'have',
        'he',
        'her',
        'hers',
        'him',
        'his',
        'how',
        'however',
        'i',
        'if',
        'in',
        'into',
        'is',
        'it',
        'its',
        'just',
        'least',
        'let',
        'like',
        'likely',
        'may',
        'me',
        'might',
        'most',
        'must',
        'my',
        'neither',
        'no',
        'nor',
        'not',
        'of',
        'off',
        'often',
        'on',
        'only',
        'or',
        'other',
        'our',
        'own',
        'rather',
        'said',
        'say',
        'says',
        'she',
        'should',
        'since',
        'so',
        'some',
        'than',
        'that',
        'the',
        'their',
        'them',
        'then',
        'there',
        'these',
        'they',
        'this',
        'tis',
        'to',
        'too',
        'twas',
        'us',
        'wants',
        'was',
        'we',
        'were',
        'what',
        'when',
        'where',
        'which',
        'while',
        'who',
        'whom',
        'why',
        'will',
        'with',
        'would',
        'yet',
        'you',
        'your',
      ],
    },
  ],
};

export default class SavedReplySearchService extends Service {
  @service appService;
  @service frontendStatsService;
  @service intercomEventService;
  client = new SearchClient(searchClientConfig);

  @tracked searchableSavedReplies = [];

  get app() {
    return this.appService.app;
  }

  get savedReplies() {
    return this.app.savedReplies;
  }

  build() {
    if (!this._buildingPromise) {
      this._buildingPromise = this.savedReplies.then(() => this._build.perform());
    }
    return this._buildingPromise;
  }

  rebuild() {
    this._buildingPromise = undefined;
    return this.build();
  }

  setSearchableReplies(macroTypeToSearch) {
    if (macroTypeToSearch) {
      this.searchableSavedReplies = this.savedReplies.filter(
        ({ types }) => types.length === 0 || types.includes(macroTypeToSearch),
      );
    } else {
      this.searchableSavedReplies = this.savedReplies;
    }
  }

  typeaheadSearch(queryString, macroTypeToSearch) {
    let results = [];

    this.setSearchableReplies(macroTypeToSearch);

    if (!this._build.isRunning && this._build.performCount > 0) {
      results = this._search(queryString);
    } else {
      results = this.searchUsingRegex(this.searchableSavedReplies, queryString);
    }

    return results.slice(0, 10);
  }

  @task({ restartable: true })
  *search(queryString) {
    if (!this.app.canUseSavedRepliesFuzzySearch) {
      throw new Error('Does not have fuzzy search enabled');
    }

    this.setSearchableReplies();
    yield this.build();
    return this._search(queryString);
  }

  @task({ drop: true })
  *_build() {
    let startTime = window.performance.now();
    let savedRepliesSearchDocuments = this.savedReplies.map((reply) => ({
      id: reply.id,
      name: reply.name,
      admin: reply?.admin?.name,
      body: reply.body ? reply.body : reply.summary,
    }));
    yield this.client.build(savedRepliesSearchDocuments);
    let duration = window.performance.now() - startTime;
    this.frontendStatsService.capture('ember_savedReplySearchIndexBuildTime', duration, 0);
  }

  searchUsingRegex(replies, query, regexTestFunction = this.testAllReplyFields) {
    let escaped = escapeRegExp(query);

    let results = replies.filter((reply) => {
      let rx = new RegExp(escaped, 'gi');

      return !this._isUnsavedReply(reply) && regexTestFunction(rx, reply);
    });
    this._trackSearch(query, results.length);

    return results;
  }

  _trackSearch(query, resultCount) {
    if (this.app.trackMacroSearches) {
      debounce(
        this,
        this.intercomEventService.trackEvent,
        'analytics-event',
        {
          action: 'search',
          object: 'macro',
          place: 'inbox',
          query,
          resultCount,
        },
        ENV.APP._1000MS,
      );
    }
  }

  @action
  testAllReplyFields(regex, reply) {
    return (
      regex.test(reply.admin?.name) || this.testName(regex, reply) || regex.test(reply.summary)
    );
  }

  testName(regex, reply) {
    return regex.test(reply.name);
  }

  _search(queryString) {
    if (isBlank(queryString)) {
      if (!this.searchableSavedReplies || this.searchableSavedReplies.length === 0) {
        return [];
      }
      return this.searchableSavedReplies.filter((reply) => {
        return !this._isUnsavedReply(reply);
      });
    }

    let combinedResultsSet = [];

    let fuzzyResults = this.client.search(queryString);
    fuzzyResults.forEach((result) => {
      let reply = this.searchableSavedReplies.findBy('id', result.ref);
      if (!this._isUnsavedReply(reply)) {
        combinedResultsSet.push(reply);
      }
    });

    let regexSearchResults = this.searchUsingRegex(this.searchableSavedReplies, queryString);

    combinedResultsSet = combinedResultsSet.concat(regexSearchResults);
    let uniqResults = combinedResultsSet.reduce((obj, reply, index) => {
      if (reply && reply.id && !obj[reply.id]) {
        obj[reply.id] = reply;
      }
      return obj;
    }, {});
    return Object.values(uniqResults);
  }

  _isUnsavedReply(reply) {
    return reply.isNew;
  }
}
