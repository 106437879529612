/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const DefaultTranslationNotEmpty = BaseValidator.extend({
  validate(customTranslations, options, model) {
    let defaultTranslation = model.defaultTranslationForEndUsers;
    let { name, description } = defaultTranslation;
    if (name === '' || description === '') {
      return "Name and description can't be blank for default language";
    }

    return true;
  },
});

DefaultTranslationNotEmpty.reopenClass({
  getDependentsFor() {
    return ['model.customTranslations.@each.name', 'model.customTranslations.@each.description'];
  },
});

export default DefaultTranslationNotEmpty;
