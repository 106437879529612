/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';
import OpenCenteredWindow from 'embercom/lib/open-centered-window';

export default Service.extend({
  setup(url, height, width, name) {
    return OpenCenteredWindow(url, height, width, name);
  },
});
