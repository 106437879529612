/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';

const WorkflowHasValidActionsByIsValid = BaseValidator.extend({
  validate(_value, _options, model) {
    let invalidWorkflowActions = model.actions.filter((workflowAction) => !workflowAction.isValid);

    return invalidWorkflowActions.length === 0;
  },
});

WorkflowHasValidActionsByIsValid.reopenClass({
  getDependentsFor() {
    return ['model.actions.@each.isValid'];
  },
});

export default WorkflowHasValidActionsByIsValid;
