/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Service from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { perform, taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { get } from 'embercom/lib/ajax';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import { type ArticleSummary } from 'embercom/components/articles/site/collections/tree/types';

export default class ArticleSummaryService extends Service {
  @tracked allArticles: ArticleSummary[] = [];

  get isLoading(): boolean {
    return taskFor(this.loadAllArticles).isRunning && this.allArticles.length === 0;
  }

  fetchAllArticleSummaries(helpCenterSite: HelpCenterSite) {
    perform(this.loadAllArticles, helpCenterSite);
  }

  @task *loadAllArticles(helpCenterSite: HelpCenterSite): TaskGenerator<void> {
    let content = yield get('/ember/articles_collections/articles', {
      app_id: helpCenterSite.appId,
      help_center_id: helpCenterSite.id,
      published: true,
    });

    this.allArticles = content;
  }

  isAvailable(articleId: string, locale: string) {
    return this.articleForLocale(articleId, locale)?.state === 'published';
  }

  allArticlesForLocale(locale: string) {
    return this.allArticles.filter((article) => article.locales[locale]?.state === 'published');
  }

  articleForLocale(articleId: string, locale: string) {
    return this.articlesByArticleId[articleId]?.locales[locale];
  }

  @cached
  get articlesByArticleId() {
    return this.allArticles.reduce(
      (acc, article) => {
        acc[article.id] = article;
        return acc;
      },
      {} as Record<string, ArticleSummary>,
    );
  }

  isArticleRestricted(articleId: string) {
    let article = this.allArticles.find((article) => article.id === articleId);
    return article?.public === false;
  }
}

declare module '@ember/service' {
  interface Registry {
    articleSummaryService: ArticleSummaryService;
    'article-summary-service': ArticleSummaryService;
  }
}
