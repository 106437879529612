/* RESPONSIBLE TEAM: team-help-desk-experience */

import { openDB, deleteDB, type DBSchema, type IDBPDatabase } from 'idb';

import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';

import type Session from 'embercom/services/session';
import TagsStore, { type TagSchema } from 'embercom/objects/inbox/core-data/tags-store';
import MacrosStore, { type MacroSchema } from 'embercom/objects/inbox/core-data/macros-store';
import AssigneesStore, {
  type AssigneeSchema,
} from 'embercom/objects/inbox/core-data/assignees-store';
import ENV from 'embercom/config/environment';
import type Tracing from 'embercom/services/tracing';

const DB_VERSION = 11;

type VersionSchema = {
  id: string;
  version: number;
};

export interface DB extends DBSchema {
  tags: {
    key: string;
    value: TagSchema;
  };
  assignees: {
    key: number;
    value: AssigneeSchema;
    indexes: { type: string; email: string };
  };
  macros: {
    key: number;
    value: MacroSchema;
  };
  versions: {
    key: string;
    value: VersionSchema;
  };
}

// temporary schema for testing IndexedDB compatibility
interface TestSchema extends DBSchema {
  test: {
    key: string;
    value: { key: string; data: string };
  };
}

const STORES = {
  tags: TagsStore,
  macros: MacrosStore,
  assignees: AssigneesStore,
};

type StoreKey = keyof typeof STORES;
type StoreInstance<T extends StoreKey> = InstanceType<(typeof STORES)[T]>;

export default class CoreData extends Service {
  @service declare session: Session;
  @service declare tracing: Tracing;
  @tracked isBooted = false;

  #db?: IDBPDatabase<DB>;
  #stores = new Map<StoreKey, StoreInstance<StoreKey>>();

  get isEnabled() {
    return (
      this.session.isWorkspaceLoaded &&
      this.session.workspace.isFeatureEnabled('inbox-idb-core-data')
    );
  }

  private get dbPrefix() {
    return `inbox-${ENV.environment}-`;
  }

  private get dbName() {
    let workspaceID = this.session.workspace.id;
    return `${this.dbPrefix}${workspaceID}`;
  }

  // Temporary method to run some compatibility tests to ensure that IndexedDB works
  // as expected across all browsers
  async runTests() {
    try {
      await this.tracing.inSpan(
        { name: 'compatibility-test', resource: 'inbox2-core-data' },
        async () => {
          if (!('indexedDB' in window)) {
            throw new Error('IndexedDB not found in window');
          }

          let testDbName = `${this.dbPrefix}-compatibility-test`;
          let db = await openDB<TestSchema>(testDbName, 1, {
            upgrade(db) {
              if (!db.objectStoreNames.contains('test')) {
                db.createObjectStore('test', { keyPath: 'key' });
              }
            },
          });

          // eslint-disable-next-line @intercom/intercom/no-bare-strings
          await db.put('test', { key: 'test', data: 'some data' });
          await db.get('test', 'test');

          db.close();

          await deleteDB(testDbName);
        },
      );
    } catch (_e) {
      // nothing to do here, failure will be traced
    }
  }

  async boot() {
    if (!this.isEnabled) {
      return;
    }

    await this.tracing.inSpan({ name: 'boot', resource: 'inbox2-core-data' }, async () => {
      await this.setupDB();

      for (let storeType of Object.keys(STORES)) {
        await this.storeFor(storeType as StoreKey).boot();
      }

      this.isBooted = true;
    });
  }

  storeFor<T extends StoreKey>(type: T): StoreInstance<T> {
    this.assertDbExists(this.#db);

    let store = this.#stores.get(type);
    if (!store) {
      let Klass = STORES[type];
      store = new Klass(getOwner(this), this.#db);
      this.#stores.set(type, store);
    }

    return store as StoreInstance<T>;
  }

  async shutdown() {
    if (!this.isEnabled) {
      return;
    }

    for (let [storeType, store] of this.#stores) {
      await store.shutdown();
      this.#stores.delete(storeType);
    }

    this.#db?.close();
    this.#db = undefined;
    this.isBooted = false;
  }

  // To be called on logout to nuke all the data
  async clear() {
    if (!this.isEnabled) {
      return;
    }

    await this.shutdown();
    await this.deleteAllDatabases();
  }

  private async deleteAllDatabases() {
    let databases = await indexedDB.databases();
    databases.forEach(async (db) => {
      if (db.name?.startsWith(this.dbPrefix)) {
        await deleteDB(db.name);
      }
    });
  }

  private assertDbExists(db: IDBPDatabase<DB> | undefined): asserts db is IDBPDatabase<DB> {
    if (!db) {
      throw new Error('DB not initialized');
    }
  }

  private async setupDB() {
    this.#db = await openDB<DB>(this.dbName, DB_VERSION, {
      upgrade(db, oldVersion, newVersion, _transaction, _event) {
        for (let storeClass of Object.values(STORES)) {
          storeClass.upgradeDB(db, oldVersion, newVersion);
        }

        // for now nuke all data on update, in future we can do something nicer
        if (db.objectStoreNames.contains('versions')) {
          db.deleteObjectStore('versions');
        }

        if (!db.objectStoreNames.contains('versions')) {
          db.createObjectStore('versions', { keyPath: 'id', autoIncrement: false });
        }
      },
      terminated() {
        console.error('CoreData DB terminated unexpectedly');
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2CoreData: CoreData;
    'inbox2-core-data': CoreData;
  }
}
