/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

// `model` here is Ruleset and `value` is Ruleset#clientPredicateGroup
const RulesetDoesNotUseClientPredicatesForStaticMatchBehavior = BaseValidator.extend({
  validate(value, options, model) {
    if (isEmpty(value)) {
      return true;
    }
    if (model.isMatchBehaviorStatic && value.basePredicates?.length > 0) {
      return 'URL rules and Time on Page rules are not supported in one-off messages.';
    }
    return true;
  },
});

RulesetDoesNotUseClientPredicatesForStaticMatchBehavior.reopenClass({
  getDependentsFor() {
    return ['model.isMatchBehaviorStatic', 'model.clientPredicateGroup.basePredicates.[]'];
  },
});

export default RulesetDoesNotUseClientPredicatesForStaticMatchBehavior;
