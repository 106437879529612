/* RESPONSIBLE TEAM: team-messenger */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default class CarouselScreenSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    carouselActions: { embedded: 'always' },
    templateId: {
      serialize: false,
    },
  };
}
