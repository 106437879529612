/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isPresent } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const DeliveryWindowOnlyPermittedForEmailOrPush = BaseValidator.extend({
  validate(value, options, model) {
    if (isPresent(value) && !model.get('canEditDeliveryWindows')) {
      return 'Change this to an email or push message if you want to set a delivery window';
    }
    return true;
  },
});

DeliveryWindowOnlyPermittedForEmailOrPush.reopenClass({
  getDependentsFor() {
    return ['model.canEditDeliveryWindows'];
  },
});

export default DeliveryWindowOnlyPermittedForEmailOrPush;
