/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default BaseValidator.extend({
  operatorMessengerAppService: service(),

  validate(value) {
    if (!value) {
      return false;
    }

    let messengerAppId = value.messenger_app_id;
    if (messengerAppId) {
      return this.operatorMessengerAppService.validateMessengerAppIds([messengerAppId]);
    }

    return false;
  },
});
