/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const EdgeSplitPercentages = BaseValidator.extend({
  validate(_value, _options, model) {
    let percentages = model.edgeSplits.map((edgeSplit) => Number(edgeSplit.percentage));
    if (percentages.some((percentage) => percentage < 0 || percentage > 100)) {
      return 'Percentages cannot be less than 0 or greater than 100';
    } else if (percentages.reduce((a, b) => a + b) !== 100) {
      return 'Percentages should add up to a 100';
    }
    return true;
  },
});

EdgeSplitPercentages.reopenClass({
  getDependentsFor() {
    return ['model.edgeSplits.@each.percentage'];
  },
});

export default EdgeSplitPercentages;
