/* RESPONSIBLE TEAM: team-tickets-1 */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    this.addIdsToSections(payload);
    return this._super(...arguments);
  },

  addIdsToSections(payload) {
    payload.forEach(function (section) {
      if (section.id === null) {
        section.id = section.name;
      }
    });
    return payload;
  },
});
