/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import { post, put } from 'embercom/lib/ajax';
import DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import type Session from './session';
import { request } from 'embercom/lib/inbox/requests';

const DEFAULT_IDLE_THRESHOLD = 30 * 60;
const DEFAULT_AGGREGATION_TYPE = 'median';
const DEFAULT_EXCLUDE_BOT_TIME = false;
export class ResponseError extends Error {
  response: Response;

  constructor(response: Response) {
    super(`Failed request to ${response.url} with status ${response.status}`);
    this.response = response;
  }
}
export default class DashboardSettingsService extends Service {
  @service declare session: Session;

  constructor(properties?: object | undefined) {
    super(properties);
  }

  get app() {
    return this.session.workspace;
  }

  get admin() {
    return this.session.teammate;
  }

  async fetchDashboardSettings(): Promise<DashboardSettings | null> {
    let response = await request(
      `/ember/monitoring/visible_fields?admin_id=${this.admin.id}&app_id=${this.app.id}`,
    );
    let json = await response.json();
    return json ? DashboardSettings.deserialize(json) : null;
  }

  async updateDashboardSettings(settings: DashboardSettings) {
    return await put(`/ember/monitoring/visible_fields/${settings.id}`, {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
      inbox_ids: settings.inboxIds,
      selected_inbox_overview_columns: settings.selectedInboxOverviewColumns,
      selected_inbox_overview_timeframe: settings.selectedInboxOverviewTimeframe,
      team_ids: settings.teamIds,
      selected_teammate_activity_columns: settings.selectedTeammateActivityColumns,
      selected_teammate_activity_timeframe: settings.selectedTeammateActivityTimeframe,
      selected_teammate_statuses: settings.selectedTeammateStatuses,
      aggregation_type: settings.aggregationType,
      idle_threshold: settings.idleThreshold,
      exclude_bot_time: settings.excludeBotTime,
    });
  }

  async createDashboardSettings(inboxIds: string[], teamIds: string[]) {
    let response = await post(`/ember/monitoring/visible_fields/`, {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
      inbox_ids: inboxIds,
      team_ids: teamIds,
      aggregation_type: DEFAULT_AGGREGATION_TYPE,
      idle_threshold: DEFAULT_IDLE_THRESHOLD,
      exclude_bot_time: DEFAULT_EXCLUDE_BOT_TIME,
    });
    return DashboardSettings.deserialize(response);
  }
}

declare module '@ember/service' {
  interface Registry {
    dashboardSettingsService: DashboardSettingsService;
    'dashboard-settings-service': DashboardSettingsService;
  }
}
