/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const WorkflowConnectorHasAction = BaseValidator.extend({
  validate(value, options, model) {
    if (!model.stepData.actionId) {
      return 'Custom action step should have an action';
    }

    return true;
  },
});

WorkflowConnectorHasAction.reopenClass({
  getDependentsFor() {
    return ['model.stepData', 'model.stepData.actionId'];
  },
});

export default WorkflowConnectorHasAction;
