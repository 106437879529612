/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const ConnectionPointsAreValid = BaseValidator.extend({
  intl: service(),

  validate(value, options, model) {
    if (model.isTerminal && !model.isConnected) {
      return true;
    }

    if (!model.isTerminal && model.isConnected) {
      return true;
    }

    if (model.isStartOver) {
      return true;
    }

    if (!model.isConnected && model.type === 'capture_composer_input') {
      return true;
    }

    return this.intl.t(options.message);
  },
});

ConnectionPointsAreValid.reopenClass({
  getDependentsFor() {
    return ['model.isTerminal', 'model.isConnected', 'model.isStartOver'];
  },
});

export default ConnectionPointsAreValid;
