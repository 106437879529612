/* RESPONSIBLE TEAM: team-messenger */

import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';

export default class HostedMessengerConversationService extends Service {
  @service appService;
  @tracked latestConversationId = null;

  @dropTask
  *fetchConversationIdIfNecessary() {
    if (this.latestConversationId) {
      return;
    }
    let conversationRecord = yield get(
      '/ember/onboarding/hosted_messenger_created_conversations/latest_for_admin_or_app',
      { app_id: this.appService.app.id },
    );
    this.latestConversationId = conversationRecord.conversation_id;
  }
}
