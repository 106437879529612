/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { get } from 'embercom/lib/ajax';
import { A } from '@ember/array';
import moment from 'moment-timezone';

const ADMIN_PAGEVIEW_KEY_PREFIX = 'onboarding_home_level';

export default class Inbox2OnboardingHomeService extends Service {
  @service declare session: Session;
  @tracked guide: any = null;
  @tracked levelAdminPageViews = A();

  constructor() {
    super(...arguments);
    this.loadGuide();
    this.setLevelAdminPageViews();
  }

  async setLevelAdminPageViews() {
    let allPageViews = await get(`/ember/admin_pageviews?app_id=${this.session.workspace.id}`);
    let levelPageViews = allPageViews.filter((pageView: any) =>
      pageView.page_key.startsWith(ADMIN_PAGEVIEW_KEY_PREFIX),
    );
    this.levelAdminPageViews = levelPageViews;
  }

  get hasIncompleteAndUnseenLevels() {
    let incompleteAndUnseenLevels = this.incompleteLevels().reject((level: any) =>
      this._levelPageViewPresentFor(level.identifier),
    );
    return !!incompleteAndUnseenLevels.length;
  }

  get showUnseenLevelBadge() {
    let now = moment();
    let appCreatedPlus6Months = moment(this.session.workspace.createdAt).add(6, 'M');
    return this.hasIncompleteAndUnseenLevels && now.isBefore(appCreatedPlus6Months);
  }

  incompleteLevels() {
    if (!this.guide) {
      return [];
    }

    return this.guide.levels.filter((level: any) => {
      return level.state === 'available';
    });
  }

  async loadGuide() {
    this.guide = await get(`/ember/onboarding/home/guide?app_id=${this.session.workspace.id}`);
  }

  _levelPageViewPresentFor(levelIdentifier: string) {
    return this.levelAdminPageViews.any((pageview: any) => {
      return pageview.page_key === this._pageViewKeyForLevel(levelIdentifier);
    });
  }

  _pageViewKeyForLevel(levelIdentifier: string) {
    return [ADMIN_PAGEVIEW_KEY_PREFIX, levelIdentifier].join('_');
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2OnboardingHomeService: Inbox2OnboardingHomeService;
    'inbox2-onboarding-home-service': Inbox2OnboardingHomeService;
  }
}
