/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Evented from '@ember/object/evented';
import { later } from '@ember/runloop';
import Service from '@ember/service';
import ENV from 'embercom/config/environment';

export default class GoogleTagManagerService extends Service.extend(Evented) {
  promiseCount = 0;

  pushEvent(event: $TSFixMe) {
    let timeOutPromise = new Promise((_, reject) => {
      later(
        this,
        () => reject(`GoogleTagManagerService pushEvent timeout: ${event.event ?? event}`),
        ENV.APP._5000MS,
      );
    });

    let gtmPromise = new Promise((resolve, reject) => {
      let messageId = this.incrementProperty('promiseCount');
      event.messageId = messageId;

      let triggerGtmEvent = (
        resolve: $TSFixMe,
        reject: $TSFixMe,
        event: $TSFixMe,
        canRetry: boolean,
      ) => {
        if (this.has('gtm-event')) {
          this.trigger('gtm-event', { resolve, reject, event });
        } else if (canRetry) {
          later(this, () => triggerGtmEvent(resolve, reject, event, false), ENV.APP._2000MS);
        } else {
          reject('No gtm-event subscription in Google Tag Manager service');
        }
      };

      triggerGtmEvent(resolve, reject, event, true);
    });

    return Promise.race([gtmPromise, timeOutPromise]);
  }
}
