/* RESPONSIBLE TEAM: team-help-desk-experience */
type Letter =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

export function letterKeyShortcut(
  letter: Letter,
  modifier?: string,
): { keys: string; alternateKeys: string[] } {
  // For cmd+shift shortcuts this is a Workaround for https://github.com/adopted-ember-addons/ember-keyboard/issues/621
  // Mac + English layout (e.g. British Mac) gives the key value as upper case letter
  // Mac + Latin layout (e.g. French AZERTY) gives the key value as lower case letter
  // Windows + Latin layout or non Latin gives the key value as lower case letter
  // We need to register both the key cases for the shortcutst to work
  //
  // Otherwise this just supports people who have capslock enabled.
  modifier = modifier ? `${modifier}+` : '';
  return {
    keys: `${modifier}${letter.toLowerCase()}`,
    alternateKeys: [`${modifier}${letter.toUpperCase()}`],
  };
}

export function alternateKeysLetter(letter: Letter): string[] {
  return [letter.toUpperCase(), letter.toLowerCase()];
}
