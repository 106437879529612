/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';
import {
  outboundWebhookHttpMethods,
  outboundWebhookContentTypes,
} from 'embercom/models/data/outbound-webhook/constants';

export default buildValidations({
  url: {
    description: 'Webhook Url',
    validators: [validator('url-with-template-string')],
  },
  httpMethod: {
    description: 'HTTP Method',
    validators: [
      validator('presence', true),
      validator('inclusion', {
        in: [outboundWebhookHttpMethods.post, outboundWebhookHttpMethods.put],
      }),
    ],
  },
  contentType: {
    description: 'Content-Type',
    validators: [
      validator('presence', true),
      validator('inclusion', {
        in: [outboundWebhookContentTypes.json, outboundWebhookContentTypes.urlEncoded],
      }),
    ],
  },
});
