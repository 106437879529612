/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import generateUUID from 'embercom/lib/uuid-generator';
import { INBOUND_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    visualBuilderObject: { embedded: 'always' },
  },

  normalize(modelClass, resourceHash) {
    resourceHash = this._normalizeSingleResource(resourceHash);
    return this._super(modelClass, resourceHash);
  },

  serialize(snapshot, options) {
    let serialized = this._super(...arguments);
    if (serialized.visual_builder_object) {
      this._serializeVisualBuilderObject(serialized);
    }

    return serialized;
  },

  _normalizeSingleResource(response) {
    response.paths = this._normalizePaths(response.paths);
    if (response.visual_builder_object) {
      this._normalizeVisualBuilderObject(response);
    }
    return response;
  },

  _normalizePaths(paths) {
    if (!paths) {
      return paths;
    }

    paths = paths.map((path) => ({
      ...path,
      _id: path.id,
    }));

    paths.forEach((path) => {
      path.steps = this._normalizeSteps(path.steps);
    });

    return paths;
  },

  _normalizeSteps(steps) {
    if (!steps) {
      return steps;
    }

    steps = steps.map((step) => {
      if (step.type === 'follow_up_rules' || step.type === 'answer_bot') {
        if (step.simple_action_rule === null) {
          step.simple_action_rule = this.store.createFragment('rules/rule');
        }
      }
      return step;
    });

    return steps;
  },

  _normalizeVisualBuilderObject(response) {
    let { visual_builder_object } = response;

    // Create the capture composer input & placeholder group if required
    let startingGroup = visual_builder_object.groups.find((group) => group.is_start);
    let replyButtons = startingGroup.steps[startingGroup.steps.length - 1];

    if (response.type === INBOUND_BOT_TYPE) {
      let captureComposerInput = replyButtons.outward_connection_points.find(
        (button) => button.type === 'capture_composer_input',
      );

      if (!captureComposerInput && !replyButtons.is_composer_disabled) {
        let placeholderGroup = {
          id: generateUUID(),
          placeholder_name: 'capture-composer',
        };

        replyButtons.outward_connection_points.push({
          id: generateUUID(),
          type: 'capture_composer_input',
          edge: { to_group_id: placeholderGroup.id },
        });
        visual_builder_object.groups.push(placeholderGroup);
      }
    }
  },

  _serializeVisualBuilderObject(serialized) {
    let { visual_builder_object } = serialized;
    // Filter out empty placeholder groups
    visual_builder_object.groups = visual_builder_object.groups.filter(
      (group) => group.is_start || group.steps.length > 0,
    );

    // Delete the capture composer input if it's unconnected
    if (serialized.type === INBOUND_BOT_TYPE) {
      let startingGroup = visual_builder_object.groups.find((group) => group.is_start);
      let replyButtonsStep = startingGroup.steps[startingGroup.steps.length - 1];
      let unconnectedCaptureComposerInputIndex =
        replyButtonsStep.outward_connection_points.findIndex(
          (point) =>
            point.type === 'capture_composer_input' &&
            !visual_builder_object.groups.find((group) => group.id === point.edge?.to_group_id) &&
            !point.is_terminal,
        );

      if (unconnectedCaptureComposerInputIndex !== -1) {
        replyButtonsStep.outward_connection_points.splice(unconnectedCaptureComposerInputIndex, 1);
      }
    }
  },
});
