/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { action } from '@ember/object';
import { none, not, or, readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import {
  hookIntoTourComplete,
  hookIntoTourError,
  startTour,
} from 'embercom/lib/intercom-widget-helper';
import { captureException } from 'embercom/lib/sentry';
import Metrics from 'embercom/models/metrics';
import routeRegexes from '../lib/route-regexes';

const SESSION_STORAGE_STEP_KEY = 'onboarding-external-step';

export default Service.extend({
  appService: service(),
  app: readOnly('appService.app'),
  onboardingHomeService: service(),
  hostedMessengerConversationService: service(),
  intercomEventService: service(),
  store: service(),
  router: service(),

  _tourHookRemovers: [],
  externalStep: null,
  externalStepModeDisabled: none('externalStep'),
  externalStepModeEnabled: not('externalStepModeDisabled'),
  externalStepIsCompletedOrPendingCompleted: or(
    'externalStep.completed',
    'externalStep.pendingCompleted',
  ),
  externalStepAvailable: readOnly('externalStep.available'),

  isCurrentExternalStep(identifier) {
    if (this.externalStepModeDisabled) {
      return false;
    }
    return this.get('externalStep.identifier') === identifier;
  },

  isExternalStepRunning(identifier) {
    return identifier && this.isCurrentExternalStep(identifier);
  },

  startAfterTransition: action(function (stepIdentifier, startTourMetadata) {
    this._clearStartAfterTransitionCallback();
    this.startAfterTransitionCallback = (transition) => {
      if (!transition.isAborted) {
        this.start(stepIdentifier, startTourMetadata);
      }
      this.startAfterTransitionCallback = null;
    };

    this.router.one('routeDidChange', this, this.startAfterTransitionCallback);
  }),

  start: action(async function (stepIdentifier, startTourMetadata = {}) {
    let externalStep = await this.loadStep(stepIdentifier);

    if (!externalStep) {
      return;
    }

    this._storeStepIdentifierInSession(stepIdentifier);
    this._trackAnalyticsEvent({
      object: `${externalStep.identifier}_external_step`,
      action: 'started',
      section: 'external_step',
    });

    let started = this._possiblyStartTour(externalStep, startTourMetadata);

    if (started) {
      this.router.on('routeDidChange', this, '_considerDisablingExternalStep');
    }
  }),

  async generateExampleDataForStep(stepIdentifier, params = {}) {
    if (await this._needsHostedMessengerConversation(stepIdentifier)) {
      await this.hostedMessengerConversationService.fetchConversationIdIfNecessary.perform();
      params.conversation_id = this.hostedMessengerConversationService.latestConversationId;
    }

    return ajax({
      url: `/ember/onboarding/home/steps/${stepIdentifier}/generate_external_step_data`,
      type: 'POST',
      data: JSON.stringify({
        app_id: this.app.id,
        ...params,
      }),
    });
  },

  async continueSession() {
    let stepIdentifier = this._getStepIdentifierFromSession();

    if (!stepIdentifier) {
      return;
    }

    let externalStep = await this.loadStep(stepIdentifier);

    if (!externalStep) {
      return;
    }

    this._trackAnalyticsEvent({
      object: `${externalStep.identifier}_external_step`,
      action: 'continued',
      section: 'external_step',
    });
    this.router.on('routeDidChange', this, '_considerDisablingExternalStep');
  },

  async _needsHostedMessengerConversation(stepIdentifier) {
    let externalStep = await this.loadStep(stepIdentifier);
    if (!externalStep) {
      return false;
    }
    return externalStep.requiresHostedMessengerConversation;
  },

  loadStep: action(async function (stepIdentifier) {
    await this.onboardingHomeService.findOrLoadGuide(false);
    let externalStep = this.store.peekRecord('onboarding/home/step', stepIdentifier);

    if (!externalStep) {
      console.error('Could not load step:', stepIdentifier);
      try {
        captureException(new Error(`Coud not load step ${stepIdentifier}`), {
          fingerprint: ['onboarding-home-external-step-service', 'loadStep', stepIdentifier],
          extra: {
            guide_exists: !!this.onboardingHomeService.guide,
          },
        });
      } catch (e) {
        // noop
      }
    }

    return this.set('externalStep', externalStep);
  }),

  _possiblyStartTour(externalStep, startTourMetadata) {
    let tourId = externalStep.productTourId;
    if (!tourId) {
      return false;
    }

    let removeErrorHook = hookIntoTourError(tourId, () => {
      this._recordTourError(tourId, startTourMetadata);
      removeErrorHook();
      this._tourHookRemovers.pushObject(removeErrorHook);
    });

    startTour(tourId);

    if (externalStep.stepData.productTourCompletesStep) {
      let removeHook = hookIntoTourComplete(tourId, () => {
        this.completeExternalStep();
        removeHook();
      });
      this._tourHookRemovers.pushObject(removeHook);
    }
    Metrics.capture({
      increment: ['onboarding_home_tour.start'],
      tags: {
        tour_id: tourId,
      },
    });
    return true;
  },

  _storeStepIdentifierInSession(stepIdentifier) {
    if (!window.sessionStorage) {
      return;
    }
    if (!stepIdentifier) {
      return window.sessionStorage.removeItem(SESSION_STORAGE_STEP_KEY);
    }
    window.sessionStorage.setItem(SESSION_STORAGE_STEP_KEY, stepIdentifier);
  },

  _getStepIdentifierFromSession() {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem(SESSION_STORAGE_STEP_KEY);
    }
  },

  _considerDisablingExternalStep(transition) {
    let routesToKeepExternalStepRunning = this.get('externalStep.stepData.tourInProgressRoutes');
    if (
      (this.externalStepIsCompletedOrPendingCompleted &&
        routeRegexes.onboardingHome.test(transition.to.name)) ||
      (routesToKeepExternalStepRunning && !routesToKeepExternalStepRunning.test(transition.to.name))
    ) {
      this.disable();
    }
  },

  _clearStartAfterTransitionCallback() {
    if (this.startAfterTransitionCallback) {
      this.router.off('routeDidChange', this, this.startAfterTransitionCallback);
      this.startAfterTransitionCallback = null;
    }
  },

  _recordTourError(tourId, startTourMetadata = {}) {
    Metrics.capture({
      increment: ['onboarding_home_tour.error'],
      tags: {
        tour_id: tourId,
      },
    });

    captureException(new Error(`Onboarding Home tour ${tourId} failed to start or progress`), {
      fingerprint: ['onboarding', 'home', 'tour', 'error', tourId, startTourMetadata.source],
      extra: {
        tourId,
        startTourMetadata,
        latestConversationId: this.hostedMessengerConversationService.latestConversationId,
      },
    });
  },

  _removeTourHooks() {
    this._tourHookRemovers.forEach((remover) => remover());
  },

  async completeExternalStep() {
    if (!this.externalStepAvailable) {
      return;
    }
    let state = this._determineCompletedStateForStep(this.externalStep);
    this.externalStep.set('state', state);
    await this.externalStep.saveAndRefreshGuide();
    this._trackAnalyticsEvent({
      object: `${this.externalStep.identifier}_external_step`,
      action: 'completed',
      section: 'external_step',
    });
  },

  _determineCompletedStateForStep(step) {
    let state = 'pending_completed';
    if (
      step.get('level.availableSteps.length') === 1 &&
      step.get('level.availableSteps.firstObject') === step
    ) {
      state = 'completed';
    }
    return state;
  },

  async failExternalStep() {
    if (!this.externalStepAvailable) {
      return;
    }
    this.externalStep.set('state', 'failed');
    await this.externalStep.saveAndRefreshGuide();
    this._trackAnalyticsEvent({
      object: `${this.externalStep.identifier}_external_step`,
      action: 'failed',
      section: 'external_step',
    });
  },

  disable() {
    this.set('externalStep', null);
    this._storeStepIdentifierInSession(null);
    this.set('recentlyVisitedExternalStep', true);
    this.router.off('routeDidChange', this, '_considerDisablingExternalStep');
    this._removeTourHooks();
  },

  _trackAnalyticsEvent(metadata) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.externalStep.analyticsMetadata,
      ...metadata,
    });
  },
});
