/* RESPONSIBLE TEAM: team-frontend-tech */
import Service, { inject as service } from '@ember/service';
import { cancel, debounce } from '@ember/runloop';
import Evented from '@ember/object/evented';
import ENV from 'embercom/config/environment';

/**
 * Track user idleness by lack of mouse movement and lack of keyboard use.
 *
 * Use mouseenter/mouseleave events instead of mousemove to get similar
 * information with a lower performance penalty.
 */
export default class UserIdleService extends Service.extend(Evented) {
  @service intercomEventService;

  static IDLE_TIMEOUT = ENV.APP.userIdleTimeout;

  constructor() {
    super(...arguments);
    this.isIdle = false;
    this.idleTask = null;
  }

  get isActive() {
    return !this.isIdle;
  }

  registerListeners() {
    if (ENV.APP.trackUserIdle) {
      window.addEventListener('mouseenter', this, true);
      window.addEventListener('mouseleave', this, true);
      window.addEventListener('click', this, true);
      window.addEventListener('keydown', this, true);
    }
  }

  handleEvent() {
    if (this.isIdle) {
      this.setActive();
    }
    this.idleTask = debounce(this, this.setIdle, UserIdleService.IDLE_TIMEOUT);
  }

  setIdle() {
    this.isIdle = true;
    this.trigger('idle');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'idle',
      object: 'user_idle_service',
      section: 'global',
      place: 'global',
    });
  }

  setActive() {
    this.isIdle = false;
    this.trigger('active');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'active',
      object: 'user_idle_service',
      section: 'global',
      place: 'global',
    });
  }

  willDestroy() {
    window.removeEventListener('mouseenter', this, true);
    window.removeEventListener('mouseleave', this, true);
    window.removeEventListener('click', this, true);
    window.removeEventListener('keydown', this, true);
    if (this.idleTask !== null) {
      cancel(this.idleTask);
    }
  }
}
