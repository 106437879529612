/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { animate, stop } from 'liquid-fire';

export default function bounce(opts) {
  stop(this.oldElement);

  let container = this.oldElement.parent('.liquid-container');
  container.css({
    overflow: 'visible',
  });

  let newOpts = { ...opts, loop: 1, duration: 150, easing: 'easeInOutSine' };
  return animate(this.oldElement, { scale: 1.05 }, newOpts).then(() =>
    animate(this.newElement, { visible: true }, opts),
  );
}
