/* RESPONSIBLE TEAM: team-customer-data-platform */

import { isBlank, isEmpty, isPresent } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type CrmTypeTranslationService from 'embercom/services/data/crm-type-translation-service';
import { IntercomTypeTranslator } from 'embercom/services/data/crm-type-translation-service';
// @ts-ignore
import type AttributeService from 'embercom/services/attribute-service';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get, post } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import ImportConfiguration from 'embercom/models/data/import/import-configuration';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type { MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type { SourceAttributeType as MappingSourceAttributeType } from 'embercom/models/crm/attribute-mapping';
import type { default as CrmAttribute } from 'embercom/models/crm/attribute';
import type IntercomAttribute from 'embercom/models/attribute';
import { type SyncHasMany } from '@ember-data/model';
import type Admin from 'embercom/models/admin';
import type Team from 'embercom/models/team';
import type IdentityMapping from 'embercom/models/crm/identity-mapping';
import type IdentityMappingFragmentInput from 'embercom/models/crm/identity-mapping';
import type AttributesConfigService from 'embercom/services/data/attributes-config-service';
import type TicketType from 'embercom/models/inbox/ticket-type';
import { tracked } from '@glimmer/tracking';

class BaseObject {
  id: string;
  name: string;
  objectType: string;
  constructor(id: string, name: string, objectType: string) {
    this.id = id;
    this.name = name;
    this.objectType = objectType;
  }
}

export class CrmObject extends BaseObject {}
export class IntercomObject extends BaseObject {
  model: Team | Admin;
  email?: string;

  constructor(id: string, name: string, objectType: string, model: Team | Admin, email?: string) {
    super(id, name, objectType);
    this.model = model;
    this.email = email;
  }
}

export type SourceAttributeType = MappingSourceAttributeType | 'non-required' | 'all';

export default class ImportConfigurationService extends Service {
  // @ts-ignore
  @service declare attributeService: AttributeService;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;
  @service declare session: Session;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service('data/attributes-config-service')
  declare attributesConfigService: AttributesConfigService;
  @service('data/crm-type-translation-service')
  declare typeTranslationService: CrmTypeTranslationService;

  private typeTranslation = new IntercomTypeTranslator([]);
  private integrationCode = 'migrate-from-crm'; // this must be overridden by calling loadConfiguration
  protected importConfig: ImportConfiguration = new ImportConfiguration({});

  @tracked privateTicketType: TicketType | undefined;
  @tracked publicTicketType: TicketType | undefined;

  identityMappings!: SyncHasMany<IdentityMapping>;
  attributeMappings!: SyncHasMany<AttributeMapping>;
  sourceAttributes!: SyncHasMany<CrmAttribute>;

  crmObjects: Map<string, CrmObject> = new Map<string, CrmObject>();

  validObjectTypes = new Map<string, (attr: IntercomAttribute) => boolean>([
    ['company', (attr: IntercomAttribute) => attr.isCompany],
    ['contact', (attr: IntercomAttribute) => attr.isUser],
    ['ticket', (_attr: IntercomAttribute) => true],
  ]);

  initialize(integrationCode: string) {
    this.integrationCode = integrationCode;
    this.typeTranslation = this.typeTranslationService.getTranslator(integrationCode)!;
  }

  async loadConfiguration(integrationCode: string) {
    this.initialize(integrationCode);
    return taskFor(this.loadConfigurationTask).perform(integrationCode);
  }

  async storeConfiguration(objectTypes: string[], identityTypes: string[] = []) {
    return taskFor(this.storeConfigurationTask).perform(objectTypes, identityTypes);
  }

  async startDataImport(objectTypes: string[], identityTypes: string[] = []) {
    return taskFor(this.startDataImportTask).perform(objectTypes, identityTypes);
  }

  async stopDataImport(integrationCode?: string) {
    return taskFor(this.stopDataImportTask).perform(integrationCode);
  }

  async loadCrmObjects(integrationCode: string, objectType: string) {
    return taskFor(this.loadCrmObjectsTask).perform(integrationCode, objectType);
  }

  get hasLoadedConfiguration() {
    return taskFor(this.loadConfigurationTask).performCount > 0;
  }

  get isLoading() {
    return taskFor(this.loadConfigurationTask).isRunning;
  }

  get isSaving() {
    return taskFor(this.storeConfigurationTask).isRunning;
  }

  get isStartingDataImport() {
    return taskFor(this.startDataImportTask).isRunning;
  }

  listMappings(
    objectType: string,
    mappingVisibility: MappingVisibility = 'public',
    attributeType: SourceAttributeType = 'all',
  ): Array<AttributeMapping> {
    let crmType = this.typeTranslation.getCrmType(objectType)!;
    let mappings = this.attributeMappings.filter(
      (mapping: AttributeMapping) =>
        mapping.sourceObjectType === crmType && mapping.mappingVisibility === mappingVisibility,
    );

    if (attributeType === 'all') {
      return mappings;
    }

    if (attributeType === 'non-required') {
      return mappings.filter((mapping) => mapping.sourceAttributeType !== 'required');
    }

    return mappings.filter((mapping) => mapping.sourceAttributeType === attributeType);
  }

  listRequiredTicketMappings(
    ticketTypeId: string,
    visibility: MappingVisibility = 'private',
  ): AttributeMapping[] {
    let destinationObjectType = `ticket_type.${ticketTypeId}`;
    return this.attributeMappings.filter(
      (mapping: AttributeMapping) =>
        mapping.destinationObjectType === destinationObjectType &&
        mapping.sourceAttributeType === 'required' &&
        mapping.mappingVisibility === visibility,
    );
  }

  listObjectTypeAttributes(
    objectType: string,
    mappingVisibility: MappingVisibility,
  ): Array<IntercomAttribute> {
    let scope = this.validObjectTypes.get(objectType);
    if (isEmpty(scope)) {
      return [];
    }

    if (objectType !== 'ticket') {
      return this.attributeService.attributes.filter(scope);
    }

    let ticketTypeId = this.getTicketTypeId(mappingVisibility);
    if (isBlank(ticketTypeId)) {
      return [];
    }

    let objectTypeAttributes: IntercomAttribute[] =
      this.attributeService.crmTicketAttributesByType[ticketTypeId] || [];

    return objectTypeAttributes;
  }

  listMappingAlternatives(objectType: string, mapping: AttributeMapping): Array<IntercomAttribute> {
    let mappableTypes = this.getMappableTypes(mapping);
    if (isEmpty(mappableTypes)) {
      return [];
    }

    let attributes = this.listObjectTypeAttributes(objectType, mapping.mappingVisibility).filter(
      (attr: IntercomAttribute) =>
        mappableTypes.includes(attr.type) &&
        (attr.isCustomData || this.attributesConfigService.matchAllowedAttribute(objectType, attr)),
    );

    if (objectType === 'ticket') {
      return attributes.filter((attr) => !attr.required);
    }

    return attributes;
  }

  listMappingAlternativesForTicketRequiredAttribute(mapping: AttributeMapping): CrmAttribute[] {
    let destinationAttribute = this.matchingDestinationAttribute(mapping);
    if (destinationAttribute === undefined) {
      return [];
    }
    return this.sourceAttributes.filter(
      (attr) =>
        attr.crmObjectType === 'ticket' && attr.mappableTypes.includes(destinationAttribute!.type),
    );
  }

  getMappableTypes(mapping: AttributeMapping): Array<string> {
    let sourceAttr = this.matchingSourceAttribute(mapping);
    return sourceAttr?.mappableTypes || [];
  }

  attributeUnassignable(objectType: string, attribute: IntercomAttribute): boolean {
    return this.attributesConfigService.matchDisabledAttribute(objectType, attribute);
  }

  attributeAssigned(
    objectType: string,
    attribute: IntercomAttribute,
    mappingVisibility: MappingVisibility,
  ): boolean {
    return this.attributeMappings.any(
      (mapping) =>
        (this.matchDestinationObjectType(objectType, mapping, mappingVisibility) &&
          this.matchIntercomAttribute(attribute, mapping)) ||
        this.attributesConfigService.matchSpecialMapping(objectType, attribute, mapping),
    );
  }

  matchingDestinationAttribute(mapping: AttributeMapping) {
    let objectType = this.typeTranslation.getIntercomType(mapping.sourceObjectType);
    if (
      !objectType ||
      isEmpty(mapping.destinationAttributePath) ||
      isEmpty(mapping.destinationObjectType)
    ) {
      return undefined;
    }

    return this.listObjectTypeAttributes(objectType, mapping.mappingVisibility).find(
      (attr: IntercomAttribute) => this.matchIntercomAttribute(attr, mapping),
    );
  }

  matchingSourceAttribute(mapping: AttributeMapping): CrmAttribute | undefined {
    return this.sourceAttributes.find((attr) => this.matchCrmAttribute(attr, mapping));
  }

  listIdentityMappings(objectType: string): Array<IdentityMapping> {
    let crmObjectType = this.typeTranslation.getCrmType(objectType);
    return this.identityMappings.filter((mapping: IdentityMapping) => {
      return mapping.crmObjectType === crmObjectType && this.matchingCrmObject(mapping);
    });
  }

  matchingCrmObject(mapping: IdentityMapping): CrmObject | undefined {
    return this.crmObjects.get(this.crmObjectKey(mapping.crmObjectId, mapping.crmObjectType));
  }

  listAssignableIntercomObjects(mapping: IdentityMapping): IntercomObject[] {
    let intercomObjectType = this.typeTranslation.getIntercomType(mapping.crmObjectType);

    return intercomObjectType !== undefined
      ? this.getAssignableIntercomObjects(intercomObjectType)
      : [];
  }

  objectAssigned(object: IntercomObject): boolean {
    return this.identityMappings.any(
      (mapping) =>
        mapping.intercomObjectType === object.objectType && mapping.intercomObjectId === object.id,
    );
  }

  @dropTask *loadConfigurationTask(integrationCode: string): TaskGenerator<any> {
    yield taskFor(this.loadInitialConfigurationTask).perform(integrationCode);
    for (let objectType of ['team', 'admin']) {
      yield taskFor(this.loadCrmObjectsTask).perform(integrationCode, objectType);
    }
  }

  @dropTask *loadInitialConfigurationTask(integrationCode: string): TaskGenerator<any> {
    let importConfig = yield this.store.queryRecord('data/import/import-configuration', {
      app_id: this.session.workspace.id,
      integration_code: integrationCode,
    });

    this.importConfig = importConfig;
    this.importConfig.appId = this.session.workspace.id;

    let workingCopy = this.createImportConfigurationRecord('-internal-cached-model');
    this.attributeMappings = workingCopy.attributeMappings;
    this.identityMappings = workingCopy.identityMappings;
    this.sourceAttributes = workingCopy.sourceAttributes;
    this.crmObjects.clear();

    importConfig.attributeMappings.forEach((mapping: AttributeMapping) => {
      if (mapping.destinationObjectType?.startsWith('ticket_type.')) {
        if (mapping.destinationObjectType.endsWith('_default_')) {
          this.attributeMappings.addFragment({
            ...mapping.toJSON(),
            status: 'missing',
            mappingVisibility: 'public',
            destinationObjectType: `ticket_type.${this.publicTicketTypeId || '_default_'}`,
          });
          this.attributeMappings.addFragment({
            ...mapping.toJSON(),
            status: 'missing',
            mappingVisibility: 'private',
            destinationObjectType: `ticket_type.${this.privateTicketTypeId || '_default_'}`,
          });
        } else {
          let ticketTypeId = mapping.destinationObjectType.split('.').lastObject;
          this.attributeMappings.addFragment({
            ...mapping.toJSON(),
            status: 'missing',
            mappingVisibility: ticketTypeId === this.privateTicketTypeId ? 'private' : 'public',
          });
        }
      } else {
        this.attributeMappings.addFragment({
          ...mapping.toJSON(),
          status: 'missing',
        });
      }
    });

    importConfig.identityMappings.forEach((mapping: IdentityMapping) => {
      this.identityMappings.addFragment({ ...mapping.toJSON(), status: 'missing' });
    });

    importConfig.sourceAttributes.forEach((attr: CrmAttribute) => {
      this.sourceAttributes.createFragment(attr.toJSON());

      let mappings = this.attributeMappings.filter((mapping: AttributeMapping) =>
        this.matchCrmAttribute(attr, mapping),
      );

      let sourceAttributeType: MappingSourceAttributeType = attr.custom ? 'custom' : 'default';

      if (mappings.length > 0) {
        mappings.forEach((mapping) => {
          if (mapping.editable) {
            mapping.status =
              this.findAssignableDestinationAttribute(mapping) === undefined ? 'skip' : 'done';
          } else {
            mapping.status = 'done';
          }
          mapping.sourceAttributeType = sourceAttributeType;
        });
      } else {
        // non mappable mappings or mappings with no mappable types have a status skip
        let status = attr.mappable || isEmpty(attr.mappableTypes) ? 'skip' : 'non-mappable';

        let fragment = {
          appIdCode: this.session.workspace.id,
          editable: true,
          integrationCode,
          direction: 1,
          sourceAttributePath: attr.path,
          sourceObjectType: attr.crmObjectType,
          sourceType: attr.type,
          disableEmptySourceSync: false,
          disableNonEmptyDestinationSync: false,
          updatedAt: new Date(),
          status,
          sourceAttributeType,
        };

        if (attr.crmObjectType === 'ticket') {
          this.attributeMappings.addFragment({
            ...fragment,
            mappingVisibility: 'public',
            destinationObjectType: `ticket_type.${this.publicTicketTypeId || '_default_'}`,
          });
          this.attributeMappings.addFragment({
            ...fragment,
            mappingVisibility: 'private',
            destinationObjectType: `ticket_type.${this.privateTicketTypeId || '_default_'}`,
          });
        } else {
          this.attributeMappings.addFragment(fragment);
        }
      }
    });

    ['public', 'private'].forEach((visibility: MappingVisibility) => {
      let ticketTypeId = this.getTicketTypeId(visibility);
      if (ticketTypeId !== null) {
        this.loadRequiredTicketMappings(ticketTypeId, visibility);
        taskFor(this.loadTicketType).perform(ticketTypeId, visibility);
      }
    });
  }

  @dropTask *loadCrmObjectsTask(integrationCode: string, objectType: string): TaskGenerator<any> {
    let crmType = this.typeTranslation.getCrmType(objectType)!;
    for (let o of this.crmObjects.values()) {
      if (o.objectType === crmType) {
        return; // crmObjects were already loaded, no need to make a call to the server
      }
    }

    try {
      let page = 1;
      let response = yield this.loadCrmObjectsPage(page, objectType, integrationCode);
      while (isPresent(response.last_page) && response.last_page === false) {
        response = yield this.loadCrmObjectsPage(response.next_page, objectType, integrationCode);
      }
    } catch (e) {
      this.captureError(
        e,
        new Error('DataImport loadCrmObjects failed'),
        'crm_data_import_load_crm_object_task',
      );
    }
  }

  @dropTask *storeConfigurationTask(
    objectTypes: string[],
    identityTypes: string[],
  ): TaskGenerator<any> {
    let crmTypes: string[] = objectTypes.map((t) => this.typeTranslation.getCrmType(t)!);
    let attrMappings = this.filteredAttributeMappings(crmTypes);
    let identityMappings = this.filteredIdentityMappings(identityTypes);
    // We need to create copies to save,
    // otherwise our working mappings and attributes are overwritten by the server's response
    this.importConfig.attributeMappings.clear();
    attrMappings.forEach((mapping) => {
      this.importConfig.attributeMappings.createFragment(mapping.toJSON());
    });
    this.importConfig.identityMappings.clear();
    identityMappings.forEach((mapping) => {
      this.importConfig.identityMappings.createFragment(mapping.toJSON());
    });
    yield this.importConfig.save();
  }

  @dropTask *startDataImportTask(
    objectTypes: string[],
    identityTypes: string[],
  ): TaskGenerator<boolean> {
    let crmObjectTypes: string[] = objectTypes.map((t) => this.typeTranslation.getCrmType(t)!);
    let attrMappings = this.filteredAttributeMappings(crmObjectTypes).map((m) => m.serialize());
    let identityMappings = this.filteredIdentityMappings(identityTypes).map((m) => m.serialize());

    try {
      yield post(`/ember/migrate_from_crm_integrations/start_data_import`, {
        app_id: this.session.workspace.id,
        integration_code: this.integrationCode,
        object_types: crmObjectTypes,
        fallback_intercom_admin_id: this.importConfig.fallbackIntercomAdminId,
        fallback_intercom_team_id: this.importConfig.fallbackIntercomTeamId,
        private_ticket_type_id: this.importConfig.privateTicketTypeId,
        public_ticket_type_id: this.importConfig.publicTicketTypeId,
        migrate_attachments: this.importConfig.migrateAttachments,
        attach_inline_images: this.importConfig.attachInlineImages,
        attribute_mappings: attrMappings,
        identity_mappings: identityMappings,
      });
      return true;
    } catch (e) {
      this.captureError(
        e,
        new Error('DataImport startImport failed'),
        'crm_data_import_start_import_task',
      );
    }
    return false;
  }

  @dropTask *stopDataImportTask(integrationCode?: string): TaskGenerator<void> {
    try {
      yield post(`/ember/migrate_from_crm_integrations/stop_data_import`, {
        app_id: this.session.workspace.id,
        integration_code: integrationCode || this.integrationCode,
      });
    } catch (e) {
      this.captureError(
        e,
        new Error('DataImport stopImport failed'),
        'crm_data_import_stop_import_task',
      );
    }
  }

  @dropTask *loadTicketType(
    ticketTypeId: string | undefined | null,
    visibility: MappingVisibility,
  ): TaskGenerator<void> {
    if (isBlank(ticketTypeId)) {
      return;
    }
    let ticketType = yield this.store.findRecord('inbox/ticket-type', ticketTypeId!);
    if (visibility === 'public') {
      this.publicTicketType = ticketType;
    } else {
      this.privateTicketType = ticketType;
    }
  }

  getTicketTypeId(mappingVisibility: MappingVisibility) {
    return mappingVisibility === 'private' ? this.privateTicketTypeId : this.publicTicketTypeId;
  }

  get fallbackTeamId() {
    return this.importConfig.fallbackIntercomTeamId;
  }

  get fallbackAdminId() {
    return this.importConfig.fallbackIntercomAdminId;
  }

  get privateTicketTypeId() {
    return this.importConfig.privateTicketTypeId;
  }

  get publicTicketTypeId() {
    return this.importConfig.publicTicketTypeId;
  }

  get attachInlineImagesSetting() {
    return this.importConfig.attachInlineImages;
  }

  get migrateAttachmentsSetting() {
    return this.importConfig.migrateAttachments;
  }

  @action setFallbackTeamId(value: string | null) {
    this.importConfig.fallbackIntercomTeamId = value;
  }

  @action setFallbackAdminId(value: string | null) {
    this.importConfig.fallbackIntercomAdminId = value;
  }

  @action setPrivateTicketTypeId(value: string | null) {
    if (this.importConfig.privateTicketTypeId === value) {
      return;
    }

    this.resetTicketMappings(value, 'private');
    this.importConfig.privateTicketTypeId = value;
    taskFor(this.loadTicketType).perform(value, 'private');
  }

  @action setPublicTicketTypeId(value: string | null) {
    if (this.importConfig.publicTicketTypeId === value) {
      return;
    }

    this.resetTicketMappings(value, 'public');
    this.importConfig.publicTicketTypeId = value;
    taskFor(this.loadTicketType).perform(value, 'public');
  }

  @action setAttachInlineImagesSetting(value: boolean) {
    this.importConfig.attachInlineImages = value;
  }

  @action setMigrateAttachmentsSetting(value: boolean) {
    this.importConfig.migrateAttachments = value;
  }

  private findAssignableDestinationAttribute(mapping: AttributeMapping) {
    let objectType = this.typeTranslation.getIntercomType(mapping.sourceObjectType);
    if (
      !objectType ||
      isEmpty(mapping.destinationAttributePath) ||
      isEmpty(mapping.destinationObjectType)
    ) {
      return undefined;
    }

    return this.listMappingAlternatives(objectType, mapping).find((attr: IntercomAttribute) =>
      this.matchIntercomAttribute(attr, mapping),
    );
  }

  private resetTicketMappings(ticketTypeId: string | null, visibility: MappingVisibility) {
    let oldTicketType = this.getTicketTypeId(visibility);
    if (oldTicketType !== null) {
      this.unloadRequiredTicketMappings(oldTicketType);
    }

    // non-required attributes are reset to their empty state and marked as skipped
    this.attributeMappings
      .filter(
        (m) =>
          m.sourceObjectType === 'ticket' &&
          m.mappingVisibility === visibility &&
          m.sourceAttributeType !== 'required',
      )
      .forEach((m) => {
        m.destinationObjectType = `ticket_type.${ticketTypeId}`;
        if (m.editable) {
          m.status = 'skip';
          m.destinationAttributePath = '';
        }
      });

    if (ticketTypeId !== null) {
      this.loadRequiredTicketMappings(ticketTypeId, visibility);
    }
  }

  private loadRequiredTicketMappings(ticketTypeId: string, visibility: MappingVisibility) {
    let attributes: IntercomAttribute[] =
      this.attributeService.crmTicketAttributesByType[ticketTypeId] || [];

    let destinationObjectType = `ticket_type.${ticketTypeId}`;

    attributes
      .filter((attr) => attr.required)
      .forEach((attribute) => {
        let mapping = this.attributeMappings.find(
          (mapping) =>
            mapping.destinationObjectType === destinationObjectType &&
            mapping.destinationAttributePath === attribute.identifier,
        );

        if (mapping !== undefined) {
          // We need to create a copy of the mapping so it can appear in the default/custom attributes table
          // this mapping has the status of 'assigned-elsewhere' so it can't be changed unless the required mapping is changed
          let fragment = {
            ...mapping.toJSON(),
            destinationObjectType: '',
            destinationAttributePath: '',
            status: 'assigned-elsewhere',
          };
          this.attributeMappings.addFragment(fragment);

          // mappings loaded from the server are not made required, do it here.
          mapping.sourceAttributeType = 'required';
          mapping.status = isPresent(mapping.sourceAttributePath) ? 'done' : 'pending';
        } else {
          this.attributeMappings.addFragment({
            appIdCode: this.session.workspace.id,
            editable: true,
            integrationCode: this.integrationCode,
            direction: 1,
            sourceObjectType: 'ticket',
            destinationAttributePath: attribute.identifier,
            destinationObjectType,
            disableEmptySourceSync: false,
            disableNonEmptyDestinationSync: false,
            updatedAt: new Date(),
            status: 'pending',
            sourceAttributeType: 'required',
            mappingVisibility: visibility,
          });
        }
      });
  }

  private unloadRequiredTicketMappings(ticketTypeId: string) {
    let destinationObjectType = `ticket_type.${ticketTypeId}`;
    this.attributeMappings
      .filter(
        (mapping) =>
          mapping.sourceAttributeType === 'required' &&
          mapping.destinationObjectType === destinationObjectType,
      )
      .forEach((mapping) => {
        this.attributeMappings.removeFragment(mapping);
      });
  }

  private async loadCrmObjectsPage(page: number, objectType: string, integrationCode: string) {
    let crmType = this.typeTranslation.getCrmType(objectType)!;
    let response = await get(`/ember/migrate_from_crm_integrations/fetch_crm_objects`, {
      app_id: this.session.workspace.id,
      integration_code: integrationCode,
      object_type: crmType,
      page,
    });

    let intercomObjects = this.getAssignableIntercomObjects(objectType);
    let hasIdentityMappings = this.identityMappings.any((m) => m.intercomObjectType === objectType);

    response.objects?.forEach((object: any) => {
      this.crmObjects.set(
        this.crmObjectKey(object.id, object.object_type),
        new CrmObject(object.id, object.name, object.object_type),
      );

      let mapping = this.identityMappings.find(
        (mapping: IdentityMapping) =>
          mapping.crmObjectId === object.id && mapping.crmObjectType === object.object_type,
      );

      if (mapping !== undefined) {
        mapping.status = 'done';
      } else {
        let matchedEmailFragment = hasIdentityMappings
          ? {}
          : this.intercomObjectMatchEmail(intercomObjects, object.email, objectType);
        this.identityMappings.addFragment({
          status: 'skip',
          crmObjectId: object.id,
          crmObjectType: object.object_type,
          ...matchedEmailFragment,
        } as IdentityMappingFragmentInput);
      }
    });

    return response;
  }

  private intercomObjectMatchEmail(
    intercomObjects: IntercomObject[],
    email: string,
    objectType: string,
  ) {
    let intercomObject = intercomObjects.find(
      (intercomObject: IntercomObject) =>
        intercomObject.email !== undefined && intercomObject.email === email,
    );
    return intercomObject !== undefined
      ? {
          status: 'done',
          intercomObjectId: intercomObject.id,
          intercomObjectType: objectType,
        }
      : {};
  }

  private filteredAttributeMappings(crmTypes: string[]) {
    let mappings = this.attributeMappings.filter(
      (mapping) => crmTypes.includes(mapping.sourceObjectType) && mapping.status === 'done',
    );

    // if the ticket type is not set for either private or public, filter out those mappings
    ['private', 'public'].forEach((visibility: MappingVisibility) => {
      if (isEmpty(this.getTicketTypeId(visibility))) {
        mappings = mappings.filter(
          (mapping) =>
            mapping.sourceObjectType !== 'ticket' || mapping.mappingVisibility !== visibility,
        );
      }
    });

    return mappings;
  }

  private filteredIdentityMappings(types: string[]) {
    return this.identityMappings.filter(
      (mapping) => mapping.status === 'done' && types.includes(mapping.intercomObjectType),
    );
  }

  private matchCrmAttribute(attr: CrmAttribute, mapping: AttributeMapping): boolean {
    return (
      mapping.sourceObjectType === attr.crmObjectType && mapping.sourceAttributePath === attr.path
    );
  }

  private matchIntercomAttribute(attr: IntercomAttribute, mapping: AttributeMapping): boolean {
    return mapping.destinationAttributePath === attr.identifier;
  }

  private matchDestinationObjectType(
    objectType: string,
    mapping: AttributeMapping,
    mappingVisibility: MappingVisibility,
  ) {
    return objectType === 'ticket'
      ? mappingVisibility === mapping.mappingVisibility &&
          [
            'ticket_type._default_',
            `ticket_type.${this.getTicketTypeId(mappingVisibility)}`,
          ].includes(mapping.destinationObjectType)
      : mapping.destinationObjectType === objectType;
  }

  private getAssignableIntercomObjects(intercomObjectType: string): IntercomObject[] {
    let items: IntercomObject[] = [];
    if (intercomObjectType === 'team') {
      items = this.appService.app.assignableTeams.map(
        (team: Team) => new IntercomObject(team.id, team.name, intercomObjectType, team),
      );
    } else if (intercomObjectType === 'admin') {
      items = this.appService.app.humanAdmins.map(
        (admin: Admin) =>
          new IntercomObject(admin.id, admin.name, intercomObjectType, admin, admin.email),
      );
    }

    return items;
  }

  private createImportConfigurationRecord(id: string) {
    let data = this.store.normalize('data/import/import-configuration', {
      _id: id,
      app_id: this.session.workspace.id,
      attribute_mappings: [],
      source_attributes: [],
      identity_mappings: [],
    });

    return this.store.push(data) as ImportConfiguration;
  }

  private crmObjectKey(id: string, objectType: string) {
    return `${objectType}.${id}`;
  }

  private captureError(e: any, error: Error, fingerprint: string) {
    if (e.jqXHR.status === 400 && !isEmpty(e.jqXHR.responseJSON.error_message)) {
      this.notificationsService.notifyError(e.jqXHR.responseJSON.error_message);
      return;
    }
    captureException(error, {
      fingerprint: [fingerprint, 'failed'],
      extra: {
        appId: this.session.workspace.id,
        error: e?.jqXHR?.responseJSON?.errors?.[0],
      },
      tags: {
        responsibleTeam: 'team-customer-data-platform',
        responsible_team: 'team-customer-data-platform',
      },
    });

    this.notificationsService.notifyError(
      this.intl.t(`settings.data-import.${this.integrationCode}.generic-error`),
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    dataImportConfigurationService: ImportConfigurationService;
    'data/import-configuration-service': ImportConfigurationService;
  }
}
