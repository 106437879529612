/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import BreakdownBuilder from 'embercom/lib/monitoring/breakdown-builder';

export default class InboxMonitoringBreakdownService extends Service {
  @service appService;

  async fetchBreakdown(context) {
    let data = await get(this._fetchingUrl(context.metric), this._fetchingParams(context));
    if (data.breakdown && data.breakdown.length > 0) {
      return new BreakdownBuilder({
        buckets: data.breakdown,
        metricType: context.metric.metricType,
      }).build();
    }
  }

  _fetchingUrl(metric) {
    if (metric.adminId) {
      return '/ember/monitoring/teammate_activities/metric_drilldown';
    } else {
      return '/ember/monitoring/inbox_overview/metric_drilldown';
    }
  }

  _timeframeInMinutes(timeframe) {
    return timeframe * 60;
  }

  _fetchingParams(context) {
    return {
      app_id: this.appService.app.id,
      inbox_identifier: context.metric.inboxId,
      metric_type: context.metric.metricType,
      admin_id: context.metric.adminId,
      timeframe: this._timeframeInMinutes(context.timeframe),
      aggregation_type: context.settings.aggregationType,
      idle_threshold: context.settings.idleThreshold,
      exclude_bot_time: context.settings.excludeBotTime,
      filter: context.filter,
    };
  }
}
