/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { mapBy } from '@ember/object/computed';

const WorkflowStepDataFieldIsSet = BaseValidator.extend({
  attributeService: service(),
  collectableAttributeIdentifiers: mapBy(
    'attributeService.botAutoMessageCollectableAttributes',
    'identifier',
  ),
  validate(identifier) {
    if (!identifier || this.collectableAttributeIdentifiers.indexOf(identifier) === -1) {
      return 'You must add a valid attribute for each data field.';
    }
    return true;
  },
});

WorkflowStepDataFieldIsSet.reopenClass({
  getDependentsFor() {
    return ['model.attributeIdentifier'];
  },
});

export default WorkflowStepDataFieldIsSet;
