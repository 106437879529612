/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import type Session from './session';
import { post } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Router from '@ember/routing/router-service';
import { OnboardingType, getActiveStepKey, StepId } from 'embercom/lib/inbox2/onboarding/steps';
import storage from 'embercom/vendor/intercom/storage';

export default class Inbox2OptIn extends Service {
  @service declare appService: any;
  @service declare session: Session;
  @service declare router: Router;

  @tracked forceOptIn = false;

  // we can't rely on the InboxVersion service
  // at this point as `setVersion` may not have
  // been called yet when opting in/out
  get inInbox1(): boolean {
    return !!this.appService.app;
  }

  get hasNeverSeenInbox2() {
    // We call optIn method below when the user completes onboarding.
    // We also need to call the optIn method when the user clicks Skip all button, but we did not do it for some users
    // This means they will be shown onboarding screen when they follow a link even though they have already skipped the onboarding and seen Inbox 2
    // This if condition is for those users who are affected by the bug
    if (this.isOnboardingSkipped) {
      return false;
    }
    return this.optInSetting === null;
  }

  get hasCompletedOnboarding() {
    return this.optInSetting === true;
  }

  get hasInbox1Access() {
    return this.appService.app.currentAdminHasInboxAccess;
  }

  get activeOnboardingStep() {
    return storage.get(getActiveStepKey(this.onboardingType, this.appID));
  }

  @action redirectToOnboarding() {
    this.router.transitionTo('inbox.workspace.onboarding', this.appID, {
      queryParams: {
        type: this.onboardingType,
      },
    });
  }

  get appID(): string {
    if (this.inInbox1) {
      return this.appService.app.id;
    } else {
      return this.session.workspace.id;
    }
  }

  get onboardingType() {
    return this.canUseFeature('inbox_2_new_user_onboarding')
      ? OnboardingType.Short
      : OnboardingType.Default;
  }

  canUseFeature(name: string) {
    if (this.inInbox1) {
      return this.appService.app.canUseFeature(name);
    } else {
      return this.session.workspace.isFeatureEnabled(name);
    }
  }

  async optIn() {
    await post(`/ember/inbox/opt_in/opt_in?app_id=${this.appID}`);
  }

  private get optInSetting() {
    if (this.inInbox1) {
      return this.appService.app.currentAdmin.inbox_2_opt_in;
    } else {
      return this.session.teammate.optedIntoInbox2;
    }
  }

  get isOnboardingCompleted(): boolean {
    let onboardingStepIdInLocalStorage =
      storage.get(getActiveStepKey(OnboardingType.Default, this.session.workspace.id)) ||
      storage.get(getActiveStepKey(OnboardingType.Short, this.session.workspace.id));
    return (
      onboardingStepIdInLocalStorage === StepId.GoToRealInbox ||
      onboardingStepIdInLocalStorage === StepId.FinishOnboardingAndNext
    );
  }

  private get isOnboardingSkipped(): boolean {
    return storage.get(`onboarding-skip-all:${this.appID}`) === true;
  }

  get canResumeOnboarding(): boolean {
    return this.isOnboardingSkipped && !this.isOnboardingCompleted;
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2OptIn: Inbox2OptIn;
    'inbox2-opt-in': Inbox2OptIn;
  }
}
