/* RESPONSIBLE TEAM: team-frontend-tech */
export default function (tmap) {
  tmap.transition(
    tmap.hasClass('product-card-transition'),
    tmap.toValue(true),
    tmap.use('flip', { duration: 100 }),
    tmap.reverse('flip', { duration: 100, reverse: true }),
  );
  tmap.transition(
    tmap.hasClass('product-card-key-features-transition'),
    tmap.toValue(true),
    tmap.use('toDown', { duration: 300 }),
    tmap.reverse('toUp', { duration: 300 }),
  );
}
