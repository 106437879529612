/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { isPresent } from '@ember/utils';
import { resolve } from 'rsvp';
import { schedule } from '@ember/runloop';
import { A } from '@ember/array';
import Service, { inject as service } from '@ember/service';
import Metrics from 'embercom/models/metrics';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import storage from 'embercom/vendor/intercom/storage';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import platform from 'embercom/lib/browser-platform';

const PAGE_RELOAD_TYPE = 1; // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigation/type
const BROWSER_NAME = UserAgentDetector.browserName();

let FrontendStatsService = Service.extend({
  stats: {},
  timings: {},
  markOptions: {},
  firstRun: true,
  interactionMetrics: A(),
  modelDataCacheService: service(),
  lastVisibilityChange: null,
  tracing: service(),
  spans: {},

  statsRecordingDisabled() {
    return !window.performance || !window.performance.getEntriesByName;
  },

  enqueueInteractionMetric(data) {
    this.interactionMetrics.pushObject(data);
  },

  sendInteractionMetrics() {
    return this.debouncedSendMetricsTask.perform();
  },

  debouncedSendMetricsTask: task(function* () {
    yield timeout(ENV.APP._3000MS);
    this.interactionMetrics.forEach((metric) => {
      if (metric.startTime) {
        metric.offset_ms = performance.now() - metric.startTime;
      }

      if (this.lastVisibilityChange) {
        metric.last_visibility_change_offset_ms = performance.now() - this.lastVisibilityChange;
      }

      if (metric.child_events) {
        metric.child_events.forEach((event) => {
          if (event.startTime) {
            event.offset_ms = performance.now() - event.startTime;
          }
        });
      }
    });
    yield Metrics.sendInteractionMetrics(this.interactionMetrics);

    this.interactionMetrics.clear();
  }).restartable(),

  recordBootLocationAndType(targetRoute) {
    if (this.hasRecordedBootLocation || this.statsRecordingDisabled()) {
      return;
    }
    this.set('hasRecordedBootLocation', true);
    let location = Metrics.getGeneralLocationForRoute(targetRoute);
    this.log(`METRICS: 📍 Boot location: ${location}`);
    this.set('bootLocation', location);
    this.enqueueInteractionMetric({
      name: 'boot_location',
      location,
      route: targetRoute,
      browser: this.getBrowserName(),
      platform: this.getPlatformName(),
    });

    if (this.getPerformanceNavigationType() === PAGE_RELOAD_TYPE) {
      this.log(`METRICS: ♻️ Page reload`);
      this.enqueueInteractionMetric({ name: 'page_reload', location });
    }
  },

  getBrowserName() {
    return BROWSER_NAME;
  },

  getPlatformName() {
    return platform.name;
  },

  recordTimeToRender() {
    if (this.hasRecordedTimeToRender || this.statsRecordingDisabled()) {
      return;
    }
    this.set('hasRecordedTimeToRender', true);
    schedule('afterRender', this, () => {
      let time = window.performance.now();
      this.log(`METRICS: 🎨 Time to render: ${time}`);
      this.enqueueInteractionMetric({
        name: 'time_to_render',
        time,
        location: this.bootLocation,
      });
    });
  },

  recordFirstInteraction(target) {
    if (this.hasRecordedFirstInteraction || this.statsRecordingDisabled()) {
      return resolve();
    }
    let location = Metrics.getInteractionLocationForTarget(target);
    let time = window.performance.now();
    this.enqueueInteractionMetric({ name: 'first_interaction', time, location });
    this.log(`METRICS: 👩‍💻 First interaction: ${time}`);
    this.set('hasRecordedFirstInteraction', true);
    return this.sendInteractionMetrics();
  },

  recordCacheMetrics() {
    let cacheMetrics = this.get('modelDataCacheService.cacheMetrics');
    if (isPresent(cacheMetrics)) {
      cacheMetrics.forEach((metric) =>
        this.enqueueInteractionMetric({
          name: 'model_data_cache',
          model: metric.model,
          status: metric.status,
        }),
      );
    }
    return this.sendInteractionMetrics();
  },

  _getInteractionTimeTopic(subject, id) {
    if (id) {
      return `${subject}:${id}`;
    }
    return subject;
  },

  isDocumentHidden() {
    if (typeof document.visibilityState !== 'undefined') {
      return document.visibilityState === 'hidden';
    }

    return false;
  },

  _addMetadataToOpenMarks(metadata) {
    Object.values(this.markOptions).forEach((options) => {
      options.metadata = { ...options.metadata, ...metadata };
    });
  },

  _attachVisibilityChangeListener() {
    document.addEventListener(
      'visibilitychange',
      (event) => {
        this._addMetadataToOpenMarks({ pageVisibilityChanged: true });
        this.lastVisibilityChange = event.timeStamp;
      },
      false,
    );
  },

  startInteractionTime(subject, options = {}) {
    if (this.statsRecordingDisabled()) {
      return;
    }

    if (this.firstRun) {
      this._attachVisibilityChangeListener();
      this.firstRun = false;
    }

    let topic = this._getInteractionTimeTopic(subject, options.id);
    let startMarkName = `${topic}:start`;
    window.performance.mark(startMarkName);

    this.markOptions[topic] = options;

    // We noticed that sometimes it takes hours to finish interaction:inbox spans.
    // It may have something to do with the fact we start and stop this interaction in different components based on conditions.
    // Same issue happens in embercom-performance dataset https://ui.honeycomb.io/intercomops/datasets/embercom-performance/result/vyri6uJJkGq
    // We are disabling them as they prevent our auto-idle detection for OTEL to complete the traces.
    let ignoredCustomMetricNames = ['interaction:inbox', 'component:'];
    if (!ignoredCustomMetricNames.some((ignoredName) => subject.startsWith(ignoredName))) {
      this.spans[options.id] = this.tracing.startChildSpan('customMeasurement', subject);
    }
  },

  stopInteractionTime(subject, options = {}) {
    if (this.statsRecordingDisabled()) {
      return;
    }

    if (this.spans[options.id]) {
      this.tracing.endChildSpan({ span: this.spans[options.id], attributes: options.metadata });
      delete this.spans[options.id];
    }

    let topic = this._getInteractionTimeTopic(subject, options.id);
    let startMarkName = `${topic}:start`;
    let startMarks = performance.getEntriesByName(startMarkName, 'mark');
    if (startMarks.length === 0) {
      return;
    }

    let markOptions = this.markOptions[topic];

    markOptions.metadata = {
      ...markOptions.metadata,
      ...options.metadata,
      pageVisibilityChanged:
        markOptions?.metadata?.pageVisibilityChanged || this.isDocumentHidden(),
    };

    let endMarkName = `${topic}:end`;
    window.performance.mark(endMarkName);
    window.performance.measure(topic, startMarkName, endMarkName);
    let measurement = window.performance.getEntriesByType('measure').find((m) => m.name === topic);

    if (window.__log_performance_metrics) {
      console.info(measurement);
    }

    if (measurement) {
      this.enqueueInteractionMetric({
        name: 'interaction_time',
        subject,
        time: measurement.duration,
        sample_rate: options.sampleRate || 0.01,
        browser: this.getBrowserName(),
        platform: this.getPlatformName(),
        ...markOptions,
      });

      delete this.markOptions[topic];
      window.performance.clearMarks(startMarkName);
      window.performance.clearMarks(endMarkName);
      window.performance.clearMeasures(topic);

      this.sendInteractionMetrics();
    }
  },

  start(key) {
    if (this.statsRecordingDisabled()) {
      return;
    }
    if (this.timings[key] === undefined) {
      //skip the first so we're not capturing data when loading the app
      this.timings[key] = null;
    } else {
      this.timings[key] = window.performance.now();
    }
  },

  stop(key, bufferSize, numberOfItemsRendered) {
    if (this.statsRecordingDisabled()) {
      return;
    }

    let start = this.timings[key];
    if (start) {
      let duration = window.performance.now() - start;
      this.capture(key, duration, bufferSize, numberOfItemsRendered);
      this.timings[key] = null;
    }
  },

  timeUntilAfterRender(key, options = {}) {
    this.start(key);
    schedule('afterRender', this, function () {
      this.stop(key, options.bufferSize, options.numberOfItemsRendered);
    });
  },

  capture(key, value, bufferSize = 10, numberOfItemsRendered = 1) {
    this.stats[key] = this.stats[key] || [];
    this.stats[key].push(value / numberOfItemsRendered);

    if (this.stats[key].length >= bufferSize) {
      Metrics.capture(this.stats);
      this.stats = {};
    }
  },

  log(string) {
    if (storage.get('frontendMetricsLogging')) {
      console.debug(string);
    }
  },

  getPerformanceNavigationType() {
    return window.performance.navigation.type;
  },
});

export default FrontendStatsService;
