/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default class CustomEmailAssetsDomainRequired extends BaseValidator {
  @service intl;

  validate(isLegallyMandated, options, model) {
    if (isLegallyMandated && !model.peekHasCustomEmailAssetsDomain()) {
      return this.intl.t('outbound.email.error-messages.custom-email-assets-domain-missing');
    }
    return true;
  }
}

CustomEmailAssetsDomainRequired.reopenClass({
  getDependentsFor() {
    return ['model.peekHasCustomEmailAssetsDomain'];
  },
});
