/* RESPONSIBLE TEAM: team-frontend-tech */
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import { A } from '@ember/array';
import BlockSerializer from './block';

const OPTIONAL_BLOCK_ATTRIBUTES = A(['text', 'trackingLinkUrl']);

export default BlockSerializer.extend({
  optionalAttributes: OPTIONAL_BLOCK_ATTRIBUTES,
  serialize(snapshot, options) {
    let serialized = this._super(snapshot, options);
    if (isPresent(serialized.videoId)) {
      serialized.id = serialized.videoId;
      delete serialized.videoId;
    } else {
      assert('video blocks must have a videoId to be serialized correctly');
    }
    return serialized;
  },
});
