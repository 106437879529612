/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default class StepHasButtonText extends BaseValidator {
  @service intl;

  validate(value, options, model) {
    if (model.invalidButtonText) {
      return `${model.readableName}: ${this.intl.t(
        'outbound.surveys.validation-errors.no-empty-step-button-text',
      )}`;
    }

    return true;
  }
}

StepHasButtonText.reopenClass({
  getDependentsFor() {
    return ['model.invalidButtonText'];
  },
});
