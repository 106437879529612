/* RESPONSIBLE TEAM: team-reporting */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    archived_events: {
      serialize: 'records',
      deserialize: 'records',
    },
    event_quota: {
      embedded: 'always',
    },
  },
});
