/* RESPONSIBLE TEAM: team-messenger */
import Service, { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { Promise as EmberPromise, all } from 'rsvp';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type NewsfeedSite from 'embercom/models/news/newsfeed-site';

let imageActionsConfig = {
  save: {
    url: '/ember/news/newsfeed_sites/upload_asset.json',
    type: 'POST' as const,
  },
  delete: {
    url: '/ember/news/newsfeed_sites/delete_asset.json',
    type: 'DELETE' as const,
  },
};

export interface MediaHelper {
  type: string;
  url: string;
  file?: File | undefined;
  isReset: boolean;
  isSaved: boolean;
  setProperties(props: Partial<MediaHelper>): void;
  reset(): void;
}

export default class NewsfeedSiteService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare intl: IntlService;
  @tracked site?: NewsfeedSite;

  get app() {
    return this.appService.app;
  }

  @task({ drop: true }) *loadSite(): TaskGenerator<NewsfeedSite> {
    let response = yield this.store.findAll('news/newsfeed-site');
    this.site = response.firstObject;

    return this.site as NewsfeedSite;
  }

  @task({ drop: true }) *saveSite() {
    if (this.site) {
      yield this.site.save();
    }
  }

  saveMediaUpdates(media: any) {
    let updateMediaPromises = [];
    for (let i = 0; i < media.length; i++) {
      if (media[i].hasUpdates) {
        updateMediaPromises.push(this.updateMedia(media[i]));
      }
    }
    return all(updateMediaPromises);
  }

  updateMedia(mediaHelper: MediaHelper) {
    return new EmberPromise(async (resolve, reject) => {
      let type = mediaHelper.type;
      let url = mediaHelper.url;
      let file = mediaHelper.file;
      let isReset = mediaHelper.isReset;
      try {
        if (url) {
          await this.saveImage(file, type);
          mediaHelper.setProperties({
            isSaved: true,
            isReset: false,
          });
        } else if (!url && isReset) {
          await this.deleteImage(type);
          mediaHelper.reset();
        }
        resolve();
      } catch (e) {
        mediaHelper.setProperties({
          isSaved: false,
          isReset: true,
        });
        reject(e);
      }
    });
  }

  async saveImage(image: File | undefined, type: string) {
    let response = await this.imageRequest('save', type, image);
    this.storeUpdatedSite(response);
  }

  async deleteImage(type: string) {
    let response = await this.imageRequest('delete', type);
    this.storeUpdatedSite(response);
  }

  async imageRequest(action: 'save' | 'delete', assetType: string, image?: File | undefined) {
    let { url, type } = imageActionsConfig[action];
    let data = this.imageFormData(assetType, image);

    let response = await ajax({
      type,
      url,
      data,
      contentType: false,
      processData: false,
    });

    return response;
  }

  imageFormData(assetType: string, image?: File | undefined) {
    let formData = new FormData();
    formData.append('asset_type', assetType);
    formData.append('app_id', this.app.id);
    if (image) {
      formData.append('image', image);
    }
    return formData;
  }

  storeUpdatedSite(updated: NewsfeedSite) {
    this.store.pushPayload({
      'news/newsfeed-site': [updated],
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    newsfeedSiteService: NewsfeedSiteService;
    'newsfeed-site-service': NewsfeedSiteService;
  }
}
