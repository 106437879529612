import Handlebars from 'handlebars';
      const _template = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <img class=\"avatar__media\" src=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"image_urls") : stack1)) != null ? lookupProperty(stack1,"normal") : stack1), depth0))
    + "\" style=\"position:absolute;z-index:2;\">\n  <img class=\"avatar__media\" src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"default_avatar_url") || (depth0 != null ? lookupProperty(depth0,"default_avatar_url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"default_avatar_url","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":56}}}) : helper)))
    + "\" style=\"position:absolute;z-index:1;\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"initials") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"avatar__media\" style=\"background-color: #"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + ";\">\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"initials") : stack1), depth0))
    + "\n    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <img class=\"avatar__media\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"default_avatar_url") || (depth0 != null ? lookupProperty(depth0,"default_avatar_url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"default_avatar_url","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":58}}}) : helper)))
    + "\" style=\"position:absolute;z-index:1;\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- TODO: possible to reuse this from avatar component template? -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? lookupProperty(stack1,"image_urls") : stack1)) != null ? lookupProperty(stack1,"normal") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});
      export default _template;