/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';

export default class Inbox2TrustedDomainsForInboundEmailLinks extends Service {
  @service declare regionService: any;

  get trustedDomains() {
    return this.regionService.safeBrowsingIntercomHosts;
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2TrustedDomainsForInboundEmailLinks: Inbox2TrustedDomainsForInboundEmailLinks;
    'inbox2-trusted-domains-for-inboun-email-links': Inbox2TrustedDomainsForInboundEmailLinks;
  }
}
