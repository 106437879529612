/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

const RulesetDoesNotTargetMobileAndUsePageTargeting = BaseValidator.extend({
  validate(value, options, model) {
    let devicesPreferred = model.rulesetLinks
      .map((rulesetLink) => rulesetLink.preferredDevices)
      .flat()
      .compact();

    if (isEmpty(value) || isEmpty(value.basePredicates)) {
      return true;
    }

    let targetsWeb = devicesPreferred.includes(preferredDevices.web);
    if (targetsWeb) {
      return true;
    }

    let targetsiOS = devicesPreferred.includes(preferredDevices.ios);
    let targetsAndroid = devicesPreferred.includes(preferredDevices.android);

    if (targetsiOS || targetsAndroid) {
      return 'You can not use page targeting when sending to iOS or Android.';
    } else {
      return true;
    }
  },
});

RulesetDoesNotTargetMobileAndUsePageTargeting.reopenClass({
  getDependentsFor() {
    return [
      'model.rulesetLinks.@each.preferredDevices',
      'model.clientPredicateGroup.basePredicates.@each',
    ];
  },
});

export default RulesetDoesNotTargetMobileAndUsePageTargeting;
