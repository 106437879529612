/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const NoEmptyCodeBlocks = BaseValidator.extend({
  validate(value, options, model) {
    let codeBlocks = value.filter((block) => block.type === 'code');
    if (codeBlocks.every((block) => block.hasContent)) {
      return true;
    }
    return options.message || 'Finish writing the text for your code block.';
  },
});

NoEmptyCodeBlocks.reopenClass({
  getDependentsFor() {
    return ['model.blocks.@each.{type,hasContent}'];
  },
});

export default NoEmptyCodeBlocks;
