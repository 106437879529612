/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';

export default class OperatorMessengerAppService extends Service {
  @service store;
  @service appService;

  get app() {
    return this.appService.app;
  }

  validateMessengerAppIds(messengerAppIds) {
    let errors = messengerAppIds.reduce((errors, appId) => {
      let messengerApp = this.store.peekRecord('messenger-app', appId);
      if (!messengerApp?.installed) {
        errors.push(`An uninstalled app is present`);
      } else if (!messengerApp.availableInOperator) {
        errors.push(`The App ${messengerApp.get('name')} cannot be used in a bot`);
      } else if (messengerApp.paywalledInOperator && !this.app.canUseAppsInOperator) {
        errors.push(`Upgrade to use App ${messengerApp.get('name')} in a bot`);
      }
      return errors;
    }, []);

    return errors.length > 0 ? errors.join(', ') : true;
  }
}
