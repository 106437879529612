/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default BaseValidator.extend({
  operatorMessengerAppService: service(),

  validate(value) {
    if (!value) {
      return true;
    }

    let messengerAppIds = this.extractMessengerAppIds(value);
    if (messengerAppIds.length > 0) {
      return this.operatorMessengerAppService.validateMessengerAppIds(messengerAppIds);
    }

    return true;
  },

  extractMessengerAppIds(value) {
    let rules = (value.name === 'followUpRules' ? value._objects : [value]) || [];
    let actions = rules.reduce((actions, rule) => {
      actions.push(...rule.actions.toArray());
      return actions;
    }, []);

    let appActionTypes = [
      'send-app',
      'send-app-without-introduction',
      'send-app-with-introduction',
    ];

    return actions
      .filter(
        (action) => appActionTypes.includes(action.type) && action.get('actionData.messengerAppId'),
      )
      .map((action) => action.get('actionData.messengerAppId'));
  },
});
