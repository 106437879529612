/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const StepCanHaveActions = BaseValidator.extend({
  validate(value, options, model) {
    if (!model.isThankYouType && model.hasActions) {
      return `Step ${model.order + 1}: Only thank you steps can have actions.`;
    }

    return true;
  },
});

StepCanHaveActions.reopenClass({
  getDependentsFor() {
    return ['model.hasActions', 'model.isThankYouType'];
  },
});

export default StepCanHaveActions;
