/* RESPONSIBLE TEAM: team-channels */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class SmsLanguageSerializer extends EmbercomBaseSerializer {
  serialize(snapshot, options) {
    let raw = super.serialize(snapshot, options);

    let json = this.renameColumns(raw, [{ from: 'sms_languages', to: 'sms_languages_attributes' }]);

    return {
      setting: {
        sms_languages_attributes: json.sms_languages_attributes.filter((row) => !!row.sms_content),
        phone_based_user_association: json.phone_based_user_association,
        thank_you_message_url: json.thank_you_message_url,
      },
    };
  }

  renameColumns(hash, pairs = []) {
    let mutation = Object.assign({}, hash);

    pairs
      .filter(({ from, to }) => !!mutation[from])
      .forEach(({ from, to }) => {
        mutation[to] = mutation[from];
        delete mutation[from];
      });

    return mutation;
  }
}
