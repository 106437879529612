/* RESPONSIBLE TEAM: team-frontend-tech */
import AnswersTransitions from 'embercom/transition-maps/answers-transitions';
import MessageEditorTransitions from 'embercom/transition-maps/message-editor-transitions';
import OnboardingTransitions from 'embercom/transition-maps/onboarding-transitions';
import FeedTransitions from 'embercom/transition-maps/feed-transitions';
import ProductCardTransitions from 'embercom/transition-maps/product-card-transitions';
import ContentEditorTransitions from 'embercom/transition-maps/content-editor-transitions';
import OnboardingHomeTransitions from 'embercom/transition-maps/onboarding-home-transitions';
import InboxTransitions from 'embercom/transition-maps/inbox-transitions';
import ArticlesTransitions from './transition-maps/articles-transitions';

export default function () {
  AnswersTransitions(this);
  OnboardingTransitions(this);
  MessageEditorTransitions(this);
  FeedTransitions(this);
  ProductCardTransitions(this);
  ContentEditorTransitions(this);
  OnboardingHomeTransitions(this);
  InboxTransitions(this);
  ArticlesTransitions(this);
}
