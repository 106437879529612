/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { Promise as EmberPromise } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import Conversation from 'embercom/models/conversation';

export default Service.extend({
  store: service(),
  options: {},

  getConversationsPage(options) {
    this.set('options', options);
    return new EmberPromise((resolve) => {
      return this._conversationSearch().then((response) => {
        resolve({
          conversations: this._loadConversationsResponse(response),
          totalCount: response.total_count || 0,
        });
      });
    });
  },

  paginate(options) {
    this.set('options', options);
    return new EmberPromise((resolve, reject) => {
      this._conversationSearch().then(
        (response) => {
          let conversations = this._loadConversationsResponse(response);

          conversations.forEach((conversation) => {
            if (!this.get('options.currentConversations').includes(conversation)) {
              this.get('options.currentConversations').pushObject(conversation);
              return;
            }
          });

          resolve({
            conversations: this.get('options.currentConversations'),
            totalCount: response.total_count,
            hasMoreConversationsToLoad:
              this.get('options.currentConversations').length < response.total_count,
          });
        },
        (error) => {
          reject(error);
        },
      );
    });
  },

  _loadConversationsResponse(response) {
    let conversations = this._formatConversations(response.conversations);
    this.store.pushPayload({ conversations });
    return conversations.map(({ id }) => this.store.peekRecord('conversation', id));
  },

  _formatConversations(response) {
    if (this.get('options.userId')) {
      return response.map((conversation) => {
        return conversation.conversation;
      });
    } else {
      return response;
    }
  },

  _conversationSearch() {
    return Conversation.newSearch({
      app_id: this.get('options.appId'),
      assignee_identifier: this.get('options.adminId'),
      page_from: this.get('options.pageFrom'),
      user_id: this.get('options.userId'),
      scope: this.get('options.scope'),
      status: this.get('options.status'),
      before: this.get('options.before'),
      initiated_by_admin_id: this.get('options.initiatedByAdminId'),
    });
  },
});
