/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';
import { computed } from '@ember/object';
import EmberOAuth2 from 'ember-oauth2/services/ember-oauth2';

export default Service.extend({
  mailchimp: computed(function () {
    return EmberOAuth2.create().setProvider('mailchimp');
  }),
});
