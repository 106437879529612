/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import abbreviatedRelativeTime from 'embercom/lib/abbreviated-relative-time';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import { isNumber } from 'underscore';
import { tracked } from '@glimmer/tracking';
import type Session from './session';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { type TaskFunction } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type View from 'embercom/objects/inbox/inboxes/view';

interface Settings {
  timeframeInHours: number;
  aggregationType: string;
  idleThreshold: number;
  excludeBotTime: boolean;
  excludedQueryTypes: string[];
}

export interface ConversationsAggregations {
  avgWaitTime: string;
  closed: number;
  csat: string | number;
  idlePercentage: number | null;
  open: number;
  sla: number;
  snoozed: number;
  unassigned: number;
  waitingForFirstReply: number;
}

export interface CallsAggregations {
  callsInQueue?: number;
  callsActive?: number;
  callsCompleted?: number;
  voicemailCalls?: number;
  callsAbandoned?: number;
  callsAnswerTime?: string;
  callsDuration?: string;
  phoneAvailableTeammates?: number;
  callbacksAnswered?: number;
  callbacksUnanswered?: number;
  callbacksPending?: number;
  callbacksClosed?: number;
}

export interface CallsMetrics {
  callsActive?: number;
  callsInQueue?: number;
  callsCompleted?: number;
  callsAbandoned?: number;
  voicemailCalls?: number;
  callsTeamQueueTime?: string;
  callsAnswerTime?: string;
  callsTeamTalkTime?: string;
  phoneAvailableTeammates?: number;
  callbacksAnswered?: number;
  callbacksUnanswered?: number;
  callbacksPending?: number;
  callbacksClosed?: number;
}

type CachedOverviewItem = { inboxId: string; csat: number; missCount: number; missRate: number };
type CachedOverview = [CachedOverviewItem];

export default class Inbox2OverviewService extends Service {
  @service declare session: Session;
  @tracked isFetchingData = false;
  declare forceRefresh: TaskFunction<void, any>;

  async getInboxData(
    inboxes: Inbox[],
    settings: Settings,
    { cachedOverview }: { cachedOverview: CachedOverview },
  ) {
    try {
      this.isFetchingData = true;
      let response = await this.fetch(inboxes, settings);
      return {
        overview: this.aggregatePerInbox(
          response,
          inboxes,
          settings.excludedQueryTypes,
          cachedOverview,
        ),
        aggregations: this.readConversationsAggregations(response),
        callsAggregations: this.readCallsAggregations(response),
      };
    } finally {
      this.isFetchingData = false;
    }
  }

  async getMetricData(settings: any) {
    try {
      this.isFetchingData = true;
      let response = await this.fetchMetricDrilldown(settings);
      return response;
    } finally {
      this.isFetchingData = false;
    }
  }

  // TODO: create a type for response
  aggregatePerInbox(response: any, inboxes: Inbox[], excluded: string[], cache: CachedOverview) {
    return inboxes.map((inbox) => this.readResponseForInbox(inbox, response, excluded, cache));
  }

  readResponseForInbox(inbox: Inbox, response: any, excluded: string[], cache: CachedOverview) {
    let readFromCache = (key: keyof CachedOverviewItem) =>
      cache.find(({ inboxId }) => inboxId === inbox.id)?.[key];

    let slaData = excluded.includes('sla')
      ? { missRate: readFromCache('missRate'), missCount: readFromCache('missCount') }
      : this.getSlaData(response, inbox);

    let callsData: CallsMetrics = {};

    if (this.session.workspace.isFeatureEnabled('inbound-phone-call')) {
      callsData = {
        callsActive: this.getCallsActive(response, inbox),
        callsInQueue: this.getCallsInQueue(response, inbox),
        callsCompleted: this.getCallsCompleted(response, inbox),
        callsAbandoned: this.getCallsAbandoned(response, inbox),
        voicemailCalls: this.getVoicemailCalls(response, inbox),
        callsTeamQueueTime: this.getCallsTeamQueueTime(response, inbox),
        callsAnswerTime: this.getCallsAnswerTimeForInbox(response, inbox),
        callsTeamTalkTime: this.getCallsTeamTalkTime(response, inbox),
        phoneAvailableTeammates: this.getPhoneAvailableTeammates(response, inbox),
        callbacksAnswered: this.getCallbacksAnswered(response, inbox),
        callbacksUnanswered: this.getCallbacksUnanswered(response, inbox),
        callbacksPending: this.getCallbacksPending(response, inbox),
        callbacksClosed: this.getCallbacksClosed(response, inbox),
      };
    }

    return {
      inboxId: inbox.id,
      inboxIcon: this.getIcon(inbox),
      name: this.getName(inbox),
      open: this.getOpen(response, inbox),
      unassigned: this.getUnassigned(response, inbox),
      waitingForReply: this.getWaitingForReply(response, inbox),
      longestWaiting: this.getLongestWaiting(response, inbox),
      longestWaitingTimestamp: this.getLongestWaitingTimestamp(response, inbox),
      closed: this.getClosed(response, inbox),
      avgWaitTime: this.getAvgWaitTime(response.avg_wait_time[inbox.id]),
      avgWaitTimeInSeconds: response.avg_wait_time[inbox.id],
      csat: excluded.includes('csat')
        ? readFromCache('csat')
        : this.getCsat(response.csat[inbox.id]),
      activeTeammates: this.getActiveTeammates(response, inbox),
      idle: this.getIdle(response, inbox),
      snoozed: this.getSnoozed(response, inbox),
      ...slaData,
      ...callsData,
    };
  }

  readConversationsAggregations(response: any): ConversationsAggregations {
    return {
      open: response.aggregations.open,
      unassigned: response.aggregations.unassigned,
      snoozed: response.aggregations.snoozed,
      waitingForFirstReply: response.aggregations.waiting_for_first_reply,
      csat: this.getCsat(response.aggregations.csat),
      closed: response.aggregations.closed,
      idlePercentage: this.getIdlePercentage(response),
      avgWaitTime: this.getAvgWaitTime(response.aggregations.avg_wait_time),
      sla: response.aggregations.sla,
    };
  }

  readCallsAggregations(response: any): CallsAggregations {
    return {
      callsInQueue: response.calls_aggregations?.calls_in_queue,
      callsActive: response.calls_aggregations?.calls_active,
      callsCompleted: response.calls_aggregations?.calls_completed,
      voicemailCalls: response.calls_aggregations?.voicemail_calls,
      callsAbandoned: response.calls_aggregations?.calls_abandoned,
      callsAnswerTime: this.getCallsAnswerTime(response.calls_aggregations?.calls_answer_time),
      callsDuration: this.getCallsDuration(response.calls_aggregations?.calls_duration),
      phoneAvailableTeammates: response.calls_aggregations?.phone_available_teammates,
      callbacksAnswered: response.calls_aggregations?.callbacks_answered,
      callbacksUnanswered: response.calls_aggregations?.callbacks_unanswered,
      callbacksPending: response.calls_aggregations?.callbacks_pending,
      callbacksClosed: response.calls_aggregations?.callbacks_closed,
    };
  }

  getIcon(inbox: Inbox) {
    return inbox.category === InboxCategory.View ? (inbox as View).viewSummary.icon : inbox.icon;
  }

  getName(inbox: Inbox) {
    return inbox.category === InboxCategory.View ? (inbox as View).viewSummary.name : inbox.name;
  }

  getOpen(response: any, { id }: any) {
    return response.open[id] || 0;
  }

  getUnassigned(response: any, { id }: any) {
    return response.unassigned[id] || 0;
  }

  getWaitingForReply(response: any, { id }: any) {
    return response.waiting_for_first_admin_reply[id] || 0;
  }

  getLongestWaiting(response: any, { id }: any) {
    let longestWaiting = response.longest_waiting[id];
    return {
      conversationId: longestWaiting?.['conversation_id'],
      waitingSince: longestWaiting?.['waiting_since']
        ? abbreviatedRelativeTime(longestWaiting['waiting_since'])
        : '',
    };
  }

  getLongestWaitingTimestamp(response: any, { id }: any): number | undefined {
    let longestWaiting = response.longest_waiting[id];
    if (longestWaiting?.['waiting_since']) {
      return new Date(longestWaiting?.['waiting_since']).getTime();
    }

    return undefined;
  }

  getAvgWaitTime(avgWaitTime: any) {
    return this.getFormattedTime(avgWaitTime);
  }

  getCallsDuration(callsDuration?: number) {
    return this.getFormattedTime(callsDuration);
  }

  getCallsAnswerTime(callsAnswerTime?: number) {
    return this.getFormattedTime(callsAnswerTime);
  }

  getCallsTeamQueueTime(response: any, { id }: any) {
    let callsTeamQueueTime = response.calls_team_queue_time[id];
    return this.getFormattedTime(callsTeamQueueTime);
  }

  getCallsAnswerTimeForInbox(response: any, { id }: any) {
    let callsAnswerTime = response.calls_answer_time[id];
    return this.getFormattedTime(callsAnswerTime);
  }

  getCallsTeamTalkTime(response: any, { id }: any) {
    let callsTeamTalkTime = response.calls_team_talk_time[id];
    return this.getFormattedTime(callsTeamTalkTime);
  }

  getFormattedTime(timeInSeconds: any) {
    if (timeInSeconds) {
      let timeFormatter = new Formatters.seconds(); // eslint-disable-line new-cap
      return timeFormatter.formatCounter(timeInSeconds);
    }

    return '';
  }

  getClosed(response: any, { id }: any) {
    return response.closed[id] || 0;
  }

  getCsat(csat: any) {
    return isNumber(csat) ? Math.round(csat) : '';
  }

  getActiveTeammates(response: any, { id }: any) {
    return response.active_teammates[id];
  }

  getPhoneAvailableTeammates(response: any, { id }: any) {
    return response.phone_available_teammates[id];
  }

  getIdle(response: any, { id }: any) {
    return response.idle?.[id] || 0;
  }

  getIdlePercentage(response: any) {
    let totalOpen = response.aggregations.open;
    return totalOpen === 0 ? null : Math.round((response.aggregations.idle * 100) / totalOpen);
  }

  getSnoozed(response: any, { id }: any) {
    return response.snoozed[id] || 0;
  }

  getSlaData(response: any, { id }: any) {
    if (!response.sla[id]) {
      return null;
    }

    let conversationsWithSla = response.sla[id].conversations_with_sla;
    let missed = response.sla[id].missed;
    return {
      missRate:
        conversationsWithSla === 0 ? null : Math.round((missed * 100) / conversationsWithSla),
      missCount: missed,
    };
  }

  getCallsActive(response: any, { id }: any) {
    return response.calls_active[id] || 0;
  }

  getCallsInQueue(response: any, { id }: any) {
    return response.calls_in_queue[id] || 0;
  }

  getCallsCompleted(response: any, { id }: any) {
    return response.calls_completed[id] || 0;
  }

  getCallsAbandoned(response: any, { id }: any) {
    return response.calls_abandoned[id] || 0;
  }

  getCallbacksAnswered(response: any, { id }: any) {
    return response.callbacks_answered[id] || 0;
  }

  getCallbacksUnanswered(response: any, { id }: any) {
    return response.callbacks_unanswered[id] || 0;
  }
  getCallbacksPending(response: any, { id }: any) {
    return response.callbacks_pending[id] || 0;
  }

  getCallbacksClosed(response: any, { id }: any) {
    return response.callbacks_closed[id] || 0;
  }

  getVoicemailCalls(response: any, { id }: any) {
    return response.voicemail_calls[id] || 0;
  }

  // set type
  fetchMetricDrilldown({
    inboxId,
    metricType,
    adminId,
    timeframeInHours,
    filter,
    aggregationType,
    idleThreshold,
    excludeBotTime,
  }: any): Promise<any> {
    return ajax({
      url: adminId
        ? '/ember/monitoring/teammate_activities/metric_drilldown'
        : '/ember/monitoring/inbox_overview/metric_drilldown',
      type: 'GET',
      data: {
        app_id: this.session.workspace.id,
        inbox_identifier: inboxId,
        metric_type: metricType,
        admin_id: adminId,
        timeframe: timeframeInHours * 60,
        filter,
        aggregation_type: aggregationType,
        idle_threshold: idleThreshold,
        exclude_bot_time: excludeBotTime,
      },
    });
  }

  fetch(
    inboxes: Inbox[],
    {
      timeframeInHours,
      aggregationType,
      idleThreshold,
      excludeBotTime,
      excludedQueryTypes,
    }: Settings,
  ): Promise<any> {
    return ajax({
      url: '/ember/monitoring/inbox_overview',
      type: 'GET',
      data: {
        app_id: this.session.workspace.id,
        inbox_identifiers: inboxes.map(({ id }) => id),
        timeframe: timeframeInHours * 60,
        aggregation_type: aggregationType,
        idle_threshold: idleThreshold,
        exclude_bot_time: excludeBotTime,
        excluded_query_types: excludedQueryTypes,
      },
    });
  }

  setForceRefresh(refreshTask: any) {
    this.forceRefresh = refreshTask;
  }

  doForceRefresh(options: any) {
    if (this.forceRefresh) {
      taskFor(this.forceRefresh).perform(options);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2OverviewService: Inbox2OverviewService;
    'inbox2-overview-service': Inbox2OverviewService;
  }
}
