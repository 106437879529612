/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Session from './session';
import type InboxVersion from './inbox-version';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';

export default class SnoozeConfig extends Service {
  @service declare intl: IntlService;
  @service declare inboxVersion: InboxVersion;
  @service declare session: Session;
  @tracked customSnoozeSelected = false;
  @tracked snoozePaneShown = false;
  @tracked placeholder = '';

  constructor(owner: any) {
    super(owner);
    this.resetPlaceHolder();
  }

  @action resetPlaceHolder() {
    this.placeholder = this.intl.t('inbox.command-k.placeholders.relative-snooze');
  }
}

declare module '@ember/service' {
  interface Registry {
    snoozeConfig: SnoozeConfig;
    'snooze-config': SnoozeConfig;
  }
}
