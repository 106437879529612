/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { timeout, type TaskGenerator } from 'ember-concurrency';
import generateUUID from 'embercom/lib/uuid-generator';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import {
  NexusEventName,
  type AdminIsViewingConversationEvent,
  type AdminStoppedViewingConversationEvent,
} from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import ENV from 'embercom/config/environment';
import { schedule } from '@ember/runloop';

// Timers will block tests from completing. Here were setting it to 10ms
// to prevent delaying our test runs.
export const POLL_INTERVAL = ENV.environment === 'test' ? 10 : 30_000;

export default class RealtimeConversationParticipants extends Service {
  @service declare nexus: Nexus;
  @service declare intercomEventService: any;

  @tracked viewDataByAdmin: { [key: string]: any } = {};
  @tracked viewDataByConversation: { [key: string]: any } = {};
  private viewDataBySession: { [key: string]: any } = {};

  private tasks: { [key: string]: any } = {};
  private sessionUUID: string;

  constructor() {
    super(...arguments);
    this.sessionUUID = generateUUID();
    this.subscribeToEvents();
  }

  adminsViewingConversation(conversationID: number): Array<AdminSummary> {
    let conversationData = this.viewDataByConversation[conversationID] || {};
    let eventData: Array<AdminIsViewingConversationEvent['eventData']> =
      Object.values(conversationData);
    return eventData.uniq().map((data) => AdminSummary.deserialize(data.admin));
  }

  conversationsAdminIsViewing(adminID: number): Array<number> {
    let adminData = this.viewDataByAdmin[adminID] || {};
    let eventData: Array<AdminIsViewingConversationEvent['eventData']> = Object.values(adminData);
    return eventData.uniq().map((data) => data.conversationID);
  }

  viewingConversation(admin: AdminSummary, conversationID: number): void {
    let sessionUUID = this.sessionUUID;

    this.nexus.sendEvent(NexusEventName.AdminIsViewingConversation, {
      sessionUUID,
      conversationID,
      admin: this.serializeAdmin(admin),
    } as AdminIsViewingConversationEvent['eventData']);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'conversation',
      place: 'inbox',
      conversation_id: conversationID,
      admin_id: admin.id,
    });
  }

  stopViewingConversation(admin: AdminSummary, conversationID: number): void {
    let sessionUUID = this.sessionUUID;
    this.nexus.sendEvent(NexusEventName.AdminStoppedViewingConversation, {
      sessionUUID,
      conversationID,
      admin: this.serializeAdmin(admin),
    } as AdminStoppedViewingConversationEvent['eventData']);
  }

  private serializeAdmin(admin: AdminSummary): AdminSummaryWireFormat {
    return {
      id: admin.id,
      name: admin.name,
      image_url: admin.imageURL,
    };
  }

  private subscribeToEvents() {
    this.nexus.addListener(
      NexusEventName.AdminIsViewingConversation,
      (e: AdminIsViewingConversationEvent) =>
        this.handleAdminIsViewingConversationEvent(e.eventData),
    );
    this.nexus.addListener(
      NexusEventName.AdminStoppedViewingConversation,
      (e: AdminStoppedViewingConversationEvent) =>
        this.handleAdminStoppedViewingConversationEvent(e.eventData),
    );
  }

  private handleAdminIsViewingConversationEvent(
    data: AdminIsViewingConversationEvent['eventData'],
  ): void {
    schedule('actions', () => {
      if (this.sessionUUID === data.sessionUUID) {
        return;
      }

      this.tasks[data.sessionUUID]?.cancel();
      let task = taskFor(this.trackAdminViewingConversation).perform(data);
      this.tasks[data.sessionUUID] = task;
    });
  }

  private handleAdminStoppedViewingConversationEvent(
    data: AdminStoppedViewingConversationEvent['eventData'],
  ): void {
    schedule('actions', () => {
      if (this.sessionUUID === data.sessionUUID) {
        return;
      }

      this.tasks[data.sessionUUID]?.cancel();
      this.untrackDataForSession(data);
    });
  }

  @task
  private *trackAdminViewingConversation(
    data: AdminIsViewingConversationEvent['eventData'],
  ): TaskGenerator<void> {
    this.trackDataForSession(data);
    yield timeout(POLL_INTERVAL * 1.1); // wait for more than the interval to account for latency in receiving the update
    this.untrackDataForSession(data);
  }

  private trackDataForSession(data: AdminIsViewingConversationEvent['eventData']): void {
    let previousSessionData = this.viewDataBySession[data.sessionUUID];
    if (previousSessionData) {
      if (previousSessionData.conversationID === data.conversationID) {
        return;
      } else {
        this.untrackDataForSession(previousSessionData);
      }
    }

    this.viewDataBySession[data.sessionUUID] = data;

    let adminViewData = this.viewDataByAdmin[data.admin.id] || {};
    adminViewData[data.sessionUUID] = data;
    this.viewDataByAdmin[data.admin.id] = adminViewData;
    this.viewDataByAdmin = this.viewDataByAdmin;

    let conversationViewData = this.viewDataByConversation[data.conversationID] || {};
    conversationViewData[data.sessionUUID] = data;
    this.viewDataByConversation[data.conversationID] = conversationViewData;
    this.viewDataByConversation = this.viewDataByConversation;
  }

  private untrackDataForSession(
    data:
      | AdminStoppedViewingConversationEvent['eventData']
      | AdminIsViewingConversationEvent['eventData'],
  ): void {
    delete this.tasks[data.sessionUUID];

    let adminViewData = this.viewDataByAdmin[data.admin.id] || {};
    delete adminViewData[data.sessionUUID];
    this.viewDataByAdmin[data.admin.id] = adminViewData;
    this.viewDataByAdmin = this.viewDataByAdmin;

    let conversationViewData = this.viewDataByConversation[data.conversationID] || {};
    delete conversationViewData[data.sessionUUID];
    this.viewDataByConversation[data.conversationID] = conversationViewData;
    this.viewDataByConversation = this.viewDataByConversation;

    delete this.viewDataBySession[data.sessionUUID];
  }
}

declare module '@ember/service' {
  interface Registry {
    realtimeConversationParticipants: RealtimeConversationParticipants;
    'realtime-conversation-participants': RealtimeConversationParticipants;
  }
}
