/* RESPONSIBLE TEAM: team-channels */
import Service, { inject as service } from '@ember/service';
import type Session from './session';

export default class AdminPermissions extends Service {
  @service declare session: Session;

  get canAccessRealTimeDashboard(): boolean {
    return this.session.teammate?.permissions?.canAccessRealTimeDashboard;
  }

  get canDeleteMessageOrNotes(): boolean {
    return this.session.teammate?.permissions?.canRedactConversationParts;
  }

  get canReassignConversations(): boolean {
    return this.session.teammate?.permissions?.canReassignConversations;
  }

  get canSendFromCustomAddresses(): boolean {
    return this.session.teammate?.permissions?.canSendFromCustomAddresses;
  }
}

declare module '@ember/service' {
  interface Registry {
    adminPermissions: AdminPermissions;
    'admin-permissions': AdminPermissions;
  }
}
