/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';

export default class Navigatorkeyboard extends Service {
  // @ts-ignore
  keyboardObject = navigator.keyboard;
}

declare module '@ember/service' {
  interface Registry {
    navigatorkeyboard: Navigatorkeyboard;
    'navigator-keyboard': Navigatorkeyboard;
  }
}
