/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import BreakdownBuilder from 'embercom/lib/monitoring/breakdown-builder';
import type Session from './session';

interface BreakdownMetric {
  adminId?: string;
  inboxId?: string;
  metricType: string;
}

export interface BreakdownSettings {
  aggregationType: string;
  idleThreshold: number;
  excludeBotTime: boolean;
}

interface Context {
  metric: BreakdownMetric;
  timeframe?: number;
  settings?: BreakdownSettings;
}

interface BucketItems {
  displayValue: string;
  displayName: string;
  name: string;
  value: string;
}

export interface Breakdown {
  buckets: BucketItems[];
  isPercentageBreakdown: boolean;
}

export default class Inbox2MonitoringBreakdownService extends Service {
  @service declare session: Session;

  async fetchBreakdown(context: Context): Promise<Breakdown | undefined> {
    let data = await get(this._fetchingUrl(context.metric), this._fetchingParams(context));
    if (data.breakdown?.length > 0) {
      return new BreakdownBuilder({
        buckets: data.breakdown,
        metricType: context.metric.metricType,
      }).build();
    }
    return undefined;
  }

  _fetchingUrl(metric: BreakdownMetric) {
    if (metric.adminId) {
      return '/ember/monitoring/teammate_activities/metric_drilldown';
    } else {
      return '/ember/monitoring/inbox_overview/metric_drilldown';
    }
  }

  _timeframeInMinutes(timeframe: number) {
    return timeframe * 60;
  }

  _fetchingParams(context: Context) {
    return {
      app_id: this.session.workspace.id,
      inbox_identifier: context.metric.inboxId,
      metric_type: context.metric.metricType,
      admin_id: context.metric.adminId,
      timeframe: this._timeframeInMinutes(context.timeframe || 0),
      aggregation_type: context?.settings?.aggregationType,
      idle_threshold: context?.settings?.idleThreshold,
      exclude_bot_time: context?.settings?.excludeBotTime,
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2MonitoringBreakdownService: Inbox2MonitoringBreakdownService;
    'inbox2-monitoring-breakdown-service': Inbox2MonitoringBreakdownService;
  }
}
