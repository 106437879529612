/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class MacrosService extends Service {
  @service realTimeEventService;
  @service store;
  @service notificationsService;
  @service router;
  @service appService;
  @service savedReplySearchService;

  enableRealtimeHandlers() {
    this.realTimeEventService.on('MacroExecutionError', this, '_handleMacroExecutionError');
    this.realTimeEventService.on('SavedReplyCreated', this, '_refreshSavedReplies');
    this.realTimeEventService.on('SavedReplyDeleted', this, '_refreshSavedReplies');
    this.realTimeEventService.on('SavedReplyUpdated', this, '_refreshSavedReplies');
  }

  disableRealtimeHandlers() {
    this.realTimeEventService.off('MacroExecutionError', this, '_handleMacroExecutionError');
    this.realTimeEventService.on('SavedReplyCreated', this, '_refreshSavedReplies');
    this.realTimeEventService.on('SavedReplyDeleted', this, '_refreshSavedReplies');
    this.realTimeEventService.on('SavedReplyUpdated', this, '_refreshSavedReplies');
  }

  _handleMacroExecutionError(event) {
    let partsToRemove = this.store
      .peekAll('conversation-part')
      .filter((part) => (event.failed_action_uuids || []).includes(part.client_assigned_uuid));
    partsToRemove.forEach((part) => {
      part.conversation.syncedPartList.removePart(part);
    });
    this.notificationsService.notifyErrorWithButton(
      "We couldn't apply all the actions in your macro",
      {
        label: 'View conversation',
        action: () => {
          this.router.transitionTo(
            this.appService.app.inboxConversationsConversationRoute,
            event.conversation_id,
          );
        },
      },
    );
  }

  async _refreshSavedReplies() {
    await this.appService.app.refreshSavedReplies();
  }
}
