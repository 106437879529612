/* RESPONSIBLE TEAM: team-frontend-tech */
export function ensureLocaleFallback(locale, fallback, baseLocale) {
  if (typeof locale === 'string') {
    locale = [locale];
  }

  let localesToRemoveFromFallbackArray = new Set(locale);
  fallback = fallback.filter((x) => !localesToRemoveFromFallbackArray.has(x));

  locale = [...locale, ...fallback];

  if (!locale.includes(baseLocale)) {
    locale = [...locale, baseLocale];
  }

  return locale;
}
