/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    step: { key: 'step_id' },
    attributeIdentifier: { embedded: 'always' },
    questionType: { embedded: 'always' },
    data: { embedded: 'always' },
  },
});
