/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import { or, equal, reads } from '@ember/object/computed';
import { findByProperty, flatMap } from '@intercom/pulse/lib/computed-properties';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import { task } from 'ember-concurrency';

const MAX_HC_SITES = 100;

export default Service.extend({
  init() {
    this._super(...arguments);
    this.maybeFetchSite();
  },
  showCollectionReorderWarning: true,
  showSectionReorderWarning: true,
  permissionsService: service(),
  store: service(),
  router: service(),
  notificationsService: service(),
  intl: service(),
  isSingleLanguage: equal('site.selectedLocales.length', 1),
  isAllSingleLanguage: equal('allUniqueLocalesOrdered.length', 1),
  currentlySelectedLocaleId: or('_queryParamLocale', 'site.locale'),
  currentlySelectedLocale: findByProperty(
    'site.selectedLocales',
    'localeId',
    'currentlySelectedLocaleId',
  ),
  fetchingSite: true,

  allSites: computed(function () {
    return this.store.peekAll('help-center-site');
  }),
  allLiveSites: computed('sites', 'sites.@each.websiteTurnedOn', function () {
    if (!this.sites) {
      return [];
    }
    return this.sites.filter((site) => site.websiteTurnedOn === true);
  }),
  defaultSite: computed('sites', function () {
    return this.sites.find((site) => site.isDefault);
  }),
  allUniqueDefaultLocales: computed('allSites', function () {
    return this.allSites.map((site) => site.defaultLocale).uniqBy('localeId');
  }),
  allUniqueNonDefaultLocales: computed('allSites', 'allUniqueDefaultLocales', function () {
    let uniqueIds = this.allUniqueDefaultLocales.mapBy('localeId');
    return this.allSites
      .map((site) => site.selectedLocales)
      .reduce((acc, val) => acc.concat(val))
      .filter((locale) => !uniqueIds.includes(locale.localeId))
      .uniqBy('localeId');
  }),
  allUniqueLocalesOrdered: computed(
    'allUniqueDefaultLocales',
    'allUniqueNonDefaultLocales',
    function () {
      return this.allUniqueDefaultLocales.concat(this.allUniqueNonDefaultLocales);
    },
  ),
  supportedLocalesForAllSites: flatMap('allSites', 'supportedLocales'),
  allSupportedLocales: computed('supportedLocalesForAllSites', function () {
    return this.supportedLocalesForAllSites.uniqBy('localeId');
  }),
  currentlySelectedLocaleInAll: findByProperty(
    'allUniqueLocalesOrdered',
    'localeId',
    'currentlySelectedLocaleId',
  ),

  _queryParamLocale: computed(
    '_articlesController.selectedLocaleId',
    'allSupportedLocales',
    function () {
      let selectedLocaleId = this._articlesController.get('selectedLocaleId');
      let isSupportedLocale = this.allSupportedLocales.any(
        (locale) => locale.localeId === selectedLocaleId,
      );
      if (isSupportedLocale) {
        return selectedLocaleId;
      }
    },
  ),

  _articlesController: computed(function () {
    return getOwner(this).lookup('controller:apps/app/articles');
  }),

  getSite: reads('site'),

  async maybeFetchSite(id, updateSelectedSite = true) {
    let sites;
    if (!this.sites) {
      this.set('fetchingSite', true);
      sites = await this.store.findAll('help-center-site');
      this.set('sites', sites);
    } else {
      sites = this.sites;
    }
    if (updateSelectedSite) {
      let site = id ? sites.find((site) => site.id === id) : sites[0];
      this.set('site', site || sites.firstObject);
    }
    this.set('fetchingSite', false);
  },

  async forceFetchSites() {
    return await this.store.findAll('help-center-site', { reload: true });
  },

  async forceFetchSite(id) {
    this.set('fetchingSite', true);
    let sites = await this.forceFetchSites();
    this.set('sites', sites);
    this.set('fetchingSite', false);
    let site = id ? sites.find((site) => site.id === id) : sites[0];
    this.set('site', site || sites.firstObject);
  },

  updateLocale(locale) {
    this._articlesController.set('selectedLocaleId', locale);
  },

  resetLocale(locale) {
    this._articlesController.set('selectedLocaleId', null);
  },

  getLocaleNameFromLocaleCode(localeCode) {
    let localeName = this.allSupportedLocales.find(
      (locale) => locale.localeId === localeCode,
    )?.name;
    return localeName || this.intl.t('articles.show.stats.locale-filter.missing-locale');
  },

  get savedViewsScope() {
    return Scope.ARTICLES;
  },

  get canAddSite() {
    return this.sites?.length < MAX_HC_SITES;
  },

  get maxNumberOfSites() {
    return MAX_HC_SITES;
  },

  createHelpCenter: task(function* (options) {
    let helpCenterSite = this.store.createRecord('help-center-site', {
      identifier: options.identifier,
      name: options.name,
      supportedLocales: [
        {
          title: options.name,
        },
      ],
      creatingAdminId: options.creatingAdminId,
    });
    helpCenterSite.isHelpCenterSectionOpen = true;

    try {
      yield helpCenterSite.save();
    } catch (err) {
      helpCenterSite.deleteRecord();
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      if (err.jqXHR.responseJSON.message.includes('You cannot create more than')) {
        this.notificationsService.notifyError(
          this.intl.t('articles.shared.create-modal.error-too-many-help-centers'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('articles.shared.create-modal.error-creating-help-center'),
        );
      }
      return null;
    }

    return helpCenterSite;
  }),

  deleteHelpCenter: task(function* (helpCenterSite) {
    let deletingCurrentSite = helpCenterSite.id === this.site.id;
    let result;
    try {
      result = yield helpCenterSite.destroyRecord();
      helpCenterSite.unloadRecord();
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.redesign.general.deletion.error'),
      );
      return;
    }
    this.notificationsService.notifyConfirmation(
      this.intl.t('articles.settings.redesign.general.deletion.success'),
    );

    if (deletingCurrentSite) {
      yield this.forceFetchSite();
      this.site.set('isHelpCenterSectionOpen', true);
      this.router.transitionTo('apps.app.articles.site.settings.setting', this.site.id);
    }
    return result;
  }),
});
