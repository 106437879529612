/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';

const WorkflowHasValidActionsByUniqueness = BaseValidator.extend({
  validate(_value, _options, model) {
    let hasMoreActionsThanAllowed = false;
    let currentWorkflowActionTypes = model.actions.map((workflowAction) => workflowAction.type);
    let restrictedActions = [
      'assign-conversation',
      'change-conversation-priority',
      'apply-conversation-sla',
    ];

    restrictedActions.map((restrictedAction) => {
      let countRestrictedActions = currentWorkflowActionTypes.filter((actionType) => {
        let checkType = actionType;
        // assign-conversation and assign-conversation-to-owner cannot both be present
        if (checkType === 'assign-conversation-to-owner') {
          checkType = 'assign-conversation';
        }
        return checkType === restrictedAction.value;
      }).length;
      if (countRestrictedActions > 1) {
        hasMoreActionsThanAllowed = true;
      }
    });

    return !hasMoreActionsThanAllowed;
  },
});

WorkflowHasValidActionsByUniqueness.reopenClass({
  getDependentsFor() {
    return ['model.actions.@each.actionType'];
  },
});

export default WorkflowHasValidActionsByUniqueness;
