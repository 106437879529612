/* RESPONSIBLE TEAM: team-messenger */
import Service, { inject as service } from '@ember/service';

export default class OfficeHoursLabelService extends Service {
  @service intl;

  translationKey = 'app.models.data.time-periods';

  getTranslatedLabelWithTime(untraslatedLabel) {
    let period = untraslatedLabel.period;
    let time = untraslatedLabel.time;
    let translatedPeriodLabel = this._translatePeriod(period);

    return `${translatedPeriodLabel} ${time}`;
  }

  getTranslatedLabel(untranslatedPeriod) {
    return this._translatePeriod(untranslatedPeriod);
  }

  _translatePeriod(period) {
    let dash = ' - ';
    let comma = ', ';

    if (period.includes(dash)) {
      return this._mapMultipleDays(period, dash);
    } else if (period.includes(comma)) {
      return this._mapMultipleDays(period, comma);
    } else {
      return this.intl.t(`${this.translationKey}.${period.toLowerCase()}`);
    }
  }

  _mapMultipleDays(period, splitChar) {
    return period
      .split(splitChar)
      .map((el) => this.intl.t(`${this.translationKey}.${el.toLowerCase()}`))
      .join(splitChar);
  }
}
