/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import Service from '@ember/service';
import EmberObject from '@ember/object';
import { bind } from '@ember/runloop';
import $ from 'jquery';
import platform from 'embercom/lib/browser-platform';
import { normalizeEvent } from 'ember-jquery-legacy';

const Shortcut = EmberObject.extend({
  stateCheck: null,
  group: null,
  context: null,
  key: null,
  textBox: false,
  modalActive: true,
  method: null,
  shiftKey: false,
  commandKey: false,
  altKey: false,
});

export default Service.extend({
  spoolInterval: 300,
  spooledKeyPresses: null,
  state: null,
  shortcuts: null,

  keyMap: {
    13: '↵',
    27: 'escape',
    32: 'space',
    38: '↑',
    40: '↓',
    65: 'a',
    66: 'b',
    69: 'e',
    71: 'g',
    73: 'i',
    74: 'j',
    75: 'k',
    77: 'm',
    78: 'n',
    79: 'o',
    80: 'p',
    82: 'r',
    83: 's',
    84: 't',
    85: 'u',
    88: 'x',
    89: 'y',
    90: 'z',
    191: '/',
  },

  init() {
    this._super(...arguments);
    this.set('spooledKeyPresses', []);
    this.set('shortcuts', []);
    this.set('state', {});
    $(document).keydown(bind(this, this.onKeyDown)).keyup(bind(this, this.onKeyUp));
  },

  register(group, context, shortcutOptions) {
    shortcutOptions.forEach((shortcutOption) => {
      shortcutOption.group = group;
      shortcutOption.context = context;
      this.shortcuts.pushObject(Shortcut.create(shortcutOption));
    });
  },

  unregister(group) {
    this.set('shortcuts', this.shortcuts.rejectBy('group', group));
  },

  setState(state, value) {
    this.set(`state.${state}`, value);
  },

  willDestroy() {
    this._super(...arguments);
    $(document).off('keydown').off('keyup');
  },

  triggerShortcuts(keyPress) {
    let nativeKeyPressEvent = normalizeEvent(keyPress);
    this.shortcuts.forEach((shortcut) => {
      if (this.shouldTriggerShortcut(shortcut, keyPress)) {
        nativeKeyPressEvent.preventDefault();
        shortcut.get('method').apply(shortcut.get('context'));
      }
    });
  },

  shouldTriggerShortcut(shortcut, keyPress) {
    return (
      (shortcut.key === keyPress.key || shortcut.key === keyPress.keys) &&
      this.hasCorrectModifierKeys(shortcut, keyPress) &&
      this.isStateValid(shortcut) &&
      this.textNotBlocking(shortcut, keyPress) &&
      this.modalNotBlocking(shortcut, keyPress)
    );
  },

  hasCorrectModifierKeys(shortcut, keyPress) {
    return (
      shortcut.shiftKey === keyPress.shiftKey &&
      shortcut.commandKey === keyPress.commandKey &&
      shortcut.altKey === keyPress.altKey
    );
  },

  isStateValid(shortcut, keyPress) {
    return !shortcut.get('stateCheck') || this.get(`state.${shortcut.get('stateCheck')}`);
  },

  modalNotBlocking(shortcut, keyPress) {
    return !keyPress.isModalActive || shortcut.get('modalActive');
  },

  textNotBlocking(shortcut, keyPress) {
    return !keyPress.isInputFocused || shortcut.get('textBox');
  },

  onKeyDown(e) {
    this.onKey(e, 'keyDown');
  },
  onKeyUp(e) {
    this.onKey(e, 'keyUp');
  },

  onKey(e, keyEventType) {
    let key = this.humanizeKeyCode(e);
    if (key) {
      this.spoolKeyPresses(key, keyEventType);
      let keyPress = this.translateRawKeyDown(key, e);
      if (keyPress) {
        //right now this only supports keyDown shortcuts, should be refactored if
        //others are added.
        if (keyEventType === 'keyDown') {
          this.triggerShortcuts(keyPress);
        }
      }
      return keyPress;
    }
  },
  humanizeKeyCode(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    return this.keyMap[keyCode];
  },

  translateRawKeyDown(humanizedKey, e) {
    let $focused = $(':focus');
    let isInputFocused = this.isInputFocused($focused);
    let isModalActive = this.isModalActive();

    return {
      key: humanizedKey,
      keys: this.spooledKeyPresses.map((e) => e.key).join('|'),
      isModalActive,
      focusedElement: $focused,
      isInputFocused,
      isNormalMode: !(isInputFocused || isModalActive),
      shiftKey: e.shiftKey || false,
      altKey: e.altKey || false,
      commandKey: (platform.isMac ? e.metaKey : e.ctrlKey) || false,
      originalEvent: e,
    };
  },

  isInputFocused($focused) {
    return $focused.is('input, textarea, [contenteditable=true]');
  },

  spoolKeyPresses(key, keyEventType) {
    let now = Date.now();
    this.set(
      'spooledKeyPresses',
      this.spooledKeyPresses.filter((element) => element.at > now - this.spoolInterval),
    );

    if (keyEventType === 'keyDown') {
      this.spooledKeyPresses.push({ key, at: now });
    }
  },
  isModalActive() {
    return $('.modal__overlay').length > 0 || $('.ds-new__modal__wrapper').length > 0;
  },
});
