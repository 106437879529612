/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import extractResponseError from 'embercom/lib/extract-response-error';
import type IntlService from 'embercom/services/intl';

export default class SenderEmailVerificationService extends Service {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  async resendVerificationEmail(params: {
    senderEmailAddressSettingsId: string;
    appId: string;
    adminId: string;
  }) {
    try {
      await ajax({
        url: `/ember/sender_email_address_settings/${params.senderEmailAddressSettingsId}/resend_verification_email`,
        type: 'POST',
        data: JSON.stringify({
          app_id: params.appId,
          admin_id: params.adminId,
        }),
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.email.addresses.sidebar.resend-verification-sent'),
      );
    } catch (error) {
      this.notificationsService.notifyError(this.getErrorMessage(error));
    }
  }

  private getErrorMessage(error: any) {
    let defaultMessage = this.intl.t(
      'new-settings.channels.email.addresses.sidebar.resend-verification-error',
    );
    try {
      let extractedError = extractResponseError(error);
      return extractedError?.message ?? defaultMessage;
    } catch {
      return defaultMessage;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'sender-email-verification-service': SenderEmailVerificationService;
  }
}
