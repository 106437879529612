/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { bind, later } from '@ember/runloop';
import { A } from '@ember/array';
import EmberObject from '@ember/object';
import ajax from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import Metrics from 'embercom/models/metrics';
import NexusClient from '@intercom/nexus-client';

const ERROR_LOGGER = {
  log() {},
  debug() {},
  warning() {},
  error(e) {
    let emberApplicationInstance = window.EmberApplicationInstance;
    if (emberApplicationInstance && emberApplicationInstance.lookup('service:log-service').log) {
      window.EmberApplicationInstance.lookup('service:log-service').log(e);
    }
  },
  info() {},
  trage() {},
};

const METRIC_SINK = {
  increment: (key) => {
    Metrics.capture({ increment: [key] });
  },
  count: () => {},
  timing: (key, value) => {
    let metrics = {};
    metrics[`ember_${key}`] = parseInt(value, 10);
    Metrics.capture(metrics);
  },
};

export const CONNECTION_STATUS = {
  pending: 'pending',
  connected: 'connected',
  disconnected: 'disconnected',
  error: 'error',
};

/* NexusClient */
export default EmberObject.extend({
  queuedListeners: null,
  queuedTopics: null,
  metricSink: null,
  logger: null,
  status: null,

  init(...args) {
    this._super(...args);

    this._resetQueues();
    this.logger = this.logger || ERROR_LOGGER;
    this.metricSink = this.metricSink || METRIC_SINK;
  },

  initForApp(appId, options = {}) {
    this.appId = appId;
    this.options = options;
    this._statusChanged(CONNECTION_STATUS.pending);

    return ajax({ url: `/ember/nexus_config/${appId}`, type: 'GET' }).then((response) => {
      this.channelName = response.channel;
      this.config = response;
      if (this.channelName !== undefined) {
        this._nexus = this._initClient(response);

        this.queuedListeners.forEach((item) => {
          this._nexus.addListener(item.name, item.listener);
        });

        this._nexus.subscribeTopics(this.queuedTopics);

        this._resetQueues();
      }
    });
  },

  _initClient(response) {
    return new NexusClient(
      response.endpoints,
      this.logger,
      this.metricSink,
      bind(this, this._onClientConnect),
      bind(this, this._onClientDisconnect),
      bind(this, this._onChannelExpired),
      bind(this, this._onFailureToEstablishConnection),
      {
        LONG_POLLING_ENABLED: this.options.longPollingEnabled,
        PRESENCE_ENABLED: false,
      },
      'admin',
    );
  },

  _resetQueues() {
    this.queuedListeners = A();
    this.queuedTopics = A();
  },

  subscribeTopics(topics) {
    if (this._nexus !== undefined) {
      this._nexus.subscribeTopics(topics);
    } else {
      topics.forEach((topic) => {
        this.queuedTopics.push(topic);
      });
    }
  },

  unsubscribeTopics(topics) {
    if (this._nexus !== undefined) {
      this._nexus.unsubscribeTopics(topics);
    }
    topics.forEach((topic) => {
      this.queuedTopics = this.queuedTopics.without(topic);
    });
  },

  addListener(name, listener) {
    if (this._nexus === undefined) {
      this.queuedListeners.push({ name, listener });
    } else {
      this._nexus.addListener(name, listener);
    }
  },

  removeListener(name, listener) {
    // NexusClient doesn't currently implement removeListener
    if (this._nexus !== undefined) {
      this._nexus.connections.forEach((connection) => {
        connection.removeListener(name, listener);
      });
    }

    this.queuedListeners = this.queuedListeners.reject(
      (queued) => queued.name === name && queued.listener === listener,
    );
  },

  unsubscribe() {
    if (this.channelName !== undefined && this._nexus !== undefined) {
      this._nexus.shutdown();
      this._nexus = undefined;
      this._resetQueues();
    }
  },

  sendUserEvent(userId, eventName, eventData) {
    if (this.channelName === undefined || !this._nexus) {
      // Nexus connection is not set up
      return;
    }
    this._nexus.sendUserEvent(userId, eventName, eventData);
  },

  throttleSendUserEvent(userId, eventName, eventData) {
    if (this.channelName === undefined || !this._nexus) {
      // Nexus connection is not set up
      return;
    }
    this._nexus.throttleSendUserEvent(userId, eventName, eventData);
  },

  throttleSendEvent(eventName, eventData) {
    if (this.channelName === undefined || !this._nexus) {
      // Nexus connection is not set up
      return;
    }
    this._nexus.throttleSendEvent(eventName, eventData);
  },

  _onClientConnect() {
    this._statusChanged(CONNECTION_STATUS.connected);
  },
  _onClientDisconnect() {
    this._statusChanged(CONNECTION_STATUS.disconnected);
  },
  _onFailureToEstablishConnection() {
    this._statusChanged(CONNECTION_STATUS.error);
  },
  _onChannelExpired() {
    if (ENV.environment !== 'test') {
      later(() => {
        this.initForApp(this.appId, this.options);
      }, ENV.APP._5000MS);
    } else {
      this.initForApp(this.appId, this.options);
    }
  },
  _statusChanged(status) {
    if (this.status !== status) {
      this.options.onStatusChanged?.(this.status, status);
      this.status = status;
    }
  },
});
