/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { defaultBlock } from 'embercom/models/data/outbound/constants';

const BlocksNotDefault = BaseValidator.extend({
  validate(blocks, options, model) {
    if (blocks.length === 1 && blocks.get('firstObject.text') === defaultBlock.text) {
      return options.message || 'Finish writing your message';
    }
    return true;
  },
});

BlocksNotDefault.reopenClass({
  getDependentsFor() {
    return ['model.blocks.firstObject.hasContent'];
  },
});

export default BlocksNotDefault;
