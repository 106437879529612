/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { later, cancel } from '@ember/runloop';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import { readOnly, filterBy, setDiff, filter, equal, bool } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import InboxRealtimeAdapter from 'embercom/lib/inbox/realtime-adapter';
import FaviconCounter from 'embercom/lib/favicon-counter';
import { getOwner } from '@ember/application';
import Metrics from 'embercom/models/metrics';

export default Service.extend({
  PAGE_SIZE: 15,

  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  store: service(),
  realtimeService: service('realTimeEventService'),

  isActive: false,

  inbox: null,
  currentConversation: null,
  teamInboxes: filterBy('inboxes', 'isTeamInbox'),

  inboxes: A(),

  inboxViews: A(),

  inbox2OpenSidebarSections: A(['conversation-details']),

  meInbox: computed('inboxes.[]', function () {
    return this.inboxes.find((inbox) => {
      return inbox.get('admin_id') === this.get('admin.id');
    });
  }),
  operatorInbox: computed('inboxes.@each.admin_id', 'app.operatorBot.id', function () {
    return this.inboxes.find((inbox) => {
      return inbox.admin_id === this.app.operatorBot?.id;
    });
  }),
  unassignedInbox: computed('inboxes.[]', function () {
    return this.inboxes.findBy('isUnassignedInbox');
  }),
  mentionsInbox: computed('inboxes.[]', function () {
    return this.inboxes.findBy('isMentionsInbox');
  }),
  allInbox: computed('inboxes.[]', function () {
    return this.inboxes.findBy('isAllInbox');
  }),

  displayableOtherInboxes: computed('inboxes.[]', 'admin.id', function () {
    return this.inboxes
      .rejectBy('isPredefinedInbox')
      .rejectBy('isBot')
      .rejectBy('admin.isBot')
      .rejectBy('admin_id', this.get('admin.id'))
      .rejectBy('isInboxView');
  }),

  displayableOtherInboxesWithOperator: computed(
    'operatorInbox',
    'displayableOtherInboxes.[]',
    function () {
      return [this.operatorInbox].concat(this.displayableOtherInboxes).compact();
    },
  ),

  defaultInboxes: computed('meInbox', 'mentionsInbox', 'unassignedInbox', 'allInbox', function () {
    return [this.meInbox, this.mentionsInbox, this.unassignedInbox, this.allInbox];
  }),
  extraInboxes: computed('visibleSidebarItems', 'inboxesWithMeMembership', function () {
    return [...this.visibleSidebarItems, ...this.inboxesWithMeMembership].uniq();
  }),

  visibleSidebarItems: computed(
    'inboxes.[]',
    'admin.visible_inbox_sidebar_item_ids.[]',
    function () {
      if (!this.get('admin.visible_inbox_sidebar_item_ids')) {
        return [];
      }
      let inboxes = this.inboxes;
      let uniqeSidebarIds = Array.from(new Set(this.get('admin.visible_inbox_sidebar_item_ids')));
      return uniqeSidebarIds
        .map((id) => {
          return inboxes.findBy('id', id);
        })
        .compact();
    },
  ),

  pinnedInboxes: computed('inboxes.[]', 'admin.visible_inbox_sidebar_item_ids.[]', function () {
    let inboxes = this.inboxes;
    let visibleAssigneeIds = this.get('admin.visible_inbox_sidebar_item_ids');
    return inboxes.filter((inbox) => visibleAssigneeIds.includes(inbox.get('id')));
  }),
  pinnedInboxesWithoutMembership: setDiff('pinnedInboxes', 'inboxesWithMeMembership'),

  nonMeMembershipInboxes: setDiff('displayableOtherInboxes', 'inboxesWithMeMembership'),

  sortedNonMeMembershipInboxes: computed('nonMeMembershipInboxes.[]', function () {
    return this.nonMeMembershipInboxes.sort(this._sortInboxes);
  }),

  sortedNonMeMembershipInboxesWithOperator: computed(
    'operatorInbox',
    'sortedNonMeMembershipInboxes.[]',
    function () {
      return [this.operatorInbox].concat(this.sortedNonMeMembershipInboxes).compact();
    },
  ),

  inboxesWithMeMembership: filter('teamInboxes', function (inbox) {
    return inbox.adminBelongsToTeam(this.admin);
  }),

  inboxViewInboxes: filter('inboxes', function (inbox) {
    return inbox.get('isInboxView');
  }),

  teamAndViewInboxes: filter('inboxes', function (inbox) {
    return inbox.get('isInboxView') || inbox.get('isTeamInbox');
  }),

  visibleInboxViews: computed(
    'inboxViewInboxes.[]',
    'admin.visible_inbox_sidebar_item_ids.[]',
    function () {
      let visibleInboxViewIds = this.admin.visible_inbox_sidebar_item_ids || [];
      let views = this.inboxViewInboxes.filter((view) => visibleInboxViewIds.includes(view.id));
      return views.sortBy('name');
    },
  ),
  hiddenInboxViews: setDiff('inboxViewInboxes', 'visibleInboxViews'),
  sortedHiddenInboxViews: computed('hiddenInboxViews', function () {
    return this.hiddenInboxViews.sort(this._sortInboxes);
  }),

  defaultDropdownInboxes: computed(
    'meInbox',
    'mentionsInbox',
    'unassignedInbox',
    'allInbox',
    'inboxesWithMeMembership',
    'pinnedInboxesWithoutMembership',
    'visibleSidebarItems',
    function () {
      let inboxes = [this.meInbox, this.mentionsInbox, this.unassignedInbox, this.allInbox];
      inboxes.pushObjects(this.visibleSidebarItems);
      inboxes.pushObjects(this.inboxesWithMeMembership);
      inboxes.pushObjects(this.pinnedInboxesWithoutMembership);
      return inboxes.uniq();
    },
  ),

  isSearchInbox: bool('inbox.isSearch'),
  isMentionsInbox: equal('inbox.assignee_identifier', 'mentions'),

  faviconCounter: null,

  init() {
    this._super(...arguments);
    this.realtimeAdapter = InboxRealtimeAdapter.create({
      inboxService: this,
      realtimeService: this.realtimeService,
      store: this.store,
    });
    this.faviconCounter = new FaviconCounter();
    this.loadInboxes();
    this.backgroundInboxList = getOwner(this).lookup('customClass:inbox/backgroundInboxList');
  },

  activate() {
    if (this.get('app.inboxIsActive')) {
      this.realtimeAdapter.enable();
      this._startSleepDetection();
      this.updateFavicon();
      this.set('isActive', true);
    }
  },

  deactivate() {
    let currentInbox = this.inbox;
    if (this.app.canUseBackgroundInboxes) {
      this.backgroundInboxList.deactivate();
    } else if (currentInbox) {
      currentInbox.clearConversations();
      currentInbox.disableRealtime();
    }
    this.realtimeAdapter.disable();
    this._disableSleepDetection();
    this._clearFaviconCounter();
    this.clearCurrentConversation();
    this.set('inbox', null);
    this.set('isActive', false);
    this.scheduleInboxCountersPoll.cancelAll();
  },

  setInbox(inbox) {
    let currentInbox = this.inbox;
    if (inbox !== currentInbox) {
      if (this.app.canUseBackgroundInboxes) {
        this.clearCurrentConversation();
        this.set('inbox', inbox);
        this.updateFavicon();
        this.backgroundInboxList.changeInbox(inbox, currentInbox);
        this.autoTransitionIfNoCurrentConversation();
      } else {
        if (currentInbox) {
          currentInbox.disableRealtime();
          currentInbox.clearConversations();
        }
        this.clearCurrentConversation();
        this.set('inbox', inbox);
        this.updateFavicon();
        this.inbox.reload();
        inbox.enableRealtime();
      }
    }
  },

  setCurrentConversation(conversation) {
    let inbox = this.inbox;
    this.currentConversation = conversation;
    if (inbox) {
      inbox.clearTransitionedConversationsExcluding(conversation);
    }
  },

  clearCurrentConversation() {
    this.currentConversation = null;
  },

  // Hook for the ConversationsService to inform this service that a change has
  // occured on a conversation and allow this service to react appropriately
  partAddedToConversationLocally(conversation) {
    if (!this.inbox) {
      return;
    }
    let removeWhenOutOfContextEvenIfCurrent =
      conversation.get('lastPart.isCloser') || conversation.get('lastPart.isSnoozer');
    if (this.app.canUseBackgroundInboxes) {
      this.backgroundInboxList.forEach((inbox) => {
        inbox.conversationWasUpdated(conversation, removeWhenOutOfContextEvenIfCurrent);
      });
      if (!this.inbox.canBeBackgrounded) {
        this.inbox.conversationWasUpdated(conversation, removeWhenOutOfContextEvenIfCurrent);
      }
    } else {
      this.inbox.conversationWasUpdated(conversation, removeWhenOutOfContextEvenIfCurrent);
    }
  },

  // Hook for the ConversationsService to inform this service that a part has been
  // removed. This can happen in the case of a bulk action failure
  partRemovedFromConversation(conversation) {
    if (!this.inbox) {
      return;
    }
    if (this.app.canUseBackgroundInboxes) {
      this.backgroundInboxList.forEach((inbox) => {
        inbox.conversationWasUpdated(conversation);
      });
      if (!this.inbox.canBeBackgrounded) {
        this.inbox.conversationWasUpdated(conversation);
      }
    } else {
      this.inbox.conversationWasUpdated(conversation);
    }
  },

  transitionToNextConversation() {
    this.transitionToConversation(
      this.inbox.conversationList.nextUntransitioned(this.currentConversation),
    );
  },

  transitionToPreviousConversation() {
    this.transitionToConversation(
      this.inbox.conversationList.previousUntransitioned(this.currentConversation),
    );
  },

  transitionToRoot() {
    this.clearCurrentConversation();
    this.transitionTo(this.get('app.inboxConversationsRoute'), this.inbox, {
      queryParams: this.inbox.queryParams,
    });
  },

  transitionToUnassigned() {
    this.clearCurrentConversation();
    this.transitionTo(this.get('app.inboxConversationsRoute'), this.unassignedInbox);
  },

  transitionToConversation(conversation) {
    if (conversation) {
      this.transitionTo(this.get('app.inboxConversationsConversationRoute'), conversation, {
        queryParams: this.inbox.queryParams,
      });
    }
  },

  updateInboxCounters(inboxes, treatMissingAssigneesAsZero, source) {
    let lookup = {};

    inboxes.forEach((inboxData) => {
      // convert from wire format
      if (Array.isArray(inboxData)) {
        inboxData = { id: inboxData[0], open_count: inboxData[1] };
      }

      lookup[inboxData.id] = inboxData.open_count;
      // nexus cannot give us good values for mentions since it is broadcast
      if (inboxData.id === 'mentions') {
        return;
      }
      let inboxModel = this.store.peekRecord('inbox', inboxData.id);

      if (inboxModel) {
        if (source === 'es' && !inboxModel.isView) {
          return;
        }

        if (inboxData.open_count !== inboxModel.open_count) {
          inboxModel.setProperties(inboxData);
        }
        if (inboxModel.get('identifier') === 'all') {
          this.set('app.open_message_thread_count', inboxModel.get('open_count'));
        }
        if (inboxModel.get('admin.id') === this.get('admin.id')) {
          this.set('app.admin_open_message_thread_count', inboxModel.get('open_count'));
        }
      }
    });

    if (treatMissingAssigneesAsZero) {
      let allInboxes = this.store.peekAll('inbox');

      allInboxes.forEach((inboxModel) => {
        if (
          lookup[inboxModel.id] ||
          inboxModel.isInboxView ||
          inboxModel.get('identifier') === 'spam'
        ) {
          return;
        } else if (inboxModel.open_count !== 0) {
          inboxModel.setProperties({ open_count: 0 });
        } else {
          if (inboxModel.get('identifier') === 'all') {
            this.set('app.open_message_thread_count', 0);
          }
          if (inboxModel.get('admin.id') === this.get('admin.id')) {
            this.set('app.admin_open_message_thread_count', 0);
          }
        }
      });
    }

    this.updateFavicon();
  },

  updateFavicon() {
    this.faviconCounter.updateFaviconCounter(this.get('inbox.determinantCount'));
  },

  autoTransitionIfNoCurrentConversation() {
    if (this.currentConversation) {
      return;
    }

    let targetConversation;

    if (this.targetConversationId) {
      targetConversation = this.inbox.findConversationById(this.targetConversationId);
      this.set('targetConversationId', null);
    }

    if (!targetConversation) {
      targetConversation = this.get('inbox.conversationList.sortedItems.firstObject');
    }
    this.transitionToConversation(targetConversation);
  },

  loadInboxes(forceReload = false) {
    let inboxes = this.store.peekAll('inbox');
    if (forceReload || inboxes.get('length') === 0) {
      this.loadInboxViews();
      return this.store.query('inbox', {}).then((result) => {
        this.set('inboxes', result);
      });
    } else {
      this.set('inboxes', inboxes);
    }
  },

  loadInboxViews() {
    let inboxViews = this.store.findAll('inbox-view');
    this.set('inboxViews', inboxViews);
    return inboxViews;
  },

  async loadInboxViewCounters() {
    let countersData = await ajax({
      url: `/ember/inboxes/view_counters?app_id=${this.app.id}`,
      type: 'get',
      contentType: 'application/json',
    });

    countersData.counters.forEach(([id, count]) => {
      let inbox = this.store.peekRecord('inbox', id);
      inbox.open_count = count;
    });
  },

  loadConversationAttributeDescriptors() {
    return this.store.findAll('conversation-attributes/descriptor');
  },

  setInbox2OpenSidebarSections(openSidebarSections) {
    this.set('inbox2OpenSidebarSections', openSidebarSections);
  },

  scheduleInboxCountersPoll: task(function* () {
    if (ENV.environment === 'test') {
      return;
    }
    yield timeout(ENV.APP._1M);
    this.loadInboxes(true);
    this.scheduleInboxCountersPoll.perform();
  }).restartable(),

  _updateInboxCount(count) {
    this.set('inbox.count', count);
  },

  _clearFaviconCounter() {
    this.faviconCounter.resetFaviconCounter();
  },

  _startSleepDetection() {
    if (ENV.environment !== 'production' || this.inbox?.isSearch) {
      return;
    }
    this._lastSleepDetectionTime = new Date().getTime();
    this._sleepDetectionTimer = later(this, this._checkForSleep, ENV.APP._1000MS);
  },

  _disableSleepDetection() {
    cancel(this._sleepDetectionTimer);
  },

  _checkForSleep() {
    let currentTime = new Date().getTime();
    let timeSinceLastRun = currentTime - this._lastSleepDetectionTime;
    if (timeSinceLastRun > 30000) {
      console.info('Sleep detected, reloading');
      this.inbox.sync.perform();
      Metrics.capture({ increment: ['inbox.sleep_detection.reload'] });
    }
    this._startSleepDetection();
  },

  _sortInboxes(a, b) {
    if (a.get('isTeamInbox') && !b.get('isTeamInbox')) {
      return -1;
    }
    if (!a.get('isTeamInbox') && b.get('isTeamInbox')) {
      return 1;
    }
    let aName = a.get('name');
    let bName = b.get('name');
    if (aName > bName) {
      return 1;
    }
    if (aName < bName) {
      return -1;
    }
    return 0;
  },
});
