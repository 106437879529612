/* RESPONSIBLE TEAM: team-customer-data-platform */

import Service from '@ember/service';

class TypeTranslator<K, V> {
  forward: Map<K, V> = new Map<K, V>();
  backward: Map<V, K> = new Map<V, K>();

  constructor(entries: [K, V][]) {
    entries.forEach(([k, v]) => {
      this.forward.set(k, v);
      this.backward.set(v, k);
    });
  }

  getForward(key: K): V | undefined {
    return this.forward.get(key);
  }

  getBackward(key: V): K | undefined {
    return this.backward.get(key);
  }
}

export class IntercomTypeTranslator extends TypeTranslator<string, string> {
  getCrmType = this.getForward;
  getIntercomType = this.getBackward;
}

export default class CrmTypeTranslationService extends Service {
  objectTypeTranslations = new Map<string, IntercomTypeTranslator>([
    [
      'migrate-from-zendesk',
      new IntercomTypeTranslator([
        ['company', 'organization'],
        ['contact', 'customer'],
        ['ticket', 'ticket'],
        ['team', 'group'],
        ['admin', 'agent'],
      ]),
    ],
  ]);

  getTranslator(integrationCode: string) {
    return this.objectTypeTranslations.get(integrationCode);
  }
}

declare module '@ember/service' {
  interface Registry {
    crmTypeTranslationService: CrmTypeTranslationService;
    'data/crm-type-translation-service': CrmTypeTranslationService;
  }
}
