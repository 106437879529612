/* RESPONSIBLE TEAM: team-tickets-1 */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { tracked } from '@glimmer/tracking';
import type CommandKService from 'embercom/services/command-k';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type IntlService from 'embercom/services/intl';
import type Snackbar from 'embercom/services/snackbar';
import { NOTIFCATION_UNDO_CONTENT_COMPONENT } from 'embercom/services/inbox-state';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';

export type ClosableConversation = Exclude<ConversationRecord, LatestConversationSummary>;
type ConversationStateAcc = { [key: number]: ConversationState | TicketCustomState | undefined };
type BulkActionAdditionalParams = {
  id: number;
  linkedCustomerReports: number[] | string;
};
type ActionContext = {
  conversations: ClosableConversation[];
  unresolvedConversations?: ClosableConversation[];
  currentStates?: ConversationStateAcc;
  targetState?: ConversationState | TicketCustomState;
  onSuccess?: CallbackFunction;
  relatedReportsHandled?: boolean;
  relatedReportsClosed?: boolean;
  bulkActionAdditionalParams?: BulkActionAdditionalParams;
};

type CallbackFunction = (context: ActionContext) => void;

export default class ConversationResolveAndCloseService extends Service {
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare commandK: CommandKService;
  @service declare ticketStateService: TicketStateService;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare lbaMetricsService: LbaMetricsService;
  @service declare notificationsService: any;

  @tracked currentContext: ActionContext = { conversations: [] };
  @tracked trackerTicketReportsModalVisible = false;

  startResolveAndCloseProcess(
    conversation: ClosableConversation | ClosableConversation[],
    onSuccess?: CallbackFunction,
    bulkActionAdditionalParams?: BulkActionAdditionalParams,
  ) {
    this.cancelProcess(); // Reset the state
    if (conversation instanceof Array) {
      this.currentContext.conversations = conversation;
    } else {
      this.currentContext.conversations = [conversation];
    }
    this.currentContext.unresolvedConversations = [];
    this.currentContext.onSuccess = onSuccess;
    this.currentContext.bulkActionAdditionalParams = bulkActionAdditionalParams;
    this._preserveCurrentStates();

    if (
      this.currentContext.conversations.every((conversation) =>
        this._isResolvedOrNotATicket(conversation),
      )
    ) {
      this.closeConversations();
      return;
    }

    this.commandK.registerAndShow({
      actionID: 'resolve-and-close-ticket-state',
      context: this._commandKContext,
      onSelect: this._onCmdKOptionSelect.bind(this),
      onCancel: this.cancelProcess.bind(this),
    });
  }

  cancelProcess() {
    this.currentContext = { conversations: [] };
    this.trackerTicketReportsModalVisible = false;
  }

  get isSingleConversationProcess() {
    return this.currentContext.conversations.length === 1;
  }

  async resolveAndClose(targetState: TicketCustomState) {
    if (!this.currentContext.conversations.length) {
      return;
    }

    if (this.isSingleConversationProcess) {
      this._changeTicketState(this._firstConversation, targetState);
    } else {
      this.inboxApi.bulkChangeTicketState(
        this.currentContext.conversations.map((conversation) => conversation.id),
        targetState.id,
        this.currentContext.bulkActionAdditionalParams,
      );
    }
    this.closeConversations();
  }

  async closeConversations() {
    if (
      this.currentContext.conversations.some((conversation) =>
        this.hasRelatedReports(conversation),
      ) &&
      this.currentContext.relatedReportsHandled !== true
    ) {
      this.trackerTicketReportsModalVisible = true;
      return;
    }

    if (this.isSingleConversationProcess) {
      this._closeSingleConversation();
      return;
    }

    try {
      this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
        LbaTriggerEvent.BULK_CLOSE,
      );
      this.currentContext.unresolvedConversations = await this.inboxState.closeConversations(
        this.currentContext.conversations.map((conversation) => conversation.id),
        false,
        this.currentContext.bulkActionAdditionalParams,
      );

      if (this.successfullyClosedConversations.length > 0) {
        this._notifyWithUndo(
          this.intl.t('inbox.notifications.bulk-closed', {
            closedConversationsCount: this.successfullyClosedConversations.length,
          }),
          () => this._rollbackClosingProcess(this.currentContext),
        );

        if (this.currentContext.onSuccess) {
          this.currentContext.onSuccess(this.currentContext);
        }
      }
    } catch {
      this.notificationsService.notifyError(this.intl.t('inbox.bulk-edit.errors.close'));
    }
  }

  async closeWithoutUpdatingRelatedReports() {
    this._finishRelatedReportsHandling();

    this.closeConversations();
  }

  async closeRelatedReportsAndConversation() {
    this._finishRelatedReportsHandling();
    this.currentContext.relatedReportsClosed = true;

    let trackerTickets = this.currentContext.conversations.filter(
      (conversation) => conversation.isTrackerTicket,
    );
    let linkedCustomerReportIds = trackerTickets.flatMap(
      (conversation) => conversation.linkedCustomerReportIds,
    );

    this.inboxState.closeLinkedConversations(linkedCustomerReportIds);
    this.closeConversations();
  }

  hasRelatedReports(conversation: ClosableConversation) {
    if (!conversation.isTrackerTicket) {
      return false;
    }

    return conversation.linkedCustomerReportIds.length > 0;
  }

  private async _closeSingleConversation() {
    let isClosed = await this.inboxState.closeConversationAndCheckAttributes(
      this._firstConversation,
    );
    if (!isClosed) {
      return;
    }

    let closeCopy = this._firstConversation.ticketType
      ? this.intl.t('inbox.notifications.ticket-closed')
      : this.intl.t('inbox.notifications.conversation-closed');
    this._notifyWithUndo(closeCopy, () => this._rollbackClosingProcess(this.currentContext));

    if (this.currentContext.onSuccess) {
      this.currentContext.onSuccess(this.currentContext);
    }
  }

  private get _firstConversation(): ClosableConversation {
    return this.currentContext.conversations[0];
  }

  private get _commandKContext() {
    if (this.isSingleConversationProcess) {
      return {
        ticketType: this._firstConversation.ticketType,
        currentTicketState: this.currentContext.currentStates![this._firstConversation.id],
        conversation: this._firstConversation,
      };
    } else {
      return {
        isMultiple: true,
        conversations: this.currentContext.conversations,
        ticketTypes: this.currentContext.conversations.map(
          (conversation) => conversation.ticketType,
        ),
      };
    }
  }

  private _onCmdKOptionSelect(targetState: string | number) {
    if (targetState === ConversationState.Closed) {
      this.currentContext.targetState = targetState;
      this.closeConversations();
    } else {
      this.currentContext.targetState = this.ticketStateService.getTicketCustomStateById(
        targetState as number,
      );
      this.resolveAndClose(this.currentContext.targetState);
    }
  }

  private _finishRelatedReportsHandling() {
    this.currentContext.relatedReportsHandled = true;
    this.trackerTicketReportsModalVisible = false;
  }

  private _changeTicketState(conversation: ClosableConversation, targetState: TicketCustomState) {
    this.inboxState.changeTicketState(conversation, targetState);
  }

  private _rollbackSingleConversation(
    conversation: ClosableConversation,
    previousState?: ConversationState | TicketCustomState,
  ) {
    if (previousState instanceof TicketCustomState) {
      this._changeTicketState(conversation, previousState);
    }
    this.inboxState.openConversation(conversation);

    if (this.currentContext.relatedReportsClosed && this.hasRelatedReports(conversation)) {
      this.inboxState.openConversations(conversation.linkedCustomerReportIds);
    }
  }

  private _rollbackClosingProcess(context: ActionContext) {
    if (!context.conversations) {
      return;
    }

    context.conversations.forEach((conversation) => {
      this._rollbackSingleConversation(conversation, context.currentStates?.[conversation.id]);
    });
  }

  private _notifyWithUndo(text: string, onUndo: () => void) {
    this.snackbar.notify(text, {
      buttonLabel: this.intl.t('inbox.notifications.undo'),
      onButtonClick: (notification) => {
        this.snackbar.clearNotification(notification);
        onUndo();
      },
      contentComponent: NOTIFCATION_UNDO_CONTENT_COMPONENT,
      clearable: true,
    });
  }

  private _isResolvedOrNotATicket(conversation: ClosableConversation) {
    if (conversation.isTicket) {
      return conversation.ticketState === TicketSystemState.Resolved;
    } else {
      return true;
    }
  }

  private _conversationCurrentState(conversation: ClosableConversation) {
    if (conversation.isTicket) {
      return this.ticketStateService.getTicketCustomStateById(conversation.ticketCustomStateId);
    } else {
      return conversation.state;
    }
  }

  private _preserveCurrentStates() {
    if (!this.currentContext.conversations) {
      return;
    }

    this.currentContext.currentStates = this.currentContext.conversations.reduce(
      (acc, conversation) => {
        acc[conversation.id] = this._conversationCurrentState(conversation);
        return acc;
      },
      {} as ConversationStateAcc,
    );
  }

  private get successfullyClosedConversations() {
    return this.currentContext.conversations.filter(
      (conversation) => !this.currentContext.unresolvedConversations?.includes(conversation),
    );
  }
}
