/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const SurveyNumericScaleRange = BaseValidator.extend({
  validate(value, options, model) {
    if (model.isRatingScale && model.data.hasInvalidScaleRange) {
      return `Step ${model.step.get('order') + 1} Question ${
        model.questionOrder
      }: Numeric scale start and end numbers must be within the 0 - 10 range.`;
    }

    return true;
  },
});

SurveyNumericScaleRange.reopenClass({
  getDependentsFor() {
    return [
      'model.data.scaleStart',
      'model.data.scaleEnd',
      'model.data.isScaleStartInvalid',
      'model.data.isScaleEndInvalid',
    ];
  },
});

export default SurveyNumericScaleRange;
