/* RESPONSIBLE TEAM: team-frontend-tech */
import Transform from '@ember-data/serializer/transform';

export default class ArrayTransform extends Transform {
  deserialize(serialized: any): Array<any> {
    return Array.isArray(serialized) ? serialized : [];
  }
  serialize(deserialized: any): Array<any> {
    return Array.isArray(deserialized) ? deserialized : [];
  }
}
