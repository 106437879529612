/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const AllGenericTriagesAreValidOrDisabled = BaseValidator.extend({
  intl: service(),

  validate(genericTriageLocalizations) {
    if (
      genericTriageLocalizations.any((triage) => !triage.isValid) &&
      genericTriageLocalizations.any((triage) => triage.canUseVisualBuilderEditor)
    ) {
      return `${this.intl.t('ai-agent.profiles.validation-errors.some-languages-contain-errors')}`;
    } else if (genericTriageLocalizations.any((triage) => !triage.isValid)) {
      return `${this.intl.t('ai-agent.profiles.validation-errors.finish-configuring-handover')}`;
    }

    return true;
  },
});

AllGenericTriagesAreValidOrDisabled.reopenClass({
  getDependentsFor() {
    return ['model.genericTriageLocalizations.@each.isValid'];
  },
});

export default AllGenericTriagesAreValidOrDisabled;
