/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import AdminBrowserSettings, {
  type AdminBrowserSettingsWireFormat,
} from 'embercom/objects/inbox/admin-browser-settings';
import type Session from './session';

export default class TeammateBrowserSettings extends Service {
  @service declare session: Session;

  private _settings?: AdminBrowserSettings;

  get settings(): AdminBrowserSettings {
    if (!this._settings) {
      throw new Error(
        `Attempted to access teammate browser settings before they have been set. Please call teammateBrowserSettings.getSettings first.`,
      );
    }
    return this._settings;
  }

  async fetchSettings(): Promise<AdminBrowserSettings> {
    let data: AdminBrowserSettingsWireFormat = await get(
      `/ember/inbox/admin_browser_settings.json?app_id=${this.session.workspace.id}`,
    );
    this._settings = AdminBrowserSettings.deserialize(data);
    return this._settings;
  }
}

declare module '@ember/service' {
  interface Registry {
    teammateBrowserSettings: TeammateBrowserSettings;
    'teammate-browser-settings': TeammateBrowserSettings;
  }
}
