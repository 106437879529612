/* RESPONSIBLE TEAM: team-channels */
import Service, { inject as service } from '@ember/service';
import cached from 'embercom/lib/cached-decorator';
import { buildParams, request } from 'embercom/lib/inbox/requests';
import WhatsappTemplate, {
  type WhatsappTemplateWireFormat,
} from 'embercom/objects/inbox/whatsapp-template';
import type Session from 'embercom/services/session';
import { isPresent } from '@ember/utils';

import BrowserLocales from 'embercom/lib/browser-locales';
import SearchClient from 'embercom/lib/search/client';
import { tracked } from '@glimmer/tracking';

type SearchClientConfig = { fields: { name: string; boost: number }[] };
let searchClientConfig: SearchClientConfig = {
  fields: [
    { name: 'name', boost: 20 },
    { name: 'languageName', boost: 5 },
    { name: 'languageCode', boost: 2 },
    { name: 'header', boost: 1 },
    { name: 'body', boost: 1 },
    { name: 'footer', boost: 1 },
    { name: 'buttons', boost: 1 },
  ],
};

export default class WhatsappTemplates extends Service {
  @service declare session: Session;

  @tracked searchClient: SearchClient;
  @tracked activeIntegration: { id: number } | undefined;

  constructor() {
    super(...arguments);
    this.searchClient = new SearchClient(searchClientConfig);
  }

  get searchClientInitialized() {
    return isPresent(this.searchClient?.index);
  }

  languageNameFromCode(language: string) {
    let languageCode: string = language.replace('_', '-');
    return BrowserLocales[languageCode] || language?.toUpperCase();
  }

  buildSearchClient(templates: Array<WhatsappTemplate>) {
    let templatesSearchDocuments = templates.map((template) => ({
      id: template.id,
      name: template.name,
      languageName: this.languageNameFromCode(template.language),
      languageCode: template.language,
      header: template.header_text,
      body: template.body_text,
      footer: template.footer_text,
      buttons: template.buttons_text,
    }));
    return this.searchClient.build(templatesSearchDocuments);
  }

  convertToParams(opts: {
    conversation_id: number | undefined;
    whatsapp_integration_id: number | undefined;
  }) {
    if (opts.conversation_id) {
      return { conversation_id: opts.conversation_id };
    }
    return opts;
  }

  filterInvalidTemplates(templates: Array<WhatsappTemplate>) {
    if (this.session.workspace.isFeatureEnabled('channels-whatsapp-templates-parameters')) {
      return templates.filter((template) => !template.unsupportedHeader);
    } else {
      return templates.filter((template) => !template.isInvalid);
    }
  }

  @cached({ max: 100, ttl: 1000 * 60 * 60, keyGenerator: JSON.stringify })
  async listTemplates(opts: {
    conversation_id: number | undefined;
    whatsapp_integration_id: number | undefined;
  }): Promise<WhatsappTemplate[]> {
    let filteredOpts = this.convertToParams(opts);
    let params = buildParams(this.session.workspace.id, filteredOpts);
    let response = await request(`/ember/whatsapp/message_templates?${params}`);
    let json: Array<WhatsappTemplateWireFormat> = await response.json();
    let templates = json.map(WhatsappTemplate.deserialize);
    let validTemplates = this.filterInvalidTemplates(templates);
    this.buildSearchClient(validTemplates);

    return validTemplates;
  }

  async searchTemplates(
    query: string,
    opts: { conversation_id: number | undefined; whatsapp_integration_id: number | undefined },
  ) {
    let templates: Array<WhatsappTemplate> = await this.listTemplates(opts);
    if (isPresent(query)) {
      let fuzzyResults = await this.searchClient.search(query);
      let foundTemplatesMap = new Map(
        templates.map((template) => [template.id.toString(), template]),
      );
      templates = fuzzyResults
        .map((result: any) => foundTemplatesMap.get(result.ref.toString()))
        .filter(Boolean); // Remove undefined values
    }

    return { query, templates };
  }
}

declare module '@ember/service' {
  interface Registry {
    whatsappTemplates: WhatsappTemplates;
    'whatsapp-templates': WhatsappTemplates;
  }
}
