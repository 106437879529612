/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const SurveyQuestionOptionsNotEmpty = BaseValidator.extend({
  validate(value, options, model) {
    if ((model.isDropdown || model.isMultiSelect) && model.data.hasInvalidNumberOfOptions) {
      return `Step ${model.step.get('order') + 1} Question ${
        model.questionOrder
      }: Add at least one answer option.`;
    }

    return true;
  },
});

SurveyQuestionOptionsNotEmpty.reopenClass({
  getDependentsFor() {
    return ['model.isDropdown', 'model.isMultiSelect', 'model.data.hasInvalidNumberOfOptions'];
  },
});

export default SurveyQuestionOptionsNotEmpty;
