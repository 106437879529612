/* RESPONSIBLE TEAM: team-proactive-support */

import Service from '@ember/service';
import { inject as service } from '@ember/service';
import loadCDNScript from 'embercom/lib/load-cdn-script';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import { tracked } from 'tracked-built-ins';

export interface EntriDnsRecord {
  type: 'CNAME' | 'TXT';
  host: string;
  value: string;
  ttl: number;
}

export interface EntriConnectConfig {
  applicationId: string;
  token: string;
  prefilledDomain: string;
  dnsRecords: EntriDnsRecord[];
  whiteLabel: any;
}

export const ENTRI_CONNECT_SETUP_TYPES = {
  AUTOMATIC: 'Automatic', // eslint-disable-line @intercom/intercom/no-bare-strings
  MANUAL: 'Manual', // eslint-disable-line @intercom/intercom/no-bare-strings
};

export default class EntriConnectService extends Service {
  @service appService: any;
  @tracked token = '';
  @tracked tokenExpiresAt: Date | null = null;
  @tracked link = '';

  @task *checkDomain(domain: string, dnsRecords: EntriDnsRecord[]): TaskGenerator<boolean> {
    try {
      let response = yield post(
        `/ember/entri_connect/check_domain?app_id=${this.appService.app.id}`,
        {
          domain,
          dns_records: dnsRecords,
        },
      );
      this.token = response.authentication?.token;
      this.tokenExpiresAt = response.authentication?.expires_at
        ? new Date(response.authentication.expires_at)
        : null;
      let setupType = response.domain?.setup_type;
      return setupType === ENTRI_CONNECT_SETUP_TYPES.AUTOMATIC;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @task *generateLink(domain: string, dnsRecords: EntriDnsRecord[]): TaskGenerator<void> {
    try {
      let response = yield post(
        `/ember/entri_connect/generate_link?app_id=${this.appService.app.id}`,
        {
          domain,
          dns_records: dnsRecords,
        },
      );
      this.link = response.link;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  @task *loadEntriLibrary(): TaskGenerator<any> {
    try {
      if ((window as any).entri === undefined) {
        return yield loadCDNScript(this.librarySourceScript);
      }
    } catch (error) {
      console.error(error);
    }
  }

  get librarySourceScript() {
    return `https://cdn.goentri.com/entri.js`;
  }

  // https://developers.entri.com/api-reference#whitelabel-object
  get whitelabelOptions(): any {
    return {
      hideEntriLogo: true,
      hideProgressIndicator: true,
      theme: {
        bg: '#222222', // button background color
        fg: '#ffffff', // button text color
        links: '#334bfa',
        buttons: {
          border: {
            radius: '8px',
          },
        },
        googleFont: {
          // eslint-disable-next-line @intercom/intercom/no-bare-strings
          family: 'Inter',
          variant: '500',
        },
      },
      customProperties: {
        initialScreen: {
          disableScreen: true,
        },
        manualConfiguration: {
          disableScreen: true,
        },
        providerLogin: {
          showEntriToS: false,
          disableExtendedLoginAnimation: true,
          forwardLink: {
            style: [],
          },
          gotoManualLink: {
            disable: true,
          },
        },
      },
    };
  }
}
