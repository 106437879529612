/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';

export default buildValidations({
  title: {
    description: 'Path title',
    validators: [validator('presence', true)],
  },
  autoFollowHardLimit: {
    description: 'Auto follow hard limit',
    validators: [validator('workflow-path-limits-consecutive-auto-follows')],
  },
  autoFollowSoftLimit: {
    description: 'Auto follow soft limit',
    validators: [
      validator('workflow-path-limits-consecutive-auto-follows', {
        isWarning: true,
      }),
    ],
  },
  steps: {
    description: 'Path step',
    validators: [validator('presence', true), validator('has-many')],
  },
  incomingControls: {
    description: 'Incoming controls',
    validators: [validator('workflow-path-is-not-orphan')],
  },
});
