/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import type Task from 'embercom/models/checklists/task';
import { isEmpty } from '@ember/utils';
import {
  type EmberCPError,
  toErrorResult,
  type EmberCPValidationsResult,
} from 'embercom/validations/typescript/ember-cp-validations-compat';
import { type Paragraph } from 'embercom/models/common/blocks/paragraph';
import containerLookup from 'embercom/lib/container-lookup';

export function validateTask(task: Task): EmberCPValidationsResult {
  let errors: Array<EmberCPError> = taskValidationErrors(task);
  return toErrorResult(errors);
}

export function taskValidationErrors(task: Task): Array<EmberCPError> {
  let intl = containerLookup('service:intl');
  let taskPrefix = intl.t('outbound.checklists.task-number', {
    order: task.humanReadableOrder,
  });

  let errors: Array<EmberCPError> = [];

  if (isEmpty(task.titleBlocks) || task.titleBlocks.any((block: Paragraph) => !block.hasContent)) {
    errors.push({
      attribute: 'titleBlocks',
      message: `${taskPrefix}: ${intl.t('outbound.checklists.validations.empty-title-blocks')}`,
    });
  }

  if (isEmpty(task.bodyBlocks) || !task.bodyBlocks.any((block: Paragraph) => block.hasContent)) {
    errors.push({
      attribute: 'bodyBlocks',
      message: `${taskPrefix}: ${intl.t('outbound.checklists.validations.empty-task-body-blocks')}`,
    });
  }

  if (task.openUrlAction && isEmpty(task.actionUrl)) {
    errors.push({
      attribute: 'actionUrl',
      message: `${taskPrefix}: ${intl.t('outbound.checklists.validations.missing-action-url')}`,
    });
  }

  if (task.productTourAction && isEmpty(task.actionContentId)) {
    errors.push({
      attribute: 'actionContentId',
      message: `${taskPrefix}: ${intl.t('outbound.checklists.validations.no-tour-selected')}`,
    });
  }

  if (task.articleAction && isEmpty(task.actionContentId)) {
    errors.push({
      attribute: 'actionContentId',
      message: `${taskPrefix}: ${intl.t('outbound.checklists.validations.no-article-selected')}`,
    });
  }

  if (!task.canBeResolved) {
    errors.push({
      attribute: 'canBeResolved',
      message: `${taskPrefix}: ${intl.t('outbound.checklists.validations.task-not-resolvable')}`,
    });
  }

  return errors;
}
