/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { buildParams, request } from 'embercom/lib/inbox/requests';
import CrmProfile, { type CrmProfileWireFormat } from 'embercom/objects/inbox/crm-profile';
import { post } from 'embercom/lib/ajax';

export default class CrmProfilesApi extends Service {
  @service declare session: Session;

  async fetchPipedriveProfile(
    userId: string,
    init: RequestInit = {},
  ): Promise<CrmProfile | undefined> {
    let params = buildParams(this.session.workspace.id, {
      profile_type: 'pipedrive',
      user_id: userId,
    });

    let response = await request(`/ember/crm_profiles?${params}`, init);

    // We can get a 204 when there is no profile.
    if (response.status !== 200) {
      return;
    }

    let [profile] = (await response.json()) as CrmProfileWireFormat[];
    return CrmProfile.deserialize(profile);
  }

  async createPipedriveDeal(userId: string, conversationId: number): Promise<CrmProfile> {
    let profile = (await post(`/ember/crm_profiles`, {
      app_id: this.session.workspace.id,
      profile_type: 'pipedrive',
      user_id: userId,
      conversation_id: conversationId,
    })) as CrmProfileWireFormat;

    return CrmProfile.deserialize(profile);
  }
}

declare module '@ember/service' {
  interface Registry {
    crmProfilesApi: CrmProfilesApi;
    'crm-profiles-api': CrmProfilesApi;
  }
}
