/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { all } from 'ember-concurrency';
import { chunk, indexBy } from 'underscore';

const STATS_BATCH_SIZE = 5;
const PROPAGATED_STATS_BATCH_SIZE = 100;

export default class TopicStatsService extends Service {
  @service store;
  @service appService;

  @task({ enqueue: true, maxConcurrency: 4 })
  *loadRawTopicStats({
    rangeStart,
    rangeEnd,
    excludeOooHours,
    excludeBotInboxTime,
    topicIds,
    includeKpis,
    includePreviousRange,
  }) {
    let response = yield ajax({
      url: '/ember/conversational_insights/conversation_topics/stats',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        range_start: rangeStart,
        range_end: rangeEnd,
        exclude_ooo_hours: excludeOooHours,
        exclude_bot_inbox: excludeBotInboxTime,
        topic_ids: topicIds,
        include_kpis: includeKpis,
        include_previous_range: includePreviousRange,
      }),
    });

    return indexBy(response.stats, 'topic_id');
  }

  @task
  *loadTopicConversationCoverage({ rangeStart, rangeEnd }) {
    let response = yield ajax({
      url: '/ember/conversational_insights/conversation_topics/topic_conversation_coverage',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        range_start: rangeStart,
        range_end: rangeEnd,
      }),
    });
    return response.topic_coverage;
  }

  @task({ keepLatest: true, maxConcurrency: 1 })
  *loadRawKeyphraseStats({
    rangeStart,
    rangeEnd,
    excludeOooHours,
    excludeBotInboxTime,
    keyphrases,
    excludedKeyphrases,
    authorTypes,
    conversationCustomData = [],
    topicId,
  }) {
    return yield ajax({
      url: '/ember/conversational_insights/conversation_topics/stats',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        range_start: rangeStart,
        range_end: rangeEnd,
        exclude_ooo_hours: excludeOooHours,
        exclude_bot_inbox: excludeBotInboxTime,
        keyphrases,
        excluded_keyphrases: excludedKeyphrases,
        author_types: authorTypes,
        conversation_custom_data: conversationCustomData,
        topic_id: topicId,
      }),
    });
  }

  @task({ restartable: true })
  *loadTopicStatsInBatches({
    rangeStart,
    rangeEnd,
    topicIds,
    excludeOooHours,
    excludeBotInboxTime,
    includePreviousRange,
  }) {
    let batches = chunk(topicIds, STATS_BATCH_SIZE);

    let loadingTasks = batches.map((batch) => {
      return this.loadRawTopicStats.perform({
        rangeStart,
        rangeEnd,
        excludeOooHours,
        excludeBotInboxTime,
        topicIds: batch,
        includePreviousRange,
      });
    });

    let topicStatsBatches = yield all(loadingTasks);

    return Object.assign({}, ...topicStatsBatches);
  }

  @task({ enqueue: true, maxConcurrency: 2 })
  *loadRawPropagatedTopicStats({
    rangeStart,
    rangeEnd,
    excludeOooHours,
    excludeBotInboxTime,
    topicIds,
    includeKpis,
  }) {
    let response = yield ajax({
      url: '/ember/conversational_insights/conversation_topics/propagated_stats',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        range_start: rangeStart,
        range_end: rangeEnd,
        exclude_ooo_hours: excludeOooHours,
        exclude_bot_inbox: excludeBotInboxTime,
        topic_ids: topicIds,
        include_kpis: includeKpis,
      }),
    });

    return indexBy(response.stats, 'topic_id');
  }

  @task
  *loadPropagatedTopicStatsInBatches({
    rangeStart,
    rangeEnd,
    excludeOooHours,
    excludeBotInboxTime,
    topicIds,
    includeKpis,
  }) {
    let batches = chunk(topicIds, PROPAGATED_STATS_BATCH_SIZE);

    let loadingTasks = batches.map((batch) => {
      return this.loadRawPropagatedTopicStats.perform({
        rangeStart,
        rangeEnd,
        excludeOooHours,
        excludeBotInboxTime,
        topicIds: batch,
        includeKpis,
      });
    });

    let topicStatsBatches = yield all(loadingTasks);

    return Object.assign({}, ...topicStatsBatches);
  }

  // Suggested topics
  @task({ enqueue: true, maxConcurrency: 4 })
  *loadRawSuggestedTopicStats({
    rangeStart,
    rangeEnd,
    excludeOooHours,
    excludeBotInboxTime,
    suggestedTopicIds,
    includePreviousRange,
  }) {
    return yield ajax({
      url: '/ember/conversational_insights/suggested_conversation_topics/stats',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        range_start: rangeStart,
        range_end: rangeEnd,
        exclude_ooo_hours: excludeOooHours,
        exclude_bot_inbox: excludeBotInboxTime,
        suggested_topic_ids: suggestedTopicIds,
        include_previous_range: includePreviousRange,
      }),
    });
  }

  @task({ restartable: true })
  *loadSuggestionStatsInBatches({
    rangeStart,
    rangeEnd,
    suggestedTopics,
    excludeOooHours,
    excludeBotInboxTime,
    includePreviousRange,
  }) {
    let loadedSuggestedTopics = yield suggestedTopics;
    let suggestedTopicIds = loadedSuggestedTopics.map(({ id }) => id);

    let batches = chunk(suggestedTopicIds, STATS_BATCH_SIZE);

    let promises = batches.map((batch) => {
      return this.loadRawSuggestedTopicStats.perform({
        rangeStart,
        rangeEnd,
        excludeOooHours,
        excludeBotInboxTime,
        suggestedTopicIds: batch,
        includePreviousRange,
      });
    });

    let responses = yield all(promises);

    let responsesStats = responses.map((response) => response.stats).flat();

    return indexBy(responsesStats, 'topic_id');
  }
}
