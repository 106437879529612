/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import JSONSerializer from '@ember-data/serializer/json';

export default JSONSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    user: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
});
