/* RESPONSIBLE TEAM: team-frontend-tech */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';

export default EmbercomBaseSerializer.extend({
  keyForAttribute(attr) {
    if (attr === 'contentType') {
      return attr;
    }
    return this._super(...arguments);
  },

  serialize(snapshot, options) {
    let serialized = this._super(snapshot, options);
    if (isPresent(serialized.attachment_id)) {
      serialized.id = serialized.attachment_id;
      delete serialized.attachment_id;
    } else {
      assert('Attachments must have an attachment id to be serialized correctly');
    }
    return serialized;
  },
});
