/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isEmpty } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const WorkflowPathIsNotOrphan = BaseValidator.extend({
  validate(pathSteps, options, model) {
    if (model.get('index') > 0 && isEmpty(model.get('incomingControls'))) {
      return 'No one will be able to see this path because no other paths link to it';
    }
    return true;
  },
});

WorkflowPathIsNotOrphan.reopenClass({
  getDependentsFor() {
    return ['model.index', 'model.incomingControls.[]'];
  },
});

export default WorkflowPathIsNotOrphan;
