/* RESPONSIBLE TEAM: team-growth-opportunities */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  isEarlyStageSolution,
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
} from 'embercom/lib/billing';

const SERVICE_PREFIX = 'signup-service-';
const LOCALSTORAGE_BILLING_PERIOD_KEY = `${SERVICE_PREFIX}selected-billing-period-duration-in-months`;

export default class SignupService extends Service {
  @tracked solutionId = VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID;

  get storedBillingPeriod() {
    return localStorage.getItem(LOCALSTORAGE_BILLING_PERIOD_KEY);
  }

  set billingPeriod(billingPeriodInMonths) {
    localStorage.setItem(LOCALSTORAGE_BILLING_PERIOD_KEY, billingPeriodInMonths);
  }

  get billingPeriod() {
    return this.isBillingPeriodSelected
      ? this.storedBillingPeriodInteger
      : this.defaultBillingPeriodInteger;
  }

  get isBillingPeriodSelected() {
    return !!this.storedBillingPeriod;
  }

  get storedBillingPeriodInteger() {
    return parseInt(this.storedBillingPeriod, 10);
  }

  get defaultBillingPeriodInteger() {
    return isEarlyStageSolution(this.solutionId) ? 1 : 12;
  }
}
