/* RESPONSIBLE TEAM: team-workflows */
import { validator, buildValidations } from 'ember-cp-validations';
import { not, bool, or } from '@ember/object/computed';

export default buildValidations({
  paths: {
    descriptionKey: 'operator.custom-bot.validation-descriptions.paths',
    validators: [validator('presence', true), validator('has-many')],
    disabled: bool('model.canUseVisualBuilderEditor'),
  },
  botData: {
    descriptionKey: 'operator.custom-bot.validation-descriptions.bot-data',
    validators: [
      validator('custom-bot-bot-data-selector-present', {
        disabled: not('model.isButton'),
      }),
    ],
  },
  inactivityDuration: {
    descriptionKey: 'operator.custom-bot.validation-descriptions.inactivity-duration',
    validators: [
      validator('custom-bot-inactivity-duration-valid', {
        disabled: not('model.isInactivityTrigger'),
      }),
    ],
  },
  firstPathConstraints: {
    descriptionKey: 'operator.custom-bot.validation-descriptions.first-path',
    validators: [
      validator('custom-bot-first-path-constraints', {
        disabled: or('model.isTriggerable', 'model.canUseVisualBuilderEditor'),
      }),
    ],
  },
  visualBuilderObject: validator('belongs-to', {
    descriptionKey: 'operator.custom-bot.validation-descriptions.workflow',
    disabled: not('model.canUseVisualBuilderEditor'),
  }),
});
