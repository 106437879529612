/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type Chart from 'embercom/models/reporting/custom/chart';
import generateUUID from 'embercom/lib/uuid-generator';
// @ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import { VIZ_TYPE_TO_ICON_MAPPING } from 'embercom/models/reporting/custom/visualization-options';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type IntlService from 'embercom/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type Report from 'embercom/models/reporting/custom/report';
import { cached } from 'tracked-toolbox';
import { indexBy } from 'underscore';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { isPresent } from '@ember/utils';
import Range from 'embercom/models/reporting/range';
import { setDefaultSizesOnChart } from 'embercom/lib/reporting/custom/visualization-type-grid-sizes';
import { seriesNameForMetric } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type ReportingStandalone from 'embercom/services/reporting-standalone';

export const INSIDE_OFFICE_HOURS = 'inside-office-hours';
export const ALL_HOURS = 'all-hours';

export interface ChartTemplate extends Chart {
  icon: InterfaceIconName;
  supportsCustomAggregations: boolean;
  featureKey: string;
  imageUrl: string;
  libraryTitle: string;
}

export interface ReportTemplate extends Report {
  icon: InterfaceIconName;
  featureKey: string;
  templateId: string;
}

export default class ReportingTemplates extends Service {
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare store: any;
  @service declare intl: IntlService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare reportingStandalone: ReportingStandalone;

  chartTemplates: ChartTemplate[] = [];
  reportTemplates: ReportTemplate[] = [];
  groupedTemplatesByReportType: any = {};

  private isSetup = false;

  @cached
  get reportTemplatesById(): Record<string, ReportTemplate> {
    return indexBy(this.reportTemplates, 'templateId');
  }

  getReportTemplates(isStandalone = false): ReportTemplate[] {
    if (isStandalone) {
      return this.reportingStandalone.filterReportTemplates(this.reportTemplates);
    }
    return this.reportTemplates;
  }

  get canViewCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_access_reporting');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__read');
  }

  canUseChartTemplate(templateId: string) {
    if (!isPresent(templateId)) {
      return true;
    }
    let template = this.chartTemplates.find(
      (chartTemplate) => chartTemplate.templateId === templateId,
    );
    return (
      !isPresent(template?.featureKey) || this.appService.app?.canUseFeature(template?.featureKey)
    );
  }

  async loadTemplates() {
    if (!this.isSetup && this.canViewCustomReports) {
      await taskFor(this.reportingMetrics.setup).perform(); // Ensure metrics are loaded first as we depend on them
      await taskFor(this.fetchTemplates).perform();
      this.isSetup = true;
      this.groupedTemplatesByReportType = this.groupTemplatesByReportType();
    }
  }

  @dropTask
  *fetchTemplates(): TaskGenerator<void> {
    let results = yield this.fetchData('/ember/reporting/custom_reports/templates');

    this.reportTemplates = results['reports'].map((template: any) => {
      return this.reportFromTemplateDefinition(template);
    });

    // these templates will be shown in the library, filtering the components to avoid them to be displayed.
    this.chartTemplates = results['charts']
      .filter((template: any) => !template.template_id?.startsWith('component'))
      .map((template: any) => {
        return this.chartFromTemplateDefinition(template);
      });
  }

  private chartFromTemplateDefinition(template: any) {
    let chart = this.store.normalize(
      'reporting/custom/chart',
      this.sanitizedChartTemplateDefinition(template),
    );
    let metricIds = chart.data.attributes.chartSeries.map((series: any) => {
      return series.metricId;
    });
    return {
      ...chart.data.attributes,
      icon: VIZ_TYPE_TO_ICON_MAPPING[chart.data.attributes.visualizationType],
      supportsCustomAggregations: this.usesCustomAggregations(metricIds),
      featureKey: this.getChartTemplateFeatureKey(chart.data.attributes.templateId),
      imageUrl: this.getChartTemplateImageUrl(chart.data.attributes),
      libraryTitle: template.library_title || template.title,
    };
  }

  private getChartTemplateImageUrl(chart: Chart) {
    if (chart.visualizationType === 'counter') {
      let metricId = chart.chartSeries.firstObject.metricId;
      let metric = this.reportingMetrics.getMetricById(metricId);
      let imageName = `${chart.visualizationType}-${metric.unit}`;
      return assetUrl(`/assets/svgs/reporting/chart-types/${imageName}.svg`);
    }
    return assetUrl(`/assets/svgs/reporting/chart-types/${chart.visualizationType}.svg`);
  }

  private reportFromTemplateDefinition(template: any): ReportTemplate {
    let report = this.store.normalize(
      'reporting/custom/report',
      this.sanitizedReportTemplateDefinition(template),
    );
    return {
      ...report.data.attributes,
      id: report.data.id,
      templateId: template.template_id,
      featureKey: template.feature_key,
      icon: template.icon || 'bar-charts',
      charts: report.included.map((chart: any) => {
        return { ...chart.attributes, id: chart.id };
      }),
    };
  }

  private usesCustomAggregations(metricIds: string[]) {
    return metricIds.some((metricId: string) => {
      return this.reportingMetrics.metricSupportsCustomAggregations(metricId);
    });
  }

  private getChartTemplateFeatureKey(chartTemplateId: string) {
    // we'll return early if workspace has access to custom_reports
    if (this.appService.app.hasCustomReportsBillingFeature) {
      return 'custom_reports';
    }

    // we paywall based on the report templates that reference this chart template
    let reportTemplatesReferencingChart = this.reportTemplates.filter((template) =>
      template.charts.any((chart: Chart) => chart.templateId === chartTemplateId),
    );
    // a chart template can be used in several report templates
    // we'll return the first report template featureKey which is valid for the workspace, if any
    let validReportTemplate = reportTemplatesReferencingChart.find(
      (template) =>
        !isPresent(template.featureKey) || // if the chart is referenced by a template without a featureKey, we shouldn't paywall it
        (isPresent(template.featureKey) && this.appService.app.canUseFeature(template.featureKey)),
    );
    if (isPresent(validReportTemplate)) {
      return validReportTemplate!.featureKey;
    }

    // if the workspace doesn't have a valid feature for the template
    // we'll return the first report template featureKey associated with the template to assure we'll paywall it, if any
    return reportTemplatesReferencingChart.find((template) => isPresent(template.featureKey))
      ?.featureKey;
  }

  async fetchData(url: string): Promise<any> {
    return await ajax({
      url,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  searchCharts(searchTerm: string): ChartTemplate[] {
    if (!searchTerm) {
      return this.chartTemplates;
    }

    return this.chartTemplates.filter((item: ChartTemplate) => {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  searchChartsWithFilters(
    searchTerm: string,
    chartType: string,
    reportType: string,
    isStandalone = false,
  ): any {
    if (!searchTerm && chartType === 'any' && reportType === 'any') {
      if (isStandalone) {
        return this.reportingStandalone.filterStandaloneCharts(this.groupedTemplatesByReportType);
      }
      return this.groupedTemplatesByReportType;
    }

    let templates = this.groupedTemplatesByReportType.reduce(
      (filteredTemplates: any[], group: any) => {
        if (reportType !== 'any' && group.reportId !== reportType) {
          return filteredTemplates;
        }

        let chartTemplates = group.chartTemplates.filter((chart: ChartTemplate) => {
          let matchesSearchTerm = searchTerm
            ? chart.title.toLowerCase().includes(searchTerm.toLowerCase())
            : true;
          let matchesChartType = chartType !== 'any' ? chart.visualizationType === chartType : true;
          return matchesSearchTerm && matchesChartType;
        });

        if (chartTemplates.length > 0) {
          filteredTemplates.push({
            ...group,
            chartTemplates,
          });
        }

        return filteredTemplates;
      },
      [],
    );
    if (isStandalone) {
      return this.reportingStandalone.filterStandaloneCharts(templates);
    }

    return templates;
  }

  searchReports(searchTerm: string): ReportTemplate[] {
    if (!searchTerm) {
      return this.reportTemplates;
    }

    return this.reportTemplates.filter((item: ReportTemplate) => {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  groupTemplatesByReportType(): any {
    let templateMap = this.chartTemplates.reduce(
      (map: Record<string, ChartTemplate>, template: ChartTemplate) => {
        map[template.templateId] = template;
        return map;
      },
      {},
    );
    let usedTemplateIds = new Set();

    let reportResults = this.reportTemplates.map((report: ReportTemplate) => {
      let chartTemplates = report.charts
        .map((chart: Chart) => templateMap[chart.templateId])
        .filter((template: ChartTemplate) => !!template);

      chartTemplates.forEach((template: ChartTemplate) => usedTemplateIds.add(template.templateId));

      return {
        title: report.title,
        reportId: report.templateId,
        chartTemplates,
      };
    });

    let unusedTemplates = this.chartTemplates.filter(
      (template: any) => !usedTemplateIds.has(template.templateId),
    );

    if (unusedTemplates.length > 0) {
      reportResults.push({
        title: this.intl.t('reporting.custom-reports.chart.sidebar-filters.reports.other'),
        reportId: 'other',
        chartTemplates: unusedTemplates,
      });
    }

    return reportResults;
  }

  sanitizedChartTemplateDefinition(template: any) {
    let sanitizedTemplateDefinition = copy(template, true);
    // Sanitize chart ids so we don't accidentally overwrite other custom charts in the store
    let chartId = `chart-template-${generateUUID()}`;
    sanitizedTemplateDefinition.id = chartId;
    return sanitizedTemplateDefinition;
  }

  sanitizedReportTemplateDefinition(template: any) {
    let sanitizedTemplateDefinition = copy(template, true);
    // Sanitize IDs so we don't accidentally overwrite other custom reports in the store
    let reportId = `report-template-${generateUUID()}`;
    sanitizedTemplateDefinition.id = reportId;
    sanitizedTemplateDefinition['charts'].forEach((chart: any, index: number) => {
      chart.id = `chart-${reportId}-${index}`;
    });
    return sanitizedTemplateDefinition;
  }

  buildChartFromTemplate(
    chartTemplate: ChartTemplate,
    aggregation: Aggregation,
    report: Report,
    selectedOfficeHours: string,
  ) {
    let chartDefinition = copy(chartTemplate, true);
    chartDefinition.dateRange = isPresent(report.dateRange)
      ? copy(report.dateRange)
      : Range.createFromPreset('past_4_weeks', report.reportTimezone);

    if (chartDefinition.chartSeries.length === 1) {
      if (chartDefinition.supportsCustomAggregations) {
        chartDefinition.chartSeries.firstObject.aggregation = aggregation;
        let templateTitle = chartDefinition.title;
        chartDefinition.title = seriesNameForMetric(templateTitle, aggregation, true);
      }
      let variantMetricId = this.getOfficeHourMetricVariantId(
        chartDefinition.chartSeries.firstObject,
        selectedOfficeHours,
      );
      chartDefinition.chartSeries.firstObject.metricId = variantMetricId;
    } else {
      chartDefinition.chartSeries.forEach((series: ChartSeries) => {
        if (
          chartDefinition.supportsCustomAggregations &&
          this.reportingMetrics.metricSupportsCustomAggregations(series.metricId)
        ) {
          series.aggregation = aggregation;
        }
        let variantMetricId = this.getOfficeHourMetricVariantId(series, selectedOfficeHours);
        series.metricId = variantMetricId!;
      });
    }

    setDefaultSizesOnChart(chartDefinition);
    return chartDefinition;
  }

  getOfficeHourMetricVariantId(chartSeries: ChartSeries, selectedOfficeHours: string) {
    let templateMetric = this.reportingMetrics.getMetricById(chartSeries.metricId);
    let officeHoursMetricId;
    if (selectedOfficeHours === INSIDE_OFFICE_HOURS) {
      let metricDefault = this.reportingMetrics
        .getMetricsByFamilyId(templateMetric.metricFamilyId!)
        .find((metric: Metric) => metric.metricFamilyDefault);
      officeHoursMetricId = metricDefault?.id;
    } else if (selectedOfficeHours === ALL_HOURS) {
      let variantMetric = this.reportingMetrics
        .getMetricsByFamilyId(templateMetric.metricFamilyId!)
        .find((metric: Metric) => !metric.metricFamilyDefault);
      officeHoursMetricId = variantMetric?.id;
    }

    if (isPresent(officeHoursMetricId)) {
      return officeHoursMetricId;
    }
    return chartSeries.metricId;
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingTemplates: ReportingTemplates;
    'reporting-templates': ReportingTemplates;
  }
}
