/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-private-routing-service */
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import ENV from 'embercom/config/environment';
import { task } from 'ember-concurrency-decorators';
import { getOwner } from '@ember/application';

import ajax from 'embercom/lib/ajax';

export default class AppstoreService extends Service {
  @service store;
  @service router;
  @service appService;
  @service redirectService;

  appCapabilities = [
    { slug: 'messages', name: 'Outbound' },
    { slug: 'inbox', name: 'Help Desk' },
    { slug: 'operator', name: 'Automations' },
    { slug: 'messenger', name: 'Messenger' },
  ];

  get privateAppStoreUrl() {
    return this.appService.app?.canUseMigratedAppStore
      ? 'apps.app.settings.app-settings.app-store'
      : 'apps.app.appstore.index';
  }

  get baseRoute() {
    return EmbercomBootDataModule.isBootedInPublicAppStoreMode()
      ? 'appstore.index'
      : this.privateAppStoreUrl;
  }
  getRedirectQueryParams() {
    let params;
    let router = getOwner(this).lookup('router:main');
    let currentState = router.currentState;
    let routerLib = router._routerMicrolib;

    if (routerLib && routerLib.activeTransition) {
      params = routerLib.activeTransition.to.queryParams;
    } else if (currentState) {
      params = currentState.routerJsState.queryParams;
    }
    if (!params) {
      return {};
    }

    let {
      install_success,
      app_package_code,
      tab,
      error_message,
      is_onboarding,
      email_request,
      inbox_capability,
      redirect,
    } = params;

    return {
      install_success,
      app_package_code,
      tab,
      error_message,
      is_onboarding,
      email_request,
      inbox_capability,
      redirect,
    };
  }

  @task({ drop: true })
  *dismissAppInstallNotifications(restrict_to) {
    yield ajax({
      url: '/ember/messenger_apps/dismiss_notifications',
      type: 'DELETE',
      data: JSON.stringify({
        app_id: this.get('appService.app.id'),
        restrict_to,
      }),
    });
  }

  hasAppPackageCode() {
    let { app_package_code } = this.getRedirectQueryParams();
    return isPresent(app_package_code);
  }
  peekOrFindAppPackage(packageId) {
    return this.store.hasRecordForId('appstore/app-package', packageId)
      ? this.store.peekRecord('appstore/app-package', packageId)
      : this.store.findRecord('appstore/app-package', packageId);
  }
  async resolveAppPackageCodes(appPackageCodes) {
    let recommendations = [];

    for (let i = 0; i < appPackageCodes.length; i++) {
      let recommendationPackage = await this.peekOrFindAppPackage(appPackageCodes[i]);
      recommendations.push(recommendationPackage);
    }
    return recommendations;
  }
  generateTitle({ app_package_code, category, installed, search, capability }) {
    if (app_package_code) {
      let modelPath = this.get('appService.app.useAppCardApi')
        ? 'appstore/app-card'
        : 'appstore/app-package';
      let appPackage = this.store.peekRecord(modelPath, app_package_code);
      return appPackage ? `${appPackage.get('name')} | App Store` : 'App Store';
    } else if (search) {
      return 'Search Results | App Store';
    } else if (installed) {
      return 'Installed apps | App Store';
    } else if (category) {
      let categories = this.store.peekAll('appstore/category');
      let categoryName = categories.find((c) => c.get('slug') === category)?.name;
      return categoryName ? `${categoryName} | App Store` : 'App Store';
    } else if (capability) {
      let capitalized = capability.charAt(0).toUpperCase() + capability.slice(1);
      return `Works with ${capitalized} | App Store`;
    } else {
      return 'App Store';
    }
  }
  urlForWorkspaceAppstore(currentLocation) {
    let rootUrl = EmbercomBootDataModule.publicPageRootUrl();
    let appPackagePath = currentLocation.pathname.substring(rootUrl.length);
    let currentQueryParams = currentLocation.search;
    let host = currentLocation.host;
    let startOfTld = host.lastIndexOf('.') + 1;
    let tld = host.slice(startOfTld);
    let intercomHost = `${currentLocation.protocol}//app.intercom.${tld}`;
    let urlForWorkspaceAppstore = encodeURIComponent(
      `${intercomHost}/a/apps/_/appstore${appPackagePath}${currentQueryParams}`,
    );
    let signInUrl = `${intercomHost}/admins/sign_in?redirect_url=${urlForWorkspaceAppstore}`;
    return signInUrl;
  }
  redirectToWorkspaceAppstore() {
    this.redirectService.redirect(this.urlForWorkspaceAppstore(window.location));
  }
  shouldEnableGtmTracking() {
    return (
      EmbercomBootDataModule.isBootedInPublicAppStoreMode() && ENV.environment === 'production'
    );
  }
}
