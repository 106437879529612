/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { hash } from 'rsvp';
import $ from 'jquery';
import Service, { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';

export default Service.extend({
  bootOptionsService: service(),
  regionService: service(),
  companyId: null,

  boot(app, admin) {
    let hasApp = app !== undefined;
    let hasAdmin = admin !== undefined;

    if (
      !this._isEnabledInEnvironment() ||
      (hasApp && this._isImpersonationSession(app)) ||
      (hasAdmin && admin.isImpersonating)
    ) {
      return;
    }

    if (this._isEmbeddedContent()) {
      return;
    }

    let promises = {
      script: this._loadCachedScript(this._scriptSrc()),
    };

    if (hasApp) {
      promises.data = $.get('/ember/admins/intercom_settings.json');
    }

    return hash(promises).then(({ data }) => {
      data = data || { app_id: this.regionService.intercomUSAppCode };
      window.intercomSettings = data;
      this._setCompanyId(data);
      this._intercomBoot(data);
    });
  },

  update(data) {
    window.Intercom('update', data);
  },

  _intercomBoot(data) {
    window.Intercom('boot', data);
  },

  _isImpersonationSession(app) {
    return app.impersonation_session;
  },

  _isEnabledInEnvironment() {
    //stub this to `true` if you want this service to run in tests
    return ENV.environment !== 'test';
  },

  _isDevelopmentEnvironment() {
    return ENV.environment === 'development';
  },

  _isEmbeddedContent() {
    return this.get('bootOptionsService.embeddedContent');
  },

  _scriptSrc() {
    return `https://widget.intercom.io/widget/${this.regionService.intercomUSAppCode}`;
  },

  _setCompanyId(data) {
    if (data.company && data.company.id) {
      this.set('companyId', data.company.id);
    }
  },

  _loadCachedScript(url, options) {
    // Taken here https://api.jquery.com/jquery.getscript/
    // Allow user to set any option except for dataType, cache, and url
    options = $.extend(options || {}, {
      dataType: 'script',
      cache: true,
      url,
    });

    // Use $.ajax() since it is more flexible than $.getScript
    // Return the jqXHR object so we can chain callbacks
    return $.ajax(options);
  },
});
