/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default BaseValidator.extend({
  operatorMessengerAppService: service(),

  validate(value) {
    if (!value) {
      return true;
    }

    let messengerAppIds = this.extractMessengerAppIds(value);
    if (messengerAppIds.length > 0) {
      return this.operatorMessengerAppService.validateMessengerAppIds(messengerAppIds);
    }

    return true;
  },

  extractMessengerAppIds(value) {
    return value
      .filter((block) => block.type === 'messengerCard' && block.messenger_app_id)
      .map((block) => block.messenger_app_id);
  },
});
