/* RESPONSIBLE TEAM: team-proactive-support */
import RulesetLinkSerializer from '../ruleset-link';

export default RulesetLinkSerializer.extend({
  serialize() {
    let json = this._super(...arguments);

    json.object.headers = this._jsonToKeyValues(json.object.headers);
    json.object.body = this._jsonToKeyValues(json.object.body);

    return json;
  },

  normalize(modelClass, resourceHash) {
    resourceHash.object.url = resourceHash.object.url || '';
    resourceHash.object.headers = this._keyValuesAsJson(resourceHash.object.headers);
    resourceHash.object.body = this._keyValuesAsJson(resourceHash.object.body);
    return this._super(...arguments);
  },

  _jsonToKeyValues(array) {
    return array.reduce((acc, item) => {
      if (Boolean(item.key) || Boolean(item.value)) {
        acc[item.key] = item.value;
      }
      return acc;
    }, {});
  },

  _keyValuesAsJson(json) {
    if (!json) {
      return [];
    } else {
      return Object.entries(json).map(([key, value]) => {
        return { key, value };
      });
    }
  },
});
