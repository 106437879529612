/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';
import type Session from 'embercom/services/session';

export default class ConversationStreamSettings extends Service {
  @service declare session: Session;
  @tracked hideEvents = this.storedValueOrDefault;

  get storedValueOrDefault() {
    if (storage.get(this.localStorageHideEventsKey) !== null) {
      return storage.get(this.localStorageHideEventsKey);
    }
    return true;
  }

  get localStorageHideEventsKey() {
    let appId = this.session.workspace.id;
    let adminId = this.session.teammate.id;
    return `${appId}::${adminId}::ConversationStreamSettings::hideEvents`;
  }

  @action toggleEvents(toggle?: boolean): void {
    if (toggle === undefined) {
      this.hideEvents = !this.hideEvents;
    } else {
      this.hideEvents = toggle;
    }
    storage.set(this.localStorageHideEventsKey, this.hideEvents);
  }
}

declare module '@ember/service' {
  interface Registry {
    conversationStreamSettings: ConversationStreamSettings;
    'conversation-stream-settings': ConversationStreamSettings;
  }
}
