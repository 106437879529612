/* RESPONSIBLE TEAM: team-product-setup */

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency-decorators';
import SdkApp from 'embercom/models/sdk-app';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  INSTALLATION_PLATFORMS,
  MOBILE_PLATFORM_NAMES,
  type MobilePlatform,
} from 'embercom/components/installation-new/constants';
import type Store from '@ember-data/store';
import { type TaskGenerator } from 'ember-concurrency';

export default class SdkAppService extends Service {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;

  // NOTE: loadSdkApps task needs to be run before these values can be used
  @tracked iosSdkApp: $TSFixMe;
  @tracked androidSdkApp: $TSFixMe;

  @tracked iosApiKey = '';
  @tracked androidApiKey = '';
  androidApiSecret = '';
  iosApiSecret = '';
  iosGcmKey = '';
  androidGcmKey = '';

  // NOTE: This task needs to be run and completed before the sdk app variables can be used
  @task({ keepLatest: true })
  *loadSdkApps() {
    let [iosSdkApp, androidSdkApp]: [SdkApp, SdkApp] = yield Promise.all([
      SdkApp.findOrCreateForPlatform('iOS'),
      SdkApp.findOrCreateForPlatform('Android'),
    ]);

    this.iosSdkApp = iosSdkApp;
    this.androidSdkApp = androidSdkApp;

    this.iosApiKey = iosSdkApp.api_key;
    this.androidApiKey = androidSdkApp.api_key;
    this.iosApiSecret = iosSdkApp.api_secret;
    this.androidApiSecret = androidSdkApp.api_secret;
    this.iosGcmKey = iosSdkApp.gcm_key;
    this.androidGcmKey = androidSdkApp.gcm_key;
  }

  get loadSdkAppsIsRunning() {
    return taskFor(this.loadSdkApps).isRunning;
  }

  get app() {
    return this.appService.app;
  }

  @dropTask
  *regenerateSdkApp(type: MobilePlatform): TaskGenerator<void> {
    if (!confirm(this.intl.t('apps.app.settings.installation.deletion-confirmation'))) {
      return;
    }

    if (type === INSTALLATION_PLATFORMS.IOS) {
      let iosSdkApp = yield this._regenerateIosSdkApp();
      this.iosApiKey = iosSdkApp.api_key;
      this.iosApiSecret = iosSdkApp.api_secret;
      this.iosGcmKey = iosSdkApp.gcm_key;
      this.iosSdkApp = iosSdkApp;
    } else {
      let androidSdkApp = yield this._regenerateAndroidSdkApp();
      this.androidApiKey = androidSdkApp.api_key;
      this.androidApiSecret = androidSdkApp.api_secret;
      this.androidGcmKey = androidSdkApp.gcm_key;
      this.androidSdkApp = androidSdkApp;
    }
  }

  get isRegenerateSdkAppRunning() {
    return taskFor(this.regenerateSdkApp).isRunning;
  }

  private async _regenerateIosSdkApp() {
    let appToDelete = this.iosSdkApp;
    let currentState = {
      is_disabled: appToDelete.is_disabled,
      identity_verified: appToDelete.identity_verified,
      admin_id: this.app.currentAdmin.id,
      platform: MOBILE_PLATFORM_NAMES.IOS,
    };

    await appToDelete.destroyRecord();
    let iosSdkApp = this.store.createRecord('sdk-app', currentState);
    await iosSdkApp.save();

    return iosSdkApp;
  }

  private async _regenerateAndroidSdkApp() {
    let appToDelete = this.androidSdkApp;
    let currentState = {
      is_disabled: appToDelete.is_disabled,
      identity_verified: appToDelete.identity_verified,
      admin_id: this.app.currentAdmin.id,
      platform: MOBILE_PLATFORM_NAMES.ANDROID,
    };

    await appToDelete.destroyRecord();
    let androidSdkApp = this.store.createRecord('sdk-app', currentState);
    await androidSdkApp.save();

    return androidSdkApp;
  }
}

declare module '@ember/service' {
  interface Registry {
    'sdk-app-service': SdkAppService;
  }
}
