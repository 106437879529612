import Handlebars from 'handlebars';
      const _template = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-test-label-self-serve-percentage class=\"absolute m-auto left-4 top-0 bottom-10\">\n  <div>\n    <span class=\"text-lg align-middle\" style=\"color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ";\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</span>\n    <span class=\"text-base align-middle opacity-50 font-light ml-1 pb-2\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"selfServePercentage") : depth0), depth0))
    + "</span>\n  </div>\n  <div>\n    <span class=\"font-normal\">Resolved by</span>\n    <span class=\"font-bold\">Self-serve</span>\n  </div>\n  <div class=\"font-bold\">support</div>\n</div>\n";
},"useData":true});
      export default _template;