/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    stats: { embedded: 'always' },
  },
  normalize(modelClass, resourceHash) {
    // Ensure that blocks must be an array
    resourceHash.blocks = resourceHash.blocks || [];
    return this._super(modelClass, resourceHash);
  },
});
