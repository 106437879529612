/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

const urlDomainValidation = /.+\..+(\..+)?/;
const queryParameterValidation = /^((?!\?).)*$/;
const noProtocolValidation = /^((?!:\/\/).)*$/;
const protocalMatcher = /(.*\:\/\/)/;
const wildcardValidation = /^(\*\.)?([^*]*)$/;

const uniqueBrandDomainValidator = (value, options, model, attribute) => {
  let otherDomains = model.brand.get('brandDomains').rejectBy('id', model.id);
  let matchingDomain = otherDomains.find(
    (brandDomain) => brandDomain.domain.toLowerCase() === value.toLowerCase(),
  );
  if (matchingDomain) {
    return `You've already listed this domain for this brand`;
  }

  let existingBrandWithDomain = model.brand
    .get('otherBrands')
    .find((brand) => brand.brandDomains.find((brandDomain) => brandDomain.domain === value));
  if (existingBrandWithDomain) {
    return `Your style ${existingBrandWithDomain.brandName} is already using this domain`;
  }

  return true;
};

export default buildValidations({
  domain: {
    validators: [
      validator('format', {
        regex: urlDomainValidation,
        message: 'Enter a valid URL',
      }),
      validator('format', {
        regex: noProtocolValidation,
        message(type, options) {
          let match = options.match(protocalMatcher);
          return match ? `Remove the ${match[0]} from your URL` : null;
        },
      }),
      validator('format', {
        message:
          "Wildcads (*) should only be used at the beginning of the domain, and only for subdomains. For example, '*.example.com' is valid, but 'example.*.com' and '*ple.com' is not.",
        regex: wildcardValidation,
      }),
      validator('format', {
        regex: queryParameterValidation,
        message:
          'Remove the query parameters by deleting the question mark and everything after it',
      }),
      validator('inline', {
        validate: uniqueBrandDomainValidator,
      }),
    ],
  },
});
