/* RESPONSIBLE TEAM: team-messenger */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const DOMAIN_REGEX = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

/**
 * Validator which validates domains
 *
 * Example of valid domains:
 *
 * domain.com
 * example.domain.com
 * example.domain-hyphen.com
 * www.domain.com
 * example.museum
 *
 * Example of invalid domains:
 *
 * http://example.com
 * subdomain.-example.com
 * example.com/parameter
 * example.com?anything
 */
const Domain = BaseValidator.extend({
  validate(value) {
    let invalidMessage = 'Domain must be in the form <second-level>.<top-level> e.g. mysite.com';
    if (isEmpty(value)) {
      return invalidMessage;
    }
    return value.match(DOMAIN_REGEX) ? true : invalidMessage;
  },
});

export default Domain;
