/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const RulesetLinksHaveValidMatchingLocations = BaseValidator.extend({
  validate(value, options, model) {
    if (model.hasControlGroup && model.isTriggeredByCode) {
      return 'Automatic sending must be enabled';
    }
    return true;
  },
});

RulesetLinksHaveValidMatchingLocations.reopenClass({
  getDependentsFor() {
    return ['model.hasControlGroup', 'model.isTriggeredByCode'];
  },
});

export default RulesetLinksHaveValidMatchingLocations;
