/* RESPONSIBLE TEAM: team-ai-agent */
import BaseValidator from 'ember-cp-validations/validators/base';
import type AnswerTerminal from 'embercom/models/operator/visual-builder/step/answer-terminal';
import type CustomAnswerEditorConfig from 'embercom/objects/visual-builder/configuration/editor/custom-answer';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { CHANNEL_EMAIL } from 'embercom/lib/operator/custom-bots/constants';

export default class SendTicketChannelIsValid extends BaseValidator {
  @service declare intl: IntlService;

  validate(_value: unknown, _options: ValidationOptions, model: AnswerTerminal) {
    if (!model.endingType || model.endingType !== 'send_ticket') {
      return true;
    }
    let editorConfig = model.editorConfig as CustomAnswerEditorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }
    let targetChannels = editorConfig.targetChannels;
    if (!targetChannels) {
      return true;
    }

    if (targetChannels.includes(CHANNEL_EMAIL)) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.step-not-supported-by-channel.send-ticket',
        {
          channels: this.intl.t('operator.workflows.channels.type', { channel: CHANNEL_EMAIL }),
        },
      );
    }

    return true;
  }

  static getDependentsFor() {
    return ['model.editorConfig', 'model.editorConfig.targetChannels'];
  }
}
