/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';
import Event from 'embercom/objects/inbox/event';

export default class EventsApi extends Service {
  @service declare session: Session;

  async fetchRecentEventsForUser(
    userId: string,
    opts?: { before: string; timestampEventId: string },
  ): Promise<{ events: Event[]; pageSize: number }> {
    let response = await post(`/ember/events.json`, {
      app_id: this.session.workspace.id,
      intercom_user_id: userId,
      profileType: 'user',
      aggregate: false,
      timeline_type: 'event',
      followed_user_events: false,
      before: opts?.before,
      timestamp_event_id: opts?.timestampEventId,
    });

    return {
      events: response.events.map(Event.deserialize),
      pageSize: response.page_size,
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    eventsApi: EventsApi;
    'events-api': EventsApi;
  }
}
