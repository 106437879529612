/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class BootOptionsService extends Service {
  @tracked embeddedContent = false;

  initFromQueryParams(params: $TSFixMe) {
    if (params.embeddedContent) {
      this.embeddedContent = true;
    }
  }
}
