/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class ArticleSerializer extends EmbercomBaseSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    folder: { key: 'folder_id' },
    stats: { embedded: 'always' },
  };
}
