/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';
import { recurringScheduleFrequencies } from 'embercom/models/data/matching-system/matching-constants';

const CalendarBasedScheduledOffsets = BaseValidator.extend({
  validate(value, options, model) {
    let recurringSchedule = model.recurringSchedule;
    if (isEmpty(recurringSchedule) || recurringSchedule.attributeBased) {
      return true;
    }

    let dayOffset = recurringSchedule.dayOffset;
    let monthOffset = recurringSchedule.monthOffset;
    switch (recurringSchedule.frequency) {
      case recurringScheduleFrequencies.daily:
        return 'Daily fixed schedules are not currently supported';
      case recurringScheduleFrequencies.weekly:
        if (isEmpty(dayOffset) || dayOffset < 0 || dayOffset > 6) {
          return 'A fixed weekly schedule must specify a valid day of the week';
        }
        return true;
      case recurringScheduleFrequencies.monthly:
        if (isEmpty(dayOffset) || dayOffset < 1 || dayOffset > 31) {
          return 'A fixed monthly schedule must specify a valid day of the month';
        }
        return true;
      case recurringScheduleFrequencies.yearly:
        if (
          isEmpty(dayOffset) ||
          dayOffset < 1 ||
          dayOffset > 31 ||
          isEmpty(monthOffset) ||
          monthOffset < 1 ||
          monthOffset > 12
        ) {
          return 'A fixed yearly schedule must specify a valid date';
        }
        return true;
      default:
        return 'Please finish creating your fixed schedule';
    }
  },
});

CalendarBasedScheduledOffsets.reopenClass({
  getDependentsFor() {
    return [
      'model.recurringSchedule',
      'model.recurringSchedule.dayOffset',
      'model.recurringSchedule.monthOffset',
      'model.recurringSchedule.frequency',
    ];
  },
});

export default CalendarBasedScheduledOffsets;
