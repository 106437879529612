/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { map, filterBy, readOnly } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import PredicateFilterMap from 'embercom/models/data/predicate-filter-map';
import { USER_ROLE_PREDICATE, LEAD_ROLE_PREDICATE } from 'embercom/lib/default-predicates';

const COMPARISON_OPTIONS_EXCLUSIONS = [
  'duration_integer',
  'url_visit_integer',
  'url_visit',
  'over_messaging_protection',
];

export default Service.extend({
  appService: service(),
  app: readOnly('appService.app'),

  androidAppVersionOptions: map('app.android_app_versions', function (version) {
    return { text: version, value: version };
  }),

  androidVersionOptions: map('app.android_os_versions', function (version) {
    return { text: version, value: version };
  }),

  androidDeviceOptions: map('app.android_devices', function (device) {
    return { text: device, value: device };
  }),

  iosAppVersionOptions: map('app.ios_app_versions', function (version) {
    return { text: version, value: version };
  }),

  anonymousOptions: [
    { text: 'Users', value: 'false', icon: 'multiple-people' },
    { text: 'Leads', value: 'true', icon: 'multiple-people' },
  ],

  tagOptions: computed('app.segments.[]', function () {
    return this.get('app.segments')
      .rejectBy('is_company', true)
      .rejectBy('is_editable', false)
      .map((segment) => ({
        text: get(segment, 'name'),
        value: get(segment, 'id'),
      }));
  }),

  companyTagOptions: computed('app.segments.[]', function () {
    return this.get('app.segments')
      .rejectBy('is_company', false)
      .rejectBy('is_editable', false)
      .map((segment) => ({
        text: get(segment, 'name'),
        value: get(segment, 'id'),
      }));
  }),

  manualTagOptions: map('app.tags', function (tag) {
    return { text: get(tag, 'name'), value: get(tag, 'id') };
  }),

  iosDeviceOptions: computed(function () {
    return PredicateFilterMap.ios_device.data.map((device) => ({
      text: device.label,
      value: device.value,
      group: device.group,
    }));
  }),

  iosVersionOptions: computed(function () {
    return PredicateFilterMap.ios_version.data.map((device) => ({
      text: device.label,
      value: device.value,
    }));
  }),

  planOptions: map('app.plans', function (plan) {
    return { text: get(plan, 'name'), value: get(plan, 'id') };
  }),

  browserLocaleOptions: map('app.browser_locales', function (locale) {
    return { text: get(locale, 'name'), value: get(locale, 'locale_id') };
  }),

  ownerIdOptions: map('app.admins', function (admin) {
    return { text: get(admin, 'name'), value: get(admin, 'id') };
  }),

  accounts: filterBy('app.accounts', 'deleted', false),

  accountIdOptions: map('accounts', function (account) {
    return { text: get(account, 'name'), value: get(account, 'id') };
  }),

  lastConversationRatingGivenOptions: computed(function () {
    return PredicateFilterMap.last_conversation_rating_given.data.map((conversationRating) => ({
      text: conversationRating.label,
      emoji: conversationRating.emoji,
      value: conversationRating.value,
    }));
  }),
}).reopenClass({
  genericTypes: [
    'tag',
    'numericTag',
    'optionalTag',
    'contentEvent',
    'select',
    'string',
    'numeric',
    'date',
    'boolean',
    'anonymous',
    'role',
    'logical',
    'overMessagingProtection',
    'urlVisit',
    'emailDisengagement',
    'officeHoursSchedule',
    'surveyDropdownQuestion',
    'teamCapacity',
  ],
  tagTypes: ['tag', 'manual_tag', 'company_tag', 'android_device', 'ios_device'],
  numericTagTypes: [
    'android_app_version',
    'android_version',
    'ios_app_version',
    'ios_version',
    'last_conversation_rating_given',
  ],
  optionalTagTypes: ['plan', 'browser_locale', 'owner_id'],
  selectTypes: ['account_id'],
  stringTypes: ['string', 'downcased_string', 'id'],
  numericTypes: [
    'integer',
    'float',
    'user_event',
    'user_event_integer',
    'duration_integer',
    'url_visit_integer',
  ],
  dateTypes: ['date', 'user_event_date'],
  booleanTypes: ['boolean'],
  anonymousTypes: ['anonymous'],
  roleTypes: ['role'],
  logicalTypes: ['and', 'or', 'not'],
  notUserEditable: ['relationship'],
  contentEventTypes: ['content_event'],
  overMessagingProtectionTypes: ['over_messaging_protection'],
  emailDisengagementTypes: ['email_disengagement'],
  urlVisitTypes: ['url_visit'],
  officeHoursScheduleTypes: ['office_hours_schedule'],
  surveyDropdownQuestionTypes: ['survey_dropdown_question'],
  teamCapacityTypes: ['team_capacity'],
  isAnonymousOrRoleType(predicate) {
    return ['anonymous', 'role'].includes(predicate.type);
  },
  removeAnonymousPredicateIfRolePresent(predicates) {
    let rolePredicate = predicates.findBy('type', 'role');
    let anonymousPredicate = predicates.findBy('type', 'anonymous');
    if (rolePredicate && anonymousPredicate) {
      predicates = predicates.removeObjects([rolePredicate, anonymousPredicate]);
      if (anonymousPredicate.comparison === 'true') {
        predicates.pushObject(LEAD_ROLE_PREDICATE);
      } else {
        predicates.pushObject(USER_ROLE_PREDICATE);
      }
    }
    return predicates;
  },
  comparisonOptionsForType(type, genericType) {
    let comparisonOptions = COMPARISON_OPTIONS_EXCLUSIONS.includes(type)
      ? PredicateFilterMap[type]
      : PredicateFilterMap[genericType];
    if (!comparisonOptions) {
      throw new Error(`No comparison option found for type: ${type}`);
    } else {
      return comparisonOptions.comparison;
    }
  },
});
