/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import JSONSerializer from '@ember-data/serializer/json';

export default JSONSerializer.extend({
  primaryKey: '_id',

  serialize(snapshot, options) {
    let json = this._super(...arguments);

    json._isDirty = snapshot.record.get('hasDirtyAttributes');

    return json;
  },
});
