/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import Graph from 'embercom/models/graph-editor/graph';
import { task, taskGroup } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { allNodeConfigurations } from 'embercom/objects/series/configuration-list';
import { toPercentString } from 'embercom/lib/percentage-formatter';
import { next } from '@ember/runloop';
import SeriesNode from 'embercom/models/series/node';
import SeriesEdge from 'embercom/models/series/edge';
import {
  objectNames,
  objectTypes,
  seriesEdgeTypes,
  seriesNodeTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { isNone, isPresent } from '@ember/utils';
import { A } from '@ember/array';
import LoopDetector from 'embercom/lib/series/loop-detector';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import Coordinates from 'embercom/models/graph-editor/coordinates';
import SeriesEdgeGenerator from 'embercom/components/series/edge/generator';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { timeout } from 'ember-concurrency';
import { copy } from 'ember-copy';
import moment from 'moment-timezone';
import SeriesAnnotation from 'embercom/models/series/annotation';
import { pluralize } from 'ember-inflector';
import { editorViews } from 'embercom/models/data/outbound/constants';

const NODE_WIDTH = 192;
const NODE_HEIGHT = 144;

export const CONNECTION_POINTS = [
  { x: -8, y: NODE_HEIGHT / 2, alignment: 'left', direction: 'inwards' },
];

// This maps computed property names to object types when an object type requires a feature flag
// Example: { canUseSeriesWebhooks: objectTypes.outboundWebhook }
const featureFlagMapToOptInObjectTypes = {
  [objectTypes.sms]: ['messages-sms'],
  [objectTypes.whatsapp]: ['whatsapp_outbound_billing_feature'],
};

class TemporaryEdgeObject {
  isTemporary = true;
  edgeType = undefined;
  edgeSplit = undefined;

  constructor(inputs) {
    let { edgeType, edgeSplit } = inputs;
    this.edgeType = edgeType;
    this.edgeSplit = edgeSplit;
  }

  get isPrimary() {
    return this.edgeType === seriesEdgeTypes.primary;
  }

  get isAlternative() {
    return this.edgeType === seriesEdgeTypes.alternative;
  }
}

export default class SeriesEditorService extends Service {
  @service appService;
  @service store;
  @service notificationsService;
  @service intercomEventService;
  @service router;
  @service realTimeEventService;
  @service frontendStatsService;

  @tracked series = undefined;
  @tracked nodes = [];
  @tracked graph = undefined;
  @tracked activeSeriesNode = undefined;
  onChangeActiveSeriesNode = undefined;
  @tracked activeStatsNode = undefined;
  edgeTypeForNewEdge = seriesEdgeTypes.primary;
  seriesObjectType = objectTypes.series;
  @tracked nodesWithErrorsToDisplay = A([]);
  @tracked mode = 'edit';
  onChangeMode = undefined;
  @tracked activeView = 'overview';
  onChangeView = undefined;
  @tracked activeFilter = stats.receipt;
  onChangeFilter = undefined;
  @tracked contentEditorView = 'content';
  @tracked activeSeriesOverviewStat = undefined;

  @tracked isShowingChangelog = false;
  @tracked isHighlightingNewChanges = true;
  @tracked isShowingSettings = false;
  @tracked isEditingTitle = false;
  @tracked isDuplicatingNode = false;
  isShowingShortcuts = false;

  @tracked lastUpdatedNodesAt = undefined;
  @tracked lastUpdatedEdgesAt = undefined;
  @tracked hasNewChanges = false;
  settingsState = undefined;
  @tracked range = null;

  get lastGraphUpdateTime() {
    return this.lastUpdatedNodesAt > this.lastUpdatedEdgesAt
      ? this.lastUpdatedNodesAt
      : this.lastUpdatedEdgesAt;
  }

  @tracked lastRenderedEdgesAt = undefined;

  @taskGroup({ drop: true })
  apiTasks;

  lastReceivedNexusTimestamp = 0;

  /*
   * PUBLIC API
   */

  register(params) {
    this.log(`Registered series id:[${params.series.id}]`);
    if (this.series) {
      throw new Error(
        'Only one series object may be registered at a time in the series editor service',
      );
    } else {
      this.series = params.series;
      this.nodes = params.series.nodes;
      this._initializeGraph();
      this.showErrorsOnAllNodes();
    }
    if (params.mode) {
      this.changeMode(params.mode);
    }
    if (params.view) {
      this.changeActiveView(params.view);
    }
    if (params.filter) {
      this.changeActiveFilter(params.filter);
    }
    if (params.onChangeMode) {
      this.onChangeMode = params.onChangeMode;
    }
    if (params.onChangeView) {
      this.onChangeView = params.onChangeView;
    }
    if (params.onChangeFilter) {
      this.onChangeFilter = params.onChangeFilter;
    }
    if (params.onChangeActiveSeriesNode) {
      this.onChangeActiveSeriesNode = params.onChangeActiveSeriesNode;
    }
    this.pollForNewChanges.perform();
    this.focusNodesIfNotVisible.perform();

    this.realTimeEventService.on('SeriesActivationStatus', this, 'showActivationUpdate');
    this.realTimeEventService.on('SeriesDeactivationStatus', this, 'showDeactivationUpdate');
  }

  unregister(series) {
    if (this.series !== series) {
      throw new Error('Attempted to unregister a series object which was not set');
    } else {
      this.realTimeEventService.off('SeriesActivationStatus', this, 'showActivationUpdate');
      this.realTimeEventService.off('SeriesDeactivationStatus', this, 'showDeactivationUpdate');
      this.series = undefined;
      this.nodes = [];
      this.graph = undefined;
      this.activeSeriesNode = undefined;
      this.activeStatsNode = undefined;
      if (isPresent(this.range)) {
        this.updateRange(undefined);
      }
    }
  }

  registerActiveSeriesNode(node) {
    if (node !== this.activeSeriesNode) {
      this.unregisterActiveStatsNode();
      this.activeSeriesNode = node;
    }
    if (this.onChangeActiveSeriesNode) {
      this.onChangeActiveSeriesNode(node);
    }
  }

  unregisterActiveSeriesNode() {
    this._showErrorsOnNode(this.activeSeriesNode);
    this.activeSeriesNode = undefined;
    if (this.onChangeActiveSeriesNode) {
      this.onChangeActiveSeriesNode(null);
    }
  }

  registerActiveStatsNode(node) {
    if (node !== this.activeStatsNode) {
      this.activeSeriesNode = undefined;
      this.activeStatsNode = node;
    }
  }

  unregisterActiveStatsNode() {
    this.activeStatsNode = undefined;
  }

  closeRulesetEditor() {
    this.set('contentEditorView', 'content');
    this.unregisterActiveSeriesNode();
  }

  canShowErrorOnNode(node) {
    return this.nodesWithErrorsToDisplay.includes(node.id);
  }

  updateRange(range) {
    this.range = range;
    if (isPresent(range)) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'update_date_range',
        object: 'date_range_filter',
        place: objectNames[this.seriesObjectType],
        range_start_date: range.start,
        range_end_date: range.end,
        range_time_zone: range.timezone,
        selected_range: range.selectedRange,
      });
    }
  }

  get nodeConfigurations() {
    let enabledNodeConfigurations = allNodeConfigurations;
    Object.keys(featureFlagMapToOptInObjectTypes).forEach((objectType) => {
      let hasAccessToRestrictedFeature = featureFlagMapToOptInObjectTypes[objectType].any(
        (feature) => this.appService.app.canUseFeature(feature),
      );
      if (!hasAccessToRestrictedFeature) {
        enabledNodeConfigurations = enabledNodeConfigurations.filter(
          (config) => config.inserterConfiguration.objectType !== Number(objectType),
        );
      }
    });
    return enabledNodeConfigurations;
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  get isEditMode() {
    return !this.isViewMode;
  }

  get isReportingView() {
    return this.activeView === editorViews.reporting;
  }

  get shortcutsDisabled() {
    return (
      this.isEditingTitle ||
      this.isShowingSettings ||
      this.isDuplicatingNode ||
      this.showRulesetEditor
    );
  }

  get hasPaywalledNodes() {
    return this.nodes.any(
      (node) =>
        !node.graphConfiguration.componentConfig.hasAccessToRequiredFeature(this.appService.app),
    );
  }

  get canHighlightNewChanges() {
    return (
      this.isViewMode &&
      this.series.isLive &&
      this.series.latestChangelog?.hasNewChanges &&
      this.series.latestChangelog?.editedBy.id !== this.appService.app.currentAdmin.id &&
      this.isHighlightingNewChanges
    );
  }

  @task({ restartable: true })
  *updateRenderedEdges() {
    yield timeout(ENV.APP._250MS);
    this.lastRenderedEdgesAt = new Date();
  }

  @task({ group: 'apiTasks' })
  *save() {
    if (this.isViewMode) {
      throw new Error('Cannot save a Series in view mode');
    }
    try {
      yield this._save.perform();
      this.intercomEventService.trackEvent('series-saved');
      this.notificationsService.notifyConfirmation('Your Series has been updated.');
      return true;
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: 'Error saving the Series',
      });
      return false;
    }
  }

  @task
  *saveAndClose() {
    let success = yield this.save.perform();
    if (success) {
      this.changeMode('view');
    }
  }

  @task({ group: 'apiTasks' })
  *saveAndSetLive() {
    this.showErrorsOnAllNodes();
    yield this._save.perform();
    this.notificationsService.notifyConfirmation(
      'Beginning setting live, this might take a minute...',
      6000,
      'series-state-change-report',
    );
    yield this.series.activate();
    this.notificationsService.removeNotification('series-state-change-report');
    this.lastReceivedNexusTimestamp = moment().unix();
    this.notificationsService.notifyConfirmation('Your Series has been set live.');
    this.changeMode('view');
    this.intercomEventService.trackEvent('series-set-live');
  }

  showActivationUpdate(event) {
    if (this.lastReceivedNexusTimestamp > event.timestamp) {
      return;
    }
    if (Number(event.seriesId) === Number(this.series.id)) {
      let notificationText = '';
      if (event.eventType === 'validating') {
        notificationText = '(0%) Validating your Series...';
      } else if (event.eventType === 'activating') {
        let title = this.series.nodes.find(
          (node) => node.rulesetId === event.currentRulesetId,
        ).title;
        let percentage = toPercentString(event.progress * 100);
        notificationText = `(${percentage}) Activating '${title}'`;
      } else if (event.eventType === 'processing') {
        notificationText = `(100%) Finishing up...`;
      }
      this.notificationsService.removeNotification('series-state-change-report');
      this.lastReceivedNexusTimestamp = event.timestamp;
      this.notificationsService.notifyConfirmation(
        notificationText,
        4000,
        'series-state-change-report',
      );
    }
  }

  showDeactivationUpdate(event) {
    if (this.lastReceivedNexusTimestamp > event.timestamp) {
      return;
    }
    if (Number(event.seriesId) === Number(this.series.id)) {
      let notificationText = '';
      if (event.eventType === 'validating') {
        notificationText = '(0%) Validating your Series...';
      } else if (event.eventType === 'deactivating') {
        let title = this.series.nodes.find(
          (node) => node.rulesetId === event.currentRulesetId,
        ).title;
        let percentage = toPercentString(event.progress * 100);
        notificationText = `(${percentage}) Deactivating '${title}'`;
      } else if (event.eventType === 'processing') {
        notificationText = `(100%) Finishing up...`;
      }
      this.notificationsService.removeNotification('series-state-change-report');
      this.lastReceivedNexusTimestamp = event.timestamp;
      this.notificationsService.notifyConfirmation(
        notificationText,
        10000,
        'series-state-change-report',
      );
    }
  }

  @task({ group: 'apiTasks' })
  *deactivate() {
    this.notificationsService.notifyConfirmation(
      'Beginning pausing, this might take a minute...',
      6000,
      'series-state-change-report',
    );
    yield this.series.deactivate();
    this.lastReceivedNexusTimestamp = moment().unix();
    this.notificationsService.removeNotification('series-state-change-report');
    this.notificationsService.notifyConfirmation('Your Series has been paused.');
  }

  @task({ group: 'apiTasks' })
  *createNode(graphNode, inserterConfig) {
    try {
      let newNode = yield SeriesNode.createNode({
        store: this.store,
        app: this.appService.app,
        series: this.series,
        objectType: inserterConfig.objectType,
        objectData: inserterConfig.objectData,
        defaultParams: graphNode.dataObject?.defaultParams,
      });
      newNode.xPosition = graphNode.position.x;
      newNode.yPosition = graphNode.position.y;

      let originalDataObject = graphNode.dataObject;

      graphNode.dataObject = newNode;

      if (originalDataObject.hasTemporaryEdge) {
        this._replaceTemporaryEdge(graphNode);
      }
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: "Something went wrong and we couldn't create that block",
      });
      this.graph.deleteNode(graphNode);
    }
  }

  @task({ group: 'apiTasks' })
  *duplicateNodes(graphNodes) {
    if (this.isViewMode) {
      throw new Error('Cannot duplicate in view mode');
    }

    //TODO: @RuairiK make duplication work for annotations
    graphNodes = graphNodes.filter(
      (graphNode) => graphNode.dataObject.constructor.modelName === 'series/node',
    );

    let newSeriesNodes = yield SeriesNode.duplicateCollection({
      nodes: graphNodes.map((graphNode) => graphNode.dataObject),
      store: this.store,
      app: this.appService.app,
    });
    let newGraphNodes;
    this.graph.trackActions(() => {
      newGraphNodes = graphNodes.map((graphNode) => this._graphNodeDuplicate(graphNode));

      if (newGraphNodes.length !== newSeriesNodes.length) {
        throw new Error('Unexpected error while duplicating');
      }

      for (let i = 0; i < newGraphNodes.length; i++) {
        newSeriesNodes[i].xPosition = newGraphNodes[i].position.x;
        newSeriesNodes[i].yPosition = newGraphNodes[i].position.y;
        newGraphNodes[i].dataObject = newSeriesNodes[i];
      }

      let originalNodeSet = new Set(graphNodes.mapBy('dataObject'));
      let newNodeMapping = newSeriesNodes.reduce((mapping, node) => {
        mapping[node.duplicatedFromNode.id] = node;
        return mapping;
      }, {});
      let edgesToDuplicate = this.series.edges.filter((edge) => {
        return originalNodeSet.has(edge.predecessor) && originalNodeSet.has(edge.successor);
      });

      edgesToDuplicate.forEach((edge) => {
        let newEdge = SeriesEdge.createEdge({
          store: this.store,
          series: this.series,
          predecessor: newNodeMapping[edge.predecessor.id],
          successor: newNodeMapping[edge.successor.id],
          edgeSplit: edge.edgeSplit,
          edgeType: edge.edgeType,
        });
        next(this, () => this._addEdge(newEdge));
      });

      this.graph.state.selectedNodes.clear();
      newGraphNodes.map((node) => this.graph.state.addSelectedNode(node));
    });

    return newGraphNodes;
  }

  @task
  *_save() {
    this._correctMismatchedCoordinates();
    // we need to fetch these first because series.save() unloads them
    let locallyCreatedEdgeNodePairs = this._getLocallyCreatedEdgeNodePairs();
    this.graph.clearRecordedActions();
    if (this.series.isLive) {
      this.showErrorsOnAllNodes();
    }

    yield this.series.save();
    this._reassociatePersistedAnnotationsWithGraph();
    this._associateSeriesEdgesWithGraph(locallyCreatedEdgeNodePairs);
  }

  _correctMismatchedCoordinates() {
    //if the series node has different coordinates to the graph node, it implies there is a bug that has resulted in us not calling the didUpdateNode hook correctly.
    //Calling it here ensures that the saved node positions are what the teammate is currently seeing.
    if (
      this.graph.nodes.some((node) => {
        node.position.x !== node.dataObject.xPosition ||
          node.position.y !== node.dataObject.yPosition;
      })
    ) {
      this.frontendStatsService.enqueueInteractionMetric({
        name: 'mismatched_series_node_coordinates',
      });
      this.frontendStatsService.sendInteractionMetrics();
    }
    this.graph.nodes.forEach((node) => {
      if (
        node.position.x !== node.dataObject.xPosition ||
        node.position.y !== node.dataObject.yPosition
      ) {
        this._didUpdateNode(node);
      }
    });
  }

  _reassociatePersistedAnnotationsWithGraph() {
    let persistedAnnotations = this.series.annotations.filter((annotation) =>
      isPresent(annotation.id),
    );

    let annotationGraphNodes = this.graph.nodes.filter(
      (node) => node.dataObject.constructor.modelName === 'series/annotation',
    );
    if (persistedAnnotations.length !== annotationGraphNodes.length) {
      throw new Error('Unexpected error while saving');
    }

    for (let i = 0; i < annotationGraphNodes.length; i++) {
      annotationGraphNodes[i].dataObject = persistedAnnotations[i];
    }

    this.series.annotations
      .filter((annotation) => isNone(annotation.id))
      .map((annotation) => this.store.unloadRecord(annotation));
  }

  @task({ group: 'apiTasks' })
  *duplicate() {
    try {
      let duplicatedSeries = yield this.series.duplicate();
      this.router.transitionTo('apps.app.outbound.series.redirect-to.series', duplicatedSeries);
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: "Something went wrong and we couldn't duplicate the series.",
      });
    }
  }

  @task({ group: 'apiTasks' })
  *delete() {
    try {
      yield this.series.archive();
      this.router.transitionTo('apps.app.outbound.series.index');
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: "Something went wrong and we couldn't delete the series.",
      });
    }
  }

  @task({ group: 'apiTasks' })
  *updateSettings() {
    try {
      yield this.series.updateSettings();
      this.notificationsService.notifyConfirmation('Your settings have been updated.');
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: "Something went wrong and we couldn't update the series.",
      });
    }
  }

  @task({ group: 'apiTasks' })
  *cancelSettingsChanges() {
    yield this.series.cancelSettingsChanges();
  }

  @task
  *pollForNewChanges() {
    if (this.isEditMode) {
      this.hasNewChanges = yield this.series.hasNewChanges();
    }
    if (Ember.testing) {
      return;
    }
    yield timeout(ENV.APP._1M).then(() => {
      this.pollForNewChanges.perform();
    });
  }

  get showRulesetEditor() {
    return this.activeSeriesNode !== undefined;
  }

  storeSettingsState() {
    let currentGoal = copy(this.series.goal);
    let currentExitBehavior = this.series.exitBehavior;
    let currentExitPredicateGroup = copy(this.series.exitPredicateGroup);
    let currentCompanyPrioritizer = copy(this.series.companyPrioritizer);

    this.settingsState = {
      goal: currentGoal,
      exitBehavior: currentExitBehavior,
      exitPredicateGroup: currentExitPredicateGroup,
      companyPrioritizer: currentCompanyPrioritizer,
    };
  }

  changeMode(mode) {
    this.mode = mode;

    this.graph.state.inPanningMode = this.isViewMode;
    this.graph.state.disableScroll = this.isViewMode;

    if (this.onChangeMode) {
      this.onChangeMode(mode);
    }
  }

  changeActiveView(newView) {
    if (this.activeView === newView) {
      return;
    }
    if (!this.isEditMode) {
      this.activeView = newView;
      if (this.onChangeView) {
        this.onChangeView(newView);
      }
    } else {
      throw new Error('Attempted to change active view while in edit mode');
    }
  }

  changeActiveFilter(filter) {
    if (this.activeFilter === filter) {
      return;
    }
    if (!this.isEditMode) {
      this.activeFilter = filter;
      if (this.onChangeFilter) {
        this.onChangeFilter(filter);
      }
    } else {
      throw new Error('Attempted to change active filter while in edit mode');
    }
  }

  addNode(node) {
    return this._addNode(node);
  }

  addEdge(edge) {
    this._addEdge(edge);
  }

  createGraphEdge(predecessor, successor) {
    if (this.graph.hooks.canInsertEdge(predecessor, successor)) {
      let edgeDataObject = { edgeType: seriesEdgeTypes.primary };
      let edge = this.graph.addEdge({
        predecessor,
        successor,
        dataObject: edgeDataObject,
      });
      this.graph.hooks.didInsertEdge(edge);
    }
  }

  showErrorsOnAllNodes() {
    this.nodesWithErrorsToDisplay = A(this.series.nodes.map((node) => node.id));
  }

  @task
  *focusNodesIfNotVisible() {
    yield timeout(ENV.APP._500MS);
    if (isPresent(this.graph.nodes) && !this.graph.state.hasVisibleNodes) {
      this.graph.focusNode(this.graph.nodes.firstObject);
    }
  }

  /*
   * PRIVATE
   */

  _graphNodeDuplicate(graphNode) {
    let spacing = this.graph.settings.grid.spacing;
    let position = this.graph.findClosestFreeCoordinates(
      new Coordinates(
        graphNode.position.x + spacing,
        graphNode.position.y + this.graph.settings.node.defaultHeight + spacing * 2,
      ),
    );
    return this.graph.addNode({ position, connectionPoints: CONNECTION_POINTS });
  }

  _initializeGraph() {
    this.graph = new Graph({
      canUpdateNode: this._canUpdateNode.bind(this),
      didUpdateNode: this._didUpdateNode.bind(this),
      canInsertNode: this._canInsertNode.bind(this),
      didInsertNode: this._didInsertNode.bind(this),
      canSelectNode: this._canSelectNode.bind(this),
      canInsertEdge: this._canInsertEdge.bind(this),
      didInsertEdge: this._didInsertEdge.bind(this),
      canDeleteEdge: this._canDeleteEdge.bind(this),
      didDeleteEdge: this._didDeleteEdge.bind(this),
      canDeleteNode: this._canDeleteNode.bind(this),
      didDeleteNode: this._didDeleteNode.bind(this),
      canAddNodesToSelection: this._canAddNodesToSelection.bind(this),
      undeleteNode: this._undeleteNode.bind(this),
      undeleteEdge: this._undeleteEdge.bind(this),
    });

    this.graph.settings.scale.zoomFactor = 0.2;
    this.graph.settings.grid.spacing = 16;
    this.graph.settings.node.defaultWidth = NODE_WIDTH;
    this.graph.settings.node.defaultHeight = NODE_HEIGHT;
    this.graph.settings.node.defaultSpacing = NODE_WIDTH * 2;

    this.series.nodes.forEach((node) => {
      this._addNode(node);
    });

    this.series.annotations.forEach((annotation) => {
      this._addAnnotation(annotation);
    });

    this.series.edges.forEach((edge) => {
      this._addEdge(edge);
    });
  }

  _addNode(node) {
    let position = this.graph.findClosestFreeCoordinates(
      new Coordinates(node.xPosition, node.yPosition),
    );
    return this.graph.addNode({
      position,
      connectionPoints: CONNECTION_POINTS,
      dataObject: node,
    });
  }

  _addEdge(edge) {
    let predecessor = this.graph.nodeForDataObject(edge.predecessor);
    let successor = this.graph.nodeForDataObject(edge.successor);
    let graphEdge = this.graph.addEdge({
      predecessor,
      successor,
      dataObject: edge,
    });
    graphEdge.generator = new SeriesEdgeGenerator(graphEdge);
  }

  _addAnnotation(annotation) {
    let position = this.graph.findClosestFreeCoordinates(
      new Coordinates(annotation.xPosition, annotation.yPosition),
    );
    return this.graph.addNode({
      position,
      connectionPoints: [],
      dataObject: annotation,
    });
  }

  async _createSeriesNodeOrAnnotation(graphNode) {
    if (graphNode.dataObject.isAnnotation) {
      let annotation = this.store.createRecord('series/annotation', {
        series: this.series,
        lastEditedBy: this.appService.app.currentAdmin,
      });
      annotation.xPosition = graphNode.position.x;
      annotation.yPosition = graphNode.position.y;
      graphNode.dataObject = annotation;
    } else {
      let inserterConfig = graphNode.dataObject.inserterConfig;
      await this.createNode.perform(graphNode, inserterConfig);
    }
  }

  _canUpdateNode(node, offset) {
    if (
      this._withinBounds(node.position.x, node.position.y) &&
      !this._withinBounds(node.position.x + offset.x, node.position.y + offset.y)
    ) {
      return false;
    }

    return this.isEditMode;
  }

  _withinBounds(x, y) {
    let grid = this.graph.settings.grid;
    return -grid.width <= x && x <= grid.width * 2 && -grid.height <= y && y <= grid.height * 2;
  }

  _didUpdateNode(node) {
    let seriesNode = node.dataObject;
    seriesNode.xPosition = node.position.x;
    seriesNode.yPosition = node.position.y;
    this.lastUpdatedNodesAt = new Date();
  }

  _canInsertNode(dataObject) {
    if (this.isViewMode) {
      return false;
    }
    if (dataObject.isAnnotation) {
      return true;
    }
    return dataObject.inserterConfig.canInsertNode({ app: this.appService.app, dataObject });
  }

  _canSelectNode() {
    return this.isEditMode;
  }

  async _didInsertNode(graphNode) {
    let hasOverlap = false;
    do {
      hasOverlap = this.graph.nodes.some(
        (node) =>
          node.position.x === graphNode.position.x &&
          node.position.y === graphNode.position.y &&
          node !== graphNode,
      );
      if (hasOverlap) {
        graphNode.position.x += this.graph.settings.grid.spacing;
        graphNode.position.y += this.graph.settings.grid.spacing;
      }
    } while (hasOverlap);

    await this._createSeriesNodeOrAnnotation(graphNode);

    // If we had created edges on the graph before the Series Node had been created and
    // persisted, we must update the successor references on those edges to the real data object.
    if (isPresent(graphNode.inwardEdges)) {
      graphNode.inwardEdges.forEach((edge) => {
        edge.dataObject.successor = graphNode.dataObject;
      });
    }
    this.lastUpdatedNodesAt = new Date();
  }

  _canInsertEdge(predecessor, successor) {
    if (this.isViewMode) {
      return false;
    }

    if (successor.dataObject instanceof SeriesAnnotation) {
      return false;
    }

    // In the case where we're directly inserting a shortcut we will allow this through.
    // We handle this here as we will return false with the next check otherwise.
    if (successor.dataObject.hasTemporaryEdge) {
      return true;
    }

    if (successor.dataObject.isNewlyInserted) {
      return false;
    }

    if (predecessor === successor) {
      return false;
    }

    let predecessorSeriesNode = predecessor.dataObject;
    let successorSeriesNode = successor.dataObject;

    if (isNone(successorSeriesNode.id)) {
      throw new Error(`Cannot insert edge. Successor is missing ID`);
    }

    if (this._doesCreateLoop(predecessorSeriesNode, successorSeriesNode)) {
      debounce(this, this._notifyInfiniteLoopError, ENV.APP._750MS, true);
      return false;
    }

    if (
      predecessorSeriesNode.isStarting &&
      predecessorSeriesNode.objectTypes.includes(objectTypes.seriesCondition) &&
      this.edgeTypeForNewEdge === seriesEdgeTypes.alternative
    ) {
      return false;
    }

    let existingEdge = predecessorSeriesNode.outwardEdges.find(
      (edge) => edge.successor === successorSeriesNode && edge.edgeType === this.edgeTypeForNewEdge,
    );

    return isNone(existingEdge);
    // TODO: Check with predecessor & successor series node objects if they can connect with each other
  }

  _didInsertEdge(edge) {
    edge.generator = new SeriesEdgeGenerator(edge);

    // For temporary edges, we will defer calling this method until
    // the node we're inserting has been persisted on the backend.
    if (edge.dataObject?.isTemporary) {
      edge.dataObject = new TemporaryEdgeObject({
        edgeType: edge.dataObject.edgeType,
        edgeSplit: edge.dataObject.edgeSplit,
      });
      return;
    }

    let predecessorSeriesNode = edge.predecessor.dataObject;
    let successorSeriesNode = edge.successor.dataObject;

    // Once the edge is inserted remove predecessor and successor
    // nodes from `nodesWithErrorsToDisplay` list. This makes sure
    // that if the Nodes had validation errors for edge connections
    // they are cleared once the edge is connected
    this._removeNodeFromErrorsToDisplay(predecessorSeriesNode);
    this._removeNodeFromErrorsToDisplay(successorSeriesNode);

    let edgeType = isPresent(edge.dataObject?.edgeType)
      ? edge.dataObject.edgeType
      : this.edgeTypeForNewEdge;

    edge.dataObject = SeriesEdge.createEdge({
      store: this.store,
      series: this.series,
      predecessor: predecessorSeriesNode,
      successor: successorSeriesNode,
      edgeSplit: edge.dataObject?.edgeSplit,
      edgeType,
    });

    if (this.series.isLive && predecessorSeriesNode.completedCheckpointCount > 0) {
      this._warnAddingNewNodesToLiveSeries(predecessorSeriesNode, successorSeriesNode);
    }

    successorSeriesNode.nodeType = seriesNodeTypes.internal;
    this.lastUpdatedEdgesAt = new Date();
  }

  _warnAddingNewNodesToLiveSeries(predecessor, successor) {
    this.notificationsService.notify(
      `${pluralize(predecessor.completedCheckpointCount, 'person')} who ${pluralize(
        predecessor.completedCheckpointCount,
        'has',
        {
          withoutCount: true,
        },
      )} already completed the '${predecessor.title}' block will not enter the newly connected '${
        successor.title
      }' block.`,
      ENV.APP._1S * 20,
      'connecting-to-a-live-node',
    );
  }

  _canDeleteEdge() {
    return this.isEditMode;
  }

  _didDeleteEdge(edge) {
    this.series.deletedEdgeIds.push(edge.dataObject.id);
    this.store.unloadRecord(edge.dataObject);
    this.lastUpdatedEdgesAt = new Date();
  }

  _canDeleteNode() {
    return this.isEditMode;
  }

  _didDeleteNode(node) {
    this.series.deletedNodeIds.push(node.dataObject.id);
    this.store.unloadRecord(node.dataObject);
  }

  _canAddNodesToSelection(event) {
    return event?.shiftKey || event?.metaKey;
  }

  _doesCreateLoop(predecessorSeriesNode, successorSeriesNode) {
    let newEdgeList = this.series.edges.map((edge) => {
      return { predecessor: edge.predecessor.id, successor: edge.successor?.id };
    });
    newEdgeList.push({ successor: successorSeriesNode.id, predecessor: predecessorSeriesNode.id });

    return new LoopDetector(newEdgeList).detectLoop();
  }

  _notifyInfiniteLoopError() {
    this.notificationsService.notifyError(
      'This connection would create an infinite loop',
      ENV.APP._2000MS,
    );
  }

  /*
   * When creating a Rules block from a shortcut in the UI, we must wait for the server request for to complete
   * before we have the ID of the successor node for us to actually create an edge. While that request is happening
   * we create a 'temporary edge' in the UI. This is an graph edge that links the two graph nodes but does not
   * have an associated Series edge model as its dataObject.
   *
   * When the server request completes, we upgrade this temporary edge to a real edge by calling the _didInsertEdge
   * handler. This will then create the Series edge model we require.
   */
  _replaceTemporaryEdge(graphNode) {
    let temporaryEdges = this.graph.edges.filter((edge) => edge.dataObject?.isTemporary);
    let temporaryEdge = temporaryEdges.find((edge) => edge.successor === graphNode);
    if (isPresent(temporaryEdge)) {
      temporaryEdge.dataObject.isTemporary = false;
      this._didInsertEdge(temporaryEdge);
    } else {
      throw new Error(`Expected a temporary edge to be present`);
    }
  }

  _getLocallyCreatedEdgeNodePairs() {
    let locallyCreatedEdges = this.series.locallyCreatedEdges;
    return locallyCreatedEdges.map((locallyCreatedEdge) => {
      return {
        predecessor: locallyCreatedEdge.predecessor,
        successor: locallyCreatedEdge.successor,
      };
    });
  }

  _associateSeriesEdgesWithGraph(edgeNodePairs) {
    edgeNodePairs.forEach((edgeNodePair) => {
      let { predecessor, successor } = edgeNodePair;
      let seriesEdge = this.series.edgeForNodes(predecessor, successor);
      let graphEdge = this.graph.edgeForNodeDataObjects(predecessor, successor);
      graphEdge.dataObject = seriesEdge;
    });
  }

  _showErrorsOnNode(node) {
    this.nodesWithErrorsToDisplay.pushObject(node.id);
  }

  _removeNodeFromErrorsToDisplay(node) {
    this.nodesWithErrorsToDisplay.removeObject(node.id);
  }

  log(message) {
    if (ENV.environment !== 'production' || window.LOG_CONTENT_EDITOR_SERVICE) {
      console.info(`📝[Series Editor Service] ${message}`);
    }
  }

  _undeleteNode(serializedNode) {
    this.series.deletedNodeIds = this.series.deletedNodeIds.filter(
      (id) => id !== serializedNode.id,
    );
    let undeletedNode = this.store.push(this.store.normalize('series/node', serializedNode));
    return this.series.nodes.pushObject(undeletedNode);
  }

  _undeleteEdge(serializedEdge) {
    let undeletedEdge;
    if (serializedEdge.id) {
      this.series.deletedEdgeIds = this.series.deletedEdgeIds.filter(
        (id) => id !== serializedEdge.id,
      );
      undeletedEdge = this.store.push(this.store.normalize('series/edge', serializedEdge));
    } else {
      undeletedEdge = SeriesEdge.createEdgeFromSerializedData({
        store: this.store,
        data: serializedEdge,
      });
    }
    return this.series.edges.pushObject(undeletedEdge);
  }
}
