/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isEmpty } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';
import moment from 'moment-timezone';

const MessageStartAtStopAtIsValid = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isNotDraft') && model.get('isNotScheduled')) {
      return true; // Pass if not a draft or scheduled message
    }

    if (isEmpty(value) || isEmpty(model.get('dueToStopAt'))) {
      return true; // Pass if either date is null
    }

    if (moment(value) >= moment(model.get('dueToStopAt'))) {
      return 'Choose a start date that is before the stop date';
    }

    return true;
  },
});

MessageStartAtStopAtIsValid.reopenClass({
  getDependentsFor() {
    return ['model.isNotDraft', 'model.isNotScheduled', 'model.dueToStopAt'];
  },
});

export default MessageStartAtStopAtIsValid;
