/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';

export default buildValidations({
  count: {
    description: 'Audience count',
    validators: [validator('number', { allowBlank: true, positive: true })],
  },
  predicates: {
    description: 'Predicates',
    validators: [validator('presence', true), validator('has-many')],
  },
  hasUntrackedPredicates: {
    validators: [
      validator('inline', {
        validate(value) {
          return value ? 'Integrate untracked attributes' : true;
        },
      }),
    ],
  },
});
