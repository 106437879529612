/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  carouselScreens: {
    description: 'Carousel Screens',
    validators: [
      validator('has-many'),
      validator('length', {
        min: 1,
        max: 10,
        message: 'You can have at most 10 carousel screens',
      }),
    ],
  },
});
