/* RESPONSIBLE TEAM: team-messenger */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class ReportSubscriptionService extends Service {
  @tracked subscription;
  @service store;
  @service appService;

  @task *retrieveSubscription(subscriptionId) {
    if (isPresent(subscriptionId)) {
      try {
        this.subscription = yield this.store.findRecord(
          'reporting/report-subscription',
          subscriptionId,
        );
      } catch (err) {
        this.subscription = this.createNewDefaultSubscription();
        return err.jqXHR.status;
      }
    } else {
      this.subscription = this.createNewDefaultSubscription();
    }
    return 200;
  }

  rollbackSubscriptionChanges() {
    this.subscription.rollbackAttributes();
    if (this.subscription.isDeleted) {
      this.subscription = this.createNewDefaultSubscription();
    }
  }

  resetSubscription() {
    this.subscription = this.createNewDefaultSubscription();
  }

  createNewDefaultSubscription() {
    return this.store.createRecord('reporting/report-subscription', {
      teammateIds: [],
      cadence: 'weekly',
      cadenceTimeOfDay: 8,
      cadenceDayOfWeek: 'monday',
      cadenceDateOfMonth: 1,
      jsonBlob: {},
    });
  }
}
