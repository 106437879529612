/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { WILDCARD_TITLE_SEARCH } from 'embercom/models/data/outbound/constants';
import { inject as service } from '@ember/service';
import { isPresent, isBlank } from '@ember/utils';
import {
  matchBehaviors,
  objectTypes,
  roleTypes,
  states,
  objectsWithPriority,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { LEGACY_TYPES } from 'embercom/lib/outbound/constants';
import { NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import { IN_OPERATOR, DEFAULT_COLUMN_LIST } from 'embercom/lib/outbound/constants';
import { post } from 'embercom/lib/ajax';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';

export default class OutboundHomeService extends Service {
  @service store;
  @service appService;
  @service notificationsService;
  // Available filters
  @tracked selectedAudienceValues = [];
  @tracked selectedStateValue = null;
  @tracked selectedSenderValue = null;
  @tracked selectedTagOperator = IN_OPERATOR;
  @tracked selectedTagValues = null;
  @tracked selectedSubscriptionTypeValue = null;
  @tracked selectedMatchBehaviorValues = null;
  @tracked isDeliverabilityReport = false;
  @tracked additionalSearchableData = null;
  @tracked searchString = null;
  @tracked selectedObjectTypes = null;
  @tracked lastSelectedContentTypes = [];
  @tracked lastSelectedContentType = [];
  @tracked predicates = null;
  @tracked hideSeries = null;
  @tracked view = null;
  isFirstRequest = true;
  @tracked activeList = null;
  @tracked selectedTableColumns = DEFAULT_COLUMN_LIST;

  get app() {
    return this.appService.app;
  }

  get viewsFilterQuery() {
    let query = {};
    if (isPresent(this.selectedAudienceValues)) {
      query.audiences = this.selectedAudienceValues.join(',');
    }
    if (isPresent(this.selectedStateValue)) {
      query.state = String(this.selectedStateValue);
    }
    if (isPresent(this.selectedSenderValue)) {
      query.senderId = this.selectedSenderValue;
    }
    if (isPresent(this.selectedTagValues)) {
      query.tagIds = this.selectedTagValues.join(',');
    }
    if (this.selectedTagOperator !== IN_OPERATOR) {
      query.tagOperator = this.selectedTagOperator;
    }
    if (isPresent(this.selectedSubscriptionTypeValue)) {
      query.subscriptionTypeId = this.selectedSubscriptionTypeValue;
    }
    if (isPresent(this.selectedMatchBehaviorValues)) {
      query.matchBehaviors = this.selectedMatchBehaviorValues;
    }
    if (isPresent(this.searchString)) {
      query.searchString = this.searchString;
    }
    if (isPresent(this.selectedObjectTypes)) {
      query.selectedObjectTypes = this.selectedObjectTypes.join(',');
    }
    if (isPresent(this.predicates)) {
      query.predicates = this.predicates;
    }
    if (isPresent(this.hideSeries)) {
      query.hideSeries = String(this.hideSeries);
    }
    return query;
  }

  get viewsColumns() {
    if (this.selectedTableColumns.toString() === DEFAULT_COLUMN_LIST.toString()) {
      return null;
    }
    return this.selectedTableColumns;
  }

  get allObjectTypes() {
    let types = [
      objectTypes.legacyMessageInApp,
      objectTypes.legacyMessageEmail,
      objectTypes.legacyMessagePush,
      objectTypes.push,
      objectTypes.tour,
      objectTypes.post,
      objectTypes.chat,
      objectTypes.carousel,
      objectTypes.email,
      objectTypes.customBot,
      objectTypes.banner,
      objectTypes.sms,
      objectTypes.survey,
      objectTypes.newsItem,
      objectTypes.checklist,
      objectTypes.tooltipGroup,
    ];

    if (this.app.canUseFeature('whatsapp_outbound_billing_feature')) {
      types.push(objectTypes.whatsapp);
    }

    return types;
  }

  get contentToDisplay() {
    let contentToDisplay = [
      // Proactive Essential
      objectTypes.chat,
      objectTypes.banner,
      objectTypes.tooltipGroup,

      // Advanced Proactive
      objectTypes.post,
      objectTypes.email,
      objectTypes.push,
      objectTypes.tour,
      objectTypes.checklist,
      objectTypes.sms,
      objectTypes.survey,
      objectTypes.carousel,
      objectTypes.customBot,
    ];
    if (this.app.canUseNews) {
      contentToDisplay.push(objectTypes.newsItem);
    }

    if (this.app.canUseFeature('whatsapp_outbound_billing_feature')) {
      contentToDisplay.push(objectTypes.whatsapp);
    }
    return contentToDisplay;
  }

  get rulesetBasedObjectTypes() {
    return this.allObjectTypes.reject(
      (objectType) =>
        objectType === objectTypes.legacyMessageInApp ||
        objectType === objectTypes.legacyMessageEmail ||
        objectType === objectTypes.legacyMessagePush,
    );
  }

  get selectedTabIsOngoing() {
    return (
      this.selectedMatchBehaviorValues?.length === 2 &&
      this.selectedMatchBehaviorValues.includes(matchBehaviors.single) &&
      this.selectedMatchBehaviorValues.includes(matchBehaviors.multi)
    );
  }

  get selectedTabIsOneOff() {
    return (
      this.selectedMatchBehaviorValues?.length === 1 &&
      this.selectedMatchBehaviorValues[0] === matchBehaviors.static
    );
  }

  get selectedStateIsLiveOrSent() {
    return this.selectedStateValue === states.live;
  }

  get selectedStateIsPaused() {
    return this.selectedStateValue === states.paused;
  }

  get selectedAudienceContainsVisitors() {
    return this.selectedAudienceValues.includes(roleTypes.visitorRole);
  }

  get isPrioritizable() {
    return objectsWithPriority.includes(this.activeList);
  }

  get savedViewsScope() {
    return Scope.OUTBOUND;
  }

  setSearchParams(params) {
    Object.keys(params).forEach((key) => {
      this.set(key, params[key]);
    });
  }

  setActiveList(currentList) {
    this.activeList = currentList;

    if (this.activeList === objectTypes.tour) {
      // We don't support one-off tours so we don't have one-off / ongoing tabs for tours.
      this.selectedMatchBehaviorValues = this.selectedMatchBehaviorValues
        ? [matchBehaviors.single, matchBehaviors.multi]
        : null;
    } else if (this.activeList === objectTypes.banner) {
      // Banners don't currently fit in the ongoing  and one off world, as their matching behavior is multi
      this.selectedMatchBehaviorValues = this.selectedMatchBehaviorValues
        ? [matchBehaviors.multi]
        : null;
    } else if (
      this.activeList === objectTypes.inboundCustomBot ||
      this.activeList === objectTypes.buttonCustomBot ||
      this.activeList === objectTypes.triggerableCustomBot ||
      this.activeList === objectTypes.resolutionBotBehavior ||
      this.activeList === objectTypes.tooltipGroup ||
      this.activeList === objectTypes.newsItem
    ) {
      // Inbound/button Custom Bots don't currently fit in the ongoing and one off world, as their matching behavior is transient
      this.selectedMatchBehaviorValues = this.selectedMatchBehaviorValues
        ? [matchBehaviors.transient]
        : null;
    } else if (
      // If someone switches list from banners or tours they'd be looking at messages in a state that is now illegal
      // So we should set users that are on only matchBehaviors.multi/transient/ to default ongoing => [matchBehaviors.single, matchBehaviors.multi]
      this.selectedMatchBehaviorValues &&
      ((this.selectedMatchBehaviorValues.includes(matchBehaviors.multi) &&
        this.activeList !== objectTypes.banner) ||
        (this.selectedMatchBehaviorValues.includes(matchBehaviors.transient) &&
          this.activeList !== objectTypes.inboundCustomBot &&
          this.activeList !== objectTypes.buttonCustomBot &&
          this.activeList !== objectTypes.triggerableCustomBot &&
          this.activeList !== objectTypes.resolutionBotBehavior))
    ) {
      this.selectedMatchBehaviorValues = [matchBehaviors.single, matchBehaviors.multi];
    } else if (!this.selectedMatchBehaviorValues) {
      this.selectedMatchBehaviorValues = null;
    }
  }

  resetFilters() {
    this.selectedSenderValue = null;
    this.selectedAudienceValues = [];
    this.selectedStateValue = null;
    this.selectedTagOperator = IN_OPERATOR;
    this.selectedTagValues = null;
    this.selectedSubscriptionTypeValue = null;
    this.additionalSearchableData = null;
    this.searchString = null;
    this.selectedObjectTypes = null;
    this.predicates = [];
    this.hideSeries = null;
    if (this.activeList === objectTypes.newsItem) {
      this.selectedMatchBehaviorValues = [matchBehaviors.transient];
    } else {
      this.selectedMatchBehaviorValues = null;
    }
  }

  resetTableColumns() {
    this.selectedTableColumns = DEFAULT_COLUMN_LIST;
  }

  handleIllegalFilter(propertyChanged) {
    if (propertyChanged !== 'selectedMatchBehaviorValues') {
      return;
    }

    if (this.selectedStateIsLiveOrSent) {
      this.selectedStateValue = null;
    }
    if (this.selectedTabIsOneOff && this.selectedStateIsPaused) {
      this.selectedStateValue = null;
    }
    if (this.selectedTabIsOneOff && this.selectedAudienceContainsVisitors) {
      this.selectedAudienceValues.removeObject(roleTypes.visitorRole);
    }
  }

  safelySet(property, value) {
    this.set(property, value);
    this.handleIllegalFilter(property);
  }

  contentSearchWithFilters(searchParams) {
    this.handleLastContentTypeSelected();
    searchParams = this._addFilterParams(searchParams);
    searchParams = this._addPredicatesToFilterParams(searchParams);

    if (searchParams['predicates']) {
      return this._doSearchWithPredicates(searchParams);
    } else {
      return this._doSearch(searchParams);
    }
  }

  handleLastContentTypeSelected() {
    if (isPresent(this.selectedObjectTypes)) {
      let objectTypes = this.convertToObjectTypeWithLegacy(this.selectedObjectTypes, false);
      //Remove any content types that are no longer selected
      this.lastSelectedContentTypes = this.lastSelectedContentTypes.filter(
        (type) => objectTypes.indexOf(type) !== -1,
      );
      //Add any new content types that are selected
      let newObjectTypes = objectTypes.filter(
        (type) => this.lastSelectedContentTypes.indexOf(type) === -1,
      );
      this.lastSelectedContentTypes = this.lastSelectedContentTypes.concat(newObjectTypes);
      this.lastSelectedContentType = [
        this.lastSelectedContentTypes[this.lastSelectedContentTypes.length - 1],
      ];
    } else {
      this.lastSelectedContentTypes = [];
      this.lastSelectedContentType = [];
    }
  }

  contentSearch(searchParams) {
    return this._doSearch(searchParams);
  }

  async getAllRulesetIds(searchParams) {
    return await this._getAllRulesetIds(searchParams);
  }

  async _doSearch(searchParams) {
    let response = await this._search(searchParams);

    return {
      contentWrappers: this._contentWrapperModels(response.content_wrappers),
      pageFrom: response.page_from,
      perPage: response.per_page,
      totalCount: response.total_count,
      totalPages: response.total_pages,
      lastPageHit: response.last_page_hit,
    };
  }

  async _doSearchWithPredicates(searchParams) {
    let response = await this._searchWithPredicates(searchParams);

    return {
      contentWrappers: this._contentWrapperModels(response.content_wrappers),
      pageFrom: response.page_from,
      perPage: response.per_page,
      totalCount: response.total_count,
      totalPages: response.total_pages,
      lastPageHit: response.last_page_hit,
    };
  }

  async getStats(params) {
    return await this._getStats(params);
  }

  async getMostSentRulesets(params) {
    let rulesets = await this._getMostSentRulesets(params);
    return this._contentWrapperModels(rulesets);
  }

  convertToObjectTypeWithLegacy(objectNamesOrId, withLegacy = true) {
    let objectTypeIds = [];
    if (isPresent(objectNamesOrId)) {
      objectNamesOrId.forEach((objectNameOrType) => {
        let objectId = this.objectNamesMapping[objectNameOrType];
        if (isPresent(objectId)) {
          objectTypeIds.push(objectId);
        } else {
          let objectType = Number(objectNameOrType);
          if (this.objectTypeValues.includes(objectType)) {
            objectTypeIds.push(objectType);
          }
        }
        let legacyTypeId = LEGACY_TYPES[objectId];
        if (isPresent(legacyTypeId) && withLegacy) {
          objectTypeIds.push(legacyTypeId);
        }
      });
    }
    return objectTypeIds;
  }

  get objectTypeValues() {
    return Object.values(objectTypes);
  }

  get objectNamesMapping() {
    let objectNamesMapping = {};
    Object.keys(objectNames)
      .filter(
        (id) =>
          Number(id) !== objectTypes.legacyMessageEmail && id !== objectTypes.legacyMessagePush,
      )
      .map((id) => (objectNamesMapping[objectNames[id]] = Number(id)));
    return objectNamesMapping;
  }

  _addFilterParams(searchParams) {
    if (this.selectedSenderValue) {
      this._setIfPresent(searchParams, 'sender_type', this.selectedSenderValue.split('_')[0]);
      this._setIfPresent(searchParams, 'sender_id', this.selectedSenderValue.split('_')[1]);
    }
    this._setIfPresent(searchParams, 'audience_types', this._selectedAudience(searchParams));
    this._setIfPresent(searchParams, 'states', this._stateParamsForSearch());
    this._setIfPresent(searchParams, 'tag_ids', this.selectedTagValues || []);
    this._setIfPresent(searchParams, 'tag_operator', this.selectedTagOperator || IN_OPERATOR);
    this._setIfPresent(searchParams, 'match_behaviors', this.selectedMatchBehaviorValues || []);
    this._setIfPresent(
      searchParams,
      'subscription_type_id',
      this.selectedSubscriptionTypeValue || [],
    );
    this._setIfPresent(
      searchParams,
      'additional_searchable_data',
      searchParams.additionalSearchableData || this.additionalSearchableData || [],
    );
    if (!isPresent(searchParams.title)) {
      this._setIfPresent(searchParams, 'content_terms', this.searchString);
    }
    if (isPresent(this.selectedObjectTypes)) {
      this._setIfPresent(
        searchParams,
        'object_types',
        this.convertToObjectTypeWithLegacy(this.selectedObjectTypes),
      );
    }
    if (isPresent(this.hideSeries)) {
      this._setIfPresent(searchParams, 'hide_series', this.hideSeries);
    }
    searchParams['wildcard_title_search'] = WILDCARD_TITLE_SEARCH;
    return searchParams;
  }

  _addPredicatesToFilterParams(searchParams) {
    this._setIfPresent(searchParams, 'predicates', this.predicates || []);
    return searchParams;
  }

  _selectedAudience(searchParams) {
    if (searchParams.additionalSearchableData) {
      if (searchParams.additionalSearchableData.target === NO_TRIGGER_TARGET) {
        return [];
      } else {
        return this.selectedAudienceValues || [];
      }
    } else {
      return this.selectedAudienceValues || [];
    }
  }

  _stateParamsForSearch() {
    if (isBlank(this.selectedStateValue)) {
      return [];
    } else if (
      (this.selectedTabIsOneOff || this.isDeliverabilityReport) &&
      this.selectedStateIsLiveOrSent
    ) {
      return [states.live, states.paused];
    } else {
      return [this.selectedStateValue];
    }
  }

  _setIfPresent(searchParams, key, value, defaultValue = null) {
    if (isPresent(value)) {
      searchParams[key] = value;
    } else {
      delete searchParams[key];
    }
  }

  _contentWrapperModels(objects = []) {
    return objects.map((contentWrapper) => {
      let normalizedObject = this.store.normalize('outbound/content-wrapper', contentWrapper);
      return this.store.push(normalizedObject);
    });
  }

  _search(params) {
    return ajax({
      url: '/ember/content_service/contents/search',
      type: 'GET',
      data: params,
    }).catch(() => {
      this.notificationsService.notifyError('Unable to load content. Please try again later.');
      return {};
    });
  }

  async _searchWithPredicates(params) {
    return await post('/ember/content_service/contents/search', params).catch(() => {
      this.notificationsService.notifyError('Unable to load content. Please try again later.');
      return {};
    });
  }

  _getStats(params) {
    return ajax({
      url: '/ember/content_service/contents/get_stats',
      type: 'GET',
      data: params,
    });
  }

  _getMostSentRulesets(params) {
    return ajax({
      url: '/ember/reporting/customer_engagement_report/most_sent_rulesets',
      type: 'GET',
      data: params,
    });
  }

  _getAllRulesetIds(params) {
    return ajax({
      url: '/ember/content_service/contents/get_ruleset_ids_per_content_type',
      type: 'GET',
      data: params,
    });
  }
}
