/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    lastStateChange: { embedded: 'always' },
    object: { embedded: 'always' },
    objectStats: { embedded: 'always' },
    contentObject: { embedded: 'always' },
    contentPreview: { embedded: 'always' },
    ruleset: {
      serialize: false,
    },
    wentLiveAt: {
      serialize: false,
    },
  },
});
