/* RESPONSIBLE TEAM: team-tickets-1 */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class InboundTriggerSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    attributeDescriptors: { embedded: 'always', serialize: 'records', deserialize: 'records' },
  };
}
