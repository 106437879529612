/* RESPONSIBLE TEAM: team-reporting */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    eventTrigger: 'trigger',
  },
});
