/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { request } from 'embercom/lib/inbox/requests';
import { post } from 'embercom/lib/ajax';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';

interface CreateSalesforceTaskResponse {
  lead_or_contact: Record<string, unknown>;
  tasks: {
    task_url: string;
  }[];
}

interface IntegrationsApiResponse {
  integrations: {
    has_github: boolean;
    has_salesforce: boolean;
    has_pipedrive: boolean;
  };
}

export default class IntegrationsApi extends Service {
  @service declare session: Session;

  get isLoading() {
    return this.integrationsLoader.isLoading;
  }

  get hasGithubIntegration() {
    return this.integrations.hasGithub;
  }

  get hasSalesforceIntegration() {
    return this.integrations.hasSalesforce;
  }

  get hasPipedriveIntegration() {
    return this.integrations.hasPipedrive;
  }

  async createGithubIssue(
    conversationId: string,
    partId: string,
    userId?: string,
  ): Promise<string> {
    let response = await post(
      `/ember/github_integrations/create_issue?app_id=${this.session.workspace.id}`,
      {
        admin_id: this.session.teammate.id.toString(),
        conversation_id: conversationId,
        conversation_part_id: partId,
        user_id: userId,
      },
    );

    return response.issue_url;
  }

  async createSalesforceTask(
    conversationId: string,
    partId: string,
    userId?: string,
  ): Promise<CreateSalesforceTaskResponse> {
    let response = await post(`/ember/salesforce_integrations/tasks`, {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id.toString(),
      conversation_id: conversationId,
      conversation_part_id: partId,
      user_id: userId,
    });

    return response;
  }

  private get integrations() {
    let { integrations } = this.integrationsLoader.value ?? {};

    return {
      hasGithub: integrations?.has_github ?? false,
      hasSalesforce: integrations?.has_salesforce ?? false,
      hasPipedrive: integrations?.has_pipedrive ?? false,
    };
  }

  @use private integrationsLoader = AsyncData<IntegrationsApiResponse>(async ({ signal }) => {
    let response = await request(
      `/ember/inbox/app_integrations.json?app_id=${this.session.workspace.id}`,
      { signal },
    );

    return await response.json();
  });
}

declare module '@ember/service' {
  interface Registry {
    integrationsApi: IntegrationsApi;
    'integrations-api': IntegrationsApi;
  }
}
