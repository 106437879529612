/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';
import redirect from 'embercom/lib/redirect';

export default Service.extend({
  futureRedirectPath: null,

  redirect() {
    redirect(...arguments);
  },

  setRedirectPath(path) {
    this.set('futureRedirectPath', path);
  },

  getRedirectPath() {
    return this.futureRedirectPath;
  },
});
