/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */
export enum HotkeyID {
  AddAttachment = 'AddAttachment',
  AssignToAction = 'AssignToAction',
  ChangePriority = 'ChangePriority',
  Close = 'Close',
  CommandK = 'CommandK',
  ComposeNote = 'ComposeNote',
  ComposeReply = 'ComposeReply',
  ConvertToTicket = 'ConvertToTicket',
  MergeInto = 'MergeInto',
  Emoji = 'Emoji',
  InsertArticle = 'InsertArticle',
  InsertGif = 'InsertGif',
  InsertImage = 'InsertImage',
  JumpToAction = 'JumpToAction',
  JumpToBottom = 'JumpToBottom',
  JumpToTop = 'JumpToTop',
  NavigateArrowUp = 'NavigateArrowUp',
  NavigateArrowDown = 'NavigateArrowDown',
  NavigateUp = 'NavigateUp',
  NavigateDown = 'NavigateDown',
  NewConversation = 'NewConversation',
  KeyboardShortcuts = 'KeyboardShortcuts',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  Reopen = 'Reopen',
  Send = 'Send',
  SendAndClose = 'SendAndClose',
  SendAndSnooze = 'SendAndSnooze',
  SendAndCrossPost = 'SendAndCrossPost',
  SnoozeAction = 'SnoozeAction',
  SnoozeUntilTomorrow = 'SnoozeUntilTomorrow',
  SwitchView = 'SwitchView',
  TagLastUserComment = 'TagLastUserComment',
  TagUser = 'TagUser',
  'ToggleAll' = 'ToggleAll',
  ToggleEvents = 'ToggleEvents',
  ToggleLeftNav = 'ToggleLeftNav',
  ToggleConversationDetailsSidebar = 'ToggleConversationDetailsSidebar',
  ToggleSelect = 'ToggleSelect',
  ToggleUp = 'ToggleUp',
  ToggleDown = 'ToggleDown',
  TriggerWorkflowConnectorAction = 'TriggerWorkflowConnectorAction',
  Undo = 'Undo',
  UseMacro = 'UseMacro',
  Impersonate = 'Impersonate',
  Search = 'Search',
  AiAssist = 'AiAssist',
  UpdateTicketState = 'UpdateTicketState',
  ResolveAndCloseTicketState = 'ResolveAndCloseTicketState',
  OpenKnowledgeBasePanel = 'OpenKnowledgeBasePanel',
  OpenCopilot = 'OpenCopilot',
  OpenConversationDetails = 'OpenConversationDetails',
}
