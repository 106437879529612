/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

export default BaseValidator.extend({
  intl: service(),

  validate(value, options, _model) {
    if (!value) {
      return true;
    }

    let extractedAudioBlocks = this.extractAudioFile(value);
    let extractedParagraphBlocks = this.extractParagraphBlocks(value);

    if (extractedAudioBlocks.length === 0 || extractedParagraphBlocks.length === 0) {
      return true;
    }

    let allParagraphsEmpty = this.paragraphBlocksContainNoContent(extractedParagraphBlocks);

    if (!allParagraphsEmpty) {
      return this._extractErrorMessage(options);
    }

    return true;
  },

  extractAudioFile(value) {
    return value.filter((block) => block.type === 'audioFile');
  },

  extractParagraphBlocks(value) {
    return value.filter((block) => block.type === 'paragraph');
  },

  paragraphBlocksContainNoContent(paragraphBlocks) {
    let blocksWithMeaningfulContent = paragraphBlocks.filter((block) => block.hasContent);
    return blocksWithMeaningfulContent.length === 0;
  },

  _extractErrorMessage(options) {
    let errorMessage = options.message;

    if (options.messageTranslated === true) {
      errorMessage = this.intl.t(options.message);
    }
    return errorMessage;
  },
});
