/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

let removeWhiteSpace = function (text = '') {
  return text
    .split(/(\{\{.*?\}\})/)
    .map((str) => (str.startsWith('{{') ? str.replace(/\s/g, '') : str))
    .join('');
};

const MessageVariationContentNotPlaceholder = BaseValidator.extend({
  validate(value, options, model) {
    if (
      model.get('message.isMobilePush') ||
      model.get('emailTemplate.isHtmlMode') ||
      value.get('length') > 2 ||
      !value.get('firstObject.isText')
    ) {
      return true;
    }

    let placeholder = model.get('message.app.commentPlaceholder');
    let blocksWithoutEmptyTextBlocks = value.reject((block) => {
      return block.get('isText') && block.get('text').trim() === '';
    });
    if (
      blocksWithoutEmptyTextBlocks.get('length') > 1 ||
      removeWhiteSpace(blocksWithoutEmptyTextBlocks.get('firstObject.text')) !==
        removeWhiteSpace(placeholder)
    ) {
      return true;
    }

    return 'Finish writing your message';
  },
});

MessageVariationContentNotPlaceholder.reopenClass({
  getDependentsFor() {
    return [
      'model.message.isMobilePush',
      'model.emailTemplate.isHtmlMode',
      'model.message.app.commentPlaceholder',
      'model.blocks.@each.{text}',
    ];
  },
});

export default MessageVariationContentNotPlaceholder;
