/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default class SavedReplyInsertionsService extends Service {
  async recordInsertion(app, admin, savedReply, conversationId) {
    try {
      await ajax({
        url: `/ember/saved_replies/${savedReply.id}/inserted`,
        data: JSON.stringify({
          app_id: app.id,
          admin_id: admin.id,
          id: savedReply.id,
          conversation_id: conversationId,
        }),
        type: 'POST',
        contentType: 'application/json',
        dataType: 'JSON',
      });
    } catch (err) {
      console.error('Failed to record macro insertion');
    }
  }
}
