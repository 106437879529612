/* RESPONSIBLE TEAM: team-channels */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class InboxVersion extends Service {
  @tracked version?: number;

  setVersion(version: number) {
    this.version = version;
  }

  unsetVersion() {
    this.version = undefined;
  }

  get isInbox1(): boolean {
    return this.version === 1;
  }

  get isInbox2(): boolean {
    return this.version === 2;
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxVersion: InboxVersion;
    'inbox-version': InboxVersion;
  }
}
