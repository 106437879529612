/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';

export default class ConversationalInsightsService extends Service {
  @service declare appService: $TSFixMe;
  @tracked currentDateRange;

  constructor() {
    super(...arguments);
    let endDate = moment.tz(this.appService.app.timezone).endOf('day').format();
    let startDate = moment
      .tz(this.appService.app.timezone)
      .subtract(1, 'years')
      .startOf('day')
      .format();
    this.currentDateRange = Range.createFromParams(
      startDate,
      endDate,
      this.appService.app.timezone,
    );
  }

  updateRange(range: Range) {
    this.currentDateRange = range;
  }
}
