/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import bracketedRelativeTimeAgo from 'embercom/lib/bracketed-relative-time-ago';
import ajax from 'embercom/lib/ajax';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import { chunk, isNumber } from 'underscore';
import { tracked } from '@glimmer/tracking';
import type Session from './session';
import { type TaskFunction } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

export enum TeammateStatus {
  Active = 'active',
  Away = 'away',
  Reassign = 'away_and_reassigning',
}

export const TEAMMATE_ACTIVITY_STATUS_CODE_MAP = new Map([
  [0, TeammateStatus.Active],
  [1, TeammateStatus.Away],
  [2, TeammateStatus.Reassign],
]);

export enum ChannelAvailabilityCode {
  Phone = 'phone',
  Conversations = 'conversations',
  Both = 'both',
}

const SECONDS_BEFORE_IDLE = 15 * 60;

const FETCH_ADMIN_BATCH_SIZE = 20;

interface Settings {
  timeframeInHours: number;
  aggregationType: string;
  idleThreshold: number;
  excludeBotTime: boolean;
  excludedQueryTypes: string[];
  selectedTeammateStatuses?: string[];
}

type CachedOverviewItem = { id: number; csat?: string | number };
type CachedOverview = CachedOverviewItem[];

export interface TeammateActivityOverview {
  id: number;
  admin: AdminSummary;
  name: string;
  openConversations: number;
  closedConversations: number;
  snoozedConversations: number;
  participatedIn: number;
  idle: number;
  status?: TeammateStatus;
  statusCode: number;
  statusReason: string | null;
  csat?: string | number;
  workload?: number | null;
  conversationLimit?: number | null;
  timeOnStatusInSeconds: number;
  timeOnStatus: string;
  firstActiveTimeInSeconds: number | null;
  firstActiveTime: string | null;
  totalActiveTimeInSeconds: number;
  totalActiveTime: string;
  totalAwayTimeInSeconds?: number;
  totalAwayTime?: number;
  lastSeenInSeconds: number | null;
  lastSeen: string | null;
  currentActivity: string | null;
  avgTimeToSubsequentResponseInSeconds: number;
  avgTimeToSubsequentResponse: string;
  participatedInPerHour?: number;
  closedPerHour?: number;
  avgFirstResponseTime: string;
  avgFirstResponseTimeInSeconds: number;
  callsTalkTime?: string;
  channelCode?: ChannelAvailabilityCode;
}

export interface ConversationsActivityAggregations {
  active: number;
  away: number;
  reassigning: number;
  open: number;
  closed: number;
  snoozed: number;
  awayReasons: string | null;
  reassigningReasons: string | null;
  participatedIn: number;
  idlePercentage: number | null;
  avgWaitTime: string;
  avgTimeToSubsequentResponse: string;
  csat?: string | number;
}

export interface CallsActivityAggregations {
  callsTalkTime?: string;
}

interface TeammateActivityForTeamsResponse {
  overview: Array<TeammateActivityOverview>;
  aggregations: ConversationsActivityAggregations;
  callsAggregations?: CallsActivityAggregations;
}

export default class Inbox2TeammateActivityService extends Service {
  @service declare session: Session;

  @tracked isFetchingData = false;
  declare forceRefresh: TaskFunction<void, any>;

  async getTeammateActivityForTeams(
    teamIds: number[],
    settings: Settings,
    {
      cachedOverview,
      cachedAggregations,
    }: { cachedOverview: CachedOverview; cachedAggregations: any },
  ): Promise<TeammateActivityForTeamsResponse> {
    try {
      this.isFetchingData = true;
      let rawTeammateActivities = await this.fetch(teamIds, settings);
      let teammateActivities = await this.processRawTeammateActivityOverview(
        rawTeammateActivities.overview,
        settings,
        cachedOverview,
      );
      let response: TeammateActivityForTeamsResponse = {
        overview: teammateActivities,
        aggregations: this.readAggregations(
          rawTeammateActivities,
          settings.excludedQueryTypes,
          cachedAggregations,
        ),
      };
      if (this.session.workspace.isFeatureEnabled('inbound-phone-call')) {
        response.callsAggregations = this.readCallsAggregations(rawTeammateActivities);
      }
      return response;
    } finally {
      this.isFetchingData = false;
    }
  }

  private async processRawTeammateActivityOverview(
    overview: any,
    settings: Settings,
    cachedOverview: CachedOverview,
  ): Promise<TeammateActivityOverview[]> {
    let teammateActivities = [];
    for (let activityBatch of chunk(overview, FETCH_ADMIN_BATCH_SIZE)) {
      let newTeammateActivities = await Promise.all(
        activityBatch.map((activity) =>
          this.mapRawTeammateActivity(activity, settings.excludedQueryTypes, cachedOverview),
        ),
      );
      teammateActivities.push(...newTeammateActivities);
    }
    return teammateActivities;
  }

  async getCurrentTeammateActivityOverview(
    settings: Settings,
    { cachedOverview }: { cachedOverview: CachedOverview },
  ): Promise<{ overview: TeammateActivityOverview }> {
    try {
      this.isFetchingData = true;
      let rawTeammateActivities = await this.fetchCurrentAdminActivity(settings);
      let teammateActivities = await this.processRawTeammateActivityOverview(
        rawTeammateActivities.overview,
        settings,
        cachedOverview,
      );
      return {
        overview: teammateActivities[0],
      };
    } finally {
      this.isFetchingData = false;
    }
  }

  mapRawTeammateActivity = async (activity: any, excluded: string[], cache: CachedOverview) => {
    let admin = await this.getAdmin(activity);
    let readFromCache = (key: keyof CachedOverviewItem) =>
      cache.find(({ id }) => id === admin.id)?.[key];

    return {
      id: admin.id,
      admin,
      name: admin.name,
      openConversations: activity.open || 0,
      closedConversations: activity.closed || 0,
      snoozedConversations: activity.snoozed || 0,
      idle: activity.idle || 0,
      status: TEAMMATE_ACTIVITY_STATUS_CODE_MAP.get(activity.status_code),
      statusCode: activity.status_code,
      statusReason: activity.away_status_reason,
      csat: excluded.includes('csat') ? readFromCache('csat') : this.getCsat(activity),
      ...this.getWorkload(activity),
      participatedIn: activity.participated_in || 0,
      ...this.getTimeOnStatus(activity),
      ...this.getFirstActiveTime(activity),
      ...this.getTotalActiveTime(activity),
      ...this.getLastSeen(activity),
      ...this.getAvgFirstResponsetime(activity),

      ...this.getAvgTimeToSubsequentResponse(activity),
      ...(this.session.workspace.isFeatureEnabled('team-sops-realtime-conversations-per-hour')
        ? this.getTotalAwayTime(activity)
        : {}),
      ...(this.session.workspace.isFeatureEnabled('team-sops-realtime-conversations-per-hour')
        ? this.getConversationsPerHour(activity)
        : {}),
      ...(this.session.workspace.isFeatureEnabled('inbound-phone-call')
        ? this.getCallsRelatedMetrics(activity)
        : {}),
      ...(this.session.workspace.isFeatureEnabled('inbound-phone-call')
        ? { channelAvailabilityCode: activity.channel_availability_code }
        : {}),
    };
  };

  readAggregations(response: any, excluded: string[], cache: CachedOverview) {
    return {
      active: response.aggregations.active,
      away: response.aggregations.away,
      reassigning: response.aggregations.reassigning,
      awayReasons: response.aggregations.away_reasons,
      reassigningReasons: response.aggregations.reassigning_reasons,
      open: response.aggregations.open,
      closed: response.aggregations.closed,
      snoozed: response.aggregations.snoozed,
      idlePercentage: this.getIdlePercentage(response),
      participatedIn: response.aggregations.participated_in,
      avgWaitTime: this.formatTime(response.aggregations.median_first_response_time),
      avgTimeToSubsequentResponse: this.formatTime(
        response.aggregations.median_time_to_subsequent_response,
      ),
      //@ts-ignore
      csat: excluded.includes('csat') ? cache['csat'] : this.getCsat(response.aggregations),
    };
  }

  readCallsAggregations(response: any) {
    return {
      callsTalkTime: this.formatTime(response.calls_aggregations?.calls_talk_time_in_seconds),
    };
  }

  async getAdmin({ id }: { id: number }): Promise<AdminSummary> {
    let admins = await this.session.workspace.fetchAdminAssignees();
    return admins.find((a) => a.id === id) || admins[0];
  }

  getTimeOnStatus({ time_on_status_in_seconds }: any) {
    return {
      timeOnStatusInSeconds: time_on_status_in_seconds,
      timeOnStatus: this.formatTime(time_on_status_in_seconds),
    };
  }

  getFirstActiveTime({ first_active_time }: any) {
    let firstActiveTimeInSeconds = this.getTimeInSeconds(first_active_time);
    return {
      firstActiveTimeInSeconds,
      firstActiveTime: first_active_time
        ? moment(first_active_time).calendar({
            sameDay: '[Today,] LT',
            lastDay: '[Yesterday,] LT',
            sameElse: 'MMMM Do, h:mma',
          })
        : null,
    };
  }

  getTotalActiveTime({ total_active_time_in_seconds }: any) {
    return {
      totalActiveTimeInSeconds: total_active_time_in_seconds,
      totalActiveTime: this.formatTime(total_active_time_in_seconds),
    };
  }

  getTotalAwayTime({ total_away_time_in_seconds }: any) {
    return {
      totalAwayTimeInSeconds: total_away_time_in_seconds,
      totalAwayTime: this.formatTime(total_away_time_in_seconds),
    };
  }

  getConversationsPerHour({ conversations_per_hour }: any) {
    return {
      participatedInPerHour: conversations_per_hour.participated_in,
      closedPerHour: conversations_per_hour.closed,
    };
  }

  getCallsRelatedMetrics({ calls_talk_time_in_seconds }: any) {
    return {
      callsTalkTime: this.formatTime(calls_talk_time_in_seconds),
    };
  }

  getLastSeen({ last_seen_at }: any) {
    let lastSeenInSeconds = this.getTimeInSeconds(last_seen_at);
    return {
      lastSeenInSeconds,
      lastSeen: last_seen_at ? bracketedRelativeTimeAgo(last_seen_at) : null,
      currentActivity: this.getCurrentActivityStatus(lastSeenInSeconds),
    };
  }

  getCurrentActivityStatus(lastSeenInSeconds: any) {
    if (!lastSeenInSeconds) {
      return null;
    }
    return lastSeenInSeconds < SECONDS_BEFORE_IDLE ? 'Working' : 'idle';
  }

  getAvgFirstResponsetime({ avg_first_response_time_in_seconds }: any) {
    return {
      avgFirstResponseTimeInSeconds: avg_first_response_time_in_seconds,
      avgFirstResponseTime: this.formatTime(avg_first_response_time_in_seconds),
    };
  }

  getAvgTimeToSubsequentResponse({ avg_time_to_subsequent_response }: any) {
    return {
      avgTimeToSubsequentResponseInSeconds: avg_time_to_subsequent_response,
      avgTimeToSubsequentResponse: this.formatTime(avg_time_to_subsequent_response),
    };
  }

  getWorkload({ workload }: any) {
    return isNumber(workload?.workload)
      ? {
          workload: workload.workload,
          conversationLimit: workload.conversation_limit,
        }
      : null;
  }

  getAvgWaitTime(avgWaitTime: any) {
    if (avgWaitTime) {
      let timeFormatter = new Formatters.seconds(); // eslint-disable-line new-cap
      return timeFormatter.formatCounter(avgWaitTime);
    }

    return '';
  }

  getCsat({ csat }: any) {
    return isNumber(csat) ? Math.round(csat) : '';
  }

  getIdlePercentage(response: any) {
    let totalOpen = response.aggregations.open;
    return totalOpen === 0 ? null : Math.round((response.aggregations.idle * 100) / totalOpen);
  }

  getTimeInSeconds(time: any) {
    return time ? moment.duration(moment.utc().diff(moment(time))).asSeconds() : null;
  }

  formatTime(value: any) {
    let timeFormatter = new Formatters.seconds(); // eslint-disable-line new-cap
    return value ? timeFormatter.formatCounter(value) : null;
  }

  fetch(
    teamIds: number[],
    {
      timeframeInHours,
      aggregationType,
      idleThreshold,
      excludeBotTime,
      excludedQueryTypes,
      selectedTeammateStatuses,
    }: Settings,
  ) {
    return ajax({
      url: '/ember/monitoring/teammate_activities',
      type: 'GET',
      data: {
        app_id: this.session.workspace.id,
        team_ids: teamIds,
        timeframe: timeframeInHours * 60,
        aggregation_type: aggregationType,
        idle_threshold: idleThreshold,
        exclude_bot_time: excludeBotTime,
        excluded_query_types: excludedQueryTypes,
        selected_teammate_statuses: selectedTeammateStatuses,
      },
    });
  }

  fetchCurrentAdminActivity({
    timeframeInHours,
    aggregationType,
    idleThreshold,
    excludeBotTime,
    excludedQueryTypes,
  }: Settings) {
    return ajax({
      url: '/ember/inbox/teammate_activities',
      type: 'GET',
      data: {
        app_id: this.session.workspace.id,
        timeframe: timeframeInHours * 60,
        aggregation_type: aggregationType,
        idle_threshold: idleThreshold,
        exclude_bot_time: excludeBotTime,
        excluded_query_types: excludedQueryTypes,
      },
    });
  }

  setForceRefresh(refreshTask: any) {
    this.forceRefresh = refreshTask;
  }

  doForceRefresh(options: any) {
    if (this.forceRefresh) {
      taskFor(this.forceRefresh).perform(options);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2TeammateActivityService: Inbox2TeammateActivityService;
    'inbox2-teammate-activity-service': Inbox2TeammateActivityService;
  }
}
