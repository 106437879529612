import Handlebars from 'handlebars';
      const _template = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <h2 class=\"follow\">Follow us</h2>\n <p class=\"social\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"facebook") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"twitter") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + " </p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\"><img alt=\"Facebook\" border=\"0\" src=\"https://marketing.intercomcdn.com/assets/email/broadcast/facebook-2263526f2b7c7cf3c7c2a066588b01ef.png\" width=\"60\" /></a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\"><img alt=\"Twitter\" border=\"0\" src=\"https://marketing.intercomcdn.com/assets/email/broadcast/twitter-fe222f8697fa267d095338db3f583c94.png\" width=\"60\" /></a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<h2 class=\"follow\">Follow us</h2>\n <p class=\"social\">\n      <a href=\"#\"><img alt=\"Facebook\" border=\"0\" src=\"https://marketing.intercomcdn.com/assets/email/broadcast/facebook-2263526f2b7c7cf3c7c2a066588b01ef.png\" width=\"60\" /></a>\n      <a href=\"#\"><img alt=\"Twitter\" border=\"0\" src=\"https://marketing.intercomcdn.com/assets/email/broadcast/twitter-fe222f8697fa267d095338db3f583c94.png\" width=\"60\" /></a>\n </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasSocial") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "");
},"useData":true});
      export default _template;