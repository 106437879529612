/* RESPONSIBLE TEAM: team-frontend-tech */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default EmbercomBaseSerializer.extend({
  serialize(snapshot, options) {
    let serialized = this._super(snapshot, options);
    if (serialized.hasOwnProperty('value_description')) {
      delete serialized.value_description;
    }
    if (serialized.type === 'or' || serialized.type === 'and') {
      delete serialized.attribute;
      delete serialized.comparison;
      delete serialized.value;
    } else {
      delete serialized.predicates;
    }
    return serialized;
  },
});
