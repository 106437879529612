/* RESPONSIBLE TEAM: team-workflows */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    teamAvailability: { embedded: 'always' },
    conversationRouting: { embedded: 'always' },
    conversationRating: { embedded: 'always' },
    articleSuggestions: { embedded: 'always' },
    articleAutoReaction: { embedded: 'always' },
    qualifyLeads: { embedded: 'always' },
    contactCollection: { embedded: 'always' },
    duplicateConversationDetection: { embedded: 'always' },
    upfrontCollection: { embedded: 'always' },
  },
});
