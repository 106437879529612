/* RESPONSIBLE TEAM: team-frontend-tech */
export default function (tmap) {
  tmap.transition(
    tmap.hasClass('message-editor___expanded-panel__main'),
    tmap.toValue(true),
    tmap.use('flex-grow'),
    tmap.reverse('fade'),
  );
  tmap.transition(
    tmap.hasClass('message-editor__optional-panel__show'),
    tmap.toValue(true),
    tmap.use('flex-grow'),
  );
  tmap.transition(tmap.hasClass('animation__flex-grow'), tmap.use('fading-flex-grow'));
  tmap.transition(
    tmap.hasClass('animation__flex-grow-and-then-focus'),
    tmap.use('fading-flex-grow', {
      complete(elements) {
        if (typeof elements === 'object' && typeof elements[0] === 'object') {
          let input = elements[0].querySelector('.f__text');
          if (input !== null) {
            input.focus();
          }
        }
      },
    }),
  );
  tmap.transition(tmap.hasClass('message-editor__completed__show'), tmap.use('scale'));
  tmap.transition(
    tmap.hasClass('message__editor__content__sidebar'),
    tmap.toValue(true),
    tmap.use('toRight'),
    tmap.reverse('toLeft'),
  );
}
