/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { later, cancel } from '@ember/runloop';
import Service from '@ember/service';
import ENV from 'embercom/config/environment';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type { EmberRunTimer } from '@ember/runloop/types';

export default class BrowserTabNotifications extends Service {
  _staticIconSrc = assetUrl('/assets/images/conversation-dots-static@2x.png');
  _lightAnimationSrc = assetUrl('/assets/images/conversation-dots-animated@2x.gif');
  _darkAnimationSrc = assetUrl('/assets/images/conversation-dots-animated-dark@2x.gif');

  alertWaiting = false;
  conversationId?: string;
  originalTitle?: string;
  toggle?: string;
  toggleTimer: EmberRunTimer | null = null;

  constructor() {
    super(...arguments);
    this._leave = this._leave.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);

    window.addEventListener('beforeunload', this._leave, false);
    document.addEventListener('visibilitychange', this.onVisibilityChange, false);
  }

  showBrowserAlert(content: string, conversationId: string) {
    this.setBrowserAlert();
    this.setPageTitle(content, conversationId);
  }

  setBrowserAlert() {
    this.alertWaiting = true;
    if (document.hidden) {
      return;
    }
    this._activateBrowserAlertWithDelay(0);
  }

  setPageTitle(content: string, conversationId: string) {
    if (!document.hidden) {
      return;
    }
    this.conversationId = conversationId;
    this.originalTitle = this.originalTitle || this._getTitle();
    this.toggle = this.originalTitle;
    this._setTitle(content);
    if (!this.toggleTimer) {
      this.toggleTimer = later(this, this._toggleHeader, ENV.APP._3000MS);
    }
  }

  reset(conversationId?: string) {
    if (!this.toggleTimer) {
      return;
    }
    if (conversationId && conversationId !== this.conversationId) {
      return;
    }
    cancel(this.toggleTimer);
    this.toggleTimer = null;
    this._setTitle(this.originalTitle);
    this.originalTitle = undefined;
    this.conversationId = undefined;
  }

  onVisibilityChange() {
    if (document.hidden) {
      return;
    }
    this.reset();
    this._activateBrowserAlertWithDelay(ENV.APP._1000MS);
  }

  _activateBrowserAlertWithDelay(delay: number) {
    if (!this.alertWaiting) {
      return;
    }
    this.alertWaiting = false;
    later(this, this._startBrowserAlertAnimation, delay);
    later(this, this._resetBrowserAlertAnimation, (ENV.APP._10000MS as number) + delay);
  }

  _startBrowserAlertAnimation() {
    // This CSS needs to be convoluted because we don't want to trigger invisible animations in case they later become visible
    let $dark = $(
      '.nav__primary__group.o__selected .nav__link:not(.o__selected) .conversations_web_notification_animation_dark',
    );
    let $light = $(
      '.nav__primary__group:not(.o__selected) .nav__link:not(.o__selected) .conversations_web_notification_animation',
    );
    if (!$dark.hasClass('active')) {
      $dark.addClass('active').attr('src', this._darkAnimationSrc);
    }
    if (!$light.hasClass('active')) {
      $light.addClass('active').attr('src', this._lightAnimationSrc);
    }
    let noActiveAnimations = $dark.length === 0 && $light.length === 0;
    $('.nav__primary__group .nav__link .conversations_web_notification').toggleClass(
      'active',
      noActiveAnimations,
    );
  }

  _resetBrowserAlertAnimation() {
    $('.nav__primary__group .nav__link .conversations_web_notification_animation_dark')
      .removeClass('active')
      .attr('src', this._staticIconSrc);
    $('.nav__primary__group .nav__link .conversations_web_notification_animation')
      .removeClass('active')
      .attr('src', this._staticIconSrc);
    $('.nav__primary__group .nav__link .conversations_web_notification').addClass('active');
  }

  _leave() {
    window.removeEventListener('beforeunload', this._leave);
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }

  _getTitle() {
    let title = document.querySelector('title');
    if (title) {
      return title.textContent ?? undefined;
    }
    return 'Intercom';
  }

  _setTitle(content?: string) {
    let title = document.querySelector('title');
    if (title) {
      title.textContent = content ?? '';
    }
  }

  _toggleHeader() {
    let current = this._getTitle();
    this._setTitle(this.toggle);
    this.toggle = current;
    this.toggleTimer = later(this, this._toggleHeader, ENV.APP._3000MS);
  }
}
