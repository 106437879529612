/* RESPONSIBLE TEAM: team-tickets-1 */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

// only convert the ones we know about
const CONVERSATION_ATTRIBUTES = {
  manual_tag_ids: 'conversation.tag_ids',
  assignee_id: 'conversation.assignee_id',
  waiting_since: 'conversation.waiting_since',
  next_breach_time: 'conversation.next_breach_time',
};

function ensurePredicatesArePrefixed(predicates) {
  predicates.forEach((predicate) => {
    if (predicate.predicates) {
      ensurePredicatesArePrefixed(predicate.predicates);
    } else if (CONVERSATION_ATTRIBUTES[predicate.attribute]) {
      predicate.attribute = CONVERSATION_ATTRIBUTES[predicate.attribute];
    }
  });
}

export default EmbercomBaseSerializer.extend({
  normalize(typeClass, hash) {
    let payload = this._super(...arguments);
    ensurePredicatesArePrefixed(payload.data.attributes.predicateGroup?.predicates || []);
    return payload;
  },
});
