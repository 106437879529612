/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  bodyBlocks: {
    description: 'Localized SMS body',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Body should not be empty',
      }),
      validator('blocks-not-empty', {
        message: 'SMS body should not be empty',
      }),
    ],
  },
});
