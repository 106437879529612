import { A } from '@ember/array';
import BlockSerializer from './block';

/* RESPONSIBLE TEAM: team-proactive-support */

const OPTIONAL_BLOCK_ATTRIBUTES = A(['align', 'style', 'class']);

export default BlockSerializer.extend({
  optionalAttributes: OPTIONAL_BLOCK_ATTRIBUTES,
});
