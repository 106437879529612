/* RESPONSIBLE TEAM: team-data-interop */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  name: {
    validators: [validator('presence', { presence: true })],
  },
  tokenValue: {
    validators: [validator('presence', { presence: true })],
  },
  requestHeaderKey: {
    validators: [validator('presence', { presence: true })],
  },
});
