/* RESPONSIBLE TEAM: team-frontend-tech */

import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import type ReactIframe from 'embercom/components/react/iframe';

export default class ReactService extends Service {
  @tracked didBootFail = false;
  @tracked iframeInstance?: ReactIframe;

  @action setBootFailed() {
    this.didBootFail = true;
  }

  @action
  loadIframe(iframe: ReactIframe) {
    this.iframeInstance = iframe;
  }

  @action
  clearIframe() {
    this.iframeInstance = undefined;
    this.didBootFail = false;
  }

  postBridgeMessage(type: string, payload: any) {
    this.iframeInstance?.postBridgeMessage(type, payload);
  }

  postAppStateChangeMessage(...state: Parameters<ReactIframe['postAppStateChangeMessage']>) {
    this.iframeInstance?.postAppStateChangeMessage(...state);
  }
}

declare module '@ember/service' {
  interface Registry {
    reactService: ReactService;
    'react-service': ReactService;
  }
}
