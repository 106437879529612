/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import abbreviatedRelativeTime from 'embercom/lib/abbreviated-relative-time';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import { isNumber } from 'underscore';
import { tracked } from '@glimmer/tracking';

export default class InboxOverviewService extends Service {
  @service appService;
  @service store;

  @tracked isFetchingData = false;

  forceRefresh;

  get app() {
    return this.appService.app;
  }

  async getInboxData(inboxes, settings, { cachedOverview }) {
    try {
      this.isFetchingData = true;
      let response = await this.fetch(inboxes, settings);
      return {
        overview: this.aggregatePerInbox(
          response,
          inboxes,
          settings.excludedQueryTypes,
          cachedOverview,
        ),
        aggregations: this.readAggregations(response),
      };
    } finally {
      this.isFetchingData = false;
    }
  }

  aggregatePerInbox(response, inboxes, excluded, cache) {
    return inboxes.map((inbox) => this.readResponseForInbox(inbox, response, excluded, cache));
  }

  readResponseForInbox(inbox, response, excluded, cache) {
    let readFromCache = (key) => cache.find(({ inboxId }) => inboxId === inbox.id)?.[key];

    let slaData = excluded.includes('sla')
      ? { missRate: readFromCache('missRate'), missCount: readFromCache('missCount') }
      : this.getSlaData(response, inbox);

    return {
      inboxId: inbox.id,
      inboxIcon: this.getIcon(inbox),
      name: this.getName(inbox),
      open: this.getOpen(response, inbox),
      unassigned: this.getUnassigned(response, inbox),
      waitingForReply: this.getWaitingForReply(response, inbox),
      longestWaiting: this.getLongestWaiting(response, inbox),
      longestWaitingTimestamp: this.getLongestWaitingTimestamp(response, inbox),
      closed: this.getClosed(response, inbox),
      avgWaitTime: this.getAvgWaitTime(response.avg_wait_time[inbox.id]),
      avgWaitTimeInSeconds: response.avg_wait_time[inbox.id],
      csat: excluded.includes('csat')
        ? readFromCache('csat')
        : this.getCsat(response.csat[inbox.id]),
      activeTeammates: this.getActiveTeammates(response, inbox),
      idle: this.getIdle(response, inbox),
      snoozed: this.getSnoozed(response, inbox),
      ...slaData,
    };
  }

  readAggregations(response) {
    return {
      open: response.aggregations.open,
      unassigned: response.aggregations.unassigned,
      snoozed: response.aggregations.snoozed,
      waitingForFirstReply: response.aggregations.waiting_for_first_reply,
      csat: this.getCsat(response.aggregations.csat),
      closed: response.aggregations.closed,
      idlePercentage: this.getIdlePercentage(response),
      avgWaitTime: this.getAvgWaitTime(response.aggregations.avg_wait_time),
      sla: response.aggregations.sla,
    };
  }

  getIcon(inbox) {
    if (inbox.isInboxView) {
      return {
        emojiUri: this.store.peekRecord('inbox-view', inbox.inboxViewId).emojiUri,
      };
    }

    return {
      avatarData: inbox.avatarData,
    };
  }

  getName(inbox) {
    return inbox.name;
  }

  getOpen(response, { id }) {
    return response.open[id] || 0;
  }

  getUnassigned(response, { id }) {
    return response.unassigned[id] || 0;
  }

  getWaitingForReply(response, { id }) {
    return response.waiting_for_first_admin_reply[id] || 0;
  }

  getLongestWaiting(response, { id }) {
    let longestWaiting = response.longest_waiting[id];
    return {
      conversationId: longestWaiting?.['conversation_id'],
      waitingSince: longestWaiting?.['waiting_since']
        ? abbreviatedRelativeTime(longestWaiting['waiting_since'])
        : '',
    };
  }

  getLongestWaitingTimestamp(response, { id }) {
    let longestWaiting = response.longest_waiting[id];
    if (longestWaiting?.['waiting_since']) {
      return new Date(longestWaiting?.['waiting_since']).getTime();
    }
  }

  getAvgWaitTime(avgWaitTime) {
    if (avgWaitTime) {
      let timeFormatter = new Formatters[units.seconds]();
      return timeFormatter.formatCounter(avgWaitTime);
    }

    return '';
  }

  getClosed(response, { id }) {
    return response.closed[id] || 0;
  }

  getCsat(csat) {
    return isNumber(csat) ? Math.round(csat) : '';
  }

  getActiveTeammates(response, { id }) {
    return response.active_teammates[id];
  }

  getIdle(response, { id }) {
    return response.idle?.[id] || 0;
  }

  getIdlePercentage(response) {
    let totalOpen = response.aggregations.open;
    return totalOpen === 0 ? null : Math.round((response.aggregations.idle * 100) / totalOpen);
  }

  getSnoozed(response, { id }) {
    return response.snoozed[id] || 0;
  }

  getSlaData(response, { id }) {
    if (!response.sla[id]) {
      return null;
    }

    let conversationsWithSla = response.sla[id].conversations_with_sla;
    let missed = response.sla[id].missed;
    return {
      missRate:
        conversationsWithSla === 0 ? null : Math.round((missed * 100) / conversationsWithSla),
      missCount: missed,
    };
  }

  fetch(
    inboxes,
    { timeframeInHours, aggregationType, idleThreshold, excludeBotTime, excludedQueryTypes },
  ) {
    return ajax({
      url: '/ember/monitoring/inbox_overview',
      type: 'GET',
      data: {
        app_id: this.app.id,
        inbox_identifiers: inboxes.map(({ id }) => id),
        timeframe: timeframeInHours * 60,
        aggregation_type: aggregationType,
        idle_threshold: idleThreshold,
        exclude_bot_time: excludeBotTime,
        excluded_query_types: excludedQueryTypes,
      },
    });
  }

  setForceRefresh(refreshTask) {
    this.forceRefresh = refreshTask;
  }

  doForceRefresh(options) {
    if (this.forceRefresh) {
      this.forceRefresh.perform(options);
    }
  }
}
