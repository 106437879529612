/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isPresent } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const MINIMUM_GAP_BETWEEN_START_AND_END_IN_HOURS = 3;

const DeliveryWindowEndIsAfterStart = BaseValidator.extend({
  validate(value, options, model) {
    if (isPresent(value)) {
      let deliverySecondsSinceMidnightStart = value.get('deliverySecondsSinceMidnightStart');
      let deliverySecondsSinceMidnightEnd = value.get('deliverySecondsSinceMidnightEnd');

      if (deliverySecondsSinceMidnightStart >= deliverySecondsSinceMidnightEnd) {
        return 'Your start time needs to be before the end';
      }

      if (model.get('isInApp')) {
        return true; // delivery windows are trigger times for in-app messages. There's no restriction on window length for trigger times.
      }

      if (
        deliverySecondsSinceMidnightEnd <
        deliverySecondsSinceMidnightStart + MINIMUM_GAP_BETWEEN_START_AND_END_IN_HOURS * 3600
      ) {
        return `Your delivery window start time needs be at least ${MINIMUM_GAP_BETWEEN_START_AND_END_IN_HOURS} hours before the end`;
      }
    }
    return true;
  },
});

DeliveryWindowEndIsAfterStart.reopenClass({
  getDependentsFor() {
    return [
      'model.isInApp',
      'model.deliveryWindow.deliverySecondsSinceMidnightStart',
      'model.deliveryWindow.deliverySecondsSinceMidnightEnd',
    ];
  },
});

export default DeliveryWindowEndIsAfterStart;
