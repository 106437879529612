/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';

const RulesetHasValidGoal = BaseValidator.extend({
  validate(value, options, model) {
    let predicateGroup = model.get('goal.predicateGroup');
    return predicateGroup.hasPredicates && predicateGroup.predicates.isEvery('isValid', true);
  },
});

RulesetHasValidGoal.reopenClass({
  getDependentsFor() {
    return ['model.goal.predicateGroup.predicates.@each.isValid'];
  },
});

export default RulesetHasValidGoal;
