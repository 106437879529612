/* RESPONSIBLE TEAM: team-workflows */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    steps: { embedded: 'always' },
    outwardConnectionPoint: { embedded: 'always' },
    _uuid: { serialize: false },
    placeholderName: { serialize: false },
  },

  serialize(snapshot) {
    let json = this._super(...arguments);
    this._maybeGeneratePrimaryIdFromUuid(json, snapshot);
    return json;
  },

  _maybeGeneratePrimaryIdFromUuid(json, snapshot) {
    json[this.primaryKey] = json[this.primaryKey] ?? snapshot.record._uuid;
  },
});
