/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Inbox2ImagePreviewService extends Service {
  @tracked imagePreviewUrl?: string;

  @action onPreviewImageClick(imageSrc: string) {
    this.imagePreviewUrl = imageSrc;
  }

  @action closeImagePreview() {
    this.imagePreviewUrl = undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2ImagePreviewService: Inbox2ImagePreviewService;
    'inbox2-image-preview-service': Inbox2ImagePreviewService;
  }
}
