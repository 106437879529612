/* RESPONSIBLE TEAM: team-frontend-tech */
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import TagIdToSearchParam from 'embercom/helpers/tag-id-to-search-param';

export function getInboxTagUrl(tag) {
  let appId = tag.app_id;
  let tagIdToSearchParam = new TagIdToSearchParam();
  let tagFilter = tagIdToSearchParam.compute([tag.id]);
  return `/a/inbox/${appId}/inbox/search?filters=${tagFilter}`;
}

export function getUserOrCompanyUrl(tag, type) {
  return B64FilterParamEncoder.tagUrl(tag, type);
}

export function tagFilterUrl(tag, type) {
  if (['conversation', 'comment'].indexOf(type) !== -1) {
    return getInboxTagUrl(tag);
  } else {
    return getUserOrCompanyUrl(tag, type);
  }
}
