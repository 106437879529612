/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  localizedBannerContents: {
    description: 'Localized Banner Content',
    validators: [validator('has-many'), validator('length', { min: 1 })],
  },
  action: {
    description: 'Action',
    validators: [validator('banner-button-text-length'), validator('banner-action-url-valid')],
  },
});
