/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';

const WorkflowHasValidTriggerData = BaseValidator.extend({
  validate(_value, _options, model) {
    if (!model.awaitingFirstReplyTrigger && !model.awaitingEndUserReplyTrigger) {
      return true;
    }

    return !model.hasInvalidDelay;
  },
});

WorkflowHasValidTriggerData.reopenClass({
  getDependentsFor() {
    return ['model.data', 'model.triggerTypes'];
  },
});

export default WorkflowHasValidTriggerData;
