/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const BannerBlocksNotEmpty = BaseValidator.extend({
  validate(value, options, model) {
    if (!value.get('firstObject.hasContent')) {
      return 'Finish writing your banner message';
    }
    return true;
  },
});

BannerBlocksNotEmpty.reopenClass({
  getDependentsFor() {
    return ['model.blocks.firstObject.hasContent'];
  },
});

export default BannerBlocksNotEmpty;
