/* RESPONSIBLE TEAM: team-tickets-1 */
import JSONAPISerializer from '@ember-data/serializer/json-api';
import { underscore } from '@ember/string';

export default class InboxSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }
  keyForRelationship(attr) {
    return underscore(attr);
  }
}
