/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';

export default class WindowVisibilityEventsService extends Service {
  @service intercomEventService;
  @service activityTrackingSamplingService;

  registerListeners() {
    window.addEventListener('blur', this);
    window.addEventListener('focus', this);
  }

  handleEvent(event) {
    if (this.activityTrackingSamplingService.shouldSampleActivity) {
      this.intercomEventService.trackAnalyticsEvent({
        action: event.type,
        object: 'window',
        section: 'global',
        place: 'global',
      });
    }
  }

  willDestroy() {
    window.removeEventListener('blur', this);
    window.removeEventListener('focus', this);
  }
}
