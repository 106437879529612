/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';
import type Workspace from 'embercom/objects/workspace';
import moment from 'moment-timezone';
export default class CardlessTrialService extends Service {
  @service declare customerService: any;
  @service declare appService: any;
  @service declare purchaseAnalyticsService: any;

  @tracked appId?: string;
  @tracked testAppId?: string;
  @tracked parentAppId?: string;
  @tracked isTestApp?: boolean;
  @tracked customerId?: string;
  @tracked customer?: any;
  @tracked inProductSalesExperience?: string;
  @tracked signedUpWithSolutionId?: number;

  // tell the service to get the information it needed from the app model (used by the /apps route)
  initializeUsingApp(app: any) {
    this.appId = app.id;
    this.testAppId = app.test_app_id;
    this.parentAppId = app.parent_app_id;
    this.isTestApp = app.is_test_app;
    this.customerId = app.customerId;
    this.inProductSalesExperience = app.inProductSalesExperience;
    this.signedUpWithSolutionId = app.signedUpWithSolutionId;
    this.loadCustomer();
    this.loadCustomerService();
  }

  // tell the workspace to get the information it needed from the workspace model (used by the inbox2 route)
  initializeUsingWorkspace(workspace: Workspace) {
    this.appId = workspace.id;
    this.testAppId = workspace.testAppId;
    this.parentAppId = workspace.parentAppId;
    this.isTestApp = workspace.isTestWorkspace;
    this.customerId = workspace.customerId;
    this.inProductSalesExperience = workspace.inProductSalesExperience;
    this.loadCustomer();
    this.loadCustomerService();
    this.signedUpWithSolutionId = workspace.signedUpWithSolutionId;
  }

  async loadCustomer(forceReload = false) {
    // this will be called for pretty much all the pages, but most of the tests don't have a customer setup
    // so skip this for test environment
    // however, for some tests we do need to load the customer information
    // so use `cardlessTrialService.LoadCustomerInfo` to control this behaviour in tests
    if (ENV.environment === 'test' && !ENV.APP.cardlessTrialServiceLoadCustomerInfo) {
      return;
    }
    if (typeof this.customerId === 'undefined') {
      throw new Error('no customer id found');
    }
    if (forceReload || !this.customer || (this.customer && this.customer.id !== this.customerId)) {
      this.customer = await this.customerService.fetchCustomer(this.customerId);
    }
  }

  async loadCustomerService() {
    if (ENV.environment === 'test' && !ENV.APP.cardlessTrialServiceLoadCustomerInfo) {
      return;
    }
    await this.appService.loadApp(this.appId);
    this.customerService.ensureDataIsLoaded.perform();
  }

  // TODO: deprecate this and use customer.inCardlessTrial instead.
  get isInCardlessTrial() {
    // this.customer is a tracked property, which means isInCardlessTrial will be updated whenever this.customer is updated
    if (!this.customer) {
      return false;
    }
    return this.customer.inCardlessTrial || this.customer.inExpiredCardlessTrial;
  }

  get activeOrExpiredPlanTrialIds() {
    return this.trialHasEnded
      ? this.customerService.inactivePlanTrialIdsSortedByLatest
      : this.customerService.activePlanTrialIds;
  }

  get hasActiveBillingAdminTrialSubscription() {
    if (!this.customer) {
      return false;
    }

    return this.customer.hasActiveBillingAdminTrialSubscription;
  }

  get noActiveSubOnP5() {
    if (!this.customer) {
      return false;
    }
    return !this.customer?.hasActiveSubscription && this.customer?.onPricing5;
  }

  get hasActiveTrialSubscriptionOrIsInCardlessTrial() {
    return this.isInCardlessTrial || this.hasActiveBillingAdminTrialSubscription;
  }

  /**
   * Returns the number of days left in the trial. The final 24 hours of the trial will be counted as 1 day. If the trial has ended, returns -1.
   */
  get daysLeftOfTrial(): number {
    let termStartDate: any = this.customer?.subscription?.termStartDate;
    if (!termStartDate) {
      return -1;
    }
    let start: any = moment(termStartDate);
    let now: any = moment();
    let daysLeft = Math.floor(moment.duration(start - now).asDays());
    return daysLeft;
  }

  get trialHasEnded() {
    return this.isInCardlessTrial && !this.customer?.hasActiveSubscription;
  }

  get isPricing5() {
    return this.customer?.onPricing5;
  }

  get showExtendTrialCTA(): boolean {
    return (
      !this.isPricing5 &&
      (this.trialHasEnded || (this.daysLeftOfTrial >= 0 && this.daysLeftOfTrial <= 3))
    );
  }

  get canUseEarlyStageDetection() {
    return this.customer?.matchEarlyStageDetectionCriterias || false;
  }

  get cardlessVerifyPageUrl() {
    return `${window.location.origin}/admins/verify?solution_id=${this.signedUpWithSolutionId}`;
  }

  get canUseProductSpecialistCTAs() {
    return this.inProductSalesExperience === 'sales_assist';
  }

  get canUseTalkToSalesCTAs() {
    return this.inProductSalesExperience === 'talk_to_sales';
  }

  @action
  triggerAnalytics(context: string, object: string) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      context,
    });
  }

  convertingFromCardlessTrial(transition: Transition) {
    return transition.to.queryParams.cardless_trial === 'true';
  }
}

declare module '@ember/service' {
  interface Registry {
    cardlessTrialService: CardlessTrialService;
    'cardless-trial-service': CardlessTrialService;
  }
}
