/* RESPONSIBLE TEAM: team-tickets-1 */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const WorkflowStepSupportedByContext = BaseValidator.extend({
  intl: service(),
  appService: service(),

  validate(stepType, _options, model) {
    if (!this.appService.app?.canUseWorkflowsTicketsV3) {
      return true;
    }

    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }

    let supportedContexts = model.constructor.supportedContexts;
    let targetContexts = editorConfig.targetContexts;

    // Skip this validator until we can get the target contexts
    // Or if the step type has no target contexts specified
    if (isEmpty(targetContexts)) {
      return true;
    }

    if (isEmpty(supportedContexts)) {
      return false;
    }

    let unsupportedContexts = targetContexts.filter(
      (context) => !supportedContexts.includes(context),
    );

    if (!isEmpty(unsupportedContexts)) {
      return this.intl.t(
        `operator.workflows.visual-builder.validations.step-not-supported-by-context.${model.typeKey}`,
        {
          context: this.intl.formatList(
            unsupportedContexts.map((context) =>
              this.intl.t('operator.workflows.context.type', { context: TargetContext[context] }),
            ),
            {
              style: 'long',
              type: 'conjunction',
            },
          ),
          default:
            'operator.workflows.visual-builder.validations.step-not-supported-by-context.default',
        },
      );
    } else {
      return true;
    }
  },
});

WorkflowStepSupportedByContext.reopenClass({
  getDependentsFor() {
    return ['model.editorConfig.targetContexts', 'model.editorConfig'];
  },
});

export default WorkflowStepSupportedByContext;
