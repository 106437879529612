/* RESPONSIBLE TEAM: team-web */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-private-routing-service */
import { getOwner } from '@ember/application';
import Service from '@ember/service';
import Gtm from '@intercom/gtm-js';
import ENV from 'embercom/config/environment';
import { MARKETO_ACCOUNT_ID, MARKETO_FORM_IDS, MARKETO_URL } from 'embercom/lib/gtm';
import { captureException } from 'embercom/lib/sentry';
import { inject as service } from '@ember/service';

export default class GtmService extends Service {
  /** @type {import('@intercom/gtm-js').default | null} */
  intercomGtm = null;
  loadedThirdPartyScripts = false;
  globalPageViewTrackingEnabled = false;
  @service googleTagManagerService;

  constructor() {
    super(...arguments);
    this.intercomGtm = new Gtm();
  }

  screenSize() {
    return this.intercomGtm.device;
  }

  startTrackingPageViewsGlobally() {
    if (this.globalPageViewTrackingEnabled) {
      return;
    }

    let currentContext = this;
    getOwner(this)
      .lookup('router:main')
      .on('didTransition', function () {
        currentContext._trackPageView();
      });

    this.globalPageViewTrackingEnabled = true;
  }

  _trackPageView() {
    this.recordPageView().then(() => this.recordOnPageviewEvent());
    this.recordPageViewInThirdPartyTools();
  }

  loadThirdPartyScripts() {
    if (this.loadedThirdPartyScripts) {
      return;
    }

    let intercomGtm = this.intercomGtm;
    intercomGtm.loadGoogleAnalytics(ENV.APP.google_analytics_id);
    intercomGtm.loadMarketoMunchkin();
    intercomGtm.loadFacebookPixel();
    intercomGtm.loadGooglePixel();
    intercomGtm.loadClearbit();

    this.loadedThirdPartyScripts = true;
  }

  recordEvent(eventName, metadata) {
    let intercomGTM = this.intercomGtm;
    let pageviewId = intercomGTM.pageviewId;
    let app = getOwner(this).lookup('route:apps/app').app;

    if (app && metadata) {
      metadata['app_id_code'] = app.get('id');
      metadata['admin_id'] = app.get('currentAdmin.id');
    }

    if (pageviewId) {
      return intercomGTM.recordEvent(eventName, pageviewId, metadata);
    }
  }

  pageViewId() {
    return this.intercomGtm.pageviewId;
  }

  recordPageView(metadata) {
    return this.intercomGtm.recordPageView({ metadata });
  }

  recordOnPageviewEvent() {
    return this.intercomGtm.recordOnPageviewEvent(window);
  }

  recordPageViewInThirdPartyTools() {
    let intercomGtm = this.intercomGtm;
    intercomGtm.recordPageViewInThirdPartyTools();
  }

  setupMarketo(formId) {
    try {
      let IntercomGTM = this.intercomGtm;
      if (IntercomGTM.initMarketoForms) {
        IntercomGTM.initMarketoForms(MARKETO_URL, MARKETO_ACCOUNT_ID, formId);
      }
    } catch (e) {
      console.error(e);
      captureException(e);
    }
  }

  fireMarketoEvent(formId, data) {
    try {
      let IntercomGTM = this.intercomGtm;
      let firstName = '';
      let lastName = '';
      try {
        let arr = (data.name || '').split(' ');
        firstName = arr.splice(0, 1)[0];
        lastName = arr.join(' ');
        delete data['name'];
      } catch (e) {
        captureException(e);
      }
      let formData = Object.assign(
        {
          FirstName: firstName || '',
          LastName: lastName || '',
          Company: data['Company'] || '',
        },
        data,
      );
      IntercomGTM.recordMarketoEvent(formId, formData)
        .catch((error) => {
          captureException(error);
        })
        .then(() => true);
      this.fireGoogleTagManagerEvent(formId);
      this.recordEmailSubmission(data.Email, formId, data);
    } catch (e) {
      captureException(e);
    }
  }

  fireGoogleTagManagerEvent(formId) {
    this.googleTagManagerService.pushEvent({
      event: 'marketo-form-submitted',
      metadata: {
        'marketo-form-id': formId,
        origin: window.location.href,
      },
    });
  }

  marketoTrialSignupStart(email) {
    // Someone went to the login page and then went "don't have an account, sign up"
    try {
      // marketo Trial (Start Signup)
      let IntercomGTM = this.intercomGtm;
      if (IntercomGTM.initMarketoForms) {
        IntercomGTM.initMarketoForms(
          MARKETO_URL,
          MARKETO_ACCOUNT_ID,
          MARKETO_FORM_IDS.trialSignupStart,
        ).then(() => {
          try {
            IntercomGTM.recordMarketoEvent(MARKETO_FORM_IDS.trialSignupStart, {
              Email: email,
            });
          } catch (e) {
            captureException(e);
          }
        });
      }
    } catch (e) {
      captureException(e);
    }
  }

  // Sends the email submission to our backend + marketo. Returns the email submission ID from our backend
  async recordEmailSubmission(email, marketoFormID, extraPayload) {
    let IntercomGTM = this.intercomGtm;

    // Load the marketo form
    await IntercomGTM.initMarketoForms(MARKETO_URL, MARKETO_ACCOUNT_ID, marketoFormID);

    // Record the email submission in our backend and marketo
    let { id } = await IntercomGTM.recordEmailSubmission(
      email,
      true,
      IntercomGTM.pageviewId,
      extraPayload,
      null,
      marketoFormID,
    );

    // Fire third party events on email submission
    IntercomGTM.recordThirdPartyEventsOnEmailSubmission(id);

    return id;
  }

  async getCookieConsentMode() {
    return await this.intercomGtm.getCookieConsentMode();
  }
}
