/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service from '@ember/service';
import MetaTags from 'embercom/lib/meta-tags';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import ENV from 'embercom/config/environment';

export default class AppstoreMetaTagsService extends Service {
  siteName = 'Intercom Apps & Integrations';
  imageUrl = 'https://static.intercomassets.com/appstore/images/seo/public_app_store_home.png';

  getMetaTags({
    appPackage,
    category,
    capability,
  }: {
    appPackage: $TSFixMe;
    category: $TSFixMe;
    capability: $TSFixMe;
  }) {
    let title = this.getTitle(appPackage, category, capability);
    let description = this.getDescription(appPackage);
    let url = this.getUrl(appPackage, category, capability);

    return this.buildMetaTags(url, title, description);
  }

  buildMetaTags(url: string, title: string, description: string) {
    return [
      MetaTags.buildMetaTag('description', description),
      MetaTags.buildLinkTag('canonical', url),
      MetaTags.buildOpenGraphTag('locale', 'en_US'),
      MetaTags.buildOpenGraphTag('type', 'website'),
      MetaTags.buildOpenGraphTag('title', title),
      MetaTags.buildOpenGraphTag('description', description),
      MetaTags.buildOpenGraphTag('url', url),
      MetaTags.buildOpenGraphTag('site_name', this.siteName),
      MetaTags.buildOpenGraphTag('image', this.imageUrl),
      MetaTags.buildTwitterTag('card', 'summary'),
      MetaTags.buildTwitterTag('description', description),
      MetaTags.buildTwitterTag('title', title),
      MetaTags.buildTwitterTag('image', this.imageUrl),
      MetaTags.buildTwitterTag('site', '@intercom'),
    ];
  }

  getTitle(appPackage: $TSFixMe, category: $TSFixMe, capability: $TSFixMe) {
    if (appPackage) {
      return `${appPackage.get('name')} | ${this.siteName}`;
    } else if (category) {
      return `${category.get('name')} | ${this.siteName}`;
    } else if (capability) {
      return `${capability.name} | ${this.siteName}`;
    }
    return this.siteName;
  }

  getDescription(appPackage: $TSFixMe) {
    if (appPackage) {
      return appPackage.get('description');
    }
    return 'Powerful apps and integrations to acquire, engage and retain more customers with Intercom.';
  }

  getUrl(appPackage: $TSFixMe, category: $TSFixMe, capability: $TSFixMe) {
    let appstoreRootPath = `${
      ENV.APP.marketingSiteUrl
    }${EmbercomBootDataModule.publicPageRootUrl()}`;
    if (appPackage) {
      return `${appstoreRootPath}apps/${appPackage.get('id')}`;
    } else if (category) {
      return `${appstoreRootPath}?category=${category.get('slug')}`;
    } else if (capability) {
      return `${appstoreRootPath}?capability=${capability.slug}`;
    }
    return this.cleanCurrentUrl();
  }

  cleanCurrentUrl() {
    let currentUrl = window.location.href;
    if (currentUrl.endsWith('/')) {
      currentUrl = currentUrl.slice(0, -1);
    }
    return currentUrl;
  }
}
