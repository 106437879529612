/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

// This is to support Inbox 1 urls which include an anchor to a specific conversation part
// e.g. https://app.intercom.com/a/apps/tx2p130c/inbox/inbox/conversation/30#part_id=comment-30-948
export default class InboxRedirectionService extends Service {
  @tracked anchorId?: string;

  setConversationPartAnchor(anchorId: string) {
    this.anchorId = anchorId;
  }

  unsetConversationPartAnchor() {
    this.anchorId = undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxRedirectionService: InboxRedirectionService;
    'inbox-redirection-service': InboxRedirectionService;
  }
}
