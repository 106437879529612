/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';
import ScriptData from 'embercom/lib/home/guide-assistant-scripts';
import { readOnly } from '@ember/object/computed';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default Service.extend({
  intl: service(),
  appService: service(),
  app: readOnly('appService.app'),
  currentAdmin: readOnly('app.currentAdmin'),
  onboardingHomeService: service(),
  latestAvailableOrPendingCompletedStep: readOnly(
    'onboardingHomeService.guide.latestAvailableOrPendingCompletedStep',
  ),

  onboardingAssistant: {
    name: 'Phil',
    avatarLink: assetUrl('/assets/images/onboarding/home/guide-assistants/phil.png'),
  },

  _getScriptData() {
    if (this.app.isTestApp) {
      return ScriptData.testApp;
    }

    if (this.latestAvailableOrPendingCompletedStep) {
      return this.latestAvailableOrPendingCompletedStep.stepData.generateGuideAssistantScript(
        this.onboardingHomeService.guide.identifier,
      );
    } else {
      return ScriptData.allStepsFinished;
    }
  },

  refreshScript() {
    let script = this._getScriptData();
    this.set('scriptKey', script.scriptKey);
  },

  triggerTransitionalScript() {
    let script = this._getScriptData();
    if (script.hasTransitionalScript) {
      this.set('scriptKey', script.transitionalScriptKey);
    }
  },
});
