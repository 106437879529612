/* RESPONSIBLE TEAM: team-workflows */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  normalizeResponse(store, primaryModelClass, response, id, requestType) {
    response.paths = this._normalizePaths(response.paths);
    return this._super(...arguments);
  },

  _normalizePaths(paths) {
    if (!paths) {
      return paths;
    }

    paths = paths.map((path) => ({
      ...path,
      _id: path.id,
    }));

    return paths;
  },
});
