/* RESPONSIBLE TEAM: team-phone */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const PhoneWorkflowStepConstraints = BaseValidator.extend({
  intl: service(),
  contentEditorService: service(),
  appService: service(),

  validate(_value, _options, model) {
    let rulesetLink = this.contentEditorService.activeRulesetLink;
    // Only validate if the ruleset link is phone workflow
    if (!rulesetLink || !rulesetLink.object.isPhoneCallTrigger) {
      return true;
    }

    if (model.steps.any((step) => step.typeKey === 'hang-up')) {
      let isInvalid = model.steps
        .map((step, index) => {
          return step.typeKey === 'hang-up' && index !== model.steps.length - 1;
        })
        .some((s) => s);

      if (isInvalid) {
        return this.intl.t(
          'operator.workflows.visual-builder.validations.phone-workflow-constraints.hang-up',
        );
      }
    }

    return true;
  },
});

PhoneWorkflowStepConstraints.reopenClass({
  getDependentsFor() {
    return ['model.steps.[]'];
  },
});

export default PhoneWorkflowStepConstraints;
