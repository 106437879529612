/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { isEmpty } from '@ember/utils';
import { warn } from '@ember/debug';
import containerLookup from 'embercom/lib/container-lookup';
import type MissingTranslations from 'embercom/services/missing-translations';

/**
 * @private
 * @hide
 */
export default function missingMessage(key: string, locales: string[]): string {
  if (isEmpty(locales)) {
    warn(
      `[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl`,
      false,
      {
        id: 'ember-intl-no-locale-set',
      },
    );

    return `No locale defined.  Unable to resolve translation: "${key}"`;
  }

  let localeNames = locales.join(', ');

  warn(`[ember-intl] translation: "${key}" on locale: "${localeNames}" was not found.`, false, {
    id: 'ember-intl-missing-translation',
  });

  // Yes this is nasty to lookup the service by the container, but Ember Intl
  // provides no hooks and just suggests overriding this class. Here we look up
  // the missing translations service so we can notify the backend about translations
  // we've missed.
  let missingTranslationsService = containerLookup(
    'service:missingTranslations',
  ) as MissingTranslations;

  if (missingTranslationsService) {
    missingTranslationsService.notifyMissingTranslation(key, localeNames);
  }

  return `Missing translation "${key}" for locale "${localeNames}"`;
}
