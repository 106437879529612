/* RESPONSIBLE TEAM: team-workflows */

import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const AttributeIdentifierIsTextAttribute = BaseValidator.extend({
  attributeService: service(),
  intl: service(),

  validate(identifier, options) {
    let attributes = this.attributeService.textOnlyBotAutoMessageCollectableAttributes;

    if (identifier && !attributes.some((attribute) => attribute.identifier === identifier)) {
      return this.intl.t(options.messageKey);
    }

    return true;
  },
});

export default AttributeIdentifierIsTextAttribute;
