/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { CHANNEL_WEB } from '../lib/operator/custom-bots/constants';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type AttributeCollector from 'embercom/models/operator/visual-builder/step/attribute-collector';

export default class AttributeCollectorStepSupportedByChannels extends BaseValidator {
  @service declare intl: $TSFixMe;
  @service declare attributeService: any;

  validate(_stepType: any, _options: ValidationOptions, model: AttributeCollector | any) {
    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }

    if (isEmpty(editorConfig.targetChannels)) {
      return true; // Skip this validator until we can get the target channels
    }

    let targetChannels = model.editorConfig.targetChannels;

    if (!model?.attributeIdentifier) {
      return true;
    }

    let attributeIdentifier = model?.attributeIdentifier;

    let attribute = [...this.attributeService.botAutoMessageCollectableAttributes].find(
      (attribute) => attribute.identifier === attributeIdentifier,
    );

    if (attribute.type !== DataType.Files) {
      return true;
    }

    let fileAttributeSupportedChannels = [CHANNEL_WEB];

    let unsupportedChannels = targetChannels.filter(
      (channel: $TSFixMe) => !fileAttributeSupportedChannels.includes(channel),
    );

    if (!isEmpty(unsupportedChannels)) {
      return this.intl.t(
        `operator.workflows.visual-builder.validations.step-not-supported-by-channel.${model.typeKey}`,
        {
          channels: this.intl.formatList(
            unsupportedChannels.map((channel: $TSFixMe) =>
              this.intl.t('operator.workflows.channels.type', { channel }),
            ),
            {
              style: 'long',
              type: 'conjunction',
            },
          ),
        },
      );
    } else {
      return true;
    }
  }

  static getDependentsFor() {
    return ['model.editorConfig', 'model.editorConfig.targetChannels'];
  }
}
