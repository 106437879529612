/* RESPONSIBLE TEAM: team-product-exploration */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SearchStateService extends Service {
  @tracked isGlobalSearchOpen = false;

  toggleGlobalSearch(): void {
    this.isGlobalSearchOpen = !this.isGlobalSearchOpen;
  }

  closeAll(): void {
    this.isGlobalSearchOpen = false;
  }
}

declare module '@ember/service' {
  interface Registry {
    searchStateService: SearchStateService;
    'search-state-service': SearchStateService;
  }
}
