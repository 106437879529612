/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Transform from '@ember-data/serializer/transform';
import { isEmpty } from '@ember/utils';

// From ember-data-model-fragments README...
// Relationships to Models
// Currently, fragments cannot have normal belongsTo or hasMany relationships. This is not a technical limitation,
// but rather due to the fact that relationship management in Ember Data is in a state of flux and would require
// accessing private (and changing) APIs

// We have at least one relationship from a model fragment to a model (spamming-stats => user)
// This transform is a reasonable alternative for now.

export default Transform.extend({
  deserialize(serialized, opts = {}) {
    if (!serialized) {
      return serialized;
    }
    if (isEmpty(opts.modelName)) {
      throw new Error('You must supply a model name');
    }
    this.store.pushPayload({ [opts.modelName]: serialized });
    return serialized.map((s) => this.store.peekRecord(opts.modelName, s.id));
  },

  serialize(deserialized = []) {
    if (!deserialized) {
      return deserialized;
    }
    return deserialized.map((d) => d.serialize());
  },
});
