/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const ControlGroupMessageHasGoal = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('hasControlGroup') && model.get('hasNoGoal')) {
      return 'You must add a goal to use control groups';
    }
    return true;
  },
});

ControlGroupMessageHasGoal.reopenClass({
  getDependentsFor() {
    return ['model.hasControlGroup', 'model.hasNoGoal'];
  },
});

export default ControlGroupMessageHasGoal;
