/* RESPONSIBLE TEAM: team-proactive-support */
import Service from '@ember/service';
import { get } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { COPILOT_SEAT_TYPE } from 'embercom/lib/settings/seats/constants';
import { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';

export default class CopilotDataService extends Service {
  @service appService: any;
  @service customerService: any;

  @tracked contractedLimit = 0;
  @tracked pending = 0;
  @tracked seatPrice = 0;
  @tracked seatUsage = 0;
  @tracked copilotPrice = 0;

  get copilotData() {
    return {
      seatUsages: {
        contractedLimit: this.contractedLimit,
        pending: this.pending,
        used: this.seatUsage,
        price: this.seatPrice,
      },
      price: this.copilotPrice,
    };
  }

  async ensureSetup() {
    await this.fetchSeatUsage();
    await this.loadCopilotPrice();

    return this.copilotData;
  }

  async loadCopilotPrice() {
    try {
      await this.customerService.bulkLoadPrices([
        {
          planIds: [parseInt(FIN_AI_COPILOT_BASE_ID, 10)],
        },
      ]);

      this.copilotPrice =
        this.customerService.prices.find((price: any) =>
          price.hasSamePlans([parseInt(FIN_AI_COPILOT_BASE_ID, 10)]),
        )?.perUnitPrice ?? 0;
    } catch (e) {
      console.error('Failed to load Copilot price', e);
    }
  }

  updateSeatUsage(change: 'increment' | 'decrement') {
    if (change === 'increment') {
      this.seatUsage++;
    } else {
      this.seatUsage--;
    }
  }

  async fetchSeatUsage() {
    await this.customerService.ensureDataIsLoaded.perform({ fetchPrices: false });
    let seatsUsed = await get('/ember/settings/seats/used_and_pending_seat_count', {
      app_id: this.appService.app.id,
    });

    let copilotSeatUsage = seatsUsed[COPILOT_SEAT_TYPE];

    let { includedSeatCountPerType, pricePerSeatTypes } = this.customerService.customer;

    this.contractedLimit = includedSeatCountPerType[COPILOT_SEAT_TYPE] || 0;
    this.seatUsage = copilotSeatUsage?.used || 0;
    this.pending = copilotSeatUsage?.pending || 0;
    this.seatPrice = pricePerSeatTypes[COPILOT_SEAT_TYPE] || 0;
  }
}

declare module '@ember/service' {
  interface Registry {
    copilotDataService: CopilotDataService;
    'copilot-data-service': CopilotDataService;
  }
}
