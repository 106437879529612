/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { and } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  state: {
    description: 'State',
    validators: [
      validator('presence', true),
      validator('inclusion', { in: ['draft', 'live', 'scheduled', 'stopped'] }),
    ],
  },
  type: {
    description: 'Type',
    validators: [
      validator('presence', true),
      validator('inclusion', { in: ['Email', 'In-App', 'Push'] }),
    ],
  },
  deliveryChannel: {
    description: 'Delivery Channel',
    validators: [
      validator('number', { allowBlank: true, gte: -1, lte: 4 }),
      validator('delivery-channel-valid-for-audience'),
      validator('delivery-channel-admin-must-verify-email'),
    ],
  },
  dueToStopAt: {
    validators: [
      validator('date', {
        onOrAfter: new Date(),
        precision: 'day',
        message: 'Choose a stop date in the future',
      }),
    ],
  },
  scheduledToGoLiveAt: {
    validators: [
      validator('date', {
        onOrAfter: new Date(),
        precision: 'hour',
        message: 'Choose a start date in the future',
        disabled: and('model.isNotDraft', 'model.isNotScheduled'),
      }),
      validator('message-start-at-stop-at-is-valid'),
    ],
  },
  deliveryWindow: {
    description: 'Delivery Window',
    validators: [
      validator('belongs-to'),
      validator('delivery-window-only-permitted-for-email-or-push'),
      validator('delivery-window-end-is-after-start'),
    ],
  },
  followUpRules: {
    description: 'Follow Up Rules',
    validators: [validator('follow-up-rule-is-valid')],
  },
  goal: {
    description: 'Goal',
    validators: [validator('belongs-to'), validator('control-group-message-has-goal')],
  },
  pageTarget: {
    description: 'Page Targeting',
    validators: [validator('belongs-to')],
  },
  selectionState: {
    description: 'Audience',
    validators: [
      validator('presence', true),
      validator('belongs-to'),
      validator('auto-message-has-user-predicates'),
      validator('message-limit-user-predicates'),
    ],
  },
  audiencePreview: {
    description: 'Audience Selection',
    validators: [validator('manual-message-has-count')],
  },
  variations: {
    description: 'Message Variations',
    validators: [
      validator('has-many'),
      validator('length', { min: 1, max: 2 }),
      validator('message-variations-are-different'),
    ],
  },
  isUploading: {
    description: 'The composer upload state',
    validators: [
      validator('inline', {
        validate(value) {
          return value ? 'An upload is in progress' : true;
        },
      }),
    ],
  },
  clientPredicates: {
    description: 'Client Predicates',
    validators: [validator('time-on-page-value'), validator('page-target-predicates')],
  },
});
