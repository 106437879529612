/* RESPONSIBLE TEAM: team-ai-agent */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class HasOutboundNumbers extends BaseValidator {
  @service declare store: Store;

  /* eslint-disable promise/prefer-await-to-then */
  validate() {
    return this.store.findAll('inbox/phone-number').then((numbers: any) => {
      return numbers.length !== 0;
    });
  }
}
