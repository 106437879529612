/* RESPONSIBLE TEAM: team-workflows */
import Service, { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import type Model from '@ember-data/model';

interface PreviewObject {
  save: () => Promise<any[] | Model>;
}

export interface SetupArgs {
  ruleset: any;
  previewObject?: PreviewObject;
  onEdit?: () => void;
  onSetLive?: () => void;
}

export default class WorkflowSidedrawerService extends Service {
  @service declare router: RouterService;

  @tracked sidedrawerOpen = false;
  @tracked ruleset: any;
  previewObject: PreviewObject | undefined;
  onEdit: () => void = () => {};
  onSetLive: () => void = () => {};

  setup({
    ruleset,
    previewObject = undefined,
    onEdit = () => {},
    onSetLive = () => {},
  }: SetupArgs) {
    this.ruleset = ruleset;
    this.sidedrawerOpen = true;
    this.previewObject = previewObject;
    this.onEdit = onEdit;
    this.onSetLive = onSetLive;
  }

  async editWorkflow() {
    let ruleset = this.ruleset;
    if (this.previewObject) {
      ruleset = await this.previewObject.save();
    }
    this.onEdit();
    this.router.transitionTo('apps.app.automation.workflows.edit', ruleset.id, {
      queryParams: {
        mode: 'edit',
        visual: 'enabled',
        returnPath: this.router.currentRouteName,
      },
    });
  }

  async setWorkflowLive() {
    if (this.previewObject) {
      this.ruleset = await this.previewObject.save();
    }
    await this.ruleset.activate();
  }

  teardown() {
    this.ruleset = undefined;
    this.previewObject = undefined;
    this.sidedrawerOpen = false;
  }
}

declare module '@ember/service' {
  interface Registry {
    workflowSidedrawerService: WorkflowSidedrawerService;
    'workflow-sidedrawer-service': WorkflowSidedrawerService;
  }
}
