/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import extractResponseError from 'embercom/lib/extract-response-error';
import { later } from '@ember/runloop';
import { A } from '@ember/array';
import defaultTo from '@intercom/pulse/lib/default-to';
import { action } from '@ember/object';

const defaultResponseProvidedErrors = [403, 422];

const Notification = EmberObject.extend({
  message: null,
  type: defaultTo('help'),
  duration: defaultTo(ENV.APP.default_notification_timeout),
  key: null,
  data: defaultTo({}),
  button: null,
  component: null,

  get hasComponent() {
    return this.component !== null;
  },
});

export default Service.extend({
  conversationsService: service(),
  intercomEventService: service(),
  paywallService: service(),
  permissionsService: service(),
  purchaseAnalyticsService: service(),

  init() {
    this._super(...arguments);
    this.set('notifications', A());
  },

  notify(message, duration, key) {
    this._notify(message, duration, 'default', key);
  },

  notifyConfirmation(message, duration, key) {
    this._notify(message, duration, 'confirmation', key);
  },

  notifyHelp(message, duration, key) {
    this._notify(message, duration, 'help', key);
  },

  notifyError(message, duration, key) {
    this._notify(message, duration, 'error', key);
  },

  notifyWarning(message, duration, key) {
    this._notify(message, duration, 'warning', key);
  },

  notifyLoading(message, duration, key) {
    this._notify(message, duration, 'loading', key);
  },

  removeNotification(key) {
    this._removeNotificationWithKey(key);
  },

  notifyErrorWithModelAndComponent(model, componentClass, duration) {
    let notification = this._createCustomNotification(model, componentClass, 'error', duration);
    this._addObject(notification);
  },

  notifyConfirmationWithModelAndComponent(model, componentClass, duration) {
    let notification = this._createCustomNotification(
      model,
      componentClass,
      'confirmation',
      duration,
    );
    this._addObject(notification);
  },

  notifyWarningWithModelAndComponent(model, componentClass, duration) {
    let notification = this._createCustomNotification(model, componentClass, 'warning', duration);
    this._addObject(notification);
  },

  notifyErrorWithButton(message, button, duration, key) {
    this._notify(message, duration, 'error', key, button);
  },

  notifyWarningWithButton(message, button, duration, key) {
    this._notify(message, duration, 'warning', key, button);
  },

  notifyConfirmationWithButton(message, button, duration, key) {
    this._notify(message, duration, 'confirmation', key, button);
  },

  notifyResponseError(
    response,
    messages = {},
    options = { responseProvidedErrors: defaultResponseProvidedErrors, duration: null },
  ) {
    messages.default = messages.default || 'Something went wrong, please try again.';

    options.responseProvidedErrors =
      options.responseProvidedErrors || defaultResponseProvidedErrors;

    let error = this._getErrorForResponse(response);
    let messageProvidedForErrors = Object.keys(messages)
      .filter((statusCode) => !isNaN(statusCode))
      .map((status) => parseInt(status, 10));
    let useMessageFromError =
      options.responseProvidedErrors.includes(error.status) &&
      !messageProvidedForErrors.includes(error.status);
    if (error.status === 402 && error.required_feature) {
      this._paywallFeature(
        Array.isArray(error.required_feature) ? error.required_feature[0] : error.required_feature,
      );
    } else if (error.status === 403 && error.required_permission) {
      this.permissionsService.checkPermission(error.required_permission);
    } else if (useMessageFromError) {
      this.notifyError(error.message);
    } else {
      let messageForErrorStatus = messages[error.status] || messages.default;
      this.notifyError(messageForErrorStatus, options.duration);
    }
  },

  clear() {
    this.notifications.clear();
  },

  _notify(message, duration, type, key, button) {
    let notification = this._createDefaultNotification(message, duration, type, key, button);
    this._addObject(notification);
  },

  _createDefaultNotification(message, duration, type, key, button) {
    duration = this._getNotificationDuration(duration);
    return Notification.create({ message, duration, type, key, button });
  },

  _getNotificationDuration(duration) {
    if (
      ENV.APP.forced_notification_timeout !== null &&
      ENV.APP.forced_notification_timeout !== undefined
    ) {
      return ENV.APP.forced_notification_timeout;
    }
    return duration;
  },

  _removeNotificationWithKey(key) {
    let matchingNotification = this.notifications.findBy('key', key);
    if (matchingNotification !== null) {
      this._removeObject(matchingNotification);
    }
  },

  _addObject(notification) {
    this.notifications.addObject(notification);

    if (notification.get('duration') > 0) {
      later(
        this,
        function () {
          this._removeObject(notification);
        },
        notification.get('duration'),
      );
    }
  },

  _removeObject(notification) {
    this.notifications.removeObject(notification);
  },

  _createCustomNotification(
    model,
    componentClass,
    type,
    duration = ENV.APP.desktopNotificationTimeout,
    key = {},
  ) {
    return Notification.create({ component: componentClass, duration, type, data: model, key });
  },

  _getErrorForResponse(response) {
    return extractResponseError(response) || {};
  },

  _paywallFeature(feature) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'payment_required',
      context: 'paywall',
      object: 'notifications-service',
      feature,
    });
    return this.paywallService
      .paywall({ featureName: feature })
      .catch(this.paywallService.handleError);
  },

  closeNotification: action(function (notification) {
    this._removeObject(notification);
    if (notification.button) {
      notification.button.action();
    }
  }),
});
