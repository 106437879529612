/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const PageTargetString = BaseValidator.extend({
  validate(value, options, model) {
    if (isBlank(value) && model.get('present')) {
      return 'Add a target URL';
    }
    return true;
  },
});

PageTargetString.reopenClass({
  getDependentsFor() {
    return ['model.present'];
  },
});

export default PageTargetString;
