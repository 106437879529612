/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import isValidUrl from 'embercom/lib/url-validator';

const UrlWithTemplateString = BaseValidator.extend({
  validate(value, _options, model) {
    if (!model.clientData?.insertable) {
      return true;
    }

    let url = value || '';

    // Skip the URL validation if it contains a CDA since we can't reliably validate.
    // Avoids cases like https://github.com/intercom/intercom/issues/138897
    if (url.includes('{{') && url.includes('}}')) {
      return true;
    }

    // Regex to validate IP address – shamelessly stolen from https://stackoverflow.com/questions/5284147
    if (url.match(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/)) {
      return true;
    }

    if (!isValidUrl(url)) {
      return _options.message || 'The URL is invalid';
    }

    return true;
  },
});

UrlWithTemplateString.reopenClass({
  getDependentsFor() {
    return ['model.clientData.insertable'];
  },
});

export default UrlWithTemplateString;
