/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    drafts: {
      embedded: 'always',
    },

    participants: {
      embedded: 'always',
    },

    conversationAttributes: {
      embedded: 'always',
    },

    requestType: {
      embedded: 'always',
    },

    first_user_part: {
      embedded: 'always',
    },

    initiator: {
      embedded: 'always',
    },

    last_part: {
      embedded: 'always',
    },

    last_action_part: {
      embedded: 'always',
    },

    last_part_where_current_admin_is_mentioned: {
      embedded: 'always',
    },

    last_part_with_content_excluding_operator_v2: {
      embedded: 'always',
    },
  },

  normalize(modelClass, resourceHash) {
    if (resourceHash && resourceHash.id) {
      let existingRecord = this.store.peekRecord('conversation', resourceHash.id);

      if (existingRecord) {
        // We rollback the attributes of the existing record here because Ember Data forces the value of a dirty attribute [1]
        // to take precedence over the value returned from the server. This can cause problems when we set, for example, the
        // state of the conversation in the client to CLOSED and the conversation syncing operation loads the conversation,
        // which has been OPENED in the meantime. Since we have set the value of the attribute to CLOSED, it will always return
        // the CLOSED value because the attribute is dirty even though the actual value in the model is OPENED.
        //
        // With this in mind, if the client receives an update from the server, we always want it to read the latest attribute
        // value from the server (and not the dirty attribute value which takes precedence) so we rollback any dirty attributes
        // on the model.
        //
        // 1: A dirty attribute is an model attribute updated locally in the client
        existingRecord.rollbackAttributes();
      }

      if (!resourceHash.links) {
        resourceHash.links = {};
      }

      resourceHash.links.taggings = `/ember/conversations/tags.json?conversation_id=${resourceHash.id}`;
    }

    return this._super(modelClass, resourceHash);
  },
});
