/* RESPONSIBLE TEAM: team-purchase-experience */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { post } from 'embercom/lib/ajax';
import {
  BILLING_PERIODS,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  defaultSolutionId,
  plansFromSolution,
} from 'embercom/lib/billing';
import storage from 'embercom/vendor/intercom/storage';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { captureException } from 'embercom/lib/sentry';
import type StripeIntent from 'embercom/models/billing/stripe-intent';
import type Plan from 'embercom/models/plan';

interface AddressResult {
  city?: string;
  country?: string;
  line1?: string;
  postal_code?: string;
  state?: string;
}

export default class CheckoutService extends Service {
  @service declare appService: any;
  @service declare store: Store;
  @service declare router: Router;
  @service declare customerService: any;
  @service declare purchaseAnalyticsService: any;
  @service declare notificationsService: any;
  @service declare intl: any;
  @service declare stripev3: any;
  @service declare cardlessTrialService: any;
  @service declare earlyStageService: any;

  @tracked billingPeriod = BILLING_PERIODS.Monthly;
  @tracked selectedSeatNumber = 1;
  @tracked seatNumber = 1;
  @tracked minimumSeatNumber = 1;
  @tracked selectedProactiveAddon = false;
  @tracked copilotInitialised = false;
  @tracked selectedCopilotAdmins: string[] = [];
  //Force override of copilot seats to equal seatnumber if required
  @tracked forceFillCopilotAdmins = false;
  @tracked showCreditCardPage = false;
  @tracked paymentMethod = '';
  @tracked selectedPlanId = '';
  @tracked solutionId?: number;
  @tracked stripeIntent?: StripeIntent;

  // billing address attributes
  @tracked countryCode?: string;
  @tracked stateCode?: string;
  @tracked postCode?: string;
  @tracked city?: string;
  @tracked streetAddress?: string;

  constructor() {
    super(...arguments);
    this.billingPeriod = this.isEarlyStageProgression
      ? BILLING_PERIODS.Monthly
      : BILLING_PERIODS.Annual;

    if (!this.solutionId) {
      this.setSolutionAndPlanIds(Number(defaultSolutionId(PRICING_5_X_CORE_ADVANCED_ID)));
    }
  }

  get selectedCopilotAddon() {
    return this.forceFillCopilotAdmins ? this.forceFillCopilotAdmins : this.copilotSeatNumber > 0;
  }

  get sendCopilotAdminIds() {
    return this.forceFillCopilotAdmins ? !this.forceFillCopilotAdmins : this.copilotSeatNumber > 0;
  }

  get copilotSeatNumber() {
    return this.copilotInitialised ? this.selectedCopilotAdmins.length : 0;
  }

  get yearlyBillingPeriodSelected() {
    return this.billingPeriod === BILLING_PERIODS.Annual;
  }

  get selectedPlanIds() {
    let planIds = [Number(this.selectedPlanId)];
    if (this.selectedProactiveAddon) {
      planIds.push(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID));
    }
    if (this.selectedCopilotAddon) {
      planIds.push(Number(FIN_AI_COPILOT_BASE_ID));
    }
    return planIds;
  }

  get queryParamsForRedirect() {
    return {
      solution_id: this.solutionId,
      plan_id: this.selectedPlanId,
      cardless_trial: true,
      billing_period: this.billingPeriod,
      seat_number: this.seatNumber,
      proactive_addon: this.selectedProactiveAddon,
      copilot_addon: this.selectedCopilotAddon,
      city: this.city ? this.city : undefined,
      country_code: this.countryCode,
      street_address: this.streetAddress,
      post_code: this.postCode ? this.postCode : undefined,
      state_code: this.stateCode ? this.stateCode : undefined,
    };
  }

  get app() {
    return this.appService.app;
  }

  get corePlans() {
    let plansOnPricingModel = this.app.allPlansOnPricingModel;
    if (this.isEarlyStageProgression && !this.app.onPricing5) {
      plansOnPricingModel = this.customerService.earlyStageGraduation.plans;
    }
    return plansOnPricingModel.filter((plan: Plan) => !plan.product.addon);
  }

  get isEarlyStageProgression() {
    return this.customerService.isEarlyStageProgression;
  }

  setSelectedCopilotAdmins(selectedAdmins: string[]) {
    this.selectedCopilotAdmins = selectedAdmins;
  }

  setAddress(addressResult: AddressResult) {
    this.countryCode = addressResult.country;
    this.stateCode = addressResult?.state ? addressResult?.state : undefined;
    this.postCode = addressResult?.postal_code ? addressResult?.postal_code : undefined;
    this.city = addressResult?.city ? addressResult.city : undefined;
    this.streetAddress = addressResult.line1;
  }

  setSolutionAndPlanIds(value: number) {
    this.solutionId = Number(value);
    this.selectedPlanId = plansFromSolution(value)[0];
    // Override URL param if solution id doesn't map to a self serve plan
    // Can be removed after release of Exert self serve
    let isSelectedPlanSelfServe = this.corePlans.find(
      (plan: Plan) => plan.id === this.selectedPlanId,
    )?.selfServe;
    if (!isSelectedPlanSelfServe) {
      this.solutionId = Number(defaultSolutionId(PRICING_5_X_CORE_ADVANCED_ID));
      this.selectedPlanId = plansFromSolution(this.solutionId)[0];
    }
    this.setSeatsToHumanAdminsOrCoreSeatLength();
  }

  setSeatsToHumanAdminsOrCoreSeatLength() {
    let hasAssignedCoreSeats = this.appService.app.humanAdminsWithCoreSeat.length;
    let viewingAdvanced = this.selectedPlanId === PRICING_5_X_CORE_ADVANCED_ID;

    this.minimumSeatNumber =
      hasAssignedCoreSeats && viewingAdvanced
        ? this.appService.app.humanAdminsWithCoreSeat.length
        : this.appService.app.humanAdmins.length;

    let isAnnualAndSeatsAreUnderMinimumAdmins =
      this.billingPeriod === BILLING_PERIODS.Annual &&
      this.minimumSeatNumber > this.selectedSeatNumber;

    let isMonthly = this.billingPeriod === BILLING_PERIODS.Monthly;

    if (isMonthly || isAnnualAndSeatsAreUnderMinimumAdmins) {
      this.seatNumber = this.minimumSeatNumber;
      this.selectedSeatNumber = this.minimumSeatNumber;
    }
  }

  @action
  enableForceFillCopilotAdmins() {
    this.forceFillCopilotAdmins = true;
  }

  @action
  setCopilotAsInitialised(value: boolean) {
    this.copilotInitialised = value;
  }

  @action
  setSelectedProactiveAddon(value: boolean) {
    this.selectedProactiveAddon = value;
    this.sendAnalyticsEvent({
      action: value ? 'selected' : 'unselected',
      object: 'proactive_support_addon_selector',
    });
  }

  @action
  updateBillingPeriod(value: BILLING_PERIODS) {
    this.setSeatsToHumanAdminsOrCoreSeatLength();
    if (value === BILLING_PERIODS.Monthly) {
      this.seatNumber = this.minimumSeatNumber;
    } else {
      this.seatNumber = this.selectedSeatNumber;
    }
    this.billingPeriod = value;
    this.sendAnalyticsEvent({
      action: 'selected',
      object: 'billing_period_selector',
      context: { billing_period: `${this.billingPeriod}` },
    });
  }

  @action
  updateSelectedPlanId(plan: Plan) {
    if (!plan.selfServe) {
      window.Intercom('showMessages');
      this.selectedPlanId = PRICING_5_X_CORE_ADVANCED_ID;
    } else {
      this.selectedPlanId = plan.id;
      this.setSeatsToHumanAdminsOrCoreSeatLength();
    }
    // Only Start::Subscription in billing takes into account the solution id
    // Start::Subscription is only used for inactive subscriptions
    // We can use the default solution id "safely" because it will return 5.0 for now and 5.1 when its released
    // Existing active cardless trials will go to convert_cardless_subscription and solution id is not used
    // details : https://github.com/intercom/intercom/issues/327313
    this.solutionId = Number(defaultSolutionId(plan.id));
    this.sendAnalyticsEvent({
      action: 'selected',
      object: 'plan_selector',
      context: { plan: this.selectedPlanId },
    });
  }

  @action
  updateSeatNumber(seatNumber: number) {
    this.selectedSeatNumber = seatNumber;
    this.seatNumber = this.selectedSeatNumber;
    this.sendAnalyticsEvent({
      action: 'inputed',
      object: 'seat_number_input',
      context: { seat_number: `${this.seatNumber}` },
    });
  }

  @action
  updateShowCreditCardPage() {
    this.showCreditCardPage = !this.showCreditCardPage;
    if (this.showCreditCardPage) {
      this.sendAnalyticsEvent({
        action: 'clicked',
        object: 'continue_to_payment_button',
        context: {
          plans: this.selectedPlanIds,
          billing_period: `${this.billingPeriod}`,
          seat_number: `${this.seatNumber}`,
          proactive_addon: `${this.selectedProactiveAddon}`,
        },
      });

      this.sendAnalyticsEvent({
        action: 'viewed',
        object: 'pricing_5_checkout',
      });
    }
  }

  @keepLatestTask
  *createPaymentIntent(amount_in_cent: number): TaskGenerator<StripeIntent> {
    let params: { [key: string]: any } = {
      app_id: this.appService.app.id,
      solution_id: this.solutionId,
      plan_ids: this.selectedPlanIds,
      seats: this.seatNumber,
      billing_period: this.billingPeriod,
      amount_in_cent,
    };
    if (this.earlyStageService.isEarlyStage(String(this.solutionId))) {
      params['force_setup_intent'] = true;
    }
    try {
      let response = yield post('/ember/customers/create_stripe_intent_for_checkout', params);
      this.store.pushPayload('billing/stripe-intent', {
        'billing/stripe-intent': [response],
      });
      this.stripeIntent = this.store.peekRecord('billing/stripe-intent', response.object_id)!;
      return this.stripeIntent;
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  @keepLatestTask
  *updatePaymentIntent(newAmount: number): TaskGenerator<StripeIntent> {
    if (!this.stripeIntent) {
      throw new Error('Error encountered with configuring payment gateway');
    }

    if (!this.stripeIntent.isPaymentIntent) {
      return this.stripeIntent;
    }

    let params = {
      payment_intent_id: this.stripeIntent.id,
      amount_in_cent: newAmount,
      app_id: this.appService.app.id,
    };
    try {
      let response = yield post('/ember/customers/update_payment_intent', params);
      this.stripeIntent.updateFromPayload(response);
      return this.stripeIntent;
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  @keepLatestTask
  *validateAddress(): TaskGenerator<AddressResult> {
    let params = {
      country_code: this.countryCode,
      state_code: this.stateCode,
      postcode: this.postCode,
      city: this.city,
      street_address: this.streetAddress,
      app_id: this.appService.app.id,
    };
    let response;
    try {
      response = yield post('/ember/customers/validate_address', params);
      return response;
    } catch (err) {
      captureException(err);
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
      );
      console.error(err?.jqXHR?.responseJSON?.error);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      throw err;
    }
  }

  @keepLatestTask
  *confirmSetup({ elements, url }: { elements: any; url: string }): TaskGenerator<any> {
    if (!this.stripeIntent) {
      throw new Error('Error encountered with configuring payment gateway');
    }

    yield this.stripev3.load(this.getStripeKey(), {
      stripeAccount: this.stripeIntent.stripeAccountId,
    });

    let params = {
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: url,
      },
    };
    let response;
    if (this.stripeIntent.isPaymentIntent) {
      response = yield this.stripev3.instance.confirmPayment(params);
    } else {
      response = yield this.stripev3.instance.confirmSetup(params);
    }
    return response;
  }

  @keepLatestTask
  *convertSubscription(paymentMethod: string): TaskGenerator<void> {
    let billingPeriod = this.billingPeriod;
    if (this.earlyStageService.isEarlyStage(String(this.solutionId))) {
      billingPeriod = BILLING_PERIODS.Monthly;
    }
    let paramsForAnalytics = {
      billing_period_duration_in_months: billingPeriod,
      seats: this.seatNumber,
      copilot_seat_count: this.copilotSeatNumber,
      solution_id: this.solutionId,
      plan_ids: this.selectedPlanIds,
    };

    let params = {
      ...paramsForAnalytics,
      country_code: this.countryCode,
      street_address: this.streetAddress,
      ...(this.stateCode && { state_code: this.stateCode }),
      ...(this.postCode && { postcode: this.postCode }),
      ...(this.city && { city: this.city }),
      stripe_payment_method_id: paymentMethod,
      app_id: this.appService.app.id,
      partner: this.earlyStageService.partnerSource,
      ...(this.sendCopilotAdminIds && { copilot_admin_ids: this.selectedCopilotAdmins }),
      ...(this.stripeIntent?.isPaymentIntent && { stripe_payment_intent_id: this.stripeIntent.id }),
    };

    try {
      yield post(`/ember/customers/${this.appService.app.id}/convert_subscription`, params);
      yield this.appService.app.reload();
      yield this.customerService.resetAll();
      yield this.cardlessTrialService.loadCustomer(true);
      storage.set('shouldShowConfetti', true);
      this.router.transitionTo('apps.app.checkout.success', this.appService.app.id);
      this.sendAnalyticsEvent({
        action: 'completed',
        object: 'convert_subscription',
        context: paramsForAnalytics,
        solutionId: this.solutionId,
      });
    } catch (err) {
      console.error(err);
      if (err && err?.jqXHR?.responseJSON && err?.jqXHR?.responseJSON[0]) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON[0]);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
      }
    }
  }

  _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  getStripeKey() {
    if (this._isStaging()) {
      return ENV.APP.stripe.sandboxPublishableKey;
    }
    return this.stripev3.publishableKey;
  }

  @action sendAnalyticsEvent({
    action,
    object,
    context,
    solutionId,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
    solutionId?: number;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'app.signup.teams.checkout.pricing5',
      solutionId,
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    checkoutService: CheckoutService;
    'checkout-service': CheckoutService;
  }
}
