/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type Session from './session';
import { type SearchByTypeResponse } from './quick-search';
import SearchableDocument from 'embercom/objects/inbox/searchable-document';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { request } from 'embercom/lib/inbox/requests';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';
import type FuzzySearch from './fuzzy-search';
import { prepareStringForFuzzySearch } from './fuzzy-search';
import Macro, {
  type MacroWireFormat,
  type QuickSearchContexts,
} from 'embercom/objects/inbox/macro';
import { intersection } from 'underscore';

export type MacroResponse = {
  saved_replies: {
    total: number;
    results: MacroWireFormat[];
  };
};

export default class Inbox2MacrosSearch extends Service {
  @service declare session: Session;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare fuzzySearch: FuzzySearch;

  private allMacros: SearchableDocument[] = [];

  async loadAllMacros() {
    try {
      let json = await taskFor(this.fetchMacros).perform();
      this.allMacros = json.saved_replies.results.map(this.toSearchableDocument);
    } catch {
      this.notificationsService.notifyError(
        this.intl.t('inbox.macros-search.error-loading-macros'),
      );
    }
  }

  findById(id: number): Macro | undefined {
    let searchableDocument = this.allMacros.findBy('entityId', id);

    return searchableDocument?.data as Macro;
  }

  get macrosLoaded(): boolean {
    let task = taskFor(this.fetchMacros);
    return !task.isRunning && task.performCount > 0;
  }

  @task({ keepLatest: true })
  private *fetchMacros(): TaskGenerator<MacroResponse> {
    let response = yield request(`/ember/inbox/saved_replies?app_id=${this.session.workspace.id}`);
    let json = (yield response.json()) as unknown as MacroResponse;
    return json;
  }

  async filterMacros(
    searchTerm?: string,
    excludeIds: string[] = [],
    searchContext: QuickSearchContexts[] = [],
    size?: number,
  ): Promise<SearchByTypeResponse> {
    let filteredMacros = excludeIds.length
      ? this.allMacros.filter((macro) => !excludeIds.includes(macro.data.id.toString()))
      : this.allMacros;

    let results;
    if (searchTerm) {
      let filteredByContext = filteredMacros.filter((item) => {
        return !this.isRestrictedInCurrentContext(item, searchContext);
      });

      results = this.fuzzySearch.search(searchTerm, filteredByContext, 'data.name');
    } else if (searchContext.length) {
      results = filteredMacros.filter((savedReply) => {
        let isRestricted = intersection(savedReply.restrictedContexts, searchContext).length > 0;
        return !isRestricted;
      });
    } else {
      results = filteredMacros;
    }

    return {
      total: results.length,
      results: size ? results.slice(0, size) : results,
    };
  }

  private isRestrictedInCurrentContext(
    item: SearchableDocument,
    searchContext: QuickSearchContexts[],
  ): boolean {
    return intersection(item.restrictedContexts, searchContext).length > 0;
  }

  private toSearchableDocument(macro: MacroWireFormat): SearchableDocument {
    return new SearchableDocument(
      macro.id,
      SearchableType.Macro,
      new Date(),
      new Date(),
      Macro.deserialize(macro),
      macro.restricted_contexts,
      undefined,
      prepareStringForFuzzySearch(macro.name),
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2MacrosSearch: Inbox2MacrosSearch;
    'inbox2-macros-search': Inbox2MacrosSearch;
  }
}
