/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    eventId: { embedded: 'always' },
    eventType: { embedded: 'always' },
    eventName: { embedded: 'always' },
    eventPredicateGroup: { embedded: 'always' },
    rulesetId: { embedded: 'always' },
  },
});
