/* RESPONSIBLE TEAM: team-messenger */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    stats: { embedded: 'always' },
    clickTrackingLinks: { embedded: 'always' },
    customFromAddress: { key: 'from_custom_email_address_id' },
    customReplyToAddress: { key: 'reply_to_custom_email_address_id' },
  },
  normalize(modelClass, resourceHash) {
    // Ensure that blocks must be an array
    resourceHash.blocks = resourceHash.blocks || [];
    return this._super(modelClass, resourceHash);
  },
});
