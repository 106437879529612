/* RESPONSIBLE TEAM: team-reporting */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default EmbercomBaseSerializer.extend({
  serialize(snapshot, options) {
    let activePredicates = snapshot.record.activePredicates;
    snapshot._attributes.predicates = activePredicates;
    let serialized = this._super(snapshot, options);
    return serialized;
  },
});
