/* RESPONSIBLE TEAM: team-tickets-1 */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type TeammateBrowserSettings from 'embercom/services/teammate-browser-settings';
import type AdminBrowserSettings from 'embercom/objects/inbox/admin-browser-settings';
import {
  type ThreadCreatedEvent,
  type ThreadUpdatedEvent,
  type ThreadAssignedEvent,
} from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName } from 'embercom/services/nexus';
import type BrowserAlerts from 'embercom/services/browser-alerts';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { isEmpty } from 'underscore';
import { UNASSIGNED_ID } from 'embercom/objects/inbox/admin-summary';
import type Tracing from 'embercom/services/tracing';

type ThreadEvent = ThreadCreatedEvent | ThreadUpdatedEvent | ThreadAssignedEvent;
export default class InboxNexusEventsHandler extends Service {
  @service declare session: Session;
  @service declare teammateBrowserSettings: TeammateBrowserSettings;
  @service declare nexus: Nexus;
  @service declare browserAlerts: BrowserAlerts;
  @service declare intl: IntlService;
  @service declare tracing: Tracing;

  private threadUpdated: (e: ThreadUpdatedEvent) => void;
  private threadCreated: (e: ThreadCreatedEvent) => void;
  private threadAssigned: (e: ThreadAssignedEvent) => void;

  private topics: string[] = [];

  constructor(owner: any) {
    super(owner);
    this.threadUpdated = this.handleThreadUpdatedEvent.bind(this);
    this.threadCreated = this.handleThreadCreatedEvent.bind(this);
    this.threadAssigned = this.handleThreadAssignedEvent.bind(this);
  }

  async startEventHandling() {
    let settings: AdminBrowserSettings = await this.teammateBrowserSettings.fetchSettings();

    this.topics = [];
    this.topics.push(`inbox/admin/${this.session.teammate.id}`);

    this.teammateBrowserSettings.settings.teamIds.forEach((teamId) => {
      this.topics.push(`inbox/team/${teamId}`);
    });

    this.topics.push(`inbox/shared/unassigned`);

    this.nexus.subscribeTopics(this.topics);

    if (settings?.browserAlertSetting) {
      this.nexus.addListener(NexusEventName.ThreadUpdated, this.threadUpdated);
      this.nexus.addListener(NexusEventName.ThreadReopened, this.threadUpdated);
      this.nexus.addListener(NexusEventName.ThreadUnsnoozed, this.threadUpdated);
      this.nexus.addListener(NexusEventName.ThreadCreated, this.threadCreated);
      this.nexus.addListener(NexusEventName.ThreadAssigned, this.threadAssigned);
    }
  }

  stopEventHandling() {
    this.nexus.unsubscribeTopics(this.topics);

    this.nexus.removeListener(NexusEventName.ThreadUpdated, this.threadUpdated);
    this.nexus.removeListener(NexusEventName.ThreadReopened, this.threadUpdated);
    this.nexus.removeListener(NexusEventName.ThreadUnsnoozed, this.threadUpdated);
    this.nexus.removeListener(NexusEventName.ThreadCreated, this.threadCreated);
    this.nexus.removeListener(NexusEventName.ThreadAssigned, this.threadAssigned);
  }

  get tracingEnabled(): boolean {
    return this.session.workspace.isFeatureEnabled('inbox2-audio-notifications-tracing');
  }

  private eventAttributes(event: ThreadEvent) {
    let eventData = event.eventData;

    return {
      'audio_metadata.eventName': event.eventName,
      'audio_metadata.eventGuid': event.eventGuid,
      'audio_metadata.conversationId': eventData?.conversationId,
      'audio_metadata.subType': eventData?.subType,
      'audio_metadata.lastCommentId': eventData?.lastCommentId,
      'audio_metadata.visibilityState': document.visibilityState,
    };
  }

  private async handleThreadUpdatedEvent(event: ThreadUpdatedEvent) {
    await this.tracing.inSpan(
      {
        name: 'handle-nexus-event',
        resource: 'nexus-event:thread_updated',
        attributes: { ...this.eventAttributes(event) },
        enabled: this.tracingEnabled,
      },
      async () => {
        let threadUpdatedData = event.eventData;
        if (!threadUpdatedData) {
          return;
        }

        let { subType, lastActivityPreview } = threadUpdatedData;
        let isComment = subType === 'comment';
        let isReopenedDueToReply = subType === 'open' && !isEmpty(lastActivityPreview);
        if ((isComment || isReopenedDueToReply) && this.shouldReceiveBrowserAlert(event)) {
          if (threadUpdatedData.lastActivityOwnerId) {
            this.browserAlerts.cancelPageTitleToggle(threadUpdatedData.conversationId);
          } else {
            await this.playSoundAndToggleTitle(
              `${this.intl.t('inbox.user-says', {
                user: threadUpdatedData.userDisplayAs || this.intl.t('inbox.someone'),
              })}…`,
              threadUpdatedData.conversationId,
            );
          }
        }
      },
    );
  }

  private async handleThreadCreatedEvent(event: ThreadCreatedEvent) {
    await this.tracing.inSpan(
      {
        name: 'handle-nexus-event',
        resource: 'nexus-event:thread_created',
        attributes: { ...this.eventAttributes(event) },
        enabled: this.tracingEnabled,
      },
      async () => {
        let threadCreatedData = event.eventData;
        this.tracing.tagActiveSpan({
          'audio_metadata.eventDataExists': !!threadCreatedData,
        });
        if (threadCreatedData && this.shouldReceiveBrowserAlert(event)) {
          await this.playSoundAndToggleTitle(
            threadCreatedData.userDisplayAs
              ? this.intl.t('inbox.new-message-from', { user: threadCreatedData.userDisplayAs })
              : this.intl.t('inbox.new-message-received'),
            threadCreatedData.conversationId,
          );
        }
      },
    );
  }

  private async handleThreadAssignedEvent(event: ThreadAssignedEvent) {
    await this.tracing.inSpan(
      {
        name: 'handle-nexus-event',
        resource: 'nexus-event:thread_assigned',
        attributes: { ...this.eventAttributes(event) },
        enabled: this.tracingEnabled,
      },
      async () => {
        let threadAssignedData = event.eventData;
        if (threadAssignedData && this.shouldReceiveBrowserAlert(event)) {
          let adminId: number = this.session.teammate.id;
          let adminBrowserSettings: AdminBrowserSettings = this.teammateBrowserSettings.settings;

          if (threadAssignedData.assigneeIdWas === adminId) {
            this.browserAlerts.cancelPageTitleToggle(threadAssignedData.conversationId);
          } else if (
            threadAssignedData.assigneeId === adminId ||
            this.isNotificationForTeam(event) ||
            // Conversation was moved out of the bot inbox
            threadAssignedData.assigneeIdWas === adminBrowserSettings.operatorBotId
          ) {
            await this.playSoundAndToggleTitle(
              threadAssignedData.userDisplayAs
                ? this.intl.t('inbox.new-message-from', { user: threadAssignedData.userDisplayAs })
                : this.intl.t('inbox.new-message-received'),
              threadAssignedData.conversationId,
            );
          }
        }
      },
    );
  }

  private async playSoundAndToggleTitle(title: string, conversationId: string) {
    let adminBrowserSettings: AdminBrowserSettings = this.teammateBrowserSettings.settings;
    this.tracing.tagActiveSpan({
      'audio_metadata.audioNotificationsEnabled': adminBrowserSettings.audioNotificationsEnabled,
    });
    if (adminBrowserSettings.audioNotificationsEnabled) {
      this.tracing.tagActiveSpan({
        'audio_metadata.playbackAttempted': true,
      });
      await this.browserAlerts.playNotificationSound(
        this.teammateBrowserSettings.settings.notificationSound,
      );
    }
    this.browserAlerts.togglePageTitle(title, conversationId);
  }

  private shouldReceiveBrowserAlert(event: ThreadEvent): boolean {
    let adminBrowserSettings: AdminBrowserSettings = this.teammateBrowserSettings.settings;
    this.tracing.tagActiveSpan({
      'audio_metadata.adminBrowserSettingsExist': !!adminBrowserSettings,
    });
    if (adminBrowserSettings) {
      let isNotificationForCurrentAdmin = this.isNotificationForCurrentAdmin(event);
      let isNotificationForCurrentTeam = this.isNotificationForTeam(event);
      let isNotificationForUnassigned = this.isNotificationForUnassigned(event);
      this.tracing.tagActiveSpan({
        'audio_metadata.isForCurrentAdmin': isNotificationForCurrentAdmin,
        'audio_metadata.isForCurrentTeam': isNotificationForCurrentTeam,
        'audio_metadata.isForUnassigned': isNotificationForUnassigned,
        'audio_metadata.browserAlertSetting': adminBrowserSettings.browserAlertSetting,
      });
      switch (adminBrowserSettings.browserAlertSetting) {
        // Browser alert setting is the result of bitwise OR operation of Notification bit masks mentioned here: https://github.com/intercom/intercom/blob/master/app/models/assignee_notification_settings_concern.rb#L9
        // NOTIFY_FOR_CONVERSATIONS_ASSIGNED_TO_ME = 1
        // NOTIFY_FOR_CONVERSATIONS_ASSIGNED_TO_TEAM = 2
        // NOTIFY_FOR_UNASSIGNED_CONVERSATIONS = 4
        case 1:
          return isNotificationForCurrentAdmin;
        case 3: // 1 | 2 = 3
          return isNotificationForCurrentAdmin || isNotificationForCurrentTeam;
        case 5: // 1 | 4 = 5
          return isNotificationForCurrentAdmin || isNotificationForUnassigned;
        case 7: // 1 | 2 | 4 = 7
          return (
            isNotificationForCurrentAdmin ||
            isNotificationForCurrentTeam ||
            isNotificationForUnassigned
          );
        default:
          return false;
      }
    }
    return false;
  }

  private isNotificationForCurrentAdmin(event: ThreadEvent): boolean {
    let eventData = event.eventData;
    let adminId: number = this.session.teammate.id;
    /*
      rule_id is set on a conversation part if it was created by a "rule", which are now called workflows.
      For example here: https://github.com/intercom/intercom/blob/3ab6fb100c161149891ac301fdaff07895a3b0c9/app/commands/rules/actions/conversation/assign_to.rb#L67-L77, as opposed to being performed by an admin.
      lastActivityOwnerId similarly is the owner_id of that last part.
      So this code essentially boils down to:
      The notification is for the current admin if it has been assigned to this admin by a rule, or it is assigned to this admin and the last part was created by someone else.
    */
    if (eventData.ruleId) {
      return eventData.assigneeId === adminId && eventData.assigneeIdWas !== adminId;
    }
    return eventData.assigneeId === adminId && eventData.lastActivityOwnerId !== adminId;
  }

  private isNotificationForTeam(event: ThreadEvent): boolean {
    let eventData = event.eventData;
    let adminTeamIds: Array<number> = this.teammateBrowserSettings.settings.teamIds;
    return adminTeamIds.includes(eventData.assigneeId);
  }

  private isNotificationForUnassigned(event: ThreadEvent): boolean {
    let eventData = event.eventData;
    let ignoreRepliesToUnassigned = this.session.workspace.isFeatureEnabled(
      'disable-notification-for-replies-to-unassigned',
    );

    if (ignoreRepliesToUnassigned && eventData.assigneeId === UNASSIGNED_ID) {
      let exitingOperatorInbox =
        [NexusEventName.ThreadUpdated, NexusEventName.ThreadAssigned].includes(event.eventName) &&
        eventData.lastActivityOwnerId === this.teammateBrowserSettings.settings.operatorBotId;
      return event.eventName === NexusEventName.ThreadCreated || exitingOperatorInbox;
    }

    return (
      eventData.assigneeId === UNASSIGNED_ID && eventData.lastActivityOwnerId !== UNASSIGNED_ID
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxNexusEventsHandler: InboxNexusEventsHandler;
    'inbox-nexus-events-handler': InboxNexusEventsHandler;
  }
}
