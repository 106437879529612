/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { get } from '@ember/object';
import BaseValidator from 'ember-cp-validations/validators/base';

const VideoVamHasVideoBlock = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isVideo')) {
      if (get(value, 'firstObject.type') !== 'videoFile') {
        return 'You need to record a video';
      }
    }
    return true;
  },
});

VideoVamHasVideoBlock.reopenClass({
  getDependentsFor() {
    return ['model.isVideo'];
  },
});

export default VideoVamHasVideoBlock;
