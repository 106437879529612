/* RESPONSIBLE TEAM: team-frontend-tech */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend({
  primaryKey: 'admin_id',

  // By default, ember will try to singluarize the key to get the model name
  modelNameFromPayloadKey() {
    return 'admin-notification-settings';
  },
});
