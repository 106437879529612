/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isBlank, isNone } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

let validateEmptyPreview = function (model) {
  if (model.get('isManualMessage')) {
    return 'Choose an audience for your message';
  } else {
    return true;
  }
};

let validatePopulatedPreview = function (value, model) {
  let count = value.get('count');
  if ((isBlank(count) || count === 0) && model.get('isManualMessage')) {
    return 'Choose an audience for your message';
  }
  return true;
};

const ManualMessageHasCount = BaseValidator.extend({
  validate(value, options, model) {
    if (isNone(value)) {
      return validateEmptyPreview(model);
    } else {
      return validatePopulatedPreview(value, model);
    }
  },
});

ManualMessageHasCount.reopenClass({
  getDependentsFor() {
    return ['model.isManualMessage'];
  },
});

export default ManualMessageHasCount;
