/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

import { task } from 'ember-concurrency';

export default Service.extend({
  appService: service(),
  app: readOnly('appService.app'),
  hasConversations: null,

  checkForConversations: task(function* () {
    if (this.hasConversations !== null) {
      return this.hasConversations;
    }
    let app_id = this.get('app.id');
    try {
      let result = yield this._ajaxGet({
        url: `/ember/apps/${app_id}/conversations_exist.json`,
        data: { app_id },
      });
      let hasConversations = this.set('hasConversations', result.conversations_exists);
      return hasConversations;
    } catch (e) {
      console.error(e);
      return false;
    }
  }),

  _ajaxGet(options) {
    return ajax(Object.assign(options, { type: 'GET' }));
  },
});
