/* RESPONSIBLE TEAM: team-purchase-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntershopService from './intershop-service';

export default class UsageLimitsService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare intershopService: IntershopService;

  @tracked finUsageLimits?: any;

  get finHardLimitBreached() {
    return this.finUsageLimits?.limited;
  }

  get finUsageLimitThreshold() {
    return this.finUsageLimits?.threshold;
  }

  get finUsageLimitEnabled() {
    return this.finUsageLimits?.enabled;
  }

  get contractedResolutionsUsageLimit() {
    if (!this.intershopService.contract) {
      // assumed that the app with no contract has no resolutions usage limit
      return 0;
    }

    if (this.appService.app.usesResolutionsWithCustomAnswers) {
      return this.intershopService.contract.contractUsageLimits.resolutions_with_custom_answers;
    }

    return this.intershopService.contract.contractUsageLimits.resolutions;
  }

  async fetchFinUsageLimits(): Promise<void> {
    this.finUsageLimits = await this.store.queryRecord('fin-usage-limit', {});
  }
}

declare module '@ember/service' {
  interface Registry {
    usageLimitsService: UsageLimitsService;
    'usage-limits-service': UsageLimitsService;
  }
}
