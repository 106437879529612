/* RESPONSIBLE TEAM: team-standalone */
import Service, { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { get, post } from 'embercom/lib/ajax';
import type ContentImportService from './content-import-service';
import { type TaskGenerator } from 'ember-concurrency';
import type RouterService from '@ember/routing/router-service';

interface ContentLibrarySummary {
  [key: number]: {
    entity_type: number;
    total_count: number;
    used_by_fin_count: number;
    additional_data: any;
  };
}

type ZendeskAdmin = {
  id: number;
  name: string;
  email: string;
  photo: string;
};

export type SalesforceAdmin = {
  id: number | string;
  name: string;
  email: string;
  user_type: string;
};

type SalesforceQueue = {
  id: number;
  name: string;
};

export type ZendeskTicketsSetupData = {
  admins: Array<ZendeskAdmin>;
  email_addresses: Array<string>;
  live_trigger: { id?: number };
};

export type SalesforceSetupData = {
  admins: Array<SalesforceAdmin>;
  queues: Array<SalesforceQueue>;
};

export type FieldType =
  | 'text'
  | 'textarea'
  | 'checkbox'
  | 'date'
  | 'integer'
  | 'decimal'
  | 'regexp'
  | 'dropdown'
  | 'multiselect'
  | 'lookup'
  | 'tagger'
  | 'id'
  | 'string'
  | 'address'
  | 'email'
  | 'phone'
  | 'boolean'
  | 'datetime'
  | 'url'
  | 'double'
  | 'picklist';

export interface UserFieldResponse {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  intercom_cda?: {
    identifier: string;
  };
}

export interface TicketFieldResponse {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  intercom_cvda?: {
    id: string;
  };
}

export class UserField {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  @tracked isSynchronizing?: boolean = false;
  intercom_cda?: {
    identifier: string;
  };

  constructor(response: UserFieldResponse) {
    this.id = response.id;
    this.name = response.name;
    this.description = response.description;
    this.type = response.type;
    this.required = response.required;
    this.intercom_cda = response.intercom_cda;
  }
}

export class TicketField {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  @tracked isSynchronizing?: boolean = false;
  intercom_cvda?: {
    id: string;
  };

  constructor(response: TicketFieldResponse) {
    this.id = response.id;
    this.name = response.name;
    this.description = response.description;
    this.type = response.type;
    this.required = response.required;
    this.intercom_cvda = response.intercom_cvda;
  }
}

const REQUIRED_ATTRIBUTES = {
  userFields: ['id', 'name', 'email'],
  ticketFields: ['id', 'subject', 'description'],
};

export class ZendeskDataFields {
  @tracked userFields: UserField[] = [];
  @tracked ticketFields: TicketField[] = [];

  constructor(inputs: { userFields: UserField[]; ticketFields: TicketField[] }) {
    this.userFields = this.requiredUserFields.concat(inputs.userFields);
    this.ticketFields = this.requiredTicketFields.concat(inputs.ticketFields);
  }

  get requiredUserFields(): UserField[] {
    return REQUIRED_ATTRIBUTES.userFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        type: 'string',
        required: true,
      };
    });
  }

  get requiredTicketFields(): TicketField[] {
    return REQUIRED_ATTRIBUTES.ticketFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        type: 'string',
        required: true,
      };
    });
  }

  titleCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export class SalesforceDataFields {
  @tracked userFields: UserField[] = [];
  @tracked caseFields: TicketField[] = [];

  constructor(inputs: { userFields: UserField[]; caseFields: TicketField[] }) {
    this.userFields = this.requiredUserFields.concat(inputs.userFields);
    this.caseFields = this.requiredCaseFields.concat(inputs.caseFields);
  }

  get requiredUserFields(): UserField[] {
    return REQUIRED_ATTRIBUTES.userFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        required: true,
        type: 'string',
      };
    });
  }

  get requiredCaseFields(): TicketField[] {
    return REQUIRED_ATTRIBUTES.ticketFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        required: true,
        type: 'string',
      };
    });
  }

  titleCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export default class FinStandaloneService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;
  @service declare router: RouterService;

  @tracked contentLibrarySummary: ContentLibrarySummary = {};

  @tracked zendeskConfig!: ZendeskConfiguration;
  @tracked salesforceConfig!: SalesforceConfiguration;

  @tracked zendeskBrands: Array<any> = [];
  @tracked sunshineChannels: Array<any> = [];
  @tracked zendeskTicketsSetupData: ZendeskTicketsSetupData = {
    admins: [],
    email_addresses: [],
    live_trigger: { id: undefined },
  };

  @tracked salesforceSetupData: SalesforceSetupData = {
    admins: [],
    queues: [],
  };

  @tracked zendeskDataFields: ZendeskDataFields = new ZendeskDataFields({
    userFields: [],
    ticketFields: [],
  });

  @tracked salesforceDataFields: SalesforceDataFields = new SalesforceDataFields({
    userFields: [],
    caseFields: [],
  });

  get workflowsReturnRoute(): string {
    let current = this.router.currentRouteName;

    if (current === 'apps.app.standalone.channels.zendesk.tickets.workflow') {
      return 'apps.app.standalone.channels.zendesk.tickets';
    }

    if (current === 'apps.app.standalone.channels.zendesk.messaging.workflow') {
      return 'apps.app.standalone.channels.zendesk.messaging';
    }

    if (current === 'apps.app.standalone.channels.salesforce.cases.workflow') {
      return 'apps.app.standalone.channels.salesforce.cases';
    }

    if (this.appService.app.canUseStandaloneSalesforce) {
      return 'apps.app.standalone.salesforce-setup';
    }

    return 'apps.app.standalone.setup';
  }

  get hasContentForFin() {
    return Object.keys(this.contentLibrarySummary).some((key) => {
      return this.contentLibrarySummary[Number(key)].used_by_fin_count > 0;
    });
  }

  async initialize() {
    await RSVP.all([
      taskFor(this.loadZendeskConfig).perform(),
      taskFor(this.loadSalesforceConfig).perform(),
      this.store.findAll('conversation-attributes/descriptor'),
    ]);
    this.contentImportService.fetchContentImportSources();
    taskFor(this.fetchLibrarySummary).perform();
    taskFor(this.loadSunshineChannels).perform();
    taskFor(this.loadZendeskBrands).perform();
    taskFor(this.loadZendeskTicketsSetupData).perform();
    taskFor(this.loadSalesforceSetupData).perform();
  }

  @dropTask *loadZendeskConfig() {
    try {
      this.zendeskConfig = yield this.store.findRecord(
        'standalone/zendesk-configuration',
        this.appService.app.id,
      );

      if (!this.zendeskConfig) {
        this.zendeskConfig = this.store.createRecord('standalone/zendesk-configuration', {
          id: this.appService.app.id,
          sunshineIntegration: this.store.createRecord('standalone/sunshine-configuration', {
            id: this.appService.app.id,
          }),
        });
      }
    } catch (e) {
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  @dropTask *loadSalesforceConfig() {
    try {
      this.salesforceConfig = yield this.store.findRecord(
        'standalone/salesforce-configuration',
        this.appService.app.id,
      );

      if (!this.salesforceConfig) {
        this.salesforceConfig = this.store.createRecord('standalone/salesforce-configuration', {
          id: this.appService.app.id,
        });
      }
    } catch (e) {
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  get isLoadingSunshineChannels() {
    return taskFor(this.loadSunshineChannels).isRunning;
  }

  @dropTask *loadZendeskBrands(): TaskGenerator<void> {
    if (this.zendeskConfig.isPendingSetupForTickets) {
      this.zendeskBrands = [];
      return;
    }

    let brands = yield get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/available_brands`,
      {
        app_id: this.appService.app.id,
      },
    );

    this.zendeskBrands = brands;
  }

  @dropTask *loadSunshineChannels(): TaskGenerator<void> {
    if (this.zendeskConfig.isPendingSetupForSunshine) {
      this.sunshineChannels = [];
      return;
    }

    let channels = yield get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/sunshine_channels`,
      {
        app_id: this.appService.app.id,
      },
    );

    this.sunshineChannels = channels;
  }

  @dropTask *fetchLibrarySummary(): TaskGenerator<void> {
    let result: ContentLibrarySummary = yield get(
      '/ember/content_service/contents/ai_content_library_summary',
      {
        app_id: this.appService.app.id,
      },
    ) as unknown as ContentLibrarySummary;
    this.contentLibrarySummary = result;
  }

  get isLoadingZendeskConfig() {
    return taskFor(this.loadZendeskConfig).isRunning;
  }

  get isLoadingLibrarySummary() {
    return taskFor(this.fetchLibrarySummary).isRunning;
  }

  @action async saveZendeskConfig() {
    try {
      await this.zendeskConfig.save();
      taskFor(this.loadZendeskTicketsSetupData).perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.setup.api.save-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('standalone.setup.api.save-error'),
      });
    }
  }

  @dropTask *loadZendeskTicketsSetupData(): TaskGenerator<void> {
    if (this.zendeskConfig.isPendingSetupForTickets) {
      this.zendeskTicketsSetupData = {
        admins: [],
        email_addresses: [],
        live_trigger: { id: undefined },
      };
      return;
    }

    this.zendeskTicketsSetupData = (yield get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/zendesk_tickets_config`,
      {
        app_id: this.appService.app.id,
      },
    )) as unknown as ZendeskTicketsSetupData;
  }

  get isLoadingZendeskTicketsSetupData() {
    return taskFor(this.loadZendeskTicketsSetupData).isRunning;
  }

  @dropTask *loadSalesforceSetupData(): TaskGenerator<void> {
    if (this.salesforceConfig.isPendingSetup) {
      this.salesforceSetupData = {
        admins: [],
        queues: [],
      };

      return;
    }

    let salesforceAdmins = yield get(
      `/ember/standalone/salesforce_configurations/${this.appService.app.id}/list_salesforce_users`,
      {
        app_id: this.appService.app.id,
      },
    );

    let salesforceQueues = yield get(
      `/ember/standalone/salesforce_configurations/${this.appService.app.id}/list_salesforce_queues`,
      {
        app_id: this.appService.app.id,
      },
    );

    this.salesforceSetupData = {
      admins: salesforceAdmins as Array<SalesforceAdmin>,
      queues: salesforceQueues as Array<SalesforceQueue>,
    };
  }

  get isLoadingSalesforceSetupData() {
    return taskFor(this.loadSalesforceSetupData).isRunning;
  }

  @dropTask *loadZendeskDataFields(): TaskGenerator<void> {
    yield this.reloadZendeskDataFields();
  }

  async reloadZendeskDataFields() {
    if (this.zendeskConfig.isPendingSetupForTickets) {
      this.zendeskDataFields = new ZendeskDataFields({
        userFields: [],
        ticketFields: [],
      });
      return;
    }

    let dataFields = (await get(`/ember/standalone/zendesk_data/available_fields`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    })) as {
      user_fields: UserFieldResponse[];
      ticket_fields: TicketFieldResponse[];
    };

    this.zendeskDataFields = new ZendeskDataFields({
      userFields: dataFields.user_fields.map((field) => new UserField(field)),
      ticketFields: dataFields.ticket_fields.map((field) => new TicketField(field)),
    });
  }

  @dropTask *loadSalesforceDataFields(): TaskGenerator<void> {
    yield this.reloadSalesforceDataFields();
  }

  async reloadSalesforceDataFields() {
    if (this.salesforceConfig.isPendingSetup) {
      this.salesforceDataFields = new SalesforceDataFields({
        userFields: [],
        caseFields: [],
      });
      return;
    }

    let dataFields = (await get(`/ember/standalone/salesforce_data/available_fields`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    })) as {
      user_fields: UserFieldResponse[];
      case_fields: TicketFieldResponse[];
    };

    this.salesforceDataFields = new SalesforceDataFields({
      userFields: dataFields.user_fields.map((field) => new UserField(field)),
      caseFields: dataFields.case_fields.map((field) => new TicketField(field)),
    });
  }

  get isLoadingZendeskDataFields() {
    return taskFor(this.loadZendeskDataFields).isRunning;
  }

  get isLoadingSalesforceDataFields() {
    return taskFor(this.loadSalesforceDataFields).isRunning;
  }

  @action async saveSalesforceConfig() {
    try {
      await this.salesforceConfig.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.setup.api.save-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('standalone.setup.api.save-error'),
      });
    }
  }

  async addZendeskUserField(userField: UserField) {
    try {
      // Place field at the end of the list
      this.zendeskDataFields.userFields.removeObject(userField);
      this.zendeskDataFields.userFields.pushObject(userField);

      userField.isSynchronizing = true;
      let intercomCda = await post(
        `/ember/standalone/zendesk_data/${userField.id}/synchronize_user_field`,
        {
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
        },
      );
      userField.intercom_cda = intercomCda;
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: 'Something went wrong and we couldn’t synchronize that field.',
      });
    } finally {
      userField.isSynchronizing = false;
    }
  }

  async addZendeskTicketField(ticketField: TicketField) {
    try {
      // Place the field at the end of the list
      this.zendeskDataFields.ticketFields.removeObject(ticketField);
      this.zendeskDataFields.ticketFields.pushObject(ticketField);

      ticketField.isSynchronizing = true;
      let intercomCvda = await post(
        `/ember/standalone/zendesk_data/${ticketField.id}/synchronize_ticket_field`,
        {
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
        },
      );
      ticketField.intercom_cvda = intercomCvda;
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: 'Something went wrong and we couldn’t synchronize that field.',
      });
    } finally {
      ticketField.isSynchronizing = false;
    }
  }

  async addSalesforceUserField(userField: UserField) {
    try {
      // Place field at the end of the list
      this.salesforceDataFields.userFields.removeObject(userField);
      this.salesforceDataFields.userFields.pushObject(userField);

      userField.isSynchronizing = true;
      let intercomCda = await post(
        `/ember/standalone/salesforce_data/${userField.id}/synchronize_user_field`,
        {
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
        },
      );
      userField.intercom_cda = intercomCda;
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: 'Something went wrong and we couldn’t synchronize that field.',
      });
    } finally {
      userField.isSynchronizing = false;
    }
  }

  async addSalesforceCaseField(caseField: TicketField) {
    try {
      // Place the field at the end of the list
      this.salesforceDataFields.caseFields.removeObject(caseField);
      this.salesforceDataFields.caseFields.pushObject(caseField);

      caseField.isSynchronizing = true;
      let intercomCvda = await post(
        `/ember/standalone/salesforce_data/${caseField.id}/synchronize_case_field`,
        {
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
        },
      );
      caseField.intercom_cvda = intercomCvda;
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: 'Something went wrong and we couldn’t synchronize that field.',
      });
    } finally {
      caseField.isSynchronizing = false;
    }
  }
}
