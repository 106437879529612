/* RESPONSIBLE TEAM: team-messenger */
import Service from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import type Cropper from 'cropperjs';

interface CropperConstructable {
  new (image: HTMLImageElement, options: Cropper.Options): Cropper;
}

const CANVAS_CROPPING_DEFAULT_OPTIONS: Cropper.GetCroppedCanvasOptions = {
  minWidth: 256,
  minHeight: 256,
  maxWidth: 4096,
  maxHeight: 4096,
};

export default class ImageCropperService extends Service {
  Cropper?: CropperConstructable;
  cropper: Cropper | undefined;
  canvas: HTMLCanvasElement | undefined;

  initCropper(image: HTMLImageElement, options: Cropper.Options) {
    if (this.Cropper) {
      this.cropper = new this.Cropper(image, options);
    }
  }

  destroyCropper(): void {
    this.cropper?.destroy();
    this.cropper = undefined;
  }

  @task({ drop: true })
  *maybeLoadCropperAssets(): TaskGenerator<void> {
    if (!this.Cropper) {
      let module = yield import('cropperjs');
      this.Cropper = module.default as CropperConstructable;
    }
  }

  async cropCenter(url: string, aspectRatio: number): Promise<HTMLCanvasElement> {
    // we return a Promise that gets resolved with our canvas element
    return new Promise((resolve) => {
      // this image will hold our source image data
      let inputImage = new Image();

      // we want to wait for our image to load
      inputImage.onload = () => {
        // let's store the width and height of our image
        let inputWidth = inputImage.naturalWidth;
        let inputHeight = inputImage.naturalHeight;

        // get the aspect ratio of the input image
        let inputImageAspectRatio = inputWidth / inputHeight;

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }

        // calculate the position to draw the image at
        let outputX = (outputWidth - inputWidth) * 0.5;
        let outputY = (outputHeight - inputHeight) * 0.5;

        // create a canvas that will present the output image
        let outputImage = document.createElement('canvas');

        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        // draw our image at position 0, 0 on the canvas
        let ctx = outputImage.getContext('2d');
        ctx?.drawImage(inputImage, outputX, outputY);
        this.canvas = outputImage;
        resolve(outputImage);
      };

      // start loading our image
      inputImage.src = url;
    });
  }

  toFile(
    fileName: string,
    options: { type: string; canvasOptions?: Cropper.GetCroppedCanvasOptions },
  ): Promise<File> {
    if (this.cropper) {
      return new Promise((resolve, reject) => {
        if (this.cropper) {
          let canvasOptions = { ...CANVAS_CROPPING_DEFAULT_OPTIONS, ...options.canvasOptions };
          this.cropper.getCroppedCanvas(canvasOptions).toBlob((blob) => {
            if (blob) {
              resolve(new File([blob], fileName, options));
            } else {
              reject(new Error('Could not create blob'));
            }
          }, options.type);
        } else {
          reject(new Error('No cropper'));
        }
      });
    }
    return new Promise((resolve) => {
      this.canvas?.toBlob((blob: Blob) => {
        let file = new File([blob], fileName, options);
        resolve(file);
      }, options.type);
    });
  }

  toDataURL(
    type: string,
    options?: { canvasOptions?: Cropper.GetCroppedCanvasOptions },
  ): string | undefined {
    if (this.cropper) {
      let canvasOptions = { ...CANVAS_CROPPING_DEFAULT_OPTIONS, ...options?.canvasOptions };
      return this.cropper.getCroppedCanvas(canvasOptions).toDataURL(type);
    }
    return this.canvas?.toDataURL(type);
  }
}

declare module '@ember/service' {
  interface Registry {
    imageCropperService: ImageCropperService;
    'image-cropper-service': ImageCropperService;
  }
}
