/* RESPONSIBLE TEAM: team-customer-data-platform */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  collectionKey: 'companies',

  attrs: {
    notes: { key: 'note_ids' },
    plan: { key: 'plan_id' },
    segments: { key: 'segment_ids' },
    tags: { key: 'tag_ids' },
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
});
