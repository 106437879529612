/* RESPONSIBLE TEAM: team-growth-opportunities */

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import md5 from 'blueimp-md5';

export default class PauseSubscriptionModalService extends Service {
  @service appService: any;
  @service customerService: any;
  @tracked isOpen = false;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  get canPauseEarlyStageSubscription() {
    let hash = md5(this.app.id);
    let hashLong = this.md5ToLong(hash);
    return (
      this.app.canPauseEarlyStageSubscription &&
      this.customer.currentlyOnEarlyStage &&
      hashLong % 2 === 0
    );
  }

  md5ToLong(md5String: string): number {
    // Convert the entire MD5 string to a long integer
    let longValue = BigInt(`0x${md5String}`);

    // Convert BigInt to a regular number if it's within the safe integer range
    if (longValue <= Number.MAX_SAFE_INTEGER) {
      return Number(longValue);
    } else {
      // If the value is too large, return a truncated version within the safe range
      return Number(longValue % BigInt(Number.MAX_SAFE_INTEGER));
    }
  }
}
