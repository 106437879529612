/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const RulesetHasValidPredicate = BaseValidator.extend({
  appService: service(),

  validate(value, _options, model) {
    let predicates = value.get('predicates');

    if (
      !this.appService.app?.canUseWorkflowsTicketsV3 &&
      !this.appService.app?.canUseJiraInboundWorkflows
    ) {
      return this.validateWithoutEditorConfig(predicates);
    }

    // Editor config is only relevant in the context of workflows so it's safe to always access the
    // first object on the ruleset links
    let editorConfig = model.rulesetLinks?.firstObject?.editorConfig;

    if (editorConfig?.workflowMatchingTargetingAttributes) {
      return this.validateWithEditorConfig(predicates, editorConfig);
    } else {
      return this.validateWithoutEditorConfig(predicates);
    }
  },

  validateWithEditorConfig(predicates, editorConfig) {
    let supportedPredicateIdentifiers = editorConfig.workflowMatchingTargetingAttributeIdentifiers;

    for (let i = 0; i < predicates.length; i++) {
      let predicate = predicates.objectAt(i);

      if (!this.predicateIsValid(predicate, supportedPredicateIdentifiers)) {
        return 'Audience rules are invalid.';
      }
    }

    return true;
  },

  validateWithoutEditorConfig(predicates) {
    for (let i = 0; i < predicates.length; i++) {
      let predicate = predicates.objectAt(i);

      if (!predicate.isValid) {
        return 'Finish specifying your audience rules.';
      }
    }

    return true;
  },

  predicateIsValid(predicate, supportedPredicateIdentifiers) {
    if (predicate.predicates) {
      return predicate.predicates.every((predicate) => {
        return this.predicateIsValid(predicate, supportedPredicateIdentifiers);
      });
    } else {
      return predicate.isValid && supportedPredicateIdentifiers.includes(predicate.attribute);
    }
  },
});

RulesetHasValidPredicate.reopenClass({
  getDependentsFor() {
    return [
      'model.isRuleset',
      'model.predicateGroup.predicates.@each.isValid',
      'model.rulesetLinks.@each.editorConfig',
    ];
  },
});

export default RulesetHasValidPredicate;
