/* RESPONSIBLE TEAM: team-tickets-1 */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    sections: { embedded: 'always' },
  },

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload = this.alterIdsForNonpersistedSections(payload);
    return this._super(...arguments);
  },

  alterIdsForNonpersistedSections(payload) {
    payload.sections.forEach(function (section) {
      if (section.id === null) {
        section.id = section.name;
      }
    });
    return payload;
  },
});
