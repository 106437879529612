/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

function validateLinkForPreferredDevice(value, options, model, preferredDevice) {
  let surveyPreferredDevices = model.get('step.survey.preferredDevices');

  if (surveyPreferredDevices.includes(preferredDevices['web'])) {
    return true;
  }

  if (
    surveyPreferredDevices.includes(preferredDevices[preferredDevice]) &&
    !value &&
    !model.webUrl
  ) {
    return `Thank you step action: Add a web URL or an ${
      preferredDevice === 'android' ? 'Android' : 'iOS'
    } URI`;
  }
  return true;
}

function validateWebUrl(value, options, model, preferredDevice) {
  if (value && !model.webUrlHasValidFormat) {
    return `Thank you step action: Invalid web URL format`;
  }

  let surveyPreferredDevices = model.get('step.survey.preferredDevices');
  if (surveyPreferredDevices.includes(preferredDevices[preferredDevice]) && !value) {
    return `Thank you step action: Missing web URL`;
  }

  return true;
}

export default buildValidations({
  actionTitle: {
    description: 'Action title',
    validators: [
      validator('presence', {
        presence: true,
        message: 'Thank you step action: Missing button text',
      }),
    ],
  },
  webUrl: {
    description: 'Web URL',
    validators: [
      validator('inline', {
        dependentKeys: ['model.step.survey.preferredDevices', 'model.webUrl'],
        validate: (value, options, model, _attribute) => {
          return validateWebUrl(value, options, model, 'web');
        },
      }),
    ],
  },
  androidUri: {
    description: 'Android URI',
    validators: [
      validator('inline', {
        dependentKeys: ['model.step.survey.preferredDevices', 'model.androidUri', 'model.webUrl'],
        validate: (value, options, model, _attribute) => {
          return validateLinkForPreferredDevice(value, options, model, 'android');
        },
      }),
    ],
  },
  iosUri: {
    description: 'iOS URI',
    validators: [
      validator('inline', {
        dependentKeys: ['model.step.survey.preferredDevices', 'model.iosUri', 'model.webUrl'],
        validate: (value, options, model, _attribute) => {
          return validateLinkForPreferredDevice(value, options, model, 'ios');
        },
      }),
    ],
  },
});
