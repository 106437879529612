/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const CustomBotBotDataSelectorPresent = BaseValidator.extend({
  validate(botData) {
    if (!botData.selector || botData.selector.length === 0) {
      return 'Add a selector to trigger your bot';
    }

    return true;
  },
});

CustomBotBotDataSelectorPresent.reopenClass({
  getDependentsFor() {
    return ['model.botData.selector'];
  },
});

export default CustomBotBotDataSelectorPresent;
