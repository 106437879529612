/* RESPONSIBLE TEAM: team-purchase-experience */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_PRICING_MODELS,
} from 'embercom/lib/billing';
import { Metadata } from 'embercom/models/quote';
import { tracked } from 'tracked-built-ins';
import { isEqual } from 'underscore';

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { type TaskGenerator } from 'ember-concurrency';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';
import type Quote from 'embercom/models/quote';

export interface RequestParams {
  planIds: Array<number>;
  includeTiers?: number;
  bypassCurrentCycleUsage?: boolean;
  includePricingByBillingPeriod?: boolean;
  includePlanCombinationValidation?: boolean;
  pricingModelIdentifier?: string;
  source?: string;
  coreSeatCount?: number;
}

export const DEFAULT_REQUEST_PARAMS: RequestParams = {
  planIds: [],
  includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  source: 'paywall-component-quote-service',
  bypassCurrentCycleUsage: true,
  includePricingByBillingPeriod: true,
  includePlanCombinationValidation: true,
  coreSeatCount: 0,
};

export default class QuoteService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare intl: any;

  @tracked quotes: Array<Quote> = [];
  @tracked loading = true;
  @tracked selectedPlanId?: number;
  @tracked openedFromBillingSummary = false;

  get allInSubscriptionPlans(): Array<Plan> {
    let sortedBillableOrTrialPlansByTier = this.appService.app.allPlansOnPricingModel
      .filter(
        (plan: Plan) => plan.billableCustomerPlan || (plan.active && plan.activeTrialIsGraduating),
      )
      .sort((a: Plan, b: Plan) => b.tierId - a.tierId);

    let productsCovered: string[] = [];
    let highestTiers: Plan[] = [];
    for (let plan of sortedBillableOrTrialPlansByTier) {
      if (!productsCovered.includes(plan.product.id)) {
        productsCovered.push(plan.product.id);
        highestTiers.push(plan);
      }
    }
    return highestTiers;
  }

  get inSubscriptionPlanIds(): Array<number> {
    return this.allInSubscriptionPlans.mapBy('idAsNumber');
  }

  get selectedProduct(): Product {
    return this.appService.app.allPlansOnPricingModel.find((plan: Plan) => {
      return this.selectedPlanId === plan.idAsNumber;
    }).product;
  }

  get inActivePlansOnProduct(): Array<Plan> {
    return this.selectedProduct.plans.filter(
      (plan: Plan) => !this.inSubscriptionPlanIds.includes(plan.idAsNumber),
    );
  }

  get inActivePlanIdsOnProduct(): Array<number> {
    return this.inActivePlansOnProduct.mapBy('idAsNumber');
  }

  get customerSubscriptionPrice(): Quote | undefined {
    return this.getQuoteById(this.inSubscriptionPlanIds);
  }

  get customerQuoteForPlan(): Quote | undefined {
    return this.getQuoteById(this.allRequiredPlanIdsWithNewPlan);
  }

  get currentPricingModelIdentifier(): PricingModelIdentifier {
    return this.customerSubscriptionPrice?.pricingModelIdentifier || '';
  }

  get onPricing5_X(): boolean {
    return PRICING_5_X_PRICING_MODELS.includes(this.currentPricingModelIdentifier);
  }

  get fullSeatCount() {
    return this.appService.app.humanAdminsWithCoreSeat.length;
  }

  get copilotSeatCount() {
    return this.appService.app.humanAdminsWithCopilotSeat.length;
  }

  get liteSeatCount() {
    return this.appService.app.humanAdminsWithLiteSeat.length;
  }

  get fullAndLiteSeatCountCombined() {
    return Number(this.liteSeatCount) + Number(this.fullSeatCount);
  }

  get isValidPlanCombination(): boolean {
    return this.getQuoteById(this.allRequiredPlanIdsWithNewPlan)?.plan_combination_valid;
  }

  get inSubscriptionPlanIdsOnOtherProducts(): Array<number> {
    let productIdForPlan = this.appService.app.products.find((product: Product) =>
      product.hasPlan(Number(this.selectedPlanId)),
    ).id;
    return this.allInSubscriptionPlans
      .filter((plan: Plan) => plan.product.id !== productIdForPlan)
      .map((plan: Plan) => plan.idAsNumber);
  }

  get allRequiredPlanIdsWithNewPlan(): Array<number> {
    return Array.from(
      new Set([Number(this.selectedPlanId), ...this.inSubscriptionPlanIdsOnOtherProducts]),
    );
  }

  getQuoteById(planIds: Array<number>): Quote | undefined {
    return this.quotes.find((quote: Quote) => quote.hasSamePlans(planIds));
  }

  getFirstQuoteByPricingModelIdentifier(pricingModelIdentifier: string): Quote | undefined {
    return this.quotes.find(
      (quote: Quote) => quote.pricingModelIdentifier === pricingModelIdentifier,
    );
  }

  maybeUseLiteSeatCount(planId: number, params: RequestParams): RequestParams {
    if (planId === Number(PRICING_5_X_CORE_ESSENTIAL_ID) && !!this.liteSeatCount) {
      params.coreSeatCount = this.fullAndLiteSeatCountCombined;
    }
    return params;
  }

  get allRequiredPlanIdCombinations(): Array<RequestParams> {
    let params = DEFAULT_REQUEST_PARAMS;
    if (this.appService.app.onPricing5) {
      params.coreSeatCount = this.fullSeatCount;
    }
    let allRequiredPlanIdCombinations = [
      {
        // current price for full customer subscription
        ...params,
        planIds: this.inSubscriptionPlanIds,
      },
      {
        // new price for customer subscription with selected plan added
        ...params,
        planIds: this.allRequiredPlanIdsWithNewPlan,
      },
    ];

    // Ignore is allRequiredPlanIdsWithNewPlan is the same as inSubscriptionPlanIds
    // We already have it above in allRequiredPlanIdCombinations
    if (!isEqual(this.allRequiredPlanIdsWithNewPlan, [Number(this.selectedPlanId)])) {
      allRequiredPlanIdCombinations.push({
        // individual plan price for selected plan
        ...params,
        planIds: [Number(this.selectedPlanId)],
      });
    }

    // Add product sibling plan ids for individual plan prices
    this.selectedProduct.plans.forEach((plan: Plan) => {
      // Ignore the selected plan on product we already added that above
      // Ignore if we only have one in subscription plan
      if (
        plan.idAsNumber !== this.selectedPlanId &&
        !isEqual(this.inSubscriptionPlanIds, [plan.idAsNumber])
      ) {
        let cloneParams = this.maybeUseLiteSeatCount(plan.idAsNumber, { ...params });
        allRequiredPlanIdCombinations.push({
          ...cloneParams,
          planIds: [plan.idAsNumber],
        });
      }
    });

    // If opened from billing summary, we need to fetch prices for
    // all product sibling plans on the same product with active plans on other products
    if (this.openedFromBillingSummary) {
      this.inActivePlanIdsOnProduct.forEach((planId: number) => {
        // If a sibling plan is Essential and we have lite seats in use
        // Use both lite and core seats for pricing
        let cloneParams = this.maybeUseLiteSeatCount(planId, { ...params });
        // Ignore selected plan we already added that above
        if (planId !== this.selectedPlanId) {
          // Add sibling plan ids and active plan ids on other products
          allRequiredPlanIdCombinations.push({
            ...cloneParams,
            planIds: [planId, ...this.inSubscriptionPlanIdsOnOtherProducts],
          });
        }
      });
    }

    return allRequiredPlanIdCombinations.filter((params) => params.planIds.length);
  }

  formatPrice(price: number, minimumFractionDigits = 0) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
      maximumFractionDigits: 2,
    });
  }

  @task({})
  *getQuotes(getQuoteParams: Array<RequestParams> | null): TaskGenerator<void> {
    this.loading = true;
    this.quotes = [];

    // If we are given an individual request param use that
    // Otherwise get all possible combinations of required plan params for use in paywalls
    let planCombinationsParams = getQuoteParams || this.allRequiredPlanIdCombinations;
    let localStore = this.store.peekAll('quote');
    yield planCombinationsParams.map(async (params) => {
      if (this.appService.app.onPricing5) {
        if (!params.pricingModelIdentifier) {
          params.pricingModelIdentifier = this.appService.app.show5_1PricingModal
            ? PricingModelIdentifier.PRICING_5_1
            : PricingModelIdentifier.PRICING_5_0;
        }
      }
      let paramsToMetaData = new Metadata(params);
      let quoteExists = localStore.find((quote: Quote) =>
        isEqual(quote.metaData, paramsToMetaData),
      );

      if (quoteExists) {
        this.quotes.push(quoteExists);
        this.quotes = this.quotes;
      } else {
        await taskFor(this.requestPrices).perform(paramsToMetaData);
      }
      if (planCombinationsParams.length === this.quotes.length) {
        this.loading = false;
      }
    });
  }

  @task({})
  *requestPrices(params: Metadata): TaskGenerator<void> {
    try {
      let quote = yield this.store.query('quote', params);
      quote.firstObject.setMetaData(params);
      this.quotes.push(quote.firstObject);
      this.quotes = this.quotes;
    } catch (e) {
      console.error('Error fetching prices', e);
    }
  }

  @action
  setSelectedPlanId(planId: number) {
    this.selectedPlanId = Number(planId);
  }

  @action
  setOpenedFromBillingSummary(openedFromBillingSummary: boolean) {
    this.openedFromBillingSummary = openedFromBillingSummary;
  }
}

declare module '@ember/service' {
  interface Registry {
    quoteService: QuoteService;
    'quote-service': QuoteService;
  }
}
