/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { AVAILABLE_FILTERS_WITH_CUSTOM_ATTRIBUTES } from 'embercom/lib/reporting/flexible/constants';

const BUILD_REVISION = 'ember_rev';

export default class StickQueryParamsService extends Service {
  @service declare router: Router;

  isBuildRevisionKey(key: string) {
    return BUILD_REVISION === key;
  }

  get safeCarryOverQueryParams() {
    return Object.fromEntries(
      Object.entries(this.router.currentRoute.queryParams).filter(
        ([key, _value]) =>
          AVAILABLE_FILTERS_WITH_CUSTOM_ATTRIBUTES.includes(key) || this.isBuildRevisionKey(key),
      ),
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    stickQueryParamsService: StickQueryParamsService;
    'stick-query-params-service': StickQueryParamsService;
  }
}
