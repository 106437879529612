/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isPresent } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const RulesetLinksAreDifferent = BaseValidator.extend({
  // The value in this validator is the ruleset links and the model is the Ruleset
  validate(value, options, model) {
    if (isPresent(value) && value.length > 1) {
      if (this._rulesetLinksAreEqual(value, model)) {
        return 'The A and B variations of your message should be different';
      }
    }
    return true;
  },

  _rulesetLinksAreEqual(rulesetLinks, ruleset) {
    if (
      !isPresent(rulesetLinks) ||
      rulesetLinks.length < 2 ||
      ruleset.hasDifferentRulesetLinkTypes
    ) {
      return false;
    }

    let stringifiedRulesetLinkContentHashes = rulesetLinks.map((rulesetLink) => {
      return JSON.stringify(rulesetLink.contentHash);
    });

    return (
      new Set(stringifiedRulesetLinkContentHashes).size !==
      stringifiedRulesetLinkContentHashes.length
    );
  },
});

RulesetLinksAreDifferent.reopenClass({
  getDependentsFor() {
    return ['model.type', 'model.rulesetLinks.@each.contentHash'];
  },
});

export default RulesetLinksAreDifferent;
