/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import JSONSerializer from '@ember-data/serializer/json';
import { decamelize } from '@ember/string';

export default JSONSerializer.extend({
  // Prevents `reaction0Count` from being decamelized as `reaction0_count`
  // Non reaction stats are ignored.
  keyForAttribute(attr) {
    let preformattedAttr = attr.replace(/(\w+?)(\d+)(\w+)/, (match, a, b, c) => `${a}_${b}_${c}`);
    return decamelize(preformattedAttr);
  },
});
