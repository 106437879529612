/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ValidatorMessages from 'ember-cp-validations/validators/messages';

import { inject as service } from '@ember/service';
import { warn } from '@ember/debug';
import { getOwner } from '@ember/application';

export default class CustomValidatorMessages extends ValidatorMessages {
  @service intl;

  constructor() {
    super(...arguments);

    let owner = getOwner(this);

    this._config = owner?.resolveRegistration('config:environment') || {};
  }

  _warn(msg, test, meta) {
    if (this._config?.APP.suppressValidationWarnings) {
      return;
    }

    warn(msg, test, meta);
  }

  getDescriptionFor(attribute, options = {}) {
    let { descriptionKey, description } = options;

    if (descriptionKey) {
      if (this.intl.exists(descriptionKey)) {
        return this.intl.t(descriptionKey, options);
      }

      this._warn(
        `Validation descriptionKey '${descriptionKey}' provided but does not exist in intl translations.`,
        false,
        {
          id: 'ember-intl-cp-validations-missing-custom-key',
        },
      );
    }

    return description ?? this.intl.t('validations.default-description');
  }

  getMessageFor(type, options = {}) {
    let { messageKey } = options;

    if (messageKey) {
      if (this.intl.exists(messageKey)) {
        return this.intl.t(messageKey, options);
      }

      this._warn(
        `[ember-intl-cp-validations] Missing translation for validation key: ${messageKey}`,
        false,
        {
          id: 'ember-intl-cp-validations-missing-translation',
        },
      );
    }

    return this.intl.t(`validations.errors.${type}`, options);
  }
}
