/* RESPONSIBLE TEAM: team-proactive-support */

import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { type ContentObject } from 'embercom/models/content-object';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export interface ContentObjectQuery {
  object_type: number;
  series_id?: string;
  included_pending_node_ids?: string[];
  show_only_live_content?: boolean;
}

export default class ContentObjectService extends Service {
  @service declare store: Store;
  @tracked tourContentObjects: ContentObject[] = [];
  @tracked articleContentObjects: ContentObject[] = [];

  @task({ keepLatest: true }) *objectSearch(
    query: ContentObjectQuery,
  ): TaskGenerator<ContentObject[]> {
    let objectType = query.object_type;
    let allStoreObjects = yield this.store.peekAll('content-object');

    switch (objectType) {
      case objectTypes.tour:
        this.tourContentObjects = yield this.getObjects(
          query,
          this.tourContentObjects,
          allStoreObjects,
        );
        return this.tourContentObjects;

      case objectTypes.article:
        this.articleContentObjects = yield this.getObjects(
          query,
          this.articleContentObjects,
          allStoreObjects,
        );
        return this.articleContentObjects;

      default:
        return [];
    }
  }

  private async getObjects(
    query: ContentObjectQuery,
    contentObjects: ContentObject[],
    allStoreObjects: ContentObject[],
  ) {
    if (contentObjects.length > 0) {
      this.fetchObjectsNonBlocking(query);
      return this.filterObjects(allStoreObjects, query);
    } else {
      return await this.store.query('content-object', query);
    }
  }

  // This is called in the background every time we fetch from the local store
  // The purpose is so users won't need a full refresh if the set a new content object live
  private async fetchObjectsNonBlocking(query: ContentObjectQuery) {
    this.store.query('content-object', query);
  }

  private filterObjects(objects: ContentObject[], query: ContentObjectQuery): ContentObject[] {
    return objects.filter((object: ContentObject) => {
      let objectType = parseInt(object.id.split('_')[0], 10);

      let sameType = objectType !== null && objectType === query.object_type;
      return sameType;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    contentObjectService: ContentObjectService;
    'content-object-service': ContentObjectService;
  }
}
