/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import {
  meetsAndroidSdkRequirements,
  meetsIosSdkRequirements,
} from 'embercom/lib/mobile/sdk-version-check';

const MIN_ANDROID_SDK_VERSION = '15.4.0';
const MIN_IOS_SDK_VERSION = '16.2.0';

function _targetingUnsupportedSdkVersion(rulesetLink) {
  return (
    // targeting unsupported ios sdk version
    (rulesetLink.preferredDevices.any((device) => device === preferredDevices.ios) &&
      !meetsIosSdkRequirements(rulesetLink.app, MIN_IOS_SDK_VERSION)) ||
    // targeting unsupported android sdk version
    (rulesetLink.preferredDevices.any((device) => device === preferredDevices.android) &&
      !meetsAndroidSdkRequirements(rulesetLink.app, MIN_ANDROID_SDK_VERSION))
  );
}

const ChatMessageInboundBotMobileConstraints = BaseValidator.extend({
  intl: service(),
  contentEditorService: service(),

  validate(value, options, model) {
    let rulesetLink = this.contentEditorService.activeRulesetLink;
    // Only validate if the ruleset link is inbound bot
    if (!rulesetLink || !rulesetLink.object.isInbound) {
      return true;
    }

    // Only validate if the ruleset link preferred devices include mobile
    if (
      !rulesetLink.preferredDevices.any((device) =>
        [preferredDevices.ios, preferredDevices.android].includes(device),
      )
    ) {
      return true;
    }

    // Only validate if the app is targeting an unsupported sdk version
    if (!_targetingUnsupportedSdkVersion(rulesetLink)) {
      return true;
    }

    let chatMessages = model.steps.filter((step) => step.typeKey === 'chat-message');
    if (chatMessages.length > 1) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.inbound-bot-mobile-constraints.multiple-messages',
        {
          iosSdkVersion: MIN_IOS_SDK_VERSION,
          androidSdkVersion: MIN_ANDROID_SDK_VERSION,
        },
      );
    }

    let chatMessageBlocks = model.steps.firstObject.blocks;
    if (chatMessageBlocks.length > 1 || chatMessageBlocks.firstObject.type !== 'paragraph') {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.inbound-bot-mobile-constraints.paragraph-only',
        {
          iosSdkVersion: MIN_IOS_SDK_VERSION,
          androidSdkVersion: MIN_ANDROID_SDK_VERSION,
        },
      );
    }

    return true;
  },
});

ChatMessageInboundBotMobileConstraints.reopenClass({
  getDependentsFor() {
    return ['model.steps.[]', 'model.steps.firstObject.blocks.[]'];
  },
});

export default ChatMessageInboundBotMobileConstraints;
