/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class DomainObjectTypeSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    descriptors: {
      embedded: 'always',
    },
  };
}
