/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import JSONSerializer from '@ember-data/serializer/json';
import RESTSerializer from '@ember-data/serializer/rest';
import { isArray } from '@ember/array';
import { decamelize, underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';
import { isFunction } from 'underscore';
import { warn } from '@ember/debug';
import { makeArray } from '@ember/array';

let restSerializer = RESTSerializer.create();

export default JSONSerializer.extend({
  serialize(snapshot, options = {}) {
    options.includeId = true;
    return this._super(snapshot, options);
  },
  keyForAttribute(attr) {
    return decamelize(attr);
  },
  keyForRelationship(attr) {
    return decamelize(attr);
  },
  modelNameFromPayloadKey: restSerializer.modelNameFromPayloadKey,
  // implementation is copied from https://github.com/emberjs/data/blob/cae5433e859a9d8c9fccb51a25d34e4c607aa341/packages/serializer/addon/rest.js
  // we need this to fix https://github.com/intercom/intercom/issues/233261 which is caused by a bug in Ember Data whose fix hasn't yet been released
  // see https://github.com/emberjs/data/pull/7848
  pushPayload(store, payload) {
    let documentHash = {
      data: [],
      included: [],
    };

    // eslint-disable-next-line guard-for-in
    for (let prop in payload) {
      let modelName = this.modelNameFromPayloadKey(prop);
      if (!store._hasModelFor(modelName)) {
        warn(this.warnMessageNoModelForKey(prop, modelName), false, {
          id: 'ds.serializer.model-for-key-missing',
        });
        // eslint-disable-next-line no-continue
        continue;
      }
      let type = store.modelFor(modelName);
      let typeSerializer = store.serializerFor(type.modelName);

      makeArray(payload[prop]).forEach((hash) => {
        let { data, included } = typeSerializer.normalize(type, hash, prop);
        documentHash.data.push(data);
        if (included) {
          documentHash.included = documentHash.included.concat(included);
        }
      });
    }

    store.push(documentHash);
  },
  warnMessageNoModelForKey: restSerializer.warnMessageNoModelForKey,

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let extractedPayload = this.extractArrayPayload(primaryModelClass.modelName, payload);
    return this._super(store, primaryModelClass, extractedPayload, id, requestType);
  },

  // Optional, custom key to extract an array response payload
  collectionKey: null,

  extractArrayPayload(modelName, payload) {
    if (isArray(payload)) {
      return payload;
    }

    let key = this.collectionKey || underscore(pluralize(modelName));
    let extractedPayload = payload[key] ? payload[key] : payload;
    extractedPayload.meta = payload.meta;

    return extractedPayload;
  },

  serializeAttribute(snapshot, json, key, attribute) {
    let shouldSerialize = attribute.options.shouldSerialize;
    shouldSerialize = isFunction(shouldSerialize) ? shouldSerialize() : shouldSerialize;

    if (shouldSerialize !== false) {
      this._super(...arguments);
    }
  },
});
