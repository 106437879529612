/* RESPONSIBLE TEAM: team-phone */
import BaseValidator from 'ember-cp-validations/validators/base';
import { validateVideoDetails } from 'embercom/lib/video-embed-url';

const VideoDetails = BaseValidator.extend({
  validate(value, _, model) {
    return validateVideoDetails(value, model.get('appStoreListing.videoProvider'));
  },
});

export default VideoDetails;
