/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { request } from 'embercom/lib/inbox/requests';
import ConversationTopic from 'embercom/objects/inbox/conversation-topic';
import type Conversation from 'embercom/objects/inbox/conversation';
import cached from 'embercom/lib/cached-decorator';

export default class Inbox2ConversationalInsightsApi extends Service {
  @service declare session: Session;

  @cached({ max: 1, ttl: 5 * 60 * 1000 })
  async fetchAllTopics(init: RequestInit = {}) {
    let response = await request(
      `/ember/conversational_insights/conversation_topics?app_id=${this.session.workspace.id}`,
      init,
    );
    let json = await response.json();
    return json.map(ConversationTopic.deserialize);
  }

  async fetchTopicsForConversation(conversationId: Conversation['id'], init: RequestInit = {}) {
    let response = await request(
      `/ember/conversations/${conversationId}/topics?app_id=${this.session.workspace.id}`,
      init,
    );
    let json = await response.json();
    return json.map(ConversationTopic.deserialize);
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2ConversationalInsightsApi: Inbox2ConversationalInsightsApi;
    'inbox2-conversational-insights-api': Inbox2ConversationalInsightsApi;
  }
}
