/* RESPONSIBLE TEAM: team-ai-agent */
import Service, { inject as service } from '@ember/service';
import type FinFreeUsageWindow from 'embercom/models/fin-free-usage-window';
import { AiAnswersState } from 'embercom/lib/operator/fin/types';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';

export default class FinTrialService extends Service {
  @service declare appService: $TSFixMe;

  get isTrialCustomer() {
    return (
      (this.freeTrialNotStarted || this.freeTrialStarted || this.freeTrialExpired) &&
      !this.freeUsageWindow?.hasExtendedFinFreeUsageWindow
    );
  }

  get freeUsageWindow(): FinFreeUsageWindow {
    return this.appService.app.finFreeUsageWindow;
  }

  get trialDaysRemaining() {
    return this.freeUsageWindow?.daysRemaining;
  }

  get aiAnswersState(): AiAnswersState {
    return this.appService.app.aiAnswersState;
  }

  get isContractedForFin() {
    return this.appService.app.contractedForFin;
  }

  get isSalesforceContracted() {
    return this.appService.app.isSalesforceContracted;
  }

  get isNonPricing5SalesforceContracted() {
    return this.appService.app.isSalesforceContracted && this.appService.app.notOnPricing5;
  }

  get freeTrialNotStarted() {
    return this.aiAnswersState === AiAnswersState.trialNotStarted;
  }

  get freeTrialStarted() {
    return this.aiAnswersState === AiAnswersState.trialStarted;
  }

  get resolutionPrice() {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get trialExpired() {
    return this.aiAnswersState === AiAnswersState.trialExpired;
  }

  get freeTrialExpired() {
    return (
      this.trialExpired ||
      // we consider self-serve customers to be in the trial expired state for 1 week after their trial finishes
      (this.aiAnswersState === AiAnswersState.active &&
        !this.isSalesforceContracted &&
        this.freeUsageWindow?.state === 2 &&
        this.freeUsageWindow?.daysRemaining >= -7)
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    finTrialService: FinTrialService;
    'fin-trial-service': FinTrialService;
  }
}
