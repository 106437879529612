/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

export default BaseValidator.extend({
  validate(blocks) {
    if (!blocks) {
      return true;
    }

    let cardErrors = [];
    let messengerCardBlocks = blocks.filter((block) => block.type === 'messengerCard');
    if (messengerCardBlocks.length > 0) {
      cardErrors = messengerCardBlocks.reduce((errors, card) => {
        if (!card.canvas || !card.uri || !card.messenger_app_id) {
          errors.push('The messenger app is in an invalid state, please remove and add it again');
        }
        return errors;
      }, []);
    }
    return cardErrors.length > 0 ? cardErrors.join(', ') : true;
  },
});
