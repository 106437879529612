/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

export default BaseValidator.extend({
  validate(nextPath, _options, model) {
    if (!nextPath && model.type !== 'success') {
      return this.controlNotLinkedHumanFriendlyExplanation(model.get('step.type'));
    }
    return true;
  },

  controlNotLinkedHumanFriendlyExplanation(type) {
    switch (type) {
      case 'reply_buttons':
      case 'custom_object_selector':
        return 'Each reply button must point to a path';
      case 'conditional_branches':
        return 'Each jump condition must point to a path';
      case 'workflow_connector':
        return 'The action failure must point to a path';
      default:
        return 'Each reply button must point to a path';
    }
  },
});
