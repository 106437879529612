/* RESPONSIBLE TEAM: team-frontend-tech */
import { serial, wait, parallel } from 'ember-animated';
import ENV from 'embercom/config/environment';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';

export default function (tmap) {
  tmap.transition(tmap.hasClass('onboarding__image-fade'), tmap.use('crossFade'));

  tmap.transition(tmap.hasClass('onboarding__checklist-slide'), tmap.use('flex-grow'));
}

export const GUIDE_ASSISTANT_TEXT_TRANSITION_DURATION = ENV.APP._250MS;
export function* guideAssistantTextTransition({ insertedSprites, removedSprites }) {
  yield removedSprites.forEach(
    parallel((sprite) => {
      fadeOut(sprite, { duration: ENV.APP._100MS });
    }),
  );

  let newLineWaitDuration = 0;

  let newLineWait = 0;
  if (insertedSprites.length <= 1) {
    newLineWait = ENV.APP._400MS;
  } else {
    newLineWait = ENV.APP._200MS;
  }

  yield insertedSprites.forEach(
    serial(
      () => wait((newLineWaitDuration += newLineWait)),
      (sprite) => {
        fadeIn(sprite, { duration: ENV.APP._250MS });
      },
    ),
  );
}
