/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';

const RulesetHasValidScheduledDeactivation = BaseValidator.extend({
  validate(value, options, model) {
    if (isEmpty(model.scheduledDeactivation)) {
      return true;
    }

    let currentDate = moment();
    let scheduledDeactivationDate = moment(model.scheduledDeactivation.changeAt);

    if (scheduledDeactivationDate <= currentDate) {
      return 'Choose a valid stop date';
    }

    return true;
  },
});

RulesetHasValidScheduledDeactivation.reopenClass({
  getDependentsFor() {
    return ['model.scheduledDeactivation', 'model.scheduledDeactivation.changeAt'];
  },
});

export default RulesetHasValidScheduledDeactivation;
