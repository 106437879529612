/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Navigatorkeyboard from './navigator-keyboard';
// @ts-ignore
import { cached } from 'tracked-toolbox';

export enum KeyboardLayoutName {
  English_US_ISO_PC = 'English_US_ISO_PC',
  English_British_PC = 'English_British_PC',
  English_British_Mac = 'English_British_Mac',
  English_US_ISO_Terminal = 'English_US_ISO_Terminal',
  English_British_Variant_1 = 'English_British_Variant_1',
  Brazilian_ABNT2 = 'Brazilian_ABNT2',
  Brazilian_ABNT2_2 = 'Brazilian_ABNT2_2',
  French_AZERTY_1 = 'French_AZERTY_1',
  French_AZERTY_2 = 'French_AZERTY_2',
  French_AZERTY_3 = 'French_AZERTY_3',
  French_AZERTY_4 = 'French_AZERTY_4',
  French_AZERTY_5 = 'French_AZERTY_5',
  Spanish_Latin_American_1 = 'Spanish_Latin_American_1',
  Spanish_Latin_American_2 = 'Spanish_Latin_American_2',
  Spanish_Latin_American_3 = 'Spanish_Latin_American_3',
  Spanish_Latin_American_4 = 'Spanish_Latin_American_4',
  Spanish_Latin_American_5 = 'Spanish_Latin_American_5',
  Spanish_Latin_American_6 = 'Spanish_Latin_American_6',
  Hebrew = 'Hebrew',
  Greek = 'Greek',
  Georgian_QWERTY = 'Georgian_QWERTY',
  Russian_PC_Variant_1 = 'Russian_PC_Variant_1',
  Russian_PC_Variant_2 = 'Russian_PC_Variant_2',
  Russian_PC_Variant_3 = 'Russian_PC_Variant_3',
  Russian_PC_Variant_4 = 'Russian_PC_Variant_4',
  German_QWERTZ_1 = 'German_QWERTZ_1',
  German_QWERTZ_2 = 'German_QWERTZ_2',
  German_QWERTZ_3 = 'German_QWERTZ_3',
  German_QWERTZ_4 = 'German_QWERTZ_4',
  German_QWERTZ_5 = 'German_QWERTZ_5',
  German_QWERTZ_6 = 'German_QWERTZ_6',
}

export default class KeyboardLayout extends Service {
  @service intercomEventService: any;
  // getting navigator.keyboard from a separate service so that we can test easily with mocks
  // otherwise our testing will be dependent on running the tests in a browser that supports this API
  @service declare navigatorKeyboard: Navigatorkeyboard;

  @tracked keyboardLayoutMap: Map<string, string> | undefined = undefined;
  private readonly keyCodes = [
    'KeyQ',
    'KeyW',
    'KeyE',
    'KeyR',
    'KeyT',
    'KeyY',
    'KeyU',
    'KeyI',
    'KeyO',
    'KeyP',
    'BracketLeft',
    'BracketRight',
    'Backslash',
    'KeyA',
    'KeyS',
    'KeyD',
    'KeyF',
    'KeyG',
    'KeyH',
    'KeyJ',
    'KeyK',
    'KeyL',
    'Semicolon',
    'Quote',
    'Backslash',
    'IntlBackslash',
    'KeyZ',
    'KeyX',
    'KeyC',
    'KeyV',
    'KeyB',
    'KeyN',
    'KeyM',
    'Comma',
    'Period',
    'Slash',
    'IntlRo',
  ];

  private layoutKeysMap: { [key in KeyboardLayoutName]: string } = {
    [KeyboardLayoutName.English_US_ISO_PC]: "qwertyuiop[]\\asdfghjkl;'\\\\zxcvbnm,./",
    [KeyboardLayoutName.English_British_PC]: "qwertyuiop[]#asdfghjkl;'#\\zxcvbnm,./",
    [KeyboardLayoutName.English_British_Mac]: "qwertyuiop[]\\asdfghjkl;'\\§zxcvbnm,./`",
    [KeyboardLayoutName.English_US_ISO_Terminal]: "qwertyuiop[]\\asdfghjkl;'\\<zxcvbnm,./",
    [KeyboardLayoutName.English_British_Variant_1]: "qwertyuiop[]\\asdfghjkl;'\\§zxcvbnm,./",
    [KeyboardLayoutName.Brazilian_ABNT2]: 'qwertyuiop´[]asdfghjklç~]\\zxcvbnm,.;/',
    [KeyboardLayoutName.Brazilian_ABNT2_2]: "qwertyuiop'[]asdfghjklç~]\\zxcvbnm,.;/",
    [KeyboardLayoutName.French_AZERTY_1]: 'azertyuiop^$`qsdfghjklmù`@wxcvbn,;:=<',
    [KeyboardLayoutName.French_AZERTY_2]: 'azertyuiop^$*qsdfghjklmù*²wxcvbn,;:!<',
    [KeyboardLayoutName.French_AZERTY_3]: 'azertyuiop^$*qsdfghjklmù*<wxcvbn,;:!',
    [KeyboardLayoutName.French_AZERTY_4]: 'azertyuiop^$`qsdfghjklmù`@wxcvbn,;:=',
    [KeyboardLayoutName.French_AZERTY_5]: 'azertyuiop^$µqsdfghjklmùµ<wxcvbn,;:=',
    [KeyboardLayoutName.Spanish_Latin_American_1]: 'qwertyuiop´+}asdfghjklñ{}<zxcvbnm,.-',
    [KeyboardLayoutName.Spanish_Latin_American_2]: 'qwertyuiop´+}asdfghjklñ{}|zxcvbnm,.-<',
    [KeyboardLayoutName.Spanish_Latin_American_3]: 'qwertyuiop´+}asdfghjklñ{}|zxcvbnm,.-|',
    [KeyboardLayoutName.Spanish_Latin_American_4]: 'qwertyuiop´+}asdfghjklñ{}|zxcvbnm,.-',
    [KeyboardLayoutName.Spanish_Latin_American_5]: "qwertyuiop'+}asdfghjklñ{}<zxcvbnm,.-",
    [KeyboardLayoutName.Spanish_Latin_American_6]: 'qwertyuiop´+}asdfghjklñ{}<zxcvbnm,.-<',
    [KeyboardLayoutName.Hebrew]: "/'קראטוןםפ][\\שדגכעיחלךף,\\<זסבהנמצתץ.",
    [KeyboardLayoutName.Greek]: ";ςερτυθιοπ[]\\ασδφγηξκλ''\\«ζχψωβνμ,./",
    [KeyboardLayoutName.Georgian_QWERTY]: "ქწერტყუიოპ[]\\ასდფგჰჯკლ;'\\«ზხცვბნმ,./",
    [KeyboardLayoutName.Russian_PC_Variant_1]: 'йцукенгшщзхъ\\фывапролджэ\\/ячсмитьбю.',
    [KeyboardLayoutName.Russian_PC_Variant_2]: 'йцукенгшщзхъ\\фывапролджэ\\<ячсмитьбю№',
    [KeyboardLayoutName.Russian_PC_Variant_3]: 'йцукенгшщзхїґфівапролджєґ/ячсмитьбю.',
    [KeyboardLayoutName.Russian_PC_Variant_4]: 'йцукенгшщзхъ\\фывапролджэ\\\\ячсмитьбю.',
    [KeyboardLayoutName.German_QWERTZ_1]: 'qwertzuiopü+#asdfghjklöä#<yxcvbnm,.-',
    [KeyboardLayoutName.German_QWERTZ_2]: 'qwertzuiopü+#asdfghjklöä#^yxcvbnm,.-<',
    [KeyboardLayoutName.German_QWERTZ_3]: 'qwertzuiopü+#asdfghjklöä#^yxcvbnm,.-',
    [KeyboardLayoutName.German_QWERTZ_4]: 'qwertzuiopü¨$asdfghjklöä$<yxcvbnm,.-',
    [KeyboardLayoutName.German_QWERTZ_5]: 'qwertzuiopü¨$asdfghjklöä$§yxcvbnm,.-<',
    [KeyboardLayoutName.German_QWERTZ_6]: 'qwertzuiopü¨$asdfghjklöä$§yxcvbnm,.-',
  };

  private trackKeyboardLayoutLoad(options: {
    status: string;
    reason?: string;
    mappedKeys?: string;
  }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'load',
      object: 'keyboard_layout',
      place: 'inbox',
      status: options.status,
      reason: options.reason,
      mappedKeys: options.mappedKeys,
      eventVersion: '1',
    });
  }

  async loadKeyboardLayout() {
    try {
      if (!this.navigatorKeyboard.keyboardObject?.getLayoutMap) {
        this.trackKeyboardLayoutLoad({ status: 'failed' });
        return;
      }

      this.keyboardLayoutMap = await this.navigatorKeyboard.keyboardObject?.getLayoutMap();
      let mappedKeys = this.mappedKeys;
      this.trackKeyboardLayoutLoad({ status: 'succeeded', mappedKeys });
    } catch (err) {
      this.trackKeyboardLayoutLoad({ status: 'errored', reason: err?.toString() });
    }
  }

  @action getKeyForCode(keyCode: string, fallbackKey: string): string {
    if (this.keyboardLayoutMap) {
      return this.keyboardLayoutMap.get(keyCode) || fallbackKey;
    }
    return fallbackKey;
  }

  private get mappedKeys(): string | undefined {
    if (this.keyboardLayoutMap) {
      return this.keyCodes.map((code) => this.keyboardLayoutMap!.get(code)).join('');
    }
    return undefined;
  }

  private get englishLayoutNames(): KeyboardLayoutName[] {
    return [
      KeyboardLayoutName.English_US_ISO_PC,
      KeyboardLayoutName.English_British_Mac,
      KeyboardLayoutName.English_British_PC,
      KeyboardLayoutName.English_British_Variant_1,
      KeyboardLayoutName.English_US_ISO_Terminal,
    ];
  }

  private get nonLatinLayoutNames(): KeyboardLayoutName[] {
    return [
      KeyboardLayoutName.Hebrew,
      KeyboardLayoutName.Greek,
      KeyboardLayoutName.Georgian_QWERTY,
      KeyboardLayoutName.Russian_PC_Variant_1,
      KeyboardLayoutName.Russian_PC_Variant_2,
      KeyboardLayoutName.Russian_PC_Variant_3,
      KeyboardLayoutName.Russian_PC_Variant_4,
    ];
  }

  @cached
  private get keyboardLayoutName(): KeyboardLayoutName | undefined {
    let mappedKeys = this.mappedKeys;
    if (mappedKeys && mappedKeys.length) {
      let keyboardLayout = Object.entries(this.layoutKeysMap).find(([_, val]) => {
        return val === mappedKeys;
      });

      if (keyboardLayout) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'match',
          object: 'keyboard_layout',
          place: 'inbox',
          status: 'succeeded',
          layoutName: keyboardLayout[0],
        });
        return keyboardLayout[0] as KeyboardLayoutName;
      }
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'match',
      object: 'keyboard_layout',
      place: 'inbox',
      status: 'failed',
      mappedKeys,
    });
    return undefined;
  }

  // This is a temporary method used to track the keyboard layout names in Inbox 1
  // Will be removed once we have sufficient data about keyboard layout names in Inbox 1
  trackKeyboardLayoutName() {
    this.keyboardLayoutName;
  }

  get isEnglishOrNonLatinKeyboard(): boolean {
    let currentLayout = this.keyboardLayoutName;
    if (currentLayout) {
      if (
        this.englishLayoutNames.includes(currentLayout) ||
        this.nonLatinLayoutNames.includes(currentLayout)
      ) {
        return true;
      }
    }
    return false;
  }

  get isFrenchAzertyKeyboard(): boolean {
    let currentLayout = this.keyboardLayoutName;
    if (currentLayout) {
      if (
        [
          KeyboardLayoutName.French_AZERTY_1,
          KeyboardLayoutName.French_AZERTY_2,
          KeyboardLayoutName.French_AZERTY_3,
          KeyboardLayoutName.French_AZERTY_4,
          KeyboardLayoutName.French_AZERTY_5,
        ].includes(currentLayout)
      ) {
        return true;
      }
    }
    return false;
  }

  get isSpanishLatinAmericanKeyboard(): boolean {
    let currentLayout = this.keyboardLayoutName;
    if (currentLayout) {
      if (
        [
          KeyboardLayoutName.Spanish_Latin_American_1,
          KeyboardLayoutName.Spanish_Latin_American_2,
          KeyboardLayoutName.Spanish_Latin_American_3,
          KeyboardLayoutName.Spanish_Latin_American_4,
          KeyboardLayoutName.Spanish_Latin_American_5,
          KeyboardLayoutName.Spanish_Latin_American_6,
        ].includes(currentLayout)
      ) {
        return true;
      }
    }
    return false;
  }

  get isBrazilianABNT2Keyboard(): boolean {
    let currentLayout = this.keyboardLayoutName;
    if (currentLayout) {
      if (
        [KeyboardLayoutName.Brazilian_ABNT2, KeyboardLayoutName.Brazilian_ABNT2_2].includes(
          currentLayout,
        )
      ) {
        return true;
      }
    }
    return false;
  }

  get isGermanQwertzKeyboard(): boolean {
    let currentLayout = this.keyboardLayoutName;
    if (currentLayout) {
      if (
        [
          KeyboardLayoutName.German_QWERTZ_1,
          KeyboardLayoutName.German_QWERTZ_2,
          KeyboardLayoutName.German_QWERTZ_3,
          KeyboardLayoutName.German_QWERTZ_4,
          KeyboardLayoutName.German_QWERTZ_5,
          KeyboardLayoutName.German_QWERTZ_6,
        ].includes(currentLayout)
      ) {
        return true;
      }
    }
    return false;
  }
}

declare module '@ember/service' {
  interface Registry {
    keyboardLayout: KeyboardLayout;
    'keyboard-layout': KeyboardLayout;
  }
}
