/* RESPONSIBLE TEAM: team-channels */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type ConvertToTicket from 'embercom/models/operator/visual-builder/step/convert-to-ticket';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';

export default class ConvertToTicketHasValidTicketType extends BaseValidator {
  @service declare store: Store;
  @service declare intl: IntlService;

  async validate(_value: unknown, _options: ValidationOptions, model: ConvertToTicket) {
    if (!model.ticketTypeId) {
      return true;
    }

    let ticketType = await this.store.findRecord('inbox/ticket-type', model.ticketTypeId);

    if (ticketType.archived) {
      return this.intl.t(
        'operator.workflows.visual-builder.convert-to-ticket.archived-error-message',
        {
          ticketTypeId: model.ticketTypeId,
        },
      );
    }

    return true;
  }

  static getDependentsFor() {
    return ['model.ticketTypeId'];
  }
}
