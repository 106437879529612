/* RESPONSIBLE TEAM: team-customer-data-platform */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  primaryKey: 'identifier',
  attrs: {
    options: {
      serialize: 'records',
      deserialize: 'records',
    },
    relationship: {
      serialize: 'records',
      deserialize: 'records',
    },
    reference: {
      serialize: 'records',
      deserialize: 'records',
    },
  },
});
