/* RESPONSIBLE TEAM: team-purchase-experience */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';

import type IntlService from 'embercom/services/intl';
import { type TaskGenerator } from 'ember-concurrency';

export enum VALID_TICKET_TYPES {
  FREE_TRIAL = 'free_trial',
  CONTRACT_AMENDMENT = 'contract_amendment',
}

export interface RequiredParams {
  app_id: string;
  product_name: string;
  admin_id: string;
  admin_email: string;
}

export interface FreeTrialRequestParams extends RequiredParams {
  current_plan?: string;
}

export interface SubscriptionAmendmentRequestParams extends RequiredParams {
  product_usage: string;
  quoted_cost: number;
  discount: number;
  new_net_usage: string;
  new_net_revenue: number;
  current_plan_name: string;
  discount_percentage: number;
}

export default class CatalogApi extends Service {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked shouldShowFreeTrialSuccessModal = false;
  @tracked shouldShowOrderSummarySuccessModal = false;

  get title() {
    if (this.shouldShowFreeTrialSuccessModal) {
      return this.intl.t('intershop.ticket_creation.free_trial_success_model_header');
    }
    return this.intl.t('intershop.ticket_creation.order_summary_success_model_header');
  }

  get body() {
    if (this.shouldShowFreeTrialSuccessModal) {
      return this.intl.t('intershop.ticket_creation.free_trial_success_model_body');
    }
    return this.intl.t('intershop.ticket_creation.order_summary_success_model_body');
  }

  @action
  openMessenger() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'track_ticket',
      object: 'intershop',
      place: this.shouldShowFreeTrialSuccessModal
        ? 'request_free_trial_ticket'
        : 'request_order_ammendment_ticket',
    });
    this.shouldShowFreeTrialSuccessModal = false;
    this.shouldShowOrderSummarySuccessModal = false;
    window.Intercom('showMessages');
  }

  @action
  closeSuccessModal() {
    this.shouldShowFreeTrialSuccessModal = false;
    this.shouldShowOrderSummarySuccessModal = false;
  }

  @task
  *requestFreeTrial(params: FreeTrialRequestParams): TaskGenerator<void> {
    try {
      let addTicketTypeParam = {
        ...params,
        ticket_type: VALID_TICKET_TYPES.FREE_TRIAL,
      };
      yield post('/ember/intershop/subscription_amendment', addTicketTypeParam);

      this.shouldShowFreeTrialSuccessModal = true;
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('intershop.ticket_creation.request_error'));
    }
  }

  @task
  *requestSubscriptionAmendment(params: SubscriptionAmendmentRequestParams): TaskGenerator<void> {
    try {
      let addTicketTypeParam = {
        ...params,
        ticket_type: VALID_TICKET_TYPES.CONTRACT_AMENDMENT,
      };
      yield post('/ember/intershop/subscription_amendment', addTicketTypeParam);
      this.shouldShowOrderSummarySuccessModal = true;
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('intershop.ticket_creation.request_error'));
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    catalogApi: CatalogApi;
    'catalog-api': CatalogApi;
  }
}
