/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class ChecklistSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    taskGroups: { embedded: 'always' },
  };
}
