/* RESPONSIBLE TEAM: team-frontend-tech */
// @pat - We currently override the base FragmentArrayTransform to always return an array, even when the attr is null/undefined
// See the following issue for more details https://github.com/lytics/ember-data-model-fragments/issues/238#issuecomment-298405050
import FragmentArrayTransform from 'ember-data-model-fragments/transforms/fragment-array';
import { isNone } from '@ember/utils';

export default FragmentArrayTransform.extend({
  deserialize(serialized) {
    if (isNone(serialized)) {
      return this._super([]);
    }

    return this._super(serialized);
  },
});
