/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type BlockList } from '@intercom/interblocks.ts';
import {
  type CopilotChatMessageAnalyticsData,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { type EntityType } from 'embercom/models/data/entity-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import { type MacroAction } from 'embercom/objects/inbox/macro';

type KeyboardShortcutsSheet = {
  name: 'keyboard-shortcuts';
};

type AchievementsSheet = {
  name: 'achievements';
};

type KnowledgeBaseSourcePreviewSheet = {
  name: 'knowledge-base-source-preview';
  entityId: number;
  entityType: EntityType;
  userId: string;
  analyticsData: CopilotChatMessageAnalyticsData;
  highlightedBlockIndices?: [number, number];
  articleVersionId?: string;
};

export type RelatedContentSheet = {
  name: 'related-content';
  data: NormalizedFinSource[];
  insertBlocks: (source: NormalizedFinSource, message: InboxAssistantConversationPart) => void;
  message: InboxAssistantConversationPart;
};

export type ConversationPreviewSheet = {
  name: 'conversation-preview';
  conversationId: number;
  entityId: number;
  highlightedConversationPartSelectors: string[];
  analyticsData: CopilotChatMessageAnalyticsData;
};

export type MacroSheet = {
  name: 'macro-preview';
  entityId: number;
  analyticsData: CopilotChatMessageAnalyticsData;
  conversation: Conversation;
  onAddToComposerClick: (
    blocks: BlockList,
    conversationId: number,
    actions?: MacroAction[],
  ) => void;
  onAddNoteToComposerClick: (
    blocks: BlockList,
    conversationId: number,
    actions?: MacroAction[],
  ) => void;
};

export type KnowledgePanelSheet = {
  name: 'knowledge-panel';
  conversationId: Conversation['id'];
  activeParticipantId: string;
  selectedContentId?: KnowledgeBaseContent['entityId'] | undefined;
  selectedContentType?: EntityType | undefined;
};

type SidebarSheet =
  | KeyboardShortcutsSheet
  | AchievementsSheet
  | KnowledgeBaseSourcePreviewSheet
  | RelatedContentSheet
  | ConversationPreviewSheet
  | KnowledgePanelSheet
  | MacroSheet;

export default class SidebarManager extends Service {
  @tracked stack: SidebarSheet[] = [];

  get isOpen() {
    return this.stack.length > 0;
  }

  get currentSheet(): SidebarSheet | undefined {
    return this.stack[this.stack.length - 1];
  }

  @action openSheet(sheet: SidebarSheet) {
    // If the sheet is already open and in front, do nothing
    if (this.currentSheet?.name === sheet.name) {
      return;
    }

    this.stack = [...this.stack, sheet];
  }

  @action closeSheet(sheetName: SidebarSheet['name']) {
    this.stack = this.stack.filter((s) => s.name !== sheetName);
  }

  @action toggleSheet(sheet: SidebarSheet) {
    if (this.stack.any((s) => s.name === sheet.name)) {
      this.closeSheet(sheet.name);
    } else {
      this.openSheet(sheet);
    }
  }

  @action closeSidebar() {
    this.stack = [];
  }
}

declare module '@ember/service' {
  interface Registry {
    sidebarManager: SidebarManager;
    'sidebar-manager': SidebarManager;
  }
}
