/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const NoOrphanPaths = BaseValidator.extend({
  intl: service(),

  validate(value, options, model) {
    if (value.isEvery('isDeleted')) {
      return this.intl.t(options.message, {
        fullPathTitle: model.fullPathTitle,
      });
    }
    return true;
  },
});

NoOrphanPaths.reopenClass({
  getDependentsFor() {
    return ['model.inwardEdges.@each.isDeleted', 'model.fullPathTitle'];
  },
});

export default NoOrphanPaths;
