/* RESPONSIBLE TEAM: team-proactive-support */
import EmberObject from '@ember/object';
import { isEmpty } from '@ember/utils';

export type EmberCPError = { attribute: string; message: string };

export type EmberCPValidationsResult = {
  message: string | undefined;
  messages: Array<string>;
  error: EmberCPError | undefined;
  errors: Array<EmberCPError>;
  isValid: boolean;
};

export function toErrorResult(errors: Array<EmberCPError>) {
  // Ember CP Validations expects objects to be ember objects so we convert them upfront here.
  let emberObjectErrors = errors.map((e) => EmberObject.create(e));

  return EmberObject.create({
    message: emberObjectErrors[0]?.message,
    error: emberObjectErrors[0],
    errors: emberObjectErrors,
    messages: emberObjectErrors.map((error) => error.message),
    isValid: isEmpty(emberObjectErrors),
  });
}

export interface EmberCPValidationsCompat {
  validations: EmberCPValidationsResult;
}
