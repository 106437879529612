/* RESPONSIBLE TEAM: team-product-guidance */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type ContentImportService from 'embercom/services/content-import-service';
import type Inbox2OnboardingHomeService from 'embercom/services/inbox2-onboarding-home-service';
import RouteRegexes from 'embercom/lib/route-regexes';
import { get } from 'embercom/lib/ajax';
import { trackedFunction } from 'ember-resources/util/function';

const MAX_VISIBLE_PERCENTAGE = 85;

const LegacyGuidesToInstallStepMap: { [key: string]: string } = {
  all_of_intercom: 'set_up_messenger_visitors',
  all_of_intercom_vbp: 'set_up_messenger_visitors',
  conversational_support: 'set_up_messenger_visitors',
  intercom_home_experiment_jan_2023: 'set_up_messenger_primary',
  may_23_consolidation: 'set_up_messenger_primary',
  fin_phase_two: 'set_up_messenger_primary',
  fin_general_release: 'choose_target_audience_go_live_ga',
};

export default class GuideLibraryService extends Service {
  @service declare appService: any;
  @service declare onboardingHomeService: any;
  @service declare store: any;
  @service declare router: any;
  @service declare intercomEventService: any;
  @service declare contentImportService: ContentImportService;
  @service declare realTimeEventService: any;
  @service declare session: any;
  @service declare inbox2OnboardingHomeService: Inbox2OnboardingHomeService;
  @service declare purchaseAnalyticsService: any;
  @tracked showChecklistPopover = false;
  @tracked currentLevel: any;
  @tracked lastCompletedStep: any;
  @tracked isMessengerInstalled = true;
  @tracked forcefulMessengerWizardBrandFetchSuccessful = false;
  @tracked forcefulMessengerWizardURLProvided = false;
  @tracked selectedExistingSupportSolutionZendesk?: boolean;

  constructor() {
    super(...arguments);
    taskFor(this.loadGuide).perform();
    this.addNexusEventListeners();
  }

  willDestroy() {
    super.willDestroy();
    this.removeNexusEventListeners();
  }

  addNexusEventListeners() {
    this.realTimeEventService.on('MessengerFirstPing', this, 'markInstallStepCompleted');
    this.realTimeEventService.on('FirstCustomDataPing', this, 'markCDAStepCompleted');
    this.realTimeEventService.subscribeTopics(['mark-step-as-completed']);
    this.realTimeEventService.on('MarkStepAsCompleted', this, 'markStepCompletedFromEvent');
    this.addWorkflowSetLiveListener();
    this.addOutboundMessageSetLiveListener();
  }

  removeNexusEventListeners() {
    this.realTimeEventService.unsubscribeTopics([
      'mark-step-as-completed',
      'workflow-set-live',
      'outbound-message-set-live',
    ]);
    this.realTimeEventService.off('MarkStepAsCompleted', this, 'markStepCompletedFromEvent');
    this.realTimeEventService.off('WorkflowSetLive', this, 'handleWorkflowSetLiveEvent');
    this.realTimeEventService.off(
      'OutboundMessageSetLive',
      this,
      'handleOutboundMessageSetLiveEvent',
    );
    this.realTimeEventService.off('MessengerFirstPing', this, 'markInstallStepCompleted');
    this.realTimeEventService.off('FirstCustomDataPing', this, 'markCDAStepCompleted');
  }

  markInstallStepCompleted(): void {
    let stepIdentifier = 'guide_library_foundational_steps_messenger_omnichannel';
    if (stepIdentifier) {
      this.markStepCompleted(stepIdentifier);
    }
    this.updateIsMessengerInstalled();
  }

  markCDAStepCompleted(): void {
    if (this.canUseGuideLibraryService) {
      this.markStepCompleted('guide_library_foundational_steps_send_customer_data');
    }
  }

  markStepCompletedFromEvent(event: any) {
    if (
      this.guide?.identifier !== 'great_guidance_guide' &&
      event.step_identifier !== 'great_guidance_setup_messenger'
    ) {
      return;
    }
    this.set('isMessengerInstalled', true);
  }

  @action
  openChecklistPopover() {
    this.showChecklistPopover = true;
  }

  @action
  closeChecklistPopover() {
    this.showChecklistPopover = false;
  }

  get isChecklistPopoverOpen() {
    return this.showChecklistPopover;
  }

  get onInboxRoute() {
    return (
      this.router.currentRouteName.match(RouteRegexes.inbox) ||
      this.router.currentRouteName.match(RouteRegexes.inbox2) ||
      this.router.currentRouteName.startsWith('inbox_loading')
    );
  }

  get progressPercentage() {
    let steps = this.store.peekAll('onboarding/home/step');
    if (this.onInboxRoute) {
      return this._calculateProgressPercentageInbox2();
    } else {
      return this._calculateProgressPercentageStandard(steps);
    }
  }

  get canUseGuideLibraryService() {
    return true;
  }

  get guide() {
    if (this.onInboxRoute) {
      return this.inbox2OnboardingHomeService.guide;
    }
    return this.onboardingHomeService.guide;
  }

  get isLegacyGuide() {
    return this.guide?.identifier in LegacyGuidesToInstallStepMap;
  }

  get progressPercentageColorClass() {
    if (this.progressPercentage <= 29) {
      return 'o__red';
    } else if (this.progressPercentage < 70) {
      return 'o__orange';
    } else {
      return 'o__green';
    }
  }

  get isProgressBadgeVisible() {
    return this.progressPercentage < MAX_VISIBLE_PERCENTAGE && this.progressPercentage > 0;
  }

  get hasAccessToProactiveMonitoring() {
    if (this.onInboxRoute) {
      return false;
    }
    return this.appService.app.hasAccessToProactiveMonitoring;
  }

  @action
  triggerAnalytics(context: string, object: string) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      context,
    });
  }

  private _isMessengerInstalledLegacy(): boolean {
    return !!(
      this.appService.app?.hasAnyInstalledAtDate ||
      this.appService.app?.first_identified_request_at ||
      this.appService.app?.first_anonymous_request_at ||
      this.appService.app?.first_android_request_at ||
      this.appService.app?.first_ios_request_at
    );
  }

  @action
  updateIsMessengerInstalled() {
    let stepCompletionStatus = false;
    let stepIdentifier =
      this.guide?.identifier === 'great_guidance_guide'
        ? 'great_guidance_setup_messenger'
        : 'guide_library_foundational_steps_messenger_omnichannel';
    if (this.onInboxRoute) {
      let step = this.inbox2OnboardingHomeService.guide?.levels
        .flatMap((level: any) => level.steps)
        .find((step: any) => step.identifier === stepIdentifier);
      stepCompletionStatus = step?.state === 'completed';
    } else {
      let step = this.store.peekRecord('onboarding/home/step', stepIdentifier);
      stepCompletionStatus = step?.completed || this._isMessengerInstalledLegacy();
    }
    this.set('isMessengerInstalled', stepCompletionStatus);
  }

  _calculateProgressPercentageInbox2() {
    if (!this.inbox2OnboardingHomeService.guide || !this.inbox2OnboardingHomeService.guide.levels) {
      return -1;
    }
    let steps = this.inbox2OnboardingHomeService.guide?.levels
      .flatMap((level: any) => level.steps)
      .filter(
        (step: any) =>
          step.identifier.startsWith('guide_library_foundational_steps') ||
          step.identifier.startsWith('display_only'),
      );
    let completedSteps = steps.filter((step: any) => step.state === 'completed');
    if (steps.length === 0) {
      return -1;
    }
    return Math.round((completedSteps.length / steps.length) * 100);
  }

  _calculateProgressPercentageStandard(steps: any) {
    steps = steps.filter(
      (step: any) =>
        step.id.startsWith('guide_library_foundational_steps') ||
        step.id.startsWith('display_only'),
    );
    let completedSteps = steps.filter((step: any) => step.state === 'completed');
    if (steps.length === 0) {
      return -1;
    }
    return Math.round((completedSteps.length / steps.length) * 100);
  }

  subscribeToContentImportUpdates() {
    this.contentImportService.subscribeToContentImportRunStatusUpdates();
    this.contentImportService.assignCompletionCallback(
      this.markStepCompleted.bind(this, 'display_only_setup_fin_v2'),
    );
  }

  addWorkflowSetLiveListener() {
    this.realTimeEventService.subscribeTopics(['workflow-set-live']);
    this.realTimeEventService.on('WorkflowSetLive', this, 'handleWorkflowSetLiveEvent');
  }

  addOutboundMessageSetLiveListener() {
    this.realTimeEventService.subscribeTopics(['outbound-message-set-live']);
    this.realTimeEventService.on(
      'OutboundMessageSetLive',
      this,
      'handleOutboundMessageSetLiveEvent',
    );
  }

  async handleWorkflowSetLiveEvent(e: any) {
    if (this.canUseGuideLibraryService) {
      if (e.admin_id.toString() === this.appService.app.currentAdmin.id) {
        if (e.has_fin) {
          await this.markStepCompleted('display_only_test_fin_v2');
        } else {
          await this.markStepCompleted('display_only_set_a_workflow_live');
        }
      }
    }
  }

  async handleOutboundMessageSetLiveEvent(e: any) {
    if (this.canUseGuideLibraryService) {
      if (e.admin_id.toString() === this.appService.app.currentAdmin.id) {
        if (e.message_type === 'chat') {
          await this.markStepCompleted('display_only_set_chat_live');
        } else if (e.message_type === 'banner') {
          await this.markStepCompleted('display_only_set_banner_live');
        }
      }
    }
  }

  @action
  markStepCompleted(stepIdentifier: string) {
    return taskFor(this.completeStep).perform(stepIdentifier);
  }

  @restartableTask
  *loadGuide(): TaskGenerator<any> {
    if (this.onInboxRoute && !this.inbox2OnboardingHomeService.guide) {
      yield this.inbox2OnboardingHomeService.loadGuide();
    } else if (!this.onboardingHomeService.guide) {
      yield this.onboardingHomeService.findOrLoadGuide(false);
    }
    this.currentLevel = this.store.peekRecord(
      'onboarding/home/level',
      'evaluation_wizard_helpdesk_v2',
    );
    this.updateIsMessengerInstalled();
  }

  @restartableTask
  *completeStep(stepIdentifier: string): TaskGenerator<any> {
    try {
      yield taskFor(this.loadGuide).perform();
      let step = this.store.peekRecord('onboarding/home/step', stepIdentifier);

      if (!step) {
        return;
      }

      if (!step.completed) {
        step.set('state', 'completed');
        yield step.saveAndRefreshGuide();

        if (this.canUseGuideLibraryService) {
          this.currentLevel = this.store.peekRecord(
            'onboarding/home/level',
            step.level.get('identifier'),
          );
          this.lastCompletedStep = step;

          if (this.router.currentRouteName !== 'apps.app.guide-library') {
            this.openChecklistPopover();
          }

          this.intercomEventService.trackAnalyticsEvent({
            action: 'completed',
            object: 'step',
            step_identifier: stepIdentifier,
            context: 'guide_library_service',
          });
        }
      }
    } catch (e) {
      console.error('Error while completing step:', stepIdentifier);
      console.error(e);
      try {
        captureException(new Error(`Error while completing step ${stepIdentifier}`), {
          fingerprint: ['guide-library-service', 'completeStep', stepIdentifier],
          extra: {
            guide_exists: !!this.onboardingHomeService.guide,
          },
        });
      } catch (e) {
        // noop
      }
    }
  }

  get existingSupportSolutionZendesk(): boolean | undefined {
    if (!this.appService.app?.id || !this.appService.app.canMigrateFromZendesk) {
      return false;
    }
    if (this.selectedExistingSupportSolutionZendesk !== undefined) {
      return this.selectedExistingSupportSolutionZendesk;
    }
    if (this.leadProfileSurveyQuestionLoader.isPending) {
      return undefined;
    }
    let question = this.leadProfileSurveyQuestionLoader.value;
    if (question) {
      return (
        question?.answers
          ?.findBy('identifier', 'yes')
          ?.follow_up_question.answers?.findBy('identifier', 'zendesk')?.selected || false
      );
    }
    return false;
  }

  private leadProfileSurveyQuestionLoader = trackedFunction(this, async () => {
    return await get('/ember/onboarding/lead_profile_survey/question', {
      app_id: this.appService.app.id,
      question_identifier: 'do_you_already_use_a_customer_support_tool',
    });
  });
}

declare module '@ember/service' {
  interface Registry {
    guideLibraryService: GuideLibraryService;
    'guide-library-service': GuideLibraryService;
  }
}
