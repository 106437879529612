/* RESPONSIBLE TEAM: team-tickets-1 */
import Service from '@ember/service';

export default class DetectIntercomradesDomainService extends Service {
  get domain() {
    return document.domain;
  }

  get isIntercomradesDomain() {
    return this.domain.includes('intercomrade');
  }
}
