/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxState from 'embercom/services/inbox-state';
import { ConversationsViewType } from 'embercom/services/inbox-state';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export enum LbaTriggerEvent {
  CLOSE = 'close',
  SEND_AND_CLOSE = 'send_and_close',
  UPDATE_ATTR_AND_CLOSE = 'update_attr_and_close',
  RESOLVE_AND_CLOSE = 'resolve_and_close',
  CLOSE_WITHOUT_RESOLVE = 'close_without_resolving',
  SNOOZE = 'snooze',
  SEND_AND_SNOOZE = 'send_and_snooze',
  ONLINE = 'online',
  ASSIGNMENT = 'assignment',
  BULK_ASSIGN = 'bulk_assign',
  BULK_CLOSE = 'bulk_close',
}

export default class LbaMetricsService extends Service {
  @service declare inboxState: InboxState;

  teammateWaitingAt: undefined | number = undefined;
  lbaTriggerEvent: undefined | LbaTriggerEvent = undefined;

  get shouldTrackTeammateWaitTime() {
    return (
      this.inboxState.activeConversationsView === ConversationsViewType.List &&
      this.inboxState.activeInbox?.type === InboxType.Admin
    );
  }

  trackTeammateMaybeWaitingForNewConversationAt(lbaTriggerEvent?: LbaTriggerEvent) {
    if (!this.shouldTrackTeammateWaitTime) {
      return;
    }

    this.teammateWaitingAt = performance.now();
    this.lbaTriggerEvent = lbaTriggerEvent;
  }

  getTeammateWaitMetrics(
    conversationAssignedToTeamAt?: number,
    conversationAssignedToTeammateAt?: number,
  ): undefined | { teammateWaitTime: number; lbaTriggerEvent?: LbaTriggerEvent } {
    if (
      !this.teammateWaitingAt ||
      !conversationAssignedToTeamAt ||
      !conversationAssignedToTeammateAt
    ) {
      return undefined;
    }

    let conversationTimeInInbox = conversationAssignedToTeammateAt - conversationAssignedToTeamAt;
    let teammateWaitTime = performance.now() - this.teammateWaitingAt;
    let lbaTriggerEvent = this.lbaTriggerEvent;

    // reset tracking when we have reported assignment metrics
    this.reset();

    // conversation time in inbox should be greater than teammate waiting time
    if (conversationTimeInInbox > teammateWaitTime) {
      return {
        teammateWaitTime,
        lbaTriggerEvent,
      };
    }

    return undefined;
  }

  reset() {
    this.teammateWaitingAt = undefined;
    this.lbaTriggerEvent = undefined;
  }

  /*
   * Reset teammate wait time after any new assignment
   * */
  newAssignmentReset(
    assignedConversations: ConversationSummary[],
    newConversations: ConversationSummary[],
  ) {
    let assignedConversationIds = assignedConversations.map(
      (assignedConversation) => assignedConversation.id,
    );

    if (
      newConversations.length > 0 &&
      !newConversations.every((newConversation) =>
        assignedConversationIds.includes(newConversation.id),
      )
    ) {
      this.reset();
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'lba-latency-metrics': LbaMetricsService;
  }
}
