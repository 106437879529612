/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';

export default Service.extend({
  store: service(),
  intl: service(),

  providerNameMap: {
    twitter: 'Twitter',
    facebook: 'Facebook',
    linkedin: 'LinkedIn',
  },
  notificationsService: service('notificationsService'),

  providerName(provider) {
    return this.providerNameMap[provider];
  },

  updateSocialAccounts(admin) {
    return this.store.query('social-account', { admin_id: admin.get('id') });
  },

  oAuthSuccess(admin, provider) {
    return this.updateSocialAccounts(admin).then((items) => {
      admin.setSocialAccounts(items);
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.admin.about.oauth-success', {
          providerName: this.providerName(provider),
        }),
        3000,
      );
      return items;
    });
  },

  oAuthFailure(provider) {
    this.notificationsService.notifyError(
      this.intl.t('components.admin.about.oauth-failure', {
        providerName: this.providerName(provider),
      }),
      3000,
    );
  },

  deleteSocialAccount(account, admin, provider) {
    return account.destroyRecord().then(() => {
      return this.updateSocialAccounts(admin).then((items) => {
        admin.setSocialAccounts(items);
        this.notificationsService.notifyConfirmation(
          this.intl.t('components.admin.about.social-account-deleted', {
            providerName: this.providerName(provider),
          }),
          3000,
        );
      });
    });
  },
});
