/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const MessageVariationContentButtonNoLinkUrl = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('message.isMobilePush') || model.get('emailTemplate.isHtmlMode')) {
      return true;
    }

    let buttons = value.filterBy('type', 'button');
    if (buttons.any((button) => !button.get('linkUrl'))) {
      return 'A button is missing a link';
    }

    return true;
  },
});

MessageVariationContentButtonNoLinkUrl.reopenClass({
  getDependentsFor() {
    return ['model.mutations.lastMutatedAt'];
  },
});

export default MessageVariationContentButtonNoLinkUrl;
