/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias, bool, filterBy, gt, not, readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import {
  addProperties,
  greaterThanProperty,
  lessThanProperty,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import { all, task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import currencyFormatter from 'embercom/lib/currency-formatter';
import { Promise as EmberPromise } from 'rsvp';
import { isEmpty } from 'underscore';

const FREE_PLAN_SEAT_COUNT = 1;
const MAXIMUM_NUMBER_OF_INFLIGHT_UPDATE_PERMISSIONS_REQUESTS = 10;

export default Service.extend({
  intercomEventService: service(),
  appService: service(),
  modelDataCacheService: service(),
  store: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  notificationsService: service(),
  customerService: service(),
  router: service(),
  intl: service(),
  intercomConfirmService: service(),

  invitedAdmins: null,
  app: readOnly('appService.app'),
  teammates: alias('app.admins'),
  adminsWithInboxAccess: readOnly('app.adminsWithInboxAccess'),
  adminsWithoutInboxAccess: readOnly('app.adminsWithoutInboxAccess'),
  adminsWithInboxAccessCount: readOnly('adminsWithInboxAccess.length'),
  adminsWithoutInboxAccessCount: readOnly('adminsWithoutInboxAccess.length'),
  activeInvitedAdmins: filterBy('invitedAdmins', 'active', true),
  invitesWithInboxAccess: filterBy('activeInvitedAdmins', 'hasInboxAccess', true),
  invitesWithInboxAccessCount: readOnly('invitesWithInboxAccess.length'),
  hasInvitesWithInboxAccess: gt('invitesWithInboxAccessCount', 0),
  adminsAndInvitesWithInboxAccessCount: addProperties(
    'adminsWithInboxAccessCount',
    'invitesWithInboxAccessCount',
  ),

  onFreePlan: computed(
    'customerService.customer.hasActiveSubscription',
    'app.canUseFree',
    function () {
      return (
        !this.get('customerService.customer.hasActiveSubscription') && this.get('app.canUseFree')
      );
    },
  ),
  currentEarlyStageCustomer: bool('customerService.customer.currentEarlyStageCustomer'),
  hasNoSubscriptionForInbox: not('app.inboxProduct.active'),
  freeSeatCount: readOnly('app.customer.freeSeatCount'),
  formattedPricePerSeat: computed('customerService.pricePerInboxSeat', function () {
    return currencyFormatter(this.get('customerService.pricePerInboxSeat'));
  }),
  pricingTransitionDate: computed(
    'intl.locale',
    'customerService.migrationState.date_migration_complete',
    function () {
      let date = this.get('customerService.migrationState.date_migration_complete') || new Date();
      return this.intl.formatDate(date, { month: 'short', day: 'numeric' });
    },
  ),
  aboveFreeSeatThreshold: greaterThanProperty(
    'adminsAndInvitesWithInboxAccessCount',
    'freeSeatCount',
  ),
  allFreeSeatsNotActivelyOccupied: lessThanProperty('adminsWithInboxAccessCount', 'freeSeatCount'),
  allFreeSeatsOccupiedByActiveAdmins: not('allFreeSeatsNotActivelyOccupied'),
  priceWillDecreaseMessage: computed(
    'intl.locale',
    'app.{canUseSeatOverages,isOptedOutOfSeatBlocking,isSalesforceContracted}',
    'formattedPricePerSeat',
    function () {
      if (this.app.isSalesforceContracted && this.app.isOptedOutOfSeatBlocking) {
        return '';
      }
      return this.app.isSalesforceContracted && this.app.canUseSeatOverages
        ? this.intl.t(
            'apps.app.settings.modals.reassign-messages.seat-overages-price-will-decrease-message',
            { formattedPricePerSeat: this.formattedPricePerSeat },
          )
        : this.intl.t('apps.app.settings.modals.reassign-messages.price-will-decrease-message', {
            formattedPricePerSeat: this.formattedPricePerSeat,
          });
    },
  ),
  pricingTransitionPriceWillDecreaseMessage: computed(
    'intl.locale',
    'pricingTransitionDate',
    'formattedPricePerSeat',
    function () {
      return this.intl.t(
        'apps.app.settings.modals.reassign-messages.pricing-transition-price-will-decrease-message',
        {
          pricingTransitionDate: this.pricingTransitionDate,
          formattedPricePerSeat: this.formattedPricePerSeat,
        },
      );
    },
  ),
  priceWillNotChangeMessage: computed('intl.locale', function () {
    return this.intl.t('apps.app.settings.modals.reassign-messages.price-will-not-change-message');
  }),
  pricingTransitionPriceWillNotChangeMessage: computed(
    'intl.locale',
    'pricingTransitionDate',
    function () {
      return this.intl.t(
        'apps.app.settings.modals.reassign-messages.pricing-transition-price-will-not-change-message',
        {
          pricingTransitionDate: this.pricingTransitionDate,
        },
      );
    },
  ),
  removeSeatPriceInfo: ternaryToProperty(
    'aboveFreeSeatThreshold',
    'priceWillDecreaseMessage',
    'priceWillNotChangeMessage',
  ),
  removedSeatPriceInfo: computed('intl.locale', function () {
    return this.intl.t('apps.app.settings.modals.reassign-messages.removed-seat-price-info');
  }),
  pricingTransitionRemoveSeatPriceInfo: ternaryToProperty(
    'aboveFreeSeatThreshold',
    'pricingTransitionPriceWillDecreaseMessage',
    'pricingTransitionPriceWillNotChangeMessage',
  ),
  hasSeatsLeft: gt('seatsLeft', 0),
  seatsLeft: computed(
    'freeSeatCount',
    'adminsAndInvitesWithInboxAccessCount',
    'onFreePlan',
    function () {
      let adminsAndInvitesWithInboxAccessCount = this.adminsAndInvitesWithInboxAccessCount;
      if (this.onFreePlan) {
        return Math.max(FREE_PLAN_SEAT_COUNT - adminsAndInvitesWithInboxAccessCount, 0);
      }
      return Math.max(this.freeSeatCount - adminsAndInvitesWithInboxAccessCount, 0);
    },
  ),
  async giveInboxSeat(admin) {
    let isConfirmed = await this.showChangedSeatsModalForAdmin(admin);
    if (!isConfirmed) {
      return;
    }
    await this.savePermission(
      admin,
      admin.get('currentAppPermissions'),
      true,
      this.intl.t('settings.teammates.permissions.notification.inbox-seat-given', {
        adminName: admin.get('name'),
      }),
    );
  },

  async removeInboxSeat(admin, teammates) {
    return this._openReassignModal(admin, teammates);
  },

  savePermission(admin, permission, hasInboxAccess, confirmationNotificationMessage) {
    return this._savePermissionTask.perform(
      admin,
      permission,
      hasInboxAccess,
      confirmationNotificationMessage,
    );
  },

  savePermissionAndSeats(admin, permission, seatTypes) {
    return this._savePermissionAndSeatsTask.perform(admin, permission, seatTypes);
  },

  _saveChangesToPermission: task(function* (teammate, permissionChanges) {
    let permissions = teammate.currentAppPermissions;
    permissions.setProperties(permissionChanges);
    try {
      yield permissions.save();
    } catch (err) {
      console.error(err);
      permissions.rollbackAttributes();
      throw err;
    }
  })
    .maxConcurrency(MAXIMUM_NUMBER_OF_INFLIGHT_UPDATE_PERMISSIONS_REQUESTS)
    .enqueue(),

  _bulkEditPermissions: task(function* (teammates, permissionChanges) {
    // we can't reload individual models since the "show" action doesn't exist
    yield this.app.fetchAndUpdateAllAdminPermissions();
    let tasks = teammates.map((teammate) =>
      this._saveChangesToPermission.perform(teammate, permissionChanges),
    );

    yield all(tasks);
  }),

  _bulkEditSeatsAndPermissions: task(
    function* (teammates, permissionChanges, seatTypes, reassignments) {
      // we can't reload individual models since the "show" action doesn't exist
      yield this.app.fetchAndUpdateAllAdminPermissions();
      let tasks = teammates.map((teammate) => {
        teammate.currentAppPermissions.setProperties(permissionChanges);
        if (!seatTypes[teammate.id].length) {
          teammate.currentAppPermissions.reassignments = reassignments;
        }
        return this._savePermissionAndSeatsTaskBulkEdition.perform(
          teammate,
          seatTypes[teammate.id],
        );
      });
      yield all(tasks);
    },
  ),

  bulkEditPermissions(teammates, permissionChanges) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'permissions',
      teammateIds: teammates.map((t) => t.id),
      teammateCount: teammates.length,
      permissionChanges,
    });
    return this._bulkEditPermissions.perform(teammates, permissionChanges);
  },

  bulkEditSeatsAndPermissions(teammates, permissionChanges, seatTypeByUsers, reassignments) {
    return this._bulkEditSeatsAndPermissions.perform(
      teammates,
      permissionChanges,
      seatTypeByUsers,
      reassignments,
    );
  },

  getInTransitionAddTeammateModalBody(adminName) {
    let transitionDate = this.pricingTransitionDate;
    let seatPrice = this.formattedPricePerSeat;

    return this.hasSeatsLeft
      ? this.intl.t('settings.teammate-list.changed-seats-modal.in-transition-fill-seat-message', {
          adminName,
          transitionDate,
        })
      : this.intl.t('settings.teammate-list.changed-seats-modal.in-transition-add-seat-message', {
          adminName,
          transitionDate,
          seatPrice,
        });
  },

  getAddTeammateModalBody(adminName) {
    let seatPrice = this.formattedPricePerSeat;
    if (this.hasSeatsLeft) {
      return this.intl.t('settings.teammate-list.changed-seats-modal.fill-seat-message', {
        adminName,
      });
    }
    if (this.get('app.isDeveloperWorkspace')) {
      return this.intl.t(
        'settings.teammate-list.changed-seats-modal.developer-workspace-add-seat-message',
      );
    }
    let priceChangeWarning = this.intl.t(
      'settings.teammate-list.changed-seats-modal.add-seat-price-change-message',
      {
        adminName,
        seatPrice,
      },
    );

    if (!this.hasSeatsLeft && this.app.isSalesforceContracted && this.app.canUseSeatOverages) {
      priceChangeWarning = this.intl.t(
        'settings.teammate-list.changed-seats-modal.add-seat-inbox-full-message',
        {
          adminName,
          seatPrice,
        },
      );
    }
    return this._maybeAddPendingInviteNoticeToText(priceChangeWarning);
  },

  showChangedSeatsModalForAdmin(admin) {
    if (this.currentEarlyStageCustomer) {
      return true;
    }
    if (this.app.isSalesforceContracted && !this.app.canUseSeatOverages) {
      return true;
    }

    let adminName = admin.get('name');
    let options = {
      title: this.hasSeatsLeft
        ? this.intl.t('settings.teammate-list.changed-seats-modal.fill-an-inbox-seat')
        : this.intl.t('settings.teammate-list.changed-seats-modal.add-new-inbox-seat'),
      body: this.get('customerService.appIsInPricingTransition')
        ? this.getInTransitionAddTeammateModalBody(adminName)
        : this.getAddTeammateModalBody(adminName),
      confirmButtonText: this.hasSeatsLeft
        ? this.intl.t('settings.teammate-list.changed-seats-modal.fill-seat')
        : this.intl.t('settings.teammate-list.changed-seats-modal.add-seat'),
    };
    return this._showIntercomConfirmModal(options);
  },

  _maybeAddPendingInviteNoticeToText(modalText) {
    if (this.allFreeSeatsOccupiedByActiveAdmins) {
      return modalText;
    }

    if (this.hasInvitesWithInboxAccess) {
      let numberOfAdminsWithSeats = this.adminsWithInboxAccessCount;
      let numberOfInviteesWithSeats = this.invitesWithInboxAccessCount;

      modalText += ` ${this.intl.t(
        'settings.teammate-list.changed-seats-modal.projected-change-message',
        {
          numberOfAdminsWithSeats,
          numberOfInviteesWithSeats,
        },
      )}`;
    }
    return modalText;
  },

  _showIntercomConfirmModal(options) {
    return this.intercomConfirmService.confirm(options);
  },

  _openReassignModal(admin, teammates) {
    let baseReassignMessage =
      this.adminsAndInvitesWithInboxAccessCount > this.freeSeatCount &&
      this.app.isSalesforceContracted &&
      this.app.canUseSeatOverages
        ? `${this.intl.t('apps.app.settings.modals.reassign-messages.remove-seat-info')} `
        : `${this.intl.t('apps.app.settings.modals.reassign-messages.remove-seat-info-from-app', {
            appName: this.app.name,
          })} `;

    let removeSeatPriceInfoText = this.get('customerService.appIsInPricingTransition')
      ? this.pricingTransitionRemoveSeatPriceInfo
      : this.removeSeatPriceInfo;

    let removeSeatPriceInfo = this.currentEarlyStageCustomer
      ? this.priceWillNotChangeMessage
      : removeSeatPriceInfoText;
    return new EmberPromise((resolve) => {
      this.modalService.openModal(
        'settings/modals/reassign-messages',
        admin,
        {
          teammates,
          removeSeatPriceInfo: baseReassignMessage.concat(removeSeatPriceInfo),
          removedSeatPriceInfo: this.removedSeatPriceInfo,
          hadInboxAccess: true,
        },
        undefined,
        {
          onClose: resolve,
        },
      );
    });
  },
  _updateOutOfOfficeSettings(admin, adminIsAvailable) {
    if (
      (this.get('app.requiresInboxSeatAccess') || this.get('app.hasMultipleSeatTypes')) &&
      !adminIsAvailable &&
      admin.get('reassign_conversations')
    ) {
      admin.set('reassign_conversations', false);
      admin.saveOutOfOfficeSettings();
    }
  },

  _cleanUpTeamMembershipsAndReturnWhetherCacheShouldBeUpdated(permission, shouldDeleteEmptyTeams) {
    if (shouldDeleteEmptyTeams && permission.get('reassign_replies_teams.length') > 0) {
      let idsOfDeletedTeams = permission.get('reassign_replies_teams').mapBy('team_id');
      let teamsToBeDeleted = this.get('app.teams').filter((team) =>
        idsOfDeletedTeams.includes(team.get('id')),
      );
      teamsToBeDeleted.forEach((team) => this.app.removeTeam(team));
      return true;
    }
    return false;
  },

  _afterPermissionSave(admin, permission, shouldDeleteEmptyTeams) {
    let shouldUpdateAppCache = this._cleanUpTeamMembershipsAndReturnWhetherCacheShouldBeUpdated(
      permission,
      shouldDeleteEmptyTeams,
    );
    let isAdmin = admin.constructor.modelName !== 'invited-admin';
    let shouldUpdateAdminAndAppCache = isAdmin && admin.id === this.app.currentAdmin.id;
    if (shouldUpdateAdminAndAppCache) {
      admin.updateLocalCache(this.modelDataCacheService);
      this.app.updateLocalCache(this.modelDataCacheService);
    } else if (shouldUpdateAppCache) {
      this.app.updateLocalCache(this.modelDataCacheService);
    }
  },

  _savePermissionTask: task(
    function* (admin, permission, hasInboxAccess, confirmationNotificationMessage) {
      permission.set('has_inbox_access', hasInboxAccess);
      this._updateOutOfOfficeSettings(admin, hasInboxAccess);
      try {
        yield permission.save();
        this._afterPermissionSave(admin, permission, !hasInboxAccess);
        this.notificationsService.notifyConfirmation(
          confirmationNotificationMessage ||
            this.intl.t('settings.teammates.permissions.notification.permissions-changed', {
              adminName: admin.name,
            }),
        );
      } catch (error) {
        if (error.jqXHR?.responseJSON?.error_code === 'seat_limit_reached') {
          permission.set('has_inbox_access', !hasInboxAccess);
          this._updateOutOfOfficeSettings(admin, !hasInboxAccess);
          this.notificationsService.notifyErrorWithButton(
            this.intl.t('pricing-and-packaging.multi_workspace_seat_limit_reached.error_message'),
            {
              label: this.intl.t(
                'pricing-and-packaging.multi_workspace_seat_limit_reached.button_label',
              ),
              action: this.redirectToBilling.bind(this),
            },
            10000,
          );
        } else {
          let message;
          if (
            this.appService.app.cannotAssignWiderAccess &&
            isPresent(error?.jqXHR?.responseJSON?.tokens)
          ) {
            message = this.intl.t('settings.error_message.cannot_modify_unowned_permissions_error');
          } else {
            message =
              error?.jqXHR?.responseJSON?.message ||
              this.intl.t('settings.error_message.cannot_save_error');
          }
          this.notificationsService.notifyError(message);
        }
        throw error;
      }
    },
  ),

  redirectToBilling() {
    this.router.transitionTo('apps.app.billing');
  },

  _savePermissionAndSeatsTaskBulkEdition: task(function* (admin, seatTypes) {
    let adminIsAvailable = !isEmpty(seatTypes);
    this._updateOutOfOfficeSettings(admin, adminIsAvailable);
    try {
      let updatedPermission = yield ajax({
        url: `/ember/admins/update_permissions_and_seats`,
        type: 'PUT',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          permission_id: admin.currentAppPermissions.id,
          permissions_object: admin.currentAppPermissions.serialize(),
          seat_types: seatTypes,
        }),
      });
      this.store.pushPayload({ permission: updatedPermission });
      this.store.pushPayload({ admin: { id: admin.id, seats: seatTypes } });
      this._afterPermissionSave(admin, admin.currentAppPermissions, !adminIsAvailable);
    } catch (error) {
      let message =
        error?.jqXHR?.responseJSON?.message ||
        this.intl.t('settings.teammates.permissions.notification.unexpected-error');
      this.notificationsService.notifyError(message);
      throw error;
    }
  })
    .maxConcurrency(MAXIMUM_NUMBER_OF_INFLIGHT_UPDATE_PERMISSIONS_REQUESTS)
    .enqueue(),

  _savePermissionAndSeatsTask: task(function* (admin, permission, seatTypes) {
    let adminIsAvailable = !isEmpty(seatTypes);
    this._updateOutOfOfficeSettings(admin, adminIsAvailable);
    try {
      let updatedPermission = yield ajax({
        url: `/ember/admins/update_permissions_and_seats`,
        type: 'PUT',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          permission_id: permission.id,
          permissions_object: permission.serialize(),
          seat_types: seatTypes,
        }),
      });

      this.store.pushPayload({ permission: updatedPermission });
      this._afterPermissionSave(admin, permission, !adminIsAvailable);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'settings.teammates.permissions.notification.permissions-and-seats-changed-for-admin',
          { adminName: admin.name },
        ),
      );
      // Marks the seatUsageUpdated state as changed.
      this.customerService.seatUsageUpdated = true;
    } catch (error) {
      let message =
        error?.jqXHR?.responseJSON?.message ||
        this.intl.t('settings.teammates.permissions.notification.unexpected-error');
      this.notificationsService.notifyError(message);
      throw error;
    }
  }),
});
