/* RESPONSIBLE TEAM: team-messenger */
import Service, { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';

export interface Newsfeed {
  id: string;
  name?: string;
  platform?: string;
}

export interface NewsItem {
  newsfeedIds: number[];
}

export default class NewsfeedsService extends Service {
  @service declare store: Store;
  @tracked newsfeeds: Newsfeed[] = [];

  @task({ drop: true }) *loadNewsfeeds(): TaskGenerator<Newsfeed[]> {
    this.newsfeeds = yield this.store.findAll('news/newsfeed');
    return this.newsfeeds;
  }

  get newsfeedWeb(): Newsfeed | { id: null } {
    return this.newsfeeds.findBy('platform', 'web') || { id: null };
  }

  get targetedNewsfeeds(): Newsfeed[] {
    return this.newsfeeds.filter(({ platform }) => platform !== 'web');
  }

  assignedToNewsfeedWeb(newItem: NewsItem): boolean {
    let selectedNewsfeedIds = newItem.newsfeedIds || [];
    return selectedNewsfeedIds.includes(Number(this.newsfeedWeb.id));
  }
}

declare module '@ember/service' {
  interface Registry {
    newsfeeds: NewsfeedsService;
    newsfeedsService: NewsfeedsService;
    'newsfeeds-service': NewsfeedsService;
  }
}
