/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  localizedEmailContents: {
    description: 'Localized email contents',
    validators: [validator('has-many')],
  },
  subscriptionTypeId: {
    description: 'Subscription type id',
    validators: [validator('subscription-type-is-not-archived')],
  },
  sendToUnsubscribed: {
    description: 'Send to Unsubscribed',
    validators: [
      validator('custom-domain-required', {
        message: 'outbound.email.error-messages.custom-domain-required-for-unsubscribed',
      }),
    ],
  },
  isLegallyMandated: {
    description: 'Legally mandated Email',
    validators: [
      validator('custom-domain-required', {
        message: 'outbound.email.error-messages.custom-domain-required-for-legally-mandated',
      }),
      validator('custom-email-assets-domain-required'),
    ],
  },
});
