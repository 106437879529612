/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const PointerSelector = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isPointer')) {
      if (isEmpty(value)) {
        return 'Choose where to point your message at';
      }
      try {
        document.querySelector(value);
      } catch (_e) {
        return 'CSS code is not valid.';
      }
    }
    return true;
  },
});

PointerSelector.reopenClass({
  getDependentsFor() {
    return ['model.isPointer'];
  },
});

export default PointerSelector;
