/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

const RulesetDoesNotTargetMobileOnlyAndNonUsers = BaseValidator.extend({
  validate(value, options, model) {
    let devicesPreferred = model.rulesetLinks
      .map((rulesetLink) => rulesetLink.preferredDevices)
      .flat()
      .compact();

    let targets = function (userType) {
      if (isEmpty(value?.value)) {
        return false;
      }

      if (value.comparison === 'in') {
        return value.userTypes.includes(userType);
      } else {
        return value.userType === userType;
      }
    };

    let targetsLeads = targets('lead');
    let targetsVisitors = targets('visitor');
    let targetsWeb = isEmpty(devicesPreferred) || devicesPreferred.includes(preferredDevices.web);
    let hasPushRulesetLink = model.rulesetLinks.any((rulesetLink) => {
      return rulesetLink.type === 'matching-system/ruleset-links/push';
    });

    if ((targetsLeads || targetsVisitors) && hasPushRulesetLink) {
      return 'You can not target leads or visitors with a mobile push.';
    } else if ((targetsLeads || targetsVisitors) && !targetsWeb) {
      return 'You can not target leads or visitors unless you show the message on Web.';
    } else {
      return true;
    }
  },
});

RulesetDoesNotTargetMobileOnlyAndNonUsers.reopenClass({
  getDependentsFor() {
    return ['model.rulesetLinks.@each.preferredDevices', 'model.userRolePredicate.userTypes'];
  },
});

export default RulesetDoesNotTargetMobileOnlyAndNonUsers;
