/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Promise as EmberPromise } from 'rsvp';

type ConfirmOptions = {
  autofocus?: boolean;
  title?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  body?: string;
  bodyComponentName?: string;
  warning?: string;
  confirmContext?: any;
  center?: boolean;
  invertButtonTypes?: boolean;
  primaryButtonType?: string;
  secondaryButtonType?: string;
  invertButtonActions?: boolean;
  distinguishCancelFromClose?: boolean;
  hideCancelButton?: boolean;
  shouldTrackAnalyticsEvents?: boolean;
  analyticsEventChannelName?: string;
};

export default class IntercomConfirmService extends Service {
  @tracked isOpen = false;
  @tracked options: ConfirmOptions | null = null;
  reject: ((reason?: any) => void) | null = null;
  resolve: ((value?: any) => void) | null = null;

  async _confirm(options: ConfirmOptions) {
    return new EmberPromise((resolve, reject) => {
      this.openConfirm(resolve, reject, options);
    });
  }

  async confirm(options: ConfirmOptions) {
    try {
      await this._confirm(options);
      return true;
    } catch (e) {
      if (options.distinguishCancelFromClose) {
        return e;
      }
      return false;
    }
  }

  openConfirm(
    resolve: (value?: any) => void,
    reject: (reason?: any) => void,
    options: ConfirmOptions,
  ) {
    this.resolve = resolve;
    this.reject = reject;
    this.options = options;
    this.isOpen = true;
  }

  closeConfirm() {
    this.isOpen = false;
    this.resolve = null;
    this.reject = null;
    this.options = null;
  }
}

declare module '@ember/service' {
  interface Registry {
    intercomConfirmService: IntercomConfirmService;
    'intercom-confirm-service': IntercomConfirmService;
  }
}
