/* RESPONSIBLE TEAM: team-product-guidance */
import Service, { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import emailLowEngagementData from 'embercom/lib/outbound/email-low-engagement-data';

export const STAT_PROCESS_COMPLETE = 'stat-process-complete';

export default class LowEngagementContentService extends Service {
  @service appService;
  @service intercomEventService;
  @service router;

  get app() {
    return this.appService.app;
  }

  isLowEngagementContent(contentWrapper) {
    // Ignore A/B Tests
    if (
      contentWrapper.hasOneContentFragment &&
      this.router.currentRouteName === 'apps.app.outbound.email.index'
    ) {
      let content = contentWrapper.versionA;
      if (content.contentType === objectTypes.email) {
        return this.processEmailEngagementStatistics(content);
      }
    }

    return false;
  }

  processEmailEngagementStatistics(content) {
    let stats = content.stats;
    let sentStatistics = stats.findBy('key', statisticKeys.receipts)?.value;

    let engagementData = emailLowEngagementData(
      content.wentLiveAt,
      sentStatistics || 0,
      stats.findBy('key', statisticKeys.opens)?.value || 0,
      stats.findBy('key', statisticKeys.failures)?.subaggregations,
    );

    let isLowEngagement = Object.keys(engagementData).length > 0;

    if (sentStatistics && isLowEngagement) {
      this.trackEmailEngagement(content.contentId, isLowEngagement, Object.keys(engagementData));
    }

    return isLowEngagement;
  }

  trackEmailEngagement(contentId, isLowEngagement, lowEngagementTypes) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'flagged',
      object: 'email',
      place: 'email_index',
      flagged_property: 'low_engagement_stats',
      content_id: contentId,
      low_engagement: isLowEngagement,
      low_engagement_types: lowEngagementTypes,
    });
  }
}
