/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import bracketedRelativeTimeAgo from 'embercom/lib/bracketed-relative-time-ago';
import ajax from 'embercom/lib/ajax';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import { chunk, isNumber } from 'underscore';
import { tracked } from '@glimmer/tracking';

export const TEAMMATE_ACTIVITY_STATUS_CODE_MAP = new Map([
  [0, 'Active'],
  [1, 'Away'],
  [2, 'Away & reassigning'],
]);

const SECONDS_BEFORE_IDLE = 15 * 60;

const FETCH_ADMIN_BATCH_SIZE = 20;

export default class TeammateActivityService extends Service {
  @service store;
  @service appService;

  @tracked isFetchingData = false;

  async getTeammateActivityForTeams(teamIds, settings, { cachedOverview, cachedAggregations }) {
    try {
      this.isFetchingData = true;
      let rawTeammateActivities = await this.fetch(teamIds, settings);
      let teammateActivities = [];
      for (let activityBatch of chunk(rawTeammateActivities.overview, FETCH_ADMIN_BATCH_SIZE)) {
        let newTeammateActivities = await Promise.all(
          activityBatch.map((activity) =>
            this.mapRawTeammateActivity(activity, settings.excludedQueryTypes, cachedOverview),
          ),
        );
        teammateActivities.push(...newTeammateActivities);
      }
      return {
        overview: teammateActivities,
        aggregations: this.readAggregations(
          rawTeammateActivities,
          settings.excludedQueryTypes,
          cachedAggregations,
        ),
      };
    } finally {
      this.isFetchingData = false;
    }
  }

  mapRawTeammateActivity = async (activity, excluded, cache) => {
    let admin = await this.getAdmin(activity);

    let readFromCache = (key) => cache.find(({ id }) => id === admin.id)?.[key];

    return {
      id: admin.id,
      admin,
      name: admin.name,
      openConversations: activity.open || 0,
      closedConversations: activity.closed || 0,
      snoozedConversations: activity.snoozed || 0,
      idle: activity.idle || 0,
      status: TEAMMATE_ACTIVITY_STATUS_CODE_MAP.get(activity.status_code),
      statusCode: activity.status_code,
      statusReason: admin.away_status_reason,
      csat: excluded.includes('csat') ? readFromCache('csat') : this.getCsat(activity),
      ...this.getWorkload(activity),
      participatedIn: activity.participated_in || 0,
      ...this.getTimeOnStatus(activity),
      ...this.getFirstActiveTime(activity),
      ...this.getTotalActiveTime(activity),
      ...this.getLastSeen(activity),
      ...this.getAvgFirstResponsetime(activity),
      ...this.getAvgTimeToSubsequentResponse(activity),
      ...(this.appService.app.canSeeConversationsPerHourMetric
        ? this.getTotalAwayTime(activity)
        : {}),
      ...(this.appService.app.canSeeConversationsPerHourMetric
        ? this.getConversationsPerHour(activity)
        : {}),
    };
  };

  readAggregations(response, excluded, cache) {
    return {
      active: response.aggregations.active,
      away: response.aggregations.away,
      reassigning: response.aggregations.reassigning,
      open: response.aggregations.open,
      closed: response.aggregations.closed,
      snoozed: response.aggregations.snoozed,
      idlePercentage: this.getIdlePercentage(response),
      participatedIn: response.aggregations.participated_in,
      avgWaitTime: this.formatTime(response.aggregations.median_first_response_time),
      avgTimeToSubsequentResponse: this.formatTime(
        response.aggregations.median_time_to_subsequent_response,
      ),
      csat: excluded.includes('csat') ? cache['csat'] : this.getCsat(response.aggregations),
    };
  }
  async getAdmin({ id }) {
    return (await this.store.peekRecord('admin', id)) || this.store.findRecord('admin', id);
  }

  getTimeOnStatus({ time_on_status_in_seconds }) {
    return {
      timeOnStatusInSeconds: time_on_status_in_seconds,
      timeOnStatus: this.formatTime(time_on_status_in_seconds),
    };
  }

  getFirstActiveTime({ first_active_time }) {
    let firstActiveTimeInSeconds = this.getTimeInSeconds(first_active_time);
    return {
      firstActiveTimeInSeconds,
      firstActiveTime: first_active_time
        ? moment(first_active_time).calendar({
            sameDay: '[Today,] LT',
            lastDay: '[Yesterday,] LT',
            sameElse: 'MMMM Do, h:mma',
          })
        : null,
    };
  }

  getTotalActiveTime({ total_active_time_in_seconds }) {
    return {
      totalActiveTimeInSeconds: total_active_time_in_seconds,
      totalActiveTime: this.formatTime(total_active_time_in_seconds),
    };
  }

  getTotalAwayTime({ total_away_time_in_seconds }) {
    return {
      totalAwayTimeInSeconds: total_away_time_in_seconds,
      totalAwayTime: this.formatTime(total_away_time_in_seconds),
    };
  }

  getConversationsPerHour({ conversations_per_hour }) {
    return {
      participatedInPerHour: conversations_per_hour.participated_in,
      closedPerHour: conversations_per_hour.closed,
    };
  }

  getLastSeen({ last_seen_at }) {
    let lastSeenInSeconds = this.getTimeInSeconds(last_seen_at);
    return {
      lastSeenInSeconds,
      lastSeen: last_seen_at ? bracketedRelativeTimeAgo(last_seen_at) : null,
      currentActivity: this.getCurrentActivityStatus(lastSeenInSeconds),
    };
  }

  getCurrentActivityStatus(lastSeenInSeconds) {
    if (!lastSeenInSeconds) {
      return null;
    }
    return lastSeenInSeconds < SECONDS_BEFORE_IDLE ? 'Working' : 'idle';
  }

  getAvgFirstResponsetime({ avg_first_response_time_in_seconds }) {
    return {
      avgFirstResponseTimeInSeconds: avg_first_response_time_in_seconds,
      avgFirstResponseTime: this.formatTime(avg_first_response_time_in_seconds),
    };
  }

  getAvgTimeToSubsequentResponse({ avg_time_to_subsequent_response }) {
    return {
      avgTimeToSubsequentResponseInSeconds: avg_time_to_subsequent_response,
      avgTimeToSubsequentResponse: this.formatTime(avg_time_to_subsequent_response),
    };
  }

  getWorkload({ workload }) {
    return isNumber(workload?.workload)
      ? {
          workload: workload.workload,
          conversationLimit: workload.conversation_limit,
        }
      : null;
  }

  getCsat({ csat }) {
    return isNumber(csat) ? Math.round(csat) : '';
  }

  getIdlePercentage(response) {
    let totalOpen = response.aggregations.open;
    return totalOpen === 0 ? null : Math.round((response.aggregations.idle * 100) / totalOpen);
  }

  getTimeInSeconds(time) {
    return time ? moment.duration(moment.utc().diff(moment(time))).asSeconds() : null;
  }

  getTimeFormatter() {
    if (!this.timeFormatter) {
      this.timeFormatter = new Formatters[units.seconds]();
    }
    return this.timeFormatter;
  }

  formatTime(value) {
    return value ? this.getTimeFormatter().formatCounter(value) : null;
  }

  fetch(
    teamIds,
    {
      timeframeInHours,
      aggregationType,
      idleThreshold,
      excludeBotTime,
      excludedQueryTypes,
      selectedStatuses,
    },
  ) {
    return ajax({
      url: '/ember/monitoring/teammate_activities',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
        team_ids: teamIds,
        timeframe: timeframeInHours * 60,
        aggregation_type: aggregationType,
        idle_threshold: idleThreshold,
        exclude_bot_time: excludeBotTime,
        excluded_query_types: excludedQueryTypes,
        selected_teammate_statuses: selectedStatuses,
      },
    });
  }

  setForceRefresh(refreshTask) {
    this.forceRefresh = refreshTask;
  }

  doForceRefresh(options) {
    if (this.forceRefresh) {
      this.forceRefresh.perform(options);
    }
  }
}
