/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const PredicateIsValid = BaseValidator.extend({
  intl: service(),
  appService: service(),

  validate(predicateGroup, options, model) {
    // No need to validate else branch
    if (model.type !== 'conditional_branch') {
      return true;
    }

    if (!predicateGroup.predicates.length) {
      return this.intl.t(options.noPredicatesMessage);
    }

    let predicates = predicateGroup.predicates;

    if (
      !this.appService.app?.canUseWorkflowsTicketsV3 &&
      !this.appService.app?.canUseJiraInboundWorkflows
    ) {
      return this.validateWithoutEditorConfig(predicates, options);
    }

    let editorConfig = model.editorConfig;

    if (editorConfig) {
      return this.validateWithEditorConfig(predicates, editorConfig, options);
    } else {
      return this.validateWithoutEditorConfig(predicates, options);
    }
  },

  validateWithEditorConfig(predicates, editorConfig, options) {
    let supportedPredicateIdentifiers = editorConfig.runtimeMatchingTargetingAttributeIdentifiers;

    for (let i = 0; i < predicates.length; i++) {
      let predicate = predicates.objectAt(i);

      if (!this.predicateIsValid(predicate, supportedPredicateIdentifiers)) {
        return this.intl.t(options.finishPredicatesMessage);
      }
    }

    return true;
  },

  validateWithoutEditorConfig(predicates, options) {
    for (let i = 0; i < predicates.length; i++) {
      let predicate = predicates.objectAt(i);

      if (!predicate.isValid) {
        return this.intl.t(options.finishPredicatesMessage);
      }
    }

    return true;
  },

  predicateIsValid(predicate, supportedPredicateIdentifiers) {
    if (predicate.predicates) {
      return predicate.predicates.every((predicate) => {
        return this.predicateIsValid(predicate, supportedPredicateIdentifiers);
      });
    } else {
      return predicate.isValid && supportedPredicateIdentifiers.includes(predicate.attribute);
    }
  },
});

PredicateIsValid.reopenClass({
  getDependentsFor() {
    return ['model.editorConfig'];
  },
});

export default PredicateIsValid;
