/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import ENV from 'embercom/config/environment';
import { isBlank } from '@ember/utils';
const httpsRegex = /^https:\/\//;

/**
 * Validates that a value begins with https:
 * Allows other values when the environment is developement.
 */
const Https = BaseValidator.extend({
  validate(value, options, model) {
    if (isBlank(value)) {
      return options && options['allowBlank'] ? true : "This field can't be blank";
    } else if (ENV.environment === 'development' || httpsRegex.test(value)) {
      return true;
    } else {
      return 'Must use HTTPS';
    }
  },
});

export default Https;
