/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import moment from 'moment-timezone';

const WaitTimeIsInTheFuture = BaseValidator.extend({
  validate(_value, _options, model) {
    if (model.series?.isDraft && model.isAbsolute && model.timeDelayAsMoment.isBefore(moment())) {
      return 'You must select a time in the future';
    }
    return true;
  },
});

WaitTimeIsInTheFuture.reopenClass({
  getDependentsFor() {
    return ['model.type', 'model.timeDelay', 'model.series.state'];
  },
});

export default WaitTimeIsInTheFuture;
