/* RESPONSIBLE TEAM: team-messenger */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  USER_TARGET_TYPE,
  LEADS_AND_VISITORS_TARGET_TYPE,
  MOBILE_TARGET_TYPE,
} from 'embercom/lib/messenger-settings/target-types';

type TargetUserType =
  | typeof USER_TARGET_TYPE
  | typeof LEADS_AND_VISITORS_TARGET_TYPE
  | typeof MOBILE_TARGET_TYPE;

export default class MessengerSettingsTargetUserProvider extends Service {
  @service declare attributeService: $TSFixMe;

  @tracked settings: $TSFixMe = null; // instance of app/models/messenger-settings/all.js
  @tracked targetUserType: TargetUserType = LEADS_AND_VISITORS_TARGET_TYPE;

  @computed('targetUserType')
  get isUserType() {
    return this.targetUserType === USER_TARGET_TYPE;
  }

  @computed('targetUserType')
  get isMobile() {
    return this.targetUserType === MOBILE_TARGET_TYPE;
  }

  @computed('targetUserType')
  get isVisitor() {
    return this.targetUserType === LEADS_AND_VISITORS_TARGET_TYPE;
  }

  get isMobileOrWebUserType() {
    return this.isUserType || this.isMobile;
  }

  setVisitorTarget() {
    this.targetUserType = LEADS_AND_VISITORS_TARGET_TYPE;
  }

  setUserTarget() {
    this.targetUserType = USER_TARGET_TYPE;
  }

  setMobileTarget() {
    this.targetUserType = MOBILE_TARGET_TYPE;
  }

  @computed(
    'targetUserType',
    'isUserType',
    'isMobile',
    'attributeService.{attributeGroupListForUsers,attributeGroupListForAnonymous}',
  )
  get targetingAttributes() {
    return this.isUserType || this.isMobile
      ? this.attributeService.attributeGroupListForUsers
      : this.attributeService.attributeGroupListForAnonymous;
  }
}
