/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';
import IdentityVerificationRiskStates from 'embercom/lib/identity-verification-risk-states';

export default Service.extend({
  app: undefined,
  riskState: 0,
  securityContactEmailFilled: true,

  adminHasWorkSpacePermissions: readOnly('app.currentAdmin.canAccessWorkSpaceSettings'),

  // Any admin without full permissions should have their risk level decreased by one.
  // E.g., medium risk == low risk for a partial permission admin.
  adminRiskState: computed('adminHasWorkSpacePermissions', 'riskState', function () {
    return this.adminHasWorkSpacePermissions ? this.riskState : Math.max(this.riskState - 1, 0);
  }),

  shouldShowLowNotification: computed('adminRiskState', function () {
    return this.adminRiskState >= IdentityVerificationRiskStates.LOW_RISK;
  }),

  shouldShowMediumNotification: computed('adminRiskState', function () {
    return this.adminRiskState >= IdentityVerificationRiskStates.MEDIUM_RISK;
  }),

  shouldShowHighNotification: computed('adminRiskState', function () {
    return this.adminRiskState >= IdentityVerificationRiskStates.HIGH_RISK;
  }),

  shouldShowLackOfSecurityContactEmailNotification: computed(
    'securityContactEmailFilled',
    function () {
      if (this.app === undefined) {
        return false;
      }
      return !this.securityContactEmailFilled;
    },
  ),

  start(app) {
    this.set('app', app);

    return ajax({
      url: '/ember/identity_verification_risk_state',
      type: 'GET',
      data: {
        app_id: app.get('id'),
      },
    }).then((response) => {
      this.set('riskState', response.identity_verification_risk_state);
      this.set('securityContactEmailFilled', response.security_contact_email_filled);
    });
  },
});
