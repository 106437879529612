/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const DeliveryChannelAdminMustVerifyEmail = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isEmail') && model.get('app.currentAdmin.current_email_pending_verification')) {
      return 'To start sending email, verify your email address';
    }
    return true;
  },
});

DeliveryChannelAdminMustVerifyEmail.reopenClass({
  getDependentsFor() {
    return ['model.isEmail', 'model.app.currentAdmin.current_email_pending_verification'];
  },
});

export default DeliveryChannelAdminMustVerifyEmail;
