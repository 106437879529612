/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import type Session from './session';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import type InboxApi from './inbox-api';
import { request } from 'embercom/lib/inbox/requests';
import type CompanySummary from 'embercom/objects/inbox/company-summary';

const DEFAULT_SUGGESTIONS_TO_FETCH = 6;

export default class InboxSearchSuggestionsService extends Service {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;

  async getUserSuggestions(
    query: string,
    count: number = DEFAULT_SUGGESTIONS_TO_FETCH,
    selectedUsers: UserSummary[] = [],
    searchComparison = 'contains',
    sortBy = 'name',
  ): Promise<UserSummary[]> {
    let predicates = [
      {
        type: 'or',
        predicates: [
          { attribute: 'name', comparison: searchComparison, type: 'string', value: query },
          { attribute: 'email', comparison: searchComparison, type: 'string', value: query },
        ],
      },
    ];
    let options = {
      page: 1,
      per_page: count + selectedUsers.length,
      sort_by: sortBy,
      sort_direction: 'asc',
    };

    let searchResults = await this.inboxApi.searchUserSuggestions(
      predicates,
      options,
      searchComparison,
    );

    return searchResults.slice(0, count);
  }

  async getUserSuggestionsForDialler(
    query: string,
    count: number = DEFAULT_SUGGESTIONS_TO_FETCH,
    searchComparison = 'contains',
    sortBy = 'name',
  ): Promise<UserSummary[]> {
    let predicates = [
      {
        type: 'and',
        predicates: [
          { attribute: 'phone', comparison: 'known', value: null },
          {
            type: 'or',
            predicates: [
              { attribute: 'name', comparison: searchComparison, type: 'string', value: query },
              { attribute: 'phone', comparison: searchComparison, type: 'string', value: query },
            ],
          },
        ],
      },
    ];
    let options = {
      page: 1,
      per_page: count,
      sort_by: sortBy,
      sort_direction: 'asc',
    };

    let searchResults = await this.inboxApi.searchUserSuggestions(
      predicates,
      options,
      searchComparison,
    );

    return searchResults.slice(0, count);
  }

  async loadUserFromId(userId: string): Promise<UserSummary | null> {
    let response;

    try {
      response = await request(`/ember/users/${userId}?app_id=${this.session.workspace.id}`);
    } catch (_e) {
      return null;
    }

    let json = await response.json();
    let users = json.users?.map((userJson: UserSummaryWireFormat) =>
      UserSummary.deserialize(userJson),
    );

    return users?.[0];
  }

  async getCompanySuggestions(
    query: string,
    count: number = DEFAULT_SUGGESTIONS_TO_FETCH,
    selectedCompanies: CompanySummary[] = [],
    searchComparison = 'contains',
    sortBy = 'name',
  ): Promise<CompanySummary[]> {
    let predicates = [
      { attribute: 'company.name', comparison: searchComparison, type: 'string', value: query },
    ];
    let options = {
      page: 1,
      per_page: count + selectedCompanies.length,
      sort_by: sortBy,
      sort_direction: 'asc',
    };

    let searchResults = await this.inboxApi.searchCompanySuggestions(predicates, options);

    return searchResults.slice(0, count);
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxSearchSuggestionsService: InboxSearchSuggestionsService;
    'inbox-search-suggestions-service': InboxSearchSuggestionsService;
  }
}
