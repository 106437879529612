/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import Metrics from 'embercom/models/metrics';
import ENV from 'embercom/config/environment';

export default Service.extend({
  hearbeatFrequencyInMinutes: 30,
  minuteCount: 0,

  store: service(),

  init() {
    this._super(...arguments);
    if (window.performance && ENV.environment === 'production') {
      this.scheduleHeartbeat();
    }
  },

  scheduleHeartbeat() {
    let hearbeatFrequencyInMinutes = this.hearbeatFrequencyInMinutes;
    let hearbeatFrequencyInMilliseconds = hearbeatFrequencyInMinutes * 60 * 1000;
    later(this, this.heartbeat, hearbeatFrequencyInMilliseconds);
  },

  heartbeat() {
    let minuteCount = this.incrementProperty('minuteCount', this.hearbeatFrequencyInMinutes);
    let payload = {};

    try {
      payload[`emberUsedJSHeapSize${minuteCount}`] = window.performance.memory.usedJSHeapSize;
      payload[`emberTotalJSHeapSize${minuteCount}`] = window.performance.memory.totalJSHeapSize;
    } catch (e) {} // eslint-disable-line no-empty

    try {
      payload[`emberDomNodeCount${minuteCount}`] = this._getAllElements().length;
      payload[`emberDomEventListenerCount${minuteCount}`] = this._getDomEventListenerCount();
    } catch (e) {} // eslint-disable-line no-empty

    try {
      let userCount = this._getModelCacheCount('user');
      payload[`emberCachedUserCount${minuteCount}`] = userCount;

      let conversationCount = this._getModelCacheCount('conversation');
      payload[`emberCachedConversationCount${minuteCount}`] = conversationCount;

      let conversationPartCount = this._getModelCacheCount('conversation-part');
      payload[`emberCachedConversationPartCount${minuteCount}`] = conversationPartCount;
    } catch (e) {} // eslint-disable-line no-empty

    Metrics.capture(payload);
    this.scheduleHeartbeat();
  },

  _getModelCacheCount(modelName) {
    return this.store.peekAll(modelName).length;
  },

  _getAllElements() {
    return document.querySelectorAll('*');
  },

  _getDomEventListenerCount() {
    let elements = this._getAllElements();
    let count = 0;

    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];
      let events = $._data(element, 'events');

      if (events) {
        let eventKeys = Object.keys(events);

        for (let j = 0; j < eventKeys.length; j++) {
          count += events[eventKeys[j]].length;
        }
      }
    }

    return count;
  },
});
