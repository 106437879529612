/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    billAheadPreSubtotalLineItems: { embedded: 'always' },
    billAheadCouponLineItems: { embedded: 'always' },
    billAheadFixedPriceDiscountLineItems: { embedded: 'always' },
    billAheadMultiProductDiscountLineItems: { embedded: 'always' },
    billAheadPermanentDiscountLineItems: { embedded: 'always' },
    billAheadVatLineItems: { embedded: 'always' },
    outOfPeriodCouponLineItems: { embedded: 'always' },
    outOfPeriodPermanentDiscountLineItems: { embedded: 'always' },
    outOfPeriodVatLineItems: { embedded: 'always' },
    outOfPeriodCreditLineItems: { embedded: 'always' },
    retrospectiveCouponLineItems: { embedded: 'always' },
    retrospectiveMultiProductDiscountLineItems: { embedded: 'always' },
    retrospectiveFixedPriceDiscountLineItems: { embedded: 'always' },
    retrospectivePermanentDiscountLineItems: { embedded: 'always' },
    retrospectivePreSubtotalLineItems: { embedded: 'always' },
    retrospectivePrepaidCreditLineItems: { embedded: 'always' },
    retrospectiveVatLineItems: { embedded: 'always' },
  },
});
