/* RESPONSIBLE TEAM: team-phone */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const PhoneReplyButtonsWarning = BaseValidator.extend({
  intl: service(),
  contentEditorService: service(),
  appService: service(),

  validate(_value, options, model) {
    let rulesetLink = this.contentEditorService.activeRulesetLink;

    // Only validate if the ruleset link is phone workflow
    if (!rulesetLink || !rulesetLink.object.isPhoneCallTrigger) {
      return true;
    }

    if (model.hasDirtyAttributes || model.replyButtons.any((b) => b.hasDirtyAttributes)) {
      return this.intl.t(options.messageKey);
    }

    return true;
  },
});

PhoneReplyButtonsWarning.reopenClass({
  getDependentsFor() {
    return [
      'model.hasDirtyAttributes',
      'model.replyButtons.@each.label',
      'model.outwardConnectionPoints.[]',
      'model.outwardConnectionPoints.@each.isConnected',
    ];
  },
});

export default PhoneReplyButtonsWarning;
