/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { taskFor } from 'ember-concurrency-ts';
import { postRequest } from 'embercom/lib/inbox/requests';
import { type TaskGenerator } from 'ember-concurrency';

export default class RealtimeUsersService extends Service {
  @service declare session: Session;

  // Tracked Users represents a mapping of User ID => Number of places tracking this user.
  // When the number of references to this user reaches 0, we will remove the ID from being tracked.
  @tracked trackedUsers: Map<string, number> = new Map();

  // For now we will simply track the list of users who are returned as active.
  // Additional data is returned from the server which details things like last page
  // and platform they were seen on which we can choose to store later.
  @tracked activeUsers: Set<string> = new Set();

  track(userId: string) {
    let referenceCount = this.trackedUsers.get(userId) ?? 0;
    this.trackedUsers.set(userId, referenceCount + 1);
  }

  untrack(userId: string) {
    if (this.trackedUsers.has(userId)) {
      let referenceCount = this.trackedUsers.get(userId) as number;
      if (referenceCount > 1) {
        this.trackedUsers.set(userId, referenceCount - 1);
      } else {
        this.trackedUsers.delete(userId);
      }
    }
  }

  beginTracking() {
    if (ENV.environment === 'test') {
      return;
    }

    taskFor(this.refresh).perform();
  }

  get visibilityState() {
    return document.visibilityState;
  }

  @task({ keepLatest: true }) *refresh(): TaskGenerator<void> {
    yield timeout(ENV.APP._5000MS);
    if (this.trackedUsers.size > 0 && this.visibilityState === 'visible') {
      let response = yield postRequest(
        ENV.APP.userPresenceProxyPollingEndpoint,
        {
          users: Array.from(this.trackedUsers.keys()),
          app_id: this.session.workspace.id,
        },
        { priority: 'low' },
      );
      let json = (yield response.json()) as { users: Array<{ userId: string }> };
      this.activeUsers = new Set(json.users.map((u) => u.userId));
    } else {
      this.activeUsers = new Set();
    }
    taskFor(this.refresh).perform();
  }
}

declare module '@ember/service' {
  interface Registry {
    realtimeUsersService: RealtimeUsersService;
    'realtime-users': RealtimeUsersService;
  }
}
