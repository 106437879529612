/* RESPONSIBLE TEAM: team-frontend-tech */
import Service, { inject as service } from '@ember/service';
import { later, cancel } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type Session from './session';
import type ApplicationInstance from '@ember/application/instance';
import { type Span } from '@opentelemetry/api';
import type Tracing from 'embercom/services/tracing';

export enum NotificationSound {
  default = 'default',
  blip = 'blip',
  bop = 'bop',
  clack = 'clack',
  hiss = 'hiss',
  shake = 'shake',
  tink = 'tink',
}

export enum BlockedNotificationReason {
  Default,
  ChromeNoInteraction,
  AudioCannotLoad,
}

const timeout = async (promise: Promise<unknown>, time: number, exception: unknown) => {
  let timer: NodeJS.Timeout | undefined;
  try {
    return await Promise.race([
      promise,
      new Promise((_, reject) => (timer = setTimeout(reject, time, exception))),
    ]);
  } finally {
    clearTimeout(timer);
  }
};

const PLAYBACK_TIMEOUT_MS = 5000;

class PlaybackTimeoutError extends Error {
  constructor() {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    super('audio playback timed out');
    this.name = this.constructor.name;
  }
}

class AudioCannotLoadError extends Error {
  constructor() {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    super('audio unable to load in background tab');
    this.name = this.constructor.name;
  }
}

export default class BrowserAlerts extends Service {
  private toggleTimer: any;
  private conversationId: string | null = null;
  private originalTitle?: string;
  private toggle: string | undefined = undefined;
  private defaultTitle = 'Intercom';
  private readonly audioObjects: Record<NotificationSound, HTMLAudioElement> = {
    [NotificationSound.default]: new window.Audio(assetUrl('/assets/audio/notification.mp3')),
    [NotificationSound.blip]: new window.Audio(assetUrl('/assets/audio/blip.mp3')),
    [NotificationSound.bop]: new window.Audio(assetUrl('/assets/audio/bop.mp3')),
    [NotificationSound.clack]: new window.Audio(assetUrl('/assets/audio/clack.mp3')),
    [NotificationSound.hiss]: new window.Audio(assetUrl('/assets/audio/hiss.mp3')),
    [NotificationSound.shake]: new window.Audio(assetUrl('/assets/audio/shake.mp3')),
    [NotificationSound.tink]: new window.Audio(assetUrl('/assets/audio/tink.mp3')),
  } as const;
  // Used to show notification bar when notifications are blocked
  @tracked audioNotificationsBlockedReason?: BlockedNotificationReason;
  @tracked hideAudioNotificationsBlockedBanner = true;
  private successfullyPlayedAudioThisSession = false;
  private isFirstAudioPlayAttemptThisSession = true;

  @service declare session: Session;
  @service declare tracing: Tracing;

  constructor(owner: ApplicationInstance) {
    super(owner);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.leave = this.leave.bind(this);

    window.addEventListener('beforeunload', this.leave, false);
    document.addEventListener('visibilitychange', this.onVisibilityChange, false);
  }

  private leave() {
    window.removeEventListener('beforeunload', this.leave);
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }

  private onVisibilityChange() {
    if (document.hidden) {
      return;
    }
    this.cancelPageTitleToggle(null);
  }

  private setPageTitle(title: string | undefined) {
    let titleElement = document.querySelector('title');
    if (titleElement) {
      titleElement.textContent = title || this.defaultTitle;
    }
  }

  private getPageTitle(): string {
    let title = document.querySelector('title');
    if (title) {
      return title.textContent || this.defaultTitle;
    }
    return this.defaultTitle;
  }

  private activateTitleToggling() {
    let current = this.getPageTitle();
    this.setPageTitle(this.toggle);
    this.toggle = current;
    this.toggleTimer = later(this, this.activateTitleToggling, ENV.APP._3000MS);
  }

  cancelPageTitleToggle(conversationId: string | null) {
    if (!this.toggleTimer) {
      return;
    }
    if (conversationId && conversationId !== this.conversationId) {
      return;
    }
    cancel(this.toggleTimer);
    this.toggleTimer = null;
    this.setPageTitle(this.originalTitle);
    this.originalTitle = undefined;
    this.conversationId = null;
  }

  togglePageTitle(content: string, conversationId: string) {
    if (!document.hidden) {
      return;
    }
    this.conversationId = conversationId;
    this.originalTitle = this.originalTitle || this.getPageTitle();
    this.toggle = this.originalTitle;
    this.setPageTitle(content);
    if (!this.toggleTimer) {
      this.toggleTimer = later(this, this.activateTitleToggling, ENV.APP._3000MS);
    }
  }

  get userActivation(): { isActive: boolean; hasBeenActive: boolean } | undefined {
    return 'userActivation' in navigator
      ? (navigator.userActivation as { isActive: boolean; hasBeenActive: boolean })
      : undefined;
  }

  getAudioFile(sound: NotificationSound): HTMLAudioElement {
    return this.audioObjects[sound];
  }

  get audioNotificationsBlocked(): boolean {
    return this.audioNotificationsBlockedReason !== undefined;
  }

  get isBlockedByDefault(): boolean {
    return this.audioNotificationsBlockedReason === BlockedNotificationReason.Default;
  }

  get isBlockedByNoInteraction(): boolean {
    return this.audioNotificationsBlockedReason === BlockedNotificationReason.ChromeNoInteraction;
  }

  get isBlockedByAudioCannotLoad(): boolean {
    return this.audioNotificationsBlockedReason === BlockedNotificationReason.AudioCannotLoad;
  }

  async playNotificationSound(sound?: NotificationSound) {
    try {
      await this.tracing.inSpan(
        {
          name: 'playNotificationSound',
          resource: 'inbox2-audio-notification',
          attributes: {
            'audio_metadata.visibilityState': document.visibilityState,
            'audio_metadata.isActive': this.userActivation?.isActive,
            'audio_metadata.hasBeenActive': this.userActivation?.hasBeenActive,
            'audio_metadata.sound': sound || NotificationSound.default,
            'audio_metadata.hasChromeNoInteractionBanner': true,
            'audio_metadata.successfullyPlayedThisSession': this.successfullyPlayedAudioThisSession,
            'audio_metadata.isFirstPlayThisSession': this.isFirstAudioPlayAttemptThisSession,
            'audio_metadata.wasDiscarded':
              'wasDiscarded' in document ? (document.wasDiscarded as boolean) : undefined,
          },
          enabled: this.session.workspace.isFeatureEnabled('inbox2-audio-notifications-tracing'),
        },
        async (span?: Span) => {
          let selectedSound = this.getAudioFile(sound ?? NotificationSound.default);

          this.setBeforePlaySpanTags(span, selectedSound);

          if (
            document.visibilityState === 'hidden' &&
            selectedSound.readyState === 0 &&
            this.userActivation?.hasBeenActive === false
          ) {
            throw new AudioCannotLoadError();
          }

          try {
            await timeout(selectedSound.play(), PLAYBACK_TIMEOUT_MS, new PlaybackTimeoutError());
          } finally {
            this.setAfterPlaySpanTags(span, selectedSound);
          }

          this.audioNotificationsBlockedReason = undefined;
        },
      );
    } catch (error) {
      if (
        error.name === 'NotAllowedError' ||
        error instanceof PlaybackTimeoutError ||
        error instanceof AudioCannotLoadError
      ) {
        this.audioNotificationsBlockedReason = this.getBlockedNotificationReason(error);
        this.hideAudioNotificationsBlockedBanner = false;
        return;
      }
      throw error;
    }
  }

  private getBlockedNotificationReason(error: Error): BlockedNotificationReason {
    if (isNoInteractionChromeError(error)) {
      return BlockedNotificationReason.ChromeNoInteraction;
    } else if (error instanceof AudioCannotLoadError) {
      return BlockedNotificationReason.AudioCannotLoad;
    } else {
      return BlockedNotificationReason.Default;
    }
  }

  private setAfterPlaySpanTags(span: Span | undefined, selectedSound: HTMLAudioElement) {
    this.successfullyPlayedAudioThisSession = true;
    if (span === undefined) {
      return;
    }
    if ('wasDiscarded' in document) {
      span?.setAttributes({
        'audio_metadata.wasDiscarded.after': document.wasDiscarded as boolean,
      });
    }
    span?.setAttributes({
      'audio_metadata.visibilityState.after': document.visibilityState,
      'audio_metadata.isActive.after': this.userActivation?.isActive,
      'audio_metadata.hasBeenActive.after': this.userActivation?.hasBeenActive,
      'audio_metadata.readyState.after': selectedSound.readyState,
    });
  }

  private setBeforePlaySpanTags(span: Span | undefined, selectedSound: HTMLAudioElement) {
    this.isFirstAudioPlayAttemptThisSession = false;
    span?.setAttributes({
      'audio_metadata.alreadyPlaying': this.isSoundPlaying(selectedSound),
      'audio_metadata.readyState': selectedSound.readyState,
    });
  }

  private isSoundPlaying(sound: HTMLAudioElement): boolean {
    return !!(sound.currentTime > 0 && !sound.paused && !sound.ended && sound.readyState > 2);
  }
}

function isNoInteractionChromeError(error: Error): boolean {
  return (
    error.name === 'NotAllowedError' &&
    error.message.includes("play() failed because the user didn't interact with the document first")
  );
}

declare module '@ember/service' {
  interface Registry {
    browserAlerts: BrowserAlerts;
    'browser-alerts': BrowserAlerts;
  }
}
