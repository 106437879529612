/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const CustomBotFirstPathConstraints = BaseValidator.extend({
  validate(pathSteps, options, model) {
    if (model.get('isButton') && !model.get('paths.firstObject.steps.firstObject.hasBlocks')) {
      return 'You must start your path with a bot message.';
    }

    let replyButtons = model.get('paths.firstObject.steps.lastObject.branchingControls');
    let isCustomObjectSelector = model.get(
      'paths.firstObject.steps.lastObject.isCustomObjectSelector',
    );
    if (
      (model.get('isOutbound') || model.get('isInbound')) &&
      (replyButtons === undefined || replyButtons.length === 0) &&
      !isCustomObjectSelector
    ) {
      return 'You must include at least one reply button that goes to another path.';
    }
    return true;
  },
});

CustomBotFirstPathConstraints.reopenClass({
  getDependentsFor() {
    return [
      'model.paths.firstObject.steps.firstObject.hasBlocks',
      'model.paths.firstObject.steps.lastObject.branchingControls',
      'model.isButton',
      'model.isOutbound',
      'model.isInbound',
      'model.isTriggerable',
    ];
  },
});

export default CustomBotFirstPathConstraints;
