/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable @intercom/intercom/no-legacy-modal */
import { isArray } from '@ember/array';
import { readOnly } from '@ember/object/computed';
import { later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import defaultTo from '@intercom/pulse/lib/default-to';
import ENV from 'embercom/config/environment';
import { post } from 'embercom/lib/ajax';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import {
  CONVERSATION_PRIORITIES,
  CONVERSATION_STATES,
  SNOOZED_UNTIL_MAP,
} from 'embercom/lib/inbox/constants';
import { captureException } from 'embercom/lib/sentry';
import generateUUID from 'embercom/lib/uuid-generator';
import Admin from 'embercom/models/admin';
import storage from 'embercom/vendor/intercom/storage';
import moment from 'moment-timezone';
import { Promise as EmberPromise } from 'rsvp';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import ErrorConversationIsRestricted from 'embercom/components/notifications/error-conversation-is-restricted';
import ErrorInConversation from 'embercom/components/notifications/error-in-conversation';

let validTypes = ['assignment', 'close', 'comment', 'note', 'open', 'snoozed', 'unsnoozed'];
let passivePartTypes = ['priority_changed', 'title_changed'];

export default Service.extend({
  SILENCE_AWAY_WARNING_KEY: 'silence_away_mode_warning',
  realTimeEventService: service(),
  inboxService: service(),
  frontendStatsService: service(),
  store: service(),
  onboardingHomeExternalStepService: service(),
  notificationsService: service(),
  intercomEventService: service(),
  appService: service(),
  modalService: service(),
  app: readOnly('appService.app'),
  admin: null,
  snoozedUntilDefaultValue: 1,
  snoozedUntilDefaultText: 'tomorrow',

  // TODO - Ideally these realtime event handlers would hang off the conversation model
  // once the conversation model has moved to ember-data and we can inject the realtime
  // service there we should move these
  init() {
    this._super(...arguments);
    this.realtimeConversations = defaultTo({});
    this.setupRealtime();
  },

  setupRealtime() {
    let realtimeEventService = this.realTimeEventService;
    realtimeEventService.get('inboxEvents').forEach((eventName) => {
      realtimeEventService.on(eventName, this, '_genericRealtimeHandler');
    });
  },

  subscribeRealtime(conversation) {
    if (!conversation) {
      return;
    }

    let conversationId = conversation.get('id');
    if (this.realtimeConversations[conversationId]) {
      return;
    }

    this.realTimeEventService.subscribeTopics([`conversation/${conversationId}`]);
    this.realtimeConversations[conversationId] = conversation;
  },

  unsubscribeRealtime(conversation) {
    if (!conversation) {
      return;
    }

    let conversationId = conversation.get('id');
    if (!this.realtimeConversations[conversationId]) {
      return;
    }

    this.realTimeEventService.unsubscribeTopics([`conversation/${conversationId}`]);
    delete this.realtimeConversations[conversationId];
  },

  async _genericRealtimeHandler(event) {
    let conversation = this.realtimeConversations[event.conversationId];

    if (!conversation || conversation.isDestroying || conversation.isDestroyed) {
      return;
    }

    if (conversation.shouldHandleRealtimeEvent(event)) {
      await conversation.syncedPartList.sync();
      conversation.updatedByRealtimeEvent(event);
    }
  },

  trackMessengerCardUsage({ conversation, blocks }) {
    blocks.forEach((block) => {
      if (block.type === 'messengerCard') {
        let messenger_app_id = block.messenger_app_id;

        post('/ember/messenger_app_usages', {
          app_id: this.get('app.id'),
          messenger_app_id,
        });
        this.intercomEventService.trackAnalyticsEvent({
          action: 'sent',
          object: 'messenger_app_card',
          messenger_app_id,
          conversation_id: conversation.get('id'),
        });
      }
    });
  },

  addPart(conversation, type, blocks, uploads, assignee) {
    validatePartArgs(...arguments);
    let admin = this.admin;
    let modifiedType = modifyType(conversation, type, admin);
    let part = createPart(admin, conversation, modifiedType, blocks);

    this._updateStateAndPriority(conversation, part, admin);
    updateAssignment(admin, conversation, part, assignee, this.app);
    this.addUploads(part, uploads);
    conversation.addParts([part]);
    this.trackMessengerCardUsage({ conversation, blocks });
    this.inboxService.partAddedToConversationLocally(conversation);
    this._notifyExternalStepServiceIfNecessary(part);

    return part;
  },

  addPartAndSave(conversation, type, blocks, uploads, assignee) {
    let part = this.addPart(conversation, type, blocks, uploads, assignee);
    this._warnAboutAwayMode(type);
    return this.saveConversation(conversation, part);
  },

  reopenConversation(conversation) {
    this.addPartAndSave(conversation, 'open', []);
  },

  closeConversation(conversation) {
    this.addPartAndSave(conversation, 'close', []);
  },

  redactPart(part) {
    let partDisplayName = part.get('isNote') ? 'note' : 'message';
    return part
      .redact()
      .then((redacted_part) => {
        let redactedBy = Admin.peekAndMaybeLoad(this.store, redacted_part.redacted_by_id);
        part.uploads.forEach((upload) => {
          this.store.unloadRecord(upload);
        });
        part.setProperties({
          body: redacted_part.body,
          blocks: redacted_part.blocks,
          uploads: redacted_part.uploads,
          redacted_at: redacted_part.redacted_at,
          redactedBy,
        });
        this.notificationsService.notifyConfirmation(
          `The ${partDisplayName} was deleted`,
          ENV.APP._5000MS,
        );
      })
      .catch(() => {
        this.notificationsService.notifyError(
          `Something went wrong and the ${partDisplayName} wasn՚t deleted. Try again.`,
          ENV.APP._5000MS,
        );
      });
  },

  addPartAndSnooze(conversation, type, blocks, uploads, assignee, options = {}) {
    let snoozedUntil = isBlank(options.snoozedUntil)
      ? this.snoozedUntilDefaultValue
      : options.snoozedUntil;

    let commentPart = this.addPart(conversation, type, blocks, uploads, assignee);
    let snoozedEventPart = this.addEventPart(conversation, 'snoozed', {
      snoozedUntil,
      customSnoozedUntil: options.customSnoozedUntil,
    });

    return this.saveConversationWithMultipleParts(conversation, [commentPart, snoozedEventPart]);
  },

  addEventPartAndSave(conversation, type, options) {
    let part = this.addEventPart(conversation, type, options);

    return this.saveConversation(conversation, part);
  },

  saveConversation(conversation, part) {
    return new EmberPromise((resolve, reject) => {
      let correlationId = performance.now();
      let error = false;
      this.frontendStatsService.startInteractionTime('interaction:inbox/create-part', {
        id: correlationId,
      });

      conversation
        .get('savePart')
        .perform(part)
        .then(() => {
          // Note: We don't want to delete drafts when a snoozed/open/closed/unsnoozed/assignment part is added to the conversation
          if (this.shouldDeleteDraft(part)) {
            this.deleteDraft(conversation).then(resolve);
          } else {
            resolve();
          }
        })
        .catch((e) => {
          error = true;
          this.handleSavePartError(conversation, e);
          reject();
        })
        .finally(() => {
          this.frontendStatsService.stopInteractionTime('interaction:inbox/create-part', {
            id: correlationId,
            metadata: { error },
          });
        });
    });
  },

  saveConversationWithMultipleParts(conversation, parts) {
    return new EmberPromise((resolve, reject) => {
      conversation
        .get('saveMultipleParts')
        .perform(parts)
        .then(() => {
          this.deleteDraft(conversation).then(resolve);
        })
        .catch((e) => {
          this.handleSavePartError(conversation, e);
          reject();
        });
    });
  },

  addEventPart(conversation, type, options = {}) {
    let admin = this.admin;
    let eventData = {
      template: this._generateEventDataTemplate(type, options),
      event_references: {
        admin: {
          id: this.get('admin.id'),
          label: this.get('admin.nameOrEmail'),
          type: 'admin',
        },
      },
    };

    let part = createPart(admin, conversation, type);
    this._handleSnoozeOptions(options, eventData, part);
    this._handlePriorityOptions(options, eventData, part);
    this._handleTitleOption(options, eventData, part, conversation);

    part.setProperties({
      event_data: eventData,
      blocks: null,
      is_event: true,
    });

    this._updateStateAndPriority(conversation, part, admin);
    conversation.addParts([part]);
    this.inboxService.partAddedToConversationLocally(conversation);

    if (type === 'snoozed') {
      this.intercomEventService.trackEvent('respond-snoozed-conversation');
    }
    return part;
  },

  _notifyExternalStepServiceIfNecessary(part) {
    if (
      (part.type !== 'assignment' &&
        this.onboardingHomeExternalStepService.isExternalStepRunning('reply_in_inbox')) ||
      (part.type === 'close' &&
        this.onboardingHomeExternalStepService.isExternalStepRunning('resolve_a_conversation'))
    ) {
      later(() => {
        this.onboardingHomeExternalStepService.completeExternalStep();
      }, ENV.APP._600MS);
    }
  },

  _generateEventDataTemplate(eventPartType, options) {
    switch (eventPartType) {
      case 'snoozed':
        return '[[admin]] snoozed this conversation [[snoozed_until]]';
      case 'title_changed':
        if (options.title === '') {
          return '[[admin]] removed the title';
        }
        return '[[admin]] set title to "[[title]]"';
      case 'priority_changed':
        if (options.priority === CONVERSATION_PRIORITIES.priority) {
          return '[[admin]] marked this conversation as priority';
        }
        return '[[admin]] removed priority from this conversation';
      default:
        return '[[admin]] reopened this conversation';
    }
  },

  _handleSnoozeOptions(options, eventData, part) {
    if (isPresent(options.snoozedUntil)) {
      let snoozeUntilOptionKey = this.getSnoozeUntilOptionsKey(options.snoozedUntil);
      eventData.event_references.snoozed_until = {
        id: snoozeUntilOptionKey,
        label: this.getSnoozedUntilLabel(options),
        type: 'snoozed_until',
        custom_snoozed_until_time: options.customSnoozedUntil,
      };
      part.set('snoozed_until', snoozeUntilOptionKey);
      part.set('custom_snoozed_until_time', options.customSnoozedUntil);
      part.set('author_timezone', moment.tz.guess());
    }
  },

  _handleTitleOption(options, eventData, part, conversation) {
    if (options.hasOwnProperty('title')) {
      eventData.event_references.title = {
        type: 'title',
        value: options.title,
      };
      part.set('title', options.title);
      conversation.set('title', options.title);
    }
  },

  _handlePriorityOptions(options, eventData, part) {
    if (isPresent(options.priority)) {
      eventData.event_references.priority = {
        type: 'priority',
        value: options.priority,
      };
      part.set('priority', options.priority);
    }
  },

  getSnoozedUntilLabel(options) {
    let snoozedUntilOption = this.getSnoozeUntilOptionsKey(options.snoozedUntil);
    if (snoozedUntilOption === 'custom_timer') {
      return `until ${moment(options.customSnoozedUntil).format(dateAndTimeFormats.timeOnDay)}`;
    } else {
      return SNOOZED_UNTIL_MAP[options.snoozedUntil].eventLabel;
    }
  },

  getSnoozeUntilOptionsKey(snoozeUntil) {
    return snoozeUntil === 'custom_timer' ? snoozeUntil : SNOOZED_UNTIL_MAP[snoozeUntil].key;
  },

  handleSavePartError(conversation, error) {
    if (conversation === this.get('inboxService.currentConversation')) {
      return;
    }

    captureException(error, {
      fingerprint: ['conversations-service', 'handleSavePartError'],
      tags: {
        conversation_id: conversation.id,
        app_id: this.app.id,
      },
    });

    if (error?.jqXHR?.status === 403) {
      this.notificationsService.notifyErrorWithModelAndComponent(
        {
          app: this.app,
          conversation,
        },
        ErrorConversationIsRestricted,
        ENV.APP.notificationNoTimeout,
      );
    } else {
      this.notificationsService.notifyErrorWithModelAndComponent(
        {
          app: this.app,
          conversation,
        },
        ErrorInConversation,
        ENV.APP.notificationNoTimeout,
      );
    }
  },

  removePart(conversation, part) {
    if (part.get('assignee_id')) {
      conversation.set('assignee_id', conversation.get('last_assignee'));
    }
    conversation.removePart(part);
    this._updateStateAndPriority(conversation, conversation.get('lastPart'), this.admin);
    this.inboxService.partRemovedFromConversation(conversation);
  },

  getDraft(conversation) {
    let draft = conversation.get('draft');
    if (!draft) {
      draft = conversation.createDraftForAdmin(this.admin);
    }
    return draft;
  },

  clearDraft(conversation) {
    let draft = this.getDraft(conversation);
    draft.setProperties({
      blocks: [{ type: 'paragraph', text: '', class: 'no-margin' }],
      actions: [],
    });
  },

  updateDraft(conversation, subclass, blocks, actions) {
    let draft = this.getDraft(conversation);
    if (draft.get('subclass') !== subclass || !this._blocksEqual(draft.get('blocks'), blocks)) {
      draft.setProperties({ blocks, subclass, actions });
      return conversation.get('updateDraft').perform(draft);
    }
  },

  deleteDraft(conversation) {
    return conversation.get('deleteDraft').perform();
  },

  _blocksEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  },

  _warnAboutAwayMode(partTypeBeingAdded) {
    if (
      partTypeBeingAdded === 'comment' &&
      this.get('admin.away_mode_enabled') &&
      !this.get('admin.reassign_conversations') &&
      !storage.get(this.SILENCE_AWAY_WARNING_KEY)
    ) {
      this._openWarningDialog();
    }
  },

  _openWarningDialog() {
    this.modalService.openModal('inbox/inbox/conversations/modals/away_mode_warning', {
      admin: this.admin,
      app: this.app,
    });
  },

  _updateStateAndPriority(conversation, part, admin) {
    this._updateState(conversation, part, admin);
    this._updatePriority(conversation, part);
  },

  _updateState(conversation, part, admin) {
    if (part.get('isCloser')) {
      this._setState(conversation, part, CONVERSATION_STATES.closed);
    }
    if (
      part.get('isOpener') &&
      (!conversation.get('isSnoozed') || isSnoozedAndNotByAssignee(conversation, admin))
    ) {
      this._setState(conversation, part, CONVERSATION_STATES.open);
    }
    if (part.get('isSnoozer')) {
      this._setState(conversation, part, CONVERSATION_STATES.snoozed);
    }
    if (part.get('isUnsnoozer')) {
      this._setState(conversation, part, CONVERSATION_STATES.open);
    }
  },

  _setState(conversation, part, state) {
    conversation.setProperties({
      state,
      lastActionPart: part,
    });
  },

  _updatePriority(conversation, part) {
    if (part.get('isPriorityChanger')) {
      conversation.setProperties({
        priority: part.get('priority'),
        lastActionPart: part,
      });
    }
  },

  shouldDeleteDraft(part) {
    return isPresent(part.get('blocks'));
  },

  addUploads(part, uploads) {
    if (!part.uploads) {
      part.set('uploads', []);
    }

    if (uploads) {
      uploads.forEach((upload) => {
        part.get('uploads').pushObject(this.store.createRecord('upload', upload));
      });
    }
  },
});

function validatePartArgs(conversation, type, blocks, uploads, assignee) {
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid conversation part type ${type}`);
  }
  if (!isArray(blocks)) {
    throw new Error('Blocks must be an array');
  }
  if (type === 'assignment' && !assignee) {
    throw new Error(`When creating an assignment part you must pass an assignee`);
  }
}

function modifyType(conversation, type, admin) {
  if (type === 'comment') {
    if (conversation.get('isClosed') || isSnoozedAndNotByAssignee(conversation, admin)) {
      return 'open';
    }
  }

  return type;
}

function createPart(admin, conversation, type, blocks) {
  let store = getEmberDataStore();
  return store.createRecord('conversation-part', {
    admin_id: admin.get('id'),
    admin,
    admin_only: type === 'note',
    conversation_id: conversation.get('id'),
    app_id: conversation.get('app_id'),
    blocks,
    type,
    sub_type: type,
    created_at: moment().toISOString(),
    lightweight_reply_type: null,
    isImmediateSend: true,
    client_assigned_uuid: generateUUID(),
    passive: passivePartTypes.includes(type),
  });
}

function updateAssignment(admin, conversation, part, assignee, app) {
  if (part.get('type') === 'assignment') {
    setAssignee(admin, app, conversation, part, assignee);
  } else if (isImplicitAssignment(admin, conversation, part, app)) {
    if (app.canAssignToTeamAndTeammate) {
      setAssignee(admin, app, conversation, part, {
        adminId: admin.id,
        teamId: conversation.team_assignee_id,
      });
    } else {
      setAssignee(admin, app, conversation, part, admin);
    }
  }
}

function isImplicitAssignment(admin, conversation, part, app) {
  let adminHasInboxAccess = !app.get('requiresInboxSeatAccess') || admin.get('hasInboxAccess');

  return (
    part.get('isComment') &&
    conversation.get('canBeImplicitlyAssigned') &&
    admin.get('assign_conversations_on_reply') &&
    adminHasInboxAccess
  );
}

function setAssignee(admin, app, conversation, part, assignment) {
  if (app.canAssignToTeamAndTeammate) {
    let event_data = assignmentEventData(admin, app, conversation, assignment);

    let assignee_id = String(assignment.adminId) === '0' ? assignment.teamId : assignment.adminId;

    conversation.set('assignee_id', assignee_id);
    conversation.set('admin_assignee_id', assignment.adminId);
    conversation.set('team_assignee_id', assignment.teamId);

    part.setProperties({
      event_data,
      assignee_id,
      admin_assignee_id: assignment.adminId,
      team_assignee_id: assignment.teamId,
    });
  } else {
    conversation.set('last_assignee', conversation.get('assignee_id'));
    conversation.set('assignee_id', assignment.get('id'));

    part.setProperties({
      assignee: assignment,
      assignee_id: assignment.get('id'),
    });
  }
}

function assignmentEventData(admin, app, conversation, { adminId, teamId }) {
  let adminChanged = adminId !== conversation.admin_assignee_id;
  let teamChanged = teamId !== conversation.team_assignee_id;

  let template;
  if (adminChanged && teamChanged) {
    template = '[[admin]] assigned this conversation to [[admin_assignee]] and [[team_assignee]]';
  } else if (adminChanged) {
    template = '[[admin]] assigned this conversation to [[admin_assignee]]';
  } else {
    template = '[[admin]] assigned this conversation to [[team_assignee]]';
  }

  return {
    template,
    event_references: {
      admin: {
        id: Number(admin.id),
        type: 'admin',
        label: admin.name,
      },
      admin_assignee: {
        id: Number(adminId),
        type: 'admin',
        label: app.admins.findBy('id', adminId)?.name || 'Unassigned',
      },
      team_assignee: {
        id: Number(teamId),
        type: 'team',
        label: app.admins.findBy('id', teamId)?.name || 'Unassigned',
      },
    },
  };
}

function isSnoozedAndNotByAssignee(conversation, admin) {
  return conversation.get('isSnoozed') && !conversation.isAssignedTo(admin);
}
