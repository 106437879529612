/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { reads } from '@ember/object/computed';
import Service from '@ember/service';
export default Service.extend({
  app: null,
  start(app) {
    this.set('app', app);
  },
  stop() {
    this.set('app', null);
  },
  timezone: reads('app.timezone'),
});
