/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { get } from '@ember/object';
import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const MessageVariationHtmlModeContentNotEmpty = BaseValidator.extend({
  validate(value, options, model) {
    if (model.get('isHtmlMode')) {
      if (isBlank(get(value, 'firstObject.content'))) {
        return 'Finish writing your HTML email';
      }
    }
    return true;
  },
});

MessageVariationHtmlModeContentNotEmpty.reopenClass({
  getDependentsFor() {
    return ['model.emailTemplate.isHtmlMode', 'model.block.@each.{content}'];
  },
});

export default MessageVariationHtmlModeContentNotEmpty;
