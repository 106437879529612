/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const TriggerWorkflowBackgroundOnlyBotCompatibility = BaseValidator.extend({
  intl: service(),

  validate(_stepType, _options, model) {
    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }
    if (!editorConfig.isBackgroundOnly) {
      return true; // Skip this validator if the bot is not background only
    }

    if (model.userVisible) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.trigger-workflow-not-supported-for-bg-only-workflow',
      );
    }

    return true;
  },
});

TriggerWorkflowBackgroundOnlyBotCompatibility.reopenClass({
  getDependentsFor() {
    return ['model.userVisible', 'model.editorConfig', 'model.editorConfig.isBackgroundOnly'];
  },
});

export default TriggerWorkflowBackgroundOnlyBotCompatibility;
