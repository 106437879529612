/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';
import { isEmpty } from '@ember/utils';

const StepQuestions = BaseValidator.extend({
  validate(value, options, model) {
    if (model.isOrdered && isEmpty(value)) {
      return `Step ${model.order + 1}: Add at least one question.`;
    }

    return true;
  },
});

StepQuestions.reopenClass({
  getDependentsFor() {
    return ['model.questions.[]'];
  },
});

export default StepQuestions;
