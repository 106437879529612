/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

const EmailSenderDomainAuthenticated = BaseValidator.extend({
  validate(senderId, options, model) {
    let ruleset = model.email.get('rulesetLink.ruleset');
    if (
      ruleset &&
      ruleset.get('isMatchBehaviorMulti') &&
      !(
        model.isSenderUsingCustomDomain &&
        model.isDomainDkimAuthenticated &&
        model.isDomainReturnPathAuthenticated
      )
    ) {
      return "Recurring emails require a 'From' address with an authenticated custom domain.";
    }
    return true;
  },
});

EmailSenderDomainAuthenticated.reopenClass({
  getDependentsFor() {
    return [
      'model.email.rulesetLink.ruleset.isMatchBehaviorMulti',
      'model.isSenderUsingCustomDomain',
      'model.isDomainDkimAuthenticated',
      'model.isDomainReturnPathAuthenticated',
    ];
  },
});

export default EmailSenderDomainAuthenticated;
