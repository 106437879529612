/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';

const TitleAndBodyBlocksNotEmpty = BaseValidator.extend({
  validate(value, options, model) {
    if (!value.get('firstObject.hasContent')) {
      return options.message;
    }
    return true;
  },
});

TitleAndBodyBlocksNotEmpty.reopenClass({
  getDependentsFor() {
    return ['model.{titleBlocks,bodyBlocks}.firstObject.hasContent'];
  },
});

export default TitleAndBodyBlocksNotEmpty;
