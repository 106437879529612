/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    latestSignificantReviewEntry: { embedded: 'always' },
  },
});
