/* RESPONSIBLE TEAM: team-product-setup */

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { put, post, get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import {
  INSTALLATION_STATUS,
  type InstallationPlatform,
} from 'embercom/components/installation-new/constants';
import { type VerifyAnyInstallApiResponse } from 'embercom/components/installation-new/installation-status';
import type SdkAppService from 'embercom/services/sdk-app-service';
import type Store from '@ember-data/store';

export default class MessengerInstallation extends Service {
  @service declare appService: any;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare sdkAppService: SdkAppService;
  @service declare store: Store;

  @tracked isInstalledForVisitors = false;
  @tracked isInstalledForUsers = false;
  @tracked isInstalledForIos = false;
  @tracked isInstalledForAndroid = false;

  @tracked isIdvConfiguredForWeb = false;

  constructor() {
    super(...arguments);

    this.isInstalledForVisitors = !!this.app.first_anonymous_request_at;
    this.isInstalledForUsers = !!this.app.first_identified_request_at;
    this.isIdvConfiguredForWeb = this.app.identityVerified;

    this.isInstalledForIos = this.iosSdkApp?.is_activated;
    this.isInstalledForAndroid = this.androidSdkApp?.is_activated;

    this.realTimeEventService.on('MessengerFirstPing', this, 'updateLatestInstallationStatus');
  }

  get iosSdkApp() {
    return this.sdkAppService.iosSdkApp;
  }

  get androidSdkApp() {
    return this.sdkAppService.androidSdkApp;
  }

  get app() {
    return this.appService.app;
  }

  get isInstalledForWeb() {
    return this.isInstalledForVisitors || this.isInstalledForUsers;
  }

  get isIdvEnabled() {
    return (
      this.isIdvConfiguredForWeb &&
      (!this.isInstalledForIos || this.iosSdkApp?.identityVerified) &&
      (!this.isInstalledForAndroid || this.androidSdkApp?.identityVerified)
    );
  }

  get webStatus(): INSTALLATION_STATUS {
    if (this.isInstalledForWeb && this.isInstalledForUsers) {
      return this.isIdvConfiguredForWeb
        ? INSTALLATION_STATUS.SECURELY_INSTALLED
        : INSTALLATION_STATUS.INSECURELY_INSTALLED;
    }

    if (this.isInstalledForWeb && this.isInstalledForVisitors) {
      return INSTALLATION_STATUS.INSTALLED_FOR_VISITORS_ONLY;
    }

    return INSTALLATION_STATUS.NOT_INSTALLED;
  }

  get iosStatus(): INSTALLATION_STATUS {
    if (this.isInstalledForIos && this.iosSdkApp?.identityVerified) {
      return INSTALLATION_STATUS.SECURELY_INSTALLED;
    }

    if (this.isInstalledForIos) {
      return INSTALLATION_STATUS.INSECURELY_INSTALLED;
    }

    return INSTALLATION_STATUS.NOT_INSTALLED;
  }

  get androidStatus(): INSTALLATION_STATUS {
    if (this.isInstalledForAndroid && this.androidSdkApp?.identityVerified) {
      return INSTALLATION_STATUS.SECURELY_INSTALLED;
    }

    if (this.isInstalledForAndroid) {
      return INSTALLATION_STATUS.INSECURELY_INSTALLED;
    }

    return INSTALLATION_STATUS.NOT_INSTALLED;
  }

  @task({ drop: true })
  *updateMessengerVisibility(appId: string, showMessenger: boolean): TaskGenerator<void> {
    let response = yield put(`/ember/messenger_settings/all/${appId}`, {
      visibility: {
        messenger_enabled_for_users: showMessenger,
        messenger_enabled_for_visitors: showMessenger,
      },
    });
    this.store.pushPayload('messenger-settings/all', { 'messenger-settings/all': [response] });
  }

  async updateLatestInstallationStatus() {
    let response: VerifyAnyInstallApiResponse = await get(
      `/ember/messenger_install_verification/verify_any_install?app_id=${this.app.id}`,
    );

    this.isInstalledForVisitors = !!response.anonymous_request_at;
    this.isInstalledForUsers = !!response.identified_request_at;
    this.isInstalledForIos = response.ios_is_activated;
    this.isInstalledForAndroid = response.android_is_activated;
  }

  @task({ drop: true })
  *togglePlatformApiAvailability(
    platform: InstallationPlatform,
    valueToSet?: boolean,
  ): TaskGenerator<void> {
    let appToUpdate = this.appForPlatform(platform);
    let wasDisabled = appToUpdate.get(this.fieldName(platform));

    if (valueToSet === wasDisabled) {
      return;
    }

    try {
      let disable =
        valueToSet !== undefined
          ? valueToSet
          : appToUpdate.toggleProperty(this.fieldName(platform));

      appToUpdate.set(this.fieldName(platform), disable);

      yield post(`/ember/messenger_settings/all/${this.app.id}/set_api_availability`, {
        platform,
        disable,
      });

      let isTurnedOn = !appToUpdate.get(this.fieldName(platform));
      this.intercomEventService.trackAnalyticsEvent({
        action: 'toggled',
        object: 'user_api_toggle',
        metadata: {
          enabled: isTurnedOn,
          platform,
        },
        place: 'installation-new',
        section: 'settings',
      });
    } catch (e) {
      appToUpdate.set(this.fieldName(platform), wasDisabled);
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.installation.disable-banner.error', {
          platform,
        }),
      );
    }
  }

  private appForPlatform(platform: InstallationPlatform) {
    switch (platform) {
      case 'ios':
        return this.iosSdkApp;
      case 'android':
        return this.androidSdkApp;
      case 'web':
        return this.app;
    }
  }

  private fieldName(platform: InstallationPlatform) {
    switch (platform) {
      case 'ios':
      case 'android':
        return 'is_disabled';
      case 'web':
        return 'is_web_messenger_disabled';
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'messenger-installation': MessengerInstallation;
  }
}
