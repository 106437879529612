/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseValidator from 'ember-cp-validations/validators/base';

function emptyTextBlocks(blocks) {
  return [].concat(blocks.filterBy('text', '')).concat(blocks.filterBy('text', '<br>'));
}

export default BaseValidator.extend({
  validate(blocks, step) {
    if (!this.get('model.isChatMessage') && !this.get('model.isAnswerBot')) {
      return true;
    }

    let emptyBlocks = emptyTextBlocks(blocks);
    if (blocks.length === emptyBlocks.length) {
      return this.get('model.isAnswerBot')
        ? `Resolution Bot prompts must contain text.`
        : 'Each bot message must contain text.';
    }

    return true;
  },
});
