/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { later, cancel, debounce } from '@ember/runloop';
import { A, isArray } from '@ember/array';
import { notEmpty } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';

let UserPresenceService = Service.extend({
  store: service(),
  app: null,
  isPollingStarted: notEmpty('pollingTimer'),
  newUserIds: A(),

  start(app) {
    if (this._isEnabledInEnvironment()) {
      this.set('app', app);
      this.pollNexusForData();
      later(this, this.refreshUserPresenceStatus, ENV.APP.userPresenceRefreshInterval);
    }
  },

  stop() {
    if (this._isEnabledInEnvironment()) {
      cancel(this.pollingTimer);
      cancel(this.refreshTimer);
      this.set('pollingTimer', null);
      this.set('refreshTimer', null);
      this.set('app', null);
    }
  },

  pollNexusForData() {
    if (this._isKillswitchEnabled()) {
      return;
    }

    if (document.visibilityState !== 'hidden') {
      let userIds = this._getAllUniqueUserIdsFromTheCache();
      this._getLatestUserPresenceFromNexusInBatches(userIds);
    }

    if (this._isEnabledInEnvironment()) {
      this.set(
        'pollingTimer',
        later(this, this.pollNexusForData, ENV.APP.userPresencePollingInterval),
      );
    }
  },

  refreshUserPresenceStatus() {
    let self = this;
    let userIds = self._getAllUniqueUserIdsFromTheCache();
    userIds.forEach(function (userId) {
      self._updatePresenceStatusForUser(userId);
      self._updatePresenceStatusForParticipant(userId);
    });
    if (this._isEnabledInEnvironment()) {
      self.set(
        'refreshTimer',
        later(self, self.refreshUserPresenceStatus, ENV.APP.userPresenceRefreshInterval),
      );
    }
  },

  syncParticipantStatusWithUser(user) {
    this._updatePresenceStatusForParticipant(user.get('id'), user.get('last_request_at'));
  },

  updateUserPresenceForLoadedObjects(objects) {
    if (this.isPollingStarted) {
      if (isArray(objects)) {
        this.newUserIds.pushObjects(objects.mapBy('id'));
      } else {
        this.newUserIds.pushObject(objects.id);
      }
      debounce(
        this,
        this._getLatestUserPresenceFromNexusInBatches,
        this.newUserIds.uniq(),
        ENV.APP._150MS,
      );
    }
  },

  updateUserPresenceForUser(user) {
    if (this.isPollingStarted) {
      this._getLatestUserPresenceFromNexusInBatches([user.get('id')]);
    }
  },

  _getAllUniqueUserIdsFromTheCache() {
    let userIds = A();
    userIds.pushObjects(this._getUserIdsFromCache());
    userIds.pushObjects(this._getParticipantIdsFromCache());
    return userIds.uniq();
  },

  _getUserIdsFromCache() {
    return this.store.peekAll('user').map((u) => u.id);
  },

  _getParticipantIdsFromCache() {
    return this.store.peekAll('participant').map((u) => u.id);
  },

  _getLatestUserPresenceFromNexusInBatches(userIds) {
    this.newUserIds.clear();
    while (userIds.length > 0) {
      let data = userIds.splice(0, ENV.APP.userPresencePollingBatchSize);
      this._getLatestUserPresenceFromNexus(data);
    }
  },

  _getLatestUserPresenceFromNexus(userIds) {
    let request = { type: 'POST' };

    request.url = ENV.APP.userPresenceProxyPollingEndpoint;
    request.data = JSON.stringify({
      app_id: this.get('app.id'),
      users: userIds,
    });

    return ajax(request)
      .then((response) => {
        this._updatePresenceStatusForReturnedUsers(response.users);
      })
      .catch((e) => {
        if (!e.jqXHR) {
          captureException(e, {
            fingerprint: ['user-presence-service', 'getLatestUserPresenceFromNexus'],
          });
        }
      });
  },

  _flattenUserPresenceResponse(response) {
    if (response['userData']) {
      return response['userData'];
    }
    return response;
  },

  _updatePresenceStatusForReturnedUsers(response) {
    response.forEach(
      function (userPresenceResponse) {
        let userId = userPresenceResponse['userId'];
        let userData = this._flattenUserPresenceResponse(userPresenceResponse);
        let latestTime = new Date(userData['lastSeen'] * 1000).toISOString();
        let platform = userData['platform'] || null;
        let lastVisitedUrl = userData['page'] || null;

        this._updatePresenceStatusForUser(userId, latestTime, platform, lastVisitedUrl);
        this._updatePresenceStatusForParticipant(userId, latestTime, platform, lastVisitedUrl);
      }.bind(this),
    );
  },

  _updatePresenceStatusForUser(userId, time, platform, lastVisitedUrl) {
    let user = this.store.peekRecord('user', userId);
    if (user) {
      user.updatePresenceStatus(time, platform, lastVisitedUrl);
    }
  },

  _updatePresenceStatusForParticipant(userId, time, platform, lastVisitedUrl) {
    let participant = this.store.peekRecord('participant', userId);
    if (participant) {
      participant.updatePresenceStatus(time, platform, lastVisitedUrl);
    }
  },

  _isEnabledInEnvironment() {
    return ENV.environment !== 'test';
  },

  _isKillswitchEnabled() {
    return this.get('app.isForbiddenFromUsingUserPresenceService');
  },
});

export default UserPresenceService;
