/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { validator, buildValidations } from 'ember-cp-validations';

export default buildValidations({
  mondayDelivery: {
    description: 'Delivery on Monday',
    validators: [validator('presence', true)],
  },
  tuesdayDelivery: {
    description: 'Delivery on Tuesday',
    validators: [validator('presence', true)],
  },
  wednesdayDelivery: {
    description: 'Delivery on Wednesday',
    validators: [validator('presence', true)],
  },
  thursdayDelivery: {
    description: 'Delivery on Thursday',
    validators: [validator('presence', true)],
  },
  fridayDelivery: {
    description: 'Delivery on Friday',
    validators: [validator('presence', true)],
  },
  saturdayDelivery: {
    description: 'Delivery on Saturday',
    validators: [validator('presence', true)],
  },
  sundayDelivery: {
    description: 'Delivery on Sunday',
    validators: [validator('presence', true)],
  },
  deliverySecondsSinceMidnightStart: {
    description: 'Delivery Window Start Time',
    validators: [validator('number', { allowBlank: false, gte: 0, lte: 24 * 3600 })],
  },
  deliverySecondsSinceMidnightEnd: {
    description: 'Delivery Window End Time',
    validators: [validator('number', { allowBlank: false, gte: 0, lte: 24 * 3600 })],
  },
});
