/* RESPONSIBLE TEAM: team-customer-data-platform */
import { underscore } from '@ember/string';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class ImportConfiguration extends EmbercomBaseSerializer {
  primaryKey = '_id';

  attrs = {
    sourceAttributes: { serialize: false },
  };

  // @ts-ignore
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload._id ||= 'migrate-from-zendesk';
    payload.source_attributes = payload.attributes;
    payload.attribute_mappings.forEach((mapping: any) => (mapping.status = 'missing'));
    delete payload.attributes;

    // @ts-ignore
    return super.normalizeResponse(...arguments);
  }

  serialize(snapshot: any, options: any) {
    let json = super.serialize(snapshot, options);

    json.attribute_mappings = snapshot
      .attr('attributeMappings')
      .filter(function (snap: any) {
        let status = snap.attr('status');
        return status === 'done';
      })
      .map((snap: any) => snap.serialize(options));

    return json;
  }

  keyForAttribute(attr: string) {
    return underscore(attr);
  }
}
